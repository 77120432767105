export default {
    "+{value}% profit": "+{value}% доходность",
    "Selfie verification required": "Требуется подтверждение селфи",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "Чтобы выполнить необходимые мероприятия по проверке учетной записи, мы просим вас предоставить фотографию с высоким разрешением (селфи), на которой вы держите свой документ, удостоверяющий личность, с текущей датой и вашей подписью на листе бумаги. Ваше лицо, тело и обе руки должны быть видны. <br><br>Указанная информация в документе должна быть четкой и читаемой.",
    "Your documents have been checked, now your identity is verified.": "Ваши документы проверены, теперь ваша личность подтверждена.",
    "You need fill identity information before verification your profile.": "Нужно заполнить личную информацию перед верификацией аккаунта.",
    "Documents verification": "Верификация документов",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "Проверка профиля означает предоставление официального документа, удостоверяющего личность клиента. Эта процедура может быть инициирована отделом безопасности компании в любое время.",
    "Upload Photo": "Загрузить Фото",
    "Nickname": "Никнейм",
    "First Name": "Имя",
    "Last Name": "Фамилия",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Имя должно быть указано точно так же, как в документе. За предоставление неверной информации аккаунт может быть удален без предупреждения.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Фамилия должна быть указана точно так же, как в документе. За предоставление неверной информации аккаунт может быть удален без предупреждения.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "Изменить дату рождения в будущем невозможно. Указав дату некорректно, вывод средств со счёта будет невозможен.",
    "Empty": "Пусто",
    "Date of birth": "Дата рождения",
    "Unverified": "Не проверено",
    "Verified": "Подтверждено",
    "Resend": "Отправить повторно",
    "Country": "Страна",
    "Address": "Адрес",
    "Change Identity Info": "Изменить личную информацию",
    "Identity Info": "Личная информация",
    "Two-factor authentication on login": "Двухфакторная авторизация на вход в платформу",
    "Two-factor authentication on withdrawal": "Двухфакторная авторизация на вывод средств",
    "Active": "Активно",
    "Inactive": "Неактивно",
    "Setup": "Установить",
    "Two-step verification": "Двухфакторная авторизация",
    "Security": "Безопасность",
    "Google Authenticator": "Google Authenticator",
    "Scan the QR code": "Отсканируйте  QR-код",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Мы очень серьезно относимся к вашей безопасности, поэтому требуем двухэтапной авторизации. Вот как ее настроить:",
    "Install ": "Установите",
    "Can't scan?": "Не можете отсканировать?",
    "Click here.": "Нажмите здесь.",
    "Enter this code into Google Authenticator.": "Введите этот код в приложение Google Authenticator.",
    "Back to QR code.": "Вернуться к QR-коду.",
    "Enter the 6-digit code from Authenticator below": "Введите 6-значный код из приложения Google Authenticator",
    "Enter 6-digit code...": "Введите 6-значный код...",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "Отлично! Вы успешно включили двухфакторную проверку. При следующем входе вам будет предложено ввести код.",
    "Code": "Код",
    "contact support": "связаться с поддержкой",
    "If you ever lose access to your 2-step verification device, please ": "Если вы когда-нибудь потеряете доступ к своему 2FA-устройству, пожалуйста",
    "Yes, activate": "Да, активировать",
    "Cancel": "Отменить",
    "Close": "Закрыть",
    "Language": "Язык",
    "Timezone": "Часовой пояс",
    "Delete Account": "Удалить аккаунт",
    "Verification Credit / Debit Cards": "Верификация кредитных или дебетовых карт",
    "Add New Card": "Добавить новую карту",
    "Wait for loading...": "Ожидает загрузки…",
    "You don’t have any credit / debit cards for verification": "У вас нет кредитных и дебетовых карт, подлежащих верификации",
    "Verificate": "Верификация",
    "Verification Card": "Верификация карты",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "На чистом листе А4 разместите разворот паспорта и лицевую сторону банковской карты  (с номером, сроком действия карты, и Вашим именем).  <br> От руки добавьте дату на листе и распишитесь<br>\nСфотографируйте и загрузите фото:",
    "Card:": "Карта:",
    "Type:": "Тип:",
    "Your ID and bank card on an A4 sheet": "Лист А4 с паспортом и картой",
    "Yes, confirm": "Да, подтверждаю",
    "Click or drag the image": "Нажмите или перетащите изображение",
    "Card": "Карта",
    "Documents": "Документы",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "Пожалуйста, сначала заполните раздел «Личная информация» в Профиле, и нажмите кнопку «Изменить информацию». После этого Вы сможете добавить новую карту.",
    "Specify the card details for further operations for depositing and debiting funds.": "Укажите реквизиты карты для дальнейших операций по зачислению и списанию средств.",
    "Card number:": "Номер карты:",
    "Expiry month:": "Месяц:",
    "Expiry year:": "Год:",
    "Cardholder name:": "Владелец карты:",
    "Next": "Следущая",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "Пожалуйста, поставьте свою подпись и текущую дату на листе, приложите документ вместе с банковской картой, сделайте снимок и загрузите его в форму ниже.",
    "Do you have a two-sided document?": "Ваш документ  двусторонний?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "Загрузите оборотную сторону отдельным файлом, также на листе с датой и вашей подписью.",
    "Submit only valid documents": "Принимаются только действующие документы",
    "Do not edit or crop photo": "Не редактируйте и не обрезайте фото",
    "Do not upload other people's documents": "Не загружайте чужие документы",
    "Read all": "Читать полностью",
    "Uploading files": "Загрузка файлов",
    "For single-sided documents, upload only the front side.": "Для односторонних документов загружайте только лицевую сторону.",
    "Back": "Назад",
    "Add a card": "Добавить карту",
    "File Requirements": "Требования к файлам",
    "Scanned images are not accepted for card verification": "Отсканированные изображения не принимаются для проверки карты",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "Мы принимаем только файлы с вашим обычным гражданским паспортом или национальным ID (обе стороны должны быть загружены как отдельные файлы)",
    "Driving license CANNOT be accepted": "Водительские права НЕ принимаются",
    "External (foreign/international/travel) passport will not be accepted as well": "Внешний (заграничный/международный/туристический) паспорт также не принимается.",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "Если документ двухсторонний, вам необходимо загрузить обе стороны в виде отдельного файла на том же листе бумаги с текущей датой и вашей подписью.",
    "All information in the document and on the card should be clear and readable on the photo": "Вся информация в документе и на карте должна быть четкой и удобочитаемой на фото.",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "Ваши документ и карта должны полностью помещаться на фото, края не должны быть обрезаны",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "На фотографиях не должно быть бликов или других дефектов, затрудняющих чтение документа и данных карты.",
    "Expired documents and cards will be rejected automatically": "Просроченные документы и карты будут отклонены автоматически",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "Если на вашей карте нет вашего имени, вам необходимо загрузить снимок экрана с банковской выпиской, на котором указаны номер вашей карты и ваше имя. В некоторых случаях разрешается написать свое имя от руки на том же листе бумаги.",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "Не допускается скрытие элементов документов, содержащих данные, необходимые для проверки (ФИО, дата рождения, фотография).",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "В номере карты можно скрыть 8 цифр. Первые 4 и последние 4 цифры должны быть хорошо видны.",
    "CVV code should be hidden at all times, in every case": "Код CVV должен быть всегда скрыт, в любом случае.",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "Любое редактирование ваших файлов запрещено. Принимаются только неотредактированные фотографии и отсканированные изображения. Редактирование личной информации в ваших документах (имя, дата рождения и т. Д.) Не допускается.",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "Фотография должна быть частью документа (наклеена или распечатана). Фотографии, приложенные отдельно, не принимаются.",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "Разрешается загружать документы и карты только на имя, указанное в профиле (на который зарегистрирован ваш аккаунт).",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "Напоминаем, что за предоставление фальшивой/ложной информации аккаунт может быть заморожен/заблокирован до выяснения обстоятельств.",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "Компания оставляет за собой право в любое время попросить вас предоставить любые дополнительные документы для проверки аккаунта (включая ваше селфи с документом).",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "По всем вопросам, связанным с проверкой документов, вы можете создать обращение в службу поддержки на платформе (раздел «Помощь» - это первая кнопка с мигающим значком вопросительного знака на панеле слева)",
    "Profile": "Профиль",
    "Deposit": "Пополнение",
    "Withdrawal": "Вывод",
    "Transactions": "Транзакции",
    "Trades": "Сделки",
    "Market": "Маркет",
    "Analytics": "Аналитика",
    "My current currency": "Текущая валюта",
    "Available for withdrawal": "Доступно для вывода",
    "In the account": "На счету",
    "Percentage of turnover {percent}%": "Процент от оборота {percent}%",
    "Cashback {percent}%": "Кешбэк {percent}%",
    "Currency:": "Валюта:",
    "Change": "Поменять",
    "Live Account": "Реальный счет",
    "Daily budget limit": "Дневной лимит",
    "The value cannot exceed your total budget": "Значение не может превышать ваш общий бюджет",
    "Demo Account": "Демо счет",
    "Apply": "Применить",
    "Edit": "Редактировать",
    "New value": "Новое значение",
    "Confirm": "Подтвердить",
    "Logout": "Выйти",
    "No, cancel": "Нет, отмена",
    "Exchange Form": "Смена валюты счета",
    "My Currency:": "Текущая валюта:",
    "New Currency:": "Новая валюта",
    "You are exchanging": "Вы меняете",
    "You will receive": "Вы получите",
    "Exchange Fee": "Комиссия",
    "Password changed": "Пароль изменен",
    "Old password": "Старый пароль",
    "New password": "Новый пароль",
    "Confirm new password": "Подтвердите новый пароль",
    "Change Password": "Изменить пароль",
    "Confirm password must be equal new password value": "Пароли не совпадают",
    "About us": "О нас",
    "Support": "Поддержка",
    "Help": "Помощь",
    "2-step verification": "Двухфакторная авторизация",
    "Please enter the PIN-code we've just sent to your email": "Пожалуйста, введите PIN-код, который мы только что отправили вам на электронную почту",
    "Please enter the 6-digit code from your authentication app": "Пожалуйста, введите 6-значный код из приложения Google Authenticator",
    "The First Name field is required": "Поле Имя обязательно к заполнению",
    "The Last Name field is required": "Поле Фамилия обязательно к заполнению",
    "Verification of documents": "Проверка документов",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "Пожалуйста, загрузите цветную фотографию или отсканированное изображение вашего гражданского паспорта, водительских прав или национального удостоверения личности.",
    "Upload Documents": "Загрузить документы",
    "Why?": "Почему?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "Вы подтверждаете удаление своей учетной записи? Это действие не может быть отменено.",
    "Set up 2-step verification": "Двухфакторная авторизация",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "К сожалению, вы не можете загружать файлы в этот раздел. <br> Пожалуйста, ознакомьтесь с сообщениями от службы поддержки и приложите необходимые файлы к тикету.",
    "The uploaded document does not match the requirements": "Загруженный документ не соответствует требованиям",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "Следующие документы имеют право на идентификацию: ID, паспорт, загранпаспорт или водительские права.",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "Изображения должны быть высокого качества, данные должны легко читаться, а края документа не должны быть обрезаны.",
    "Balance from {amount}": "Баланс от {amount}",
    "Not verified": "Не подтверждено",
    "Clear": "Очистить",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "Во избежание сервисных ограничений пройдите процедуру верификации: заполните личные данные и загрузите необходимые документы.",
    "Get verified": "Пройти проверку",
    "I got it!": "Я понял!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "Проверка AADHAAR",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "Пожалуйста, загрузите цветную фотографию вашего Aadhaar (только лицевую сторону с вашей фотографией и номером документа).",
    "Send AADHAAR": "Отправить",
    "AADHAAR number:": "Номер AADHAAR:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "Пожалуйста, загрузите цветную фотографию вашего Aadhaar (только лицевую сторону с вашей фотографией и номером документа). ☝ Электронные версии не принимаются.",
    "You have already uploaded {number} files. Please wait until they are checked.": "Вы уже загрузили {number} файлов. Пожалуйста, подождите, пока они не будут проверены.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "Пожалуйста, загрузите цветную фотографию вашего документа: Для RG - обе стороны должны быть загружены в виде отдельных файлов. Для CNH - только лицевую сторону с вашей фотографией. Документ должен быть действительным (менее 10 лет от даты выдачи), и на нем должен быть указан номер вашего CPF. * В случае отсутствия CPF в документе - пожалуйста, загрузите отдельную фотографию Comprovante de Inscrição no CPF, или скриншот выписки из банка с указанием вашего имени и номера CPF. ** Пожалуйста, не загружайте электронные версии. Они не будут приняты.",
    "Submit for verification": "Отправить на проверку",
    "Settings": "Настройки",
    "Please upload a color photograph of the front of your national identity card with your photo.": "Пожалуйста, загрузите цветную фотографию лицевой стороны вашего национального удостоверения личности (страница с вашей фотографией).",
    "Template": "Тема платформы",
    "Light Mode": "День",
    "Twilight": "Сумерки",
    "Full Night": "Ночь",
    "Grid's opacity": "Прозрачность сетки",
    "Auto-scrolling": "Автопрокрутка",
    "Automatic graphic scrolling": "Автоматическая прокрутка графика",
    "1-click trade": "Сделки в 1 клик",
    "Open trades without confirmation": "Открывать сделки без подтверждения",
    "Use timezone": "Использовать часовой пояс",
    "Use your timezone data": "Использовать информацию о Вашем часовом поясе",
    "Graph colors": "Цвета графика",
    "Up Trend": "Восходящий тренд",
    "Down Trend": "Нисходящий тренд",
    "Platform": "Платформа",
    "Short order label": "Краткий вид сделок",
    "Use short order element mode": "Отключите отображение времени и суммы сделки на графике",
    "Perfomance Mode": "Производительный режим",
    "Use optimized rendering for chart and candles": "Оптимизированный режим для свечного и зонного графиков",
    "Background": "Фон",
    "Choose file": "Выбрать файл",
    "Max size — 2 Mb": "Макс. Размер - 2 Мб",
    "Sign up": "Регистрация",
    "Sign in": "Войти",
    "Budget and limits": "Бюджет и ограничения",
    "Daily limit": "Дневной лимит",
    "Not set": "Не установлен",
    "Total funds": "Всего средств",
    "Top up": "Пополнить",
    "Set up": "Настроить",
    "Set a daily limit on trading to reduce financial risks.": "Установите дневной лимит на торговлю, чтобы снизить финансовые риски.",
    "Limit amount": "Предельная сумма",
    "More": "Ещё",
    "Leader Board": "Рейтинг",
    "Signals": "Сигналы",
    "Notifications": "Уведомления",
    "mark all as read": "пометить все прочитанными",
    "No notifications": "Нет уведомлений",
    "No more notifications": "Уведомлений больше нет",
    "Your position:": "Ваша позиция:",
    "How does this rating work?": "Как работает этот рейтинг?",
    "of the Day": "текущего дня",
    "Nothing has been found for current query, try another asset name.": "По запросу ничего не найдено.",
    "My rating in the Leader Board": "Мой рейтинг в ТОПе",
    "Participants can only be those who trade in live mode": "Участвуют трейдеры, которые торгуют на Реальном счете",
    "Your position depends on the amount of earned money": "Ваша позиция зависит от объема полученной прибыли",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "Рассчитывается каждый день, с 00: 00 UTC до 23: 59 UTC",
    "All traders on our platform participate in this rating. Main features of the rating:": "Все трейдеры на нашей платформе участвуют в этом рейтинге. Основные особенности рейтинга:",
    "How does it work?": "Как это работает?",
    "Trading signals": "Торговые сигналы",
    "Past signals": "Прошлые сигналы",
    "What’s it?": "Что это такое?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "Торговый сигнал - это не прямая инструкция к торговле, а рекомендация аналитика. Сигнал не заменяет независимый анализ рынка и не гарантирует прогнозируемого исхода.",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "Каждый сигнал имеет свое время, которое он актуален. По истечении этого времени котировки могут измениться и оказать неожиданное влияние на результат сделки.",
    "Continue": "Продолжить",
    "Duration": "Длительность",
    "Return back to FAQ": "Вернуться к FAQ",
    "Return back to Help": "Вернуться к справке",
    "How to withdraw money from the account?": "Как вывести деньги со счета?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "Процедура вывода капитала предельно проста и совершается через Ваш личный кабинет.",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "Способ, который Вы выбрали для пополнения счета, является и способом снятия средств (см. вопрос «Как можно пополнить счет?»).",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "Например, если для заключения сделки Вы пополнили свой счет через платежную систему Visa, то и снимать деньги Вы будете через платежную систему Visa.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "Когда речь идет о выводе достаточно крупной суммы, Компания может запросить верификацию (верификация запрашивается на личное усмотрение Компании), вот почему очень важно регистрировать счет лично на себя, чтобы в любой момент времени подтвердить свои права на него.",
    "What is the minimum withdrawal amount?": "Какова минимальная сумма вывода?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "Минимальная сумма вывода начинается с 10 USD  для большинства платежных систем.<br> Для криптовалют эта сумма начинается от 50 USD (и может быть выше для некоторых валют, например Bitcoin).",
    "Do I need to provide any documents to make a withdrawal?": "Нужны ли какие-нибудь документы, чтобы осуществить вывод средств?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "Обычно для вывода средств дополнительные документы не нужны. Но Компания по своему усмотрению может попросить Вас подтвердить свои личные данные путем запроса определенных документов. Обычно это делается в целях предотвращения действий, связанных с незаконной торговлей, финансовыми махинациями, а также использованием средств, полученных незаконным путем.",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "Список таких документов минимален, а операция по их предоставлению не отнимет у Вас много времени и сил.",
    "How long does it take to withdraw funds?": "Сколько времени занимает вывод средств?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "В среднем процедура вывода средств занимает от одного до пяти дней с даты получения соответствующей заявки Клиента и зависит только от объема одновременно обрабатываемых заявок. Компания всегда старается производить выплаты непосредственно в день поступления запроса от Клиента.",
    "Is there any fee for depositing or withdrawing funds from the account?": "Существует ли комиссия при пополнении или выводе средств со счета?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "Нет. Компания не взымает комиссию ни за операцию пополнения счета, ни за вывод средств.",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "Однако, стоит учитывать, что платежные системы могут взимать свои комиссии и использовать внутренний курс конвертации валют.",
    "Can I close my account? How to do it?": "Могу я закрыть свой счет? Как это сделать?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "Удалить счет можно в Вашем Личном кабинете, нажав на кнопку «Удалить счет», расположенную в нижней части страницы профиля.",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "Я ввел(а) неверные данные, как это исправить?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "Необходимо обратиться в службу поддержки через сайт компании, а затем отредактировать данные профиля.",
    "What data is required to register on the Company website?": "Какие данные нужны для регистрации на сайте Компании?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "Для заработка на бинарных опционах сначала следует открыть счет, позволяющий проводить сделки. Для этого необходимо пройти регистрацию на сайте Компании.",
    "The registration process is simple and does not take much time.": "Процесс регистрации простой и не отнимет у Вас много времени.",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "Необходимо заполнить анкету по предложенной форме. От Вас потребуется ввести следующую информацию:",
    "name (in English)": "имя (на русском языке);",
    "email address (indicate the current, work, address)": "адрес электронной почты (указывайте актуальный, рабочий, адрес);",
    "telephone (with a code, for example, + 44123 ....)": "телефон (с кодом, например, +7911….);",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "пароль, который в дальнейшем будет Вами использоваться для входа в систему (в целях минимизации риска несанкционированного входа в ваш личный аккаунт, рекомендуем придумать сложный пароль с использованием строчных, заглавных букв и цифр. Не сообщайте пароль третьим лицам).",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "После заполнения формы вам будут предложены различные способы пополнения торгового счета.",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "В какой валюте открывается счет? Можно ли изменять валюту счета Клиента?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "По умолчанию торговый счет открывается в долларах США. Но для Вашего удобства Вы можете поменять валюту счета в любой момент.",
    "A list of available currencies can be found on your profile page in your Client's account.": "Список доступных валют можно посмотреть на странице Вашего профиля в личном кабинете.",
    "What are digital options?": "Что такое Digital Trading опционы?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "Опционом называется производный финансовый контракт (дериватив), в основе которого лежит любой базовый актив – акция, валютная пара, нефть и т.д.",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "Digital опцион - нестандартный опцион, который используют для получения прибыли на движениях цены таких активов за определенный период времени.",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "Digital опцион в зависимости от оговоренного сторонами сделки условия, в определенное сторонами время, приносит фиксированный доход (разница между доходом от сделки и ценой актива) или убыток (в размере стоимости актива).",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "Так как digital опцион приобретают заранее по фиксированной цене, то размер прибыли, как и размер потенциального убытка, известны еще до совершения сделки.",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "Еще одна особенность этих сделок – ограничение по времени. Любой опцион имеет свой срок (время экспирации или исполнения).",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "Независимо от степени изменения цены базового актива (насколько она стала выше или ниже), в случае выигрыша опциона, всегда производится фиксированная выплата. Следовательно, Ваши риски ограничены только той суммой, на которую приобретается опцион.",
    "What is the expiration period of a trade?": "Что такое период экспирации сделки?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "Период экспирации – это время, по истечении которого сделка будет считаться исполненной (завершенной) и по ней автоматически подводится итог.",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "При заключении сделки с Digital опционами Вы самостоятельно определяете время исполнения сделки (1 минута, 2 часа, месяц и т.д.).",
    "What is the gist of digital options trading?": "В чем заключается суть торговли Digital Trading?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "Дело в том, что Digital опцион - это самый простой вид производного финансового инструмента. Для того, чтобы зарабатывать деньги на рынке Digital опционов, не нужно прогнозировать значение рыночной цены актива, которое она может достичь.",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "Принцип торгового процесса сводится лишь к решению одной единственной задачи - повысится или понизится цена актива к моменту исполнения контракта.",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "Особенность такого рода опционов в том, что Вам абсолютно неважно, пройдет цена базового актива сто пунктов или всего один с момента заключения сделки до ее закрытия. Вам важно определить только направление движения этой цены.",
    "If your prognosis is correct, in any case you get a fixed income.": "Если Ваш прогноз окажется верным, Вы в любом случае получаете фиксированный доход.",
    "What are the possible results of the placed trades?": "Какие существуют варианты результата проведенной сделки?",
    "There are three possible outcomes in the digital options market:": "Существует три варианта развития событий на рынке Digital опционов:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "в случае, если Ваш прогноз определения направления движения цены базового актива оказался верным, вы получаете доход.",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "в случае, если к моменту исполнения опциона Ваш прогноз оказался ошибочным, Вы несете убыток, ограниченный размером стоимости актива (т.е. по сути, Вы можете потерять только свою инвестицию);",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "в случае, если итог сделки оказался нулевым (цена базового актива не изменилась, опцион исполнился по цене, по которой был приобретен), Вы возвращаете себе свою инвестицию.",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "Существует ли на вашей торговой платформе учебный счет для того, чтобы понять процесс работы с Digital опционами, не тратя при этом собственные денежные средства?",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "Да. В целях освоения торговых навыков и тестирования возможностей торговой платформы Компании, Вы можете воспользоваться учебным счетом (бесплатный счет). Это своего рода тренажер, позволяющий сначала попробовать, а только потом переходить к реальной торговле. Такой демо-счет также подойдет и опытным трейдерам для повышения их профессионального уровня.",
    "The balance of such an account is 10,000 units.": "Баланс такого счета составляет 10000 единиц.",
    "What determines profit size?": "От чего зависит размер прибыли?",
    "There are several factors that affect the size of your profit:": "Можно выделить несколько факторов, которые влияют на размер Вашей прибыли:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "ликвидность выбранного Вами актива на рынке (чем больше актив востребован на рынке, тем большую прибыль Вы получите)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "время совершения сделки (ликвидность актива утром и ликвидность актива днем могут существенно отличаться)",
    "tariffs of a brokerage company": "тарифы брокерской компании",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "изменения на рынке (экономические события, изменения в части того или иного финансового актива и т.д.)",
    "What are the varieties of digital options?": "Какие есть разновидности digital опционов?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "Совершая опционную сделку, Вы должны выбрать базовый актив, который будет лежать в основе опциона. На данный актив и будет осуществляться Ваш прогноз.",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "Проще говоря, покупая digital контракт, вы фактически делаете ставку на движение цены такого базового актива.",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "Базовый актив — это «предмет», цена которого и берется в расчет при заключении сделки. В качестве базового актива digital опционов обычно выступают самые востребованные продукты на рынках. Их четыре вида:",
    "securities (shares of world companies)": "ценные бумаги (акции мировых компаний)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "валютные пары (EUR/USD, GBP/USD, и т.д.)",
    "raw materials and precious metals (oil, gold, etc.)": "сырьё и драгоценные металлы (нефть, золото и т.д.)",
    "indices (S&P 500, Dow, dollar index, etc.)": "индексы (S&P 500, Dow, индекс доллара и т.д)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "Идеального базового актива не существует. Актив нужно выбирать, опираясь на собственные знания и интуицию, а также на различные аналитические данные.",
    "What is a trading platform and why is it needed?": "Что такое торговая платформа и для чего она нужна?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "Торговая платформа — программный комплекс, посредством которого Клиент имеет возможность проводить сделки (операции) с помощью предложенных финансовых инструментов, а также получает доступ к информации о значении котировок, положении рынка в режиме реального времени, действиях Компании и т.д.",
    "How to learn quickly how to make money in the digital options market?": "Как быстрее научиться зарабатывать на рынке digital опционов?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "выработать собственные стратегии торговли, при которых количество верно спрогнозированных сделок будет максимальным, и следовать им",
    "diversify your risks": "диверсифицировать свои риски",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "В выработке стратегий, а также поиске вариантов диверсификации Вам поможет мониторинг рынка, изучение аналитической и статистической информации, которую можно получить из различных источников (Интернет-ресурсы, заключения экспертов, аналитиков в данной области и т.д.), один из которых – сайт Компании.",
    "Is the download of the program to a computer or smartphone required?": "Нужно ли загружать программу на компьютер или смартфон?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "Нет, не нужно. Необходимо просто зарегистрироваться на сайте Компании по установленной форме и открыть личный Счет.",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "За счет каких денежных средств Компания производит выплату прибыли Клиенту, в случае проведения им успешной сделки?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "Компания зарабатывает вместе с Клиентами. Поэтому она заинтересована в том, чтобы доля прибыльных сделок значительно превалировала над долей убыточных, в силу того, что Компания имеет процент от выплат при успешно выбранной Клиентом торговой стратегии.",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "Кроме того сделки, проведенные Клиентом, в совокупности составляют торговый объем Компании, который передается брокеру или бирже, которые в свою очередь входят в объединение поставщиков ликвидности, что в совокупности приводит к росту ликвидности самого рынка.",
    "What is account verification?": "Что такое верификация аккаунта?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "Верификация – это подтверждение Клиентом своих личных данных посредством предоставления Компании дополнительных документов. Условия верификации для Клиента максимально просты, а список документов минимален. Например, Компания может попросить:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "предоставить цветную скан-копию первого разворота паспорта Клиента (страница паспорта с фотографией)",
    "identify with the help of a \"selfie\" (photograph of himself)": "идентифицироваться с помощью «селфи» (фотография самого себя)",
    "confirm the address of registration (residence) of the Client, etc": "подтвердить адрес регистрации (проживания) Клиента и т.д.",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "Компания может запросить любые документы в случае невозможности в полном объеме идентифицировать Клиента и введенные им данные.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "После передачи Компании электронных копий документов, Клиенту придется подождать некоторое время для проверки предоставленных данных.",
    "How to understand that I need to go through account verification?": "Как понять, что мне нужно пройти верификацию аккаунта?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "В случае возникновения необходимости пройти верификацию Вы получите соответствующее уведомление на электронную почту и/или смс-оповещение.",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "При этом необходимо иметь в виду, что Компания использует только те данные (в частности, адрес электронной почты и телефон), которые Вы указали при регистрации, поэтому крайне важно предоставлять достоверные и актуальные данные.",
    "How do I know that I successfully passed verification?": "Как я узнаю, что успешно прошел верификацию?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "Вы получите оповещение на электронную почту и/или смс-уведомление о завершении процесса верификации Вашего аккаунта и возможности приступать к проведению сделок на торговой платформе Компании.",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "Можно ли указывать чужие (поддельные) данные при регистрации на сайте?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "Нет. Клиент осуществляет самостоятельную регистрацию на сайте Компании, предоставляя полную и достоверную информацию о себе по вопросам, заданным в регистрационной форме, и поддерживает эту информацию в актуальном состоянии.",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "В случае необходимости проверки личности Клиента, Компания вправе запросить соответствующие документы или пригласить клиента в офис.",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "В случае если данные, введенные в поля регистрационной формы, не соответствуют данным, указанным в предоставленных документах, счет может быть заблокирован.",
    "How long does the verification process take?": "Много ли времени занимает процесс верификации?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "Не более 5 (пяти) рабочих дней с даты получения Компанией запрошенных документов.",
    "Is there a minimum amount that I can deposit to my account at registration?": "Установлен ли размер минимальной суммы, которую я могу зачислить на счет при регистрации?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "Преимущество торговой платформы Компании в том числе в том, что Вам не придется зачислять на счет крупные суммы. Начать торговлю Вы можете, инвестировав незначительную сумму денег. Минимальный депозит составляет 10 долларов США.",
    "How can I deposit?": "Как можно пополнить счет?",
    "It is very easy to do. The procedure will take a couple of minutes.": "Сделать это очень просто. Процедура займет пару минут.",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "Откройте окно исполнения сделки и нажмите зеленую кнопку «Пополнить» в правом верхнем углу вкладки.",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "Пополнить счет можно также через Ваш Личный кабинет, нажав кнопку «Пополнить» в профиле аккаунта.",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "После необходимо выбрать способ пополнения счета (Компания предлагает массу удобных способов, которые доступны Клиенту и отображаются в его личном кабинете).",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "Далее укажите валюту, в которой будет производиться пополнение счета, и соответственно валюту самого счета.",
    "Enter the amount of the deposit.": "Введите размер пополнения.",
    "Fill out the form by entering the requested payment details.": "Заполните предложенную форму, введя запрашиваемые платежные данные.",
    "Make a payment.": "Произведите платеж.",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "Нужно ли пополнять счет на торговой платформе и как часто это нужно делать?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "Для работы с Digital Trading опционами Вам необходимо открыть личный счет. Чтобы заключать реальные сделки Вам безусловно нужно будет его пополнять на сумму приобретаемых опционов.",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "Начать торговлю без денежных средств можно только используя учебный счет компании (демо-счет). Такой счет является бесплатным и создан для демонстрации функционирования торговой платформы. С помощью такого счета Вы можете потренироваться в приобретении digital опционов, понять основные принципы торговли, протестировать различные методики и стратегии, или же оценить уровень Вашей интуиции.",
    "What is the minimum deposit amount?": "Какая минимальная сумма пополнения счета?",
    "{number} questions": "{number} вопросов",
    "Verification": "Верификация",
    "Payment": "Пополнения",
    "Payouts": "Выплаты",
    "Ask a specialist": "Задать вопрос специалисту",
    "FAQ": "Частые вопросы",
    "Didn't find an answer to your question?": "Не нашли ответ на свой вопрос?",
    "Open knowledge base": "Открыть документацию",
    "Contact support": "Связь со службой поддержки",
    "Open FAQ page": "Открыть страницу FAQ",
    "New message": "Новое сообщение",
    "Tournaments": "Турниры",
    "To end": "До конца",
    "Until start": " До начала",
    "Front side": "Лицевая сторона",
    "Back side (if any)": "Оборотная сторона (если есть)",
    "Tourna-ments": "Турниры",
    "Deletion of Account and Personal Data": "Удаление учетной записи и персональных данных",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "Удаляя свой аккаунт и личные данные, вы навсегда теряете доступ к своему аккаунту на платформе Quotex. Напоминаем, что ваши данные будут удалены безвозвратно, и восстановить аккаунт вдальнейшем будет невозможно!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "ПРИМЕЧАНИЕ: Пожалуйста, завершите все открытые сделки и отложенные ордера, прежде чем удалить свой аккаунт.",
    "Request Deletion": "Запрос на удаление",
    "To delete your account, please contact support service": "Чтобы удалить свой аккаунт, обратитесь в службу поддержки",
    "Two-factor authorization via Email": "Двухфакторная авторизация через Email",
    "This feature is for experienced users who are familiar with Google Authenticator.": "Эта функция предназначена для опытных пользователей, знакомых с Google Authenticator.",
    "Please note!": "Пожалуйста, обратите внимание!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "Если вы по какой-либо причине потеряете доступ к Google Authenticator после его подключения, вы потеряете доступ к своему аккаунту.",
    "Proceed to setup": "Перейдите к настройке",
    "Account protection method": "Метод защиты учетной записи",
    "Here's how to set it up:": "Вот как его установить:",
    "Please enter the PIN-code we've just sent to your email:": "Пожалуйста, введите PIN-код, который мы только что отправили на вашу электронную почту:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "Отличная работа. Вы успешно включили двухэтапную верификацию через Google Authenticator. При следующем входе вам будет предложено ввести код.",
    "If you ever lose access to your 2-step verification device, please": "Если вы когда-нибудь потеряете доступ к своему устройству двухэтапной проверки, пожалуйста",
    "Confirm account deletion": "Подтвердите удаление учетной записи",
    "To delete your account, follow the link in the email sent to": "Чтобы удалить свою учетную запись, перейдите по ссылке в электронном письме, отправленном по адресу",
    "Image requirements": "Требования к изображению",
    "Email": "Email",
    "Сonfirmation": "Подтверждение",
    "Change protection method": "Изменить способ защиты",
    "First, enter the code from the Google Authenticator app:": "Сначала введите код из приложения Google Authenticator:",
    "First, enter the PIN-code we've just sent to your email:": "Сначала введите PIN-код, который мы только что отправили на вашу электронную почту:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "Отлично. Вы успешно включили двухэтапную проверку по электронной почте.",
    "Receiving codes via Email": "Получение кодов по Email",
    "Receiving codes via Google Authenticator": "Получение кодов через Google Authenticator",
    "Choose a method:": "Выберите способ:",
    "To enter the platform": "Для входа на платформу",
    "To withdraw funds": "Для снятия средств",
    "day": "день",
    "days2": "дня",
    "days5": "дней",

text:'Выбор валюты',
text1:'Электронная почта',
text2:'Отменить транзакцию на',
text3:'Бесплатный промокод',
text4:'Мы ежедневно улучшаем эту платформу, чтобы сделать ее более удобной.',
text5:'Вам нравится эта платформа? Пожалуйста, поделитесь ей с другими!',
text6:'Пожалуйста, оставьте свой отзыв о QUOTEX. Вы получите вознаграждение в виде бесплатной отмены транзакции.',
text7:'Отправьте вашу ссылку на отзыв нашей команде поддержки клиентов, после проверки вознаграждение будет отправлено вам.',
text8:'Минимальная сумма депозита',
text9:'Минимальная сумма вывода',
text10:'Быстрый вывод',
text11:'Бесплатно',
text12:'Не проверено',
text13:'Отправить повторно',
text14:'Подтвердите ваше участие',
text15:'Отменить транзакцию',
text16:'Пожалуйста, введите и активируйте ваш промокод немедленно. Вы можете использовать промокод в любое время.',
text17:'Промокод:',
text18:'Получите бонус 30% на основе баланса депозита',
text19:'Текущая валюта',
text20:'Доступный для вывода остаток',
text21:'На счету',
text22:'Сегодня',
text23:'Вчера',
text24:'Неделя',
text25:'Месяц',
text26:'Чистый объем транзакций',
text27:'WELCOME - это бонус 30% за первый депозит.',
text28:'Первоначальная сумма депозита составляет $100. Чтобы получить бонус 30%, выберите способ депозита.',
text29:'Банковская карта',
text30:'Банк',
text31:'Выбранный способ оплаты:',
text32:'Минимальная сумма:',
text33:'Максимальная сумма:',
text34:'Выбрать другой способ',
text35:'Информация об оплате',
text36:'Сумма',
text37:'Использовать бонус',
text38:'Условия и положения',
text39:'У меня есть промокод',
text40:'Промокод',
text41:'Применить промокод',
text42:'Введен правильный промокод. Вы получите бонус 30%.',
text43:'Вы получите бонус в размере',
text44:'%',
text45:'Пожалуйста, ознакомьтесь со всеми часто задаваемыми вопросами',
text46:'Для торговли цифровыми активами вам необходимо открыть личный аккаунт. Для совершения реальных сделок необходимо обязательно внести сумму в соответствии с ценой опциона.',
text48:'У вас есть {0} промокодов',
text49:'Используйте этот промокод для отмены убыточной сделки',
text50:'Вы можете отменить только не защищенные сделки на сумму, не превышающую максимальное значение промокода.',
text51:'Статус',
text52:'Использовать',
text53:'У вас пока нет истории промокодов. Вы можете добавить промокоды, используя кнопку ниже.',
text54:'Показать историю',
text55:'Ввести промокод',
text56:'Личная информация',
text57:'Пусто',
text58:'Имя',
text59:'Фамилия',
text60:'Дата рождения',
text61:'Страна',
text62:'Сохранить',
text63:'Адрес',
text64:'Проверка документа',
text65:'Перед проверкой учетной записи необходимо ввести личные данные.',
text66:'Безопасность',
text67:'Двухэтапная аутентификация',
text68:'Изменить пароль',
text69:'Старый пароль',
text70:'Новый пароль',
text71:'Повторите новый пароль',
text72:'Закрыть учетную запись',
text73:'Проверка кредитной или дебетовой карты',
text74:'Добавить новую банковскую карту',
text75:'У вас нет ни одной кредитной или дебетовой карты для проверки',
text76:'У вас пока нет истории транзакций.',
text77:'Начало',
text78:'Истечение',
text79:'Применить фильтр',
text80:'Предыдущий',
text81:'Следующий',
text82:'Прибыль',
text83:'Отменено',
text84:'Счет',
text85:'Сбор',
text86:'Вы можете вывести средства с платформенного счета на банковскую карту или электронный кошелек, который вы использовали для депозита. Вы можете запросить вывод средств в любое время. Ваш запрос на вывод будет обработан в течение 3 рабочих дней.',
text87:'Депозит',
text88:'Некоторые предыдущие запросы',
text89:'История выводов',
text90:'Мои запросы',
text91:'Подать запрос',
text92:'Часто задаваемые вопросы',
text93:'У вас нет ни одного поданного запроса',
text94:'Если у вас есть вопросы, вы можете найти ответы в разделе часто задаваемых вопросов.',
text95:'Авторское право © 2024 Quotex. Все права защищены.',
text96:'Войти',
text97:'Пароль',
text98:'Запомнить меня',
text99:'Вернуть информацию',
text100:'Готово',

text104:'Инновационная платформа для интеллектуальных инвестиций',
   text105: 'Зарегистрируйтесь и получите $10 000 на демо-счет, где вы научитесь торговать',
   text106:'Зарегистрироваться',
   text107:'Открыть демо-счет',
   text108:'Удобный торговый интерфейс',
   text109:'Мы создали самый простой и удобный интерфейс, чтобы вы не отвлекались от самого главного - торговли. ',
   text110:'Встроенный сигнал',
   text111:'Рассмотрите торговую стратегию, мы предоставляем самые точные и инновационные сигналы до 87%, которые помогут вам разработать собственную эффективную стратегию. ',
   text112:'Торговый индикатор',
   text113:'Мы собрали самые выгодные торговые индикаторы. Используйте этот индикатор для увеличения баланса вашего счета. ',
   text114:'Идеальная скорость',
   text115: '«Наша платформа использует самые инновационные технологии и обеспечивает потрясающую скорость. ',
   text116:'Попробуйте на демо-счете',
   text117:'Чтобы заработать больше денег',
   text118:'Сделайте правильный прогноз',
   text119:'Цена бычья или медвежья? ',
   text120:'Открыть демо-счет',
   text121: '«Нажмите»',
   text122:'Нажми на одну из кнопок и посмотри результат',
   text123:'Цена вырастет',
   text124:'Цена упадет',
   text125:'Ждём результатов...',
   text126:'Ваша прибыль',
   text127:'Извините, ваш прогноз неверен',
   text128:'Попробуй еще раз',
   text129:'Ваша прибыль',
   text130:'Поздравляем! Ваш прогноз верен»',
   text131:'Начни зарабатывать деньги',
   text132:'Мобильное приложение всегда доступно',
   text133: '«Скачайте наше удобное приложение для iPhone или Android и начните торговать! ',
   text134:'Доступное время',
   text135:'Начать торговать',
   text136:'3 шага',
   text137:'Откройте реальный счет бесплатно всего за несколько минут',
   text138:'Торгуйте на демо-счете в один клик',
   text139:'Практика',
   text140:'Совершенствуйте свои навыки с помощью демо-счетов и обучающих материалов',
   text141:'Начните обучение с демо-счета',
   text142:'Внесите депозит на свой реальный счет и начните торговать',
   text143:'Более 410 инструментов, минимальная сумма транзакции — 10 долларов США',
   text144:'Переходной метод внесения депозита',
   text145: 'Часто задаваемые вопросы',
   text146:'Как это освоить? ',
   text147:'Зарегистрируйте демо-счет и попрактикуйтесь в использовании этого аккаунта. Демо-счет аналогичен реальному, но депозит не требуется. ',
   text148:'Сколько времени занимает обработка вывода средств? ',
   text149: 'В среднем процесс вывода средств занимает от 1 до 5 дней с даты получения соответствующего запроса пользователя и зависит только от количества запросов, обрабатываемых одновременно. Компания всегда стремится оплатить заявку Пользователя непосредственно в день его получения. ',
   text150:'Что такое торговая платформа? какой эффект? ',
   text151: '«Торговая платформа представляет собой программную систему. Пользователи используют финансовые инструменты, предоставляемые этой системой, для проведения транзакций (операций), а также используют значение котировок, рыночную ситуацию в реальном времени, действия компании и другую информацию, предоставляемую компанией. ',
   text152: '«Могу ли я использовать свой мобильный телефон для проведения транзакций?» ',
   text153:'Наша платформа использует самые инновационные технологии и может быть открыта в браузере любого компьютера или смартфона',
   text154:'Какова минимальная сумма депозита? ',
   text155: '«Преимущество торговой платформы этой компании в том, что вам не нужно вносить на свой счет большие суммы денег. Вы можете начать торговать, вложив небольшую сумму денег. Минимальный депозит составляет 10 долларов. ',
   text156:'Взимается ли какая-либо комиссия при вводе или снятии денег со счета? ',
   text157:'Нет. Компания не взимает комиссию за такие операции, как пополнение счета или вывод средств. ',
   text158: '«Однако следует отметить, что платежные системы могут взимать комиссии и использовать дифференцированные курсы обмена валют. ',
   text159:'Перейти ко всем вопросам',
   text160:'Quotex: Инновационная платформа',
   text161:'Торговля цифровыми активами',
   text162:'Открыть реальный счет',
   text163:'Партнер',
   text164:'Демо-счет',
   text165:'Контактная информация',
   text166:'Важный вопрос',
   text167:'Финансовые проблемы',
   text168:'проверка',
   text169:'Правила',
   text170:'Политика конфиденциальности',
   text171:'Условия оплаты',
   text172:'Соглашение об оказании услуг',
   text173:'Предупреждение о риске',
   text174:'Правила торговых операций',
   text175:'Правила неторговой деятельности',
   text176:`Предупреждение о рисках: торговля на платформах для торговли цифровыми активами и использование инструментов кредитного финансирования могут сопряжены со значительными рисками и могут привести к полной потере вашего инвестиционного капитала. Не вкладывайте больше, чем вы можете себе позволить, и вы должны понимать связанные с этим риски. Торговля с использованием заемных инструментов финансирования не подходит большинству инвесторов. Торговля без использования заемных средств, таких как акции, также рискованна, поскольку цены на акции могут как снижаться, так и увеличиваться, а это может означать, что даже если вы выиграете, вы получите гораздо меньше, чем вложили.
   Прошлый успех не гарантирует успеха в будущем. Прежде чем торговать, обратите внимание на свой торговый опыт и уровень, инвестиционные цели и при необходимости обратитесь к независимому финансовому консультанту. Пользователи должны определить, разрешено ли использование услуг под брендом Quotex на основании законов и правил их страны проживания. `,
}