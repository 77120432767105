<template>
  <div class="modal-sign__input select">
    <label class="modal-sign__input-label">{{ lable }}</label>
    <div class="modal-sign__input-select form__select select__wrapper">
      <div class="select__result" @click="show = !show">
        <div>
          <div data-value="USD"><span class="select__text">{{ value }}</span></div>
        </div>
        <i class="select__icon"></i>
        <!-- <slot name="right_icon" /> -->
      </div>
      <div class="select__options" :class="{ select__active: show }">
        <div>
          <span class="select__text">{{ placeholder }}</span>
        </div>
        <div v-for="item in options" :key="item.value">
          <span @click="selectHandle(item)" class="select__text">{{ item.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'placeholder',
    },
    lable: {
      type: String,
      default: 'placeholder',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array | String | Number,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    selectHandle(item) {
      this.$emit('update:value', item.value);
      this.show = false;
    },
  },
};
</script>
