/**
 * Created by guolf on
 */
import Vue from 'vue';
import axios from 'axios';
import router from '../router';
import Qs from 'qs';
let http = axios.create({
  // baseURL: './',
  baseURL: 'https://api.850s.fun',
});
Vue.prototype.$axios = http;

// 加载惯例配置
// import settings from './convention';

// http请求拦截器
http.interceptors.request.use(
  (config) => {
    // var configType = config.headers['Content-Type'];
    // config.headers = {
    //   // 'Content-Type': configType,
    //   'Content-Type': 'application/zip',
    // //   Authorization: sessionStorage.getItem('token'),
    // };
    //请求时调用轻提示
    // Toast.loading({
    //   message: '加载中...',
    //   forbidClick: true,
    //   loadingType: 'spinner',
    //   duration: 0,
    // });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
// http响应拦截器
http.interceptors.response.use(
  (response) => {
    // 响应成功关闭loading
    setTimeout(function () {
    //   Toast.clear();
    }, 500);
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
        router.push('/')
    }
    return Promise.reject(error);
  },
);

function apiAxios(method, url, params, headerType, response) {
  let header = '';
  let PostData = params;
  if (headerType == '1') {
    header = { 'Content-Type': 'application/json;charset=utf-8' }
  } else if (headerType == '2') {
    header = { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' }
  } else if (headerType == '3') {
    header = { 'Content-Type': 'multipart/form-data;charset=utf-8' }
  }else if(headerType == '4'){
    header = {"Content-Type": "application/zip"}
  }
  if (headerType == '1') {
    PostData = params;
  } else {
    PostData = Qs.stringify(params);
  }
  console.log(header,'headerheader')
  return http({
    method: method,
    url: url,
    headers: header,
    data: method === 'POST' || method === 'PUT' ? PostData : null,
    params: method === 'GET' || method === 'DELETE' ? params : null,
  }).then(function (res) {
    if(res && res.data && res.data.rspCode === '401'){
 
    }else{
      response(res);
    }

  }).catch(function (err) {
    response(err);
  })
}

export default {
  get: function (url, params, headerType, response) {
    return apiAxios('GET', url, params, headerType, response);
  },
  post: function (url, params, headerType, response) {
    return apiAxios('POST', url, params, headerType, response);
  },
  put: function (url, params, response) {
    return apiAxios('PUT', url, params, response);
  },
  delete: function (url, params, response) {
    return apiAxios('DELETE', url, params, response);
  },
};
