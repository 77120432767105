import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
let obj = {
  CNY: '¥',
  USD: '$',
  EUR: '€',
  GBP: '£',
  AUD: 'A$',
  CAD: 'C$',
  PLN: 'zł',
  NZD: 'NZ$',
  CHF: 'CHF',
};
export default new Vuex.Store({
  state: {
    lanageName: '中文',
    langs: [
      {
        code: 'sa',
        name: 'العربية',
        active: true,
      },
      {
        code: 'bn',
        name: 'বাংলা',
        active: false,
      },
      {
        code: 'gb',
        name: 'English',
        active: false,
      },
      {
        code: 'es',
        name: 'Español',
        active: false,
      },
      {
        code: 'ir',
        name: 'فارسی',
        active: false,
      },
      {
        code: 'ph',
        name: 'Filipino',
        active: false,
      },
      {
        code: 'fr',
        name: 'Français',
        active: false,
      },
      {
        code: 'in',
        name: 'हिन्दी',
        active: false,
      },
      {
        code: 'id',
        name: 'Indonesian',
        active: false,
      },
      {
        code: 'jp',
        name: '日本語',
        active: false,
      },
      {
        code: 'my',
        name: 'Malay',
        active: false,
      },
      {
        code: 'br',
        name: 'Português',
        active: false,
      },
      {
        code: 'ru',
        name: 'Русский',
        active: false,
      },
      {
        code: 'th',
        name: 'ไทย',
        active: false,
      },
      {
        code: 'tr',
        name: 'Türkçe',
        active: false,
      },
      {
        code: 'ua',
        name: 'Українська',
        active: false,
      },
      {
        code: 'vn',
        name: 'Tiếng Việt',
        active: false,
      },
      {
        code: 'zh',
        name: '中文',
        active: false,
      },
    ],
    activeLange: '',
    chartData: null,
  },
  getters: {
    chartData(state) {
      return state.chartData;
    },
  },
  mutations: {
    setMobile(state, value) {
      state.isMobile = value;
    },
    setCurrency(state, value) {
      state.currency = value;
      state.currencyVal = obj[value];
    },
    UPDATE_CHART_DATA(state, val) {
      state.chartData = val;
    },
  },
  actions: {
    updateChartData(context, data) {
      context.commit('UPDATE_CHART_DATA', data);
    },
  },
  modules: {},
});
