export default {
    "+{value}% profit": "+{value}%  سود",
    "Selfie verification required": "تأیید سلفی مورد نیاز می باشد",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "به منظور انجام مراحل مربوط به اعتبارسنجی حساب کاربری، از شما درخواست می کنیم که در حالتی که شناسنامه خود را به همراه امضاء و تاریخ ارسال تأییده بر روی یک برگ کاغذ A4 را در دست دارید، یک عکس با وضوح بالا از خود (به صورت سلفی) بگیرید. صورت، بدن و هر دو دست شما باید قابل مشاهده باشند. <br><br> اطلاعات سند باید واضح و خوانا باشد.",
    "Your documents have been checked, now your identity is verified.": "مدارک شما بررسی شده است ، اکنون هویت شما تأیید گردید.",
    "You need fill identity information before verification your profile.": "قبل از تأیید نمایه خود، باید اطلاعات هویت را پر کنید.",
    "Documents verification": "تأیید اسناد",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "تأیید مشخصات به معنای ارائه یک سند رسمی است که هویت مشتری را تأیید می کند. این روش می تواند توسط بخش امنیتی شرکت در هر زمان از شما درخواست شود.",
    "Upload Photo": "بارگذاری عکس",
    "Nickname": "نام اختصاری",
    "First Name": "نام کوچک",
    "Last Name": "نام خانوادگی",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "نام باید دقیقاً مطابق با سند مشخص شود. برای ارائه دادن اطلاعات نادرست، ممکن است حساب بدون هشدار حذف شود.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "نام خانوادگی باید دقیقاً مطابق با سند مشخص شود. برای ارائه دادن اطلاعات نادرست، ممکن است حساب بدون هشدار حذف شود.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": " از این پس امکان تغییر تاریخ تولد نیست. اگر آن را اشتباه ذکر کرده اید ، نمی توانید وجهی را از حساب خود برداشت کنید.",
    "Empty": "خالی",
    "Date of birth": "تاریخ تولد",
    "Unverified": "تأیید نشده",
    "Verified": "تأیید شده",
    "Resend": "ارسال مجدد",
    "Country": "کشور",
    "Address": "نشانی",
    "Change Identity Info": "اطلاعات هویت را تغییر دهید",
    "Identity Info": "اطلاعات هویت",
    "Two-factor authentication on login": "احراز هویت دو مرحله ای در هنگام ورود",
    "Two-factor authentication on withdrawal": "احراز هویت دو مرحله ای در هنگام برداشت",
    "Active": "فعال",
    "Inactive": "غیر فعال",
    "Setup": "فعال سازی",
    "Two-step verification": " تایید هویت دو مرحله ای",
    "Security": "امنیت",
    "Google Authenticator": "احراز هویت گوگل",
    "Scan the QR code": "کد QR را اسکن کنید",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "امنیت شما برای ما را بسیار حائز اهمیت است ، به همین دلیل نیاز به تأیید دو مرحله ای داریم. در در اینجا نحوه تنظیم آن آموزش داده شده است:",
    "Install ": "نصب",
    "Can't scan?": "قادر به اسکن نیستید؟",
    "Click here.": "اینجا کلیک کنید.",
    "Enter this code into Google Authenticator.": "این کد را در احراز هویت گوگل وارد کنید.",
    "Back to QR code.": "بازگشت به کد QR.",
    "Enter the 6-digit code from Authenticator below": "کد 6 رقمی احراز هویت را در زیر وارد کنید",
    "Enter 6-digit code...": "کد 6 رقمی را وارد کنید ...",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "بسار عالی. شما با موفقیت تایید هویت دو مرحله ای را فعال کرده اید. دفعه بعد که وارد سیستم می شوید ، از شما یک کد درخواست می شود.",
    "Code": "کد",
    "contact support": "با پشتیبانی تماس بگیرید",
    "If you ever lose access to your 2-step verification device, please ": "اگر زمانی دسترسی به قسمت تأیید صحت 2 مرحله‌ای خود را از دست دادید، لطفاً",
    "Yes, activate": "بله ، فعال کنید",
    "Cancel": "لغو",
    "Close": "نزدیک",
    "Language": "زبان",
    "Timezone": "منطقه زمانی",
    "Delete Account": "حذف حساب کاربری",
    "Verification Credit / Debit Cards": "کارت های اعتباری / بدهی تأیید",
    "Add New Card": "افزودن کارت جدید",
    "Wait for loading...": "منتظر بارگذاری باشید...",
    "You don’t have any credit / debit cards for verification": "شما هیچ کارت اعتباری / نقدی برای تأیید ندارید",
    "Verificate": "تصدیق",
    "Verification Card": "کارت تأیید",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "لطفاً شناسه خود را (با نشان دادن عکس ، نام و تاریخ تولد) همراه با تصویر روی کارت بانکی خود ( تصویر نشان دهنده شماره ، تاریخ انقضا و نام خود) را بر روی یک برگ A4 خالی قرار دهید. <br> لطفا واژه \"Quotex.io\" را بنویسید و امضای خود را به آن اضافه کنید. از این برگه عکس بگیرید و بارگذاری کنید.",
    "Card:": "کارت:",
    "Type:": "نوع:",
    "Your ID and bank card on an A4 sheet": "شناسه و کارت بانکی شما در یک برگ A4 وجود دارد",
    "Yes, confirm": "بله ، تأیید میکنم",
    "Click or drag the image": "روی تصویر کلیک کرده یا آن را بکشید",
    "Card": "کارت",
    "Documents": "اسناد",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "ابتدا بخش «اطلاعات هویت» را در نمایه خود پر کنید و برای ذخیره تغییرات روی دکمه «تغییر اطلاعات هویت» کلیک نمایید. پس از آن می توانید یک کارت جدید اضافه کنید.",
    "Specify the card details for further operations for depositing and debiting funds.": "مشخصات کارت را برای عملیات بیشتر برای واریز و برداشت وجوه مشخص کنید.",
    "Card number:": "شماره کارت:",
    "Expiry month:": "ماه انقضاء:",
    "Expiry year:": "سال انقضا:",
    "Cardholder name:": "نام دارنده کارت:",
    "Next": "بعدی",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "لطفا امضا و تاریخ فعلی را روی برگه نشان دهید، سند خود را به همراه کارت بانکی خود قرار دهید، عکس بگیرید و در فرم زیر آپلود کنید.",
    "Do you have a two-sided document?": "آیا سند و مدرک دو طرفه دارید؟",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "قسمت پشتی را به عنوان یک فایل جداگانه، همچنین در برگه ای با تاریخ و امضای خود آپلود کنید.",
    "Submit only valid documents": "فقط مدارک معتبر ارسال کنید",
    "Do not edit or crop photo": "عکس را ویرایش یا برش ندهید",
    "Do not upload other people's documents": "مدارک دیگران را آپلود نکنید",
    "Read all": "همه را بخوان",
    "Uploading files": "در حال آپلود فایل ها",
    "For single-sided documents, upload only the front side.": "برای اسناد یک طرفه، فقط قسمت رو را آپلود کنید.",
    "Back": "بازگشت",
    "Add a card": "افزودن کارت",
    "File Requirements": "فایل مورد نیاز",
    "Scanned images are not accepted for card verification": "تصاویر اسکن شده برای تایید کارت پذیرفته نمی شوند",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "ما فقط فایل هایی با پاسپورت معمولی یا شناسه ملی شما می پذیریم (هر دو طرف باید به صورت فایل جداگانه آپلود شوند)",
    "Driving license CANNOT be accepted": "گواهینامه رانندگی قابل قبول نیست",
    "External (foreign/international/travel) passport will not be accepted as well": "پاسپورت خارجی (خارجی / بین المللی / مسافرتی) نیز پذیرفته نخواهد شد",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "اگر سند 2 طرفه است، باید پشت آن را به عنوان یک فایل جداگانه، روی همان برگه کاغذ، با تاریخ فعلی و امضای خود آپلود کنید.",
    "All information in the document and on the card should be clear and readable on the photo": "تمام اطلاعات موجود در سند و روی کارت باید روی عکس واضح و قابل خواندن باشد",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "هم سند و هم کارت شما باید به طور کامل در عکس قرار بگیرند و لبه ها نباید بریده شوند",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "هیچ انعکاس نور یا نقص دیگری در عکس وجود نداشته باشد که خواندن اطلاعات مدرک و کارت را سخت کند.",
    "Expired documents and cards will be rejected automatically": "اسناد و کارت های منقضی شده به طور خودکار رد می شوند",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "اگر نام کارت شما روی آن نیست، باید اسکرین شات صورت حساب بانکی را آپلود کنید که شماره کارت و نام شما را نشان دهد. در برخی موارد، نوشتن نام خود با دست روی همان برگه مجاز است",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "پنهان کردن عناصر مدارک که حاوی داده های مورد نیاز برای تأیید است(نام، تاریخ تولد، عکس) مجاز نمی باشد.",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "مخفی کردن 8 رقم موجود در شماره کارت مجاز است. 4 رقم اول و 4 رقم آخر باید به وضوح قابل مشاهده باشند",
    "CVV code should be hidden at all times, in every case": "کد CVV باید همیشه و در هر موردی پنهان باشد",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "هر گونه ویرایش فایل های شما ممنوع می باشد. فقط عکس های ویرایش نشده و تصاویر اسکن شده قابل پذیرش هستند. ویرایش اطلاعات شخصی در مدارک شما (نام، تاریخ تولد و ...) مجاز نمی باشد",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "عکس باید بخشی از سند باشد (چسبیده یا چاپ شده). عکس های پیوست شده جداگانه پذیرفته نمی شوند",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "آپلود اسناد و کارت‌ها فقط به نام مشخص شده در پروفایل (که حساب شما در آن ثبت شده است) مجاز است.",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "یادآوری می‌کنیم که برای ارسال اطلاعات جعلی/نادرست ممکن است حساب کاربری تا روشن شدن شرایط به حالت تعلیق/مسدود شدن درآید.",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "شرکت این حق را برای خود محفوظ می دارد که در هر زمان از شما بخواهد اسناد اضافی را برای تأیید حساب (از جمله سلفی همراه با سند) ارائه دهید.",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "برای تمام سوالات مربوط به تأیید اسناد، می توانید یک تیکت پشتیبانی در پلت فرم ایجاد کنید (بخش \"راهنما\" اولین دکمه با نماد علامت سوال چشمک زن در سمت چپ ترین پانل است)",
    "Profile": "مشخصات",
    "Deposit": "سپرده",
    "Withdrawal": "برداشت از حساب",
    "Transactions": "معاملات",
    "Trades": "معاملات",
    "Market": "بازار",
    "Analytics": "تجزیه و تحلیل",
    "My current currency": "واحد پول فعلی من",
    "Available for withdrawal": "قابل برداشت است",
    "In the account": "در حساب",
    "Percentage of turnover {percent}%": "درصد گردش مالی {percent}%",
    "Cashback {percent}%": "بازگشت نقدی {percent}%",
    "Currency:": "واحد پول:",
    "Change": "تغییر دادن",
    "Live Account": "حساب زنده",
    "Daily budget limit": "محدودیت بودجه روزانه",
    "The value cannot exceed your total budget": "ارزش نمی تواند از کل بودجه شما تجاوز نماید",
    "Demo Account": "حساب آزمایشی",
    "Apply": "درخواست دادن",
    "Edit": "ویرایش کنید",
    "New value": "مقدار جدید",
    "Confirm": "تایید",
    "Logout": "خروج",
    "No, cancel": "نه، کنسل کن",
    "Exchange Form": "فرم تبادل",
    "My Currency:": "واحد پول من:",
    "New Currency:": "ارز جدید:",
    "You are exchanging": "شما رد و بدل می کنید",
    "You will receive": "دریافت خواهید کرد",
    "Exchange Fee": "هزینه مبادله",
    "Password changed": "رمز عبور تغییر کرد",
    "Old password": "رمز عبور قدیمی",
    "New password": "رمز جدید",
    "Confirm new password": "رمز ورود جدید را تأیید کنید",
    "Change Password": "رمز عبور را تغییر دهید",
    "Confirm password must be equal new password value": "تأیید رمز عبور باید برابر با مقدار رمز عبور جدید باشد",
    "About us": "درباره ما",
    "Support": "پشتیبانی",
    "Help": "کمک",
    "2-step verification": "تأیید هویت دو مرحله ای",
    "Please enter the PIN-code we've just sent to your email": "لطفاً گذرواژه ای را که به ایمیل شما ارسال کرده ایم٬ وارد کنید",
    "Please enter the 6-digit code from your authentication app": "لطفاً کد 6 رقمی را از برنامه احراز هویت خود وارد کنید",
    "The First Name field is required": "فیلد نام الزامی است",
    "The Last Name field is required": "فیلد نام خانوادگی الزامی است",
    "Verification of documents": "تایید مدارک",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "لطفاً یک عکس رنگی یا تصویر اسکن شده از گذرنامه خود به طور معمولی، گواهینامه رانندگی یا کارت شناسایی ملی خود آپلود کنید.",
    "Upload Documents": "بارگذاری اسناد",
    "Why?": "چرا؟",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "آیا می خواهید حذف حساب خود را بدون بازگشت به عقب، تأیید کنید؟ این عمل قابل بازگشت نیست.",
    "Set up 2-step verification": " تنظیم تایید هویت دو مرحله ای",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "متأسفانه نمی توانید فایل ها را در این بخش آپلود کنید. <br> لطفاً به پیام‌های تیم پشتیبانی مراجعه کرده و فایل‌های مورد نیاز را به تیکت پیوست کنید.",
    "The uploaded document does not match the requirements": "سند بارگزاری شده با مقررات مطابقت ندارد",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "مدارک زیر واجد شرایط برای تعین هویت می باشند: شناسنامه، گذرنامه، گذرنامه بین المللی یا گواهینامه رانندگی.",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "تصاویر باید کیفیت بالایی داشته باشند، داده ها باید به راحتی قابل خواندن باشند و لبه های سند نباید بریده باشد.",
    "Balance from {amount}": "تراز از {amount}",
    "Not verified": "تایید نشده است",
    "Clear": "پاک کردن",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "به منظور جلوگیری از محدودیت های خدمات، لطفاً فرآیند تأیید را تکمیل کنید: اطلاعات شخصی خود را پر کنید و اسناد مورد نیاز را بارگذاری کنید.",
    "Get verified": "تایید شود",
    "I got it!": "دریافت کردم!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "تأیید AADHAAR",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "لطفاً یک عکس رنگی از Aadhaar فیزیکی خود آپلود کنید (فقط در سمت جلو با شماره عکس و سند خود).",
    "Send AADHAAR": "ارسال AADHAAR",
    "AADHAAR number:": "شماره AADHAAR:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "لطفاً یک عکس رنگی از Aadhaar فیزیکی خود آپلود کنید (فقط در سمت جلو با شماره عکس و سند خود). ☝ هیچ نسخه الکترونیکی پذیرفته نمی شود.",
    "You have already uploaded {number} files. Please wait until they are checked.": "شما قبلاً فایل {number} را آپلود کرده اید. لطفا صبر کنید تا بررسی شوند.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "لطفاً یک عکس رنگی از سند خود آپلود کنید: برای RG - هر دو طرف باید به عنوان فایل جداگانه آپلود شوند. برای CNH - فقط سمت جلو با عکس شما. سند باید معتبر باشد (کمتر از 10 سال از تاریخ صدور)، و باید شماره CPF شما را نشان دهد. * در صورتی که هیچ CPF در سند شما وجود ندارد - لطفاً یک عکس جداگانه از Comprovante de Inscrição no CPF یا تصویری از صورت حساب بانکی خود که نام و شماره CPF شما را نشان می دهد بارگذاری کنید. ** لطفاً هیچ نسخه الکترونیکی را بارگذاری نکنید. آنها پذیرفته نخواهند شد.",
    "Submit for verification": "برای گرفتن تأیید ارسال نمایید",
    "Settings": "تنظیمات",
    "Please upload a color photograph of the front of your national identity card with your photo.": "لطفا یک عکس رنگی از روی کارت ملی خود را همراه با عکس خود آپلود کنید.",
    "Template": "قالب",
    "Light Mode": "حالت نور",
    "Twilight": "گرگ و میش",
    "Full Night": "شب کامل",
    "Grid's opacity": "تیرگی خطوط",
    "Auto-scrolling": "پیمایش خودکار",
    "Automatic graphic scrolling": "پیمایش گرافیکی خودکار",
    "1-click trade": " تجارت با 1 کلیک",
    "Open trades without confirmation": "معاملات را بدون تأیید باز کنید",
    "Use timezone": "از منطقه زمانی استفاده کنید",
    "Use your timezone data": "از داده های منطقه زمانی خود استفاده کنید",
    "Graph colors": "رنگ نمودارها",
    "Up Trend": "روند رو به بالا",
    "Down Trend": "روند نزولی",
    "Platform": "سکو",
    "Short order label": "لیبل سفارش کوتاه",
    "Use short order element mode": "از حالت عنصر سفارش کوتاه استفاده کنید",
    "Perfomance Mode": "حالت عملکرد",
    "Use optimized rendering for chart and candles": "از رندرینگ بهینه برای نمودارهای شمعی استفاده کنید",
    "Background": "پس زمینه",
    "Choose file": "فایل را انتخاب کنید",
    "Max size — 2 Mb": "حداکثر حجم - 2 مگابایت",
    "Sign up": "ثبت نام",
    "Sign in": "ورود",
    "Budget and limits": "بودجه و محدودیت ها",
    "Daily limit": "محدودیت روزانه",
    "Not set": "تنظیم نشده",
    "Total funds": "مجموع وجوه",
    "Top up": "شارژ کنید",
    "Set up": "برپایی",
    "Set a daily limit on trading to reduce financial risks.": "برای کاهش ریسک های مالی، محدودیت روزانه برای معاملات تعیین کنید.",
    "Limit amount": "مقدار محدود",
    "More": "بیشتر",
    "Leader Board": "هیئت مدیره",
    "Signals": "سیگنالها",
    "Notifications": "اطلاعیه",
    "mark all as read": "همه را علامت بزن به عنوان خوانده شده",
    "No notifications": "بدون اطلاعیه",
    "No more notifications": "دیگر خبری از اعلان نمی باشد",
    "Your position:": "موقعیت تو:",
    "How does this rating work?": "این رتبه بندی چگونه کار می کند؟",
    "of the Day": "روز",
    "Nothing has been found for current query, try another asset name.": "هیچ موردی برای جستجوی فعلی پیدا نشده است، دارایی دیگری را امتحان کنید.",
    "My rating in the Leader Board": "رتبه من در میان پیشتازان",
    "Participants can only be those who trade in live mode": "شرکت کنندگان فقط می توانند کسانی باشند که در حالت زنده تجارت می کنند",
    "Your position depends on the amount of earned money": "موقعیت شما به میزان پول بدست آمده بستگی دارد",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "محاسبه به صورت روزانه و از ساعت 00:00 به وقت محلی تا 23:59 به وقت محلی انجام می شود.",
    "All traders on our platform participate in this rating. Main features of the rating:": "همه معامله گران در پلتفرم ما در این رتبه بندی شرکت می کنند. ویژگی های اصلی رتبه بندی:",
    "How does it work?": "چگونه کار می کند؟",
    "Trading signals": "سیگنال های تجاری",
    "Past signals": "سیگنال های گذشته",
    "What’s it?": "این چیست؟",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "سیگنال تجاری یک دستورالعمل مستقیم برای تجارت نیست، بلکه یک توصیه تحلیلگر است. این عمل نباید جایگزین تحلیل بازار مستقل شود.",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "شما به طور متوسط ​​10 دقیقه فرصت دارید تا از سیگنال تجارت استفاده کنید. پس از این مدت، قیمت ها ممکن است تغییر کرده و تأثیر غیرمنتظره ای در نتیجه تجارت داشته باشند.",
    "Continue": "ادامه دهید",
    "Duration": "مدت زمان",
    "Return back to FAQ": "بازگشت به سوالات متداول",
    "Return back to Help": "بازگشت به راهنما",
    "How to withdraw money from the account?": "چگونه می توان از حساب پول برداشت کرد؟",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "روش برداشت سرمایه بسیار ساده است و  از طریق حساب شخصی شما انجام می شود.",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "روشی که برای واریز حساب انتخاب کرده اید نیز مشابه روشی است که برای برداشت وجه استفاده می کنید (به سوال \"چگونه می توانم واریز کنم؟\" مراجعه کنید).",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "به عنوان مثال ، اگر از طریق سیستم پرداخت ویزا به حساب خود پولی را واریز کرده باشید ، از طریق سیستم پرداخت ویزا نیز می توانید پول برداشت  کنید.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "وقتی نیاز به برداشت مبالغی بسیار زیاد باشد، شرکت ممکن است درخواست احراز هویت کند. احراز هویت تنها در صورت صلاحدید شرکت رخ خواهد داد)، به همین دلیل برای تأیید حقانیت خود، بسیار مهم است که حساب را به صورت شخصی و به نام خود ثبت کنید.",
    "What is the minimum withdrawal amount?": "حداقل میزان برداشت چقدر است؟",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "حداقل مبلغ برداشت از 10 USD برای اکثر سیستم‌های پرداختی شروع می‌شود.<br>برای ارزهای رمزنگاری شده این مبلغ از 50 USD شروع می‌شود (و ممکن است برای ارزهای خاصی مانند بیت‌کوین بیشتر هم باشد).",
    "Do I need to provide any documents to make a withdrawal?": "آیا برای برداشت باید مدارکی ارائه کنم؟",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "معمولاً مستندات اضافی برای برداشت وجوه مورد نیاز نیست. اما این شرکت بنا به صلاحدید خود ممکن است از شما بخواهد با ارسال مستنداتی خاص، اطلاعات شخصی خود را تأیید کنید. معمولاً این کار به منظور جلوگیری از فعالیتهای مربوط به تجارت غیرقانونی، کلاهبرداری مالی و همچنین استفاده از وجوه بدست آمده به صورت غیرقانونی انجام می شود.",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "لیست این گونه مستندات محدود است و فرآیند تهیه این مدارک، زمان و زحمت زیادی را در بر نخواهد داشت.",
    "How long does it take to withdraw funds?": "چه مدت طول می کشد تا برداشت وجوه انجام شود؟",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "به طور متوسط ​​، روند برداشت از یک روز تا پنج روز از تاریخ دریافت درخواست مربوطه از سوی مشتری طول می کشد؛ این مدت به حجم درخواست های پردازش شده همزمان بستگی دارد. این شرکت همیشه سعی می کند همان روزی که درخواست از مشتری دریافت می شود، پرداخت ها را مستقیماً انجام دهد.",
    "Is there any fee for depositing or withdrawing funds from the account?": "آیا وجهی برای واریز یا برداشت وجه از حساب وجود دارد؟",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "خیر. این شرکت هیچ گونه هزینه ای برای واریز وجه و یا عملیات برداشت از شما دریافت نمی کند.",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "با این حال، شایان توجه است که سیستم های پرداخت ممکن است هزینه ای را برای عملیات پرداخت و یا  تبدیل ارزهای داخلی از شما دریافت کنند.",
    "Can I close my account? How to do it?": "آیا می توانم حسابم را ببندم؟ چگونه انجامش بدهیم؟",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "با کلیک روی دکمه \"حذف حساب\" که در پایین صفحه نمایه است، می توانید حساب شخصی خود را حذف کنید.",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "اگر هنگام وارد کردن داده ها به حساب شخصی خود اشتباهی انجام دادم، چگونه می توانم این مشکل را برطرف کنم؟",
    "You have to contact the technical support service on the Company's website and edit the profile.": "شما باید با خدمات پشتیبانی فنی در وب سایت شرکت تماس بگیرید و مشخصات را ویرایش کنید.",
    "What data is required to register on the Company website?": "چه اطلاعاتی برای ثبت نام در وب سایت شرکت مورد نیاز است؟",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "برای کسب درآمد از منابع دیجیتال، ابتدا باید حسابی را باز کنید که به شما امکان انجام معاملات را بدهد. برای این کار باید در وب سایت شرکت ثبت نام کنید.",
    "The registration process is simple and does not take much time.": "مراحل ثبت نام ساده است و زمان زیادی لازم ندارد.",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "پر کردن پرسشنامه در فرم پیشنهادی ضروری است. شما باید اطلاعات زیر را وارد کنید:",
    "name (in English)": "نام (به انگلیسی)",
    "email address (indicate the current, work, address)": "آدرس ایمیل ( یمیل کاری  و ایمیل شخصی)",
    "telephone (with a code, for example, + 44123 ....)": "تلفن (همراه با کدشهر، به عنوان مثال ، ...44123+)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "گذرواژه ای که در آینده برای ورود به سیستم استفاده خواهید کرد (برای به حداقل رساندن خطر دسترسی غیرمجاز به حساب شخصی شما، توصیه می کنیم که یک رمز عبور پیچیده و به صورت ترکیبی از حروف کوچک و بزرگ و اعداد ایجاد کنید. رمز عبور را به هیچ عنوان به کسی ندهید)",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "پس از پر کردن پرسشنامه، روش های مختلفی برای واریز به حساب جهت شروع معاملات به شما ارائه می شود.",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "حساب مشتری با چه ارزی باز می شود؟ آیا می توانم ارز مورد استفاده در حساب مشتری را تغییر دهم؟",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "به طور پیش فرض ، یک حساب تجاری به دلار آمریکا افتتاح می شود. اما برای راحتی خود می توانید چندین حساب با ارزهای مختلف باز کنید.",
    "A list of available currencies can be found on your profile page in your Client's account.": "لیستی از ارزهای موجود را می توانید در صفحه نمایه خود در  بخش حساب مشتری پیدا کنید.",
    "What are digital options?": "گزینه های دیجیتالی چیست؟",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "گزینه - یک ابزار مالی مشتق شده بر اساس هر دارایی پایه (می تواند یک سهام ، جفت ارزی ، نفت و غیره باشد).",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "گزینه های دیجیتالی: گزینه غیر استانداردی است که برای سودآوری هنگام نوسان قیمت دارایی ها و برای مدت زمان مشخصی استفاده می شود.",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "گزینه دیجیتالی، بسته به شرایط توافق شده توسط طرفین معامله، در زمانی که طرفین تعیین می کنند ، یک درآمد ثابت (تفاوت بین درآمد تجارت و قیمت دارایی) یا ضرر  (به میزان ارزش دارایی) را موجب می شود.",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "از آنجا که گزینه دیجیتال از قبل با قیمت ثابت خریداری شده است ، اندازه سود و همچنین میزان ضرر احتمالی ، حتی قبل از معامله مشخص می شوند.",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "ویژگی دیگر این معاملات محدودیت زمانی است. هر گزینه ویژگی خاص خود را دارد (زمان انقضا یا زمان نتیجه گیری).",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "صرف نظر از میزان تغییر در قیمت دارایی پایه (اینکه چقدر  قیمت بالاتر یا کمتر شده است)، در صورت برنده شدن یک گزینه، همیشه یک پرداخت ثابت انجام می شود. بنابراین، خطرات حاصل از معامله شما فقط به مبلغی که گزینه برای آن خریداری شده، محدود می شود.",
    "What is the expiration period of a trade?": "مدت انقضا تجارت چیست؟",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "دوره انقضا زمانی است که پس از آن تجارت انجام شده (بسته شده) در نظر گرفته می شود و نتیجه به طور خودکار بیان می شود.",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "هنگام انجام معامله با گزینه های دیجیتال ، شما به طور مستقل زمان اجرای معامله (1 دقیقه، 2 ساعت، 1 ماه و غیره) را تعیین می کنید.",
    "What is the gist of digital options trading?": "اصل و ریشه معاملات گزینه های دیجیتال چیست؟",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "واقعیت این است که گزینه دیجیتالی ساده ترین نوع ابزار مالی مشتق است. برای کسب درآمد در بازار گزینه های دیجیتال، نیازی به پیش بینی ارزش قیمت یک دارایی در بازار نیست.",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "اصل فرآیند معاملات تنها به صورت یک مسئله ساده و واحد تبدیل می شود و این یعنی قیمت دارایی با اجرای قرارداد افزایش یا کاهش می یابد.",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "مزیت این گزینه ها این است که برای شما اصلاً مهم نیست که قیمت دارایی پایه از لحظه ای که تجارت معامله شروع می شود تا پایان آن، صد واحد یا فقط یک واحد  کم یا زیاد خواهد رفت. برای شما مهم است که فقط جهت حرکت این قیمت را درست تعیین کنید.",
    "If your prognosis is correct, in any case you get a fixed income.": "اگر پیش بینی شما درست باشد، در هر صورت درآمد ثابتی دریافت می کنید.",
    "What are the possible results of the placed trades?": "نتایج احتمالی معاملات انجام شده چیست؟",
    "There are three possible outcomes in the digital options market:": "سه نتیجه ممکن در بازار گزینه های دیجیتال وجود دارد:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "درصورتی که پیش بینی شما در تعیین جهت حرکت قیمت دارایی پایه صحیح باشد، درآمد دریافت می کنید.",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "اگر تا زمان اتمام گزینه ، پیش بینی شما اشتباه بود ، متحمل خساراتی می شوید که به اندازه ارزش دارایی محدود شده اند (یعنی در واقع، نهایتا سرمایه خود را از دست بدهید و بیش از آن بدهکار نخواهید شد).",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "اگر نتیجه تجارت صفر باشد (قیمت دارایی پایه تغییر نکرده باشد، گزینه با قیمتی که خریداری شده است به اتمام رسیده باشد)، سرمایه خود را پس میگیرد. بنابراین، سطح ریسک شما همیشه محدود به میزان ارزش دارایی شما است.",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "آیا پلتفرم تجارتی شما دارای یک حساب آزمایشی می باشد تا روند کار با گزینه های دیجیتال را بدون صرف هزینه شخصی یاد بگیرید؟",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "بله! به منظور توسعه مهارت های معاملاتی و آزمایش توانایی های پلتفرم معاملات شرکت، شما می توانید از یک حساب آزمایشی (رایگان) استفاده کنید. این در واقع نوعی شبیه ساز است که به شما اجازه می دهد تا ابتدا مقداری تمرین کنید و پس از آن به معاملات واقعی بپردازید. چنین حساب آزمایشی همچنین برای معامله گران باتجربه برای ارتقا سطح حرفه ای آنها مناسب است.",
    "The balance of such an account is 10,000 units.": "موجودی چنین حسابی 10،000 واحد است.",
    "What determines profit size?": "چه چیزی اندازه سود را تعیین می کند؟",
    "There are several factors that affect the size of your profit:": "عوامل مختلفی بر میزان سود شما تأثیر می گذارد:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "نقدینگیِ دارایی که در بازار انتخاب کرده اید (هرچه دارایی مورد استفاده در بازار بیشتر شود، سود بیشتری دریافت خواهید کرد)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "زمان تجارت (نقدینگی یک دارایی در صبح و نقدینگی یک دارایی در بعد از ظهر می تواند به طور قابل توجهی متفاوت باشد)",
    "tariffs of a brokerage company": "تعرفه های یک شرکت کارگزاری",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "تغییرات در بازار (وقایع اقتصادی ، تغییر در بخشی از دارایی مالی و غیره)",
    "What are the varieties of digital options?": "انواع گزینه های دیجیتال کدامند؟",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "با انجام معامله اختیاری، باید دارایی پایه مدنظر را انتخاب کنید که گزینه اصلی مدنظر شما باشد. پیش بینی شما بر روی این دارایی پایه انجام می شود.",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "به سادگی، با خرید یک قرارداد دیجیتال، شما در واقع بر روی نوسان قیمت آن دارایی شرط بندی می کنید.",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "دارایی پایه \"کالایی\" است که در هنگام معامله قیمت آن در نظر گرفته می شود. در مورد دارایی پایه در گزینه های دیجیتال ، بیشترین معاملات معمولاً بر روی محصولات متداول بازارها انجام می شود. چهار نوع از این معاملات وجود دارد:",
    "securities (shares of world companies)": "اوراق بهادار (سهام شرکتهای جهانی)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "جفت ارز ( دلار/یورو دلار/پوند و غیره)",
    "raw materials and precious metals (oil, gold, etc.)": "مواد اولیه و فلزات گرانبها (روغن ، طلا و غیره)",
    "indices (S&P 500, Dow, dollar index, etc.)": "شاخص ها (S&P 500 ، داو ، شاخص دلار و غیره)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "هیچ دارایی پایه جهانی وجود ندارد. با انتخاب آن، فقط با دانش، شهود و انواع مختلف اطلاعات تحلیلی و همچنین تجزیه و تحلیل بازار به سمت یک ابزار مالی خاص هدایت می شوید.",
    "What is a trading platform and why is it needed?": "بستر معاملاتی چیست و چرا به آن نیاز است؟",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "بستر معاملاتی - یک مجموعه نرم افزاری است که به مشتری این امکان را می دهد تا معاملات (عملیات) خود را با استفاده از ابزارهای مالی مختلف انجام دهد. همچنین این بستر به اطلاعات مختلفی از جمله ارزش قیمت، موقعیت های بازار در زمان واقعی، اقدامات شرکت و غیره دسترسی دارد.",
    "How to learn quickly how to make money in the digital options market?": "چگونه در فرصت کم می توان یاد گرفت که در بازار گزینه های دیجیتال  کسب  درآمد کرد؟",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "استراتژی های معاملاتی خود را که در آن تعداد معاملات صحیح پیش بینی شده حداکثر است را توسعه دهید و آنها را دنبال کنید",
    "diversify your risks": "تمام تخم مرغ های خود را در یک سبد نچینید.",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "در توسعه استراتژی ها و همچنین جستجوی برای یافتن گزینه های textوع، نظارت بر بازار، مطالعه اطلاعات تحلیلی و آماری که می توان از منابع مختلف به دست آورد،  به شما کمک زیادی می کند (منابع اینترنتی ، مشورت با متخصص ، گوش دادن به تحلیل گران در این زمینه و غیره). یکی از روش های کسب اطلاعات نیز  وب سایت شرکت است.",
    "Is the download of the program to a computer or smartphone required?": "آیا نصب برنامه روی رایانه یا تلفن هوشمند لازم است؟",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "نه ، لازم نیست شما فقط باید در فرم شرکت ارائه شده در وب سایت شرکت ثبت نام کرده و یک حساب شخصی باز کنید.",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "در صورت موفقیت در تجارت، شرکت با چه هزینه ای سود را به مشتری می پردازد؟",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "شرکت با مشتری درآمد کسب می کند. بنابراین ، این شرکت علاقه مند به معاملات سودآور است زیرا در معاملات غیر سود آور شرکت منفعتی نخواهد داشت، به این دلیل که شرکت تنها درصدی از مبلغ پرداختی را برای یک استراتژی تجارت موفق، از مشتری اخذ خواهد کرد.",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "علاوه بر این، معاملات انجام شده توسط مشتری، حجم تجارت شركت را بالا می برد و زمانی که مبالغ  به یك كارگزار یا صرافی منتقل می شود، به نوبه خود این مبالغ در استخر نقدینگی وارد می شود و از این طریق منجر به افزایش نقدینگی بازار می شود.",
    "What is account verification?": "تأیید حساب چیست؟",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "تأیید دیجیتالی به معنای تأیید اطلاعات شخصی مشتری از طریق ارائه مستندات اضافی به شرکت است. شرایط تأیید مشتری تا حد ممکن ساده شده است و لیست مستندات به حداقل رسیده است. به عنوان مثال، شرکت ممکن است موارد زیر را از شما بخواهد:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "یک نسخه اسکن رنگی از اولین صفحه گذرنامه مشتری (صفحه گذرنامه با عکس) ارائه دهید.",
    "identify with the help of a \"selfie\" (photograph of himself)": "شناسایی با کمک \"سلفی\" (عکس به صورت خویش انداز)",
    "confirm the address of registration (residence) of the Client, etc": "آدرس ثبت نام (محل اقامت) مشتری و غیره را ثابت کنید",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "اگر شناسایی کامل مشتری و داده های وارد شده توسط وی امکان پذیر نباشد، شرکت می تواند هرگونه مستنداتی را جهت احراز هویت درخواست کند.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "پس از ارسال نسخه الکترونیکی اسناد به شرکت ، مشتری باید مدتی صبر کند تا داده های ارائه شده تأیید شود.",
    "How to understand that I need to go through account verification?": "چگونه می توان فهمید که به تأیید حساب نیاز دارم یا نه؟",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "در صورت لزوم  جهت گذراندن تأیید، به شما از طریق ایمیل و یا پیام کوتاه اطلاع رسانی خواهد شد.",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "در همان زمان، شرکت از اطلاعات تماسی که در پرسشنامه هنگام ثبت نام مشخص کرده اید (به ویژه آدرس ایمیل و شماره تلفن) استفاده می کند. بنابراین، مراقب باشید و اطلاعات مربوطه و صحیح را ارائه دهید.",
    "How do I know that I successfully passed verification?": "چگونه بفهمم که تأیید صحت را با موفقیت پشت سر گذاشته ام؟",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "در مورد تکمیل مراحل تأیید حساب خود و امکان ادامه عملیات در بستر معاملات شرکت، اطلاع رسانی را از طریق ایمیل و یا پیام کوتاه دریافت خواهید کرد.",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "آیا می توان هنگام ثبت نام در وب سایت ، داده های (جعلی) دیگران را نشان داد؟",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "خیر. مشتری بایست شخصا ثبت نام را در وب سایت شرکت انجام بدهد و اطلاعات کامل و دقیقی راجع به خود در مورد موضوعات درخواست شده در فرم ثبت نام ارائه دهد و این اطلاعات را به روز نگه دارد.",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "در صورت لزوم و برای تشخیص هویت مشتری، شرکت می تواند اسنادی را درخواست کرده یا مشتری را به دفتر خود دعوت کند.",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "اگر اطلاعات وارد شده در قسمت های ثبت نام با اطلاعات اسناد ارسالی مطابقت نداشته باشد، ممکن است نمایه فردی شما مسدود شود.",
    "How long does the verification process take?": "روند تأیید چه مدت طول می کشد؟",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "بیش از 5 (پنج) روز کاری از تاریخ دریافت اسناد درخواستی از جانب شرکت نخواهد بود.",
    "Is there a minimum amount that I can deposit to my account at registration?": "آیا  مبلغ حداقلی برای ثبت نام تعیین شده است که باید آن را به حساب خود واریز کنم؟",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "مزیت پلتفرم معاملات شرکت این است که شما مجبور نیستید مبالغ زیادی را به حساب خود واریز کنید. با سرمایه گذاری مقدار کمی پول می توانید تجارت خود را شروع کنید. حداقل سپرده 10 دلار آمریکا است.",
    "How can I deposit?": "چگونه می توانم واریز کنم؟",
    "It is very easy to do. The procedure will take a couple of minutes.": "انجام دادن این کار خیلی سادست. این روش چند دقیقه طول خواهد کشید.",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "پنجره اجرای تجارت را باز کرده و بر روی دکمه سبز \"سپرده\" در گوشه سمت راست بالای تب، کلیک کنید.",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "همچنین می توانید با کلیک روی دکمه \"سپرده\" در نمایه حساب، وجه مورد نظر را از طریق حساب شخصی خود واریز کنید.",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "پس از انتخاب این گزینه، تعیین روش واریز حساب ضروری است (این شرکت روشهای بسیار مناسبی را ارائه می دهد که همگی در دسترس مشتری است و در حساب فردی وی نمایش داده می شود).",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "در مرحله بعدی، ارزی که میخواهید حساب با آن شارژ شود و نیز ارز فعلی حساب خود را تعیین کنید.",
    "Enter the amount of the deposit.": "مبلغ ودیعه را وارد کنید.",
    "Fill out the form by entering the requested payment details.": "با وارد کردن مشخصات خود، نسبت به پرداخت وجه اقدام فرمایید",
    "Make a payment.": "ایجاد یک پرداخت.",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "آیا لازم است به حساب پلتفرم معاملاتی وجهی را واریز کنم و چند بار نیاز به انجام این کار دارم؟",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "برای کار به صورت دیجیتال باید یک حساب شخصی باز کنید. برای انجام معاملات واقعی، طبیعتا باید به میزان خریدارهای مورد نظر خود، سپرده گذاری کنید.",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "شما فقط با استفاده از حساب آموزش شرکت (حساب آزمایشی) می توانید بدون پرداخت پول نقد تجارت کنید. چنین حسابی رایگان است و برای نشان دادن عملکرد بستر معاملاتی ایجاد شده است. با استفاده از این حساب آزمایشی، می توانید گزینه های دیجیتالی موجود را بررسی و اصول اساسی تجارت را درک کرده و روش ها و استراتژی های مختلف را آزمایش کنید یا شم اقتصادی خود را ارزیابی کنید.",
    "What is the minimum deposit amount?": "حداقل مبلغ ودیعه چقدر است؟",
    "{number} questions": "{number} سوال",
    "Verification": "تایید",
    "Payment": "پرداخت",
    "Payouts": "پرداخت ها",
    "Ask a specialist": "از یک متخصص بپرسید",
    "FAQ": "سوالات متداول",
    "Didn't find an answer to your question?": "جوابی برای سوال خود پیدا نکردید؟",
    "Open knowledge base": "پایگاه دانش آزاد",
    "Contact support": "با پشتیبانی تماس بگیرید",
    "Open FAQ page": "صفحه سوالات متداول را باز کنید",
    "New message": "پیام جدید",
    "Tournaments": "مسابقات",
    "To end": "برای پایان دادن",
    "Until start": "تا شروع",
    "Front side": "مقابل",
    "Back side (if any)": "سمت پشت (در صورت وجود)",
    "Tourna-ments": "مسابقات",
    "Deletion of Account and Personal Data": "حذف اکانت و داده های شخصی",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "با حذف حساب و اطلاعات شخصی خود، دسترسی به حساب خود را در پلتفرم Quotex برای همیشه از دست خواهید داد. به یاد داشته باشید که داده های شما به طور غیرقابل برگشتی حذف می شوند و بازیابی حساب شما در آینده غیرممکن است!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "توجه: لطفاً قبل از حذف حساب خود، تمام معاملات باز و سفارشات در حال انتظار را تکمیل کنید.",
    "Request Deletion": "درخواست حذف",
    "To delete your account, please contact support service": "برای حذف حساب خود، لطفا با خدمات پشتیبانی تماس بگیرید",
    "Two-factor authorization via Email": "مجوز دو مرحله ای از طریق ایمیل",
    "This feature is for experienced users who are familiar with Google Authenticator.": "این ویژگی برای کاربران با تجربه ای است که با مجوز احراز هویت Google آشنایی دارند.",
    "Please note!": "لطفا توجه داشته باشید!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "اگر پس از اتصال به مجوز احراز هویت Google به هر دلیلی دسترسی به آن را از دست بدهید، دسترسی به حساب خود را از دست خواهید داد.",
    "Proceed to setup": "به راه اندازی ادامه دهید",
    "Account protection method": "روش حفاظت از حساب",
    "Here's how to set it up:": "در اینجا نحوه تنظیم آن آمده است:",
    "Please enter the PIN-code we've just sent to your email:": "لطفا پین کدی را که به ایمیل شما فرستادیم وارد نمایید:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "کارت خوب بود. شما با موفقیت تأیید دو مرحله‌ای را از طریق احراز هویت Google فعال کردید. دفعه بعد که وارد سیستم می شوید، از شما یک کد خواسته می شود.",
    "If you ever lose access to your 2-step verification device, please": "اگر هرگز دسترسی به دستگاه تأیید صحت 2 مرحله‌ای خود را از دست دادید، لطفاً",
    "Confirm account deletion": "حذف اکانت را تایید کنید",
    "To delete your account, follow the link in the email sent to": "برای حذف حساب خود، پیوند موجود در ایمیل ارسال شده را دنبال کنید",
    "Image requirements": "الزامات تصویر",
    "Email": "پست الکترونیک",
    "Сonfirmation": "تاییدیه",
    "Change protection method": "تغییر روش حفاظتی",
    "First, enter the code from the Google Authenticator app:": "ابتدا کد را از برنامه احراز هویت Google وارد کنید:",
    "First, enter the PIN-code we've just sent to your email:": "ابتدا کد پین را که به ایمیل شما فرستادیم وارد کنید:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "کارت خوب بود. شما با موفقیت تأیید 2 مرحله ای را از طریق ایمیل فعال کردید.",
    "Receiving codes via Email": "دریافت کدها از طریق ایمیل",
    "Receiving codes via Google Authenticator": "دریافت کدها از طریق احراز هویت Google",
    "Choose a method:": "روشی را انتخاب کنید:",
    "To enter the platform": "برای ورود به سکو",
    "To withdraw funds": "برای برداشت وجوه",
    "day": "روز",
    "days2": "روزها",
    "days5": "روزها",

text:'انتخاب ارز',
test1:'ایمیل',
text2:'لغو تراکنش به',
text3:'کد تخفیف رایگان',
text4:'ما روزانه این پلتفرم را بهبود می‌دهیم تا آن را بهتر و راحت‌تر کنیم.',
text5:'آیا از این پلتفرم خوشتان می‌آید؟ لطفاً به دیگران معرفی کنید!',
text6:'لطفاً نظرات خود را در مورد QUOTEX بگذارید و شما جایزه‌ای برای لغو تراکنش رایگان دریافت خواهید کرد.',
text7:'لینک نظرات خود را به تیم خدمات مشتریان ما ارسال کنید، پس از بررسی، جایزه به شما ارسال می‌شود.',
text8:'حداقل مبلغ واریز',
text9:'حداقل مبلغ برداشت',
text10:'برداشت سریع',
text11:'رایگان',
text12:'تایید نشده',
text13:'ارسال مجدد',
text14:'تایید شرکت شما',
text15:'لغو تراکنش',
text16:'لطفاً کد تخفیف خود را وارد و فعال کنید. شما می‌توانید هر زمان از کد تخفیف استفاده کنید.',
text17:'کد تخفیف:',
text18:'دریافت 30٪ پاداش بر اساس موجودی سپرده',
text19:'ارز کنونی',
text20:'موجودی قابل برداشت',
text21:'در حساب',
text22:'امروز',
text23:'دیروز',
text24:'هفته',
text25:'ماه',
text26:'مجموع خالص معاملات',
text27:'WELCOME یک پاداش 30٪ برای اولین سپرده است.',
text28:'مبلغ سپرده اولیه 100 دلار است. برای دریافت 30٪ پاداش، لطفاً یک روش سپرده‌گذاری انتخاب کنید.',
text29:'کارت بانکی',
text30:'بانک',
text31:'روش پرداخت انتخاب شده:',
text32:'حداقل مبلغ:',
text33:'حداکثر مبلغ:',
text34:'انتخاب روش دیگری',
text35:'اطلاعات پرداخت',
text36:'مبلغ',
text37:'استفاده از پاداش',
text38:'شرایط و ضوابط',
text39:'من کد تخفیف دارم',
text40:'کد تخفیف',
text41:'اعمال کد تخفیف',
text42:'کد تخفیف وارد شده صحیح است. شما 30٪ پاداش دریافت خواهید کرد',
text43:'شما دریافت خواهید کرد٪',
text44:'پاداش:',
text45:'لطفاً تمام سوالات متداول را بیاموزید',
text46:'برای معامله دارایی‌های دیجیتال، باید یک حساب شخصی باز کنید. برای انجام معاملات واقعی، قطعاً باید مبلغی بر اساس قیمت گزینه را واریز کنید.',
text48:'شما دارید {0} کد تخفیف',
text49:'از این کد تخفیف برای لغو معاملات زیان‌ده استفاده کنید',
text50:'شما فقط می‌توانید معاملات غیرحفاظت‌شده که مبلغ آن از حداکثر ارزش کد تخفیف بیشتر نباشد، لغو کنید.',
text51:'وضعیت',
text52:'استفاده',
text53:'شما هنوز هیچ سابقه کد تخفیفی ندارید. می‌توانید با استفاده از دکمه‌های زیر کد تخفیف اضافه کنید.',
text54:'نمایش سوابق',
text55:'ورود کد تخفیف',
text56:'اطلاعات خصوصی',
text57:'خالی',
text58:'نام',
text59:'نام خانوادگی',
text60:'تاریخ تولد',
text61:'کشور',
text62:'ذخیره',
text63:'آدرس',
text64:'تایید فایل',
text65:'قبل از تأیید حساب، باید اطلاعات شخصی را وارد کنید.',
text66:'امنیت',
text67:'تأیید دو مرحله‌ای',
text68:'تغییر رمز عبور',
text69:'رمز عبور فعلی',
text70:'رمز عبور جدید',
text71:'رمز عبور جدید را دوباره وارد کنید',
text72:'حذف حساب',
text73:'تأیید کارت اعتباری یا بانکی',
text74:'افزودن کارت بانکی جدید',
text75:'شما هیچ کارت اعتباری یا بانکی برای تأیید ندارید',
text76:'شما هنوز هیچ سابقه معامله‌ای ندارید.',
text77:'آغازی',
text78:'پایانی',
text79:'اعمال فیلتر',
text80:'قبلی',
text81:'بعدی',
text82:'سود',
text83:'لغو شده',
text84:'حساب',
text85:'هزینه',
text86:'شما می‌توانید وجه را از حساب پلتفرم خود به کارت بانکی یا کیف پول الکترونیکی خود برداشت کنید. شما می‌توانید هر زمان درخواست برداشت کنید. درخواست برداشت شما در 3 روز کاری پردازش خواهد شد.',
text87:'واریز',
text88:'بعضی از درخواست‌های گذشته',
text89:'سابقه برداشت',
text90:'درخواست‌های من',
text91:'درخواست ارسال کنید',
text92:'سوالات متداول',
text93:'شما هنوز هیچ درخواستی ارسال نکرده‌اید',
text94:'در صورت وجود هر گونه سوال، می‌توانید پاسخ‌های خود را در سوالات متداول پیدا کنید.',
text95:'حق تکثیر © 2024 Quotex. تمامی حقوق محفوظ است',
text96:'ورود',
text97:'رمز عبور',
text98:'مرا به خاطر بسپار',
text99:'برگشت به اطلاعات',
text100:'انجام شده',


text104: "پلتفرم نوآوری برای سرمایه گذاری هوشمند",
text105: "ثبت نام کنید و 10000 دلار در یک حساب آزمایشی که در آن تجارت را یاد می گیرید دریافت کنید",
text106: 'ثبت نام',
text107: "باز کردن یک حساب آزمایشی",
text108: 'رابط تجاری راحت',
text109: 'ما ساده‌ترین و راحت‌ترین رابط کاربری را ایجاد کرده‌ایم تا از مهم‌ترین چیزی که تجارت است دور نشوید. ',
text110: "سیگنال داخلی",
text111: 'یک استراتژی معاملاتی را در نظر بگیرید، ما دقیق ترین و مبتکرانه ترین سیگنال ها را تا 87٪ ارائه می دهیم تا به شما کمک کنیم استراتژی موثر خود را توسعه دهید. ',
text112: 'شاخص معاملات',
text113: 'ما سودمندترین شاخص های معاملاتی را جمع آوری کرده ایم. از این شاخص برای افزایش موجودی حساب خود استفاده کنید. ',
text114: 'سرعت عالی',
text115: 'پلتفرم ما از خلاقانه ترین فناوری استفاده می کند و سرعت فوق العاده ای ارائه می دهد. ',
text116: "با یک حساب آزمایشی امتحان کنید",
text117:'برای کسب درآمد بیشتر',
text118: "پیش‌آگهی درستی ایجاد کنید",
text119: 'آیا قیمت صعودی است یا نزولی؟ ',
text120: "باز کردن یک حساب آزمایشی",
text121: 'کلیک کنید',
text122: 'روی یکی از دکمه ها و مشاهده نتایج',
text123: "قیمت افزایش خواهد یافت",
text124: 'قیمت کاهش می یابد',
text125:'در انتظار نتایج...',
text126: "سود شما",
text127: "با عرض پوزش، پیش بینی شما نادرست است",
text128: 'دوباره تلاش کن',
text129: "سود شما",
text130:'تبریک می گویم! پیش بینی شما درست است',
text131: 'شروع به کسب درآمد کنید',
text132: "برنامه تلفن همراه همیشه در دسترس است",
text133: 'برنامه کاربرپسند ما را برای iPhone یا  Android بارگیری کنید و تجارت را شروع کنید! ',
text134: 'زمان در دسترس',
text135: "شروع تجارت",
text136: '3 مرحله',
text137: "باز کردن یک حساب واقعی به صورت رایگان تنها در چند دقیقه",
text138: "معامله در حساب آزمایشی با یک کلیک",
text139: 'تمرین',
text140: 'مهارت های خود را با حساب های آزمایشی و مواد آموزشی بهبود بخشید',
text141: "تدریس خود را با یک حساب آزمایشی شروع کنید",
text142: "به حساب واقعی خود واریز کنید و تجارت را شروع کنید",
text143: "بیش از 410 ابزار، حداقل مبلغ تراکنش 10 دلار است",
text144: "روش سپرده پرش",
text145: 'سؤالات متداول',
text146:'چگونه به آن مسلط شویم؟ ',
text147: '«یک حساب آزمایشی ثبت کنید و با استفاده از این حساب تمرین کنید. حساب دمو همان حساب واقعی است، اما نیازی به سپرده گذاری نیست. ',
text148: 'بررسی یک برداشت چقدر طول می کشد؟ ',
text149: '«به طور متوسط، پردازش برداشت از تاریخ دریافت درخواست کاربر مربوطه بین 1 تا 5 روز طول می کشد و فقط به تعداد درخواست هایی که به طور همزمان پردازش می شوند بستگی دارد. شرکت همیشه در تلاش است تا درخواست کاربر را مستقیماً در تاریخ دریافت آن پرداخت کند. ',
text150:'پلت فرم معاملات چیست؟ چه تاثیری دارد ',
text151: "سکوی معاملاتی یک سیستم نرم افزاری است که کاربران از ابزارهای مالی ارائه شده توسط این سیستم برای انجام معاملات (عملیات) استفاده می کنند و همچنین از ارزش نقل قول، وضعیت بازار لحظه ای، اقدامات شرکت و سایر اطلاعات ارائه شده توسط شرکت استفاده می کنند. ",
text152: 'آیا می توانم از تلفن همراه خود برای انجام تراکنش استفاده کنم؟ ',
text153: 'پلتفرم ما از خلاقانه ترین فناوری استفاده می کند و می تواند در مرورگر هر رایانه یا تلفن هوشمندی باز شود',
text154: 'حداقل مبلغ سپرده چقدر است؟ ',
text155: 'مزیت پلتفرم معاملاتی این شرکت این است که نیازی به واریز مقادیر زیادی پول به حساب خود ندارید. شما می توانید با سرمایه گذاری مقدار کمی پول معامله را شروع کنید. حداقل سپرده 10 دلار است. ',
text156:'آیا هنگام واریز یا برداشت پول از حساب، کارمزدی وجود دارد؟ ',
text157: 'نه. این شرکت هیچ گونه کارمزدی برای عملیاتی مانند واریز حساب یا برداشت وجه دریافت نمی کند. ',
text158:' با این حال، باید توجه داشت که سیستم‌های پرداخت ممکن است کمیسیون دریافت کنند و از نرخ‌های مبادله ارز متفاوت استفاده کنند. ',
text159: "برو به همه سوالات",
text160: 'نقل قول: بستر نوآوری',
text161: "تجارت دارایی دیجیتال",
text162: "یک حساب واقعی باز کنید",
text163: 'شریک',
text164: "حساب دمو",
text165: 'اطلاعات تماس',
text166: 'سوال مهم',
text167: "مسائل مالی",
text168: "تأیید",
text169: "مقررات",
text170: "سیاست حفظ حریم خصوصی",
text171: 'شرایط پرداخت',
text172: "توافقنامه خدمات",
text173: "هشدار خطر",
text174: "قوانین عملیات معاملاتی",
text175: "قوانین عملیاتی غیر تجاری",
text176: `«هشدار ریسک: تجارت در پلتفرم‌های معاملات دارایی دیجیتال و استفاده از ابزارهای تأمین مالی وام می‌تواند خطرات قابل‌توجهی داشته باشد و ممکن است منجر به از دست دادن کامل سرمایه سرمایه‌گذاری شما شود. بیش از توان مالی خود سرمایه گذاری نکنید و باید ریسک های موجود را درک کنید. تجارت با استفاده از ابزارهای تامین مالی قرض گرفته شده برای اکثر سرمایه گذاران مناسب نیست. تجارت بدون استفاده از وجوه قرض‌گرفته شده مانند سهام نیز پرخطر است زیرا قیمت سهام می‌تواند کاهش یا افزایش یابد، که می‌تواند به این معنی باشد که حتی اگر برنده شوید، بسیار کمتر از آنچه سرمایه‌گذاری کرده‌اید، به دست خواهید آورد.
موفقیت گذشته تضمین کننده موفقیت آینده نیست. لطفا قبل از معامله، به تجربه و سطح معاملاتی، اهداف سرمایه گذاری خود توجه کنید و در صورت لزوم با یک مشاور مالی مستقل تماس بگیرید. کاربران باید تعیین کنند که آیا استفاده از خدمات با برند Quotex بر اساس قوانین و مقررات کشور محل اقامت آنها مجاز است یا خیر. `
}