export default {
    "+{value}% profit": "+{value}% profit",
    "Selfie verification required": "Selfie verification required",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.",
    "Your documents have been checked, now your identity is verified.": "Your documents have been checked, now your identity is verified.",
    "You need fill identity information before verification your profile.": "You need fill identity information before verification your profile.",
    "Documents verification": "Documents verification",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.",
    "Upload Photo": "Upload Photo",
    "Nickname": "Nickname",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.",
    "Empty": "Empty",
    "Date of birth": "Date of birth",
    "Unverified": "Unverified",
    "Verified": "Verified",
    "Resend": "Resend",
    "Country": "Country",
    "Address": "Address",
    "Change Identity Info": "Change Identity Info",
    "Identity Info": "Identity Info",
    "Two-factor authentication on login": "Two-factor authentication on login",
    "Two-factor authentication on withdrawal": "Two-factor authentication on withdrawal",
    "Active": "Active",
    "Inactive": "Inactive",
    "Setup": "Setup",
    "Two-step verification": "Two-step verification",
    "Security": "Security",
    "Google Authenticator": "Google Authenticator",
    "Scan the QR code": "Scan the QR code",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:",
    "Install ": "Install ",
    "Can't scan?": "Can't scan?",
    "Click here.": "Click here.",
    "Enter this code into Google Authenticator.": "Enter this code into Google Authenticator.",
    "Back to QR code.": "Back to QR code.",
    "Enter the 6-digit code from Authenticator below": "Enter the 6-digit code from Authenticator below",
    "Enter 6-digit code...": "Enter 6-digit code...",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.",
    "Code": "Code",
    "contact support": "contact support",
    "If you ever lose access to your 2-step verification device, please ": "If you ever lose access to your 2-step verification device, please ",
    "Yes, activate": "Yes, activate",
    "Cancel": "Cancel",
    "Close": "Close",
    "Language": "Language",
    "Timezone": "Timezone",
    "Delete Account": "Delete Account",
    "Verification Credit / Debit Cards": "Credit / debit card verification",
    "Add New Card": "Add New Card",
    "Wait for loading...": "Wait for loading...",
    "You don’t have any credit / debit cards for verification": "You don’t have any credit / debit cards for verification",
    "Verificate": "Verify",
    "Verification Card": "Card verification",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please add current date and your signature.<br> Take a photo and upload it.\n",
    "Card:": "Card:",
    "Type:": "Type:",
    "Your ID and bank card on an A4 sheet": "Your ID and bank card on an A4 sheet",
    "Yes, confirm": "Yes, proceed",
    "Click or drag the image": "Click or drag the image",
    "Card": "Card",
    "Documents": "Documents",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.",
    "Specify the card details for further operations for depositing and debiting funds.": "Specify the card details for further operations for depositing and debiting funds.",
    "Card number:": "Card number:",
    "Expiry month:": "Expiry month:",
    "Expiry year:": "Expiry year:",
    "Cardholder name:": "Cardholder name:",
    "Next": "Next",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.",
    "Do you have a two-sided document?": "Do you have a two-sided document?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "Upload the back side as a separate file, also on a sheet with the date and your signature.",
    "Submit only valid documents": "Submit only valid documents",
    "Do not edit or crop photo": "Do not edit or crop photo",
    "Do not upload other people's documents": "Do not upload other people's documents",
    "Read all": "Read all",
    "Uploading files": "Uploading files",
    "For single-sided documents, upload only the front side.": "For single-sided documents, upload only the front side.",
    "Back": "Back",
    "Add a card": "Add a card",
    "File Requirements": "File Requirements",
    "Scanned images are not accepted for card verification": "Scanned images are not accepted for card verification",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)",
    "Driving license CANNOT be accepted": "Driving license CANNOT be accepted",
    "External (foreign/international/travel) passport will not be accepted as well": "External (foreign/international/travel) passport will not be accepted as well",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature",
    "All information in the document and on the card should be clear and readable on the photo": "All information in the document and on the card should be clear and readable on the photo",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "Both your document and your card must fit into the photo completely the edges should not be cropped",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data",
    "Expired documents and cards will be rejected automatically": "Expired documents and cards will be rejected automatically",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible",
    "CVV code should be hidden at all times, in every case": "CVV code should be hidden at all times, in every case",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)",
    "Profile": "Account",
    "Deposit": "Deposit",
    "Withdrawal": "Withdrawal",
    "Transactions": "Transactions",
    "Trades": "Trades",
    "Market": "Market",
    "Analytics": "Analytics",
    "My current currency": "My current currency",
    "Available for withdrawal": "Available for withdrawal",
    "In the account": "In the account",
    "Percentage of turnover {percent}%": "Percentage of turnover {percent}%",
    "Cashback {percent}%": "Cashback {percent}%",
    "Currency:": "Currency:",
    "Change": "Change",
    "Live Account": "Live Account",
    "Daily budget limit": "Daily budget limit",
    "The value cannot exceed your total budget": "The value cannot exceed your total budget",
    "Demo Account": "Demo Account",
    "Apply": "Apply",
    "Edit": "Edit",
    "New value": "New value",
    "Confirm": "Confirm",
    "Logout": "Logout",
    "No, cancel": "No, go back",
    "Exchange Form": "Exchange Form",
    "My Currency:": "My Currency:",
    "New Currency:": "New Currency:",
    "You are exchanging": "You are exchanging",
    "You will receive": "You will receive",
    "Exchange Fee": "Exchange Fee",
    "Password changed": "Password changed",
    "Old password": "Old password",
    "New password": "New password",
    "Confirm new password": "Confirm new password",
    "Change Password": "Change Password",
    "Confirm password must be equal new password value": "Confirm password must be equal new password value",
    "About us": "About us",
    "Support": "Support",
    "Help": "Help",
    "2-step verification": "2-step verification",
    "Please enter the PIN-code we've just sent to your email": "Please enter the PIN-code we've just sent to your email",
    "Please enter the 6-digit code from your authentication app": "Please enter the 6-digit code from your authentication app",
    "The First Name field is required": "The First Name field is required",
    "The Last Name field is required": "The Last Name field is required",
    "Verification of documents": "Verification of documents",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.",
    "Upload Documents": "Upload Documents",
    "Why?": "Why?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "Do you want to confirm deleting your account without undo? This action cannot be undone.",
    "Set up 2-step verification": "Set up 2-step verification",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.",
    "The uploaded document does not match the requirements": "The uploaded document does not match the requirements",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "The following documents are eligible for identification: ID, passport, international passport or driver's license.",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.",
    "Balance from {amount}": "Balance from {amount}",
    "Not verified": "Not verified",
    "Clear": "Clear",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.",
    "Get verified": "Get verified",
    "I got it!": "I got it!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "AADHAAR Verification",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).",
    "Send AADHAAR": "Send AADHAAR",
    "AADHAAR number:": "AADHAAR number:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.",
    "You have already uploaded {number} files. Please wait until they are checked.": "You have already uploaded {number} files. Please wait until they are checked.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.",
    "Submit for verification": "Submit for verification",
    "Settings": "Settings",
    "Please upload a color photograph of the front of your national identity card with your photo.": "Please upload a color photograph of the front of your national identity card with your photo.",
    "Template": "Template",
    "Light Mode": "Light Mode",
    "Twilight": "Twilight",
    "Full Night": "Full Night",
    "Grid's opacity": "Grid's opacity",
    "Auto-scrolling": "Auto-scrolling",
    "Automatic graphic scrolling": "Automatic graphic scrolling",
    "1-click trade": "1-click trade",
    "Open trades without confirmation": "Open trades without confirmation",
    "Use timezone": "Use timezone",
    "Use your timezone data": "Use your timezone data",
    "Graph colors": "Graph colors",
    "Up Trend": "Up Trend",
    "Down Trend": "Down Trend",
    "Platform": "Platform",
    "Short order label": "Short order label",
    "Use short order element mode": "Use short order element mode",
    "Perfomance Mode": "Perfomance Mode",
    "Use optimized rendering for chart and candles": "Use optimized rendering for chart and candles",
    "Background": "Background",
    "Choose file": "Choose file",
    "Max size — 2 Mb": "Max size — 2 Mb",
    "Sign up": "Sign up",
    "Sign in": "Sign in",
    "Budget and limits": "Budget and limits",
    "Daily limit": "Daily limit",
    "Not set": "Not set",
    "Total funds": "Total funds",
    "Top up": "Top up",
    "Set up": "Set up",
    "Set a daily limit on trading to reduce financial risks.": "Set a daily limit on trading to reduce financial risks.",
    "Limit amount": "Limit amount",
    "More": "More",
    "Leader Board": "Leader Board",
    "Signals": "Signals",
    "Notifications": "Notifications",
    "mark all as read": "mark all as read",
    "No notifications": "No notifications",
    "No more notifications": "No more notifications",
    "Your position:": "Your position:",
    "How does this rating work?": "How does this rating work?",
    "of the Day": "of the Day",
    "Nothing has been found for current query, try another asset name.": "Nothing has been found for current query, try another asset name.",
    "My rating in the Leader Board": "My rating in the Leader Board",
    "Participants can only be those who trade in live mode": "Participants can only be those who trade in live mode",
    "Your position depends on the amount of earned money": "Your position depends on the amount of earned money",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "Calculated every day, from 00:00 UTC to 23:59 UTC",
    "All traders on our platform participate in this rating. Main features of the rating:": "All traders on our platform participate in this rating. Main features of the rating:",
    "How does it work?": "How does it work?",
    "Trading signals": "Trading signals",
    "Past signals": "Past signals",
    "What’s it?": "What’s it?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.",
    "Continue": "Continue",
    "Duration": "Duration",
    "Return back to FAQ": "Go back to FAQ's",
    "Return back to Help": "Go back to Help",
    "How to withdraw money from the account?": "How to withdraw money from the account?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.",
    "What is the minimum withdrawal amount?": "What is the minimum withdrawal amount?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "The minimum withdrawal amount starts from 10 USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).",
    "Do I need to provide any documents to make a withdrawal?": "Do I need to provide any documents to make a withdrawal?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.",
    "How long does it take to withdraw funds?": "How long does it take to withdraw funds?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.",
    "Is there any fee for depositing or withdrawing funds from the account?": "Is there any fee for depositing or withdrawing funds from the account?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "No. The company does not charge any fee for either the deposit or for the withdrawal operations.",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.",
    "Can I close my account? How to do it?": "Can I close my account? How to do it?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "If I made a mistake during entering data into my individual account, how can I fix this?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "You have to contact the technical support service on the Company's website and edit the profile.",
    "What data is required to register on the Company website?": "What data is required to register on the Company website?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.",
    "The registration process is simple and does not take much time.": "The registration process is simple and does not take much time.",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:",
    "name (in English)": "name (in English)",
    "email address (indicate the current, work, address)": "email address (indicate the current, work, address)",
    "telephone (with a code, for example, + 44123 ....)": "telephone (with a code, for example, + 44123 ....)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "After filling out the sign-up form, you will be offered various ways to fund your account for trading.",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "In what currency is the Client's account opened? Can I change the currency of the Client's account?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "By default, a trading account is opened in US dollars.\n\nBut for your convenience,  the currency can be switched at any time in your profile.",
    "A list of available currencies can be found on your profile page in your Client's account.": "A list of available currencies can be found on your profile page in your Client's account.",
    "What are digital options?": "What are digital options?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "Option is a derivative financial instrument based on any underlying asset, such as a stock, a currency pair, oil, etc.",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.",
    "What is the expiration period of a trade?": "What is the expiration period of a trade?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).",
    "What is the gist of digital options trading?": "What is the gist of digital options trading?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.",
    "If your prognosis is correct, in any case you get a fixed income.": "If your prognosis is correct, in any case you get a fixed income.",
    "What are the possible results of the placed trades?": "What are the possible results of the placed trades?",
    "There are three possible outcomes in the digital options market:": "There are three possible outcomes in the digital options market:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.",
    "The balance of such an account is 10,000 units.": "The balance of such an account is 10,000 units.",
    "What determines profit size?": "What determines profit size?",
    "There are several factors that affect the size of your profit:": "There are several factors that affect the size of your profit:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)",
    "tariffs of a brokerage company": "tariffs of a brokerage company",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "changes in the market (economic events, changes in part of a financial asset, etc.)",
    "What are the varieties of digital options?": "What are the varieties of digital options?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:",
    "securities (shares of world companies)": "securities (shares of world companies)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "currency pairs (EUR / USD, GBP / USD, etc.)",
    "raw materials and precious metals (oil, gold, etc.)": "raw materials and precious metals (oil, gold, etc.)",
    "indices (S&P 500, Dow, dollar index, etc.)": "indices (S&P 500, Dow, dollar index, etc.)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "There is no such thing as a universal underlying asset. When choosing it, you can only use your own knowledge, intuition, and various kinds of analytical information, as well as market analysis for a particular financial instrument.",
    "What is a trading platform and why is it needed?": "What is a trading platform and why is it needed?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.",
    "How to learn quickly how to make money in the digital options market?": "How to learn quickly how to make money in the digital options market?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them",
    "diversify your risks": "diversify your risks",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.",
    "Is the download of the program to a computer or smartphone required?": "Is the download of the program to a computer or smartphone required?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "At what expense does the Company pay profit to the Client in case of successful trade?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.",
    "What is account verification?": "What is account verification?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "provide a color scan copy of the first spread of the Client's passport (passport page with photo)",
    "identify with the help of a \"selfie\" (photograph of himself)": "identify with the help of a \"selfie\" (photograph of himself)",
    "confirm the address of registration (residence) of the Client, etc": "confirm the address of registration (residence) of the Client, etc",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.",
    "How to understand that I need to go through account verification?": "How to understand that I need to go through account verification?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "However, the Company uses the contact details that you specified in the registration form (in particular, your email address and phone number). Therefore, be careful to provide relevant and correct information.",
    "How do I know that I successfully passed verification?": "How do I know that I successfully passed verification?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "Is it possible to indicate other people's (fake) data when registering on the website?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.",
    "How long does the verification process take?": "How long does the verification process take?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "No more than 5 (five) business days from the date the Company receives the requested documents.",
    "Is there a minimum amount that I can deposit to my account at registration?": "Is there a minimum amount that I can deposit to my account at registration?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is 10 US dollars.",
    "How can I deposit?": "How can I deposit?",
    "It is very easy to do. The procedure will take a couple of minutes.": "It is very easy to do. The procedure will take a couple of minutes.",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.",
    "Enter the amount of the deposit.": "Enter the amount of the deposit.",
    "Fill out the form by entering the requested payment details.": "Fill out the form by entering the requested payment details.",
    "Make a payment.": "Make a payment.",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "Do I need to deposit the account of the trading platform and how often do I need to do this?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.",
    "What is the minimum deposit amount?": "What is the minimum deposit amount?",
    "{number} questions": "{number} questions",
    "Verification": "Verification",
    "Payment": "Payment",
    "Payouts": "Payouts",
    "Ask a specialist": "Submit a ticket",
    "FAQ": "FAQ",
    "Didn't find an answer to your question?": "Didn't find an answer to your question?",
    "Open knowledge base": "Open the database",
    "Contact support": "Contact support",
    "Open FAQ page": "Open FAQ page",
    "New message": "New message",
    "Tournaments": "Tournaments",
    "To end": "To end",
    "Until start": "Until start",
    "Front side": "Front side",
    "Back side (if any)": "Back side (if any)",
    "Tourna-ments": "Tourna-ments",
    "Deletion of Account and Personal Data": "Deletion of Account and Personal Data",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "NOTE: Please complete all open trades and pending orders before you delete your account.",
    "Request Deletion": "Request Deletion",
    "To delete your account, please contact support service": "To delete your account, please contact support service",
    "Two-factor authorization via Email": "Two-factor authorization via Email",
    "This feature is for experienced users who are familiar with Google Authenticator.": "This feature is for experienced users who are familiar with Google Authenticator.",
    "Please note!": "Please note!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.",
    "Proceed to setup": "Proceed to setup",
    "Account protection method": "Account protection method",
    "Here's how to set it up:": "Here's how to set it up:",
    "Please enter the PIN-code we've just sent to your email:": "Please enter the PIN-code we've just sent to your email:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.",
    "If you ever lose access to your 2-step verification device, please": "If you ever lose access to your 2-step verification device, please",
    "Confirm account deletion": "Confirm account deletion",
    "To delete your account, follow the link in the email sent to": "To delete your account, follow the link in the email sent to",
    "Image requirements": "Image requirements",
    "Email": "Email",
    "Сonfirmation": "Сonfirmation",
    "Change protection method": "Change protection method",
    "First, enter the code from the Google Authenticator app:": "First, enter the code from the Google Authenticator app:",
    "First, enter the PIN-code we've just sent to your email:": "First, enter the PIN-code we've just sent to your email:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "Nice work. You've successfully enabled 2-step verification via Email.",
    "Receiving codes via Email": "Receiving codes via Email",
    "Receiving codes via Google Authenticator": "Receiving codes via Google Authenticator",
    "Choose a method:": "Choose a method:",
    "To enter the platform": "To enter the platform",
    "To withdraw funds": "To withdraw funds",
    "day": "day",
    "days2": "days",
    "days5": "days",



text: 'Select currency',
text1: 'Email',
text2: 'Cancel transaction',
text3: 'Free promo code',
text4: 'We are constantly improving the platform to make it more convenient.',
text5: 'Do you like this platform? Introduce it to others!',
text6: 'Please leave your comments about QUOTEX, and you will receive a reward for free transaction cancellations.',
text7: 'Send your comment link to our customer support team, and after reviewing it, we will send you this reward.',
text8: 'Minimum deposit amount',
text9: 'Minimum withdrawal amount',
text10: 'Fast withdrawal',
text11: 'Free',
text12: 'Not verified',
text13: 'Resend',
text14: 'Confirm your participation',
text15: 'Cancel transaction',
text16: 'Quickly enter and activate your promo code now. You can use the promo code anytime.',
text17: 'Promo code:',
text18: 'Get a 30% bonus based on your deposit balance',
text19: 'Current currency',
text20: 'Available balance for withdrawal',
text21: 'In account',
text22: 'Today',
text23: 'Yesterday',
text24: 'Week',
text25: 'Month',
text26: 'Net transaction amount',
text27: 'WELCOME is a 30% bonus for the first deposit.',
text28: 'First deposit amount is $100. To get a 30% bonus, choose a deposit method.',
text29: 'Bank card',
text30: 'Bank',
text31: 'Selected payment method:',
text32: 'Minimum amount:',
text33: 'Maximum amount:',
text34: 'Select another method',
text35: 'Payment information',
text36: 'Amount',
text37: 'Use bonus',
text38: 'Terms and conditions',
text39: 'I have a promo code',
text40: 'Promo code',
text41: 'Apply promo code',
text42: 'The promo code you entered is correct. You will receive a 30% bonus.',
text43: 'You will receive',
text44: '',
text45: 'Learn all FAQs',
text46: 'To trade digital assets, you need to open a personal account. To make real trades, you definitely need to deposit an amount according to the option price.',
text48: 'You have {0} promo codes',
text49: 'Use this promo code to cancel losing transactions',
text50: 'You can only cancel unhedged transactions with an amount not exceeding the maximum value of the promo code.',
text51: 'Status',
text52: 'Use',
text53: 'You have no promo code history. You can add a promo code using the buttons below.',
text54: 'Show history',
text55: 'Enter promo code',
text56: 'Privacy information',
text57: 'Empty',
text58: 'Name',
text59: 'Surname',
text60: 'Date of Birth',
text61: 'Country',
text62: 'Save',
text63: 'Address',
text64: 'Document verification',
text65: 'Personal information needs to be provided before verifying the account.',
text66: 'Security',
text67: 'Two-step verification',
text68: 'Change password',
text69: 'Old password',
text70: 'New password',
text71: 'Re-enter new password',
text72: 'Log out of account',
text73: 'Credit card or debit card verification',
text74: 'Add new bank card',
text75: 'You have no credit cards or debit cards that need verification',
text76: 'You have no trading history.',
text77: 'Beginning',
text78: 'Expiration',
text79: 'Apply filter',
text80: 'Previous',
text81: 'Next',
text82: 'Profit',
text83: 'Cancelled',
text84: 'Account',
text85: 'Fee',
text86: 'You can withdraw funds from your platform account to the credit card or e-wallet you used to deposit funds. You can request a withdrawal at any time. Your withdrawal requests will be processed within 3 business days.',
text87: 'Deposit funds',
text88: 'Some previous requests',
text89: 'Withdrawal history',
text90: 'My requests',
text91: 'Submit request',
text92: 'FAQs',
text93: 'You have no submitted requests',
text94: 'If you have any questions, you can find answers in the FAQs.',
text95: 'Copyright © 2024 Quotex. All rights reserved',
text96: 'Login',
text97: 'Password',
text98: 'Remember me',
text99: 'Back to information',
text100: 'Completed',

text104: 'An Innovative Platform for Smart Investments',
text105: 'Register and get $10,000 on a simulated trading account for learning.',
text106: 'Sign Up',
text107: 'Open a simulated account',
text108: 'Convenient Trading Interface',
text109: `We have created the simplest and most convenient interface so you won't deviate from what matters most: trading.`,
text110: 'Built-in Signals',
text111: 'Consider trading strategies, we provide the most accurate and innovative signals up to 87% to assist you in formulating your own effective strategy.',
text112: 'Trading Indicators',
text113: 'We have collected the most useful trading indicators. Use this indicator to increase your account balance.',
text114: 'Perfect Speed',
text115: 'Our platform adopts the most innovative technology and provides tremendous speed.',
text116: 'Try it out with a simulated account',
text117: 'To earn more money,',
text118: 'Make the right forecast',
text119: 'Will the price rise or fall?',
text120: 'Open a simulated account',
text121: 'Click',
text122: 'on one of the buttons, then see the results',
text123: 'Price will rise',
text124: 'Price will fall',
text125: 'Waiting for the result...',
text126: 'Your profit',
text127: 'Sorry, your prediction result is incorrect',
text128: 'Try again',
text129: 'Your profit',
text130: 'Congratulations! Your prediction result is correct',
text131: 'Start making money',
text132: 'Mobile app available anytime',
text133: 'Download our iPhone or Android user-friendly app and start trading!',
text134: 'Available hours',
text135: 'Start Trading',
text136: '3 steps',
text137: 'Open a real account for free in just a few minutes',
text138: 'One-click trading on simulated accounts',
text139: 'Practice',
text140: 'Improve your skills with simulated accounts and training materials',
text141: 'Start your learning with a simulated account',
text142: 'Deposit into your real account and start trading',
text143: 'Over 410 tools, with a minimum trade amount of $10',
text144: 'Go to deposit methods',
text145: 'FAQs',
text146: 'How to master?',
text147: 'Register for a simulated account and practice with it. Simulated accounts are the same as real accounts, but no deposit is required.',
text148: 'How long does it take to process withdrawals?',
text149: `On average, the withdrawal process takes 1 to 5 days from the date of receiving the corresponding user request, depending solely on the number of requests being processed simultaneously. The company always strives to pay directly from the date of receiving the user's request.`,
text150: `What is a trading platform? What is its function?`,
text151: `A trading platform is a software system where users trade (operate) financial instruments provided by this system, and also use information such as the company's quote value, real-time market situation, and company actions.`,
text152: 'Can I trade with my phone?',
text153: 'Our platform adopts the most innovative technology and can be accessed through any computer or smartphone browser.',
text154: 'What is the minimum deposit amount?',
text155: `The advantage of the company's trading platform is that you do not need to deposit large sums into your account. You can start trading with a small investment. The minimum deposit amount is $10.`,
text156: 'Are there any commissions for account deposits or withdrawals?',
text157: 'No. The company does not charge any commissions for account deposits or fund withdrawals.',
text158: 'However, it must be noted that payment systems may charge commissions and use different currency exchange rates.',
text159: 'Go to all questions',
text160: 'Quotex: Innovative Platform',
text161: 'Digital Asset Trading',
text162: 'Open a real account',
text163: 'Partners',
text164: 'Simulated account',
text165: 'Contact Information',
text166: 'Important Questions',
text167: 'Financial Issues',
text168: 'Verification',
text169: 'Regulations',
text170: 'Privacy Policy',
text171: 'Payment Terms',
text172: 'Service Agreement',
text173: 'Risk Warning',
text174: 'Trading Rules',
text175: 'Non-trading Rules',
text176: 'Risk Warning: Trading on a digital asset trading platform and using leverage tools for trading can involve significant risks and may result in the complete loss of your invested capital. Do not invest more than you can afford, and you should understand the risks involved. Using leverage tools for trading is not suitable for most investors. Not trading with leverage tools such as stocks also carries risks, as stock prices can both decrease and increase, which may mean that even if you win, the amount you can gain is much lower than what you invest. Past success does not guarantee future success. Before trading, please consider your trading experience and level, investment objectives, and if necessary, consult an independent financial advisor. Users should determine whether the use of services under the Quotex brand is allowed according to the laws and regulations of their country of residence.'
}