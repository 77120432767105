export default {
    "+{value}% profit": "+{value}% keuntungan",
    "Selfie verification required": "Dibutuhkan verifikasi swafoto",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "Untuk melakukan langkah verifikasi akun yang dibutuhkan, mohon kirimkan foto diri Anda (swafoto) dalam resolusi tinggi, di mana Anda memegang dokumen identitas dengan tanggal saat ini dan tanda tangan Anda di selembar kertas. Wajah, badan, dan kedua tangan Anda harus terlihat.<br><br> Detail dokumen harus terlihat jelas dan dapat dibaca.",
    "Your documents have been checked, now your identity is verified.": "Dokumen Anda telah diperiksa. Identitas Anda telah diverifikasi.",
    "You need fill identity information before verification your profile.": "Anda harus mengisi informasi identitas sebelum memverifikasi profil.",
    "Documents verification": "Verifikasi dokumen",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "Verifikasi profil merujuk pada pengiriman dokumen resmi yang mengesahkan identitas Klien. Prosedur ini dapat diminta oleh departemen keamanan Perusahaan kapan pun.",
    "Upload Photo": "Unggah Foto",
    "Nickname": "Nama panggilan",
    "First Name": "Nama Depan",
    "Last Name": "Nama Belakang",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Nama harus sama seperti yang tercantum dalam dokumen. Jika memberikan informasi yang salah, akun dapat dihapus tanpa peringatan.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Nama belakang harus sama seperti yang tercantum dalam dokumen. Jika memberikan informasi yang salah, akun dapat dihapus tanpa peringatan.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "Anda tidak dapat mengganti tanggal ulang tahun lagi. Jika salah memilih tanggal, Anda tidak akan dapat menarik dana dari akun.\n",
    "Empty": "Kosong",
    "Date of birth": "Tanggal lahir",
    "Unverified": "Tidak terverifikasi",
    "Verified": "Terverifikasi",
    "Resend": "Kirim ulang",
    "Country": "Negara",
    "Address": "Alamat",
    "Change Identity Info": "Ubah Info Identitas",
    "Identity Info": "Info Identitas",
    "Two-factor authentication on login": "Autentikasi dua faktor saat masuk",
    "Two-factor authentication on withdrawal": "Autentikasi dua faktor saat penarikan",
    "Active": "Aktif",
    "Inactive": "Tidak aktif",
    "Setup": "Pengaturan",
    "Two-step verification": "Verifikasi dua langkah",
    "Security": "Keamanan",
    "Google Authenticator": "Google Authenticator",
    "Scan the QR code": "Pindai kode QR",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Kami sangat memperhatikan keamanan Anda. Itulah mengapa kami membutuhkan verifikasi 2 langkah. Inilah cara mengaturnya:",
    "Install ": "Instal",
    "Can't scan?": "Tidak dapat memindai?",
    "Click here.": "Klik di sini.",
    "Enter this code into Google Authenticator.": "Masukkan kode ini di Google Authenticator.",
    "Back to QR code.": "Kembali ke kode QR.",
    "Enter the 6-digit code from Authenticator below": "Masukkan kode 6 digit dari Authenticator di bawah ini",
    "Enter 6-digit code...": "Masukkan kode 6 digit...",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "Bagus. Anda sudah berhasil mengaktifkan verifikasi 2 langkah. Saat masuk lain kali, Anda akan diminta memasukkan kode.",
    "Code": "Kode",
    "contact support": "hubungi dukungan",
    "If you ever lose access to your 2-step verification device, please ": "Jika Anda kehilangan akses ke perangkat verifikasi 2 langkah,",
    "Yes, activate": "Ya, aktifkan",
    "Cancel": "Batalkan",
    "Close": "Tutup",
    "Language": "Bahasa",
    "Timezone": "Zona waktu",
    "Delete Account": "Hapus Akun",
    "Verification Credit / Debit Cards": "Verifikasi Kartu Kredit / Debit",
    "Add New Card": "Tambahkan Kartu Baru",
    "Wait for loading...": "Mohon tunggu…",
    "You don’t have any credit / debit cards for verification": "Anda tidak punya kartu kredit / debit untuk verifikasi",
    "Verificate": "Verifikasi",
    "Verification Card": "Verifikasi Kartu",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "Letakkan ID Anda (yang menampilkan foto, nama, dan tanggal lahir) beserta bagian depan kartu bank Anda (yang menampilkan angka, tanggal kedaluwarsa, dan nama) di selembar kertas A4 kosong.<br> Tuliskan \"Quotex.io\" dan tambahkan tanda tangan Anda.<br> Ambil fotonya dan unggah.",
    "Card:": "Kartu:",
    "Type:": "Jenis:",
    "Your ID and bank card on an A4 sheet": "ID dan kartu bank Anda di selembar kertas A4",
    "Yes, confirm": "Ya, konfirmasi",
    "Click or drag the image": "Klik atau seret gambar",
    "Card": "Kartu",
    "Documents": "Dokumen",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "Pertama, isilah bagian \"Info Identitas\" dalam Profil Anda dan klik tombol \"Ubah Info Identitas\" untuk menyimpan perubahan. Setelah itu, Anda akan dapat menambahkan kartu baru.",
    "Specify the card details for further operations for depositing and debiting funds.": "Berikan detail kartu untuk operasi deposit dan debit dana lebih lanjut.",
    "Card number:": "Nomor kartu:",
    "Expiry month:": "Bln berakhir:",
    "Expiry year:": "Thn berakhir:",
    "Cardholder name:": "Nama pemegang kartu:",
    "Next": "Selanjutnya",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "Tambahkan tanda tangan Anda dan tanggal saat ini di lembar tersebut, letakkan dokumen bersama dengan kartu bank Anda, ambil fotonya, lalu unggah dalam formulir di bawah ini.",
    "Do you have a two-sided document?": "Memiliki dokumen dengan dua sisi?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "Unggah bagian belakang dokumen sebagai berkas terpisah, juga di selembar kertas dengan tanggal dan tanda tangan Anda.",
    "Submit only valid documents": "Hanya kirimkan dokumen yang valid",
    "Do not edit or crop photo": "Jangan edit atau potong foto",
    "Do not upload other people's documents": "Jangan unggah dokumen orang lain",
    "Read all": "Baca semua",
    "Uploading files": "Mengunggah berkas",
    "For single-sided documents, upload only the front side.": "Untuk dokumen satu sisi, cukup unggah sisi depannya.",
    "Back": "Kembali",
    "Add a card": "Tambahkan kartu",
    "File Requirements": "Persyaratan Berkas",
    "Scanned images are not accepted for card verification": "Hasil pindaian tidak dapat digunakan untuk verifikasi kartu",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "Kami hanya menerima berkas berupa KTP atau paspor (kedua sisi harus diunggah sebagai berkas terpisah)",
    "Driving license CANNOT be accepted": "SIM TIDAK dapat digunakan",
    "External (foreign/international/travel) passport will not be accepted as well": "Paspor eksternal (luar negeri/internasional/perjalanan) juga tidak dapat digunakan",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "Jika dokumen memiliki dua sisi, Anda harus mengunggah bagian belakangnya sebagai berkas terpisah, di selembar kertas yang sama, dengan tanggal saat ini dan tanda tangan Anda",
    "All information in the document and on the card should be clear and readable on the photo": "Semua informasi dalam dokumen dan kartu harus jelas dan dapat dibaca di foto",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "Dokumen dan kartu Anda harus terlihat seluruhnya di foto, dan ujungnya tidak boleh terpotong",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "Tidak boleh ada pantulan cahaya atau cacat foto lainnya yang dapat menyulitkan pembacaan data dokumen dan kartu",
    "Expired documents and cards will be rejected automatically": "Dokumen dan kartu yang kedaluwarsa akan ditolak secara otomatis",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "Jika kartu Anda tidak mencantumkan nama, Anda harus mengunggah tangkapan layar dari laporan bank yang menunjukkan nomor kartu dan nama Anda. Dalam beberapa kasus, Anda boleh menuliskan nama secara manual di lembar kertas yang sama",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "Anda tidak boleh menyembunyikan bagian dokumen apa pun yang berisi data yang dibutuhkan untuk verifikasi (nama, tanggal lahir, foto)",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "Anda boleh menyembunyikan 8 digit yang ada dalam nomor kartu. Namun, 4 digit pertama dan 4 digit terakhir harus terlihat dengan jelas",
    "CVV code should be hidden at all times, in every case": "Kode CVV harus disembunyikan setiap saat",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "Anda dilarang mengedit berkas. Hanya foto dan hasil pindaian yang tidak diedit yang dapat diterima. Anda dilarang mengedit informasi pribadi dalam dokumen Anda (nama, tanggal lahir, dan sebagainya)",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "Foto harus merupakan bagian dari dokumen (ditempel atau dicetak). Foto yang terpisah tidak akan diterima",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "Anda hanya boleh mengunggah dokumen dan kartu dengan nama yang sesuai dengan profil Anda (yang tercantum dalam akun)",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "Harap diingat bahwa mengirimkan informasi palsu dapat menyebabkan akun Anda ditangguhkan atau diblokir hingga semuanya diklarifikasi",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "Perusahaan berhak meminta Anda untuk memberikan dokumen tambahan apa pun untuk verifikasi akun (termasuk swafoto Anda dengan dokumen) kapan pun",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "Untuk semua pertanyaan terkait verifikasi dokumen, Anda dapat membuat tiket dukungan di platform (di bagian \"Bantuan\", atau tombol pertama dengan ikon tanda tanya yang berkedip, di panel paling kiri)",
    "Profile": "Profil",
    "Deposit": "Deposit",
    "Withdrawal": "Penarikan",
    "Transactions": "Transaksi",
    "Trades": "Trade",
    "Market": "Pasar",
    "Analytics": "Analitik",
    "My current currency": "Mata uang saya saat ini",
    "Available for withdrawal": "Dapat ditarik",
    "In the account": "Dalam akun",
    "Percentage of turnover {percent}%": "Persentase turnover {percent}%",
    "Cashback {percent}%": "Cashback {percent}%",
    "Currency:": "Mata uang:",
    "Change": "Ubah",
    "Live Account": "Akun Live",
    "Daily budget limit": "Batas anggaran harian",
    "The value cannot exceed your total budget": "Nilai ini tidak boleh melebihi total anggaran Anda",
    "Demo Account": "Akun Demo",
    "Apply": "Gunakan",
    "Edit": "Edit",
    "New value": "Nilai baru",
    "Confirm": "Konfirmasi",
    "Logout": "Keluar",
    "No, cancel": "Tidak, batalkan",
    "Exchange Form": "Formulir Pertukaran",
    "My Currency:": "Mata Uang Saya:",
    "New Currency:": "Mata Uang Baru:",
    "You are exchanging": "Anda sedang bertukar",
    "You will receive": "Anda akan menerima",
    "Exchange Fee": "Biaya Pertukaran",
    "Password changed": "Kata sandi diubah",
    "Old password": "Kata sandi lama",
    "New password": "Kata sandi baru",
    "Confirm new password": "Konfirmasi kata sandi baru",
    "Change Password": "Ubah Kata Sandi",
    "Confirm password must be equal new password value": "Konfirmasi kata sandi harus sama dengan kata sandi baru",
    "About us": "Tentang kami",
    "Support": "Dukungan",
    "Help": "Bantuan",
    "2-step verification": "Verifikasi 2 langkah",
    "Please enter the PIN-code we've just sent to your email": "Masukkan kode PIN yang baru kami kirimkan ke email Anda",
    "Please enter the 6-digit code from your authentication app": "Masukkan kode 6 digit dari aplikasi autentikasi Anda",
    "The First Name field is required": "Kolom Nama Depan wajib diisi",
    "The Last Name field is required": "Kolom Nama Belakang wajib diisi",
    "Verification of documents": "Verifikasi dokumen",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "Harap unggah foto berwarna atau hasil pemindaian paspor, SIM, atau KTP Anda.",
    "Upload Documents": "Unggah Dokumen",
    "Why?": "Mengapa?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "Apakah Anda ingin mengonfirmasi penghapusan akun tanpa pembatalan? Tindakan ini tidak dapat dibatalkan.",
    "Set up 2-step verification": "Atur verifikasi 2 langkah",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "Maaf, Anda tidak dapat mengunggah berkas ke bagian ini. <br> Bacalah pesan dari tim Dukungan kami dan lampirkan berkas yang dibutuhkan dalam tiket.",
    "The uploaded document does not match the requirements": "Dokumen yang diunggah tidak sesuai dengan persyaratan",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "Dokumen berikut dapat digunakan untuk identifikasi: KTP, paspor, paspor internasional, atau SIM.",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "Gambar harus berkualitas tinggi, data harus mudah dibaca, dan ujung dokumen tidak boleh terpotong.",
    "Balance from {amount}": "Saldo dari {amount}",
    "Not verified": "Tidak terverifikasi",
    "Clear": "Hapus",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "Untuk menghindari pembatasan layanan, selesaikan proses verifikasi. Isilah data pribadi Anda dan unggah dokumen yang diperlukan.",
    "Get verified": "Lakukan verifikasi",
    "I got it!": "Mengerti!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "Verifikasi AADHAAR",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "Harap unggah foto berwarna dari Aadhaar fisik Anda (hanya bagian depan yang menampilkan foto dan nomor dokumen Anda).",
    "Send AADHAAR": "Kirim AADHAAR",
    "AADHAAR number:": "Nomor AADHAAR:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "Harap unggah foto berwarna dari Aadhaar fisik Anda (hanya bagian depan yang menampilkan foto dan nomor dokumen Anda). ☝ Kami tidak menerima versi elektronik.",
    "You have already uploaded {number} files. Please wait until they are checked.": "Anda telah mengunggah {number} berkas. Harap tunggu hingga berkas selesai diperiksa.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "Harap unggah foto berwarna dari dokumen Anda: Untuk RG - kedua sisi harus diunggah dalam berkas terpisah. Untuk CNH - hanya bagian depan yang menampilkan foto Anda. Dokumen harus masih berlaku (kurang dari 10 tahun sejak tanggal penerbitan) dan harus menampilkan nomor CPF Anda. *Jika tidak ada CPF dalam dokumen Anda, harap unggah foto terpisah dari Comprovante de Inscrição no CPF, atau tangkapan layar dari laporan bank yang menampilkan nama dan nomor CPF Anda. **Jangan mengunggah versi elektronik karena tidak akan diterima.",
    "Submit for verification": "Kirim untuk verifikasi",
    "Settings": "Pengaturan",
    "Please upload a color photograph of the front of your national identity card with your photo.": "Harap unggah foto berwarna dari bagian depan kartu identitas Anda yang menampilkan foto Anda.",
    "Template": "Templat",
    "Light Mode": "Mode Terang",
    "Twilight": "Biru senja",
    "Full Night": "Malam",
    "Grid's opacity": "Opasitas grid",
    "Auto-scrolling": "Gulir otomatis",
    "Automatic graphic scrolling": "Gulir grafik otomatis",
    "1-click trade": "Trade 1 klik",
    "Open trades without confirmation": "Buka trade tanpa konfirmasi",
    "Use timezone": "Gunakan zona waktu",
    "Use your timezone data": "Gunakan data zona waktu Anda",
    "Graph colors": "Warna grafik",
    "Up Trend": "Tren Naik",
    "Down Trend": "Tren Turun",
    "Platform": "Platform",
    "Short order label": "Label order short",
    "Use short order element mode": "Gunakan mode elemen order short",
    "Perfomance Mode": "Mode Performa",
    "Use optimized rendering for chart and candles": "Gunakan rendering yang dioptimasi untuk grafik dan lilin",
    "Background": "Latar belakang",
    "Choose file": "Pilih berkas",
    "Max size — 2 Mb": "Ukuran maks — 2 Mb",
    "Sign up": "Daftar",
    "Sign in": "Masuk",
    "Budget and limits": "Anggaran dan batas",
    "Daily limit": "Batas harian",
    "Not set": "Tidak diatur",
    "Total funds": "Total dana",
    "Top up": "Top up",
    "Set up": "Atur",
    "Set a daily limit on trading to reduce financial risks.": "Atur batas harian trading untuk mengurangi risiko keuangan.",
    "Limit amount": "Batasi jumlah",
    "More": "Lainnya",
    "Leader Board": "Papan Peringkat",
    "Signals": "Sinyal",
    "Notifications": "Notifikasi",
    "mark all as read": "tandai semua sebagai sudah dibaca",
    "No notifications": "Tidak ada notifikasi",
    "No more notifications": "Tidak ada notifikasi lain",
    "Your position:": "Posisi Anda:",
    "How does this rating work?": "Bagaimana cara kerja rating?",
    "of the Day": "Hari Ini",
    "Nothing has been found for current query, try another asset name.": "Tidak ada hasil untuk query ini. Cobalah nama aset yang lain.",
    "My rating in the Leader Board": "Rating saya di Papan Peringkat",
    "Participants can only be those who trade in live mode": "Partisipan adalah pengguna yang melakukan trading dalam mode live",
    "Your position depends on the amount of earned money": "Posisi Anda bergantung pada jumlah uang yang dihasilkan",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "Dihitung setiap hari, dari 00.00 UTC hingga 23.59 UTC",
    "All traders on our platform participate in this rating. Main features of the rating:": "Semua trader di platform kami berpartisipasi dalam rating ini. Fitur-fitur utama rating:",
    "How does it work?": "Bagaimana cara kerjanya?",
    "Trading signals": "Sinyal trading",
    "Past signals": "Sinyal lampau",
    "What’s it?": "Apa itu?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "Sinyal trading bukanlah petunjuk langsung untuk melakukan trading, tetapi merupakan rekomendasi analis. Sinyal trading sebaiknya tidak menggantikan analisis pasar independen.",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "Anda punya waktu rata-rata 10 menit untuk memanfaatkan sinyal trading. Setelah itu, quote dapat berubah dan berdampak tak terduga pada hasil trading.",
    "Continue": "Lanjutkan",
    "Duration": "Durasi",
    "Return back to FAQ": "Kembali ke FAQ",
    "Return back to Help": "Kembali ke Bantuan",
    "How to withdraw money from the account?": "Bagaimana cara menarik uang dari akun?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "Prosedur penarikan modal sangatlah mudah dan dilakukan melalui akun individu Anda.",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "Metode yang telah Anda pilih untuk melakukan deposit ke akun juga merupakan metode penarikan dana (lihat pertanyaan \"Bagaimana cara melakukan deposit?\")",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "Misalnya, jika Anda melakukan deposit ke akun melalui sistem pembayaran Visa, Anda juga akan menarik uang melalui sistem pembayaran Visa.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "Saat melakukan penarikan dalam jumlah yang cukup besar, Perusahaan dapat meminta verifikasi (verifikasi diminta berdasarkan kebijakan tunggal Perusahaan). Itulah mengapa penting untuk mendaftarkan akun Anda sendiri guna mengonfirmasi hak-hak Anda kapan pun.",
    "What is the minimum withdrawal amount?": "Berapa jumlah penarikan minimum?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "Jumlah penarikan minimum mulai dari 10 USD untuk sebagian besar sistem pembayaran. <br>Untuk mata uang kripto, jumlah ini mulai dari 50 USD (dan mungkin lebih tinggi untuk mata uang tertentu, seperti Bitcoin).",
    "Do I need to provide any documents to make a withdrawal?": "Apakah saya harus memberikan dokumen untuk melakukan penarikan?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "Biasanya, tidak dibutuhkan dokumen tambahan untuk menarik dana. Namun, Perusahaan, berdasarkan kebijakannya, dapat meminta Anda untuk mengonfirmasi data pribadi dengan meminta dokumen tertentu. Hal ini biasanya dilakukan untuk mencegah aktivitas terkait trade ilegal, penipuan keuangan, dan penggunaan dana yang diperoleh secara ilegal.",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "Daftar dokumen yang diminta hanya sedikit dan proses pengiriman dokumen tidak membutuhkan banyak waktu dan usaha.",
    "How long does it take to withdraw funds?": "Berapa lama waktu yang dibutuhkan untuk menarik dana?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "Prosedur penarikan rata-rata membutuhkan waktu 1-5 hari sejak tanggal diterimanya permintaan Klien dan hanya bergantung pada volume permintaan yang diproses secara bersamaan. Perusahaan selalu berusaha melakukan pembayaran langsung pada hari yang sama saat permintaan diterima dari Klien.",
    "Is there any fee for depositing or withdrawing funds from the account?": "Adakah biaya deposit atau penarikan dana dari akun?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "Tidak. Perusahaan tidak mengenakan biaya apa pun untuk deposit maupun penarikan.",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "Namun, perhatikan bahwa sistem pembayaran dapat mengenakan biaya dan menggunakan kurs mata uang internal.",
    "Can I close my account? How to do it?": "Bisakah saya menutup akun? Bagaimana cara melakukannya?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "Anda dapat menghapus akun di bagian Akun Individu dengan mengeklik tombol \"Hapus Akun\" di bawah halaman profil.",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "Bagaimana cara memperbaiki kesalahan saat memasukkan data ke akun individu?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "Anda harus menghubungi layanan dukungan teknis di situs web Perusahaan dan mengedit profil.",
    "What data is required to register on the Company website?": "Data apa yang dibutuhkan untuk mendaftar di situs web Perusahaan?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "Untuk menghasilkan uang dalam opsi digital, Anda harus membuka akun yang memberikan akses ke trading. Untuk melakukannya, Anda harus mendaftar di situs web Perusahaan.",
    "The registration process is simple and does not take much time.": "Proses pendaftarannya mudah dan tidak butuh banyak waktu.",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "Anda harus mengisi kuesioner dalam formulir yang diberikan. Anda harus memasukkan informasi berikut:",
    "name (in English)": "nama",
    "email address (indicate the current, work, address)": "alamat email (tuliskan alamat email yang aktif saat ini)",
    "telephone (with a code, for example, + 44123 ....)": "telepon (disertai kode, misalnya + 44123 ....)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "kata sandi yang akan Anda gunakan di masa mendatang untuk masuk ke sistem (Untuk meminimalkan risiko akses yang tidak sah ke akun individu Anda, kami menyarankan Anda untuk membuat kata sandi yang kompleks dengan huruf kecil, huruf kapital, dan angka. Jangan beritahukan kata sandi ke pihak ketiga.)",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "Setelah mengisi kuesioner, Anda akan ditawari berbagai cara deposit ke akun untuk trading.",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "Apakah mata uang yang digunakan dalam akun Klien? Bisakah saya mengubah mata uang dalam akun Klien?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "Umumnya, akun trading dibuka dalam dolar AS. Namun, demi kemudahan, Anda dapat membuka beberapa akun dalam mata uang berbeda.",
    "A list of available currencies can be found on your profile page in your Client's account.": "Anda dapat menemukan daftar mata uang yang tersedia di halaman profil dalam akun Klien.",
    "What are digital options?": "Apa itu opsi digital?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "Opsi - instrumen keuangan derivatif berdasarkan aset dasar (saham, pasangan mata uang, minyak, dan sebagainya).",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "OPSI DIGITAL - opsi non-standar yang digunakan untuk menghasilkan keuntungan dari pergerakan harga suatu aset untuk periode waktu tertentu.",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "Opsi digital, bergantung pada ketentuan yang disetujui oleh para pihak transaksi, memberikan keuntungan tetap (selisih antara pendapatan trade dan harga aset) atau menyebabkan kerugian (sebesar nilai aset) pada waktu yang ditentukan oleh para pihak.",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "Karena opsi digital dibeli terlebih dahulu dengan harga tetap, besar keuntungan dan kemungkinan kerugian sudah diketahui bahkan sebelum melakukan trading.",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "Fitur lain dari penawaran ini adalah batas waktu. Semua opsi memiliki jangka waktu (waktu kedaluwarsa atau waktu berakhir) masing-masing.",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "Bagaimana pun tingkat perubahan harga aset dasar (seberapa naik atau turun harganya), apabila opsi menang, Anda akan tetap menerima pembayaran tetap. Jadi, risiko Anda hanya terbatas pada nilai pembelian opsi.",
    "What is the expiration period of a trade?": "Apa itu periode kedaluwarsa trade?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "Setelah periode kedaluwarsa berakhir, trade akan dianggap telah selesai (ditutup) dan hasilnya akan secara otomatis ditetapkan.",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "Saat melakukan trade dengan opsi digital, Anda menentukan sendiri waktu eksekusi transaksi (1 menit, 2 jam, bulan, dan sebagainya).",
    "What is the gist of digital options trading?": "Apakah inti dari trading opsi digital?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "Sebenarnya, opsi digital adalah jenis instrumen keuangan derivatif yang paling sederhana. Untuk menghasilkan uang dalam pasar opsi digital, Anda tidak perlu memprediksi nilai harga pasar dari suatu aset.",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "Prinsip dari proses trading telah disederhanakan menjadi satu - apakah harga suatu aset akan naik atau turun pada saat kontrak dieksekusi.",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "Saat melakukan trading opsi, tingkat perubahan harga aset dasar tidaklah penting, baik 100 poin ataupun hanya 1 poin, dari saat trade dilakukan hingga ditutup. Anda hanya perlu menentukan arah pergerakan harga ini.",
    "If your prognosis is correct, in any case you get a fixed income.": "Jika prediksi Anda benar, Anda akan mendapatkan keuntungan tetap.",
    "What are the possible results of the placed trades?": "Hasil apa saja yang mungkin dari trade?",
    "There are three possible outcomes in the digital options market:": "Ada tiga hasil yang mungkin dalam pasar opsi digital:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "jika prediksi pergerakan arah harga dari aset dasar Anda benar, Anda akan mendapatkan keuntungan.",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "jika pada saat opsi ditutup, prediksi Anda ternyata salah, Anda akan mengalami kerugian sebesar nilai aset (malahan, Anda mungkin hanya akan kehilangan nilai investasi).",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "jika hasil trade adalah nol (harga aset dasar tidak berubah, opsi ditutup pada harga yang sama seperti saat pembelian), Anda akan mendapatkan kembali nilai investasi yang sama. Jadi, tingkat risiko Anda selalu terbatas pada nilai aset.",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "Apakah platform trading Anda menawarkan akun demo untuk memahami cara kerja opsi digital tanpa perlu mengeluarkan uang?",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "Ya. Untuk mengembangkan keahlian trading dan menguji kemampuan platform trading Perusahaan, Anda dapat menggunakan akun demo (gratis). Ini semacam simulator yang memungkinkan Anda untuk mencoba dahulu, baru kemudian beralih ke trading sungguhan. Akun demo juga cocok untuk trader berpengalaman yang ingin meningkatkan keahliannya.",
    "The balance of such an account is 10,000 units.": "Saldo akun demo adalah 10.000 unit.",
    "What determines profit size?": "Apa yang menentukan besar keuntungan?",
    "There are several factors that affect the size of your profit:": "Ada beberapa faktor yang memengaruhi besar keuntungan Anda:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "likuiditas aset yang Anda pilih di pasar (makin besar permintaan aset di pasar, makin besar keuntungan yang akan diterima)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "waktu trading (likuiditas aset di pagi hari dan sore hari bisa berbeda jauh)",
    "tariffs of a brokerage company": "biaya perusahaan broker",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "perubahan di pasar (peristiwa ekonomi, perubahan dalam bagian aset keuangan, dan sebagainya)",
    "What are the varieties of digital options?": "Apa saja jenis dari opsi digital?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "Saat melakukan trading opsi, Anda harus memilih aset dasar yang akan mendasari opsi. Prediksi Anda akan dilakukan untuk aset ini.",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "Singkatnya, saat membeli kontrak digital, Anda sebenarnya mempertaruhkan pergerakan harga dari aset dasar tersebut.",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "Aset dasar adalah \"item\" yang harganya dipertimbangkan saat melakukan trade. Produk yang paling banyak dicari di pasar biasanya berperan sebagai aset dasar dari opsi digital. Ada empat jenis aset dasar:",
    "securities (shares of world companies)": "sekuritas (saham perusahaan dunia)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "pasangan mata uang (EUR / USD, GBP / USD, dan sebagainya)",
    "raw materials and precious metals (oil, gold, etc.)": "bahan mentah dan logam berharga (minyak, emas, dan sebagainya)",
    "indices (S&P 500, Dow, dollar index, etc.)": "indeks (S&P 500, Dow, indeks dolar, dan sebagainya)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "Tidak ada aset dasar universal. Saat memilihnya, Anda hanya dapat mengandalkan pengetahuan dan intuisi Anda sendiri, serta berbagai jenis informasi analisis dan analisis pasar untuk instrumen keuangan tertentu.",
    "What is a trading platform and why is it needed?": "Apa itu platform trading dan mengapa dibutuhkan?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "Platform trading - perangkat lunak kompleks yang memungkinkan Klien melakukan trade (operasi) dengan berbagai instrumen keuangan. Platform ini juga mengakses berbagai informasi, seperti nilai quote, posisi pasar real-time, tindakan Perusahaan, dan sebagainya.",
    "How to learn quickly how to make money in the digital options market?": "Bagaimana cara cepat untuk belajar menghasilkan uang di pasar opsi digital?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "mengembangkan strategi trading sendiri agar jumlah prediksi trade yang benar maksimal dan mengikuti strategi itu",
    "diversify your risks": "melakukan diversifikasi risiko",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "Saat mengembangkan strategi dan mencari pilihan diversifikasi, ada baiknya Anda memantau pasar serta mempelajari informasi analisis dan statistik dari berbagai sumber (internet, pendapat pakar, analis di bidang ini, dan sebagainya) - salah satunya adalah situs web Perusahaan.",
    "Is the download of the program to a computer or smartphone required?": "Apakah saya harus mengunduh program di komputer atau smartphone?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "Tidak ada. Anda hanya perlu mendaftar di situs web Perusahaan melalui formulir yang diberikan dan membuka akun individu.",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "Dari mana Perusahaan dapat membayar keuntungan ke Klien untuk trade yang berhasil?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "Perusahaan menghasilkan keuntungan bersama pelanggan. Jadi, Perusahaan lebih tertarik pada transaksi yang menguntungkan dibandingkan dengan yang tidak menguntungkan, karena Perusahaan mendapatkan persentase pembayaran untuk strategi trading berhasil yang dipilih oleh Klien.",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "Selain itu, trade-trade yang dilakukan oleh Klien terakumulasi menjadi volume trading Perusahaan, yang ditransfer ke broker atau bursa dan disertakan dalam kolam penyedia likuiditas, yang kemudian mendorong peningkatan likuiditas pasar itu sendiri.",
    "What is account verification?": "Apa itu verifikasi akun?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "Verifikasi dalam opsi digital merupakan konfirmasi Klien atas data pribadinya dengan memberikan dokumen tambahan kepada Perusahaan. Kondisi verifikasi untuk Klien sangat sederhana, dan daftar dokumen yang diminta hanya sedikit. Misalnya, perusahaan dapat meminta:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "hasil pindaian berwarna dari halaman depan paspor Klien (halaman dengan foto)",
    "identify with the help of a \"selfie\" (photograph of himself)": "identifikasi dengan bantuan \"swafoto\" (foto diri sendiri)",
    "confirm the address of registration (residence) of the Client, etc": "konfirmasi alamat pendaftaran (tempat tinggal) Klien, dan sebagainya",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "Perusahaan dapat meminta dokumen apa pun jika tidak dapat sepenuhnya mengidentifikasi Klien dan data yang dimasukkan.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "Setelah salinan elektronik dokumen dikirimkan ke Perusahaan, Klien harus menunggu proses verifikasi data yang diberikan.",
    "How to understand that I need to go through account verification?": "Bagaimana cara mengetahui bahwa saya harus melakukan verifikasi akun?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "Jika harus melakukan verifikasi, Anda akan menerima notifikasi melalui email dan/atau SMS.",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "Di waktu yang sama, Perusahaan menggunakan detail kontak yang Anda masukkan dalam kuesioner saat pendaftaran (khususnya alamat email dan nomor telepon). Jadi, hati-hati dan berikan informasi yang relevan dan benar.",
    "How do I know that I successfully passed verification?": "Bagaimana cara mengetahui bahwa saya berhasil lolos verifikasi?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "Anda akan menerima notifikasi melalui email dan/atau SMS tentang penyelesaian proses verifikasi akun, dan Anda dapat melanjutkan operasi di platform trading Perusahaan.",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "Apakah mungkin menggunakan data (palsu) orang lain saat mendaftar di situs web?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "Tidak. Klien harus melakukan pendaftaran sendiri di situs web Perusahaan dengan memberikan informasi diri yang lengkap dan akurat, sesuai yang diminta di formulir pendaftaran, serta senantiasa memperbarui informasi ini.",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "Jika berbagai jenis pemeriksaan perlu dilakukan untuk memastikan identitas Klien, Perusahaan dapat meminta dokumen atau mengundang Klien ke kantor.",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "Jika data yang dimasukkan ke kolom pendaftaran tidak sama dengan data dalam dokumen yang dikirimkan, profil individu Anda dapat diblokir.",
    "How long does the verification process take?": "Berapa lama waktu yang dibutuhkan untuk proses verifikasi?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "Maksimum 5 (lima) hari kerja sejak tanggal Perusahaan menerima dokumen yang diminta.",
    "Is there a minimum amount that I can deposit to my account at registration?": "Adakah jumlah deposit minimum ke akun pada saat pendaftaran?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "Kelebihan dari platform trading Perusahaan ini adalah Anda tidak perlu melakukan deposit besar ke akun. Anda dapat mulai trading dengan menginvestasikan sedikit uang. Deposit minimum adalah 10 USD.",
    "How can I deposit?": "Bagaimana cara melakukan deposit?",
    "It is very easy to do. The procedure will take a couple of minutes.": "Caranya mudah sekali. Prosedur ini hanya butuh waktu beberapa menit.",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "Buka jendela eksekusi trade dan klik tombol \"Deposit\" yang berwarna hijau di pojok kanan atas tab.",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "Anda juga dapat melakukan deposit ke akun melalui Akun Personal dengan mengeklik tombol \"Deposit\" di profil akun.",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "Setelah itu, Anda harus memilih metode deposit ke akun (Perusahaan menawarkan banyak metode yang mudah untuk Klien, yang ditampilkan dalam akun individu).",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "Selanjutnya, tentukan mata uang untuk deposit ke akun, yang nantinya juga akan menjadi mata uang akun itu sendiri.",
    "Enter the amount of the deposit.": "Masukkan jumlah deposit.",
    "Fill out the form by entering the requested payment details.": "Isilah formulir dengan memasukkan detail pembayaran yang diminta.",
    "Make a payment.": "Lakukan pembayaran.",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "Apakah saya harus melakukan deposit ke akun di platform trading dan seberapa sering saya harus melakukannya?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "Untuk melakukan trading opsi digital, Anda harus membuka akun individu. Untuk melakukan trade sungguhan, Anda harus mendeposit sebesar opsi yang dibeli.",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "Anda dapat mulai trading tanpa uang, hanya dengan akun latihan (akun demo) perusahaan. Akun tersebut gratis dan dibuat untuk mendemonstrasikan fitur platform trading. Dengan bantuan akun demo, Anda dapat berlatih opsi biner, memahami prinsip-prinsip trading dasar, menguji berbagai metode dan strategi, atau menilai tingkat intuisi Anda.",
    "What is the minimum deposit amount?": "Berapa jumlah deposit minimum?",
    "{number} questions": "{number} pertanyaan",
    "Verification": "Verifikasi",
    "Payment": "Pembayaran",
    "Payouts": "Pembayaran",
    "Ask a specialist": "Tanyakan pada pakar",
    "FAQ": "FAQ",
    "Didn't find an answer to your question?": "Tidak menemukan jawaban atas pertanyaan Anda?",
    "Open knowledge base": "Buka pusat pengetahuan",
    "Contact support": "Hubungi dukungan",
    "Open FAQ page": "Buka halaman FAQ",
    "New message": "Pesan baru",
    "Tournaments": "Turnamen",
    "To end": "Untuk mengakhiri",
    "Until start": "Hingga mulai",
    "Front side": "Sisi depan",
    "Back side (if any)": "Sisi belakang (jika ada)",
    "Tourna-ments": "Turnamen",
    "Deletion of Account and Personal Data": "Penghapusan Akun dan Data Pribadi",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "Dengan menghapus akun dan data pribadi Anda, akses ke akun Anda di platform Quotex akan hilang secara permanen. Ingat, data Anda akan dihapus secara permanen, dan akun Anda tidak dapat dipulihkan lagi nanti!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "CATATAN: Selesaikan semua trade terbuka dan order tertunda sebelum menghapus akun Anda.",
    "Request Deletion": "Permintaan Penghapusan",
    "To delete your account, please contact support service": "Untuk menghapus akun Anda, silakan hubungi layanan dukungan",
    "Two-factor authorization via Email": "Otorisasi dua faktor melalui Email",
    "This feature is for experienced users who are familiar with Google Authenticator.": "Fitur ini untuk pengguna berpengalaman yang sudah mengenal Google Authenticator.",
    "Please note!": "Perhatian!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "Jika Anda kehilangan akses ke Google Authenticator karena alasan apa pun setelah menghubungkan, Anda akan kehilangan akses ke akun Anda.",
    "Proceed to setup": "Lanjutkan pengaturan",
    "Account protection method": "Metode perlindungan akun",
    "Here's how to set it up:": "Inilah cara mengaturnya:",
    "Please enter the PIN-code we've just sent to your email:": "Masukkan kode PIN yang baru saja kami kirimkan ke email Anda:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "Bagus! Anda berhasil mengaktifkan verifikasi dua langkah melalui Google Authenticator. Saat masuk lain kali, Anda akan dimintai kode.",
    "If you ever lose access to your 2-step verification device, please": "Jika Anda kehilangan akses ke perangkat verifikasi dua langkah,",
    "Confirm account deletion": "Konfirmasi penghapusan akun",
    "To delete your account, follow the link in the email sent to": "Untuk menghapus akun Anda, ikuti tautan dalam email yang dikirimkan ke",
    "Image requirements": "Persyaratan gambar",
    "Email": "Email",
    "Сonfirmation": "Konfirmasi",
    "Change protection method": "Ubah metode perlindungan",
    "First, enter the code from the Google Authenticator app:": "Pertama, masukkan kode dari aplikasi Google Authenticator:",
    "First, enter the PIN-code we've just sent to your email:": "Pertama, masukkan kode PIN yang baru saja kami kirimkan ke email Anda:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "Bagus! Anda berhasil mengaktifkan verifikasi dua langkah melalui Email.",
    "Receiving codes via Email": "Menerima kode melalui Email",
    "Receiving codes via Google Authenticator": "Menerima kode melalui Google Authenticator",
    "Choose a method:": "Pilih metode:",
    "To enter the platform": "Untuk masuk ke platform",
    "To withdraw funds": "Untuk menarik dana",
    "day": "day",
    "days2": "hari2",
    "days5": "hari5",


text: 'Pilih Mata Uang',
text1: 'Surel',
text2: 'Batal Transaksi Untuk',
text3: 'Kode Diskon Gratis',
text4: 'Kami meningkatkan platform ini setiap hari agar menjadi lebih nyaman.',
text5: 'Anda menyukai platform ini? Tolong rekomendasikan kepada orang lain!',
text6: 'Silakan tinggalkan komentar tentang QUOTEX, Anda akan mendapatkan hadiah pembatalan transaksi gratis.',
text7: 'Kirim tautan komentar Anda ke tim layanan pelanggan kami, setelah diperiksa, kami akan mengirimkan hadiah ini kepada Anda.',
text8: 'Jumlah Setoran Minimum',
text9: 'Jumlah Penarikan Minimum',
text10: 'Penarikan Cepat',
text11: 'Gratis',
text12: 'Belum Diverifikasi',
text13: 'Kirim Ulang',
text14: 'Konfirmasi Partisipasi Anda',
text15: 'Batalkan Transaksi',
text16: 'Silakan masukkan dan aktifkan kode diskon Anda sekarang. Anda dapat menggunakan kode diskon kapan saja.',
text17: 'Kode Diskon:',
text18: 'Dapatkan Hadiah 30% berdasarkan Saldo Setoran',
text19: 'Mata Uang Saat Ini',
text20: 'Saldo yang Dapat Ditarik',
text21: 'Di Akun',
text22: 'Hari Ini',
text23: 'Kemarin',
text24: 'Minggu',
text25: 'Bulan',
text26: 'Total Volume Transaksi Bersih',
text27: 'WELCOME adalah hadiah 30% untuk setoran pertama.',
text28: 'Jumlah setoran pertama adalah $100. Untuk mendapatkan hadiah 30%, pilih metode setoran.',
text29: 'Kartu Bank',
text30: 'Bank',
text31: 'Metode Pembayaran yang Dipilih:',
text32: 'Jumlah Minimum:',
text33: 'Jumlah Maksimum:',
text34: 'Pilih Metode Lain',
text35: 'Informasi Pembayaran',
text36: 'Jumlah',
text37: 'Gunakan Hadiah',
text38: 'Syarat dan Ketentuan',
text39: 'Saya Punya Kode Diskon',
text40: 'Kode Diskon',
text41: 'Terapkan Kode Diskon',
text42: 'Kode diskon yang Anda masukkan benar. Anda akan mendapatkan hadiah 30%.',
text43: 'Anda akan mendapatkan',
text44: 'Hadiah:',
text45: 'Harap Pahami Semua Pertanyaan Umum',
text46: 'Untuk melakukan perdagangan aset digital, Anda perlu membuka rekening pribadi. Untuk melakukan perdagangan nyata, tentu saja Anda perlu menyetorkan jumlah sesuai dengan harga opsi.',
text48: 'Ada {0} kode diskon',
text49: 'Gunakan kode diskon ini untuk membatalkan transaksi rugi',
text50: 'Anda hanya dapat membatalkan transaksi yang belum ditutup, dengan jumlah tidak melebihi nilai maksimum kode diskon.',
text51: 'Status',
text52: 'Gunakan',
text53: 'Anda belum memiliki riwayat kode diskon. Anda dapat menambahkan kode diskon menggunakan tombol di bawah.',
text54: 'Tampilkan Riwayat',
text55: 'Masukkan Kode Diskon',
text56: 'Informasi Pribadi',
text57: 'Kosong',
text58: 'Nama Depan',
text59: 'Nama Belakang',
text60: 'Tanggal Lahir',
text61: 'Negara',
text62: 'Simpan',
text63: 'Alamat',
text64: 'Verifikasi Dokumen',
text65: 'Sebelum melakukan verifikasi akun, Anda perlu memasukkan informasi pribadi.',
text66: 'Keamanan',
text67: 'Verifikasi Dua Langkah',
text68: 'Ubah Kata Sandi',
text69: 'Kata Sandi Lama',
text70: 'Kata Sandi Baru',
text71: 'Ketik Ulang Kata Sandi Baru',
text72: 'Logout dari Akun',
text73: 'Verifikasi Kartu Kredit atau Debit',
text74: 'Tambahkan Kartu Bank Baru',
text75: 'Anda tidak memiliki kartu kredit atau debit yang perlu diverifikasi',
text76: 'Anda belum memiliki riwayat transaksi.',
text77: 'Awal',
text78: 'Akhir',
text79: 'Terapkan Filter',
text80: 'Sebelumnya',
text81: 'Selanjutnya',
text82: 'Keuntungan',
text83: 'Dibatalkan',
text84: 'Akun',
text85: 'Biaya',
text86: 'Anda dapat menarik dana dari akun platform Anda ke kartu bank atau dompet elektronik yang Anda gunakan untuk melakukan deposit. Anda dapat mengajukan permintaan penarikan kapan saja. Permintaan penarikan Anda akan diproses dalam waktu 3 hari kerja.',
text87: 'Setoran',
text88: 'Beberapa Permintaan Sebelumnya',
text89: 'Riwayat Penarikan',
text90: 'Permintaan Saya',
text91: 'Ajukan Permintaan',
text92: 'Pertanyaan Umum',
text93: 'Anda tidak memiliki permintaan yang diajukan',
text94: 'Jika ada pertanyaan, Anda dapat menemukan jawabannya di Pertanyaan Umum.',
text95: 'Hak Cipta © 2024 Quotex. Hak cipta dilindungi',
text96: 'Masuk untuk Menggunakan',
text97: 'Kata Sandi',
text98: 'Ingat Saya',
text99: 'Kembali ke Informasi',
text100: 'Selesai',


text104: 'Platform Inovasi kanggo Investasi Cerdas',
   text105: 'Daftar lan entuk $10.000 ing akun demo sing sampeyan sinau dagang',
   text106: 'Daftar',
   text107: 'Bukak akun demo',
   text108: 'Antarmuka dagang sing trep',
   text109: 'Kita wis nggawe antarmuka sing paling gampang lan paling trep supaya sampeyan ora nyimpang saka sing paling penting - dagang. ',
   text110: 'Sinyal sing dibangun',
   text111: 'Coba strategi dagang, kita nyedhiyakake sinyal sing paling akurat lan inovatif nganti 87% kanggo mbantu sampeyan ngembangake strategi efektif dhewe. ',
   text112: 'Indikator dagang',
   text113: 'Kita wis nglumpukake indikator dagang sing paling migunani. Gunakake indikator iki kanggo nambah imbangan akun. ',
   text114: 'Kacepetan sampurna',
   text115: 'Platform kita nggunakake teknologi paling inovatif lan menehi kacepetan sing luar biasa. ',
   text116: 'Coba nganggo akun demo',
   text117: 'Supaya entuk dhuwit luwih akeh,',
   text118: 'Gawe ramalan sing bener',
   text119: 'Apa rega bullish utawa bearish? ',
   text120: 'Bukak akun demo',
   teks121: 'Klik',
   text122: 'Ing salah siji tombol lan deleng asil',
   text123: 'Rega bakal munggah',
   text124: 'Rega bakal mudhun',
   text125: 'Ngenteni asil...',
   teks126: 'Duwe sampeyan',
   text127: 'Nuwun sewu, ramalan sampeyan salah',
   text128: 'Coba maneh',
   teks129: 'Duwe sampeyan',
   teks130: 'Sugeng! Ramalan sampeyan bener ',
   text131: 'Mulai golek dhuwit',
   text132:'Aplikasi seluler tansah kasedhiya',
   text133: 'Download app pangguna-loropaken kita kanggo iPhone utawa Android lan miwiti dagang! ',
   teks134: 'Wektu sing kasedhiya',
   text135: 'Mulai dagang',
   teks136: '3 langkah',
   text137: 'Mbukak akun nyata kanthi gratis mung sawetara menit',
   text138: 'Dagang ing akun demo kanthi siji klik',
   teks139: 'Latihan',
   text140: 'Ningkatake katrampilan sampeyan nganggo akun demo lan materi pelatihan',
   text141: 'Mulai mulang nganggo akun demo',
   text142: 'Simpenan menyang akun nyata lan miwiti dagang',
   text143:'Swara 410 instrumen, jumlah transaksi minimal $10',
   text144: 'Metode simpenan Langsung',
   teks145: 'FAQ',
   text146: 'Carane nguwasani? ',
   text147: 'Dhaptar akun demo lan praktek nggunakake akun iki. Akun demo padha karo akun nyata, nanging ora ana simpenan sing dibutuhake. ',
   text148: 'Sepira suwene proses penarikan? ',
   text149: 'Rata-rata, proses mundur total njupuk antarane 1 lan 5 dina kanggo proses saka tanggal panrimo saka panjalukan pangguna sing cocog, lan mung gumantung ing jumlah panjalukan diproses bebarengan. Perusahaan tansah ngupayakake mbayar panjalukan pangguna kanthi langsung ing tanggal ditampa. ',
   text150: 'Apa platform dagang kasebut? apa efeke? ',
   text151: 'Platform dagang minangka sistem piranti lunak Pangguna nggunakake instrumen finansial sing diwenehake dening sistem iki kanggo nindakake transaksi (operasi), lan uga nggunakake nilai kutipan, kahanan pasar wektu nyata, tumindak perusahaan lan informasi liyane sing diwenehake dening perusahaan. ',
   text152: 'Apa aku bisa nggunakake ponsel kanggo nindakake transaksi? ',
   text153: 'Platform kita nggunakake teknologi paling inovatif lan bisa dibukak ing browser sembarang komputer utawa smartphone',
   text154: 'Apa jumlah simpenan minimal? ',
   text155: 'Kauntungan saka platform dagang perusahaan iki yaiku sampeyan ora perlu nyetor dhuwit akeh menyang akun sampeyan. Sampeyan bisa miwiti dagang kanthi nandur modal dhuwit cilik. Simpenan minimal $10. ',
   text156: 'Apa ana komisi nalika nyetor utawa mbatalake dhuwit saka akun kasebut? ',
   teks157: 'Ora. Perusahaan ora ngisi komisi kanggo operasi kayata nyetor akun utawa narik dana. ',
   text158: 'Nanging, kudu dicathet yen sistem pembayaran bisa ngisi komisi lan nggunakake kurs mata uang sing beda. ',
   text159: 'Buka kabeh pitakonan',
   text160:'Quotex: Platform Inovasi',
   text161: 'Digital asset trading',
   text162: 'Bukak akun nyata',
   teks163: 'Mitra',
   text164: 'Akun demo',
   text165: 'Informasi kontak',
   text166: 'Pitakonan penting',
   text167: 'Masalah keuangan',
   teks168: 'verifikasi',
   text169: 'Peraturan',
   text170: 'Kebijakan Privasi',
   text171: 'Syarat Pembayaran',
   text172: 'Perjanjian Layanan',
   teks173: 'Awas Resiko',
   text174: 'Aturan operasi dagang',
   text175:'Aturan operasi non-dagang',
   text176:`Pènget Resiko: Dagang ing platform dagang aset digital lan nggunakake instrumen pendanaan kredit bisa nyebabake risiko sing signifikan lan bisa nyebabake mundhut total modal investasi sampeyan. Aja nandur modal luwih saka sampeyan bisa mbayar, lan sampeyan kudu ngerti risiko sing ana. Dagang nggunakake instrumen pendanaan sing dipinjam ora cocog kanggo umume investor. Dagang tanpa nggunakake dana sing dipinjam kayata saham uga beresiko amarga rega saham bisa mudhun lan mundhak, sing bisa uga tegese sanajan sampeyan menang, sampeyan bakal entuk bali luwih murah tinimbang sing sampeyan nandur modal.
   Sukses kepungkur ora njamin sukses ing mangsa ngarep. Sadurunge dagang, mangga digatekake pengalaman dagang lan level, tujuan investasi, lan hubungi penasehat finansial independen yen perlu. Pangguna kudu nemtokake manawa panggunaan layanan bermerek Quotex diidini adhedhasar hukum lan peraturan negara panggonane. `,
}