export default {
    "+{value}% profit": "+{value}％の収益",
    "Selfie verification required": "自撮り写真の認証が必要です",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "アカウント認証に必要な手順を実行するために、お客様が現在の日付と署名の書かれた紙と身分証明書を持つ場面の高解像度の写真（自撮り）を提供してください。顔、体、両手がはっきり写る必要があります。文書の詳細は明確で読みやすいものでなければなりません。",
    "Your documents have been checked, now your identity is verified.": "書類が確認され、本人確認が完了しました。",
    "You need fill identity information before verification your profile.": "プロフィールを認証するには、身分証明書の情報を入力する必要があります。",
    "Documents verification": "書類認証",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "プロフィール承認とは、クライアントの身元を証明する公的な文書を提供することです。この手続きは、当社のセキュリティ部門がいつでも開始することができます。",
    "Upload Photo": "写真をアップロードします",
    "Nickname": "ニックネーム",
    "First Name": "姓",
    "Last Name": "名",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "氏名は、書類に記載されているとおりに正確に書く必要があります。誤って不正確な情報が提供された場合、アカウントは警告なしに削除されることがあります。",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "姓は書類に記載されているとおりに正確に書く必要があります。誤って不正確な情報が提供された場合、アカウントは警告なしに削除されることがあります。",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "今後生年月日を変更することはできません。間違って提供した場合、アカウントからの出金ができなくなります。",
    "Empty": "空欄",
    "Date of birth": "生年月日",
    "Unverified": "未確認",
    "Verified": "確認済み",
    "Resend": "再送信",
    "Country": "国",
    "Address": "住所",
    "Change Identity Info": "ID情報の変更",
    "Identity Info": "ID情報",
    "Two-factor authentication on login": "ログイン時の二要素認証",
    "Two-factor authentication on withdrawal": "出金時の二要素認証",
    "Active": "アクティブ化",
    "Inactive": "非アクティブ",
    "Setup": "設定",
    "Two-step verification": "2段階認証",
    "Security": "セキュリティ",
    "Google Authenticator": "Google Authenticator",
    "Scan the QR code": "QRコードを読み取る",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Googleではセキュリティ上の理由で、2段階認証を必須とされています。設定方法は以下の通りです。",
    "Install ": "インストールする",
    "Can't scan?": "スキャンは大丈夫ですか。",
    "Click here.": "ここをクリックしてください。",
    "Enter this code into Google Authenticator.": "このコードをGoogle Authenticatorに入力します。",
    "Back to QR code.": "QRコードに戻る",
    "Enter the 6-digit code from Authenticator below": "下にAuthenticatorからの6桁コードを入力してください",
    "Enter 6-digit code...": "6桁のコードを入力…",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "おめでとうございます。これで 2 段階認証プロセスが正常に有効になりました。次回のログイン時には、コードの入力を求められます。",
    "Code": "コード",
    "contact support": "サポートにお問い合わせ",
    "If you ever lose access to your 2-step verification device, please ": "2段階認証デバイスにアクセスできなくなった場合は、",
    "Yes, activate": "はい、アクティブ化します",
    "Cancel": "キャンセル",
    "Close": "閉じる",
    "Language": "言語",
    "Timezone": "タイムゾーン",
    "Delete Account": "アカウント削除",
    "Verification Credit / Debit Cards": "クレジット/デビットカードの認証",
    "Add New Card": "新しいカードを追加",
    "Wait for loading...": "読み込み中…",
    "You don’t have any credit / debit cards for verification": "認証のためのクレジットカード/デビットカードをお持ちでない方",
    "Verificate": "認証",
    "Verification Card": "認証済みカード",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "身分証明書（写真、氏名、生年月日が記載されているもの）と銀行カードの表面（番号、有効期限、氏名が記載されているもの）をA4の白紙にご記入ください。「Quotex.io」と書き、署名してください。<br>写真を撮ってアップロードしてください。",
    "Card:": "カード：",
    "Type:": "種類：",
    "Your ID and bank card on an A4 sheet": "身分証明書と銀行カードをA4サイズの用紙にまとめてください",
    "Yes, confirm": "はい、削除します",
    "Click or drag the image": "画像をクリックまたはドラッグします",
    "Card": "カード",
    "Documents": "書類",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "まず、プロフィールの「ID情報」欄に必要事項を記入し、「ID情報の変更」ボタンをクリックして変更を保存してください。その後、新しいカードを追加することができます。",
    "Specify the card details for further operations for depositing and debiting funds.": "入金・出金操作のために、カード情報を提供してください。",
    "Card number:": "カード番号",
    "Expiry month:": "有効期限月",
    "Expiry year:": "有効期限年",
    "Cardholder name:": "カード保有者：",
    "Next": "次へ",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "署名と現在の日付を記入した書類を銀行カードと一緒に置き、写真を撮って、以下のフォームにアップロードしてください。",
    "Do you have a two-sided document?": "両面印刷の書類をお持ちですか。",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "裏面も同じく紙に署名して日付を記入し、別ファイルとして、アップロードしてください。",
    "Submit only valid documents": "有効な書類のみ提出してください",
    "Do not edit or crop photo": "写真の編集や切り取りはしないでください",
    "Do not upload other people's documents": "他人の書類をアップロードしないでください",
    "Read all": "すべて読む",
    "Uploading files": "ファイルをアップロードする",
    "For single-sided documents, upload only the front side.": "片面ドキュメントは、表面のみをアップロードします。",
    "Back": "戻る",
    "Add a card": "カードを追加する",
    "File Requirements": "ファイル要件",
    "Scanned images are not accepted for card verification": "スキャンした画像は、カード認証に使用できません",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "パスポート、または身分証明書（両面を別々のファイルとしてアップロードしてください）のみ受け付けます",
    "Driving license CANNOT be accepted": "運転免許証は受け付けかねます",
    "External (foreign/international/travel) passport will not be accepted as well": "外国人パスポート、国際パスポート、旅行用パスポートは受け付けかねます",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "書類の裏面の内容は、同じく現在の日付と署名を記入して別々のファイルとしてアップロードしてください",
    "All information in the document and on the card should be clear and readable on the photo": "書類とカードに記載されているすべての情報が、明確で読みやすいものでなければなりません",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "書類とカードの両方が写真に完全に収まる必要があり、端はトリミングしないでください",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "書類やカードの情報を読み取りを困難にするまぶしさやその他の写真の欠陥があってはなりません",
    "Expired documents and cards will be rejected automatically": "期限切れの書類やカードは自動的に拒否されます",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "カードに名前が記載されていない場合、カード番号と名前が記載された銀行明細書のスクリーンショットをアップロードする必要があります。場合によっては、同じ用紙に手書きで名前を書くことも可能です",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "書類には本人確認に必要なデータ（氏名、生年月日、写真）を必ず含める必要があります",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "カード番号の真ん中の8桁を隠すことができますが、最初の4桁と最後の4桁ははっきりと写る必要があります",
    "CVV code should be hidden at all times, in every case": "CVVコードは常に隠しておく必要があります",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "ファイルの編集は一切禁止されています。編集されていない写真やスキャンした画像のみ受け付け可能です。書類内の個人情報（氏名、生年月日など）の編集は禁止されています",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "写真は書類の一部であることが必要です（貼り付けまたは印刷）。別途添付された写真は受け付けかねます",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "アップロードできる書類やカードは、プロフィールで提供された（アカウントに登録されている）名前のものに限ります",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "虚偽/うその情報が提出された場合、状況が明らかになるまで、アカウントが停止またはブロックされることがあることをご了承ください",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "当社は、いつでもアカウント検証のために追加書類（書類と自撮り写真を含む）の提出を求める権利を留保します",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "書類の検証に関するすべての質問については、プラットフォーム上でサポートチケットを作成することができます（「ヘルプ」セクションは、一番左のパネルにある点滅する疑問符のアイコンが付いた最初のボタンとなります）",
    "Profile": "プロフィール",
    "Deposit": "入金",
    "Withdrawal": "出金",
    "Transactions": "取引",
    "Trades": "取引",
    "Market": "市場",
    "Analytics": "アナリティクス",
    "My current currency": "現在の通貨",
    "Available for withdrawal": "出金可能",
    "In the account": "アカウント内",
    "Percentage of turnover {percent}%": "収益率{percent}%",
    "Cashback {percent}%": "キャッシュバック{percent}%",
    "Currency:": "通貨：",
    "Change": "変更",
    "Live Account": "ライブアカウント",
    "Daily budget limit": "1日の予算上限",
    "The value cannot exceed your total budget": "この値は、総予算を超えることはできません",
    "Demo Account": "デモアカウント",
    "Apply": "適用",
    "Edit": "編集",
    "New value": "新しい価値",
    "Confirm": "確認する",
    "Logout": "ログアウト",
    "No, cancel": "いいえ、キャンセルします",
    "Exchange Form": "両替フォーム",
    "My Currency:": "私の通貨:",
    "New Currency:": "新しい通貨：",
    "You are exchanging": "交換",
    "You will receive": "受け取り",
    "Exchange Fee": "両替手数料",
    "Password changed": "パスワードが変更されました",
    "Old password": "古いパスワード",
    "New password": "新しいパスワード",
    "Confirm new password": "新しいパスワードの確認",
    "Change Password": "パスワードの変更",
    "Confirm password must be equal new password value": "確認入力のパスワードは、新しいパスワードと同じ値でなければなりません",
    "About us": "企業情報",
    "Support": "サポート",
    "Help": "ヘルプ",
    "2-step verification": "2段階認証",
    "Please enter the PIN-code we've just sent to your email": "メールに記載されたPINコードを入力してください。",
    "Please enter the 6-digit code from your authentication app": "認証アプリの6桁のコードを入力してください",
    "The First Name field is required": "名フィールドは必須です",
    "The Last Name field is required": "姓フィールドは必須です",
    "Verification of documents": "書類認証",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "通常の市民パスポート、運転免許証、または国民IDカードのカラー写真またはスキャン画像をアップロードしてください。",
    "Upload Documents": "書類のアップロード",
    "Why?": "なぜ？",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "アカウントを削除しますか。この操作は元に戻すことはできません。",
    "Set up 2-step verification": "2段階認証を設定する",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "残念ながら、このセクションにファイルをアップロードすることはできません。<br>サポートチームからのメッセージを参照し、必要なファイルをチケットに添付してください。",
    "The uploaded document does not match the requirements": "アップロードされた文書が要件を満たしていません",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "次の書類が本人確認に使うことができます。身分証明書、パスポート、国際パスポート、運転免許証のいずれか。",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "画像は高画質で、データは読みやすく、書類の端が切り取られていないことが必要です。",
    "Balance from {amount}": "{amount}からの残高",
    "Not verified": "未確認",
    "Clear": "クリア",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "サービス制限を回避するには、個人情報を入力し、必要な書類をアップロードして、認証プロセスを完了してください。",
    "Get verified": "検証を受ける",
    "I got it!": "わかりました！",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "AADHAARの検証。",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "実物のAadhaarカードのカラー写真をアップロードしてください（写真と書類番号のある表側のみ）。",
    "Send AADHAAR": "AADHAARを送信",
    "AADHAAR number:": "AADHAAR番号：",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "実物のAadhaarカードのカラー写真をアップロードしてください（写真と書類番号のある表側のみ）。☝デジタルカードは受け付けられません。",
    "You have already uploaded {number} files. Please wait until they are checked.": "既に{number}のファイルがアップロードされています。確認が終わるまでお待ちください。",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "書類のカラー写真をアップロードしてください。RG の場合 - 両面を別々のファイルとしてアップロードする必要があります。CNHの場合 - 写真のある表側のみをアップロードしてください。書類は有効期限内（発行日から10年以内）で、CPF番号が記載されているものでなければなりません。*書類に CPF番号の記載がない場合は、CPF登録証明書の写真、または氏名とCPF番号の記載された銀行明細書のスクリーンショットを別途アップロードしてください。** デジタルカードは受け付けかねますので、アップロードしないでください。",
    "Submit for verification": "検証のために提出します",
    "Settings": "設定",
    "Please upload a color photograph of the front of your national identity card with your photo.": "国民IDカードの表面のカラー写真と顔写真をアップロードしてください。",
    "Template": "テンプレート",
    "Light Mode": "ライトモード",
    "Twilight": "トワイライト",
    "Full Night": "フルナイト",
    "Grid's opacity": "グリッドの不透明度",
    "Auto-scrolling": "自動スクロール",
    "Automatic graphic scrolling": "グラフィックの自動スクロール",
    "1-click trade": "1クリック取引",
    "Open trades without confirmation": "確認なしのオープン取引",
    "Use timezone": "タイムゾーンを適用する",
    "Use your timezone data": "タイムゾーンのデータを適用する",
    "Graph colors": "グラフの色",
    "Up Trend": "上昇トレンド",
    "Down Trend": "下降トレンド",
    "Platform": "プラットフォーム",
    "Short order label": "ショートオーダーラベル",
    "Use short order element mode": "ショートオーダーエレメントモードを使用する",
    "Perfomance Mode": "パーフォマンスモード",
    "Use optimized rendering for chart and candles": "チャートとローソク足の図表を最適化",
    "Background": "背景",
    "Choose file": "ファイル選択",
    "Max size — 2 Mb": "最大サイズ - 2 Mb",
    "Sign up": "新規登録",
    "Sign in": "ログイン",
    "Budget and limits": "予算と限度額",
    "Daily limit": "一日の限度額",
    "Not set": "未設定",
    "Total funds": "総資金",
    "Top up": "チャージ",
    "Set up": "設定",
    "Set a daily limit on trading to reduce financial risks.": "1日の取引限度額を設定し、リスクを軽減します。",
    "Limit amount": "限度額",
    "More": "もっと見る",
    "Leader Board": "リーダーボード",
    "Signals": "シグナル",
    "Notifications": "お知らせ",
    "mark all as read": "すべて既読にします",
    "No notifications": "通知なし",
    "No more notifications": "これ以上の通知はありません",
    "Your position:": "私のランキング：",
    "How does this rating work?": "このランキングはどのように行われるのですか。",
    "of the Day": "日間",
    "Nothing has been found for current query, try another asset name.": "現在のクエリでは何も見つかりませんでした。別の資産名を試してみてください。",
    "My rating in the Leader Board": "リーダーボードでの私の実績",
    "Participants can only be those who trade in live mode": "参加者はライブモードで取引する人のみとなります",
    "Your position depends on the amount of earned money": "ランキングは、獲得した金額によって異なります",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "00:00UTCから23:59UTCまで、毎日計算されます",
    "All traders on our platform participate in this rating. Main features of the rating:": "このプラットフォームのすべてのトレーダーがこの評価に参加しています。主な評価点:",
    "How does it work?": "どのように機能しますか",
    "Trading signals": "取引シグナル",
    "Past signals": "過去のシグナル",
    "What’s it?": "シグナルとは？",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "売買シグナルは、取引を直接指示するものではなく、アナリストが推奨するものです。独立した市場分析に取って代わるものではありません。",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "売買シグナルを利用するには、平均10分間かかります。この時間を過ぎると、相場が変化し、取引結果に予期せぬ影響を与える可能性があります。",
    "Continue": "続ける",
    "Duration": "期間",
    "Return back to FAQ": "FAQに戻る",
    "Return back to Help": "ヘルプに戻る",
    "How to withdraw money from the account?": "アカウントからお金を引き出す手順は？",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "資本を引き出す手順は非常に簡単で、個人のアカウントで行うことができます。",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "入金するために選択した方法は、資金を引き出す方法となります（質問「どのように入金することができますか。」をご参照ください）。",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "たとえば、Visa決済システムでアカウントに入金した場合、Visa決済システムで出金することになります。",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "大きな金額の引き出しに関しては、当社は認証を求める場合があります（認証は当社の独自の裁量で行われます）。そのため、お客様ご自身用のアカウントを登録していつでも権利を確認できるようにすることが非常に重要です。",
    "What is the minimum withdrawal amount?": "最小出金額はいくらですか。",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "最低出金額は、ほとんどの決済システムで1050米ドルからとなります。<br>暗号通貨については、この額は50米ドルからとなります（ビットコインなど特定の通貨ではもっと高くなることもあります）。",
    "Do I need to provide any documents to make a withdrawal?": "出金するために何か書類を提出する必要がありますか。",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "通常、資金を引き出すための追加書類は必要ありません。しかし、当社の裁量で、お客様の個人情報の確認を求め、特定の書類を要求することがあります。通常、これは違法取引、金融詐欺、および違法に取得された資金の使用に関連する活動を防止するために行われます。",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "そのような書類は最小限であり、それらを提供するために多くの時間と労力を費やすことはありません。",
    "How long does it take to withdraw funds?": "資金を引き出すためにどのぐらい時間がかかりますか。",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "出金手続きは、同時に処理される要求数に影響されるので、平均して、クライアントの要求を受け取った日から1〜5日かかります。当社は常に、クライアントから要求を受け取った日に直接支払いを行うようにしています。",
    "Is there any fee for depositing or withdrawing funds from the account?": "口座への入金や出金に手数料はかかりますか。",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "いいえ、入金操作にも出金操作にも、手数料はかかりません。",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "ただし、決済システムが手数料を請求し、内部の通貨両替レートを使用することを考慮に入れてください。",
    "Can I close my account? How to do it?": "アカウントを閉鎖できますか。どうすればよいですか。",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "個人アカウントでは、プロフィールページの下部にある「アカウント削除」ボタンをクリックすることでアカウントを削除できます。",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "個人アカウントの情報に入力ミスがあった場合、どのように修正すればよいですか。",
    "You have to contact the technical support service on the Company's website and edit the profile.": "当社ウェブサイトのテクニカルサポートサービスにご連絡いただき、プロフィールを編集していただく必要があります。",
    "What data is required to register on the Company website?": "このウェブサイトへ登録するには、どんな情報が必要ですか。",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "デジタルオプションで収益を得るには、まず取引を行うためのアカウントを開設する必要があります。そのためには、当社のウェブサイトに登録する必要があります。",
    "The registration process is simple and does not take much time.": "登録手続きは簡単で、それほど時間はかかりません。",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "アンケートを記入して提出する必要があります。その際、以下の情報を入力する必要があります。",
    "name (in English)": "名前（英語）",
    "email address (indicate the current, work, address)": "メールアドレス（現住所、勤務先、住所を記入）",
    "telephone (with a code, for example, + 44123 ....)": "電話番号（例：+ 44 123…のようにコード付き）",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "今後、システムにアクセスする際に使用するパスワード（個人アカウントへの不正アクセスのリスクを最小限に抑えるため、小文字、大文字、数字を使用した複雑なパスワードを作成することをお勧めします。パスワードは第三者に開示しないでください)",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "アンケートにお答えいただいた後、取引用のアカウントを開設する方法がたくさん提示されます。",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "アカウントはどのような通貨で開設できますか。通貨を変更することはできますか。",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "デフォルトでは、取引アカウントは米ドルで開設されます。しかし、お客様の便宜のために、異なる通貨で複数のアカウントを開設することができます。",
    "A list of available currencies can be found on your profile page in your Client's account.": "利用可能な通貨のリストは、お客様のクライアントアカウントのプロフィールページで確認することができます。",
    "What are digital options?": "デジタルオプションとは何ですか。",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "オプション - 任意の原資産（株式、通貨ペア、石油など）に基づくデリバティブ金融商品。",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "デジタルオプション - 一定期間の資産の価格変動で利益を上げるために使用される非標準オプションです。",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "デジタルオプションは、取引当事者が合意した条件により、当事者が決めた時期に、一定の収入（取引収入と資産価格の差）または損失（資産価値の額）をもたらします。",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "デジタルオプションはあらかじめ固定価格で購入されるため、利益の規模も潜在的な損失の規模も取引前から知ることができます。",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "これらの取引のもう一つの特徴は、期限付きということです。どんなオプションにも独自の期間（有効期限や締結時期）があります。",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "原資産の価格がどの程度変化したか（高くなったか、低くなったか）にかかわらず、オプションに勝った場合は、必ず固定の支払いが行われます。したがって、リスクはオプションの取得金額によってのみ制限されます。",
    "What is the expiration period of a trade?": "取引の有効期限はどのくらいですか。",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "有効期限とは、取引が完了（終了）したとみなされ、その結果が自動的に集計されるまでの時間です。",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "デジタルオプションの取引を終了させる場合、取引の実行時間（1分、2時間、1ヶ月など）を個別に決定することができます。",
    "What is the gist of digital options trading?": "デジタルオプション取引の要点は何ですか。",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "実際、デジタルオプションは、最もシンプルなタイプのデリバティブ金融商品とされています。デジタルオプション市場で儲けるためには、ある資産の市場価格が到達する価値を予測する必要はありません。",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "取引プロセスの原理として、すべては1つの単一のタスクの解決にかかります。つまり、契約が実行されるまでに資産の価格が増加または減少します。",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "このようなオプションの一つの側面として、原資産の価格が取引が締結された瞬間からその終了まで、100ポイントまたは1ポイント変化しても全く問題ではないです。重要なのは、この価格がどの方向に動くかだけです。",
    "If your prognosis is correct, in any case you get a fixed income.": "ご予測が正しい場合は、どのような場合でも、一定の収入を得ることができます。",
    "What are the possible results of the placed trades?": "取引を実行した結果、どのようなことが起こるのでしょうか。",
    "There are three possible outcomes in the digital options market:": "デジタルオプション市場には可能な結果が3つあります。",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "原資産の価格変動の方向に対するご予測が正しい場合、収益を得ることができます。",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "オプションが成立した時点で、お客様の予測が誤っていた場合、資産価値の大きさに比例した損失が発生します（つまり、実際、お客様が投資額を失うのです）。",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "取引の結果がゼロである場合、お客様には投資が返却されます（原資産の価格が変化せず、オプションが購入時の価格で締結されています）。したがって、お客様のリスクのレベルは常に資産価値の大きさによってのみ制限されます。",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "今ご利用中の取引プラットフォームには、デジタルオプションの取引プロセスを自分のお金を使わずに理解するためのデモアカウントがありますか。",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "はい、取引スキルを向上させ、当社の取引プラットフォームの機能をテストするために、デモアカウント（無料）を使用することができます。これは一種のシミュレータで、まず試してみてから実際の取引に移行することができます。このようなデモアカウントは、経験豊富なトレーダーが専門的なスキルをレベルアップさせるのにも適しています。",
    "The balance of such an account is 10,000 units.": "このようなアカウントの残高は10,000単位です。",
    "What determines profit size?": "利益の規模を決めるのは何ですか。",
    "There are several factors that affect the size of your profit:": "収益を左右するいくつかの要因があります。",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "市場で選択した資産の流動性（市場で需要が高いほど、より多くの利益を受け取ることができます）",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "取引の時間（午前中の資産の流動性と午後の資産の流動性は大きく異なる場合があります）",
    "tariffs of a brokerage company": "証券会社の料金体系",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "市場の変化（経済事象、金融資産の変化など）",
    "What are the varieties of digital options?": "デジタルオプションにはどのような種類がありますか。",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "オプション取引を行うには、オプションの基礎となる原資産を選択する必要があります。お客様のご予測は、この資産に対して実施されます。",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "簡単に言えば、デジタル契約を購入すると、実際にその原資産の価格変動に賭けることになります。",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "原資産は、取引を締結する際に価格が考慮される「アイテム」です。デジタルオプションの基礎となる資産は、通常市場で最も人気のある製品です。4つのタイプがあります。",
    "securities (shares of world companies)": "証券（世界企業の株式）",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "通貨ペア（ユーロ/米ドル、英ポンド/米ドルなど）",
    "raw materials and precious metals (oil, gold, etc.)": "原材料及び貴金属（原油、金など）",
    "indices (S&P 500, Dow, dollar index, etc.)": "指数（S&P500、ダウ、ドル指数など）",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "普遍的な原資産は存在しません。オプションは、ご自身の知識、洞察、様々な分析情報、そして特定の金融商品の市場分析で選択することをおすすめします。",
    "What is a trading platform and why is it needed?": "取引プラットフォームとは何ですか、なぜそれが必要ですか。",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "取引プラットフォーム - 異なる金融商品を利用して取引（操作）を行うことができる複合型ソフトウェア。また、見積の価値、リアルタイムの市場ポジション、会社の行動などの様々な情報へアクセスできます。",
    "How to learn quickly how to make money in the digital options market?": "デジタルオプション市場で儲ける方法を素早く学ぶには？",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "正しく予測された取引数が最大となるような独自の取引戦略を立て、それに従って取引を実行する",
    "diversify your risks": "リスクを分散させる",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "戦略を練り、多様化する投資オプションを探すには、市場を常に確認し、様々な情報源（インターネットリソース、専門家の意見、特定の分野のアナリストなど）から得られる分析・統計情報を研究することが役立ちます。そのうちの一つが当社のウェブサイトです。",
    "Is the download of the program to a computer or smartphone required?": "コンピュータやスマートフォン用のプログラムをダウンロードする必要ですか。",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "いいえ、必要ありません。当社のウェブサイト上で提示されたフォームに登録し、個人用のアカウントを開設するだけです。",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "取引に成功した場合、会社はどのよう費用でクライアントに利益を支払うのですか。",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "当社はクライアントとともに利益を得ています。当社は、クライアントが選択した取引戦略の成功に基づき、特定割合の利益を得ています。そのために、利益を生む取引のシェアが利益を生まない取引のシェアを大幅に上回っていることに関心があります。",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "また、お客様が行う取引は、当社の取引量となり、ブローカーや取引所に転送され、流動性供給者のプールに含まれ、市場自体の流動性を高めることにつながります。",
    "What is account verification?": "アカウント認証とは？",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "デジタルオプションの認証は、個人データを確認するために、クライアントに追加書類を提出してもらう手続きです。認証はできるだけシンプルで、必要な書類も最小限です。例えば、会社は以下のことを要求することができます。",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "クライアントのパスポートの最初の見開き（パスポートの写真付きページ）のカラースキャン",
    "identify with the help of a \"selfie\" (photograph of himself)": "自撮り写真による本人確認",
    "confirm the address of registration (residence) of the Client, etc": "クライアントの登録住所（居住地）の確認など",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "当社は、お客様および入力されたデータを完全に識別できない場合、任意の書類を要求することができます。",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "書類の電子コピーが当社に提出された後、データ確認のため、お客様はしばらく待つ必要があります。",
    "How to understand that I need to go through account verification?": "アカウント認証を受ける必要があるかどうかをどうすればわかるのですか。",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "認証が必要な場合は、電子メールおよび/またはSMS通知でお知らせします。",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "また、登録時にご提供いただいた連絡先（特にメールアドレス、電話番号）を使用します。そのため、正確な情報を提供するようお願いいたします。",
    "How do I know that I successfully passed verification?": "認証に合格したかどうかどうやって分かるのですか。",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "アカウントの認証プロセスが完了し、当社の取引プラットフォームで操作を続行できるようになると、電子メールおよび/またはSMS通知が届きます。",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "ウェブサイトに登録する際に、他人の（偽の）データを提出することは可能ですか。",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "いいえ。クライアントは、当社のウェブサイトで自己登録を行い、登録フォームで尋ねられた問題についてご自分に関する完全で正確な情報を提供し、最新情報を更新する必要があります。",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "お客様の身元確認を行う必要がある場合、書類の提出を求めたり、お客様を当社事務所にお招きしたりすることがあります。",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "登録項目に入力されたデータと提出された書類のデータが一致しない場合、個人プロフィールがブロックされることがあります。",
    "How long does the verification process take?": "認証にはどれくらいの時間がかかりますか。",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "当社に関連書類が届いてから5（五）営業日以内です。",
    "Is there a minimum amount that I can deposit to my account at registration?": "登録時にアカウントに入金できる最低額という制限はありますか。",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "当社の取引プラットフォームの利点は、アカウントに多額の入金をする必要がないことです。少額を投資することで取引を開始することができます。最低入金額は10米ドルです。",
    "How can I deposit?": "どのように入金することができますか。",
    "It is very easy to do. The procedure will take a couple of minutes.": "とても簡単です。手続きは2～3分で終わります。",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "取引ウィンドウを開き、タブ右上の緑色の「入金」ボタンをクリックします。",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "また、アカウントプロフィールの「入金」ボタンをクリックすることで、個人アカウントから入金することができます。",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "次に、アカウントに入金する方法を選択する必要があります（当社は、個人アカウントに表示されている多くの便利な方法を提供しています）。",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "それから、アカウント入金の通貨を指定し、それに応じてアカウント自体の通貨も指定されます。",
    "Enter the amount of the deposit.": "出金額を入力します。",
    "Fill out the form by entering the requested payment details.": "要求された支払いの詳細など、フォームに必要事項をご記入ください。",
    "Make a payment.": "支払を行います。",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "取引プラットフォームのアカウントに入金する必要がありますか、そしてどのくらいの頻度で行う必要がありますか。",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "デジタルオプションを使用するには、個人用アカウントを開設する必要があります。実際の取引を成立させるには、購入したオプションの金額分の入金する必要があります。",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "企業用トレーニングアカウント（デモアカウント）を使用して、現金なしで取引を開始することができます。このようなアカウントは無料で、取引プラットフォーム機能を実証するために作成されます。このようなアカウントでデジタルオプションの取得を練習し、取引の基本原則を把握し、様々な方法や戦略を試し、または洞察を評価することができます。",
    "What is the minimum deposit amount?": "最低入金額はいくらですか。",
    "{number} questions": "{number}の質問",
    "Verification": "認証",
    "Payment": "お支払い",
    "Payouts": "配当金",
    "Ask a specialist": "専門家にお問い合わせ",
    "FAQ": "よくあるご質問",
    "Didn't find an answer to your question?": "ご質問に対する回答が見つかりませんでしたか。",
    "Open knowledge base": "オープンな知識ベース",
    "Contact support": "サポートにお問い合わせ",
    "Open FAQ page": "よくあるご質問ページを開く",
    "New message": "新しいメッセージ",
    "Tournaments": "トーナメント",
    "To end": "終了",
    "Until start": "開始まで",
    "Front side": "前面",
    "Back side (if any)": "裏面(ある場合)",
    "Tourna-ments": "トーナメント",
    "Deletion of Account and Personal Data": "アカウントおよび個人データの削除",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "アカウントと個人データを削除する場合、Quotexプラットフォームで該当アカウントに永久にアクセスできなくなります。データは完全に削除され、後でアカウントを復元することはできません!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "注:アカウントを削除する前に、すべての未決済取引と保留中の注文を完了してください。",
    "Request Deletion": "削除依頼",
    "To delete your account, please contact support service": "アカウントを削除するには、サポートサービスにお問い合わせください",
    "Two-factor authorization via Email": "電子メールを利用した二要素認証",
    "This feature is for experienced users who are familiar with Google Authenticator.": "この機能は、Google Authenticatorに精通しているベテランユーザー向けです。",
    "Please note!": "ご注意ください!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "接続後に何らかの理由でGoogle Authenticatorにアクセスできなくなった場合、アカウントにアクセスできなくなります。",
    "Proceed to setup": "設定に進む",
    "Account protection method": "アカウントを保護する方法",
    "Here's how to set it up:": "設定方法は次のとおりとなります。",
    "Please enter the PIN-code we've just sent to your email:": "送信されたメールの中のPINコードを入力してください。",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "お疲れ様でした。これで、Google Authenticatorによる2段階認証プロセスが正常に有効になりました。次回ログインすると、コードの入力が求められます。",
    "If you ever lose access to your 2-step verification device, please": "2 段階認証プロセス デバイスにアクセスできなくなった場合は、",
    "Confirm account deletion": "アカウント削除の確認",
    "To delete your account, follow the link in the email sent to": "アカウントを削除するには、送信されたメールのリンクで手続きを進めてください",
    "Image requirements": "画像の要件",
    "Email": "電子メール",
    "Сonfirmation": "確認",
    "Change protection method": "保護方法の変更",
    "First, enter the code from the Google Authenticator app:": "まず、Google Authenticatorアプリのコードを入力します。",
    "First, enter the PIN-code we've just sent to your email:": "まず、送信されたメールの中のPINコードを入力します。",
    "Nice work. You've successfully enabled 2-step verification via Email.": "おめでとうございます。これで、電子メールを利用した二要素認証機能が有効になりました。",
    "Receiving codes via Email": "メールでコードを受け取る",
    "Receiving codes via Google Authenticator": "Google Authenticator経由でコードを受信する",
    "Choose a method:": "次の方法から選択します。",
    "To enter the platform": "プラットフォームにアクセスするには",
    "To withdraw funds": "出金するには",
    "day": "日",
    "days2": "2日目",
    "days5": "5日目",

text:'通貨の選択',
text1:'電子メール',
text2:'取引のキャンセル',
text3:'無料クーポンコード',
text4:'私たちはプラットフォームをより便利にするために毎日改善しています。',
text5:'このプラットフォームはお好きですか？他の人に紹介してください！',
text6:'QUOTEXに関するコメントを残してください。コメントをすると、無料の取引キャンセルがもらえます。',
text7:'私たちのカスタマーサポートチームにコメントリンクを送信し、確認後に報酬が送られます。',
text8:'最低入金額',
text9:'最低出金額',
text10:'迅速な出金',
text11:'無料',
text12:'未確認の',
text13:'再送信',
text14:'参加を確認する',
text15:'取引をキャンセル',
text16:'今すぐ優待コードを入力して有効にしてください。いつでも優待コードを使用できます。',
text17:'優待コード：',
text18:'入金残高に応じて30％のボーナスがもらえます',
text19:'現在の通貨',
text20:'残高から引き出せる金額',
text21:'アカウント内で',
text22:'今日',
text23:'昨日',
text24:'週',
text25:'月',
text26:'ネットトレード量',
text27:'WELCOMEは初回入金の30％ボーナスです。',
text28:'初回入金額は$100です。30％のボーナスを受け取るには、入金方法を選択してください。',
text29:'銀行カード',
text30:'銀行',
text31:'選択した支払方法：',
text32:'最低額：',
text33:'最高額：',
text34:'別の方法を選択',
text35:'支払情報',
text36:'金額',
text37:'ボーナスを使用',
text38:'規約と条件',
text39:'優待コードを持っています',
text40:'優待コード',
text41:'優待コードを適用',
text42:'入力された優待コードは正しいです。30％のボーナスがもらえます。',
text43:'%のボーナスをもらいます：',
text44:'次の',
text45:'すべての一般的な質問を確認してください',
text46:'デジタル資産取引を行う場合、個人アカウントを開設する必要があります。実際の取引を行う場合は、オプション価格に応じて入金する金額が必ず必要です。',
text48:'{0} 個の優待コードがあります',
text49:'この優待コードを使用して損失取引をキャンセルできます',
text50:'未ヘッジの取引でのみキャンセルできます。キャンセル額は優待コードの最大値を超えてはいけません。',
text51:'状態',
text52:'使用',
text53:'まだ優待コードの履歴がありません。以下のボタンを使用して優待コードを追加できます。',
text54:'履歴を表示',
text55:'優待コードを入力',
text56:'プライバシー情報',
text57:'空白',
text58:'名',
text59:'姓',
text60:'生年月日',
text61:'国',
text62:'保存',
text63:'住所',
text64:'ファイルの検証',
text65:'アカウントを確認するには、個人情報を入力する必要があります。',
text66:'セキュリティ',
text67:'二段階認証',
text68:'パスワードを変更',
text69:'古いパスワード',
text70:'新しいパスワード',
text71:'新しいパスワードを再入力',
text72:'アカウントの削除',
text73:'クレジットカードまたはデビットカードの検証',
text74:'新しい銀行カードを追加',
text75:'検証が必要なクレジットカードやデビットカードはありません',
text76:'取引履歴がまだありません。',
text77:'始点',
text78:'期限',
text79:'フィルタを適用',
text80:'前へ',
text81:'次へ',
text82:'利益',
text83:'キャンセル済み',
text84:'アカウント',
text85:'手数料',
text86:'プラットフォームアカウントから資金を銀行口座や電子ウォレットに引き出すことができます。いつでも引き出しをリクエストできます。引き出しリクエストは3営業日以内に処理されます。',
text87:'入金',
text88:'過去のいくつかのリクエスト',
text89:'引き出し履歴',
text90:'マイリクエスト',
text91:'リクエストを送信',
text92:'よくある質問',
text93:'送信したリクエストはありません',
text94:'ご不明な点がある場合は、よくある質問から回答を見つけることができます。',
text95:'Copyright © 2024Quotex. All rights reserved.',
text96:'ログインして使用',
text97:'パスワード',
text98:'私を覚えておく',
text99:'情報を戻す',
text100:'完了',

text104:'インテリジェントな投資のためのイノベーション プラットフォーム',
   text105: 'サインアップして、取引方法を学ぶデモ口座で 10,000 ドルを獲得',
   text106:'登録',
   text107:'デモ口座を開設してください',
   text108:'便利な取引インターフェイス',
   text109:'最も重要なことである取引から逸脱しないように、最もシンプルで便利なインターフェイスを作成しました。 ',
   text110:'組み込み信号',
   text111:'取引戦略を検討してください。当社は、お客様が独自の効果的な戦略を開発できるよう、最大 87% の最も正確で革新的なシグナルを提供します。 ',
   text112:'取引インジケーター',
   text113:'最も有益な取引指標を集めました。 このインジケーターを使用して口座残高を増やします。 ',
   text114:'完璧なスピード',
   text115: '私たちのプラットフォームは最も革新的なテクノロジーを使用しており、驚異的な速度を実現します。 ',
   text116:'デモアカウントで試してみる',
   text117:'もっとお金を稼ぐために',
   text118:'正しい予測を立ててください',
   text119:'価格は強気ですか、それとも弱気ですか? ',
   text120:'デモ口座を開設してください',
   text121:'クリック',
   text122:'いずれかのボタンをクリックして結果を確認してください',
   text123:'価格は上がります',
   text124:'価格は下がります',
   text125:'結果を待っています...',
   text126:'あなたの利益',
   text127:'申し訳ありませんが、あなたの予測は間違っています',
   text128:'再試行',
   text129:'あなたの利益',
   text130:'「おめでとうございます！」 あなたの予測は正しいです',
   text131:'お金を稼ぎ始めましょう',
   text132:'モバイルアプリはいつでも利用可能',
   text133: 'iPhone または Android 用の使いやすいアプリをダウンロードして取引を始めましょう! ',
   text134:'利用可能な時間',
   text135:'取引を開始',
   text136:'3ステップ',
   text137:'わずか数分でリアルアカウントを無料で開設できます',
   text138:'ワンクリックでデモ口座で取引',
   text139:'練習',
   text140:'デモアカウントとトレーニング資料でスキルを向上させてください',
   text141:'デモアカウントで指導を始めましょう',
   text142:'実際の口座に入金して取引を開始してください',
   text143:'410 以上の商品、最低取引額は 10 ドルです',
   text144:'ジャンプ デポジット方法',
   text145:'よくある質問',
   text146:'「どうやってマスターするの？」 ',
   text147:'デモアカウントを登録し、このアカウントの使用を練習してください。 デモ口座は実際の口座と同じですが、入金は必要ありません。 ',
   text148:'出金の処理にはどのくらい時間がかかりますか? ',
   text149: '出金プロセスの処理には、対応するユーザー要求の受信日から平均して 1 ～ 5 日かかりますが、これは同時に処理される要求の数にのみ依存します。 当社は常に、ユーザーの請求を受け取った日に直接支払うよう努めます。 ',
   text150:'取引プラットフォームとは何ですか? 効果は何ですか？ ',
   text151: '取引プラットフォームはソフトウェアシステムです。ユーザーは、このシステムが提供する金融商品を使用して取引（操作）を行うとともに、会社が提供する株価、リアルタイムの市場状況、会社の行動などの情報を使用します。 ',
   text152:'携帯電話を使用して取引を行うことはできますか? ',
   text153:'私たちのプラットフォームは最も革新的なテクノロジーを使用しており、あらゆるコンピューターまたはスマートフォンのブラウザーで開くことができます',
   text154:'最低入金額はいくらですか? ',
   text155: 'この会社の取引プラットフォームの利点は、アカウントに多額の資金を入金する必要がないことです。 少額の資金を投資することで取引を始めることができます。 最低入金額は10ドルです。 ',
   text156:'アカウントへの入金または引き出しの際に手数料はかかりますか? ',
   text157:'いいえ。 当社は、口座への入金や資金の出金などの操作に手数料を請求しません。 ',
   text158: 'ただし、支払いシステムでは手数料が請求され、異なる通貨為替レートが使用される場合があることに注意する必要があります。 ',
   text159:'すべての質問に移動',
   text160:'Quotex: イノベーション プラットフォーム',
   text161:'デジタル資産取引',
   text162:'実際の口座を開設してください',
   text163:'パートナー',
   text164:'デモアカウント',
   text165:'連絡先情報',
   text166:'重要な質問',
   text167:'財政問題',
   text168:'検証',
   text169:'規制',
   text170:'プライバシーポリシー',
   text171:'支払い条件',
   text172:'サービス契約',
   text173:'リスク警告',
   text174:'取引操作ルール',
   text175:'取引以外の運用ルール',
   text176:`リスク警告: デジタル資産取引プラットフォームでの取引や融資資金調達手段の使用には重大なリスクが伴う可能性があり、投資資金の完全な損失につながる可能性があります。 許容できる以上の投資は行わず、それに伴うリスクを理解する必要があります。 借りた資金調達手段を使用した取引は、ほとんどの投資家には適していません。 株式などの借入資金を使用せずに取引することは、株価が下落したり上昇したりする可能性があり、たとえ勝ったとしても投資額よりもはるかに少ない利益しか得られない可能性があるため、リスクも伴います。
   過去の成功が将来の成功を保証するものではありません。 取引する前に、自分の取引経験とレベル、投資目標に注意し、必要に応じて独立した財務アドバイザーに連絡してください。 ユーザーは、居住国の法律および規制に基づいて、Quotex ブランドのサービスの使用が許可されているかどうかを判断する必要があります。 `,
}