<template>
  <div class="panel-leader-board">
    <div class="panel-leader-board__header-block">
      <div class="panel-leader-board__button-back" @click="$parent.showRanking">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.47577 1.20379C7.73041 0.928767 7.73041 0.492463 7.47577 0.217438C7.20345 -0.0766788 6.7501 -0.0708499 6.49219 0.225389L2.52439 4.51083C2.40025 4.64491 2.33342 4.82538 2.33342 5.00401C2.33342 5.18867 2.39927 5.36204 2.52439 5.49718L6.49219 9.78262C6.76054 10.0725 7.20741 10.0725 7.47577 9.78262C7.73041 9.5076 7.73041 9.07129 7.47577 8.79627L3.95747 4.99631L7.47577 1.20379Z" fill="white"></path></svg>
      </div>
      <div class="panel-leader-board__header">
        <div class="panel-leader-board__header-title">{{ $t('Leader Board') }}</div>
        <div class="panel-leader-board__header-subtitle">{{ $t('of the Day') }}</div>
      </div>
    </div>
    <div class="panel-leader-board__body">
      <div class="position" dir="ltr">
        <div class="position__wrapper"></div>
        <div class="position__header">
          <div class="position__header-name">
            <svg class="flag-jp"><use xlink:href="../../../assets/svg/flags.svg#flag-jp"></use></svg>
            #40590966
          </div>
          <div class="position__header-money --green">$0.00</div>
        </div>
        <div class="position__loading"><span class="position__expand" style="width: 0%"></span></div>
        <div class="position__footer">
          <div class="position__footer-title">{{ $t('Your position:') }}</div>
          44287
        </div>
      </div>
      <div class="panel-leader-board__body-rating" @click="show = true">
        <svg class="icon-rating"><use xlink:href="../../../assets/svg/spritemap.svg#icon-rating"></use></svg>
        <div>{{ $t('How does this rating work?') }}</div>
      </div>
    </div>
    <div class="panel-leader-board__items">
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key">
            <img src="../../../assets/svg/top-gold.svg" alt="top-gold" />
            <div class="panel-leader-board__item-key__place">1</div>
          </div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-in"><use xlink:href="../../../assets/svg/flags.svg#flag-in"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">BOLT</div>
        </div>
        <div class="panel-leader-board__item-money --green">$15,271.00</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key">
            <img src="../../../assets/svg/top-serebro.svg" alt="top-gold" />
            <div class="panel-leader-board__item-key__place">2</div>
          </div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-br"><use xlink:href="../../../assets/svg/flags.svg#flag-br"></use></svg>
            <div class="panel-leader-board__item-avatar"><img src="https://quotex.com/en/user/avatar/view/24/46/28/81/avatar_afad2a268ce43adf1dea25a1e5d587fc.jpeg?w=16" /></div>
          </div>
          <div class="panel-leader-board__item-name">Elias_Al…</div>
        </div>
        <div class="panel-leader-board__item-money --green">$11,746.26</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key">
            <img src="../../../assets/svg/top-bronza.svg" alt="top-gold" />
            <div class="panel-leader-board__item-key__place">3</div>
          </div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-pk"><use xlink:href="../../../assets/svg/flags.svg#flag-pk"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">Perfect …</div>
        </div>
        <div class="panel-leader-board__item-money --green">$10,722.00</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">4</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-pk"><use xlink:href="../../../assets/svg/flags.svg#flag-pk"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">FATHER O…</div>
        </div>
        <div class="panel-leader-board__item-money --green">$10,040.59</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">5</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-pk"><use xlink:href="../../../assets/svg/flags.svg#flag-pk"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">#40029552</div>
        </div>
        <div class="panel-leader-board__item-money --green">$9,557.20</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">6</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-in"><use xlink:href="../../../assets/svg/flags.svg#flag-in"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">#VAISHU#</div>
        </div>
        <div class="panel-leader-board__item-money --green">$9,087.90</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">7</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-pk"><use xlink:href="../../../assets/svg/flags.svg#flag-pk"></use></svg>
            <div class="panel-leader-board__item-avatar"><img src="https://quotex.com/en/user/avatar/view/54/27/92/23/avatar_b13271f9aadcd2f98bb05bb31839e218.jpg?w=16" /></div>
          </div>
          <div class="panel-leader-board__item-name">#32297245</div>
        </div>
        <div class="panel-leader-board__item-money --green">$8,894.75</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">8</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-in"><use xlink:href="../../../assets/svg/flags.svg#flag-in"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">Iamcoming</div>
        </div>
        <div class="panel-leader-board__item-money --green">$6,029.78</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">9</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-in"><use xlink:href="../../../assets/svg/flags.svg#flag-in"></use></svg>
            <div class="panel-leader-board__item-avatar"><img src="https://quotex.com/en/user/avatar/view/22/18/18/04/avatar_44d0af62e8416d0598d4936df4587390.png?w=16" /></div>
          </div>
          <div class="panel-leader-board__item-name">Saurabh</div>
        </div>
        <div class="panel-leader-board__item-money --green">$5,255.86</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">10</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-bd"><use xlink:href="../../../assets/svg/flags.svg#flag-bd"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">Rakib Ha…</div>
        </div>
        <div class="panel-leader-board__item-money --green">$5,191.03</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">11</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-ec"><use xlink:href="../../../assets/svg/flags.svg#flag-ec"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">D/A Alva…</div>
        </div>
        <div class="panel-leader-board__item-money --green">$4,951.19</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">12</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-in"><use xlink:href="../../../assets/svg/flags.svg#flag-in"></use></svg>
            <div class="panel-leader-board__item-avatar"><img src="https://quotex.com/en/user/avatar/view/05/16/53/73/avatar_c8c44e796c17defa0a8e9ccae2d2583c.png?w=16" /></div>
          </div>
          <div class="panel-leader-board__item-name">JAI GANE…</div>
        </div>
        <div class="panel-leader-board__item-money --green">$4,899.30</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">13</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-in"><use xlink:href="../../../assets/svg/flags.svg#flag-in"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">#40425647</div>
        </div>
        <div class="panel-leader-board__item-money --green">$4,159.93</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">14</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-br"><use xlink:href="../../../assets/svg/flags.svg#flag-br"></use></svg>
            <div class="panel-leader-board__item-avatar"><img src="https://quotex.com/en/user/avatar/view/54/93/04/3/avatar_1fee3676759bf2f6eb0a6b6711ff3853.png?w=16" /></div>
          </div>
          <div class="panel-leader-board__item-name">LUIS VAZ…</div>
        </div>
        <div class="panel-leader-board__item-money --green">$4,103.58</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">15</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-in"><use xlink:href="../../../assets/svg/flags.svg#flag-in"></use></svg>
            <div class="panel-leader-board__item-avatar"><img src="https://quotex.com/en/user/avatar/view/33/25/89/01/avatar_d1542a1f4015e72824c42d398ce51680.jpg?w=16" /></div>
          </div>
          <div class="panel-leader-board__item-name">MODERN T…</div>
        </div>
        <div class="panel-leader-board__item-money --green">$3,720.00</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">16</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-ph"><use xlink:href="../../../assets/svg/flags.svg#flag-ph"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">#32492714</div>
        </div>
        <div class="panel-leader-board__item-money --green">$3,710.00</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">17</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-in"><use xlink:href="../../../assets/svg/flags.svg#flag-in"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <!-- <img src="https://quotex.com/en/user/avatar/view/33/58/56/04/avatar_9bc1475f1ea06ccd0889738c879e9d8e.jpeg?w=16" /> -->
            </div>
          </div>
          <div class="panel-leader-board__item-name">Rohit</div>
        </div>
        <div class="panel-leader-board__item-money --green">$3,255.00</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">18</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-in"><use xlink:href="../../../assets/svg/flags.svg#flag-in"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">#29264164</div>
        </div>
        <div class="panel-leader-board__item-money --green">$3,216.69</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">19</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-in"><use xlink:href="../../../assets/svg/flags.svg#flag-in"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">Magical …</div>
        </div>
        <div class="panel-leader-board__item-money --green">$3,136.00</div>
      </div>
      <div class="panel-leader-board__item" dir="ltr">
        <div class="panel-leader-board__item-wrapper"></div>
        <div class="panel-leader-board__item-inform">
          <div class="panel-leader-board__item-key"><div class="panel-leader-board__item-key__place opacity">20</div></div>
          <div class="panel-leader-board__item-block">
            <svg class="flag flag-pk"><use xlink:href="../../../assets/svg/flags.svg#flag-pk"></use></svg>
            <div class="panel-leader-board__item-avatar">
              <svg class="icon-avatar-default"><use xlink:href="../../../assets/svg/spritemap.svg#icon-avatar-default"></use></svg>
            </div>
          </div>
          <div class="panel-leader-board__item-name">73829199</div>
        </div>
        <div class="panel-leader-board__item-money --green">$3,054.67</div>
      </div>
    </div>
    <Operation v-if="show" @close="showHanlde" />
  </div>
</template>
<script>
import Operation from '../../../components/Operation.vue'
export default{
    components:{
        Operation
    },
    data(){
        return{
            show:false
        }
    },
    methods:{
        showHanlde(){
            this.show = !this.show
        }
    }
}

</script>
