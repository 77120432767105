export default {
  '+{value}% profit': '+{value}% ربح',
  'Selfie verification required': 'التحقق باستخدام سيلفي مطلوب',
  'In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.':
    'من أجل إتمام خطوات التحقق من الحساب الضرورية, نطلب منك توفير صورة شخصية عالية الدقة (سيلفي) وأنت تحمل وثيقة إثبات هوية بتاريخ ساري وتوقيعك على ورقة. يجب أن يظهر في الصورة وجهك وجسمك ويداك.<br><br> يجب أن تكون بيانات الوثيقة واضحة وقابلة للقراءة.',
  'Your documents have been checked, now your identity is verified.': 'تم التحقق من مستنداتك. اكتمل التحقق من  هويتك الآن.',
  'You need fill identity information before verification your profile.': 'عليك تعبئة معلومات الهوية قبل التحقق من ملفك الشخصي.',
  'Documents verification': 'التحقق من الوثائق',
  "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": 'التحقق من بيانات الملف الشخصي يتطلب توفير وثيقة رسمية تثبت هوية العميل. يمكن بدء هذا الإجراء عبر قسم الأمن التابع للشركة في أي وقت.',
  'Upload Photo': 'رفع صورة',
  Nickname: 'الكنية',
  'First Name': 'الاسم الأول',
  'Last Name': 'الاسم الأخير',
  'The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.': 'يجب إدخال الاسم كما يظهر تماماً في المستند. عند تقديم معلومات غير صحيحة، قد يتم حذف الحساب دون سابق إنذار.',
  'The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.': 'يجب إدخال الاسم الأخير كما يظهر تماماً في المستند. عند تقديم معلومات غير صحيحة، قد يتم حذف الحساب دون سابق إنذار.',
  "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": 'لن يكون من الممكن تغيير تاريخ ميلادك لاحقاً. إن قمت بإدخاله بشكلٍ خاطئ، لن تستطيع سحب الأموال من  حسابك.',
  Empty: 'فارغ',
  'Date of birth': 'تاريخ الميلاد',
  Unverified: 'غير متحقق منه',
  Verified: 'متحقق منه',
  Resend: 'إعادة الإرسال',
  Country: 'الدولة',
  Address: 'العنوان',
  'Change Identity Info': 'تغيير معلومات الهوية',
  'Identity Info': 'معلومات الهوية',
  'Two-factor authentication on login': 'المصادقة الثنائية عند تسجيل الدخول',
  'Two-factor authentication on withdrawal': 'المصادقة الثنائية عند السحب',
  Active: 'نشط',
  Inactive: 'غير نشط',
  Setup: 'الضبط',
  'Two-step verification': 'التحقق بخطوتين',
  Security: 'الأمان',
  'Google Authenticator': 'Google Authenticator',
  'Scan the QR code': 'مسح رمز QR ',
  "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": 'نحن نأخذ أمن معلوماتك على محمل الجد، لذلك نتطلب التحقق بخطوتين. فيما يلي كيف تقوم بضبط التحقق:',
  'Install ': 'تثبيت',
  "Can't scan?": 'لا يمكنك المسح؟',
  'Click here.': 'أنقر هنا.',
  'Enter this code into Google Authenticator.': 'أدخل هذا الكود في Google Authenticator.',
  'Back to QR code.': 'عودة لرمز QR.',
  'Enter the 6-digit code from Authenticator below': 'أدخل الرمز المكون من 6 أرقام من Authenticator فيما يلي',
  'Enter 6-digit code...': 'أدخل الرمز المكون من 6 أرقام...',
  "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": 'أحسنت. قمت بتفعيل التحقق بخطوتين بنجاح. في المرة القادمة التي تسجل الدخول بها، سيطلب منك إدخال رمز.',
  Code: 'الرمز',
  'contact support': 'الاتصال بالدعم',
  'If you ever lose access to your 2-step verification device, please ': 'في حال فقدت إمكانية الوصول لجهازك الخاص بعملية التحقق بخطوتين، يرجى ',
  'Yes, activate': 'نعم، تنشيط',
  Cancel: 'إلغاء',
  Close: 'إغلاق',
  Language: 'اللغة',
  Timezone: 'المنطقة الزمنية',
  'Delete Account': 'حذف الحساب',
  'Verification Credit / Debit Cards': 'التحقق من البطاقات الائتمانية / المدينة',
  'Add New Card': 'إضافة بطاقة جديدة',
  'Wait for loading...': 'يرجى انتظار التحميل...',
  'You don’t have any credit / debit cards for verification': 'ليست لديك بطاقات ائتمانية / مدينة للتحقق منها',
  Verificate: 'التحقق',
  'Verification Card': 'التحقق من البطاقة',
  'Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.': 'يرجى تقديم بطاقة هويتك (تظهر الصورة والاسم وتاريخ الميلاد) مع الجهة الأمامية من بطاقتك المصرفية (تظهر الرقم وتاريخ انتهاء الصلاحية واسمك) على ورقة فارغة بحجم A4. <br> يرجى كتابة "Quotex.io" وأضف توقيعك إليها. <br> التقط صورة لها وقم برفعها.',
  'Card:': 'البطاقة:',
  'Type:': 'النوع:',
  'Your ID and bank card on an A4 sheet': 'هويتك وبطاقتك المصرفية على ورقة بحجم A4',
  'Yes, confirm': 'نعم، تأكيد',
  'Click or drag the image': 'اضغط أو اسحب الصورة',
  Card: 'البطاقة',
  Documents: 'المستندات',
  'First, please fill out the “Identity Info” section in your Profile, and click on "Change Identity Info" button to save changes. After that you will be able to add a new card.': 'أولاً قم بتعبئة قسم "معلومات الهوية" في ملفك الشخصي، ثم اضغط على زر "تغيير معلومات الهوية" لحفظ التغييرات. بعد ذلك ستكون قادراً على إضافة بطاقة جديدة.',
  'Specify the card details for further operations for depositing and debiting funds.': 'حدد بيانات البطاقة للعمليات الأخرى لإيداع وخصم الأموال.',
  'Card number:': 'رقم البطاقة:',
  'Expiry month:': 'شهر الانتهاء:',
  'Expiry year:': 'سنة الانتهاء:',
  'Cardholder name:': 'اسم صاحب البطاقة:',
  Next: 'التالي',
  'Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.': 'من فضلك ضع توقيعك وتاريخ اليوم على ورقة، وضع وثيقتك وبطاقة البنك الخاصة بك، ثم التقط صورة وارفعها في النموذج التالي.',
  'Do you have a two-sided document?': 'هل لديك وثيقة بوجهين؟',
  'Upload the back side as a separate file, also on a sheet with the date and your signature.': 'ارفع الجهة الخلفية كملف منفصل، أيضاً على ورقة مع تاريخ اليوم وتوقيعك.',
  'Submit only valid documents': 'أرسل وثائق سارية المفعول فقط',
  'Do not edit or crop photo': 'لا تقم بتعديل أو قص الصورة',
  "Do not upload other people's documents": 'لا ترفع وثائق تعود لأشخاص آخرين',
  'Read all': 'قراءة الكل',
  'Uploading files': 'جارٍ رفع الملفات',
  'For single-sided documents, upload only the front side.': 'للوثائق ذات الجهة الواحدة، ارفع الجهة الأمامية.',
  Back: 'الرجوع',
  'Add a card': 'إضافة بطاقة',
  'File Requirements': 'متطلبات الملفات',
  'Scanned images are not accepted for card verification': 'لا نقبل الصور الممسوحة ضوئياً للتحقق من البطاقة',
  'We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)': 'نقبل فقط ملفات تحتوي على جواز سفرك المدني العادي أو بطاقة الهوية (يجب رفع كلا الجهتين كملفات منفصلة)',
  'Driving license CANNOT be accepted': 'لا يمكن قبول رخصة القيادة',
  'External (foreign/international/travel) passport will not be accepted as well': 'كما لن يتم قبول جواز سفر خارجي (أجنبي/دولي/للسفر)',
  'If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature': 'إن كانت الوثيقة بوجهين، تحتاج إلى رفع الجهة الأخرى كملف منفصل، على نفس الورقة، مع تاريخ اليوم وتوقيعك.',
  'All information in the document and on the card should be clear and readable on the photo': 'يجب أن تكون كل بيانات الوثيقة والبطاقة واضحة وقابلة للقراءة في الصورة',
  'Both your document and your card must fit into the photo completely the edges should not be cropped': 'يجب أن تظهر وثيقتك وبطاقتك في الصورة كاملة ولا يجب قص أطرافها',
  'There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data': 'لا يجب أن تكون هناك انعكاسات إضاءة أو أي عيوب صور أخرى قد تجعل قراءة بيانات الوثيقة والبطاقة صعبة',
  'Expired documents and cards will be rejected automatically': 'يتم رفض الوثائق والبطاقات منتهية الصلاحية بشكلٍ تلقائي',
  'If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper': 'إن كانت بطاقتك لا تحمل اسمك، تحتاج إلى رفع لقطة شاشة من كشف حساب بنكي يُظهر رقم بطاقتك واسمك. في بعض الحالات، يسمح بكتابة اسمك يدوياً على نفس الورقة',
  'It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)': 'لا يسمح بإخفاء عناصر من الوثائق تحتوي على بيانات مطلوبة لإكمال عملية التحقق (الاسم، تاريخ الميلاد، الصورة الشخصية)',
  'It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible': 'يسمح بإخفاء الأرقام الثمانية من رقم البطاقة. الأرقام الأربع الأولى والأرقام الأربع الأخيرة يجب أن تكون مرئية بوضوح.',
  'CVV code should be hidden at all times, in every case': 'يجب إخفاء رمز CVV طوال الوقت في كل الحالات',
  'Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed': 'يحظر إجراء أي تعديل على الملفات. يمكن قبول الصور العادية والممسوحة ضوئياً غير المعدلة فقط. لا يسمح بتعديل معلوماتك الشخصية في وثائقك (الاسم، تاريخ الميلاد إلخ)',
  'The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted': 'يجب أن تكون الصورة الشخصية جزءاً من الوثيقة (ملصقة أو مطبوعة عليه). لن يتم قبول الصور المرفقة بشكل منفصل',
  'It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)': 'يسمح فقط برفع وثائق وبطاقات تحمل الاسم المحدد في الملف الشخصي (الذي تم تسجيل حسابك به)',
  'We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified': 'نذكرك بأن إرسال معلومات خاطئة أو مزورة سيؤدي إلى إغلاق أو حظر الحساب حتى يتم توضيح المسألة',
  'The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time': 'تحتفظ الشركة بالحق في أن تطلب منك تقديم أي وثائق إضافية للتحقق من الحساب (بما في ذلك سيلفي خاص بك مع الوثيقة) في أي وقت',
  'For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)': 'لكل الاستفسارات المتعلقة بالتحقق من الوثائق، يمكنك إنشاء تذكرة دعم على المنصة (قسم "المساعدة" هو الزر الأول الذي يحمل أيقونة علامة استفهام تومض، على اللوح أقصى اليسار)',
  Profile: 'الملف الشخصي',
  Deposit: 'إيداع',
  Withdrawal: 'سحب',
  Transactions: 'المعاملات',
  Trades: 'التداولات',
  Market: 'السوق',
  Analytics: 'التحليلات',
  'My current currency': 'عملتي الحالية',
  'Available for withdrawal': 'متوفر للسحب',
  'In the account': 'في الحساب',
  'Percentage of turnover {percent}%': 'نسبة الإيرادات {percent}%',
  'Cashback {percent}%': 'استرداد النقود {percent}%',
  'Currency:': 'العملة:',
  Change: 'تغيير',
  'Live Account': 'حساب حقيقي',
  'Daily budget limit': 'الحد الأقصى للميزانية اليومية',
  'The value cannot exceed your total budget': 'لا يمكن أن يتجاوز المبلغ ميزانيتك الكلية',
  'Demo Account': 'حساب تجريبي',
  Apply: 'تطبيق',
  Edit: 'تعديل',
  'New value': 'قيمة جديدة',
  Confirm: 'تأكيد',
  Logout: 'تسجيل الخروج',
  'No, cancel': 'لا، إلغاء',
  'Exchange Form': 'نموذج الصرف',
  'My Currency:': 'عملتي:',
  'New Currency:': 'العملة الجديدة:',
  'You are exchanging': 'أنت تقوم بصرف',
  'You will receive': 'سوف تتلقى',
  'Exchange Fee': 'رسم الصرف',
  'Password changed': 'تغيير كلمة المرور',
  'Old password': 'كلمة المرور القديمة',
  'New password': 'كلمة المرور الجديدة',
  'Confirm new password': 'تأكيد كلمة المرور الجديدة',
  'Change Password': 'تغيير كلمة المرور',
  'Confirm password must be equal new password value': 'تأكيد كلمة المرور يجب أن يطابق قيمة كلمة المرور الجديدة',
  'About us': 'نبذة عنا',
  Support: 'الدعم',
  Help: 'المساعدة',
  '2-step verification': 'التحقق بخطوتين',
  "Please enter the PIN-code we've just sent to your email": 'يرجى إدخال رمز PIN الذي قمنا بإرساله إلى بريدك الإلكتروني للتو',
  'Please enter the 6-digit code from your authentication app': 'رجاءً أدخل الرمز المكون من 6 أرقام من تطبيق المصادقة الخاص بك',
  'The First Name field is required': 'حقل الاسم الأول مطلوب',
  'The Last Name field is required': 'حقل الاسم الأخير مطلوب',
  'Verification of documents': 'التحقق من الوثائق',
  'Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.': 'يرجى رفع صورة ملونة أو صورة ممسوحة ضوئياً لجواز السفر المدني العادي أو رخصة القيادة أو بطاقة الهوية الوطنية.',
  'Upload Documents': 'رفع وثائق',
  'Why?': 'لماذا؟',
  'Do you want to confirm deleting your account without undo? This action cannot be undone.': 'هل ترغب فعلاً في حذف حسابك دون رجعة؟ لا يمكن التراجع عن هذا الإجراء.',
  'Set up 2-step verification': 'ضبط التحقق بخطوتين',
  'Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.': 'لسوء الحظ، لا يمكنك رفع ملفات في هذا القسم. <br> يرجى الرجوع إلى الرسائل من فريق الدعم وإرفاق الملفات المطلوبة لتذكرة دعم.',
  'The uploaded document does not match the requirements': 'الوثائق المرفوعة لا تطابق المتطلبات',
  "The following documents are eligible for identification: ID, passport, international passport or driver's license.": 'الوثائق التالية مقبولة لعملية التحقق من الهوية: بطاقة هوية، جواز سفر، جواز سفر دولي أو رخصة قيادة',
  'Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.': 'يجب أن تكون الصور ذات جودة عالية، والبيانات سهلة القراءة، وألا يتم قص أطراف الوثائق.',
  'Balance from {amount}': 'الرصيد من {amount}',
  'Not verified': 'غير معرف',
  Clear: 'مسح',
  'In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.': 'لتجنب أي قيود على الخدمة، يرجى إكمال عملية التحقق: قم بتعبئة بياناتك الشخصية ورفع المستندات المطلوبة.',
  'Get verified': 'إكمال التحقق',
  'I got it!': 'حسناً!',
  'Passport.IN': 'Aadhaar',
  'Passport.BR': 'CPF',
  'AADHAAR Verification': 'التحقق من AADHAAR',
  'Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).': 'يرجى رفع صورة ملونة لبطاقة Aadhaar الفعلية الخاصة بك (الجهة الأمامية فقط التي تحمل صورتك ورقم الوثيقة).',
  'Send AADHAAR': 'إرسال AADHAAR',
  'AADHAAR number:': 'رقم AADHAAR:',
  'Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.': 'يرجى رفع صورة ملونة لبطاقة Aadhaar الفعلية الخاصة بك (الجهة الأمامية فقط التي تحمل صورتك ورقم الوثيقة). ☝ لا تقبل النسخ الإلكترونية.',
  'You have already uploaded {number} files. Please wait until they are checked.': 'قمت مسبقاً برفع {number} ملفات. يرجى الانتظار حتى يتم التحقق منها.',
  'Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.':
    'يرجى رفع صورة ملونة لوثيقتك: لبطاقة RG - يجب رفع كلا الجهتين كملفات منفصلة. لـبطاقة CNH - الجهة الأمامية فقط التي تحمل صورتك. يجب أن تكون الوثيقة سارية (أقل من 10 سنوات من تاريخ الإصدار)، ويجب أن يظهر عليها رقم CPF الخاص بك. * في حال عدم وجود رقم CPF في وثيقتك - يرجى رفع صورة منفصلة لرقم CPF لإثبات التسجيل، أو لقطة شاشة لكشف حسابك المصرفي تُظهر اسمك ورقم CPF الخاص بك. ** يرجى عدم رفع أي نسخ إلكترونية. لن يتم قبولها.',
  'Submit for verification': 'إرسال للتحقق',
  Settings: 'الإعدادات',
  'Please upload a color photograph of the front of your national identity card with your photo.': 'يرجى رفع صورة ملونة للجهة الأمامية لبطاقة الهوية الوطنية الخاصة بك تحمل صورتك.',
  Template: 'قالب',
  'Light Mode': 'الوضع النهاري',
  Twilight: 'شفق',
  'Full Night': 'عتمة كاملة',
  "Grid's opacity": 'عتامة الشبكة',
  'Auto-scrolling': 'تمرير تلقائي',
  'Automatic graphic scrolling': 'تمرير رسومات تلقائي',
  '1-click trade': 'تداول بضغطة واحدة',
  'Open trades without confirmation': 'فتح التداولات دون تأكيد',
  'Use timezone': 'استخدم منطقة زمنية',
  'Use your timezone data': 'استخدم بيانات منطقتك الزمنية',
  'Graph colors': 'ألوان الرسوم البيانية',
  'Up Trend': 'الاتجاه الصاعد',
  'Down Trend': 'الاتجاه الهابط',
  Platform: 'منصة التداول',
  'Short order label': 'عنوان أمر بيع',
  'Use short order element mode': 'استخدم وضع عنصر أمر بيع',
  'Perfomance Mode': 'وضع الأداء',
  'Use optimized rendering for chart and candles': 'استخدم العرض المحسن للرسوم البيانية والشموع',
  Background: 'الخلفية',
  'Choose file': 'اختر ملف',
  'Max size — 2 Mb': 'أقصى حجم — 2 ميغابايت',
  'Sign up': 'التسجيل',
  'Sign in': 'تسجيل الدخول',
  'Budget and limits': 'الميزانية والقيود',
  'Daily limit': 'الحد اليومي',
  'Not set': 'غير معين',
  'Total funds': 'إجمالي الأموال',
  'Top up': 'إضافة أموال',
  'Set up': 'ضبط',
  'Set a daily limit on trading to reduce financial risks.': 'قم بضبط حد يومي للتداول لتقليل المخاطر المالية.',
  'Limit amount': 'مبلغ الحد',
  More: 'أكثر',
  'Leader Board': 'لوحة الصدارة',
  Signals: 'المؤشرات',
  Notifications: 'التنبيهات',
  'mark all as read': 'تعليم الكل كمقروء',
  'No notifications': 'لا توجد تنبيهات',
  'No more notifications': 'لا مزيد من التنبيهات',
  'Your position:': 'مركزك:',
  'How does this rating work?': 'كيف يعمل هذا التصنيف؟',
  'of the Day': 'من اليوم',
  'Nothing has been found for current query, try another asset name.': 'لم يتم العثور على أي نتائج للاستعلام الحالي، جرب إدخال اسم أصل آخر.',
  'My rating in the Leader Board': 'تصنيفك في لوحة الصدارة',
  'Participants can only be those who trade in live mode': 'الانضمام متاح فقط لمن يتداول بوضع التداول الحقيقي',
  'Your position depends on the amount of earned money': 'يعتمد مركزك على مقدار الأموال التي جنيتها',
  'Calculated every day, from 00:00 UTC to 23:59 UTC': 'يتم حسابه يومياً، من الساعة 00:00 UTC إلى 23:59 UTC',
  'All traders on our platform participate in this rating. Main features of the rating:': 'كل المتداولين على منصتنا للتداول يشاركون في هذا التصنيف. أهم متصدري التصنيف:',
  'How does it work?': 'كيف يعمل؟',
  'Trading signals': 'إشارات التداول',
  'Past signals': 'الإشارات السابقة',
  'What’s it?': 'ما هو؟',
  'A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.': 'إشارة التداول ليست تعليمات مباشرة للتداول، بل هي توصية محلل. ويجب ألا تحل محل تحليل السوق المستقل.',
  'You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.': 'لديك في المتوسط 10 دقائق للاستفادة من إشارة التداول. بعد هذا الوقت، قد تتغير عروض الأسعار ويكون لها تأثير غير متوقع على نتيجة التداول.',
  Continue: 'متابعة',
  Duration: 'المدة الزمنية',
  'Return back to FAQ': 'العودة للأسئلة المتكررة',
  'Return back to Help': 'العودة للمساعدة',
  'How to withdraw money from the account?': 'كيف يمكن سحب الأموال من الحساب؟',
  'The procedure for withdrawing capital is extremely simple and is carried out through your individual account.': 'إجراء سحب رأس المال سهل للغاية ويتم تنفيذه عبر حسابك الفردي.',
  'The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question "How can I deposit?").': 'الطريقة التي اخترتها للإيداع في الحساب ستكون الطريقة نفسها لسحب الأموال (أنظر السؤال "كيف يمكنني إيداع المال؟").',
  'For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.': 'على سبيل المثال، إن قمت بالإيداع في حسابك عبر نظام فيزا للدفع، ستقوم بسحب الأموال أيضاً عبر نظام فيزا للدفع.',
  "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": 'عند طلب سحب مبلغ كبير بما فيه الكفاية، قد تطلب الشركة في هذه الحالة إكمال عملية تحقق (يتم طلب التحقق وفقاً لتقدير الشركة المطلق)، لهذا من المهم للغاية تسجيل حساب شخصي مخصص لك لتتمكن من إثبات حقك في السحب في أي وقت.',
  'What is the minimum withdrawal amount?': 'ما هو الحد الأدنى لمبلغ السحب؟',
  'The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).': 'يبدأ الحد الأدنى لمبلغ السحب من 10 دولار  أمريكي لمعظم أنظمة الدفع.<br>للعملات الرقمية، يبدأ هذا المبلغ من 50 دولار أمريكي (وقد يكون أعلى لعملات معينة مثل البتكوين).',
  'Do I need to provide any documents to make a withdrawal?': 'هل أحتاج إلى تقديم أي وثائق لإجراء سحب؟',
  'Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.':
    'عادة ، لا تكون هناك حاجة إلى وثائق إضافية لسحب الأموال. ولكن قد تطلب منك الشركة وفقاً لتقديرها تأكيد صحة بياناتك الشخصية عن طريق طلب وثائق معينة. وعادةً يتم ذلك من أجل منع الأنشطة المتعلقة بالتجارة غير المشروعة والاحتيال المالي وكذلك استخدام الأموال التي يتم الحصول عليها بشكل غير قانوني.',
  'The list of such documents is minimum, and the operation to provide them will not take you much time and effort.': 'يتم في هذه الحالة طلب الحد الأدنى من الوثائق، ولن يستغرق توفيرها منك الكثير من الوقت والجهد.',
  'How long does it take to withdraw funds?': 'كم من الوقت يستغرق سحب الأموال؟',
  'On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.':
    'في المتوسط، يستغرق إجراء عملية سحب من يوم إلى خمسة أيام من تاريخ استلام طلب السحب من العميل ويعتمد ذلك فقط على حجم الطلبات التي يتم معالجتها في وقتٍ واحد. علماً بأن الشركة تسعى دائماً إلى إكمال عمليات الدفع مباشرة في يوم استلام الطلب من العميل.',
  'Is there any fee for depositing or withdrawing funds from the account?': 'هل هناك أي رسوم على إيداع أو سحب الأموال من الحساب؟',
  'No. The company does not charge any fee for either the deposit or for the withdrawal operations.': 'لا. لا تتقاضى الشركة أي رسوم سواء للإيداع أو لعمليات السحب.',
  'However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.': 'ومع ذلك، تجدر الإشارة إلى أن أنظمة الدفع قد تقوم بفرض رسومها الخاصة واستخدام سعر صرف داخلي.',
  'Can I close my account? How to do it?': 'هل يمكنني إغلاق حسابي؟ كيف أقوم بذلك؟',
  'You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.': 'يمكنك حذف حساب من حسابك الفردي بالضغط على زر "حذف حساب" من الجزء السفلي من صفحة الملف الشخصي.',
  'If I made a mistake during entering data into my individual account, how can I fix this?': 'في حال ارتكبت خطأ أثناء إدخال البيانات في حسابي الشخصي، كيف يمكنني تصحيحه؟',
  "You have to contact the technical support service on the Company's website and edit the profile.": 'يتوجب عليك حينها التواصل مع خدمة الدعم الفني من على الموقع الإلكتروني للشركة وتعديل ملفك الشخصي.',
  'What data is required to register on the Company website?': 'ما هي البيانات المطلوبة للتسجيل على الموقع الإلكتروني للشركة؟',
  'To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.': 'لجني المال من الخيارات الرقمية، عليك أولاً فتح حساب يسمح لك بإجراء تداولات. وللقيام بذلك تحتاج إلى التسجيل على الموقع الإلكتروني للشركة.',
  'The registration process is simple and does not take much time.': 'عملية التسجيل سهلة ولا تستغرق الكثير من الوقت.',
  'It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:': 'من الضروري أن تقوم بتعبئة استبيان على النموذج المقترح. وسيُطلب منك إدخال المعلومات التالية:',
  'name (in English)': 'الاسم (باللغة الإنجليزية)',
  'email address (indicate the current, work, address)': 'البريد الإلكتروني (البريد الإلكتروني الحالي للعمل)',
  'telephone (with a code, for example, + 44123 ....)': 'رقم الهاتف (مع رمز، مثال 44123 + ...)',
  'a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)': 'كلمة مرور سوف تستخدمها مستقبلاً للدخول إلى النظام (لتقليل خطر الوصول غير المصرح به إلى حسابك الفردي. نقترح عليك إنشاء كملة مرور معقدة تتكون من أحرف صغيرة وكبيرة وأرقام. ولا تقم بالكشف عن كلمة مرورك لأطراف خارجية)',
  'After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.': 'بعد ملء الاستبيان، ستُعرض عليك طرق مختلفة للإيداع في حسابك التداولي.',
  "In what currency is the Client's account opened? Can I change the currency of the Client's account?": 'بأي عملة يتم فتح حساب العميل؟ هل يمكنني تغيير عملة حساب العميل؟',
  'By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.': 'بشكل افتراضي، يتم فتح الحساب التداولي بالدولار الأمريكي. لكن حرصاً على راحتك، نوفر إمكانية فتح عدة حسابات بعملات مختلفة.',
  "A list of available currencies can be found on your profile page in your Client's account.": 'يمكن العثور على قائمة بالعملات المتاحة في صفحة ملفك الشخصي في حساب العميل الخاص بك.',
  'What are digital options?': 'ما هي الخيارات الرقمية؟',
  'Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).': 'الخيار - أداة مالية مشتقة قائمة على أصل فعلي (يمكن أن يكون سهم أو زوج عملات أو نفط إلخ).',
  'DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.': 'الخيار الرقمي - خيار غير اعتيادي يُستخدم لتحقيق ربح من تحركات أسعار مثل هذه الأصول خلال فترة زمنية محددة.',
  'A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).': 'الخيار الرقمي، حسب الشروط المتفق عليها من قبل أطراف المداولة، وخلال فترة زمنية يحددها الأطراف، يحقق دخلاً ثابتاً (الفرق بين دخل المداولة وسعر الأصل) أو خسارة (بقيمة الأصل).',
  'Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.': 'وبما أن الخيار الرقمي يتم شراؤه مقدماً بسعر ثابت، فإن حجم الربح وكذلك حجم الخسارة المحتملة تكون محددة ومعروفة حتى قبل بدء التداول.',
  'Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).': 'من الميزات الأخرى لمثل هذه التداولات هي محدودية وقت التداول. حيث يكون لكل خيار المدة الزمنية الخاصة به (وقت انتهاء الصلاحية أو وقت الانتهاء).',
  'Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.': 'بغض النظر عن مستوى التغير في سعر الأصل الفعلي (مقدار ارتفاعه أو انخفاضه)، في حال ربح مداولة خيار ما، يتم دائماً سداد مبلغ ثابت. لذلك تكون مخاطرك مقيّدة بالمبلغ الذي تم شراء الخيار مقابله.',
  'What is the expiration period of a trade?': 'ما هي فترة انتهاء صلاحية المداولة؟',
  'The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.': 'فترة انتهاء الصلاحية هي الوقت الذي يتم بعده اعتبار المداولة مكتملة (مغلقة) ويتم تلخيص النتيجة تلقائياً.',
  'When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).': 'عند إجراء مداولة بالخيارات الرقمية، تقوم بتحديد وقت تنفيذ المداولة بشكلٍ مستقل (دقيقة واحدة، ساعتان، شهر، إلخ).',
  'What is the gist of digital options trading?': 'كيف يتم تداول الخيارات الرقمية؟',
  'The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.': 'الحقيقة هي أن الخيار الرقمي يعد أبسط أنواع الأدوات المالية المشتقة. ولجني المال في سوق الخيارات الرقمية، لا تحتاج إلى التكهن بقيمة سعر السوق الذي يمكن أن يصل إليه أصل ما.',
  'The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.': 'وينحصر مبدأ عملية التداول في نتيجة عملية واحدة فقط - هل سيرتفع أم ينخفض سعر أصل ما في وقت انتهاء صلاحية العقد.',
  'The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.': 'وتتمثل ميزة مثل هذه الخيارات في أنه لا يهم على الإطلاق ما إذا كان سعر الأصل الفعلي سيتحرك مائة نقطة أو نقطة واحدة فقط، من لحظة بدء المداولة إلى لحظة إغلاقها. بل ما يهمك هو فقط تحديد اتجاه حركة هذا السعر.',
  'If your prognosis is correct, in any case you get a fixed income.': 'وإن جاء تكهنك صحيحاً بغض النظر عن النتيجة، ستحصل على ربح ثابت.',
  'What are the possible results of the placed trades?': 'ما هي النتائج المحتملة للتداولات الموضوعة؟',
  'There are three possible outcomes in the digital options market:': 'هناك ثلاث نتائج محتملة في سوق الخيارات الرقمية:',
  'in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.': 'في حال جاء تكهنك صحيحاً في تحديد اتجاه حركة سعر الأصل الفعلي، تحقق دخل.',
  'if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).': 'في حال جاء تكهنك خاطئاً عند انتهاء وقت الخيار، سوف تتكبد خسارة تكون مقيّدة بحجم قيمة الأصل (لا يمكنك خسارة أكثر من قيمة استثمارك).',
  'if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.': 'في حال كانت نتيجة المداولة صفر (لم يتغير سعر الأصل الفعلي، أي ينتهي وقت الخيار بالسعر الذي تم شراؤه به)، تسترجع ما قمت باستثماره. لذلك فإن مستوى مخاطرتك يقتصر دائماً على حجم قيمة الأصل.',
  'Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?': 'هل توفر منصتكم للتداول حساب تجريبي لفهم عملية التداول بالخيارات الرقمية دون إنفاق أموالي الخاصة؟',
  "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.":
    'نعم. من أجل صقل مهاراتك التداولية واختبار مزايا وإمكانات منصة تداول الشركة، يمكنك استخدام حساب تجريبي (مجاني). وهو نوع من المحاكاة يسمح لك بتجربة التداول أولاً ، وحين تكون مستعداً يمكنك الانتقال إلى التداول الحقيقي. كما أن هذا الحساب التجريبي مناسب أيضاً للمتداولين ذوي الخبرة لتطوير مستواهم الاحترافي في التداول.',
  'The balance of such an account is 10,000 units.': 'رصيد مثل هذا الحساب يكون 10,000 وحدة.',
  'What determines profit size?': 'ما الذي يحدد حجم الربح؟',
  'There are several factors that affect the size of your profit:': 'هناك عدة عوامل تؤثر على حجم أرباحك:',
  'the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)': 'سيولة الأصل الذي اخترته في السوق (كلما زاد الطلب على الأصل في السوق، زاد الربح الذي ستحصل عليه)',
  'the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)': 'وقت التداول (يمكن أن تختلف سيولة الأصل في الصباح عن سيولة الأصل نفسه في فترة ما بعد الظهر بشكلٍ كبير)',
  'tariffs of a brokerage company': 'تعرفات شركة الوساطة',
  'changes in the market (economic events, changes in part of a financial asset, etc.)': 'التغيرات في السوق (الأحداث الاقتصادية، التغيرات في جزء من الأصل المالي، إلخ.)',
  'What are the varieties of digital options?': 'ما هي أنواع الخيارات الرقمية؟',
  'Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.': 'عند التداول بالخيارات، يجب عليك اختيار الأصل الفعلي الذي سيشكل أساس مثل هذا الخيار. ويتم تنفيذ تكهناتك على هذا الأصل.',
  'Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.': 'ببساطة حين تقوم بشراء عقد  رقمي، أنت تراهن على حركة سعر مثل هذا الأصل الفعلي.',
  'An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:': 'الأصل الفعلي هو "منتج" يؤخذ سعره في الاعتبار عند إبرام صفقة أو مداولة ما. وتشكل المنتجات الأكثر طلباً في السوق عادةً الأصول الفعلية للخيارات الرقمية، وهناك أربعة أنواع منها:',
  'securities (shares of world companies)': 'الأوراق المالية (أسهم الشركات العالمية)',
  'currency pairs (EUR / USD, GBP / USD, etc.)': 'أزواج العملات (EUR / USD ،GBP / USD، إلخ.)',
  'raw materials and precious metals (oil, gold, etc.)': 'المواد الخام والمعادن الثمينة (النفط والذهب وما إلى ذلك)',
  'indices (S&P 500, Dow, dollar index, etc.)': 'المؤشرات (S&P 500، داو جونز، مؤشر الدولار، إلخ.)',
  'A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.': 'الأصل الفعلي العالمي غير موجود في الواقع. لاختيار الأصل المناسب عليك الاستعانة بمعلوماتك وخبرتك وحدسك إلى جانب مراجعة أنواع مختلفة من المعلومات التحليلية، وتحليل السوق لأداة مالية معينة.',
  'What is a trading platform and why is it needed?': 'ما هي منصة التداول ولماذا هي ضرورية؟',
  'Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.': 'منصة التداول هي نظام برمجي يسمح للعميل بإجراء التداولات (العمليات) باستخدام أدوات مالية مختلفة. كما تتيح الوصول إلى معلومات متنوعة مثل قيمة عروض الأسعار، ومراكز السوق في الوقت الفعلي، والإجراءات التي تقوم بها الشركة، وما إلى ذلك.',
  'How to learn quickly how to make money in the digital options market?': 'كيف تتعلم بسرعة كيفية جني الأموال في سوق الخيارات الرقمية؟',
  'develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them': 'تطوير استراتيجيات التداول الخاصة بك بحيث تحقق الحد الأقصى من التداولات التي يتم التكهن بتحركاتها بالشكل الصحيح، ثم تطبيق هذه الاستراتيجيات.',
  'diversify your risks': 'تنويع مخاطرك',
  'In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.':
    'عند تطوير الاستراتيجيات، وكذلك في البحث عن خيارات التنويع، مراقبة السوق ودراسة المعلومات التحليلية والإحصائية التي يمكن الحصول عليها من مصادر مختلفة (موارد الإنترنت وآراء الخبراء والمحللين في هذا المجال، وما إلى ذلك)، والتي يعد الموقع الإلكتروني للشركة أحدها.',
  'Is the download of the program to a computer or smartphone required?': 'هل أحتاج إلى تحميل البرنامج على حاسوبي أو هاتفي الذكي؟',
  "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": 'لا، ذلك غير مطلوب. ما عليك سوى التسجيل على موقع الشركة باستخدام النموذج الموفر وفتح حساب فردي.',
  'At what expense does the Company pay profit to the Client in case of successful trade?': 'ما هو التعويض الذي تحصل عليه الشركة مقابل دفع أرباح للعميل من المداولات الناجحة؟',
  'Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.': 'تجني الشركة أرباحها من نجاح عملائها. لذلك تسعى إلى أن يكون حجم التداولات المربحة أعلى بكثير من حجم التداولات غير المربحة، نظراً لحقيقة حصول الشركة على نسبة ربح من كل استراتيجية تداول ناجحة يختارها العميل.',
  'In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.': 'بالإضافة إلى ذلك، يشكل الحجم الكلي للتداولات المنفذة من قبل العملاء حجم التداول للشركة، والذي يتم تحويله إلى وسيط أو بورصة، والتي بدورها تشكل جزءاً من مجموعة مزودي السيولة، مما يؤدي في النهاية إلى زيادة سيولة السوق ككل.',
  'What is account verification?': 'ماذا يعني التحقق من الحساب؟',
  'Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:':
    'تشير عملية التحقق في عالم الخيارات الرقمية إلى تأكيد من قبل العميل لبياناته الشخصية من خلال تزويد الشركة بوثائق إضافية. وتكون شروط التحقق الخاصة بالعميل بسيطة قدر الإمكان، وتتطلب تقديم الحد الأدنى من الوثائق. فعلى سبيل المثال، قد تطلب الشركة:',
  "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": 'تقديم نسخة ملونة من أول طية من جواز سفر العميل (صفحة جواز السفر مع صورة)',
  'identify with the help of a "selfie" (photograph of himself)': 'تأكيد الهوية باستخدام "صورة شخصية بالهاتف" (صورة لنفسه)',
  'confirm the address of registration (residence) of the Client, etc': 'تأكيد عنوان تسجيل (إقامة) العميل، إلخ',
  'The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.': 'يجوز للشركة طلب وثائق إثبات هوية في حال تعذر التعرّف بشكلٍ كلي على هوية العميل وصحة البيانات التي أدخلها.',
  'After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.': 'بعد تقديم النسخ الإلكترونية من الوثائق المطلوبة إلى الشركة، يتعين على العميل الانتظار لبعض الوقت حتى يتم التحقق من البيانات المقدمة.',
  'How to understand that I need to go through account verification?': 'كيف أعلم إن كنت بحاجة إلى إجراء عملية تحقق من الحساب؟',
  'If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.': 'في حال أصبح من الضروري اجتيازك لعملية تحقق، سوف تتلقى إشعاراً بذلك عبر البريد الإلكتروني أو عبر الرسائل النصية القصيرة.',
  'At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.': 'تستخدم الشركة معلومات الاتصال التي تدخلها في الاستبيان أثناء عملية التسجيل (خاصةً عنوان البريد الإلكتروني ورقم الهاتف) لذلك أدخلها بعناية وتأكد من أنها صحيحة.',
  'How do I know that I successfully passed verification?': 'كيف أعرف أن عملية التحقق تمت بنجاح؟',
  "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": 'ستتلقى إشعاراً عبر البريد الإلكتروني أو عبر الرسائل النصية القصيرة يعلمك باكتمال عملية التحقق من حسابك وقدرتك على متابعة الأنشطة على منصة تداول الشركة.',
  "Is it possible to indicate other people's (fake) data when registering on the website?": 'هل يُسمح بإدخال بيانات (وهمية) لأشخاص آخرين عند التسجيل في الموقع؟',
  "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": 'لا. يجب على العميل إجراء تسجيل ذاتي على الموقع الإلكتروني للشركة، وتقديم معلومات كاملة ودقيقة عن نفسه حول كل ما هو مطلوب في نموذج التسجيل، وأن يحافظ على تحديث هذه المعلومات.',
  'If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.': 'إذا كان من الضروري إجراء أنواع مختلفة من عمليات التحقق من هوية العميل، قد تقوم الشركة بطلب وثائق إثبات هوية أو دعوة العميل إلى مكتبها.',
  'If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.': 'في حال عدم تطابق البيانات التي يتم إدخالها في حقول التسجيل مع بيانات الوثائق المقدمة، قد يتم حظر ملفك الشخصي.',
  'How long does the verification process take?': 'كم من الوقت تستغرق عملية التحقق؟',
  'No more than 5 (five) business days from the date the Company receives the requested documents.': 'لا تستغرق أكثر من 5 (خمسة) أيام عمل من تاريخ استلام الشركة للوثائق المطلوبة.',
  'Is there a minimum amount that I can deposit to my account at registration?': 'هل هناك حد أدنى للإيداع علي إجراؤه عند التسجيل؟',
  'The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.': 'إن ما يميز منصة تداول الشركة هو عدم حاجتك إلى إيداع مبالغ كبيرة في حسابك. يمكنك بدء التداول باستثمار مبلغ صغير من المال. حيث أن الحد الأدنى للإيداع هو 10 دولار أمريكي.',
  'How can I deposit?': 'كيف يمكنني الإيداع؟',
  'It is very easy to do. The procedure will take a couple of minutes.': 'من السهل جداً القيام به ولن يستغرق أكثر من بضع دقائق.',
  'Open the trade execution window and click on the green "Deposit" button in the upper right corner of the tab.': 'افتح نافذة تنفيذ التداولات وانقر على زر "إيداع" في الزاوية العلوية اليمنى من التبويبة.',
  'You can also deposit the account through your Personal Account by clicking the "Deposit" button in the account profile.': 'كما يمكنك تمويل الحساب عبر حسابك الشخصي بالضغط على زر "إيداع" في الملف الشخصي للحساب.',
  'After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).': 'بعد ذلك تحتاج إلى اختيار طريقة للإيداع في الحساب (توفر الشركة الكثير من الطرق المريحة المتاحة للعميل ويتم عرضها في حسابه الفردي).',
  'Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.': 'بعد ذلك، عليك تحديد العملة التي سيتم الإيداع في الحساب بها، وعليه اختيار عملة الحساب نفسه.',
  'Enter the amount of the deposit.': 'أدخل مبلغ الإيداع.',
  'Fill out the form by entering the requested payment details.': 'املأ النموذج بإدخال معلومات الدفع المطلوبة.',
  'Make a payment.': 'إجراء عملية دفع',
  'Do I need to deposit the account of the trading platform and how often do I need to do this?': 'هل أحتاج إلى الإيداع في حساب منصة التداول وكم مرة أحتاج إلى القيام بذلك؟',
  'To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.': 'للتعامل بالخيارات الرقمية، تحتاج إلى فتح حساب فردي. ولتنفيذ تداولات حقيقية، ستحتاج بالتأكيد إلى إيداع قيمة الخيارات التي تقوم بشرائها.',
  "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.":
    'يمكنك بدء التداول دون نقود، والاكتفاء باستخدام حساب التدريب الذي توفره بالشركة (حساب تجريبي). يكون مثل هذا الحساب مجانياً ويتم إنشاؤه لعرض كيفية عمل منصة التداول. وبمساعدة مثل هذا الحساب، يمكنك التدرّب على امتلاك الخيارات الرقمية، وفهم المبادئ الأساسية للتداول، واختبار الأساليب والاستراتيجيات المختلفة، إلى جانب تقييم مستوى حدسك التداولي.',
  'What is the minimum deposit amount?': 'ما هو الحد الأدنى لمبلغ الإيداع؟',
  '{number} questions': '{number} أسئلة',
  Verification: 'التحقق',
  Payment: 'الدفع',
  Payouts: 'الأرباح',
  'Ask a specialist': 'اسأل متخصص',
  FAQ: 'الأسئلة المتكررة',
  "Didn't find an answer to your question?": 'لم تجد الإجابة على سؤالك؟',
  'Open knowledge base': 'افتح قاعدة المعرفة',
  'Contact support': 'اتصل بالدعم',
  'Open FAQ page': 'فتح صفحة الأسئلة المتكررة',
  'New message': 'رسالة جديدة',
  Tournaments: 'البطولات',
  'To end': 'إلى النهاية',
  'Until start': 'حتى البدء',
  'Front side': 'الجهة الأمامية',
  'Back side (if any)': 'الجهة الخلفية (إن وجد)',
  'Tourna-ments': 'البطولات',
  'Deletion of Account and Personal Data': 'حذف الحساب والبيانات الشخصية',
  'By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!': 'بحذف حسابك وبياناتك الشخصية، ستفقد إمكانية الوصول إلى حسابك على منصة Quotex بشكل دائم. تذكر أنه سيتم حذف بياناتك بشكل لا رجعة فيه ومن المستحيل استعادة حسابك لاحقًا!',
  'NOTE: Please complete all open trades and pending orders before you delete your account.': 'ملاحظة: يرجى إتمام كل التداولات المفتوحة والأوامر المعلقة قبل حذف حسابك.',
  'Request Deletion': 'طلب الحذف',
  'To delete your account, please contact support service': 'لحذف حسابك، يرجى التواصل مع خدمة العملاء',
  'Two-factor authorization via Email': 'المصادقة الثنائية عبر البريد الإلكتروني',
  'This feature is for experienced users who are familiar with Google Authenticator.': 'هذه الميزة للمستخدمين المتقدمين الذين هم على دراية باستخدام تطبيق Google Authenticator.',
  'Please note!': 'يرجى الملاحظة!',
  'If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.': 'في حال فقدت إمكانية الوصول لتطبيق Google Authenticator لأي سبب بعد ربطه بالحساب، سوف تفقد إمكانية الوصول للحساب.',
  'Proceed to setup': 'المتابعة للإعداد',
  'Account protection method': 'طريقة حماية الحساب',
  "Here's how to set it up:": 'إليك كيف تقوم بإعداده:',
  "Please enter the PIN-code we've just sent to your email:": 'يرجى إدخال الرمز السري الذي قمنا بإرساله إلى بريدك الإلكتروني:',
  "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": 'أحسنت. قمت بنجاح بتفعيل ميزة التحقق الثنائي عبر تطبيق Google Authenticator. في المرة المقبلة التي تسجل فيها الدخول، سيطلب منك إدخال رمز.',
  'If you ever lose access to your 2-step verification device, please': 'في حال فقدت إمكانية الوصول لجهازك الذي قمت بتفعيل التحقق الثنائي عليه، يرجى',
  'Confirm account deletion': 'تأكيد حذف الحساب',
  'To delete your account, follow the link in the email sent to': 'لحذف حسابك، اتبع الرابط في رسالة البريد الإلكتروني المرسلة إلى',
  'Image requirements': 'متطلبات الصورة',
  Email: 'البريد الإلكتروني',
  Сonfirmation: 'التأكيد',
  'Change protection method': 'تغيير طريقة الحماية',
  'First, enter the code from the Google Authenticator app:': 'أولاً، أدخل الرمز من تطبيق Google Authenticator:',
  "First, enter the PIN-code we've just sent to your email:": 'أولاً، أدخل الرمز السري الذي أرسلناه لك للتو على بريدك الإلكتروني:',
  "Nice work. You've successfully enabled 2-step verification via Email.": 'أحسنت. قمت بنجاح بتفعيل ميزة التحقق الثنائي عبر البريد الإلكتروني.',
  'Receiving codes via Email': 'تلقي الرموز عبر البريد الإلكتروني',
  'Receiving codes via Google Authenticator': 'تلقي الرموز عبر تطبيق Google Authenticator',
  'Choose a method:': 'اختر طريقة:',
  'To enter the platform': 'للدخول إلى المنصة',
  'To withdraw funds': 'لسحب الأموال',
  day: 'يوم',
  days2: 'يومين',
  days5: 'أيام',
  
text:'اختيار العملة',
text1:'البريد الإلكتروني',
text2:'إلغاء الصفقة',
text3:'رمز العرض المجاني',
text4:'نحن نعمل على تحسين المنصة يوميًا لتجعلها أكثر ملاءمة.',
text5:'هل تحب هذه المنصة؟ يرجى توصيتها للآخرين!',
text6:'يرجى ترك تعليقاتك حول QUOTEX ، وستحصل على مكافأة لإلغاء الصفقات مجانًا.',
text7:'أرسل تعليقاتك لفريق خدمة العملاء الخاص بنا، وبعد مراجعتها، سنرسل لك هذه المكافأة.',
text8:'الحد الأدنى للإيداع',
text9:'الحد الأدنى للسحب',
text10:'سحب سريع',
text11:'مجاني',
text12:'غير متحقق',
text13:'إعادة الإرسال',
text14:'تأكيد مشاركتك',
text15:'إلغاء الصفقة',
text16:'يرجى إدخال رمز العرض الخاص بك وتفعيله على الفور. يمكنك استخدام رمز العرض في أي وقت.',
text17:'رمز العرض:',
text18:'احصل على مكافأة تبلغ 30% بناءً على رصيد الإيداع الخاص بك',
text19:'العملة الحالية',
text20:'الرصيد المتاح للسحب',
text21:'في الحساب',
text22:'اليوم',
text23:'أمس',
text24:'الأسبوع',
text25:'الشهر',
text26:'صافي قيمة التداول',
text27:'WELCOME هو مكافأة 30% للإيداع الأول.',
text28:'مبلغ الإيداع الأولي هو 100 دولار. اختر طريقة الإيداع للحصول على مكافأة 30%',
text29:'بطاقة بنكية',
text30:'البنك',
text31:'الطريقة الدفع المحددة:',
text32:'الحد الأدنى:',
text33:'الحد الأقصى:',
text34:'اختر طريقة دفع أخرى',
text35:'معلومات الدفع',
text36:'المبلغ',
text37:'استخدام المكافأة',
text38:'البنود والشروط',
text39:'لدي كود ترويجي',
text40:'رمز الترويج',
text41:'تطبيق رمز العرض',
text42:'الرمز الترويجي الذي أدخلته صحيح. ستحصل على مكافأة بنسبة 30%',
text43:'ستحصل على مكافأة بنسبة',
text44:'',
text45:'يرجى الاطلاع على جميع الأسئلة الشائعة',
text46:'للتداول بالعملات الرقمية، ستحتاج إلى فتح حساب شخصي. وللتداول الحقيقي، فمن المؤكد أنه سيكون هناك حاجة إلى الوديعة وفقا لسعر الخيار.',
text48:'لديك {0} رمز ترويجي',
text49:'استخدم هذا الرمز الترويجي لإلغاء الصفقات الخاسرة',
text50:'يمكنك إلغاء الصفقات غير المحايدة فقط، وقيمتها لا تتجاوز الحد الأقصى للرمز الترويجي.',
text51:'الحالة',
text52:'استخدام',
text53:'ليس لديك سجل لرموز الترويج الخاصة بك بعد. يمكنك استخدام الزر أدناه لإضافة رموز ترويج.',
text54:'عرض السجل',
text55:'إدخال رمز الترويج',
text56:'معلومات الخصوصية',
text57:'فارغ',
text58:'الاسم الأول',
text59:'الكنية',
text60:'تاريخ الميلاد',
text61:'البلد',
text62:'حفظ',
text63:'العنوان',
text64:'التحقق من الملفات',
text65:'يجب إدخال المعلومات الشخصية قبل التحقق من الحساب.',
text66:'الأمان',
text67:'التحقق الثنائي',
text68:'تغيير كلمة المرور',
text69:'كلمة المرور القديمة',
text70:'كلمة المرور الجديدة',
text71:'إعادة إدخال كلمة المرور الجديدة',
text72:'إلغاء تسجيل الدخول',
text73:'التحقق من بطاقة الائتمان أو الخصم',
text74:'إضافة بطاقة بنكية جديدة',
text75:'ليس لديك أي بطاقات ائتمان أو خصم تحتاج إلى التحقق منها',
text76:'ليس لديك أي سجل تداول.',
text77:'البداية',
text78:'الانتهاء',
text79:'تطبيق التصفية',
text80:'السابق',
text81:'التالي',
text82:'الربح',
text83:'تم الإلغاء',
text84:'الحساب',
text85:'الرسوم',
text86:'يمكنك سحب الأموال من حسابك على المنصة إلى بطاقة الائتمان أو المحفظة الإلكترونية التي استخدمتها لإيداع الأموال. يمكنك طلب السحب في أي وقت. ستتم معالجة طلبات السحب الخاصة بك خلال 3 أيام عمل.',
text87:'إيداع الأموال',
text88:'بعض الطلبات السابقة',
text89:'سجل السحب',
text90:'طلباتي',
text91:'تقديم طلب',
text92:'الأسئلة الشائعة',
text93:'ليس لديك أي طلبات تم تقديمها',
text94:'إذا كانت لديك أية أسئلة، يمكنك العثور على الإجابات في الأسئلة الشائعة.',
text95:'حقوق الطبع والنشر © 2024 Quotex. جميع الحقوق محفوظة',
text96:'تسجيل الدخول',
text97:'كلمة المرور',
text98:'تذكرني',
text99:'العودة إلى المعلومات',
text100:'تم الانتهاء',

text104:"منصة الابتكار للاستثمار الذكي",
   text105: "قم بالتسجيل واحصل على 10,000 دولار في حساب تجريبي حيث تتعلم التداول",
   text106:"تسجيل",
   text107:"افتح حساب تجريبي",
   text108:"واجهة تداول مريحة",
   text109:'لقد أنشأنا الواجهة الأبسط والأكثر ملاءمة حتى لا تبتعد عن الأمر الأكثر أهمية وهو التداول. ',
   text110:"إشارة مدمجة",
   text111:'فكر في استراتيجية التداول، فنحن نقدم الإشارات الأكثر دقة وابتكارًا بنسبة تصل إلى 87% لمساعدتك في تطوير إستراتيجيتك الفعالة. ',
   text112:"مؤشر التداول",
   text113:'لقد جمعنا مؤشرات التداول الأكثر فائدة. استخدم هذا المؤشر لزيادة رصيد حسابك.',
   text114:"السرعة المثالية",
   text115: 'تستخدم منصتنا التكنولوجيا الأكثر ابتكارًا وتوفر سرعة هائلة. ',
   text116:"جربه باستخدام حساب تجريبي",
   text117:'من أجل كسب المزيد من المال',
   text118:"قم بالتشخيص الصحيح",
   text119:"هل السعر صاعد أم هابط؟",
   text120:"افتح حساب تجريبي",
   text121:'انقر',
   text122:'على أحد الأزرار وشاهد النتائج',
   text123:"السعر سوف يرتفع",
   text124:"سينخفض ​​السعر",
   text125:'في انتظار النتائج...',
   text126:"أرباحك",
   text127:"عذرًا, توقعك غير صحيح",
   text128:"حاول مرة أخرى",
   text129:"أرباحك",
    text130: "تهانينا! توقعاتك صحيحة",
   text131:"ابدأ في جني المال",
   text132:"تطبيق الهاتف المحمول متاح دائمًا",
   text133: 'قم بتنزيل تطبيقنا سهل الاستخدام لأجهزة iPhone أو Android وابدأ التداول! ',
   text134:"الوقت المتاح",
   text135:"ابدأ التداول",
   text136:'3 خطوات',
   text137:'افتح حساب حقيقي مجاناً خلال دقائق معدودة',
   text138:'تداول على الحساب التجريبي بنقرة واحدة',
   text139:"التدريب",
   text140:'حسّن مهاراتك باستخدام الحسابات التجريبية والمواد التدريبية',
   text141:'ابدأ التدريس باستخدام حساب تجريبي',
   text142:"قم بالإيداع في حسابك الحقيقي وابدأ التداول",
   text143:'أكثر من 410 أداة, الحد الأدنى لمبلغ المعاملة هو 10 دولارات',
   text144:"طريقة الإيداع السريع",
   text145:"الأسئلة الشائعة",
   text146:"كيف تتقن ذلك؟",
   text147:'سجل حسابًا تجريبيًا وتدرب على استخدام هذا الحساب. الحساب التجريبي هو نفس الحساب الحقيقي, ولكن لا يتطلب أي إيداع. ',
   text148:"ما المدة التي تستغرقها عملية السحب؟",
   text149: 'في المتوسط, تستغرق عملية السحب ما بين 1 و5 أيام للمعالجة من تاريخ استلام طلب المستخدم المقابل, وتعتمد فقط على عدد الطلبات التي تتم معالجتها في وقت واحد. تسعى الشركة دائمًا إلى سداد طلب المستخدم مباشرةً في تاريخ استلامه. ',
   text150:"ما هي منصة التداول؟ ما هو التأثير؟ ",
   text151: 'منصة التداول عبارة عن نظام برمجي يستخدم المستخدمون الأدوات المالية التي يوفرها هذا النظام لإجراء المعاملات (العمليات), ويستخدمون أيضًا قيمة عرض الأسعار وحالة السوق في الوقت الفعلي وإجراءات الشركة والمعلومات الأخرى التي تقدمها الشركة. ',
   text152:"هل يمكنني استخدام هاتفي المحمول لإجراء المعاملات؟",
   text153:'منصتنا تستخدم التكنولوجيا الأكثر ابتكارا ويمكن فتحها في متصفح أي جهاز كمبيوتر أو هاتف ذكي',
   text154:"ما هو الحد الأدنى لمبلغ الإيداع؟",
   text155: 'ميزة منصة التداول الخاصة بهذه الشركة هي أنك لا تحتاج إلى إيداع مبالغ كبيرة في حسابك. يمكنك البدء بالتداول عن طريق استثمار مبلغ صغير من المال. الحد الأدنى للإيداع هو 10 دولارات. ',
   text156:"هل توجد عمولة عند إيداع أو سحب الأموال من الحساب؟",
   text157: `لا." لا تتقاضى الشركة أي عمولة على العمليات مثل إيداع الحسابات أو سحب الأموال. `,
   text158: 'ومع ذلك, تجدر الإشارة إلى أن أنظمة الدفع قد تفرض عمولات وتستخدم أسعار صرف العملات التفاضلية. ',
   text159:"الانتقال إلى جميع الأسئلة",
   text160:'Quotex: منصة الابتكار',
   text161:"تداول الأصول الرقمية",
   text162:"افتح حساب حقيقي",
   text163:"شريك",
   text164:"حساب تجريبي",
   text165:"معلومات الاتصال",
   text166:"سؤال مهم",
   text167:"القضايا المالية",
   text168:"التحقق",
   text169:"اللوائح",
   text170:"سياسة الخصوصية",
   text171:"شروط الدفع",
   text172:"اتفاقية الخدمة",
   text173:"تحذير من المخاطر",
   text174:'قواعد عملية التداول',
   text175:"قواعد التشغيل غير التجارية",
   text176:`تحذير من المخاطر: يمكن أن ينطوي التداول على منصات تداول الأصول الرقمية واستخدام أدوات تمويل الإقراض على مخاطر كبيرة وقد يؤدي إلى خسارة كاملة لرأس المال الاستثماري الخاص بك. لا تستثمر أكثر مما تستطيع تحمله, ويجب أن تفهم المخاطر التي تنطوي عليها. التداول باستخدام أدوات التمويل المقترضة ليس مناسبًا لمعظم المستثمرين. يعد التداول دون استخدام الأموال المقترضة مثل الأسهم أمرًا محفوفًا بالمخاطر أيضًا, حيث يمكن أن تنخفض أسعار الأسهم وترتفع, مما قد يعني أنه حتى لو فزت, فسوف تسترد أقل بكثير مما استثمرته.
   النجاح الماضي لا يضمن النجاح في المستقبل. قبل التداول, يرجى الانتباه إلى خبرتك في التداول ومستواك وأهدافك الاستثمارية والاتصال بمستشار مالي مستقل إذا لزم الأمر. يجب على المستخدمين تحديد ما إذا كان استخدام الخدمات التي تحمل العلامة التجارية Quotex مسموحًا به بناءً على القوانين واللوائح المعمول بها في بلد إقامتهم. `,
   "tips": "الرجاء إدخال رقم الحساب وكلمة المرور",
};
