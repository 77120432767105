export default {
    "+{value}% profit": "+{value}% прибуток",
    "Selfie verification required": "Необхідно пройти верифікацію за допомогою Selfie",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "Для того, щоб виконати необхідні кроки з верифікації рахунку, зробіть фотознімок з високою роздільною здатністю (selfie) з документом, що посвідчує особу, а також аркушем паперу, що містить поточну дату і ваш підпис. На фотознімку повинно бути видно ваше обличчя, тіло і обидві руки.<br><br> Інформація в документі повинна бути чіткою і читабельною.",
    "Your documents have been checked, now your identity is verified.": "Ваші документи перевірені, тепер ваша особистість підтверджена.",
    "You need fill identity information before verification your profile.": "Потрібно заповнити особисту інформацію перед верифікацією аккаунта.",
    "Documents verification": "Верифікація документів",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "Верифікація профілю означає надання офіційного документа, що посвідчує особу клієнта. Ця процедура може бути ініційована відділом безпеки Компанії у будь-який час.",
    "Upload Photo": "Вивантажити фотографію",
    "Nickname": "Нікнейм",
    "First Name": "Ім'я",
    "Last Name": "Прізвище",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Ім'я необхідно вказувати точно так, як воно вказано в документі. За надання неправдивої інформації обліковий запис може бути видалено без попередження.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Прізвище необхідно вказувати точно так, як воно вказано в документі. За надання неправдивої інформації обліковий запис може бути видалено без попередження.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "В майбутньому змінити дату народження буде неможливо. Якщо ви ввели неправильні дані, ви не зможете виводити кошти з вашого рахунку.",
    "Empty": "Пусто",
    "Date of birth": "Дата народження",
    "Unverified": "Не перевірено",
    "Verified": "Підтверджено",
    "Resend": "Надіслати повторно",
    "Country": "Країна",
    "Address": "Адреса",
    "Change Identity Info": "Змінити особисту інформацію",
    "Identity Info": "Особиста інформація",
    "Two-factor authentication on login": "Двофакторна аутентифікація при вході в систему",
    "Two-factor authentication on withdrawal": "Двофакторна аутентифікація при виведенні",
    "Active": "Активний",
    "Inactive": "Неактивний",
    "Setup": "Налаштування",
    "Two-step verification": "Двоетапна верифікація",
    "Security": "Безпека",
    "Google Authenticator": "Google Authenticator",
    "Scan the QR code": "Відскануйте QR-код",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Ваша безпека є нашим основним пріоритетом, тому ми вимагаємо двоетапну аутентифікацію. Ви можете активувати її наступним чином:",
    "Install ": "Встановити ",
    "Can't scan?": "Не можете відсканувати?",
    "Click here.": "Натисніть тут.",
    "Enter this code into Google Authenticator.": "Введіть цей код в Google Authenticator.",
    "Back to QR code.": "Повернутися до QR-коду.",
    "Enter the 6-digit code from Authenticator below": "Введіть нижче 6-значний код з аутентифікатора.",
    "Enter 6-digit code...": "Введіть 6-значний код...",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "Чудово! Ви успішно активували двоетапну аутентифікацію. При наступному вході в систему вам буде запропоновано ввести код.",
    "Code": "Код",
    "contact support": "зв'язатися з підтримкою",
    "If you ever lose access to your 2-step verification device, please ": "Якщо ви коли-небудь втратите доступ до свого 2FA-пристрою, будь ласка ",
    "Yes, activate": "Так, активувати",
    "Cancel": "Скасувати",
    "Close": "Закрити",
    "Language": "Мова",
    "Timezone": "Часовий пояс",
    "Delete Account": "Видалити аккаунт",
    "Verification Credit / Debit Cards": "Верифікація кредитних або дебетових карт",
    "Add New Card": "Додати нову карту",
    "Wait for loading...": "Очікується завантаження…",
    "You don’t have any credit / debit cards for verification": "У вас немає кредитних і дебетових карт, які підлягають верифікації",
    "Verificate": "Верифікація",
    "Verification Card": "Верифікація карти",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "Будь ласка, помістіть своє посвідчення особи (з фотографією, іменем та датою народження) разом з лицьовою стороною вашої банківської карти (на якій зазначені номер карти, термін дії та ваше ім'я) на чистий аркуш паперу формату A4.<br> Будь ласка, напишіть «Quotex.io» і поставте свій підпис.<br> Зробіть фото і завантажте його.",
    "Card:": "Карта:",
    "Type:": "Тип:",
    "Your ID and bank card on an A4 sheet": "Ваше посвідчення особи і банківська карта на аркуші А4",
    "Yes, confirm": "Так, підтверджую",
    "Click or drag the image": "Натисніть на зображення або перетягніть його",
    "Card": "Карта",
    "Documents": "Документи",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "Спочатку заповніть розділ «Особиста інформація» у своєму профілі та натисніть кнопку «Змінити персональні дані», щоб зберегти зміни. Після цього ви можете додати нову банківську карту.",
    "Specify the card details for further operations for depositing and debiting funds.": "Вкажіть реквізити банківської карти для подальших операцій поповнення та виведення коштів.",
    "Card number:": "Номер карти:",
    "Expiry month:": "Місяць закінчення терміну:",
    "Expiry year:": "Рік закінчення терміну:",
    "Cardholder name:": "Ім'я власника карти:",
    "Next": "Наступна",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "Покладіть ваш документ, що посвідчує особу, поруч із вашою банківською картою на чистий білий аркуш паперу з вашим підписом і поточною датою, зробіть фотографію та завантажте у вказаній нижче формі.",
    "Do you have a two-sided document?": "Ваш документ містить дані з обох сторін?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "Завантажте оборотну сторону банківської карти окремим файлом, також на фоні чистого аркуша паперу з вашим підписом і поточною датою.",
    "Submit only valid documents": "Необхідно надати лише дійсні документи",
    "Do not edit or crop photo": "Не надсилайте відредаговану або обрізану фотографію",
    "Do not upload other people's documents": "Не надсилайте фотографії третіх осіб",
    "Read all": "Прочитати все",
    "Uploading files": "Файли надсилаються",
    "For single-sided documents, upload only the front side.": "Для документів з однією стороною завантажуйте лише лицьову сторону.",
    "Back": "Назад",
    "Add a card": "Додати банківську карту",
    "File Requirements": "Вимоги до файлів",
    "Scanned images are not accepted for card verification": "Скан-копії не приймаються для верифікації банківської карти",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "Приймаються лише файли з вашим дійсним внутрішнім паспортом або документом, що посвідчує особу (кожна зі сторін повинна бути завантажена окремим файлом)",
    "Driving license CANNOT be accepted": "Посвідчення водія НЕ приймається",
    "External (foreign/international/travel) passport will not be accepted as well": "Зовнішній (закордонний/міжнародний/туристичний) паспорт також не приймається",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "Якщо документ має дані з двох сторін, оборотну сторону потрібно завантажити окремим файлом на фоні чистого аркуша паперу з поточною датою і вашим підписом.",
    "All information in the document and on the card should be clear and readable on the photo": "Всі написи в документі та на банківській карті повинні бути чіткими та розбірливими",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "Ваш документ і ваша банківська карта повинні бути повністю зображені на фотографії, краї не повинні бути обрізані",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "На фотографії не повинно бути відблисків або інших дефектів, які не дозволяють розібрати написи у документі або на банківській карті",
    "Expired documents and cards will be rejected automatically": "Недійсні документи та банківські карти будуть автоматично відхилені",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "Якщо на вашій банківській карті не вказане ваше ім'я, вам потрібно завантажити скріншот банківської виписки з вашим іменем та номером карти. У деяких випадках дозволяється написати своє ім’я від руки на аркуші паперу",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "Не дозволяється приховувати написи, що містять необхідні для верифікації дані (П.І.Б., дата народження, фотографія)",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "Дозволяється приховати 8 цифр номеру банківської карти. Перші та останні 4 цифри карти повинні бути чітко видні",
    "CVV code should be hidden at all times, in every case": "Ніколи не показуйте код CVV, зазначений на обороті карти",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "Не дозволяється надсилати відредаговані файли. Приймаються лише оригінальні фотографії та скан-копії. Не приймаються документи зі зміненими персональними даними (П.І.Б., дата народження, тощо)",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "Фотографія повинна бути прикріплена до документа (вклеєна або роздрукована). Надіслані окремо фотографії не приймаються",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "Приймаються лише документи і банківські карти на ім’я, вказане у профілі (на яке зареєстрований ваш обліковий запис)",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "Зверніть увагу, що у випадку надання несправжніх (недостовірних) даних ваш обліковий запис може бути заблоковано до з’ясування обставин",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "Компанія залишає за собою право у будь-який час попросити вас надати додаткові документи для верифікації облікового запису (у тому числі ваше «селфі» з документом)",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "Для уточнення будь-яких моментів стосовно верифікації документів ви можете створити заявку в службу підтримки на платформі (розділ «Довідка» – перша кнопка з блимаючим знаком питання на крайній лівій панелі)",
    "Profile": "Профіль",
    "Deposit": "Поповнення",
    "Withdrawal": "Виведення",
    "Transactions": "Транзакції",
    "Trades": "Угоди",
    "Market": "Маркет",
    "Analytics": "Аналітика",
    "My current currency": "Моя поточна валюта",
    "Available for withdrawal": "Доступно для виведення",
    "In the account": "На рахунку",
    "Percentage of turnover {percent}%": "Відсоток від обороту {percent}%",
    "Cashback {percent}%": "Кешбек {percent}%",
    "Currency:": "Валюта:",
    "Change": "Обміняти",
    "Live Account": "Реальний рахунок",
    "Daily budget limit": "Денний ліміт бюджету",
    "The value cannot exceed your total budget": "Значення не може перевищувати ваш загальний бюджет",
    "Demo Account": "Демо-рахунок",
    "Apply": "Застосувати",
    "Edit": "Редагувати",
    "New value": "Нове значення",
    "Confirm": "Підтвердити",
    "Logout": "Вийти",
    "No, cancel": "Ні, скасувати",
    "Exchange Form": "Форма обміну",
    "My Currency:": "Поточна валюта:",
    "New Currency:": "Нова валюта:",
    "You are exchanging": "Ви обмінюєте",
    "You will receive": "Ви отримаєте",
    "Exchange Fee": "Комісія за обмін",
    "Password changed": "Пароль змінений",
    "Old password": "Старий пароль",
    "New password": "Новий пароль",
    "Confirm new password": "Підтвердіть новий пароль",
    "Change Password": "Змінити пароль",
    "Confirm password must be equal new password value": "Пароль підтвердження має співпадати з новим значенням пароля",
    "About us": "Про нас",
    "Support": "Підтримка",
    "Help": "Довідка",
    "2-step verification": "Двоетапна верифікація",
    "Please enter the PIN-code we've just sent to your email": "Будь ласка, введіть PIN-код, який ми щойно надіслали на вашу електронну пошту.",
    "Please enter the 6-digit code from your authentication app": "Будь ласка, введіть 6-значний код з вашої програми для аутентифікації.",
    "The First Name field is required": "Поле «Ім'я» є обов'язковим для заповнення",
    "The Last Name field is required": "Поле «Прізвище» є обов'язковим для заповнення",
    "Verification of documents": "Верифікація документів",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "Будь ласка, завантажте кольорову фотографію або скан-копію вашого дійсного внутрішнього або закордонного паспорта, водійського посвідчення або іншого документа, що посвідчує особу.",
    "Upload Documents": "Завантажити документи",
    "Why?": "Чому?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "Ви підтверджуєте видалення свого облікового запису? Ця дія не може бути скасована.",
    "Set up 2-step verification": "Активувати двоетапну верифікацію",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "На жаль, ви не можете завантажувати файли до цього розділу. <br> Будь ласка, ознайомтеся з повідомленнями від служби підтримки та додайте необхідні файли до тікета.",
    "The uploaded document does not match the requirements": "Завантажений документ не відповідає вимогам",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "Для ідентифікації підходять наступні документи: посвідчення особи, внутрішній паспорт, закордонний паспорт або посвідчення водія.",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "Зображення повинні бути високої якості, дані – читабельними, а краї документа – не обрізаними.",
    "Balance from {amount}": "Залишок від {amount}",
    "Not verified": "Не підтверджено",
    "Clear": "Видалити",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "Пройдіть процедуру верифікації, щоб уникнути обмежень на отримання послуг. Для цього вкажіть особисті дані та завантажте необхідні документи.",
    "Get verified": "Пройти верифікацію",
    "I got it!": "Зрозуміло!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "Верифікація AADHAAR",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "Будь ласка, завантажте кольорову фотографію вашого Aadhaar (тільки лицьова сторона з фотографією та номером документа).",
    "Send AADHAAR": "Надіслати AADHAAR",
    "AADHAAR number:": "Номер AADHAAR:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "Будь ласка, завантажте кольорову фотографію вашого Aadhaar (тільки лицьова сторона з фотографією та номером документа). ☝ Електронні версії не приймаються.",
    "You have already uploaded {number} files. Please wait until they are checked.": "Ви вже завантажили {number} файлів. Будь ласка, дочекайтеся завершення перевірки.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "Будь ласка, завантажте кольорову фотографію вашого документа: Для RG – обидві сторони потрібно завантажити окремими файлами. Для CNH – тільки лицьову сторону з фотографією. Документ повинен бути дійсним (менше 10 років з дати видачі), і на ньому повинен бути вказаний ваш номер CPF. * Якщо ваш документ не містить номера CPF, будь ласка, завантажте окрему фотографію Comprovante de Inscrição no CPF, або скріншот банківської виписки з вашим ім'ям та номером CPF. ** Будь ласка, не завантажуйте жодних електронних версій. Вони не будуть прийняті.",
    "Submit for verification": "Відправити файли для верифікації",
    "Settings": "Налаштування",
    "Please upload a color photograph of the front of your national identity card with your photo.": "Будь ласка, завантажте кольорову фотографію лицьової сторони вашого національного посвідчення особи з вашою фотографією.",
    "Template": "Тема",
    "Light Mode": "Світла тема",
    "Twilight": "Сутінки",
    "Full Night": "Темна ніч",
    "Grid's opacity": "Прозорість сітки",
    "Auto-scrolling": "Автопрокрутка",
    "Automatic graphic scrolling": "Автопрокрутка графіка",
    "1-click trade": "Угода в 1 клік",
    "Open trades without confirmation": "Відкривати угоди без підтвердження",
    "Use timezone": "Застосувати часовий пояс",
    "Use your timezone data": "Застосувати дані свого часового поясу",
    "Graph colors": "Кольори графіка",
    "Up Trend": "Висхідний тренд",
    "Down Trend": "Низхідний тренд",
    "Platform": "Платформа",
    "Short order label": "Етикетка для короткого замовлення",
    "Use short order element mode": "Використовувати режим елемента короткого замовлення",
    "Perfomance Mode": "Perfomance-режим",
    "Use optimized rendering for chart and candles": "Застосувати оптимізований рендеринг для графіків і свічок",
    "Background": "Фон",
    "Choose file": "Вибрати файл",
    "Max size — 2 Mb": "Максимальний розмір – 2 Мб",
    "Sign up": "Реєстрація",
    "Sign in": "Увійти",
    "Budget and limits": "Бюджет і ліміти",
    "Daily limit": "Добовий ліміт",
    "Not set": "Не задано",
    "Total funds": "Всього коштів",
    "Top up": "Поповнити",
    "Set up": "Встановити",
    "Set a daily limit on trading to reduce financial risks.": "Встановіть добовий ліміт на торгівлю, щоб знизити фінансові ризики.",
    "Limit amount": "Сума ліміту",
    "More": "Детальніше",
    "Leader Board": "Рейтинг",
    "Signals": "Сигнали",
    "Notifications": "Сповіщення",
    "mark all as read": "помітити всі прочитаними",
    "No notifications": "Немає сповіщень",
    "No more notifications": "Сповіщень більше немає",
    "Your position:": "Ваша позиція:",
    "How does this rating work?": "Як працює цей рейтинг?",
    "of the Day": "поточного дня",
    "Nothing has been found for current query, try another asset name.": "За запитом нічого не знайдено. Спробуйте ввести іншу назву активу.",
    "My rating in the Leader Board": "Мій рейтинг в ТОПі",
    "Participants can only be those who trade in live mode": "Беруть участь трейдери, які торгують на реальному рахунку",
    "Your position depends on the amount of earned money": "Ваша позиція залежить від обсягу отриманого прибутку",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "Розраховується щодня з 00:00 UTC до 23:59 UTC",
    "All traders on our platform participate in this rating. Main features of the rating:": "Всі трейдери на нашій платформі беруть участь в цьому рейтингу. Основні особливості рейтингу:",
    "How does it work?": "Як це працює?",
    "Trading signals": "Торгові сигнали",
    "Past signals": "Минулі сигнали",
    "What’s it?": "Що це таке?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "Торговий сигнал – це не пряма інструкція до торгівлі, а рекомендація аналітика. Сигнал не замінює незалежний аналіз ринку.",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "Кожен сигнал має свій час, який він актуальний (в середньому 10 хвилин). Після закінчення цього часу котирування можуть змінитися і несподівано вплинути на результат угоди.",
    "Continue": "Продовжити",
    "Duration": "Тривалість",
    "Return back to FAQ": "Повернутися до FAQ",
    "Return back to Help": "Повернутися до довідки",
    "How to withdraw money from the account?": "Як вивести гроші з рахунку?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "Процедура виведення капіталу максимально проста і відбувається через ваш особистий кабінет.",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "Спосіб, який ви вибрали для поповнення рахунку, є і способом виведення коштів (див. питання «Як можна поповнити рахунок?»).",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "Наприклад, якщо для укладення угоди ви поповнили свій рахунок через платіжну систему Visa, то і виводити кошти ви будете через платіжну систему Visa.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "Коли мова йде про виведення досить великої суми, Компанія може запросити верифікацію (верифікація запитується на особистий розсуд Компанії), ось чому дуже важливо реєструвати рахунок особисто на себе, щоб в будь-який момент часу підтвердити свої права на нього.",
    "What is the minimum withdrawal amount?": "Яка мінімальна сума виведення?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "Для більшості платіжних систем мінімальна сума виведення становить 10 доларів США.<br>Для криптовалют ця сума становить від 50 доларів США (і може бути вищою для певних валют, таких як Bitcoin).",
    "Do I need to provide any documents to make a withdrawal?": "Чи потрібні якісь документи, щоб здійснити виведення коштів?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "Зазвичай для виведення коштів додаткові документи не потрібні. Однак Компанія на свій розсуд може попросити вас підтвердити свої особисті дані шляхом запиту певних документів. Зазвичай це робиться з метою запобігання дій, пов'язаних з незаконною торгівлею, фінансовими махінаціями, а також використанням коштів, отриманих незаконним шляхом.",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "Список таких документів мінімальний, а операція з їх надання не відніме у вас багато часу і сил.",
    "How long does it take to withdraw funds?": "Скільки часу займає виведення коштів?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "В середньому процедура виведення коштів займає від одного до п'яти днів з дати отримання відповідної заявки Клієнта і залежить тільки від обсягу одночасно оброблюваних заявок. Компанія завжди намагається проводити виплати безпосередньо в день надходження запиту від Клієнта.",
    "Is there any fee for depositing or withdrawing funds from the account?": "Чи існує комісія за поповнення або виведення коштів з рахунку?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "Ні. Компанія не стягує комісію ні за операцію поповнення рахунку, ні за виведення коштів.",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "Однак, варто враховувати, що платіжні системи можуть стягувати свої комісії і використовувати внутрішній курс конвертації валют.",
    "Can I close my account? How to do it?": "Чи можу я закрити свій рахунок? Як це зробити?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "Видалити рахунок можна у вашому особистому кабінеті, натиснувши на кнопку «Видалити рахунок» у нижній частині сторінки профілю.",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "Я ввів(ввела) неправильні дані в своєму особистому кабінеті, як це виправити?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "Необхідно звернутися в службу підтримки через сайт Компанії, а потім відредагувати дані профілю.",
    "What data is required to register on the Company website?": "Які дані потрібні для реєстрації на сайті Компанії?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "Для заробітку на цифрових опціонах спочатку потрібно відкрити рахунок, що дозволяє здійснювати операції. Для цього необхідно пройти реєстрацію на сайті Компанії.",
    "The registration process is simple and does not take much time.": "Процес реєстрації простий і не займе у вас багато часу.",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "Необхідно заповнити анкету за запропонованою формою. Від вас буде потрібно ввести наступну інформацію:",
    "name (in English)": "ім'я (українською мовою)",
    "email address (indicate the current, work, address)": "адреса електронної пошти (вказуйте актуальну, робочу адресу)",
    "telephone (with a code, for example, + 44123 ....)": "телефон (з кодом, наприклад, +380....)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "пароль, який ви надалі використовуватимете для входу в систему (з метою мінімізації ризику несанкціонованого входу в ваш особистий кабінет, рекомендуємо придумати складний пароль з використанням малих, великих літер і цифр. Не повідомляйте пароль третім особам)",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "Після заповнення форми вам будуть запропоновані різні способи поповнення торгового рахунку.",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "В якій валюті відкривається рахунок Клієнта? Чи можна змінювати валюту рахунку Клієнта?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "За замовчуванням торговий рахунок відкривається у доларах США. Але для вашої зручності ви можете відкрити кілька рахунків у різних валютах.",
    "A list of available currencies can be found on your profile page in your Client's account.": "Список доступних валют можна подивитися на сторінці вашого профілю в особистому кабінеті.",
    "What are digital options?": "Що таке цифрові опціони?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "Опціоном називається похідний фінансовий контракт (дериватив), в основі якого лежить будь-який базовий актив – акція, валютна пара, нафта, тощо.",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "ЦИФРОВИЙ ОПЦІОН – це нестандартний опціон, який використовують для отримання прибутку на динаміці ціни таких активів за певний період часу.",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "Цифровий опціон, в залежності від обумовленої сторонами угоди умови, у визначений сторонами час приносить фіксований дохід (різниця між доходом від угоди і ціною активу) або збиток (в розмірі вартості активу).",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "Оскільки придбання цифрового опціону здійснюється заздалегідь за фіксованою ціною, то розмір прибутку, як і розмір потенційного збитку, відомі ще до укладення угоди.",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "Ще одна особливість цих угод – обмеження у часі. Будь-який опціон має свій термін (час експірації або виконання).",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "Незалежно від ступеня зміни ціни базового активу (наскільки вона зросла або спала), у разі виграшу опціону завжди проводиться фіксована виплата. Отже, ваші ризики обмежені тільки тією сумою, за яку здійснюється придбання опціону.",
    "What is the expiration period of a trade?": "Що таке період експірації угоди?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "Період експірації – це час, після закінчення якого угода буде вважатися виконаною (завершеною) і по ній автоматично підводиться підсумок.",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "Під час укладення угоди з цифровими опціонами ви самостійно визначаєте час виконання угоди (1 хвилина, 2 години, місяць, тощо).",
    "What is the gist of digital options trading?": "В чому полягає суть торгівлі цифровими опціонами?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "Справа в тому, що цифровий опціон – це найпростіший вид похідного фінансового інструменту. Для того, щоб заробляти гроші на ринку цифрових опціонів, не потрібно прогнозувати значення ринкової ціни активу, якого вона може досягти.",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "Принцип торгового процесу зводиться лише до вирішення однієї єдиної задачі – підвищиться або знизиться ціна активу до моменту виконання контракту.",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "Особливість такого роду опціонів в тому, що вам абсолютно неважливо, пройде ціна базового активу сто пунктів або всього один з моменту укладення угоди до її закриття. Вам важливо визначити тільки напрямок руху цієї ціни.",
    "If your prognosis is correct, in any case you get a fixed income.": "Якщо ваш прогноз виявиться правильним, ви в будь-якому випадку отримуєте фіксований дохід.",
    "What are the possible results of the placed trades?": "Які існують варіанти результату проведеної операції?",
    "There are three possible outcomes in the digital options market:": "Існує три варіанти розвитку подій на ринку цифрових опціонів:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "у разі, якщо ваш прогноз визначення напрямку руху ціни базового активу виявився правильним, ви отримуєте дохід;",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "у разі, якщо до моменту виконання опціону ваш прогноз виявився помилковим, ви несете збиток, обмежений розміром вартості активу (тобто по суті, ви можете втратити тільки свою інвестицію);",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "у разі, якщо підсумок угоди виявився нульовим (ціна базового активу не змінилася, опціон виповнився за ціною, за якою був придбаний), ви повертаєте собі свою інвестицію. Таким чином, рівень вашого ризику завжди обмежується лише розміром вартості активу.",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "Чи існує на вашій торговій платформі навчальний рахунок для того, щоб зрозуміти процес роботи з цифровими опціонами, не витрачаючи при цьому власні кошти?",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "Звісно. З метою освоєння торгових навичок і тестування можливостей торгової платформи Компанії, ви можете скористатися навчальним демо-рахунком (безкоштовний рахунок). Це свого роду тренажер, що дозволяє спочатку спробувати, а тільки потім переходити до реальної торгівлі. Такий демо-рахунок також підійде і досвідченим трейдерам для підвищення їх професійного рівня.",
    "The balance of such an account is 10,000 units.": "Баланс такого рахунку становить 10.000 одиниць.",
    "What determines profit size?": "Від чого залежить розмір прибутку?",
    "There are several factors that affect the size of your profit:": "Можна виділити декілька чинників, які впливають на розмір вашого прибутку:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "ліквідність обраного вами активу на ринку (чим більш затребуваний актив на ринку, тим більший прибуток ви отримаєте)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "час здійснення угоди (ліквідність активу вранці й ліквідність активу днем ​​можуть істотно відрізнятися)",
    "tariffs of a brokerage company": "тарифи брокерської компанії",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "зміни на ринку (економічні події, зміни в частині того чи іншого фінансового активу, тощо)",
    "What are the varieties of digital options?": "Які є різновиди цифрових опціонів?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "Здійснюючи опціонну угоду, ви повинні вибрати базовий актив, який буде лежати в основі опціону. На даний актив і буде здійснюватися ваш прогноз.",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "Простіше кажучи, купуючи цифровий контракт, ви фактично робите ставку на рух ціни такого базового активу.",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "Базовий актив – це «предмет», ціна якого і береться в розрахунок при укладенні угоди. В якості базового активу цифрових опціонів зазвичай виступають самі затребувані продукти на ринках. Їх чотири види:",
    "securities (shares of world companies)": "цінні папери (акції світових компаній)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "валютні пари (EUR / USD, GBP / USD, тощо)",
    "raw materials and precious metals (oil, gold, etc.)": "сировина і дорогоцінні метали (нафта, золото, тощо)",
    "indices (S&P 500, Dow, dollar index, etc.)": "індекси (S&P 500, Dow, Dollar Index, тощо)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "Ідеального базового активу не існує. Актив потрібно вибирати, спираючись на власні знання і інтуїцію, на різні аналітичні дані, а також на аналіз ринку для конкретного фінансового інструменту.",
    "What is a trading platform and why is it needed?": "Що таке торгова платформа і для чого вона потрібна?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "Торгова платформа – програмний комплекс, за допомогою якого Клієнт має можливість проводити угоди (операції) за допомогою запропонованих фінансових інструментів, а також отримує доступ до інформації про значення котирувань, положення ринку в режимі реального часу, дії Компанії, тощо.",
    "How to learn quickly how to make money in the digital options market?": "Як швидше навчитися заробляти на ринку цифрових опціонів?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "виробити власні стратегії торгівлі, за яких кількість правильно спрогнозованих угод буде максимальною, і дотримуватися їх",
    "diversify your risks": "диверсифікувати свої ризики",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "У виробленні стратегій, а також пошуку варіантів диверсифікації вам допоможе моніторинг ринку, вивчення аналітичної та статистичної інформації, яку можна отримати з різних джерел (інтернет-ресурси, висновки експертів, аналітиків в даній області, тощо), одне з яких – сайт Компанії.",
    "Is the download of the program to a computer or smartphone required?": "Чи потрібно завантажувати програму на комп'ютер або смартфон?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "Ні, не треба. Необхідно просто зареєструватися на сайті Компанії за встановленою формою і відкрити особистий рахунок.",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "За рахунок яких коштів Компанія здійснює виплату прибутку Клієнту в разі проведення ним успішної операції?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "Компанія заробляє разом з Клієнтами. Тому вона зацікавлена ​​в тому, щоб частка прибуткових операцій значно превалювала над часткою збиткових, в силу того, що Компанія має відсоток від виплат у разі успішно обраної Клієнтом торговельної стратегії.",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "Крім того, проведені Клієнтом угоди в сукупності складають торговий обсяг Компанії, який передається брокеру або біржі, які в свою чергу входять в об'єднання постачальників ліквідності, що в сукупності призводить до зростання ліквідності самого ринку.",
    "What is account verification?": "Що таке верифікація аккаунта?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "У цифрових опціонах верифікація – це підтвердження Клієнтом своїх особистих даних шляхом надання Компанії додаткових документів. Умови верифікації для Клієнта максимально прості, а список документів мінімальний. Наприклад, Компанія може попросити:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "надати кольорову скан-копію першого розвороту паспорта Клієнта (сторінка паспорта з фотографією)",
    "identify with the help of a \"selfie\" (photograph of himself)": "ідентифікуватися за допомогою «селфі» (фотографії самого себе)",
    "confirm the address of registration (residence) of the Client, etc": "підтвердити адресу реєстрації (проживання) Клієнта, тощо.",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "Компанія може запросити будь-які документи у разі неможливості в повному обсязі ідентифікувати Клієнта і введені ним дані.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "Після передачі Компанії електронних копій документів, Клієнту доведеться почекати деякий час для перевірки наданих даних.",
    "How to understand that I need to go through account verification?": "Як зрозуміти, що мені потрібно пройти верифікацію аккаунта?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "У разі виникнення необхідності пройти верифікацію ви отримаєте відповідне сповіщення на електронну пошту і/або смс-повідомлення.",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "При цьому необхідно мати на увазі, що Компанія використовує ті конкретні дані (зокрема, адресу електронної пошти та телефон), які ви вказали під час реєстрації, тому вкрай важливо надавати достовірні й актуальні дані.",
    "How do I know that I successfully passed verification?": "Як я дізнаюся, що успішно пройшов верифікацію?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "Ви отримаєте сповіщення на електронну пошту і/або смс-повідомлення про завершення процесу верифікації вашого облікового запису і можливості приступати до здійснення операцій на торговій платформі Компанії.",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "Чи можна вказувати чужі (фейкові) дані під час реєстрації на сайті?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "Ні. Клієнт здійснює самостійну реєстрацію на сайті Компанії, надаючи повну і достовірну інформацію у відповідях на питання у реєстраційній формі, і підтримує цю інформацію в актуальному стані.",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "У разі необхідності проведення перевірки особи Клієнта Компанія має право запросити відповідні документи або запросити Клієнта в офіс.",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "У разі якщо дані, введені в поля реєстраційної форми, не відповідають даним, зазначеним у наданих документах, рахунок може бути заблокований.",
    "How long does the verification process take?": "Скільки часу займає процес верифікації?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "До 5 (п'яти) робочих днів з моменту отримання Компанією запитаних документів.",
    "Is there a minimum amount that I can deposit to my account at registration?": "Чи встановлений розмір мінімальної суми, яку я можу зарахувати на рахунок під час реєстрації?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "Перевага торгової платформи Компанії зокрема в тому, що вам не доведеться зараховувати на рахунок великі суми. Почати торгівлю ви можете, інвестувавши незначну суму грошей. Мінімальний депозит складає 10 доларів США.",
    "How can I deposit?": "Як можна поповнити рахунок?",
    "It is very easy to do. The procedure will take a couple of minutes.": "Зробити це дуже просто. Процедура займе пару хвилин.",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "Відкрийте вікно виконання угоди і натисніть зелену кнопку «Поповнити» в правому верхньому кутку вкладки.",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "Поповнити рахунок можна також через ваш особистий кабінет, натиснувши кнопку «Поповнити» в профілі облікового запису.",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "Після необхідно вибрати спосіб поповнення рахунку (Компанія пропонує масу зручних способів, які доступні Клієнту і відображаються в його особистому кабінеті).",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "Далі вкажіть валюту, в якій буде проводитися поповнення рахунку, і відповідно валюту самого рахунку.",
    "Enter the amount of the deposit.": "Введіть розмір поповнення.",
    "Fill out the form by entering the requested payment details.": "Заповніть запропоновану форму, ввівши запитувані платіжні дані.",
    "Make a payment.": "Здійсніть платіж.",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "Чи потрібно поповнювати рахунок на торговій платформі та як часто це потрібно робити?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "Для роботи з цифровими опціонами вам необхідно відкрити особистий рахунок. Для укладення реальних угод вам безумовно потрібно буде його поповнювати на суму придбаних опціонів.",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "Почати торгівлю без грошових коштів можна тільки використовуючи навчальний рахунок компанії (демо-рахунок). Такий рахунок є безкоштовним і створений для демонстрації функціонування торгової платформи. За допомогою такого рахунку ви можете потренуватися в придбанні цифрових опціонів, зрозуміти основні принципи торгівлі, протестувати різні методики і стратегії, або ж оцінити рівень вашої інтуїції.",
    "What is the minimum deposit amount?": "Яка мінімальна сума поповнення рахунку?",
    "{number} questions": "{number} питань",
    "Verification": "Верифікація",
    "Payment": "Оплата",
    "Payouts": "Виплати",
    "Ask a specialist": "Задати питання спеціалісту",
    "FAQ": "FAQ",
    "Didn't find an answer to your question?": "Не знайшли відповідь на своє питання?",
    "Open knowledge base": "Відкрити базу знань",
    "Contact support": "Зв'язок зі службою підтримки",
    "Open FAQ page": "Відкрити сторінку FAQ",
    "New message": "Нове повідомлення",
    "Tournaments": "Турніри",
    "To end": "У кінець",
    "Until start": "До початку",
    "Front side": "Лицьова сторона",
    "Back side (if any)": "Зворотний бік (якщо є)",
    "Tourna-ments": "Турніри",
    "Deletion of Account and Personal Data": "Видалення облікового запису та персональних даних",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "Після видалення облікового запису та персональних даних ви назавжди втратите доступ до свого облікового запису на платформі Quotex. Зверніть увагу, що ваші дані будуть видалені безповоротно і відновити обліковий запис пізніше буде неможливо!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "ПРИМІТКА: Будь ласка, завершіть всі відкриті угоди і відкладені ордери перед тим, як видалити свій обліковий запис.",
    "Request Deletion": "Подати запит на видалення",
    "To delete your account, please contact support service": "Щоб видалити свій обліковий запис, зверніться до служби підтримки",
    "Two-factor authorization via Email": "Двофакторна авторизація через електронну пошту",
    "This feature is for experienced users who are familiar with Google Authenticator.": "Ця функція призначена для досвідчених користувачів, які знайомі з Google Authenticator.",
    "Please note!": "Зверніть увагу!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "Якщо ви втратите доступ до Google Authenticator після його підключення, ви втратите доступ до свого облікового запису.",
    "Proceed to setup": "Перейти до налаштування",
    "Account protection method": "Спосіб захисту облікового запису",
    "Here's how to set it up:": "Ви можете активувати цю функцію наступним чином:",
    "Please enter the PIN-code we've just sent to your email:": "Введіть PIN-код, який ми щойно надіслали на вашу електронну пошту:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "Чудово! Ви успішно активували двоетапну верифікацію через Google Authenticator. Під час наступного входу в свій обліковий запис вам потрібно буде ввести код.",
    "If you ever lose access to your 2-step verification device, please": "Якщо ви втратили доступ до свого пристрою для двоетапної верифікації, будь ласка",
    "Confirm account deletion": "Підтвердьте видалення облікового запису",
    "To delete your account, follow the link in the email sent to": "Щоб видалити обліковий запис, перейдіть за посиланням в електронному листі, надісланому на адресу",
    "Image requirements": "Вимоги до зображення",
    "Email": "E-mail",
    "Сonfirmation": "Підтвердження",
    "Change protection method": "Змінити спосіб захисту",
    "First, enter the code from the Google Authenticator app:": "Спочатку введіть код, згенерований в застосунку Google Authenticator:",
    "First, enter the PIN-code we've just sent to your email:": "Спочатку введіть PIN-код, який ми щойно надіслали на вашу електронну пошту:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "Чудово! Ви успішно активували двоетапну верифікацію через електронну пошту.",
    "Receiving codes via Email": "Отримання кодів електронною поштою",
    "Receiving codes via Google Authenticator": "Отримання кодів через Google Authenticator",
    "Choose a method:": "Виберіть спосіб:",
    "To enter the platform": "Входу на платформу",
    "To withdraw funds": "Виведення коштів",
    "day": "день тижня",
    "days2": "днів",
    "days5": "днів",


text:'Вибір валюти',
text1:'Електронна пошта',
text2:'Скасувати операцію на',
text3:'Безкоштовний промокод',
text4:'Ми щоденно вдосконалюємо цю платформу, щоб зробити її більш зручною.',
text5:'Вам подобається ця платформа? Будь ласка, рекомендуйте її іншим!',
text6:'Залиште свій відгук про QUOTEX, і ви отримаєте можливість безкоштовно скасувати операцію.',
text7:'Надішліть ваше посилання на відгук нашій службі підтримки клієнтів, після перевірки вам буде надіслано це винагороду.',
text8:'Мінімальна сума депозиту',
text9:'Мінімальна сума виведення',
text10:'Швидке виведення',
text11:'Безкоштовно',
text12:'Не підтверджено',
text13:'Надіслати ще раз',
text14:'Підтвердіть вашу участь',
text15:'Скасувати операцію',
text16:'Будь ласка, введіть і активуйте ваш промокод негайно. Ви можете використовувати промокод в будь-який час.',
text17:'Промокод:',
text18:'Отримайте бонус 30% на основі балансу депозиту',
text19:'Поточна валюта',
text20:'Доступний для виведення залишок',
text21:'На рахунку',
text22:'Сьогодні',
text23:'Вчора',
text24:'Тиждень',
text25:'Місяць',
text26:'Чистий обсяг операцій',
text27:'WELCOME - це бонус 30% за перший депозит.',
text28:'Початкова сума депозиту становить $100. Щоб отримати бонус 30%, оберіть спосіб депозиту.',
text29:'Банківська картка',
text30:'Банк',
text31:'Обраний спосіб оплати:',
text32:'Мінімальна сума:',
text33:'Максимальна сума:',
text34:'Обрати інший спосіб',
text35:'Інформація про оплату',
text36:'Сума',
text37:'Використовувати бонус',
text38:'Умови і положення',
text39:'У мене є промокод',
text40:'Промокод',
text41:'Застосувати промокод',
text42:'Введений вами промокод правильний. Ви отримаєте бонус 30%.',
text43:'Ви отримаєте бонус у розмірі',
text44:'%',
text45:'Ознайомтеся з усіма питаннями та відповідями',
text46:`Для торгівлі цифровими активами вам потрібно відкрити особистий рахунок. Для здійснення реальних операцій обов'язково внесіть суму відповідно до ціни опціону.`,
text48:'У вас є {0} промокодів',
text49:'Використовуйте цей промокод для скасування втратної операції',
text50:'Ви можете скасувати лише незахищені операції на суму, що не перевищує максимальне значення промокоду.',
text51:'Статус',
text52:'Використано',
text53:'У вас ще немає історії промокодів. Ви можете додати промокоди за допомогою кнопки нижче.',
text54:'Показати історію',
text55:'Ввести промокод',
text56:'Особиста інформація',
text57:'Порожньо',
text58:`Ім'я`,
text59:'Прізвище',
text60:'Дата народження',
text61:'Країна',
text62:'Зберегти',
text63:'Адреса',
text64:'Підтвердження документів',
text65:'Перш ніж підтвердити рахунок, вам потрібно ввести особисту інформацію.',
text66:'Безпека',
text67:'Двоетапна перевірка',
text68:'Змінити пароль',
text69:'Старий пароль',
text70:'Новий пароль',
text71:'Повторіть новий пароль',
text72:'Закрити рахунок',
text73:'Підтвердження кредитної або дебетової картки',
text74:'Додати нову банківську картку',
text75:'У вас немає жодної кредитної або дебетової картки, яку потрібно підтвердити',
text76:'У вас ще немає історії операцій.',
text77:'Початок',
text78:'Завершення',
text79:'Застосувати фільтр',
text80:'Попередній',
text81:'Наступний',
text82:'Прибуток',
text83:'Скасовано',
text84:'Рахунок',
text85:'Вартість',
text86:'Ви можете зняти кошти з рахунку платформи на вашу кредитну картку або електронний гаманець, які ви використовували для поповнення рахунку. Ви можете подати запит на виведення коштів в будь-який час. Ваш запит на виведення буде оброблено протягом 3 робочих днів.',
text87:'Внести кошти',
text88:'Деякі попередні запити',
text89:'Історія виведення коштів',
text90:'Мої запити',
text91:'Подати запит',
text92:'Часті запитання',
text93:'У вас немає жодного поданого запиту',
text94:'Якщо у вас є питання, ви можете знайти відповіді в розділі Часті запитання.',
text95:'Авторське право © 2024 Quotex. Усі права захищені.',
text96:'Увійти',
text97:'Пароль',
text98:`Запам'ятати мене`,
text99:'Повернутися до інформації',
text100:'Готово',

text104:"Інноваційна платформа для інтелектуальних інвестицій",
   text105: "Зареєструйтеся та отримайте 10 000 доларів США на демо-рахунку, де ви навчитесь торгувати",
   text106:'Реєстрація',
   text107:'Відкрити демо-рахунок',
   text108:'Зручний торговий інтерфейс',
   text109:'Ми створили найпростіший і найзручніший інтерфейс, щоб ви не відходили від найважливішого — торгівлі. ',
   text110:'Вбудований сигнал',
   text111:'Розгляньте торгову стратегію, ми надаємо найточніші та інноваційні сигнали до 87%, щоб допомогти вам розробити власну ефективну стратегію. ',
   text112:'Індикатор торгівлі',
   text113:'Ми зібрали найвигідніші торгові індикатори. Використовуйте цей показник, щоб збільшити баланс свого рахунку. ',
   text114:'Ідеальна швидкість',
   text115: '«Наша платформа використовує найінноваційніші технології та забезпечує неймовірну швидкість. ',
   text116:'Спробуйте це з демо-рахунком',
   text117:'Щоб заробити більше грошей',
   text118:'Зробіть правильний прогноз',
   text119:'Ціна бичача чи ведмежа? ',
   text120:'Відкрити демо-рахунок',
   text121:'Натисніть',
   text122:'На одній із кнопок і подивіться результати',
   text123:'Ціна зросте',
   text124:'Ціна впаде',
   text125:'Очікування результатів...',
   text126:'Ваш прибуток',
   text127:'Вибачте, ваш прогноз невірний',
   text128:'Спробуйте ще раз',
   text129:'Ваш прибуток',
   text130:'Вітаємо! Ваш прогноз правильний',
   text131:"Почніть заробляти гроші",
   text132:"Мобільний додаток завжди доступний",
   text133: '«Завантажте наш зручний додаток для iPhone або Android і починайте торгувати! ',
   text134:'Доступний час',
   text135:'Почати торгівлю',
   text136:'3 кроки',
   text137:"Відкрийте реальний рахунок безкоштовно всього за кілька хвилин",
   text138:'Торгуйте на демо-рахунку в один клік',
   text139:'Практика',
   text140:'Покращуйте свої навички за допомогою демо-рахунків і навчальних матеріалів',
   text141:'Почніть навчання з демо-облікового запису',
   text142:'Внесіть депозит на свій реальний рахунок і почніть торгувати',
   text143:'Понад 410 інструментів, мінімальна сума транзакції становить 10 доларів США',
   text144:'Метод стрибкового депозиту',
   text145:'FAQ',
   text146:'Як це опанувати? ',
   text147:'Зареєструйте демо-акаунт і попрактикуйтесь користуватися ним. Демо-рахунок такий самий, як і реальний, але депозит не потрібен. ',
   text148:'Скільки часу займає обробка виведення коштів? ',
   text149: '«У середньому процес виведення коштів займає від 1 до 5 днів із дати отримання відповідного запиту користувача та залежить лише від кількості запитів, оброблених одночасно. Компанія завжди прагне оплатити запит Користувача безпосередньо в день його отримання. ',
   text150:'Що таке торгова платформа? який ефект? ',
   text151: '«Торговий майданчик — це програмна система, яка використовує фінансові інструменти, надані цією системою, для проведення транзакцій (операцій), а також використовує котирування, ринкову ситуацію в реальному часі, дії компанії та іншу інформацію, надану компанією. ',
   text152:'Чи можу я використовувати свій мобільний телефон для здійснення операцій? ',
   text153:"Наша платформа використовує найінноваційнішу технологію, і її можна відкрити в браузері будь-якого комп'ютера чи смартфона",
   text154:'Яка мінімальна сума депозиту? ',
   text155: '«Перевагою торгової платформи цієї компанії є те, що вам не потрібно вносити великі суми грошей на свій рахунок. Ви можете почати торгувати, інвестувавши невелику суму грошей. Мінімальний депозит становить $10. ',
   text156:'Чи є комісія при внесенні або знятті грошей з рахунку? ',
   text157:'Ні. Компанія не стягує жодної комісії за такі операції, як поповнення рахунку або зняття коштів. ',
   text158: '«Однак слід зазначити, що платіжні системи можуть стягувати комісію та використовувати різні курси обміну валют. ',
   text159:'Перейти до всіх питань',
   text160:'Quotex: інноваційна платформа',
   text161:'Торгівля цифровими активами',
   text162:'Відкрийте реальний рахунок',
   text163:'Партнер',
   text164:'Демо-рахунок',
   text165:'Контактна інформація',
   text166:'Важливе запитання',
   text167:'Фінансові проблеми',
   text168:'перевірка',
   text169:'Положення',
   text170:'Політика конфіденційності',
   text171:'Умови оплати',
   text172:'Угода про надання послуг',
   text173:'Попередження про ризик',
   text174:'Правила торгових операцій',
   text175:'Неторговельні правила роботи',
   text176:`Попередження про ризики: торгівля на платформах для торгівлі цифровими активами та використання інструментів кредитування може спричинити значні ризики та призвести до повної втрати вашого інвестиційного капіталу. Не інвестуйте більше, ніж можете собі дозволити, і ви повинні розуміти пов’язані з цим ризики. Торгівля з використанням запозичених інструментів фінансування не підходить більшості інвесторів. Торгівля без використання запозичених коштів, таких як акції, також ризикована, оскільки ціни на акції можуть як знижуватися, так і зростати, що може означати, що навіть якщо ви виграєте, ви отримаєте набагато менше, ніж інвестували.
   Минулий успіх не гарантує успіху в майбутньому. Перед початком торгівлі зверніть увагу на свій досвід і рівень торгівлі, інвестиційні цілі та, якщо необхідно, зверніться до незалежного фінансового радника. Користувачі повинні визначити, чи дозволено використання послуг під брендом Quotex відповідно до законів і правил країни їхнього проживання. `,
}