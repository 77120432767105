import Vue from 'vue';
import VueRouter from 'vue-router';
import homeView from '@/views/homeView/index.vue';

Vue.use(VueRouter);
let originPush = VueRouter.prototype.push;

//接下来我们重写push|replace方法
//第一个参数location参数像原push方法声明跳转的地方 resolve和reject传递成功与失败

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //如果成功 调用原来的push方法
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {},
    );
  }
};
const routes = [
  {
    path:'/',
    component: () => import('../views/Entrance/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
  },
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/login/About.vue'),
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import('../views/login/FAQ.vue'),
  },
  {
    path: '/home',
    name: 'home',
    // component: ()=> import("../views/homeView/index.vue"),
    component: homeView,
    children: [
      {
        path: '/',
        component: () => import('../views/homeView/home.vue'),
      },
      {
        path: '/account',
        component: () => import('../views/account/account.vue'),
      },
      {
        path: '/tournaments',
        component: () => import('../views/tournaments/tournaments.vue'),
      },
      {
        path: '/tournamentsDetails',
        component: () => import('../views/tournaments/Details.vue'),
      },
      {
        path: '/help',
        component: () => import('../views/help/help.vue'),
      },
      // {
      //   path:'/diyOrder',
      //   component:()=> import("../views/diyOrder/diyOrder.vue")
      // },
      // {
      //   path:'/estimation',
      //   component:()=> import("../views/estimation/estimation.vue")
      // },
      // {
      //   path:'/search',
      //   component:()=> import("../views/search/search.vue")
      // },
      // {
      //   path:'/cart',
      //   component:()=> import("../views/cart/index.vue")
      // },
      // {
      //   path:'/details',
      //   component:()=> import("../views/shop/details.vue")
      // },
      // {
      //   path:'/user',
      //   component:()=> import("../views/user/index.vue")
      // },
      // {
      //   path:'/help',
      //   component:()=> import("../views/helpCenter/index.vue")
      // },
      // {
      //   path:'/login',
      //   component:()=> import("../views/login/index.vue")
      // },
      // {
      //   path:'/register',
      //   component:()=> import("../views/login/register.vue")
      // },
      // {
      //   path:'/richText',
      //   component:()=> import("../views/richText/index.vue")
      // },
      // {
      //   path:'/coupons',
      //   component:()=> import("../views/user/mobile/coupons")
      // },
      // {
      //   path:'/dashboard',
      //   component:()=> import("../views/user/mobile/dashboard")
      // },
      // {
      //   path:'/orders',
      //   component:()=> import("../views/user/mobile/orders")
      // },
      // {
      //   path:'/warehouse',
      //   component:()=> import("../views/user/mobile/warehouse")
      // },
      // {
      //   path:'/parcel',
      //   component:()=> import("../views/user/mobile/parcel")
      // },
      // {
      //   path:'/enquiry',
      //   component:()=> import("../views/user/mobile/enquiry")
      // },
      // {
      //   path:'/wallet',
      //   component:()=> import("../views/user/mobile/wallet")
      // },
      // {
      //   path:'/address',
      //   component:()=> import("../views/user/mobile/address")
      // },
      // {
      //   path:'/account',
      //   component:()=> import("../views/user/mobile/account")
      // },
      // {
      //   path:'/confirmOrder',
      //   component:()=> import("../views/confirmOrder/index")
      // },
      // {
      //   path:'/checkOut',
      //   component:()=> import("../views/confirmOrder/check")
      // },
    ],
  },
];

const router = new VueRouter({
  routes,
});

routes.befor;
export default router;
