export default {
    "+{value}% profit": "+{value}% de lucro",
    "Selfie verification required": "Verificação de selfie necessária",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "Para realizar as etapas necessárias de verificação de conta, solicitamos que você forneça uma foto sua em alta resolução (selfie) segurando seu documento de identidade com data atual e sua assinatura em uma folha de papel. Seu rosto, corpo e ambas as mãos devem estar visíveis.<br><br> Os detalhes do documento devem ser claros e legíveis.",
    "Your documents have been checked, now your identity is verified.": "Seus documentos foram verificados, agora sua identidade foi verificada.",
    "You need fill identity information before verification your profile.": "Você precisa preencher as informações de identidade antes de verificar seu perfil.",
    "Documents verification": "Documentos de verificação",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "A verificação do perfil significa que você tem que fornecer um documento oficial que certifica a sua identidade. Este procedimento pode ser iniciado pelo departamento de segurança da empresa a qualquer momento.",
    "Upload Photo": "Carregar foto",
    "Nickname": "Apelido",
    "First Name": "Nome",
    "Last Name": "Sobrenome",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "O nome deve ser especificado exatamente como no documento. Se você fornecer informações incorretas, a conta poderá ser excluída sem aviso.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "O sobrenome deve ser especificado exatamente como no documento. Se você fornecer informações incorretas, a conta poderá ser excluída sem aviso.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "Não é possível alterar mais sua data de nascimento. Se você o indicou incorretamente, não poderá sacar fundos de sua conta.",
    "Empty": "Vazio",
    "Date of birth": "Data de nascimento",
    "Unverified": "Não verificado",
    "Verified": "Verificado",
    "Resend": "Reenviar",
    "Country": "País",
    "Address": "Endereço",
    "Change Identity Info": "Alterar dados de identidade",
    "Identity Info": "Informações de identidade",
    "Two-factor authentication on login": "Autenticação de dois fatores no login",
    "Two-factor authentication on withdrawal": "Autenticação de dois fatores na retirada",
    "Active": "Ativo",
    "Inactive": "Inativo",
    "Setup": "Configurar",
    "Two-step verification": "Verificação em duas etapas",
    "Security": "Segurança",
    "Google Authenticator": "Autenticador do Google",
    "Scan the QR code": "Digitalize o código QR",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Levamos sua segurança muito a sério, e é por isso que exigimos a verificação em duas etapas. Veja como configurá-lo:",
    "Install ": "Instalar",
    "Can't scan?": "Não consegue digitalizar?",
    "Click here.": "Clique aqui.",
    "Enter this code into Google Authenticator.": "Digite este código no Google Authenticator.",
    "Back to QR code.": "Voltar para o código QR.",
    "Enter the 6-digit code from Authenticator below": "Digite o código de 6 dígitos do Autenticador abaixo",
    "Enter 6-digit code...": "Digite o código de 6 dígitos...",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "Bom trabalho. Você ativou com sucesso a verificação em duas etapas. Na próxima vez que você entrar, será solicitado um código.",
    "Code": "Código",
    "contact support": "entre em contato com o suporte",
    "If you ever lose access to your 2-step verification device, please ": "Se você perder o acesso ao seu dispositivo de verificação em duas etapas,",
    "Yes, activate": "Sim, ativar",
    "Cancel": "Cancelar",
    "Close": "Fechar",
    "Language": "Idioma",
    "Timezone": "Fuso horário",
    "Delete Account": "Deletar conta",
    "Verification Credit / Debit Cards": "Verificação de cartões de crédito / débito",
    "Add New Card": "Adicionar novo cartão",
    "Wait for loading...": "Aguarde o carregamento...",
    "You don’t have any credit / debit cards for verification": "Não há nenhum cartão de crédito / débito para verificação",
    "Verificate": "Verificar",
    "Verification Card": "Verificação do cartão",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "Por favor, coloque sua identidade (mostrando foto, nome e data de nascimento) junto com a frente do seu cartão bancário (mostrando o número, data de validade e seu nome) em uma folha A4 em branco.<br> Por favor, escreva “Quotex.io ” e adicione sua assinatura a ela.<br> Tire uma foto e faça o upload.",
    "Card:": "Cartão:",
    "Type:": "Tipo:",
    "Your ID and bank card on an A4 sheet": "Sua identidade e cartão bancário em uma folha A4",
    "Yes, confirm": "Sim, estou certo",
    "Click or drag the image": "Clique ou arraste a imagem",
    "Card": "Cartão",
    "Documents": "Documentos",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "Primeiro, preencha a seção \"Informações de identidade\" em seu perfil e clique no botão \"Alterar informações de identidade\" para salvar as alterações. Depois disso, você poderá adicionar um novo cartão.",
    "Specify the card details for further operations for depositing and debiting funds.": "Especifique os detalhes do cartão para outras operações de depósito e débito de fundos.",
    "Card number:": "Número do cartão:",
    "Expiry month:": "Mês de vencimento:",
    "Expiry year:": "Ano de expiração:",
    "Cardholder name:": "Nome do Titular:",
    "Next": "Próximo",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "Por favor, coloque sua assinatura e a data atual na folha, coloque seu documento junto com seu cartão bancário, tire uma foto e faça o upload no formulário abaixo.",
    "Do you have a two-sided document?": "Você tem um documento frente e verso?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "Carregue o verso como um arquivo separado, também em uma folha com a data e sua assinatura.",
    "Submit only valid documents": "Envie apenas documentos válidos",
    "Do not edit or crop photo": "Não edite ou corte a foto",
    "Do not upload other people's documents": "Não carregue documentos de outras pessoas",
    "Read all": "Ler tudo",
    "Uploading files": "Fazendo upload de arquivos",
    "For single-sided documents, upload only the front side.": "Para documentos frente e verso, carregue apenas o lado frontal.",
    "Back": "De volta",
    "Add a card": "Adicione um cartão",
    "File Requirements": "Requisitos do arquivo",
    "Scanned images are not accepted for card verification": "Imagens digitalizadas não são aceitas para verificação de cartão",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "Aceitamos apenas arquivos com seu passaporte civil regular ou identidade nacional (ambos os lados devem ser enviados como arquivos separados)",
    "Driving license CANNOT be accepted": "Carta de condução NÃO pode ser aceite",
    "External (foreign/international/travel) passport will not be accepted as well": "Passaporte externo (estrangeiro/internacional/de viagem) também não será aceito",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "Se o documento tiver 2 faces, você precisa fazer o upload do verso como arquivo separado, na mesma folha de papel, com data atual e sua assinatura",
    "All information in the document and on the card should be clear and readable on the photo": "Todas as informações no documento e no cartão devem ser claras e legíveis na foto",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "Tanto seu documento quanto seu cartão devem caber na foto completamente as bordas não devem ser cortadas",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "Não deve haver brilhos ou outros defeitos na foto que possam dificultar a leitura dos dados do documento e do cartão",
    "Expired documents and cards will be rejected automatically": "Documentos e cartões expirados serão rejeitados automaticamente",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "Se o seu cartão não tiver o seu nome, você precisa fazer o upload de uma captura de tela do extrato bancário, mostrando o número do seu cartão e seu nome. Em alguns casos, é permitido escrever seu nome à mão na mesma folha de papel",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "Não é permitido ocultar elementos dos documentos, contendo os dados necessários para verificação (nome, data de nascimento, fotografia)",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "É permitido ocultar os 8 dígitos do número do cartão. Os primeiros 4 e os últimos 4 dígitos devem ser claramente visíveis",
    "CVV code should be hidden at all times, in every case": "O código CVV deve estar sempre oculto, em todos os casos",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "Qualquer edição de seus arquivos é proibida. Somente fotos não editadas e imagens digitalizadas podem ser aceitas. A edição de informações pessoais em seus documentos (nome, data de nascimento, etc.)",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "A fotografia deve fazer parte do documento (colada ou impressa). Fotos anexadas separadamente não serão aceitas",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "Só é permitido fazer upload de documentos e cartões no nome, especificado no perfil (no qual sua conta está cadastrada)",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "Lembramos que para o envio de informações falsas/falsas a conta pode ser suspensa/bloqueada, até que as circunstâncias sejam esclarecidas",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "A Empresa se reserva o direito de solicitar que você forneça quaisquer documentos adicionais para verificação da conta (incluindo sua selfie com documento) a qualquer momento",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "Para todas as questões relacionadas à verificação de documentos, você pode criar um ticket de suporte na plataforma (a seção \"Ajuda\" é o primeiro botão com o ícone de ponto de interrogação piscando, no painel mais à esquerda)",
    "Profile": "Perfil",
    "Deposit": "Depósito",
    "Withdrawal": "Retirada",
    "Transactions": "Transações",
    "Trades": "Operações",
    "Market": "Mercado",
    "Analytics": "Análise",
    "My current currency": "Minha moeda atual",
    "Available for withdrawal": "Disponível para retirada",
    "In the account": "Na conta",
    "Percentage of turnover {percent}%": "Porcentagem de faturamento {percent}%",
    "Cashback {percent}%": "Reembolso {percent}%",
    "Currency:": "Moeda:",
    "Change": "Mudar",
    "Live Account": "Conta real",
    "Daily budget limit": "Limite de orçamento diário",
    "The value cannot exceed your total budget": "O valor não pode exceder seu orçamento total",
    "Demo Account": "Conta demo",
    "Apply": "Aplicar",
    "Edit": "Editar",
    "New value": "Novo valor",
    "Confirm": "Confirmar",
    "Logout": "Sair",
    "No, cancel": "Não, quero cancelar",
    "Exchange Form": "Formulário de troca",
    "My Currency:": "Minha moeda:",
    "New Currency:": "Nova moeda:",
    "You are exchanging": "Você está trocando",
    "You will receive": "Você receberá",
    "Exchange Fee": "Taxa de câmbio",
    "Password changed": "Senha alterada",
    "Old password": "Senha antiga",
    "New password": "Nova senha",
    "Confirm new password": "Confirmar nova senha",
    "Change Password": "Alterar senha",
    "Confirm password must be equal new password value": "Confirmar senha deve ser igual ao valor da nova senha",
    "About us": "Sobre nós",
    "Support": "Suporte",
    "Help": "Ajuda",
    "2-step verification": "Verificação em duas etapas",
    "Please enter the PIN-code we've just sent to your email": "Insira o código PIN que acabamos de enviar para o seu e-mail",
    "Please enter the 6-digit code from your authentication app": "Insira o código de 6 dígitos do seu aplicativo de autenticação",
    "The First Name field is required": "O campo Nome é obrigatório",
    "The Last Name field is required": "O campo Sobrenome é obrigatório",
    "Verification of documents": "Verificação de documentos",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "Favor carregar uma foto colorida ou imagem digitalizada de seu passaporte civil comum, carteira de motorista ou carteira de identidade nacional.",
    "Upload Documents": "Carregar documentos",
    "Why?": "Por quê?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "Deseja confirmar a exclusão de sua conta sem desfazer? Essa ação não pode ser desfeita.",
    "Set up 2-step verification": "Configurar a verificação em duas etapas",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "Infelizmente, você não pode fazer upload de arquivos para esta seção. <br> Consulte as mensagens da equipe de suporte e anexe os arquivos necessários a um ticket.",
    "The uploaded document does not match the requirements": "O documento carregado não corresponde aos requisitos",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "Os seguintes documentos são elegíveis para identificação: RG, passaporte, passaporte internacional ou carteira de motorista.",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "As imagens devem ser de alta qualidade, os dados devem ser fáceis de ler e as bordas do documento não devem ser cortadas.",
    "Balance from {amount}": "Saldo de {amount}",
    "Not verified": "Não verificado",
    "Clear": "Claro",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "Para evitar restrições de serviço, complete o processo de verificação: preencha seus dados pessoais e faça o upload dos documentos necessários.",
    "Get verified": "Ser verificado",
    "I got it!": "Entendi!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "Verificação de AADHAAR",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "Favor carregar uma foto colorida de seu Aadhaar físico (somente o lado frontal com sua foto e número de documento).",
    "Send AADHAAR": "Enviar AADHAAR",
    "AADHAAR number:": "Número do AADHAAR:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "Favor carregar uma foto colorida de seu Aadhaar físico (somente o lado frontal com sua foto e número de documento). ☝ Não são aceitas versões eletrônicas.",
    "You have already uploaded {number} files. Please wait until they are checked.": "Você já carregou {number} arquivos . Por favor, aguarde até que sejam verificados.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "Por favor, carregue uma fotografia colorida de seu documento: Para RG - ambos os lados precisam ser carregados como arquivos separados. Para CNH - somente o lado da frente com sua foto. O documento deve ser válido (menos de 10 anos a partir da data de expedição), e deve mostrar seu número de CPF. * caso não haja CPF em seu documento - favor carregar uma foto separada da Comprovante de Inscrição no CPF, ou uma captura de tela de seu extrato bancário mostrando seu nome e número de CPF. ** Por favor, não carregue nenhuma versão eletrônica. Elas não serão aceitas.",
    "Submit for verification": "Enviar para verificação",
    "Settings": "Configurações",
    "Please upload a color photograph of the front of your national identity card with your photo.": "Favor carregar uma fotografia colorida da frente de sua carteira de identidade nacional com sua foto.",
    "Template": "Modelo",
    "Light Mode": "Modo diurno",
    "Twilight": "Crepúsculo",
    "Full Night": "Noite inteira",
    "Grid's opacity": "Opacidade da grade",
    "Auto-scrolling": "Rolagem automática",
    "Automatic graphic scrolling": "Rolagem gráfica automática",
    "1-click trade": "Negociação com 1 clique",
    "Open trades without confirmation": "Negociações abertas sem confirmação",
    "Use timezone": "Usar fuso horário",
    "Use your timezone data": "Use seus dados de fuso horário",
    "Graph colors": "Cores do gráfico",
    "Up Trend": "Tendência de alta",
    "Down Trend": "Tendência de baixa",
    "Platform": "Plataforma",
    "Short order label": "Rótulo de pedido curto",
    "Use short order element mode": "Use o modo de elemento de ordem curta",
    "Perfomance Mode": "Modo de desempenho",
    "Use optimized rendering for chart and candles": "Use renderização otimizada para gráficos e velas",
    "Background": "Fundo",
    "Choose file": "Escolher arquivo",
    "Max size — 2 Mb": "Tamanho máximo — 2 MB",
    "Sign up": "Inscrever-se",
    "Sign in": "Entrar",
    "Budget and limits": "Orçamento e limites",
    "Daily limit": "Limite diário",
    "Not set": "Não definido",
    "Total funds": "Fundos totais",
    "Top up": "Recarga",
    "Set up": "Configuração",
    "Set a daily limit on trading to reduce financial risks.": "Defina um limite diário de negociação para reduzir os riscos financeiros.",
    "Limit amount": "Valor limite",
    "More": "Mais",
    "Leader Board": "Tabela de classificação",
    "Signals": "Sinais",
    "Notifications": "Notificações",
    "mark all as read": "marcar tudo como lido",
    "No notifications": "Nenhuma notificação",
    "No more notifications": "Não há mais notificações",
    "Your position:": "Sua posição:",
    "How does this rating work?": "Como funciona essa classificação?",
    "of the Day": "do dia",
    "Nothing has been found for current query, try another asset name.": "Nada foi encontrado para a consulta atual. Tente outro nome de recurso.",
    "My rating in the Leader Board": "Minha classificação na Tabela de Líderes",
    "Participants can only be those who trade in live mode": "Os participantes só podem ser aqueles que negociam no modo ao vivo",
    "Your position depends on the amount of earned money": "Sua posição depende da quantidade de dinheiro ganho",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "Calculado todos os dias, das 00:00 UTC às 23:59 UTC",
    "All traders on our platform participate in this rating. Main features of the rating:": "Todos os traders em nossa plataforma participam desta classificação. Principais características da classificação:",
    "How does it work?": "Como funciona?",
    "Trading signals": "Sinais de negociação",
    "Past signals": "Sinais passados",
    "What’s it?": "O que é isso?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "Um sinal de negociação não é uma instrução direta para negociar, mas uma recomendação de analista. Não deve substituir a análise de mercado independente.",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "Você tem uma média de 10 minutos para aproveitar o sinal de negociação. Após esse período, as cotações podem mudar e ter um efeito inesperado no resultado da negociação.",
    "Continue": "Continuar",
    "Duration": "Duração",
    "Return back to FAQ": "Voltar para FAQ",
    "Return back to Help": "Voltar para Ajuda",
    "How to withdraw money from the account?": "Como posso retirar dinheiro da conta?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "O procedimento de retirada de dinheiro é extremamente simples.",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "O método que escolheu para depositar a conta também é um método de retirada de fundos (consulte a pergunta \"Como posso depositar?\").",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "Por exemplo, se você fizer um depósito por meio do sistema Visa, você também retirará o seu dinheiro pelo mesmo sistema.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "Quando a retirada é de um valor suficientemente grande, a empresa pode solicitar verificação a seu critério exclusivo, por isso é muito importante você registrar uma conta individual, a fim de confirmar seus direitos a ela a qualquer momento.",
    "What is the minimum withdrawal amount?": "Qual é o valor mínimo da retirada?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "O valor mínimo de retirada começa em 10 USD para a maioria dos sistemas de pagamento.<br>Para criptomoedas, esse valor começa em 50 USD (e pode ser maior para certas moedas, por exemplo, Bitcoin).",
    "Do I need to provide any documents to make a withdrawal?": "Preciso fornecer algum documento para fazer uma retirada?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "Normalmente, não são necessários documentos adicionais para retirar fundos. Entretanto, a empresa, a seu critério, pode solicitar que você confirme seus dados pessoais, solicitando determinados documentos. Normalmente, isso é feito para evitar atividades relacionadas ao comércio ilegal, fraudes financeiras, bem como a utilização de recursos obtidos de forma ilegal.",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "A lista de tais documentos é mínima e a operação para fornecê-los não exigirá muito tempo e esforço.",
    "How long does it take to withdraw funds?": "Quanto tempo leva para retirar fundos?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "Em média, o procedimento de retirada leva de um a cinco dias a partir da data do recebimento da solicitação correspondente, e depende apenas do volume de solicitações processadas simultaneamente. A empresa sempre tenta efetuar os pagamentos no dia em que é recebida a solicitação.",
    "Is there any fee for depositing or withdrawing funds from the account?": "Existe alguma taxa para depositar ou retirar fundos da conta?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "Não, a empresa não cobra qualquer taxa nem pelo depósito nem pela retirada.",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "No entanto, vale a pena considerar que os sistemas de pagamento podem cobrar sua taxa e usar a taxa interna de conversão de moeda.",
    "Can I close my account? How to do it?": "Posso fechar minha conta? Como faço isso?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "Você pode excluir sua conta clicando no botão \"Excluir conta\" localizado na parte inferior da página do perfil.",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "Cometi um erro ao inserir dados na minha conta, como posso corrigir isso?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "Você precisa entrar em contato com o serviço de suporte técnico no site e editar o perfil.",
    "What data is required to register on the Company website?": "Quais dados são necessários para se registrar?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "Para ganhar dinheiro com opções digitais, você deve primeiro abrir uma conta que permita fazer transações. Para fazer isso, você precisa se registrar no site da empresa.",
    "The registration process is simple and does not take much time.": "O processo de registro é simples e não leva muito tempo.",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "É necessário preencher um formulário com os seguintes dados:",
    "name (in English)": "Nome e sobrenome (use caracteres latinos)",
    "email address (indicate the current, work, address)": "Endereço de e-mail (especifique um endereço de e-mail válido)",
    "telephone (with a code, for example, + 44123 ....)": "Número de telefone (com código de país, por exemplo, +55 ....)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "Uma senha que você usará para fazer login. Para minimizar o risco de acesso não autorizado à sua conta, nós recomendamos que você crie uma senha complexa usando letras maiúsculas e minúsculas e números. Não revele a senha a terceiros.",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "Após preencher o questionário, serão oferecidas várias maneiras de fazer depósits para a sua negociação.",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "Em que moeda se abre a conta do cliente? Posso alterar a moeda da conta?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "Por padrão, uma conta de negociação é aberta em dólares americanos. Mas, para sua conveniência, você pode mudar para moeda que você deseja a qualquer momento.",
    "A list of available currencies can be found on your profile page in your Client's account.": "Uma lista de moedas disponíveis pode ser encontrada na página do seu perfil.",
    "What are digital options?": "O que são opções digitais?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "Uma opção é um instrumento financeiro derivado baseado em qualquer ativo subjacente (ela pode ser uma ação, par de moedas, petróleo, etc.).",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "Opção digital é uma opção não padronizada que é utilizada para obter lucro sobre os movimentos de preços de certos ativos por um período de tempo determinado.",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "Uma opção digital, dependendo dos termos estabelecidos da transação e em um momento determinado pelas partes, traz uma renda fixa (a diferença entre a renda da negociação e o preço do ativo) ou perda (no valor do ativo).",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "Como a opção digital é adquirida antecipadamente a um preço fixo, o tamanho do lucro, bem como o tamanho da perda potencial, são conhecidos antes mesmo da negociação.",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "Outra característica dessas negociações é o limite de tempo. Qualquer opção tem seu próprio prazo (prazo de validade ou prazo de conclusão).",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "Independentemente do grau de variação do preço do ativo subjacente (quanto passou para cima ou para baixo), em caso de ganho da opção, sempre será efetuado um pagamento fixo. Portanto, seus riscos são limitados apenas pelo valor da opção.",
    "What is the expiration period of a trade?": "O que é o período de expiração de uma negociação?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "O período de expiração é o tempo após o qual a negociação será considerada concluída (fechada) e o resultado será automaticamente somado.",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "Ao concluir uma negociação com opções digitais, você determina de forma independente o tempo de execução da transação (um minuto, duas horas, um mês, etc.).",
    "What is the gist of digital options trading?": "Qual é a essência da negociação de opções digitais?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "O fato é que a opção digital é o tipo mais simples de instrumento financeiro derivativo. Para ganhar dinheiro no mercado de opções digitais, você não precisa prever o valor do preço de mercado de um ativo.",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "O princípio do processo de negociação é reduzido apenas à solução de uma única tarefa: se o preço de um ativo aumentará ou diminuirá no momento em que o contrato for executado.",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "O aspecto de tais opções é que não importa que o preço do ativo subjacente vá para cem pontos ou apenas um, desde o momento em que a negociação é concluída até o seu fechamento. É importante determinar apenas a direção do movimento desse preço.",
    "If your prognosis is correct, in any case you get a fixed income.": "Se a sua previsão for correta, em qualquer caso, você obtém uma renda fixa.",
    "What are the possible results of the placed trades?": "Quais são as variantes para o resultado de uma negociação?",
    "There are three possible outcomes in the digital options market:": "Existem três variantes do resultado no mercado de opções digitais:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "Caso a sua previsão do movimento do preço do ativo subjacente esteja correto, você recebe uma renda.",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "Se no momento em que a opção foi concluída sua previsão for errada, incorre em uma perda limitada pelo tamanho do valor do ativo (ou seja, na verdade, você só pode perder seu investimento, nada mais).",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "Se o resultado da negociação for zero (o preço do ativo subjacente não mudou, a opção é concluída ao preço pelo qual foi comprada), você recebe o valor do seu investimento. Assim, o nível do seu risco é sempre limitado apenas por o tamanho do valor do ativo.",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "A sua plataforma de negociação tem uma conta demo para entender o processo de trabalhar com opções digitais sem gastar dinheiro?",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "Sim. Para desenvolver habilidades de negociação e testar as capacidades da plataforma de negociação da empresa, você pode usar uma conta demo (gratuita). Este é um tipo de simulador que lhe permite experimentar primeiro e só depois passar para a negociação real. Essa conta demo também é adequada para traders experientes que quiserem melhorar seu nível profissional.",
    "The balance of such an account is 10,000 units.": "O saldo dessa conta é de 10.000 unidades.",
    "What determines profit size?": "O que determina o tamanho do lucro?",
    "There are several factors that affect the size of your profit:": "Existem vários fatores que afetam o tamanho do seu lucro:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "A liquidez do ativo que escolheu no mercado (quanto mais o ativo estiver em demanda no mercado, mais lucro receberá)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "O momento da negociação (a liquidez de um ativo pela manhã e a pela tarde pode variar significativamente)",
    "tariffs of a brokerage company": "Tarifas da corretora",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "Alterações de mercado (eventos econômicos, alterações do preço de um ativo financeiro, etc.)",
    "What are the varieties of digital options?": "Quais são as variedades de opções digitais?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "Ao negociar uma opção, você deve escolher o ativo subjacente que estará na base da opção. Sua previsão será realizada neste ativo.",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "Simplificando, ao comprar um contrato digital, você está na verdade apostando no movimento do preço desse ativo subjacente.",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "Um ativo subjacente é um \"item\", cujo preço é levado em consideração ao fazer um negócio. Os produtos mais populares nos mercados geralmente atuam como o ativo subjacente das opções digitais. Existem quatro tipos deles:",
    "securities (shares of world companies)": "títulos (ações de empresas mundiais)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "pares de moedas (EUR/USD, GBP/USD, etc.)",
    "raw materials and precious metals (oil, gold, etc.)": "matérias-primas e metais preciosos (petróleo, ouro, etc.)",
    "indices (S&P 500, Dow, dollar index, etc.)": "índices (S&P 500, Dow Jones, índice do dólar, etc.)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "Não existe um ativo subjacente universal. Ao escolhê-lo, você tem de usar o seu próprio conhecimento, intuição e vários tipos de informações analíticas, bem como a análise de mercado de um determinado instrumento financeiro.",
    "What is a trading platform and why is it needed?": "O que é uma plataforma de negociação e por que ela é necessária?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "A plataforma de negociação é um complexo de software no qual o cliente tem a oportunidade de realizar negociações (operações) utilizando os instrumentos financeiros oferecidos, além do acesso a informações sobre os preços dos ativos, posição de mercado em tempo real, ações da empresa, etc.",
    "How to learn quickly how to make money in the digital options market?": "Como se pode aprender a ganhar dinheiro no mercado de opções digitais de uma forma rápida?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "desenvolver suas próprias estratégias de negociação, nas quais o número de negociações previstas corretamente será o máximo possível, e segui-las",
    "diversify your risks": "diversificar seus riscos",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "No desenvolvimento de estratégias, bem como na busca de opções de diversificação, é preciso monitorar o mercado e estudar as informações analíticas e estatísticas que podem ser obtidas de várias fontes (recursos da Internet, opiniões de especialistas, analistas da área, etc.). O nosso site pode lhe ajudar nesta tarefa.",
    "Is the download of the program to a computer or smartphone required?": "É necessário baixar o programa para um computador ou smartphone?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "Não, não é preciso. Só você precisa se registrar no site da empresa e abrir uma conta.",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "Com que fundos a Empresa paga o lucro ao Cliente no caso de uma transação bem-sucedida?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "A empresa ganha com os clientes. Portanto, temos interesse que a parcela das transações lucrativas prevaleça significativamente sobre a parcela das não lucrativas, pelo fato de a empresa possuir um percentual de pagamentos para uma estratégia de negociação bem-sucedida escolhida pelo cliente.",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "Além disso, as negociações realizadas pelo cliente em conjunto constituem o volume de negociação da empresa, que é transferido para uma corretora ou bolsa, que por sua vez são incluídas no pool de provedores de liquidez, o que, em conjunto, leva a um aumento na liquidez do mercado em si.",
    "What is account verification?": "O que é a verificação de conta?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "A verificação significa uma confirmação pelo cliente dos seus dados pessoais ao fornecer à empresa os documentos adicionais. As condições de verificação para o cliente são as mais simples possíveis e a lista de documentos é mínima. Por exemplo, a empresa pode solicitar o seguinte:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "Uma cópia digitalizada em cores do documente de identificação (com foto)",
    "identify with the help of a \"selfie\" (photograph of himself)": "Uma selfie",
    "confirm the address of registration (residence) of the Client, etc": "Uma confirmação do endereço de residência do cliente",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "A empresa também pode solicitar quaisquer documentos caso não seja possível identificar totalmente o cliente e os dados inseridos por ele.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "Após o envio das cópias eletrónicas dos documentos à empresa, o cliente terá de aguardar algum tempo enquanto se verificarem os dados fornecidos.",
    "How to understand that I need to go through account verification?": "Como entendo que preciso passar pela verificação da conta?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "Se for necessário verificar a conta, você receberá uma notificação por e-mail e/ou por SMS.",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "A empresa usa os dados de contato que você especificou no questionário durante o registro (em particular, o endereço de e-mail e o número de telefone), portanto, seja cuidadoso e forneça informações relevantes e corretas.",
    "How do I know that I successfully passed verification?": "Como posso saber se meus dados estão verificados com sucesso?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "Você receberá uma notificação por e-mail e/ou SMS sobre a conclusão do processo de verificação de sua conta e a possibilidade de prosseguir com as operações na plataforma de negociação.",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "É possível indicar dados falsos ou de outras pessoas ao se cadastrar no site?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "Não. O cliente deve fornecer as informações completas e precisas sobre si mesmo nas questões solicitadas no formulário de cadastro, e deve manter essas informações atualizadas.",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "Se for necessário realizar vários tipos de verificação da identidade do cliente, a empresa pode solicitar documentos ou convidar o cliente para o seu escritório.",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "Se os dados inseridos nos campos de registro não corresponderem aos dados dos documentos enviados, o seu perfil pode ser bloqueado.",
    "How long does the verification process take?": "Quanto tempo leva o processo de verificação?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "No máximo 5 dias úteis a partir da data em que a empresa receber os documentos solicitados.",
    "Is there a minimum amount that I can deposit to my account at registration?": "Há um valor mínimo que posso depositar em minha conta no momento do registro?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "A vantagem da plataforma de negociação da empresa é que não você precisa depositar grandes quantias em sua conta. Você pode começar a negociar investindo uma pequena quantia em dinheiro, sendo o depósito mínimo de só $10.",
    "How can I deposit?": "Como posso depositar?",
    "It is very easy to do. The procedure will take a couple of minutes.": "É muito fácil, e o procedimento levará só alguns minutos.",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "Abra a janela de negociação e clique no botão verde \"Depósito\" no canto superior direito da guia.",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "Você também pode depositar na conta clicando no botão \"Depositar\" no perfil.",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "Depois disso, é necessário escolher um método de depósito da conta (a empresa oferece vários métodos convenientes disponíveis ao cliente).",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "Em seguida, indique a moeda em que a conta será depositada e, consequentemente, a moeda da própria conta.",
    "Enter the amount of the deposit.": "Insira o valor do depósito.",
    "Fill out the form by entering the requested payment details.": "Preencha o formulário inserindo os dados de pagamento solicitados.",
    "Make a payment.": "Faça um pagamento.",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "Preciso depositar na conta da plataforma de negociação e com que frequência preciso fazer isso?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "Para negociar opções digitais, você precisa abrir uma conta. Para concluir transações reais, você certamente precisará fazer um depósito no valor das opções adquiridas.",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "Você pode começar a negociar sem dinheiro, usando apenas a conta de treinamento (conta demo). Esta conta é gratuita e é criada para demonstrar o funcionamento da plataforma de negociação. Com uma conta assim, você pode praticar a negociação de opções digitais, entender os princípios básicos, testar vários métodos e estratégias ou avaliar o nível de sua intuição.",
    "What is the minimum deposit amount?": "Qual é o valor mínimo do depósito?",
    "{number} questions": "{number} perguntas",
    "Verification": "Verificação",
    "Payment": "Depósitos",
    "Payouts": "Retiradas",
    "Ask a specialist": "Pergunte a um especialista",
    "FAQ": "Perguntas frequentes",
    "Didn't find an answer to your question?": "Não encontrou uma resposta para sua pergunta?",
    "Open knowledge base": "Abrir a base de conhecimento",
    "Contact support": "Contatar o suporte",
    "Open FAQ page": "Abrir a página FAQ",
    "New message": "Nova mensagem",
    "Tournaments": "Torneios",
    "To end": "Para terminar",
    "Until start": "Até o início",
    "Front side": "Frente",
    "Back side (if any)": "Lado reverso (se houver)",
    "Tourna-ments": "Torneios",
    "Deletion of Account and Personal Data": "Exclusão da conta e dos dados pessoais",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "Ao excluir sua conta e seus dados pessoais, você perderá permanentemente o acesso à sua conta na plataforma Quotex. Lembre-se de que seus dados serão excluídos irremediavelmente e é impossível restaurar sua conta posteriormente!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "OBS: Por favor, complete todas as negociações abertas e ordens pendentes antes de excluir sua conta.",
    "Request Deletion": "Solicitar a exclusão",
    "To delete your account, please contact support service": "Para excluir sua conta, favor contatar o serviço de suporte",
    "Two-factor authorization via Email": "Autorização de dois fatores por e-mail",
    "This feature is for experienced users who are familiar with Google Authenticator.": "Esta função é para usuários experientes que estão familiarizados com o Google Authenticator.",
    "Please note!": "Por favor, observe!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "Se você perder o acesso ao Google Authenticator por qualquer motivo depois de conectá-lo, você perderá o acesso à sua conta.",
    "Proceed to setup": "Proceder à configuração",
    "Account protection method": "Método de proteção de conta",
    "Here's how to set it up:": "Veja como configurá-lo:",
    "Please enter the PIN-code we've just sent to your email:": "Por favor, digite o código PIN que acabamos de enviar ao seu e-mail:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "Bom trabalho. Você habilitou com sucesso a verificação em 2 etapas através do Google Authenticator. Na próxima vez em que você entrar, será solicitado um código.",
    "If you ever lose access to your 2-step verification device, please": "Se alguma vez você perder o acesso ao seu dispositivo de verificação em duas etapas, por favor",
    "Confirm account deletion": "Confirmar exclusão de conta",
    "To delete your account, follow the link in the email sent to": "Para excluir sua conta, siga o link no e-mail enviado para",
    "Image requirements": "Requisitos de imagem",
    "Email": "E-mail",
    "Сonfirmation": "Confirmação",
    "Change protection method": "Mudar o método de proteção",
    "First, enter the code from the Google Authenticator app:": "Primeiro, digite o código do aplicativo Google Authenticator:",
    "First, enter the PIN-code we've just sent to your email:": "Primeiro, digite o código PIN que acabamos de enviar ao seu e-mail:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "Bom trabalho. Você habilitou com sucesso a verificação em 2 etapas por e-mail.",
    "Receiving codes via Email": "Recebimento de códigos por email",
    "Receiving codes via Google Authenticator": "Recebendo códigos através do Google Authenticator",
    "Choose a method:": "Escolha um método:",
    "To enter the platform": "Para entrar na plataforma",
    "To withdraw funds": "Para retirar fundos",
    "day": "dia",
    "days2": "dias2",
    "days5": "dias5",

text:'Seleção de Moeda',
text1:'E-mail',
text2:'Cancelar Transação para',
text3:'Código de Cupom Gratuito',
text4:'Estamos melhorando esta plataforma diariamente para torná-la mais conveniente.',
text5:'Você gosta desta plataforma? Por favor, compartilhe com os outros!',
text6:'Por favor, deixe seus comentários sobre a QUOTEX. Você receberá uma recompensa de cancelamento de transação gratuita.',
text7:'Envie seu link de comentário para nossa equipe de suporte ao cliente, após revisão, a recompensa será enviada para você.',
text8:'Valor Mínimo de Depósito',
text9:'Valor Mínimo de Retirada',
text10:'Retirada Rápida',
text11:'Grátis',
text12:'Não Verificado',
text13:'Reenviar',
text14:'Confirme sua participação',
text15:'Cancelar Transação',
text16:'Por favor, insira e ative seu código de cupom imediatamente. Você pode usar o código de cupom a qualquer momento.',
text17:'Código de Cupom:',
text18:'Receba um bônus de 30% com base no saldo do depósito',
text19:'Moeda Atual',
text20:'Saldo Disponível para Retirada',
text21:'Na Conta',
text22:'Hoje',
text23:'Ontem',
text24:'Semana',
text25:'Mês',
text26:'Volume de Transações Líquido',
text27:'WELCOME é um bônus de 30% para o primeiro depósito.',
text28:'O primeiro valor de depósito é $100. Para receber um bônus de 30%, escolha o método de depósito.',
text29:'Cartão Bancário',
text30:'Banco',
text31:'Método de Pagamento Selecionado:',
text32:'Valor Mínimo:',
text33:'Valor Máximo:',
text34:'Selecione Outro Método',
text35:'Informações de Pagamento',
text36:'Valor',
text37:'Usar Bônus',
text38:'Termos e Condições',
text39:'Eu Tenho um Código de Cupom',
text40:'Código de Cupom',
text41:'Aplicar Código de Cupom',
text42:'O código de cupom inserido está correto. Você receberá um bônus de 30%.',
text43:'Você receberá um bônus de',
text44:'%',
text45:'Por favor, entenda todas as perguntas frequentes',
text46:'Para negociar ativos digitais, você precisa abrir uma conta pessoal. Para realizar negociações reais, definitivamente será necessário depositar um valor com base no preço da opção.',
text48:'Você tem {0} códigos de cupom',
text49:'Use este código de cupom para cancelar transações de perda',
text50:'Você só pode cancelar transações não cobertas, com valor não superior ao valor máximo do código de cupom.',
text51:'Estado',
text52:'Usar',
text53:'Você ainda não possui histórico de códigos de cupom. Você pode adicionar códigos de cupom usando o botão abaixo.',
text54:'Mostrar Histórico',
text55:'Inserir Código de Cupom',
text56:'Informações Pessoais',
text57:'Vazio',
text58:'Nome',
text59:'Sobrenome',
text60:'Data de Nascimento',
text61:'País',
text62:'Salvar',
text63:'Endereço',
text64:'Verificação de Documento',
text65:'Antes de verificar a conta, informações pessoais são necessárias.',
text66:'Segurança',
text67:'Verificação em Duas Etapas',
text68:'Alterar Senha',
text69:'Senha Antiga',
text70:'Nova Senha',
text71:'Digite Novamente a Nova Senha',
text72:'Encerrar Conta',
text73:'Verificação de Cartão de Crédito ou Débito',
text74:'Adicionar Novo Cartão Bancário',
text75:'Você não possui nenhum cartão de crédito ou débito para verificar',
text76:'Você ainda não possui histórico de transações.',
text77:'Início',
text78:'Fim',
text79:'Aplicar Filtro',
text80:'Anterior',
text81:'Próximo',
text82:'Lucro',
text83:'Cancelado',
text84:'Conta',
text85:'Taxa',
text86:'Você pode sacar fundos da conta da plataforma para o cartão bancário ou carteira eletrônica que você usou para depositar. Você pode solicitar um saque a qualquer momento. Seu pedido de saque será processado em até 3 dias úteis.',
text87:'Depósito',
text88:'Alguns Pedidos Anteriores',
text89:'Histórico de Saques',
text90:'Meus Pedidos',
text91:'Fazer um Pedido',
text92:'Perguntas Frequentes',
text93:'Você não possui nenhum pedido enviado',
text94:'Se tiver alguma dúvida, você pode encontrar respostas nas perguntas frequentes.',
text95:'Direitos Autorais © 2024 Quotex. Todos os direitos reservados.',
text96:'Login',
text97:'Senha',
text98:'Lembrar de Mim',
text99:'Retornar Informação',
text100:'Concluído',


text104:'Plataforma de Inovação para Investimento Inteligente',
   text105: 'Inscreva-se e ganhe US$ 10.000 em uma conta demo onde você aprende a negociar',
   text106:'Registrar',
   text107:'Abra uma conta demo',
   text108:'Interface de negociação conveniente',
   text109:'Criamos a interface mais simples e conveniente para que você não se desvie do que é mais importante - negociar. ',
   text110:'Sinal integrado',
   text111:'Considere uma estratégia de negociação, fornecemos os sinais mais precisos e inovadores de até 87% para ajudá-lo a desenvolver sua própria estratégia eficaz. ',
   text112:'Indicador de negociação',
   text113:'Coletamos os indicadores de negociação mais benéficos. Use este indicador para aumentar o saldo da sua conta. ',
   text114:'Velocidade perfeita',
   text115: 'Nossa plataforma usa a tecnologia mais inovadora e oferece uma velocidade tremenda. ',
   text116:'Experimente com uma conta demo',
   text117:'Para ganhar mais dinheiro,',
   text118:'Faça um prognóstico correto',
   text119:'O preço está em alta ou em baixa? ',
   text120:'Abra uma conta demo',
   text121:'Clique',
   text122:'Em um dos botões e veja os resultados',
   text123:'O preço vai subir',
   text124:'O preço vai cair',
   text125:'Aguardando resultados...',
   text126:'Seu lucro',
   text127:'Desculpe, sua previsão está incorreta',
   text128:'Tente novamente',
   text129:'Seu lucro',
   text130:'Parabéns! Sua previsão está correta',
   text131:'Comece a ganhar dinheiro',
   text132:'O aplicativo móvel está sempre disponível',
   text133: 'Baixe nosso aplicativo fácil de usar para iPhone ou Android e comece a negociar! ',
   text134:'Tempo disponível',
   text135:'Começar a negociar',
   text136:'3 etapas',
   text137:'Abra uma conta real gratuitamente em apenas alguns minutos',
   text138:'Negocie na conta demo com um clique',
   text139:'Prática',
   text140:'Melhore suas habilidades com contas de demonstração e materiais de treinamento',
   text141:'Comece seu ensino com uma conta demo',
   text142:'Deposite em sua conta real e comece a negociar',
   text143:'Mais de 410 instrumentos, o valor mínimo de transação é $10',
   text144:'Método de depósito saltado',
   text145:'Perguntas frequentes',
   text146:'Como dominar isso? ',
   text147:'Registre uma conta demo e pratique usando esta conta. Uma conta demo é igual a uma conta real, mas nenhum depósito é necessário. ',
   text148:'Quanto tempo leva para processar um saque? ',
   text149: 'Em média, o processo de retirada leva entre 1 e 5 dias para ser processado a partir da data de recebimento da solicitação do usuário correspondente, e depende apenas do número de solicitações processadas simultaneamente. A Empresa sempre se esforça para pagar a solicitação do Usuário diretamente na data em que a recebe. ',
   text150:'Qual é a plataforma de negociação? qual é o efeito? ',
   text151: 'A plataforma de negociação é um sistema de software. Os usuários utilizam os instrumentos financeiros fornecidos por este sistema para realizar transações (operações), e também utilizam o valor da cotação, a situação do mercado em tempo real, as ações da empresa e outras informações fornecidas pela empresa. ',
   text152:'Posso usar meu celular para realizar transações? ',
   text153:'Nossa plataforma utiliza o que há de mais inovador em tecnologia e pode ser aberta no navegador de qualquer computador ou smartphone',
   text154:'Qual é o valor mínimo do depósito? ',
   text155: 'A vantagem da plataforma de negociação desta empresa é que você não precisa depositar grandes quantias de dinheiro em sua conta. Você pode começar a negociar investindo uma pequena quantia de dinheiro. O depósito mínimo é de $ 10. ',
   text156:'Existe alguma comissão ao depositar ou retirar dinheiro da conta? ',
   text157:'Não. A empresa não cobra nenhuma comissão por operações como depósito em conta ou retirada de fundos. ',
   text158: 'No entanto, deve-se notar que os sistemas de pagamento podem cobrar comissões e usar taxas de câmbio diferenciadas. ',
   text159:'Vá para todas as perguntas',
   text160:'Quotex: Plataforma de Inovação',
   text161:'Negociação de ativos digitais',
   text162:'Abra uma conta real',
   text163:'Parceiro',
   text164:'Conta de demonstração',
   text165:'Informações de contato',
   text166:'Pergunta importante',
   text167:'Questões financeiras',
   text168:'verificação',
   text169:'Regulamentos',
   text170:'Política de Privacidade',
   text171:'Condições de Pagamento',
   text172:'Contrato de Serviço',
   text173:'Aviso de risco',
   text174:'Regras para operações de negociação',
   text175:'Regras operacionais não comerciais',
   text176:`Aviso de risco: A negociação em plataformas de negociação de ativos digitais e o uso de instrumentos de financiamento de empréstimos podem envolver riscos significativos e resultar na perda total do seu capital de investimento. Não invista mais do que você pode pagar e você deve compreender os riscos envolvidos. A negociação utilizando instrumentos de financiamento emprestados não é adequada para a maioria dos investidores. Negociar sem utilizar fundos emprestados, como ações, também é arriscado, pois os preços das ações podem diminuir e aumentar, o que pode significar que, mesmo que ganhe, receberá muito menos do que investiu.
   O sucesso passado não garante o sucesso futuro. Antes de negociar, preste atenção à sua experiência e nível de negociação, objetivos de investimento e entre em contato com um consultor financeiro independente, se necessário. Os usuários devem determinar se o uso dos serviços da marca Quotex é permitido com base nas leis e regulamentos do seu país de residência. `,
}