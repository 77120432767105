export default {
    "+{value}% profit": "+ %{value} kâr",
    "Selfie verification required": "Selfie doğrulaması gerekli",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "Gerekli hesap doğrulama adımlarını gerçekleştirmek için, kimlik belgenizi tutarken yüksek çözünürlüklü fotoğrafınızı (selfie) güncel bir tarih ve bir kağıt üzerine imzanızı iletmenizi rica ederiz. Yüzünüz, vücudunuz ve iki eliniz görünür olmalıdır <br><br> Belgenin detayları açık ve okunabilir olmalıdır.",
    "Your documents have been checked, now your identity is verified.": "Belgeleriniz kontrol edildi, kimliğiniz doğrulandı.",
    "You need fill identity information before verification your profile.": "Hesabınızı doğrulamadan önce kişisel bilgilerinizi girmeniz gerekir.",
    "Documents verification": "Belgelerin doğrulanması",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "Profil doğrulama, müşterinin resmi bir kimlik belgesinin sağlanması anlamına gelir. Bu prosedür, şirketin güvenlik departmanı tarafından herhangi bir zamanda başlatılabilir.",
    "Upload Photo": "Fotoğraf yükle",
    "Nickname": "Takma ad",
    "First Name": "İsim",
    "Last Name": "Soyisim",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "İsmi belgedekiyle tam olarak belirtmek gerekmektedir. Yanlış bilgi verildiği takdirde hesap bildirim yapılmadan silinebilir.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Soyadı belgedekiyle tam olarak belirtmek gerekmektedir. Yanlış bilgi verildiği takdirde hesap bildirim yapılmadan silinebilir.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "Doğum tarihinizi sonradan değiştiremezsiniz. Yanlış belirtirseniz, hesabınızdan para çekemezsiniz.",
    "Empty": "Boş",
    "Date of birth": "Doğum tarihi",
    "Unverified": "Doğrulanmadı",
    "Verified": "Doğrulandı",
    "Resend": "Yeniden gönder",
    "Country": "Ülke",
    "Address": "Adres",
    "Change Identity Info": "Bilgileri değiştir",
    "Identity Info": "Kişisel bilgi",
    "Two-factor authentication on login": "Girişte iki faktörlü kimlik doğrulama",
    "Two-factor authentication on withdrawal": "Para çekme sırasında iki faktörlü kimlik doğrulama",
    "Active": "Aktif",
    "Inactive": "Etkin değil",
    "Setup": "Kurulum",
    "Two-step verification": "İki adımlı doğrulama",
    "Security": "Emniyet",
    "Google Authenticator": "Google Kimlik Doğrulayıcı",
    "Scan the QR code": "QR kodunu tarayın",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Güvenliğinizi çok ciddiye alıyoruz, bu nedenle 2 adımlı doğrulamaya ihtiyacımız var. Bunu nasıl kuracağınız aşağıda açıklanmıştır:",
    "Install ": "Yüklemek",
    "Can't scan?": "Tarayamıyor musunuz?",
    "Click here.": "Buraya Tıkla.",
    "Enter this code into Google Authenticator.": "Bu kodu Google Authenticator'a girin.",
    "Back to QR code.": "QR koduna geri dönmek.",
    "Enter the 6-digit code from Authenticator below": "Authenticator'dan gelen 6 haneli kodu aşağıya girin",
    "Enter 6-digit code...": "6 haneli kodu girin...",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "Güzel iş. 2 adımlı doğrulamayı başarıyla etkinleştirdiniz. Bir sonraki oturum açışınızda sizden bir kod istenir.",
    "Code": "Kod",
    "contact support": "iletişim desteği",
    "If you ever lose access to your 2-step verification device, please ": "2 adımlı doğrulama cihazınıza erişiminizi kaybederseniz lütfen",
    "Yes, activate": "Evet, etkinleştir",
    "Cancel": "İptal et",
    "Close": "Kapat",
    "Language": "Dil",
    "Timezone": "Saat dilimi",
    "Delete Account": "Hesabı sil",
    "Verification Credit / Debit Cards": "Kredi veya banka kartlarının doğrulanması",
    "Add New Card": "Yeni Kart Ekle",
    "Wait for loading...": "Yüklenmesini bekleyin...",
    "You don’t have any credit / debit cards for verification": "Doğrulamak için kredi ve banka kartınız yok",
    "Verificate": "Doğrulama",
    "Verification Card": "Kart onayı",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "Boş bir A4 kağıda, pasaportunuzun ve banka kartınızın ön yüzünün (numara, kartın son kullanma tarihi ve adınızla birlikte) bir formasını yerleştirin.<br>\nTarihi kağıda elle ekleyin ve imzalayın<br>\nBir fotoğraf çekin ve bir fotoğraf yükleyin:",
    "Card:": "Harita:",
    "Type:": "Tür:",
    "Your ID and bank card on an A4 sheet": "Pasaport ve kart içeren A4 sayfa",
    "Yes, confirm": "Evet onayla",
    "Click or drag the image": "Tıklayın veya resmi sürükleyin",
    "Card": "Harita",
    "Documents": "Belgeler",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "Öncelikle Profilinizdeki “Kimlik Bilgisi” bölümünü doldurun ve değişiklikleri kaydetmek için “Kimlik Bilgilerini Değiştir” butonuna tıklayın. Ardından yeni bir kart ekleyebileceksiniz.",
    "Specify the card details for further operations for depositing and debiting funds.": "Para yatırma ve para çekme işlemleri için kart ayrıntılarını belirtin.",
    "Card number:": "Kart numarası:",
    "Expiry month:": "Son kullanma ayı:",
    "Expiry year:": "Son kullanma yılı:",
    "Cardholder name:": "Kart sahibi:",
    "Next": "Sıradaki",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "Lütfen kağıdın üzerine imzanızı ve güncel tarihinizi yazınız, belgenizi banka kartınızla birlikte yerleştiriniz, fotoğrafını çekip aşağıdaki forma yükleyiniz.",
    "Do you have a two-sided document?": "Çift taraflı belgeniz mi var?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "Arka tarafı ayrı bir dosya olarak yükleyin, aynı şekilde tarih ve imzasının olduğu bir sayfada.",
    "Submit only valid documents": "Yalnızca geçerli belgeleri gönderin",
    "Do not edit or crop photo": "Fotoğrafı düzenlemeyin veya kırpmayın",
    "Do not upload other people's documents": "Başkalarının belgelerini yüklemeyin",
    "Read all": "Hepsini oku",
    "Uploading files": "Dosya yükleme",
    "For single-sided documents, upload only the front side.": "Tek taraflı belgeler için yalnızca ön tarafı yükleyin.",
    "Back": "Geri",
    "Add a card": "Kart ekle",
    "File Requirements": "Dosya Gereksinimleri",
    "Scanned images are not accepted for card verification": "Taranan resimler kart doğrulaması için kabul edilmiyor",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "Yalnızca normal sivil pasaportunuz veya ulusal kimlik kartınız olan dosyaları kabul ediyoruz (her iki taraf da ayrı dosyalar olarak yüklenmelidir)",
    "Driving license CANNOT be accepted": "Ehliyet KABUL EDİLEMEZ",
    "External (foreign/international/travel) passport will not be accepted as well": "Harici (yabancı/uluslararası/seyahat) pasaportu da kabul edilmeyecektir.",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "Belgenin 2 yüzü varsa, tersini aynı kağıda, güncel tarih ve imzanız ile ayrı bir dosya olarak yüklemeniz gerekir.",
    "All information in the document and on the card should be clear and readable on the photo": "Belgedeki ve karttaki tüm bilgiler fotoğrafta net ve okunabilir olmalıdır.",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "Hem belgeniz hem de kartınız fotoğrafa tam oturmalı kenarları kırpılmamalı",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "Belgenin ve kart verilerinin okunmasını zorlaştırabilecek hiçbir parlama veya başka fotoğraf kusuru olmamalıdır.",
    "Expired documents and cards will be rejected automatically": "Süresi dolmuş belgeler ve kartlar otomatik olarak reddedilecektir",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "Kartınızda adınız yoksa, kart numaranızı ve adınızı gösteren banka ekstresinin ekran görüntüsünü yüklemeniz gerekir. Bazı durumlarda, aynı kağıda adınızı elle yazmanıza izin verilir.",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "Doğrulama için gerekli verileri içeren belgelerin öğelerinin gizlenmesine izin verilmez (isim, doğum tarihi, fotoğraf)",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "Kart numarasındaki 8 hanenin gizlenmesine izin verilir. İlk 4 ve son 4 hane açıkça görülebilmelidir",
    "CVV code should be hidden at all times, in every case": "CVV kodu her durumda, her zaman gizlenmelidir",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "Dosyalarınızın herhangi bir şekilde düzenlenmesi yasaktır. Yalnızca düzenlenmemiş fotoğraflar ve taranmış resimler kabul edilebilir. Belgelerinizdeki kişisel bilgilerin (isim, doğum tarihi vb.) düzenlenmesine izin verilmez.",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "Fotoğraf belgenin bir parçası olmalıdır (yapıştırılmış veya basılmış). Ayrı ayrı eklenen fotoğraflar kabul edilmeyecektir.",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "Yalnızca profilde belirtilen (hesabınızın kayıtlı olduğu) isimde belge ve kart yüklemesine izin verilir.",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "Yanlış/sahte bilgi verilmesi nedeniyle, koşullar netleşene kadar hesabın askıya alınabileceğini/bloke edilebileceğini hatırlatırız.",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "Şirket, hesabınızı doğrulamak için herhangi bir zamanda sizden ek belgeler isteme hakkını saklı tutar (belgeli özçekiminiz dahil).",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "Belge doğrulama ile ilgili tüm sorularınız için platformda bir destek bileti oluşturabilirsiniz (“Yardım” bölümü, en soldaki panelde yanıp sönen soru işareti simgesi olan ilk butondur)",
    "Profile": "Profil",
    "Deposit": "Depozito",
    "Withdrawal": "Para çekme ",
    "Transactions": "İşlemler",
    "Trades": "Anlaşmalar",
    "Market": "Market",
    "Analytics": "Analitik",
    "My current currency": "Mevcut para birimi",
    "Available for withdrawal": "Para çekme için uygun",
    "In the account": "Hesapta",
    "Percentage of turnover {percent}%": "Ciro yüzdesi {percent}%",
    "Cashback {percent}%": "Cashback {percent}%",
    "Currency:": "Para birimi:",
    "Change": "Değiştir",
    "Live Account": "Gerçek hesap",
    "Daily budget limit": "Günlük bütçe sınırı",
    "The value cannot exceed your total budget": "Tutar, toplam bütçenizi geçemez.",
    "Demo Account": "Demo hesap",
    "Apply": "Uygulamak",
    "Edit": "Düzenle",
    "New value": "Yeni değer",
    "Confirm": "Onayla",
    "Logout": "Çıkış",
    "No, cancel": "Hayır iptal",
    "Exchange Form": "Değişim formu",
    "My Currency:": "Para Birimim:",
    "New Currency:": "Yeni para birimi",
    "You are exchanging": "Değişebilirsin",
    "You will receive": "Alacaksınız",
    "Exchange Fee": "Komisyon",
    "Password changed": "Şifre değiştirildi",
    "Old password": "Eski şifre",
    "New password": "Yeni şifre",
    "Confirm new password": "Yeni şifreyi onayla",
    "Change Password": "Şifreyi değiştir",
    "Confirm password must be equal new password value": "Şifre onayı yeni şifre değeriyle eşleşmelidir",
    "About us": "Hakkımızda",
    "Support": "Destek",
    "Help": "Yardım",
    "2-step verification": "2 adımlı doğrulama",
    "Please enter the PIN-code we've just sent to your email": "Lütfen az önce e-postanıza gönderdiğimiz PIN kodunu girin",
    "Please enter the 6-digit code from your authentication app": "Lütfen kimlik doğrulama uygulamanızdan 6 haneli kodu girin",
    "The First Name field is required": "Ad alanı zorunludur",
    "The Last Name field is required": "Soyadı alanı zorunludur",
    "Verification of documents": "Belgelerin doğrulanması",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "Pasaportunuzun, sürücü belgenizin veya ulusal kimlik kartınızın renkli bir fotoğrafını veya taranmış görüntüsünü yükleyin.",
    "Upload Documents": "Belgeleri Yükle",
    "Why?": "Neden?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "Hesabınızın silinmesini onaylıyor musunuz? Bu eylem geri alınamaz.",
    "Set up 2-step verification": "2 adımlı doğrulamayı ayarlayın",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "Maalesef bu bölüme dosya yükleyemezsiniz. <br> Lütfen destek mesajlarını okuyun ve gerekli dosyaları bilete ekleyin.",
    "The uploaded document does not match the requirements": "Yüklenen belge gereksinimleri karşılamıyor",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "Aşağıdaki belgeler kimlik için uygundur: ID, uluslararası pasaport veya ehliyet.",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "Resimler yüksek kalitede olmalı, verilerin okunması kolay olmalı ve belgenin kenarları kırpılmamalıdır.",
    "Balance from {amount}": "{amount} tutarındaki bakiye",
    "Not verified": "Doğrulanmadı",
    "Clear": "Şeffaf",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "Hizmet kısıtlamalarından kaçınmak için doğrulama prosedürünü uygulayın: kişisel verilerinizi doldurun ve gerekli belgeleri yükleyin.",
    "Get verified": "Doğrulandı",
    "I got it!": "Anladım!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "AADHAAR Doğrulaması",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "Lütfen fiziksel Aadhaar'ınızın renkli bir fotoğrafını yükleyin (fotoğrafınız ve kimlik numaranız ile yalnızca ön taraf).",
    "Send AADHAAR": "AADHAAR'a gönder",
    "AADHAAR number:": "AADHAAR numarası:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "Lütfen fiziksel Aadhaar'ınızın renkli bir fotoğrafını yükleyin (fotoğrafınız ve kimlik numaranız ile yalnızca ön taraf). ☝ Elektronik versiyonlar kabul edilmemektedir.",
    "You have already uploaded {number} files. Please wait until they are checked.": "Zaten {number} dosya yüklediniz. Lütfen doğrulanana kadar bekleyin.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "Lütfen belgenizin renkli bir fotoğrafını yükleyin: RG için - her iki tarafın da ayrı dosyalar olarak yüklenmesi gerekir. CNH için - yalnızca fotoğrafınızın bulunduğu ön taraf. Belge geçerli olmalıdır (düzenlendiği tarihten itibaren 10 yıldan az) ve CPF numaranızı gösteriyor olmalıdır. * belgenizde CPF yoksa - lütfen Comprovante de Inscrição no CPF'nin ayrı bir fotoğrafını veya adınızı ve CPF numaranızı gösteren banka ekstrenizin ekran görüntüsünü yükleyin. ** Lütfen herhangi bir elektronik sürüm yüklemeyin. Kabul edilmeyecekler.",
    "Submit for verification": "Doğrulama için gönder",
    "Settings": "Ayarlar",
    "Please upload a color photograph of the front of your national identity card with your photo.": "Lütfen fotoğrafınızla birlikte nüfus cüzdanınızın ön yüzünün renkli bir fotoğrafını yükleyiniz.",
    "Template": "Platform teması",
    "Light Mode": "Gün",
    "Twilight": "Alacakaranlık",
    "Full Night": "Gece",
    "Grid's opacity": "Mesh şeffaflığı",
    "Auto-scrolling": "Otomatik kaydırma",
    "Automatic graphic scrolling": "Grafiğin otomatik kaydırılması",
    "1-click trade": "1 tıklama anlaşmaları",
    "Open trades without confirmation": "Onay olmadan anlaşmaları açın",
    "Use timezone": "Saat dilimini kullan",
    "Use your timezone data": "Saat dilimi verilerinizi kullanın",
    "Graph colors": "Grafik renkleri",
    "Up Trend": "Yükseliş",
    "Down Trend": "Düşüş trendi",
    "Platform": "Platform",
    "Short order label": "Kısa sipariş etiketi",
    "Use short order element mode": "Kısa sıralı öğe modunu kullan",
    "Perfomance Mode": "Performans Modu",
    "Use optimized rendering for chart and candles": "Grafikler ve mumlar için optimize edilmiş oluşturmayı kullanın",
    "Background": "Arka plan",
    "Choose file": "Dosya seçin",
    "Max size — 2 Mb": "Maksimum boyut - 2Mb",
    "Sign up": "Kayıt ol",
    "Sign in": "Giriş yap",
    "Budget and limits": "Bütçe ve limitler",
    "Daily limit": "Günlük sınır",
    "Not set": "Not seti",
    "Total funds": "Toplam fon",
    "Top up": "Tamamla",
    "Set up": "Ayarla",
    "Set a daily limit on trading to reduce financial risks.": "Finansal riskleri azaltmak için günlük alım satım limiti belirleyin.",
    "Limit amount": "Limit tutarı",
    "More": "Daha fazla detay",
    "Leader Board": "Sıralama",
    "Signals": "Sinyaller",
    "Notifications": "Bildirimler",
    "mark all as read": "hepsini okundu olarak işaretle",
    "No notifications": "Bildirim yok",
    "No more notifications": "Daha fazla bildirim yok",
    "Your position:": "Pozisyonunuz:",
    "How does this rating work?": "Skor sıralama tablosu nasıl çalışıyor?",
    "of the Day": "bugünün",
    "Nothing has been found for current query, try another asset name.": "İsteğiniz için hiçbir sonuç bulunamadı.",
    "My rating in the Leader Board": "Skor tablosundaki derecem",
    "Participants can only be those who trade in live mode": "Canlı hesapta işlem yapan katılımcı tüccarlar",
    "Your position depends on the amount of earned money": "Pozisyonunuz alınan kar miktarına bağlıdır",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "Her gün 00:00 UTC - 23:59 UTC arasında hesaplanır",
    "All traders on our platform participate in this rating. Main features of the rating:": "Platformumuzdaki tüm tüccarlar bu sıralamaya katılır. Skor tablonun temel özellikleri:",
    "How does it work?": "Nasıl çalışır?",
    "Trading signals": "Ticaret sinyalleri",
    "Past signals": "Geçmiş sinyaller",
    "What’s it?": "Bu nedir?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "Ticaret sinyali - ticaret için doğrudan bir talimat değil, bir analistin tavsiyesidir. Sinyal bağımsız pazar analizinin yerini almaz ve öngörülebilir bir sonucu garanti etmez.",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "Her sinyalin kendi zamanı vardır ve bu da önemlidir. Bu sürenin sonunda, teklifler değişebilir ve işlemin sonucunu beklenmedik bir şekilde etkileyebilir.",
    "Continue": "Devam et",
    "Duration": "Süresi",
    "Return back to FAQ": "SSS'ye geri dön",
    "Return back to Help": "Yardıma Geri Dön",
    "How to withdraw money from the account?": "Bir hesaptan nasıl para çekilir?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "Sermaye çekme prosedürü son derece basittir ve kişisel hesabınız üzerinden yapılır.",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "Hesabınızı yenilemek için seçtiğiniz yöntem aynı zamanda para çekme yöntemidir (\"Hesabımı nasıl yenileyebilirim?\" sorusuna bakın).",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "Örneğin, Visa ödeme sistemi üzerinden hesabınıza para yatırdıysanız, Visa ödeme sistemi aracılığıyla para çekeceksiniz.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "Yeterince büyük bir miktarın çekilmesi söz konusu olduğunda, Şirket doğrulama talep edebilir (kendi takdirine bağlı olarak doğrulama istenir.",
    "What is the minimum withdrawal amount?": "Minimum para çekme miktarı nedir?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "Çoğu ödeme sistemi için minimum para çekme miktarı 10 USD'den başlıyor.<br>Kripto para birimleri için bu miktar 50 USD'den başlar (ve belirli para birimleri için daha yüksek olabilir, ör. Bitcoin).",
    "Do I need to provide any documents to make a withdrawal?": "Para çekmek için herhangi bir belgeye ihtiyacım var mı?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "Genellikle, para çekmek için ek belgeler gerekli değildir. Ancak şirket kendi takdirine bağlı olarak, belirli belgeleri talep ederek kişisel verilerinizi onaylamanızı isteyebilir. Bu genellikle yasadışı ticaret, mali dolandırıcılık ve yasadışı yollarla elde edilen fonların kullanımıyla ilgili faaliyetleri önlemek için yapılır.",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "Bu tür belgelerin listesi asgari düzeydedir ve bunları sağlamak için yapılan işlem çok zamanınızı ve çabanızı almayacaktır.",
    "How long does it take to withdraw funds?": "Para çekmek ne kadar sürer?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "Para çekme prosedürü, ilgili müşterinin talebinin alındığı tarihten itibaren ortalama bir ila beş gün sürer ve yalnızca eşzamanlı olarak işlenen taleplerin hacmine bağlıdır. Şirket her zaman doğrudan müşteriden talebin alındığı gün ödeme yapmaya çalışır.",
    "Is there any fee for depositing or withdrawing funds from the account?": "Bir hesaptan para yatırırken veya çekerken komisyon var mı?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "Hayır. Şirket, bir hesaba para yatırmak veya para çekmek için bir komisyon talep etmez.",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "Ancak, ödeme sistemlerinin kendi ücretlerini alabileceği ve iç döviz kurunu kullanabileceği unutulmamalıdır.",
    "Can I close my account? How to do it?": "Hesabımı kapatabilir miyim? Nasıl yapılır?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "Profil sayfanızın altında bulunan \"Hesabı Sil\" düğmesine tıklayarak Kişisel Hesabınızdaki bir hesabı silebilirsiniz.",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "Yanlış veri girdim, nasıl düzeltebilirim?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "Destek servisine şirketin web sitesi üzerinden başvurmanız ve ardından profil verilerini düzenlemeniz gerekir.",
    "What data is required to register on the Company website?": " Web sitesine bir şirket kaydetmek için hangi veriler gereklidir?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "İkili opsiyonlardan para kazanmak için önce işlemlere izin veren bir hesap açmalısınız. Bunu yapmak için şirket web sitesine kayıt olmalısınız.",
    "The registration process is simple and does not take much time.": "Kayıt işlemi fazla zamanınızı almayacaktır.",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "Önerilen forma göre bir anket doldurulması gerekmektedir. Aşağıdaki bilgileri girmeniz gerekicek:",
    "name (in English)": "isim (rusça dilde);",
    "email address (indicate the current, work, address)": "e-posta adresi (geçerli adresi ekleyin);",
    "telephone (with a code, for example, + 44123 ....)": "telefon (ülke kodla, örneğin, +9053….);",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "Sisteme giriş yapmak için kullanacağınız bir parola (hesabınıza yetkisiz giriş yapma riskini en aza indirmek için, küçük harf, büyük harf ve rakam kullanarak karmaşık bir parola oluşturmanızı öneririz. Şifreyi kimseyle paylaşmayın).",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "Formu doldurduktan sonra, ticaret hesabınıza para yatırmanız için size çeşitli yollar sunulacak.",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "Hesap hangi para biriminde açıldı? Müşteri hesabının para birimini değiştirebilir miyim?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "Varsayılan olarak, ABD doları cinsinden bir ticaret hesabı açılır. Ancak size kolaylık sağlamak için farklı para birimlerinde birkaç hesap açabilirsiniz.",
    "A list of available currencies can be found on your profile page in your Client's account.": "Kullanılabilir para birimlerinin listesi, kişisel hesabınızdaki profil sayfanızda görüntülenebilir.",
    "What are digital options?": "Digital Trading seçenekleri nelerdir?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "Bir opsiyon, herhangi bir dayanak varlığa (hisse senedi, döviz çifti, petrol vb.) Dayanan bir türev finansal sözleşmedir (türev).",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "Digital opsiyon, bu tür varlıkların belirli bir süre boyunca fiyat hareketlerinden kar elde etmek için kullanılan standart olmayan bir seçenektir.",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "Bir digital opsiyon, işlemin tarafları arasında mutabık kalınan şartlara bağlı olarak, taraflarca belirtilen zamanda, sabit bir gelir (işlemden elde edilen gelir ile varlığın fiyatı arasındaki fark) veya zarar (tutar olarak) getirir.",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "Bir digital opsiyon önceden sabit bir fiyattan satın alındığından, kârın büyüklüğü ve potansiyel zararın boyutu işlem yapılmadan önce bile bilinir.",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "Bu fırsatların bir diğer özelliği de zaman sınırıdır. Herhangi bir seçeneğin kendi süresi vardır (sona erme veya egzersiz süresi).",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "Dayanak varlığın fiyatındaki değişim derecesine bakılmaksızın (ne kadar yüksek veya daha düşük hale geldiği), opsiyonun kazanılması durumunda her zaman sabit bir ödeme yapılır. Bu nedenle riskleriniz sadece opsiyonun satın alındığı miktar ile sınırlıdır.",
    "What is the expiration period of a trade?": "Bir ticaretin vade süresi nedir?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "Son kullanma süresi, işlemin tamamlanmış (kapatılmış) olarak kabul edileceği ve sonucun otomatik olarak toplanacağı süredir.",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "Digital opsiyonlarla bir işlem gerçekleştirirken, işlemin gerçekleştirilme zamanını bağımsız olarak belirlersiniz (1 dakika, 2 saat, ay vb.).",
    "What is the gist of digital options trading?": "Digital Trading özü nedir?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "Gerçek şu ki, Digital seçenek en basit finansal türev türüdür. Digital opsiyon pazarında para kazanmak için ulaşabileceği bir varlığın piyasa fiyatının değerini tahmin etmenize gerek yok.",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "Alım satım sürecinin ilkesi, yalnızca tek bir sorunu çözmeye indirgenmiştir - bir varlığın fiyatı, sözleşme yapıldığında yükselecek veya düşecektir.",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "Bu tür opsiyonların özelliği, işlemin sona erdiği andan kapanışına kadar dayanak varlığın fiyatının yüz puan mı yoksa sadece bir puan mı geçeceği sizin için kesinlikle önemsiz olmasıdır. Bu fiyatın sadece hareket yönünü belirlemeniz sizin için önemlidir.",
    "If your prognosis is correct, in any case you get a fixed income.": "Tahmininiz doğru çıkarsa, yine de sabit bir gelir elde edeceksiniz.",
    "What are the possible results of the placed trades?": "İşlemin sonucu için seçenekler nelerdir?",
    "There are three possible outcomes in the digital options market:": "Digital opsiyon pazarında olayların geliştirilmesi için üç seçenek vardır:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "Dayanak varlık fiyatının hareket yönünü belirleme tahmininiz doğru çıkarsa, gelir alırsınız.",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "Opsiyon uygulandığında tahmininizin yanlış olduğu ortaya çıkarsa, varlık değerinin boyutuyla sınırlı bir zarara maruz kalırsanız (yani aslında yalnızca yatırımınızı kaybedebilirsiniz);",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "İşlemin sonucunun sıfır olduğu ortaya çıkarsa (dayanak varlığın fiyatı değişmediyse, opsiyon satın alındığı fiyattan kullanılmışsa), yatırımınızı iade edersiniz.",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "Kendi paranızı harcamadan Digital Seçenekler ile çalışma sürecini anlamak için alım satım platformunuzun bir demo hesabı var mı?",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "Evet. Ticaret becerilerinde ustalaşmak ve Şirketin ticaret platformunun yeteneklerini test etmek için bir demo hesabı (ücretsiz hesap) kullanabilirsiniz. Bu, önce denemenizi ve ancak daha sonra gerçek ticarete geçmenizi sağlayan bir tür simülatördür. Bu demo hesabı, deneyimli tüccarların profesyonel seviyelerini geliştirmeleri için de uygundur.",
    "The balance of such an account is 10,000 units.": "Böyle bir hesabın bakiyesi 10.000 birimdir.",
    "What determines profit size?": "Kârın boyutunu ne belirler?",
    "There are several factors that affect the size of your profit:": "Karınızın boyutunu etkileyen birkaç faktör var:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "Piyasada seçtiğiniz varlığın likiditesi (piyasada varlık ne kadar talep edilirse, o kadar çok kar elde edersiniz)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "işlemin zamanı (bir varlığın sabah likiditesi ve öğleden sonra bir varlığın likiditesi önemli ölçüde farklılık gösterebilir)",
    "tariffs of a brokerage company": "aracı kurum oranları",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "piyasadaki değişiklikler (ekonomik olaylar, belirli bir finansal varlıktaki değişiklikler vb.)",
    "What are the varieties of digital options?": "Digital seçenek türleri nelerdir?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "Bir opsiyon işlemi yaparken, opsiyonun temelini oluşturacak dayanak varlığı seçmeniz gerekir. Bu varlık için tahmininiz gerçekleştirilecek.",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "Basitçe söylemek gerekirse, dijital bir sözleşme satın aldığınızda, aslında böyle bir temel varlığın fiyat hareketine bahis oynuyorsunuz.",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "Dayanak varlık, bir anlaşma yapılırken fiyatı dikkate alınan bir \"kalem\" dir. Piyasalardaki en popüler ürünler genellikle dijital opsiyonların temelindeki varlık olarak hareket eder. Dört tür vardır:",
    "securities (shares of world companies)": "menkul kıymetler (dünya şirketlerinin hisseleri)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "döviz çiftleri (EUR / USD, GBP / USD, vb.)",
    "raw materials and precious metals (oil, gold, etc.)": "hammaddeler ve değerli metaller (petrol, altın vb.)",
    "indices (S&P 500, Dow, dollar index, etc.)": "endeksler (S&P 500, Dow, dolar endeksi vb.)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "Mükemmel bir dayanak varlık yoktur. Kendi bilginize ve sezginize ve çeşitli analitik verilere dayalı bir varlık seçmeniz gerekir.",
    "What is a trading platform and why is it needed?": "Ticaret platformu nedir ve ne içindir?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "Alım satım platformu - müşterinin farklı finansal araçlar kullanarak alım satım (işlemler) gerçekleştirmesine olanak tanıyan bir yazılım kompleksi. Ayrıca, tekliflerin değeri, gerçek zamanlı piyasa pozisyonları, şirketin eylemleri gibi çeşitli bilgilere erişebilir.",
    "How to learn quickly how to make money in the digital options market?": "Digital opsiyon piyasasında nasıl para kazanılacağını hızlıca nasıl öğrenebilirim?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "Doğru tahmin edilen işlem sayısının maksimum olacağı kendi ticaret stratejilerinizi geliştirin ve bunları takip edin",
    "diversify your risks": "risklerinizi çeşitlendirin",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "Pazar izleme, çeşitli kaynaklardan (İnternet kaynakları, uzman görüşleri, bu alandaki analistler vb.) Elde edilebilecek analitik ve istatistiksel bilgilerin incelenmesi, strateji geliştirmenin yanı sıra bunlardan biri olan çeşitlendirme seçeneklerini araştırmanıza yardımcı olacaktır. Bir web sitesi şirketler.",
    "Is the download of the program to a computer or smartphone required?": "Programı bilgisayarıma veya akıllı telefonuma indirmem gerekiyor mu?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "Gerek yoktur. Şirketin web sitesinde belirtilen forma kaydolmanız ve kişisel bir Hesap açmanız yeterlidir.",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "Başarılı bir işlem durumunda Şirket, müşteriye karı hangi fonla öder?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "Şirket müşteriler ile birlikte para kazanıyor. Bu nedenle, şirketin müşteri tarafından başarılı bir şekilde seçilmiş bir ticaret stratejisine sahip bir ödeme yüzdesine sahip olması nedeniyle, kârlı olmayanların payına önemli ölçüde hakim olan karlı işlemlerin payıyla ilgilenmektedir.",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "Buna ek olarak, müşteri tarafından gerçekleştirilen işlemler, birlikte bir broker veya borsaya devredilen şirketin işlem hacmini oluşturur ve bunlar da likidite sağlayıcıları havuzuna dahil edilir ve bu da birlikte piyasanın likiditesinin artmasına neden olur.",
    "What is account verification?": "Hesap doğrulaması nedir?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "Hesap doğrulaması, Müşteri tarafından Şirkete ek belgeler sağlayarak kişisel verilerinin onaylanmasıdır.",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "Müşterinin pasaportunun ilk yayılmasının renkli taranmış bir kopyasını sağlayın (fotoğraflı pasaport sayfası)",
    "identify with the help of a \"selfie\" (photograph of himself)": "\"Selfie\" yardımı ile tespit (kendini fotoğrafını)",
    "confirm the address of registration (residence) of the Client, etc": "Müşterinin kayıt adresini (ikametgah) teyit edin vs.",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "Şirket,  müşterinin ve kendisi tarafından girilen verilerin tam olarak tanımlanmasının mümkün olmadığı durumlarda herhangi bir belge talep edebilir.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "Belgelerin elektronik kopyalarını Şirkete aktardıktan sonra, müşterinin sağlanan verileri doğrulamak için bir süre beklemesi gerekecektir.",
    "How to understand that I need to go through account verification?": "Hesap doğrulamasından geçmem gerektiğini nasıl anlayabilirim?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "Doğrulamadan geçmeniz gerekirse, e-posta ve/veya SMS bildirimi ile bir bildirim alacaksınız.",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "Şirketin kayıt sırasında verdiğiniz belirli verileri (özellikle e-posta adresi ve telefon numarası) kullandığı unutulmamalıdır, bu nedenle doğru ve güncel veriler sağlamak son derece önemlidir.",
    "How do I know that I successfully passed verification?": "Başarıyla doğruladığımı nasıl öğrenebilirim?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "Hesabınızın doğrulama işleminin tamamlanması ve Şirketin işlem platformunda işlem yapmaya başlama yeteneği hakkında bir e-posta ve/ veya SMS bildirimi alacaksınız.",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "Siteye kayıt olurken başka birinin (sahte) verilerini belirtebilir miyim?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "Hayır. Müşteri, şirketin web sitesinde kendi kendine kayıt işlemini gerçekleştirir, kayıt formunda sorulan sorular hakkında kendisi hakkında eksiksiz ve güvenilir bilgi sağlar ve bu bilgileri güncel tutar.",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "Müşteri kimliğini doğrulamak gerekirse, firma ilgili belgeleri talep etme veya müşteriyi ofise davet etme hakkına sahiptir.",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "Kayıt formu alanına girilen bilgiler, gönderilen belgeler, belirtilen verilere uymuyorsa, hesap bloke edilebilir.",
    "How long does the verification process take?": "Doğrulama işlemi ne kadar sürüyor?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "Talep edilen belgelerin şirket tarafından teslim alındığı tarihten itibaren en fazla 5 (beş) iş günü.",
    "Is there a minimum amount that I can deposit to my account at registration?": "Kayıt sırasında hesabıma aktarabileceğim minimum bir miktar var mı?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "Şirketin ticaret platformunun avantajı, hesabınıza büyük miktarlar yatırmanız gerekmemesidir. Az miktarda para yatırarak ticarete başlayabilirsiniz. Minimum depozito 10 USD'dir.",
    "How can I deposit?": "Hesabıma nasıl para yatırabilirim?",
    "It is very easy to do. The procedure will take a couple of minutes.": "Bunu yapmak çok kolay. İşlem birkaç dakika sürecektir.",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "İşlem yürütme penceresini açın ve sekmenin sağ üst köşesindeki yeşil \"Para Yatırma\" düğmesine tıklayın.",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "Ayrıca, hesap profilinizdeki \"Yükleme\" düğmesine tıklayarak kişisel hesabınız üzerinden de hesabınıza para yatırabilirsiniz.",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "Bundan sonra, hesabı yenileme yöntemini seçmeniz gerekir (şirket, müşteriye sunulan ve kişisel hesabında görüntülenen birçok uygun yöntem sunar).",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "Ardından, hesabın yenileneceği para birimini ve buna göre hesabın para birimini belirtin.",
    "Enter the amount of the deposit.": "Para yatırma tutarını girin.",
    "Fill out the form by entering the requested payment details.": "İstenen fatura bilgilerini girerek sağlanan formu doldurun.",
    "Make a payment.": "Bir deme yapmak.",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "İşlem platformunda hesabıma para yatırmam gerekiyor mu ve bunu ne sıklıkla yapmam gerekiyor?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "Digital Trading seçenekleriyle çalışmak için kişisel bir hesap açmanız gerekir. Gerçek anlaşmaları sonuçlandırmak için, satın alınan seçeneklerin miktarı kesinlikle yenilemeniz gerekecek.",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "Yalnızca şirketin demo hesabını (demo hesabı) kullanarak para olmadan işlem yapmaya başlayabilirsiniz. Böyle bir hesap ücretsizdir ve ticaret platformunun işleyişini göstermek için oluşturulur. Böyle bir hesabı kullanarak, dijital seçenekleri satın alma alıştırması yapabilir, ticaretin temel ilkelerini anlayabilir, çeşitli teknikleri ve stratejileri test edebilir veya sezgilerinizin seviyesini değerlendirebilirsiniz.",
    "What is the minimum deposit amount?": "Minimum depozito miktarı nedir?",
    "{number} questions": "{number} soru",
    "Verification": "Doğrulama",
    "Payment": "İkmal",
    "Payouts": "Ödemeler",
    "Ask a specialist": "Bir uzmana soru sorun",
    "FAQ": "SSS",
    "Didn't find an answer to your question?": "Sorunuza bir cevap bulamadınız mı?",
    "Open knowledge base": "Belgeleri aç",
    "Contact support": "Destek hizmetine ulaşın",
    "Open FAQ page": "SSS sayfasını açın",
    "New message": "Yeni Mesaj",
    "Tournaments": "Turnuvalar",
    "To end": "Bitirmek",
    "Until start": "Başlamadan önce",
    "Front side": "Ön taraf",
    "Back side (if any)": "Arka taraf (varsa)",
    "Tourna-ments": "Turnuvalar",
    "Deletion of Account and Personal Data": "Hesabın ve Kişisel Verilerin Silinmesi",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "Hesabınızı ve kişisel verilerinizi silerek, Quotex platformundaki hesabınıza erişiminizi kalıcı olarak kaybedersiniz. Verilerinizin kalıcı olarak silineceğini ve hesabınızı daha sonra geri yüklemenizin imkansız olacağını unutmayın!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "NOT: Lütfen hesabınızı silmeden önce tüm açık işlemleri ve bekleyen emirleri tamamlayın.",
    "Request Deletion": "Silme Talebi",
    "To delete your account, please contact support service": "Hesabınızı silmek için lütfen destek servisiyle iletişime geçin",
    "Two-factor authorization via Email": "E-posta aracılığıyla iki adımlı  doğrulama ",
    "This feature is for experienced users who are familiar with Google Authenticator.": "Bu özellik, Google Authenticator'i kullanan deneyimli kullanıcılar için tasarlanmıştır.",
    "Please note!": "Lütfen dikkat edin!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "Bağladıktan sonra herhangi bir nedenle Google Authenticator'a erişiminizi kaybederseniz, hesabınıza erişiminizi kaybedersiniz.",
    "Proceed to setup": "Kuruluma başla",
    "Account protection method": "Koruma yöntemi",
    "Here's how to set it up:": "Nasıl etkinleştirilir:",
    "Please enter the PIN-code we've just sent to your email:": "Lütfen E-posta adresinize gönderdiğimiz PIN kodunu girin:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "Mükemmel! Google Authenticator aracılığıyla iki adımlı doğrulamayı başarıyla etkinleştirdiniz. ",
    "If you ever lose access to your 2-step verification device, please": "Eğer iki adımlı doğrulama için kullandığınız cihaza erişiminizi kaybederseniz, lütfen",
    "Confirm account deletion": "Hesap silme işlemini onaylayın",
    "To delete your account, follow the link in the email sent to": "Hesabınızı silmek için, adresine gönderilen e-postadaki bağlantıyı takip edin.",
    "Image requirements": "Resim Gereksinimleri",
    "Email": "E-posta ",
    "Сonfirmation": "Onayla",
    "Change protection method": "Koruma yöntemini değiştir",
    "First, enter the code from the Google Authenticator app:": "Önce, Google Authenticator uygulamasından kodu girin:",
    "First, enter the PIN-code we've just sent to your email:": "Önce e-postanıza az önce gönderdiğimiz PIN kodunu girin:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "Mükemmel. 2 Adımlı Doğrulamayı e-posta yoluyla başarıyla etkinleştirdiniz.",
    "Receiving codes via Email": "Kodları e-postayla alma",
    "Receiving codes via Google Authenticator": "Kodları Google Authenticator aracılığıyla alma",
    "Choose a method:": "Bir yöntem seçin:",
    "To enter the platform": "Platforma giriş yapmak için",
    "To withdraw funds": "Para çekmek için",
    "day": "gün",
    "days2": "gün",
    "days5": "gün",

text:'Para Birimi Seçimi',
text1:'E-posta',
text2:'İptal İşlemi',
text3:'Ücretsiz İndirim Kodu',
text4:'Bu platformu her gün daha kullanışlı hale getiriyoruz.',
text5:'Bu platformu beğendiniz mi? Lütfen başkalarıyla paylaşın!',
text6:'QUOTEX hakkındaki yorumlarınızı bırakın, ücretsiz işlem iptali ödülü kazanın.',
text7:'Yorum bağlantınızı destek ekibimize gönderin, incelendikten sonra ödülünüz size gönderilecektir.',
text8:'Minimum Para Yatırma Tutarı',
text9:'Minimum Para Çekme Tutarı',
text10:'Hızlı Para Çekme',
text11:'Ücretsiz',
text12:'Doğrulanmamış',
text13:'Yeniden Gönder',
text14:'Katılımınızı Onaylayın',
text15:'İşlemi İptal Et',
text16:'Lütfen indirim kodunuzu hemen girin ve etkinleştirin. İndirim kodunu istediğiniz zaman kullanabilirsiniz.',
text17:'İndirim Kodu:',
text18:'Yatırım Bakiyesine Göre %30 Bonus Alın',
text19:'Geçerli Para Birimi',
text20:'Çekilebilir Bakiye',
text21:'Hesapta',
text22:'Bugün',
text23:'Dün',
text24:'Hafta',
text25:'Ay',
text26:'Net İşlem Hacmi',
text27:'WELCOME, ilk para yatırma işlemine %30 bonus sunar.',
text28:`İlk para yatırma tutarı 100 $'dır. %30 bonus almak için bir para yatırma yöntemi seçmelisiniz.`,
text29:'Banka Kartı',
text30:'Banka',
text31:'Seçilen Ödeme Yöntemi:',
text32:'Minimum Tutar:',
text33:'Maksimum Tutar:',
text34:'Başka Bir Yöntem Seçin',
text35:'Ödeme Bilgileri',
text36:'Tutar',
text37:'Bonus Kullan',
text38:'Şartlar ve Koşullar',
text39:'İndirim Kodum Var',
text40:'İndirim Kodu',
text41:'İndirim Kodunu Uygula',
text42:'Girdiğiniz indirim kodu doğru. %30 bonus alacaksınız.',
text43:'%30 bonus alacaksınız',
text44:'%:',
text45:'Tüm Sıkça Sorulan Soruları Okuyun Lütfen',
text46:'Dijital varlıkların ticareti için kişisel bir hesap açmanız gerekmektedir. Gerçek işlemler yapmak için bir opsiyonun fiyatına göre bir miktar para yatırmanız gerekecektir.',
text48:'{0} adet indirim kodunuz var',
text49:'Bu indirim kodunu kayıplı işlemleri iptal etmek için kullanabilirsiniz.',
text50:'Sadece korunmasız işlemleri ve indirim kodunun maksimum değerini aşmayan miktarı iptal edebilirsiniz.',
text51:'Durum',
text52:'Kullan',
text53:'Henüz indirim kodu geçmişiniz yok. Aşağıdaki düğmeyi kullanarak indirim kodları ekleyebilirsiniz.',
text54:'Geçmişi Göster',
text55:'İndirim Kodunu Gir',
text56:'Gizlilik Bilgileri',
text57:'Boş',
text58:'Ad',
text59:'Soyad',
text60:'Doğum Tarihi',
text61:'Ülke',
text62:'Kaydet',
text63:'Adres',
text64:'Belge Doğrulama',
text65:'Hesabı doğrulamadan önce kişisel bilgiler girmeniz gerekmektedir.',
text66:'Güvenlik',
text67:'İki Adımlı Doğrulama',
text68:'Şifre Değiştirme',
text69:'Eski Şifre',
text70:'Yeni Şifre',
text71:'Yeni Şifreyi Tekrar Girin',
text72:'Hesabı Kapat',
text73:'Kredi Kartı veya Banka Kartı Doğrulaması Yapma',
text74:'Yeni Bir Banka Kartı Ekleyin',
text75:'Doğrulanacak kredi kartı veya banka kartınız bulunmamaktadır',
text76:'Henüz işlem geçmişiniz yok.',
text77:'Başlangıç',
text78:'Bitiş',
text79:'Filtre Uygula',
text80:'Önceki',
text81:'Sonraki',
text82:'Kar',
text83:'İptal Edildi',
text84:'Hesap',
text85:'Ücret',
text86:'Platform hesabınızdan para çekme işlemi yapabilirsiniz. Çekim isteğinizi her zaman yapabilirsiniz. Çekim talebiniz 3 iş günü içinde işleme konacaktır.',
text87:'Yatırma',
text88:'Bazı Önceki Talepler',
text89:'Çekim Geçmişi',
text90:'Taleplerim',
text91:'Talep Oluştur',
text92:'Sıkça Sorulan Sorular',
text93:'Henüz gönderilen bir talebiniz yok',
text94:'Herhangi bir sorunuz varsa, cevapları sıkça sorulan sorular bölümünde bulabilirsiniz.',
text95:'Telif Hakkı © 2024 Quotex. Tüm hakları saklıdır.',
text96:'Giriş Yap',
text97:'Şifre',
text98:'Beni Hatırla',
text99:'Bilgiyi Geri Getir',
text100:'Tamamlandı',

text104:'Akıllı Yatırım için İnovasyon Platformu',
   text105: 'Kaydolun ve ticaret yapmayı öğreneceğiniz bir demo hesaba 10.000$ kazanın',
   text106:'Kaydol',
   text107:'Demo hesabı aç',
   text108:'Kullanışlı ticaret arayüzü',
   text109:'En basit ve en kullanışlı arayüzü oluşturduk, böylece en önemli şeyden, yani ticaretten sapmazsınız. ',
   text110:'Yerleşik sinyal',
   text111:`Bir ticaret stratejisi düşünün, kendi etkili stratejinizi geliştirmenize yardımcı olmak için %87'ye kadar en doğru ve yenilikçi sinyalleri sağlıyoruz. `,
   text112:'Ticaret göstergesi',
   text113:'En faydalı işlem göstergelerini topladık. Hesap bakiyenizi artırmak için bu göstergeyi kullanın. ',
   text114:'Mükemmel hız',
   text115: 'Platformumuz en yenilikçi teknolojiyi kullanıyor ve muazzam bir hız sunuyor. ',
   text116:'Demo hesabıyla deneyin',
   text117:'Daha fazla para kazanmak için',
   text118:'Doğru bir tahminde bulunun',
   text119:'Fiyat yükseliş mi yoksa düşüş eğilimi mi? ',
   text120:'Demo hesabı aç',
   text121:'Tıklayın',
   text122:'Düğmelerden birine basın ve sonuçları görün',
   text123:'Fiyat artacak',
   text124:'Fiyat düşecek',
   text125:'Sonuçlar bekleniyor...',
   text126:'Kârınız',
   text127:'Üzgünüz, tahmininiz yanlış',
   text128:'Tekrar dene',
   text129:'Kârınız',
   text130:'Tebrikler! Tahmininiz doğrudur',
   text131:'Para kazanmaya başla',
   text132:'Mobil uygulama her zaman kullanılabilir',
   text133: 'iPhone veya Android için kullanıcı dostu uygulamamızı indirin ve ticarete başlayın! ',
   text134:'Müsait zaman',
   text135:'İşlem yapmaya başla',
   text136:'3 adım',
   text137:'Sadece birkaç dakika içinde ücretsiz olarak gerçek bir hesap açın',
   text138:'Demo hesabında tek tıklamayla işlem yapın',
   text139:'Alıştırma yapın',
   text140:'Demo hesapları ve eğitim materyalleriyle becerilerinizi geliştirin',
   text141:'Öğretmenize bir demo hesabıyla başlayın',
   text142:'Gerçek hesabınıza para yatırın ve ticarete başlayın',
   text143:`410'un üzerinde enstrüman, minimum işlem tutarı 10$'dır`,
   text144:'Atlamalı para yatırma yöntemi',
   text145:'SSS',
   text146:'Nasıl ustalaşılır? ',
   text147:'Bir demo hesabı kaydedin ve bu hesabı kullanarak pratik yapın. Demo hesap gerçek hesapla aynıdır ancak para yatırmanıza gerek yoktur. ',
   text148:'Para çekme işleminin işlenmesi ne kadar sürer? ',
   text149: 'Ortalama olarak, geri çekme işleminin, ilgili kullanıcı isteğinin alındığı tarihten itibaren 1 ila 5 gün sürmesi gerekir ve yalnızca aynı anda işlenen isteklerin sayısına bağlıdır. Şirket her zaman Kullanıcının talebini aldığı tarihte doğrudan ödemeye çalışır. ',
   text150:'Ticaret platformu nedir? etkisi nedir? ',
   text151: 'Ticaret platformu bir yazılım sistemidir. Kullanıcılar bu sistem tarafından sağlanan finansal araçları işlemleri (operasyonları) gerçekleştirmek için kullanırlar ve ayrıca şirket tarafından sağlanan kotasyon değerini, gerçek zamanlı piyasa durumunu, şirket eylemlerini ve diğer bilgileri kullanırlar. ',
   text152:'Cep telefonumu işlem yapmak için kullanabilir miyim? ',
   text153:'Platformumuz en yenilikçi teknolojiyi kullanmaktadır ve herhangi bir bilgisayar veya akıllı telefonun tarayıcısında açılabilir',
   text154:'Minimum para yatırma tutarı nedir? ',
   text155: `Bu şirketin ticaret platformunun avantajı, hesabınıza büyük miktarlarda para yatırmanıza gerek olmamasıdır. Az miktarda para yatırarak işlem yapmaya başlayabilirsiniz. Minimum depozito 10$'dır. `,
   text156:`Hesaba para yatırırken veya çekerken herhangi bir komisyon var mı?`,
   text157:'Hayır. Hesap yatırma, para çekme gibi işlemler için şirket herhangi bir komisyon talep etmemektedir. ',
   text158: 'Ancak, ödeme sistemlerinin komisyon alabileceğini ve farklı döviz kurlarını kullanabileceğini unutmamak gerekir. ',
   text159:'Tüm sorulara git',
   text160:'Quotex: İnovasyon Platformu',
   text161:'Dijital varlık ticareti',
   text162:'Gerçek bir hesap aç',
   text163:'Ortak',
   text164:'Demo hesabı',
   text165:'İletişim bilgileri',
   text166:'Önemli soru',
   text167:'Finansal sorunlar',
   text168:'doğrulama',
   text169:'Yönetmelikler',
   text170:'Gizlilik Politikası',
   text171:'Ödeme Koşulları',
   text172:'Hizmet Sözleşmesi',
   text173:'Risk Uyarısı',
   text174:'Ticaret işlem kuralları',
   text175:'Ticaret dışı işletim kuralları',
   text176:`Risk Uyarısı: Dijital varlık alım satım platformlarında işlem yapmak ve borç verme fonlama araçlarını kullanmak önemli riskler içerebilir ve yatırım sermayenizin tamamen kaybolmasıyla sonuçlanabilir. Ödeyebileceğinizden daha fazla yatırım yapmayın ve ilgili riskleri anlamalısınız. Ödünç alınan finansman araçlarını kullanarak işlem yapmak çoğu yatırımcı için uygun değildir. Hisse senedi gibi ödünç alınan fonları kullanmadan işlem yapmak da risklidir çünkü hisse senedi fiyatları hem düşebilir hem de artabilir; bu, kazansanız bile, yatırdığınızdan çok daha azını geri alacağınız anlamına gelebilir.
   Geçmişteki başarı gelecekteki başarıyı garanti etmez. İşlem yapmadan önce lütfen işlem deneyiminize ve seviyenize, yatırım hedeflerinize dikkat edin ve gerekirse bağımsız bir mali danışmanla iletişime geçin. Kullanıcılar, ikamet ettikleri ülkenin yasa ve düzenlemelerine göre Quotex markalı hizmetlerin kullanımına izin verilip verilmediğini belirlemelidir. `,
}