<template>
  <div class="panel-trading-signals">
    <div class="panel-trading-signals__header-container">
      <div class="panel-trading-signals__button-back" @click="$parent.showTransaction">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.47577 1.20379C7.73041 0.928767 7.73041 0.492463 7.47577 0.217438C7.20345 -0.0766788 6.7501 -0.0708499 6.49219 0.225389L2.52439 4.51083C2.40025 4.64491 2.33342 4.82538 2.33342 5.00401C2.33342 5.18867 2.39927 5.36204 2.52439 5.49718L6.49219 9.78262C6.76054 10.0725 7.20741 10.0725 7.47577 9.78262C7.73041 9.5076 7.73041 9.07129 7.47577 8.79627L3.95747 4.99631L7.47577 1.20379Z" fill="white"></path></svg>
      </div>
      <div class="panel-trading-signals__header"><div class="panel-trading-signals__header-title">{{ $t('Trading signals') }}</div></div>
    </div>
    <div class="panel-trading-signals__header-block"><div @click="whatShow = !whatShow" class="panel-trading-signals__header-what">{{ $t(`What’s it?`) }}</div></div>
    <div class="panel-trading-signals__list">
      <div class="panel-trading-signals__what" v-show="whatShow">
        <div class="panel-trading-signals__continue-text">{{ $t(`A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.`) }}</div>
        <div>{{ $t(`You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.`) }}</div>
        <button  @click="whatShow = !whatShow" class="button button--primary panel-trading-signals__continue-button">{{ $t('Continue') }}</button>
      </div>
      <div :class="[whatShow ? 'panel-trading-signals__blur':'']">
        <div class="panel-trading-signals__item --animate">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-eur"><use xlink:href="../../../assets/svg/flags.svg#flag-eur"></use></svg><svg class="flag-aud"><use xlink:href="../../../assets/svg/flags.svg#flag-aud"></use></svg>
              </div>
              EUR/AUD (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 05:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:35</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-aud"><use xlink:href="../../../assets/svg/flags.svg#flag-aud"></use></svg><svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg>
              </div>
              AUD/USD (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 45:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:35</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg><svg class="flag-ngn"><use xlink:href="../../../assets/svg/flags.svg#flag-ngn"></use></svg>
              </div>
              USD/NGN (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 02:00:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-mcd"><use xlink:href="../../../assets/svg/flags.svg#flag-mcd"></use></svg><svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg>
              </div>
              McDonald's (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 03:00:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-uscrude"><use xlink:href="../../../assets/svg/flags.svg#flag-uscrude"></use></svg><svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg>
              </div>
              USCrude (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 30:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-aud"><use xlink:href="../../../assets/svg/flags.svg#flag-aud"></use></svg><svg class="flag-jpy"><use xlink:href="../../../assets/svg/flags.svg#flag-jpy"></use></svg>
              </div>
              AUD/JPY (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 30:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-nzd"><use xlink:href="../../../assets/svg/flags.svg#flag-nzd"></use></svg><svg class="flag-jpy"><use xlink:href="../../../assets/svg/flags.svg#flag-jpy"></use></svg>
              </div>
              NZD/JPY (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 02:00:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-gbp"><use xlink:href="../../../assets/svg/flags.svg#flag-gbp"></use></svg><svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg>
              </div>
              GBP/USD (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 45:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-btc"><use xlink:href="../../../assets/svg/flags.svg#flag-btc"></use></svg><svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg>
              </div>
              Bitcoin (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 45:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg><svg class="flag-pkr"><use xlink:href="../../../assets/svg/flags.svg#flag-pkr"></use></svg>
              </div>
              USD/PKR (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 02:00:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-eur"><use xlink:href="../../../assets/svg/flags.svg#flag-eur"></use></svg><svg class="flag-cad"><use xlink:href="../../../assets/svg/flags.svg#flag-cad"></use></svg>
              </div>
              EUR/CAD (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 45:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg><svg class="flag-chf"><use xlink:href="../../../assets/svg/flags.svg#flag-chf"></use></svg>
              </div>
              USD/CHF (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 04:00:00</div>
            <div class="panel-trading-signals__item-time">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__past">过去的信号</div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-eur"><use xlink:href="../../../assets/svg/flags.svg#flag-eur"></use></svg><svg class="flag-aud"><use xlink:href="../../../assets/svg/flags.svg#flag-aud"></use></svg>
              </div>
              EUR/AUD (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 05:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:35</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-aud"><use xlink:href="../../../assets/svg/flags.svg#flag-aud"></use></svg><svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg>
              </div>
              AUD/USD (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 45:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:35</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg><svg class="flag-ngn"><use xlink:href="../../../assets/svg/flags.svg#flag-ngn"></use></svg>
              </div>
              USD/NGN (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 02:00:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-mcd"><use xlink:href="../../../assets/svg/flags.svg#flag-mcd"></use></svg><svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg>
              </div>
              McDonald's (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 03:00:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:34</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-ba"><use xlink:href="../../../assets/svg/flags.svg#flag-ba"></use></svg><svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg>
              </div>
              Boeing Company (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 10:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:32</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-aud"><use xlink:href="../../../assets/svg/flags.svg#flag-aud"></use></svg><svg class="flag-nzd"><use xlink:href="../../../assets/svg/flags.svg#flag-nzd"></use></svg>
              </div>
              AUD/NZD (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 05:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:32</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg><svg class="flag-egp"><use xlink:href="../../../assets/svg/flags.svg#flag-egp"></use></svg>
              </div>
              USD/EGP (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 04:00:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:32</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-nzd"><use xlink:href="../../../assets/svg/flags.svg#flag-nzd"></use></svg><svg class="flag-cad"><use xlink:href="../../../assets/svg/flags.svg#flag-cad"></use></svg>
              </div>
              NZD/CAD (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 45:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:32</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg><svg class="flag-cad"><use xlink:href="../../../assets/svg/flags.svg#flag-cad"></use></svg>
              </div>
              USD/CAD (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 05:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:33</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg><svg class="flag-php"><use xlink:href="../../../assets/svg/flags.svg#flag-php"></use></svg>
              </div>
              USD/PHP (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 05:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:33</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-ukbrent"><use xlink:href="../../../assets/svg/flags.svg#flag-ukbrent"></use></svg><svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg>
              </div>
              UKBrent (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 05:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:33</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-eur"><use xlink:href="../../../assets/svg/flags.svg#flag-eur"></use></svg><svg class="flag-gbp"><use xlink:href="../../../assets/svg/flags.svg#flag-gbp"></use></svg>
              </div>
              EUR/GBP (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 45:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:33</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-intc"><use xlink:href="../../../assets/svg/flags.svg#flag-intc"></use></svg><svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg>
              </div>
              Intel (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 03:00:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:33</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg><svg class="flag-idr"><use xlink:href="../../../assets/svg/flags.svg#flag-idr"></use></svg>
              </div>
              USD/IDR (OTC)
            </div>
            <svg class="icon-arrow-up-circle panel-trading-signals__circle--up"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-up-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 01:00:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:33</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-usd"><use xlink:href="../../../assets/svg/flags.svg#flag-usd"></use></svg><svg class="flag-ars"><use xlink:href="../../../assets/svg/flags.svg#flag-ars"></use></svg>
              </div>
              USD/ARS (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 10:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:33</div>
          </div>
        </div>
        <div class="panel-trading-signals__item --read">
          <div class="panel-trading-signals__item-header">
            <div class="panel-trading-signals__item-flags">
              <div class="flags trading-pair__flags flags">
                <svg class="flag-eur"><use xlink:href="../../../assets/svg/flags.svg#flag-eur"></use></svg><svg class="flag-nzd"><use xlink:href="../../../assets/svg/flags.svg#flag-nzd"></use></svg>
              </div>
              EUR/NZD (OTC)
            </div>
            <svg class="icon-arrow-down-circle panel-trading-signals__circle--down"><use xlink:href="../../../assets/svg/spritemap.svg#icon-arrow-down-circle"></use></svg>
          </div>
          <div class="panel-trading-signals__item-body">
            <div class="panel-trading-signals__item-duration">{{ $t('Duration') }}: 03:00:00</div>
            <div class="panel-trading-signals__item-time --read">28.04 22:33</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        return {
            whatShow:false
        }
    }
}

</script>