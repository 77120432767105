<template>
    <aside class="sidepanel app__sidepanel sidepanel__bg-black active">
        <div class="sidepanel__close sidepanel__bg-black" @click="handleReturnClick"><svg class="icon-close">
                <use xlink:href="../../../assets/svg/spritemap.svg#icon-close"></use>
            </svg></div>
        <div class="sidepanel__content">
            <div class="sidepanel__content-wrapper">
                <div class="panel-indicators__single-back" @click="handleReturnClick">
                    <svg class="icon-caret-left">
                        <use xlink:href="../../../assets/svg/spritemap.svg#icon-caret-left"></use>
                    </svg> 指标
                </div>
                <div class="sidepanel__body sidepanel__body--scroll-shadow">
                    <div class="panel-indicators__single-title">{{ obj.shortName }}</div>
                    <div class="panel-indicators__single-settings" v-if="obj.inputLabel.length > 0">
                        <!-- <indicator-value-input v-for="(item, index) in obj.calcParams" :key="index" v-if="item.sub==='type'" :name="obj.name"
                            :index="index" :mainlabel=labelList[index].main :sublabel=labelList[index].sub
                            :min="item.value - 10" :max="item.value + 10" :default="item.value" :interval="item.step"
                            :suffix=labelList[index].suffix
                            @inputValueChange="handleInputChange"></indicator-value-input> -->
                        <div class="item-params" v-for="(item, index) in obj.calcParams" :key="index">
                            <indicator-value-select v-if="item.sub === 'type'" :name="obj.name" :index="index"
                                :mainlabel=labelList[index].main :option="item.options" :default="item.value"
                                @inputValueChange="handleInputChange">

                            </indicator-value-select>
                            <indicator-value-input v-else :name="obj.name" :index="index"
                                :mainlabel=labelList[index].main :sublabel=labelList[index].sub :min="item.value - 10"
                                :max="item.value + 10" :default="item.value" :interval="item.step"
                                :suffix=labelList[index].suffix
                                @inputValueChange="handleInputChange"></indicator-value-input>
                        </div>


                    </div>
                    <div class="panel-indicators__single-settings">
                        <indicator-color-select v-for="(item, index) in obj.figures" :key="index"
                            v-if="item.type == 'line'" :label="item.title" :_key="item" :_value=getCurrentSize(index)
                            :hasvalue=true :color="getCurrentColor(index)" @colorValueChange="handleColorChange"
                            @sizeValueChange="handleSizeChange"></indicator-color-select>

                        <indicator-color-select v-for="(item, index) in obj.figures" :key="index"
                            v-if="item.type == 'circle'" :label="item.title" :_key="item" :_value=getCurrentSize(index)
                            :hasvalue=true :color="getCurrentColor(index)" @colorValueChange="handleColorChange"
                            @sizeValueChange="handleSizeChange"></indicator-color-select>

                        <!-- <div style="width:100%" v-for="(item, index) in obj.figures" :key="index"
                            v-if="item.type == 'bar'">
                            <indicator-color-select :label="getBarTitle(item.title, 'up')" :_key="item"
                                :_value=getCurrentSize(index) :hasvalue=true :color="getCurrentColor(index)"
                                @colorValueChange="handleColorChange"
                                @sizeValueChange="handleSizeChange"></indicator-color-select>
                            <indicator-color-select :label="getBarTitle(item.title, 'down')" :_key="item"
                                :_value=getCurrentSize(index) :hasvalue=true :color="getCurrentColor(index)"
                                @colorValueChange="handleColorChange"
                                @sizeValueChange="handleSizeChange"></indicator-color-select>
                        </div> -->
                        <indicator-color-select :label="bkLabel" :_key="bkLabel" :hasvalue=false
                            @colorValueChange="handleColorChange"
                            @sizeValueChange="handleSizeChange"></indicator-color-select>
                    </div>
                </div>
                <div class="sidepanel__footer">
                    <button class="button button--danger button--small button--dark sidepanel__button"
                        @click="handleRemoveClick">
                        <svg class="icon-recycle">
                            <use xlink:href="../../../assets/svg/spritemap.svg#icon-recycle"></use>
                        </svg>
                        <span class="delText">删除</span>
                    </button>
                </div>
                <div class="mobile_footer">
                    <button class="button button--danger button--small button--dark sidepanel__button"
                        @click="handleRemoveClick">
                        <svg class="icon-recycle">
                            <use xlink:href="../../../assets/svg/spritemap.svg#icon-recycle"></use>
                        </svg>
                    </button>
                    <button class="button comfire_button button--small button--dark sidepanel__button"
                        @click="handleReturnClick">
                        <svg class="icon-check">
                            <use xlink:href="../../../assets/svg/check.svg#icon-check"></use>
                        </svg>
                        <span>ok</span>
                    </button>
                </div>
            </div>
        </div>
    </aside>
</template>

<script>
import IndicatorValueInput from './kline-value-input.vue'
import IndicatorColorSelect from './kline-color-select.vue'
import IndicatorValueSelect from "./kline-value-select.vue"
import { EventBus } from '../../../components/eventBus.js'

export default {
    name: 'indicator-parampane',
    components: { IndicatorValueInput, IndicatorColorSelect, IndicatorValueSelect },
    data () {
        return {
            labelList: [{ main: '参数1', sub: '', suffix: '' },
            { main: '参数2', sub: '', suffix: '' },
            { main: '参数3', sub: '', suffix: '' },
            { main: '参数4', sub: '', suffix: '' },
            { main: '参数5', sub: '', suffix: '' }
            ],
            bkLabel: 'background'
        }
    },
    props: {
        obj: {
            type: Object,
            default: null
        },
        defaultStyles: {
            type: Object,
            default: null
        }
    },
    created () {
        if (this.obj.inputLabel.length > 0) {
            this.labelList = this.obj.inputLabel
        }
    },
    methods: {
        getBarTitle (title, dir) {
            return title + ' ' + dir
        },
        getCurrentColor (index) {
            console.log('getCurrentColor:', index)
            if (index == -1) { //background
                if (this.obj.styles.color != undefined) {
                    var c = this.obj.styles.color
                    console.log(c.slice(0, 3) + c.slice(4, c.lastIndexOf(',')) + ')')
                    return c.slice(0, 3) + c.slice(4, c.lastIndexOf(',')) + ')'
                }
                else {
                    return 'rgb(64, 64, 64)'
                }
            }
            else {
                if (this.obj.styles.lines == undefined) {
                    const defaultColor = ['#FF9600', '#935EBD', '#1677FF',]
                    return defaultColor[index]
                }
                return this.obj.styles.lines[index].color
            }
        },
        getCurrentSize (index) {
            console.log('getCurrentSize:', index)
            if (this.obj.styles.lines == undefined) {
                console.log(this.defaultStyles.lines[index].size)
                return Number(this.defaultStyles.lines[index].size)
            }
            console.log(this.obj.styles.lines[index].size)
            return Number(this.obj.styles.lines[index].size)
        },
        handleReturnClick () {
            this.$emit('closePanel', '')
            EventBus.$emit('settingBarToKline', "")
        },
        handleRemoveClick () {
            this.$emit('remove', this.obj.name)
        },
        handleInputChange (data) {
            console.log(data, 'changeIndicatorParamInputChange')

            var calcParam = this.obj.calcParams
            calcParam[data.index].value = data.value
            EventBus.$emit("changeIndicatorParamInputChange", this.obj)
            EventBus.$emit('leftIndicatorParamInputChange', { name: this.obj.name, calcParam: calcParam })
        },
        handleColorChange (data) {
            var styles = {}
            if (data.key == 'background') {
                styles['color'] = data.color.slice(0, 3) + 'a' + data.color.slice(3, data.color.length - 1) + ', 0.1)'
                styles['style'] = 'fill'
            }
            else {
                var lines = []
                var isline = true
                this.obj.figures.forEach(function (figure) {
                    if (figure.type != 'line') {
                        isline = false
                    }
                })
                // if (!isline) {
                //     return
                // }
                const _this = this
                this.obj.figures.forEach(function (item, index) {
                    if (item.key == data.key.key) {
                        if (_this.obj.styles.lines != undefined && _this.obj.styles.lines[index] != undefined) {
                            lines.push({ style: 'solid', dashedValue: _this.defaultStyles.lines[index].dashedValue, size: _this.obj.styles.lines[index].size, color: data.color })
                        }
                        else {
                            lines.push({ style: 'solid', dashedValue: _this.defaultStyles.lines[index].dashedValue, size: _this.defaultStyles.lines[index].size, color: data.color })
                        }
                    }
                    else {
                        if (_this.obj.styles.lines == undefined || _this.obj.styles.lines[index] == undefined) {
                            lines.push({ style: 'solid', dashedValue: _this.defaultStyles.lines[index].dashedValue, size: _this.defaultStyles.lines[index].size, color: _this.defaultStyles.lines[index].color })
                        }
                        else {
                            lines.push({ style: 'solid', dashedValue: _this.defaultStyles.lines[index].dashedValue, size: _this.obj.styles.lines[index].size, color: _this.obj.styles.lines[index].color })
                        }
                    }
                })
                if (lines.length > 0) {
                    styles.lines = lines
                }
            }
            console.log(styles)
            EventBus.$emit("changeIndicatorParamColorChange", styles)
            this.obj.setStyles(styles)
            console.log(this.obj.styles)
        },
        handleSizeChange (data) {
            var styles = {}

            console.log(this.obj.styles)

            var lines = []
            var isline = true
            this.obj.figures.forEach(function (figure) {
                if (figure.type != 'line') {
                    isline = false
                }
            })
            if (!isline) {
                return
            }
            const _this = this
            if (_this.obj.styles.color != undefined) {
                styles['color'] = _this.obj.styles.color
                styles['style'] = 'fill'
            }
            this.obj.figures.forEach(function (item, index) {
                if (item.key == data.key.key) {
                    if (_this.obj.styles.lines != undefined && _this.obj.styles.lines[index] != undefined) {
                        lines.push({ style: 'solid', dashedValue: _this.defaultStyles.lines[index].dashedValue, size: data.size, color: _this.obj.styles.lines[index].color })
                    }
                    else {
                        lines.push({ style: 'solid', dashedValue: _this.defaultStyles.lines[index].dashedValue, size: data.size, color: _this.defaultStyles.lines[index].color })
                    }
                }
                else {
                    if (_this.obj.styles.lines == undefined || _this.obj.styles.lines[index] == undefined) {
                        lines.push({ style: 'solid', dashedValue: _this.defaultStyles.lines[index].dashedValue, size: _this.defaultStyles.lines[index].size, color: _this.defaultStyles.lines[index].color })
                    }
                    else {
                        lines.push({ style: 'solid', dashedValue: _this.defaultStyles.lines[index].dashedValue, size: _this.obj.styles.lines[index].size, color: _this.obj.styles.lines[index].color })
                    }
                }
            })
            if (lines.length > 0) {
                styles.lines = lines
            }

            console.log(styles)
            this.obj.setStyles(styles)
            console.log(this.obj.styles)
        }
    }
}
</script>