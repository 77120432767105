export default {
    "+{value}% profit": "beneficio +{value}%",
    "Selfie verification required": "Verificación por selfie requerida",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "Para llevar a cabo los pasos necesarios para la verificación de cuenta, le pedimos amablemente que nos proporcione una foto de usted en alta resolución (selfie) sosteniendo su documento de identificación con la fecha actual y su firma en una hoja de papel. Su cara, cuerpo y ambas manos deben ser visibles.<br><br> Los detalles del documento deben ser claros y legibles.",
    "Your documents have been checked, now your identity is verified.": "Sus documentos han sido comprobados, ahora su identidad está verificada.",
    "You need fill identity information before verification your profile.": "Necesita rellenar información de identidad antes de verificar su perfil.",
    "Documents verification": "Documentos de verificación",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "La verificación del perfil significa la provisión de un documento oficial que certifique la identidad del cliente. Este proceso puede ser iniciado por el departamento de seguridad de la empresa en cualquier momento.",
    "Upload Photo": "Subir foto",
    "Nickname": "Alias",
    "First Name": "Nombre",
    "Last Name": "Apellidos",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "El nombre debe especificarse exactamente como aparece en el documento. Proporcionar información incorrecta puede resultar en la eliminación de la cuenta sin previo aviso.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "El apellido debe especificarse exactamente como en el documento. Proporcionar información incorrecta puede resultar en la eliminación de la cuenta sin previo aviso.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "No es posible cambiar su fecha de nacimiento más adelante. Si la indicó incorrectamente, no podrá retirar fondos de su cuenta.",
    "Empty": "Vacío",
    "Date of birth": "Fecha de nacimiento",
    "Unverified": "No verificado",
    "Verified": "Verificado",
    "Resend": "Reenviar",
    "Country": "País",
    "Address": "Dirección",
    "Change Identity Info": "Cambiar identidad",
    "Identity Info": "Información de identidad",
    "Two-factor authentication on login": "Factor de autenticación de 2 pasos en el inicio de sesión",
    "Two-factor authentication on withdrawal": "Factor de autenticación de 2 pasos en la retirada de fondos",
    "Active": "Activo",
    "Inactive": "Inactivo",
    "Setup": "Configuración",
    "Two-step verification": "Verificación en dos pasos",
    "Security": "Seguridad",
    "Google Authenticator": ",",
    "Scan the QR code": "Escanear código QR",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Nos tomamos su seguridad muy en serio, y por ello requerimos autenticación en 2 pasos. Aquí puede ver cómo configurarla:",
    "Install ": "Instalar",
    "Can't scan?": "¿No puede escanear?",
    "Click here.": "Haga clic aquí.",
    "Enter this code into Google Authenticator.": "Introduzca este código en Google Authenticator.",
    "Back to QR code.": "Volver al código QR.",
    "Enter the 6-digit code from Authenticator below": "Introduzca el código de 6 dígitos del Authenticator a continuación",
    "Enter 6-digit code...": "Introduzca el código de 6 dígitos…",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "Buen trabajo. Ha habilitado con éxito la verificación en 2 pasos. La próxima vez que inicie sesión le será requerido un código.",
    "Code": "Código",
    "contact support": "contactar con soporte",
    "If you ever lose access to your 2-step verification device, please ": "Si alguna vez pierde acceso a su dispositivo de verificación en 2 pasos, por favor",
    "Yes, activate": "Sí, activar",
    "Cancel": "Cancelar",
    "Close": "Cerrar",
    "Language": "Idioma",
    "Timezone": "Zona horaria",
    "Delete Account": "Borrar cuenta",
    "Verification Credit / Debit Cards": "Verificación de tarjetas de crédito o débito",
    "Add New Card": "Añadir nueva tarjeta",
    "Wait for loading...": "Espere a la carga…",
    "You don’t have any credit / debit cards for verification": "No tiene tarjetas de crédito o débito para verificar",
    "Verificate": "Verificar",
    "Verification Card": "Verificación de tarjeta",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "Por favor, coloque su ID (mostrando foto, nombre y fecha de nacimiento) junto con la cara frontal de su tarjeta bancaria (mostrando el número, fecha de expiración y su nombre) en una hoja A4 en blanco.<br> Por favor, escriba \"Quotex.io\" y añada su firma.<br> Tome una foto y súbala.",
    "Card:": "Tarjeta:",
    "Type:": "Tipo:",
    "Your ID and bank card on an A4 sheet": "Su ID y tarjeta bancaria en una hoja A4",
    "Yes, confirm": "Sí, quiero continuar",
    "Click or drag the image": "Haga clic o arrastre la imagen",
    "Card": "Tarjeta",
    "Documents": "Documentos",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "Primero, rellene por favor la sección \"Información de Identidad\" en su Perfil, y haga clic en el botón \"Cambiar información de Identidad\" para guardar los cambios. Tras ello podrá añadir una nueva tarjeta.",
    "Specify the card details for further operations for depositing and debiting funds.": "Especifique los detalles de la tarjeta para futuras operaciones de depósito y débito de fondos.",
    "Card number:": "Número de tarjeta:",
    "Expiry month:": "Mes de caducidad:",
    "Expiry year:": "Año de caducidad:",
    "Cardholder name:": "Nombre del titular de la tarjeta",
    "Next": "Próximo",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "Por favor, ponga su firma y la fecha actual en la hoja, coloque su documento junto con su tarjeta bancaria, tome una foto y súbala en el formulario a continuación.",
    "Do you have a two-sided document?": "¿Tiene un documento con dos caras?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "Suba la parte de atrás como un fichero separado, también con una hoja con la fecha y su firma.",
    "Submit only valid documents": "Envíe solamente documentos válidos",
    "Do not edit or crop photo": "No edite ni recorte la foto",
    "Do not upload other people's documents": "No suba documentos de otras personas",
    "Read all": "Leer todo",
    "Uploading files": "Subiendo ficheros",
    "For single-sided documents, upload only the front side.": "Para documentos de una sola cara, suba solo la parte frontal.",
    "Back": "Atrás",
    "Add a card": "Añadir una tarjeta",
    "File Requirements": "Requisitos de los ficheros",
    "Scanned images are not accepted for card verification": "Las imágenes escaneadas no se aceptan para verificación de tarjetas",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "Solamente aceptamos ficheros con su pasaporte civil normal o su documento nacional de identidad (ambas caras deben ser subidas como ficheros separados)",
    "Driving license CANNOT be accepted": "El permiso de conducir NO PUEDE ser aceptado",
    "External (foreign/international/travel) passport will not be accepted as well": "Pasaportes externos (extranjeros/internacionales/viaje) no serán tampoco aceptados",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "Si el documento tiene 2 caras, usted debe subir el reverso como un fichero separado, en la misma hora de papel, con la fecha actual y su firma",
    "All information in the document and on the card should be clear and readable on the photo": "Toda la información en el documento y en la tarjeta debe ser clara y legible en la foto",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "Tanto su documento como su tarjeta deben encajar en la foto completamente. Los bordes no deben ser recortados.",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "No debe haber brillos ni otros defectos fotográficos que puedan hacer dificultoso leer el documentos y los datos de la tarjeta",
    "Expired documents and cards will be rejected automatically": "Los documentos y tarjetas caducados serán rechazados automáticamente",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "Si su tarjeta no tiene su nombre en ella, necesita subir una captura de pantalla de un certificado bancario, mostrando el número de sus tarjetas y su nombre. En algunos casos, se permite escribir su nombre a mano en la misma hoja de papel",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "No está permitido ocultar elementos de los documentos que contengan datos requeridos para la verificación (nombre, fecha de nacimiento, fotografía)",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "Se permite ocultar los 8 dígitos en el número de su tarjeta. Los primeros 4 y los últimos 4 dígitos deben ser claramente visibles",
    "CVV code should be hidden at all times, in every case": "El código CVV debe ser escondido en todo momento, en todos los casos",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "Cualquier edición de sus ficheros está prohibida. Solamente fotos sin editar e imágenes escaneadas pueden ser aceptadas. La edición de datos personales en sus documentos (nombre, fecha de nacimiento, etc.) no está permitida",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "La fotografía debe ser parte el documento (pegada o impresa). Las fotos adjuntas de forma separada no serán aceptadas",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "Solamente se permite subir documentos y tarjetas en el nombre especificado en el perfil (con el cual está registrada su cuenta)",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "Le recordamos que en caso de enviar información falsa/manipulada, la cuenta podría ser suspendida/bloqueada hasta que las circunstancias sean aclaradas",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "La Empresa se reserva el derecho a pedirle que proporcione cualquier documento adicional para la verificación de la cuenta (incluyendo su selfie con documento) en cualquier momento",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "Para todas las cuestiones relativas a la verificación de documentos, puede crear un ticket de soporte en la plataforma (la sección \"Ayuda\" en el primer botón con el icono del signo de interrogación parpadeando, en el panel más a la izquierda)",
    "Profile": "Perfil",
    "Deposit": "Depósito",
    "Withdrawal": "Retirada",
    "Transactions": "Transacciones",
    "Trades": "Operaciones",
    "Market": "Mercado",
    "Analytics": "Analíticas",
    "My current currency": "Mi divisa actual",
    "Available for withdrawal": "Disponible para retirar",
    "In the account": "En la cuenta",
    "Percentage of turnover {percent}%": "Porcentaje de facturación",
    "Cashback {percent}%": "Reembolso {percent}%",
    "Currency:": "Divisa:",
    "Change": "Cambio",
    "Live Account": "Cuenta real",
    "Daily budget limit": "Límite de gasto diario",
    "The value cannot exceed your total budget": "El valor no puede exceder su presupuesto total",
    "Demo Account": "Cuenta demo",
    "Apply": "Solicitar",
    "Edit": "Editar",
    "New value": "Nuevo valor",
    "Confirm": "Confirmar",
    "Logout": "Cerrar sesión",
    "No, cancel": "No, quiero cancelar",
    "Exchange Form": "Formulario de intercambio",
    "My Currency:": "Mi divisa:",
    "New Currency:": "Nueva divisa:",
    "You are exchanging": "Estás cambiando",
    "You will receive": "Recibirás",
    "Exchange Fee": "Tasa de cambio",
    "Password changed": "Contraseña cambiada",
    "Old password": "Contraseña anterior",
    "New password": "Nueva contraseña",
    "Confirm new password": "Confirmar nueva contraseña",
    "Change Password": "Cambiar la contraseña",
    "Confirm password must be equal new password value": "Confirmar contraseña debe tener el mismo valor que la nueva contraseña",
    "About us": "Acerca de nosotros",
    "Support": "Atención al cliente",
    "Help": "Ayuda",
    "2-step verification": "Verificación en dos pasos",
    "Please enter the PIN-code we've just sent to your email": "Por favor, introduzca el código PIN que le acabamos de enviar a su correo electrónico",
    "Please enter the 6-digit code from your authentication app": "Por favor, introduzca el código de 6 dígitos de su app de autenticación",
    "The First Name field is required": "El campo nombre es obligatorio",
    "The Last Name field is required": "El campo apellido es obligatorio",
    "Verification of documents": "Verificación de documentos",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "Por favor, suba una foto en color o imagen escaneada de su pasaporte, licencia de conducir o documento nacional de identidad.",
    "Upload Documents": "Subir documentos",
    "Why?": "¿Por qué?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "¿Quiere confirmar la eliminación de su cuenta sin deshacer? Esta acción no puede ser deshecha.",
    "Set up 2-step verification": "Configurar verificación en 2 pasos",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "Por desgracia, no puede subir ficheros a esta sección.<br>Por favor, refiérase a los mensajes del equipo de Soporte y adjunte los ficheros requeridos a un ticket.",
    "The uploaded document does not match the requirements": "El documento subido no cumple con los requisitos",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "Los siguientes documentos son válidos para identificación: ID, pasaporte, pasaporte internacional o licencia de conducir.",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "Las imágenes deberían ser de alta calidad, los datos deberían ser fáciles de leer, y los bordes del documento no deberían estar cortados.",
    "Balance from {amount}": "Saldo de {amount}",
    "Not verified": "No verificado",
    "Clear": "Limpiar",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "Para evitar restricciones del servicio, por favor complete el proceso de verificación: rellene sus datos personales y suba los documentos requeridos.",
    "Get verified": "Verificar",
    "I got it!": "¡Lo tengo!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "Verificación AADHAAR",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "Por favor, suba una fotografía en color de su Aadhaar físico (solamente la cara frontal con su foto y número de documento).",
    "Send AADHAAR": "Enviar AADHAAR",
    "AADHAAR number:": "Número AADHAAR:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "Por favor, suba una fotografía en color de su Aadhaar físico (solamente la cara frontal con su foto y número de documento).☝ No se aceptan versiones electrónicas.",
    "You have already uploaded {number} files. Please wait until they are checked.": "Usted ya ha subido {number} ficheros. Por favor, espere hasta que hayan sido comprobados.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "Por favor, suba una fotografía en color de su documento: Para RG - ambas caras deben ser subidas como ficheros separados. Para CNH - solamente la cara delantera con su foto. El documento debe ser válido (menos de 10 años desde su fecha de emisión), y debe mostrar su número CPF. * en caso de que no haya CPF en su documento - por favor, suba una foto separada de su Comprovante de Inscrição no CPF, o una captura de pantalla de su extracto de cuenta bancaria mostrando su nombre y número CPF. ** Por favor, no suba versiones electrónicas. No serán aceptadas.",
    "Submit for verification": "Envíe para verificación",
    "Settings": "Ajustes",
    "Please upload a color photograph of the front of your national identity card with your photo.": "Por favor, cargue una fotografía a color de la fachada de su documento de identidad nacional con su fotografía.",
    "Template": "Plantilla",
    "Light Mode": "Modo diurno",
    "Twilight": "Crepúsculo",
    "Full Night": "Noche cerrada",
    "Grid's opacity": "Opacidad de la rejilla",
    "Auto-scrolling": "Auto-desplazamiento",
    "Automatic graphic scrolling": "Desplazamiento de gráfico automático",
    "1-click trade": "Operar en 1 clic",
    "Open trades without confirmation": "Abrir operaciones sin confirmación",
    "Use timezone": "Utilizar zona horaria",
    "Use your timezone data": "Utilizar sus datos de zona horaria",
    "Graph colors": "Colores del gráfico",
    "Up Trend": "Tendencia alcista",
    "Down Trend": "Tendencia bajista",
    "Platform": "Plataforma",
    "Short order label": "Etiqueta de orden corta",
    "Use short order element mode": "Utilizar modo de elemento de orden corta",
    "Perfomance Mode": "Modo de rendimiento",
    "Use optimized rendering for chart and candles": "Utilizar renderizado optimizado para gráficas y velas",
    "Background": "Fondo",
    "Choose file": "Elija archivo",
    "Max size — 2 Mb": "Tamaño max - 2 Mb",
    "Sign up": "Registrarse",
    "Sign in": "Iniciar sesión",
    "Budget and limits": "Presupuestos y límites",
    "Daily limit": "Límite diario",
    "Not set": "No establecido",
    "Total funds": "Fondos totales",
    "Top up": "Llenar",
    "Set up": "Configurar",
    "Set a daily limit on trading to reduce financial risks.": "Fijar un límite diario en la operativa para reducir riesgos financieros.",
    "Limit amount": "Limitar importe",
    "More": "Más",
    "Leader Board": "Tabla de clasificación",
    "Signals": "Señales",
    "Notifications": "Notificaciones",
    "mark all as read": "marcar todo como leído",
    "No notifications": "No hay notificaciones",
    "No more notifications": "No más notificaciones",
    "Your position:": "Su posición:",
    "How does this rating work?": "¿Cómo funciona esta puntuación?",
    "of the Day": "del día",
    "Nothing has been found for current query, try another asset name.": "No se ha encontrado nada para su consulta actual, pruebe otro nombre de activo.",
    "My rating in the Leader Board": "Mi puntuación en la Tabla de Clasificación",
    "Participants can only be those who trade in live mode": "Los participantes solamente pueden ser los que operan en modo real",
    "Your position depends on the amount of earned money": "Su posición depende de la cantidad de dinero ganado",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "Calculado cada día desde las 00:00 UTC hasta las 23:59 UTC",
    "All traders on our platform participate in this rating. Main features of the rating:": "Todos los traders en nuestra plataforma participan en esta clasificación. Principales características de la clasificación:",
    "How does it work?": "¿Cómo funciona?",
    "Trading signals": "Señales de operativa",
    "Past signals": "Señales pasadas",
    "What’s it?": "¿Qué es?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "Una señal de operativa no es una instrucción directa para operar, sino una recomendación de un analista. No debería reemplazar a un análisis independiente del mercado",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "Tiene una media de 10 minutos para sacar ventaja de la señal. Tras ese tiempo, las cuotas podrían cambiar y tener un efecto inesperado en el resultado de la operación.",
    "Continue": "Continuar",
    "Duration": "Duración",
    "Return back to FAQ": "Volver a las preguntas frecuentes",
    "Return back to Help": "Volver a ayuda",
    "How to withdraw money from the account?": "¿Cómo puedo retirar dinero de la cuenta?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "El proceso de retiro es sumamente sencillo y se realiza a través de su cuenta individual.",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "El método que ha elegido para depositar la cuenta también es el método de retiro (para saber más, véase la pregunta \"¿Cómo puedo depositar?\").",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "Por ejemplo, si realizó un depósito en su cuenta a través del sistema de pago Visa, tendrá que retirar dinero también a través de Visa.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "Cuando se trata del retiro de una cantidad suficientemente grande, la empresa puede solicitar la verificación, a su discreción exclusiva. Por lo tanto, es muy importante que registre una cuenta que pertenezca sólo a usted, para poder confirmar sus derechos sobre ella en cualquier momento.",
    "What is the minimum withdrawal amount?": "¿Cuál es la cantidad mínima de retirada?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "La cantidad mínima de retirada comienza en 10 USD para la mayoría de sistemas de pago.<br>Para criptomonedas, esta cantidad comienza en 50 USD (y podría ser mayor para ciertas divisas, por ejemplo Bitcoin).",
    "Do I need to provide any documents to make a withdrawal?": "¿Necesito proporcionar algún documento para realizar un retiro?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "Por lo general, no se necesitan documentos adicionales para retirar el dinero. No obstante, la empresa, a su discreción, puede pedirle que confirme sus datos personales solicitando ciertos documentos. Normalmente, esto se hace con el fin de prevenir actividades relacionadas con el comercio ilegal, el fraude financiero, así como el uso de fondos obtenidos ilegalmente.",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "La lista de los documentos solicitados es mínima, y el proceso de proporcionarlos no le llevará mucho tiempo y esfuerzo.",
    "How long does it take to withdraw funds?": "¿Cuánto tiempo tarda el retiro de fondos?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "En promedio, el procedimiento de retiro lleva de uno a cinco días a partir de la fecha de recepción de la solicitud correspondiente del cliente, y depende sólo del número de solicitudes procesadas simultáneamente. La empresa siempre intenta realizar los pagos directamente el día en que se recibe la solicitud del cliente.",
    "Is there any fee for depositing or withdrawing funds from the account?": "¿Hay alguna tarifa por depositar o retirar fondos de la cuenta?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "No. La empresa no cobra ninguna tarifa por el depósito ni por el retiro.",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "Sin embargo, es importante tener en cuenta que los sistemas de pago pueden cobrar su tarifa y utilizar la tasa de conversión interna.",
    "Can I close my account? How to do it?": "¿Puedo cerrar mi cuenta? Si es así, ¿cómo hacerlo?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "Puede eliminar una cuenta en su cuenta personal haciendo clic en el botón \"Eliminar cuenta\" ubicado en la parte inferior de la página de perfil.",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "He cometido un error al ingresar los datos en mi cuenta individual. ¿Cómo puedo solucionarlo?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "Tiene que ponerse en contacto con el servicio de soporte técnico en el sitio web de la empresa y editar su perfil.",
    "What data is required to register on the Company website?": "¿Qué datos necesito proporcionar para registrarse en el sitio web de la empresa?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "Para ganar dinero con opciones digitales, primero debe abrir una cuenta que le permita realizar las operaciones. Para hacer esto, debe registrarse en el sitio web de la empresa.",
    "The registration process is simple and does not take much time.": "El proceso de registro es sencillo y no lleva mucho tiempo.",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "Es necesario completar un cuestionario en el formulario propuesto. Se le pedirá que ingrese la siguiente información:",
    "name (in English)": "Nombre y apellidos",
    "email address (indicate the current, work, address)": "Dirección de correo electrónico (debe ser la dirección actual)",
    "telephone (with a code, for example, + 44123 ....)": "Teléfono (con un código, por ejemplo, +34 ...)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "Una contraseña que usará en el futuro para ingresar al sistema. Para minimizar el riesgo de acceso no autorizado a su cuenta individual, le recomendamos que cree una contraseña compleja utilizando letras minúsculas, mayúsculas y números. Nunca revele la contraseña a terceros.",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "Después de completar el cuestionario, se le ofrecerán varias formas de hacer un depósito a su cuenta de negociación.",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "¿En qué moneda se abre la cuenta del cliente? ¿Puedo cambiar la moneda?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "Por defecto, la cuenta comercial se abre en dólares estadounidenses. Sin embargo, para su comodidad, puede abrir varias cuentas en diferentes monedas.",
    "A list of available currencies can be found on your profile page in your Client's account.": "Puede encontrar la lista de monedas disponibles en la página de su perfil.",
    "What are digital options?": "¿Qué son las opciones digitales?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "Una opción es un instrumento financiero derivado basado en cualquier activo subyacente (puede ser una acción, par de divisas, petróleo u otra materia prima, etc.).",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "Una opción digital es una opción no estándar que se utiliza para obtener ganancias de los movimientos de precios de dichos activos durante un cierto período de tiempo.",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "Una opción digital, dependiendo de los términos acordados por las partes de la transacción, en un momento determinado por las partes, trae un ingreso fijo (la diferencia entre el ingreso comercial y el precio del activo) o pérdida (en la cantidad del valor del activo).",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "Dado que la opción digital se compra por adelantado a un precio fijo, el tamaño de la ganancia, así como el tamaño de la pérdida potencial, se conocen incluso antes de la transacción.",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "Otra característica de los instrumentos así es el límite de tiempo. Cualquier opción tiene su propio plazo (tiempo de vencimiento o tiempo de conclusión).",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "Si la negociación de la opción digital es exitosa, siempre se realiza un pago fijo, sin tener en consideración el grado de cambio en el precio del activo subyacente (cuánto ha subido o bajado). Por lo tanto, sus riesgos están limitados únicamente por la cantidad, por la cual se adquiere la opción.",
    "What is the expiration period of a trade?": "¿Qué es el período de vencimiento de una operación?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "El período de vencimiento es el tiempo después del cual la operación se considerará completada (cerrada) y el resultado se resume automáticamente.",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "Al concluir una operación con opciones digitales, es usted que determina el tiempo de ejecución de la transacción, seleccionando un plazo: 1 minuto, 2 horas, 1 mes, etc.",
    "What is the gist of digital options trading?": "¿Cuál es la esencia del comercio de opciones digitales?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "El hecho es que la opción digital es el tipo más simple de instrumento financiero derivado. Para ganar dinero en el mercado de opciones digitales, no es necesario predecir el cambio exacto del precio de un activo, o el precio exacto que tiene que alcanzar.",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "El principio del proceso de negociación se reduce sólo a la solución de una sola tarea: si el precio de un activo aumentará o disminuirá en el momento en que se ejecute el contrato.",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "Por lo tanto, no importa en absoluto que el precio del activo subyacente suba cien puntos o solo uno, desde el momento en que se concluye la operación hasta su cierre. Sólo es importante determinar la dirección de movimiento del precio.",
    "If your prognosis is correct, in any case you get a fixed income.": "Si su predicción es correcta, en cualquier caso obtiene un ingreso fijo.",
    "What are the possible results of the placed trades?": "¿Cuáles son los posibles resultados de las operaciones?",
    "There are three possible outcomes in the digital options market:": "Existen tres variantes de los resultados de una transacción en el mercado opciones digitales:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "si su predicción sobre la dirección del movimiento del precio del activo subyacente es correcto, recibe una ganancia;",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "si para el momento en que se concluyó la opción, su predicción resultó ser errónea, incurrirá en una pérdida limitada por el tamaño del valor del activo (es decir, de hecho, solo puede perder su inversión);",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "si el resultado de la operación es cero (el precio del activo subyacente no ha cambiado, la opción se concluye al precio al que fue comprado), recibe su inversión. Por lo tanto, el nivel de su riesgo siempre está limitado sólo por el tamaño del valor del activo.",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "¿Hay una cuenta demo el plataforma de negociación para comprender el proceso de operación con opciones digitales sin algún riesgo?",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "Sí. Para desarrollar habilidades comerciales y probar las capacidades de la plataforma comercial de la empresa, puede utilizar una cuenta demo que es absolutamente gratis. Es un tipo de simulador que le permite primero intentar a negociar sin riesgo y sólo luego pasar al comercio real. Esta cuenta de demostración también es adecuada para que los negociantes experimentados mejoren su nivel profesional.",
    "The balance of such an account is 10,000 units.": "El saldo de la cuenta demo es de 10.000 monedas.",
    "What determines profit size?": "¿Qué determina el tamaño de las ganancias?",
    "There are several factors that affect the size of your profit:": "Hay varios factores que afectan el tamaño de sus ganancias:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "La liquidez del activo que ha elegido en el mercado (cuanto mayor sea la demanda del activo en el mercado, más serán su ganancias)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "El tiempo de la operación (la liquidez de un activo por la mañana y por la tarde pueden variar de una manera muy significativa)",
    "tariffs of a brokerage company": "Tarifas del bróker",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "Cambios en el mercado (eventos económicos, cambios del precio del activo financiero, etc.)",
    "What are the varieties of digital options?": "¿Cuáles son las variedades de las opciones digitales?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "Antes de realizar una transacción con una opción, tiene que elegir el activo subyacente que será la base de la opción. Su predicción también se basará en este activo.",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "En pocas palabras, al comprar un contrato digital, en realidad está apostando por el movimiento del precio de dicho activo subyacente.",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "Un activo subyacente es un activo cuyo precio se tiene en cuenta al concluir una transacción. De ordinario, son los productos del mercado más populares. Hay cuatro tipos de ellos:",
    "securities (shares of world companies)": "valores (acciones de empresas mundiales)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "pares de divisas (EUR/USD, GBP/USD, etc.)",
    "raw materials and precious metals (oil, gold, etc.)": "materias primas y metales preciosos (petróleo, oro, etc.)",
    "indices (S&P 500, Dow, dollar index, etc.)": "índices (S&P 500, Dow Jones, índice del dólar, etc.)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "No existe un activo subyacente universal. Al elegirlo, se tiene en cuenta sólo su propio conocimiento, intuición y diversos tipos de información analítica, así como el análisis de mercado de un instrumento financiero en particular.",
    "What is a trading platform and why is it needed?": "¿Qué es una plataforma de negociación y por qué es necesaria?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "La plataforma de negociación es un software, mediante el cual el cliente recibe una oportunidad de realizar transacciones u operaciones utilizando los instrumentos financieros ofrecidos. Además, con la plataforma el cliente obtiene acceso a información sobre los precios actuales, la posición de mercado en tiempo real, las acciones de la empresa, etc.",
    "How to learn quickly how to make money in the digital options market?": "¿Cómo puedo aprender a ganar dinero en el mercado de opciones digitales más rápido?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "desarrollar sus propias estrategias comerciales, en las que la cantidad de operaciones predichas correctamente será máxima, y seguirlas;",
    "diversify your risks": "diversificar sus riesgos.",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "En el desarrollo de estrategias, así como en la búsqueda de opciones de diversificación, le ayudarán el seguimiento del mercado, así como el estudio de la información analítica y estadística que se puede obtener de diversas fuentes: recursos de Internet, opiniones de expertos, analistas en este campo, etc., incluyendo el sitio web de la empresa.",
    "Is the download of the program to a computer or smartphone required?": "¿Se requiere descargar el programa a un ordenador o celular?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "No, no hace falta. Solo necesita registrarse en el sitio web de la empresa en el formulario correspondiente y abrir una cuenta personal.",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "¿A qué costo paga la empresa las ganancias al cliente en caso de una transacción exitosa?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "La empresa gana con los clientes. Por lo tanto, le interesa que el número de las transacciones rentables prevalezca significativamente sobre el número de las no rentables, debido a que la empresa tiene un porcentaje de pagos de una estrategia comercial exitosa elegida por el cliente.",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "Además, las operaciones realizadas por el cliente constituyen en conjunto el volumen de operaciones de la empresa, que se transfiere a un bróker o bolsa, que a su vez se incluyen en el grupo de proveedores de liquidez, lo que en conjunto conduce a un aumento en la liquidez del mercado.",
    "What is account verification?": "¿Qué es la verificación de la cuenta?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "Durante el proceso de verificación, el cliente necesita verificar sus datos personales proporcionando algunos documentos a la empresa. Las condiciones de verificación son lo más sencillas posible por el cliente, y la lista de documentos es mínima. Por ejemplo, la empresa puede solicitar lo siguiente:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "Proporcionar una copia escaneada en color de la identificación del cliente que lleva una foto",
    "identify with the help of a \"selfie\" (photograph of himself)": "Enviar un selfie (auto foto)",
    "confirm the address of registration (residence) of the Client, etc": "Confirmar la dirección de residencia del cliente con un documento oficial.",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "SI no resulta posible identificar completamente al cliente y los datos ingresados por él, la empresa puede solicitar cualquier documento.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "Una vez que las copias electrónicas de los documentos hayan sido enviadas a la empresa, el cliente tiene que esperar un rato a que se verifiquen los datos proporcionados.",
    "How to understand that I need to go through account verification?": "¿Cómo puedo entender si debo realizar la verificación de mi cuenta?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "Si la verificación es necesaria, recibirá una notificación por correo electrónico y/o una notificación por SMS.",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "Es importante tener en cuenta que la empresa utiliza los datos de contacto que ha indicado en el cuestionario durante el registro (en particular, la dirección de correo electrónico y el número de teléfono). Por lo tanto, tenga cuidado y proporcione la información relevante y correcta.",
    "How do I know that I successfully passed verification?": "¿Cómo me entero de que he pasado la verificación con éxito?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "Recibirá una notificación por correo electrónico y/o SMS diciendo que el proceso de verificación de su cuenta está finalizado, y que puede operar en la plataforma de negociación de la empresa.",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "¿Es posible indicar los datos de otras personas o datos falsos al registrarme en el sitio web?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "No. Al registrarse en el sitio web de la empresa, el cliente debe proporcionar la información completa y veraz sobre sí mismo sobre las cuestiones solicitadas en el formulario de registro, y mantener actualizada esta información.",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "Si es necesario realizar varios tipos de verificación de la identidad del cliente, la empresa puede solicitar documentos o invitar al cliente a su oficina.",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "Si los datos ingresados en los campos de registro no coinciden con los datos de los documentos enviados, su perfil individual puede ser bloqueado.",
    "How long does the verification process take?": "¿Cuánto tiempo dura el proceso de verificación?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "No más de 5 días hábiles a partir de la fecha en la que la empresa recibe los documentos solicitados.",
    "Is there a minimum amount that I can deposit to my account at registration?": "¿Existe una cantidad mínima que yo tenga que depositar en mi cuenta al registrarme?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "La ventaja de la plataforma de trading de la empresa es que no tiene que depositar grandes cantidades en su cuenta. Puede comenzar a operar invirtiendo una pequeña cantidad de dinero, siendo el mínimo sólo 10 dólares estadounidenses.",
    "How can I deposit?": "¿Cómo puedo depositar?",
    "It is very easy to do. The procedure will take a couple of minutes.": "Es muy fácil de hacer. el proceso tarda sólo un par de minutos.",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "Abra la pantalla de ejecución de operaciones y haga clic en el botón verde que dice \"Depositar\" en el rincón superior derecho de la pestaña.",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "Además, puede depositar la cuenta a través de su cuenta personal haciendo clic en el botón \"Depositar\" en el perfil de la cuenta.",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "Al hacer esto, elija un método de pago. La empresa ofrece muchos métodos convenientes que están disponibles para el cliente; éstos se muestran en su cuenta personal.",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "A continuación, indique la moneda en la que se depositará la cuenta y, en consecuencia, la moneda de su cuenta.",
    "Enter the amount of the deposit.": "Ingrese la cantidad del depósito.",
    "Fill out the form by entering the requested payment details.": "Complete el formulario ingresando los detalles de pago solicitados.",
    "Make a payment.": "Realice su pago.",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "¿Necesito hacer depósitos? Si es así, ¿con qué frecuencia debo hacerlo?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "Para operar las opciones digitales, hay que abrir una cuenta individual, y para hacer transacciones reales, naturalmente tendrá que realizar un depósito.",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "Puede comenzar a operar sin dinero real, solo utilizando la cuenta de prácticas (cuenta demo). Esta cuenta es gratuita y se crea para demostrar el funcionamiento de la plataforma de negociación. Gracias a la cuenta demo, puede aprender a operar las opciones digitales, comprender los principios básicos de la negociación, probar varios métodos y estrategias o evaluar el nivel de su intuición.",
    "What is the minimum deposit amount?": "¿Cuál es la cantidad mínima de depósito?",
    "{number} questions": "{number} preguntas",
    "Verification": "Verificación",
    "Payment": "Pago",
    "Payouts": "Pagos",
    "Ask a specialist": "Preguntar a un especialista",
    "FAQ": "Preguntas frecuentes (FAQ)",
    "Didn't find an answer to your question?": "¿No ha encontrado la respuesta a su pregunta?",
    "Open knowledge base": "Abrir base de conocimientos",
    "Contact support": "Contactar el soporte",
    "Open FAQ page": "Abrir la página de preguntas frecuentes",
    "New message": "Nuevo mensaje",
    "Tournaments": "Torneos",
    "To end": "Hasta el fin",
    "Until start": "Hasta el comienzo",
    "Front side": "Anverso",
    "Back side (if any)": "Parte posterior (si la hay)",
    "Tourna-ments": "Torneos",
    "Deletion of Account and Personal Data": "Borrado de cuenta y datos personales",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "Al borrar su cuenta y datos personales, usted perderá acceso a su cuenta en la plataforma Quotex permanentemente. ¡Recuerde que sus datos serán borrados irreversiblemente y es imposible restaurar su cuenta más tarde!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "NOTA: Por favor, complete todas la operaciones abiertas y órdenes pendientes antes de borrar su cuenta.",
    "Request Deletion": "Solicitar borrado",
    "To delete your account, please contact support service": "Para borrar su cuenta, por favor, contacte con atención al cliente",
    "Two-factor authorization via Email": "Autorización de dos factores vía correo electrónico",
    "This feature is for experienced users who are familiar with Google Authenticator.": "Esta función es para usuarios experimentados que estén familiarizados con Google Authenticator.",
    "Please note!": "¡Por favor, tenga en cuenta!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "Si usted pierde el acceso a Google Authenticator por cualquier motivo después de conectarlo, usted perderá el acceso a su cuenta.",
    "Proceed to setup": "Proceda a la configuración.",
    "Account protection method": "Método de protección de cuenta",
    "Here's how to set it up:": "Aquí le mostramos cómo instalarlo:",
    "Please enter the PIN-code we've just sent to your email:": "Por favor, introduzca el código PIN que acabamos de enviar a su correo electrónico:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "¡Buen trabajo! Ha habilitado la verificación en dos pasos mediante Google Authenticator. La próxima vez que inicie sesión, se le solicitará un código.",
    "If you ever lose access to your 2-step verification device, please": "Si alguna vez pierde el acceso a su dispositivo de verificación en 2 pasos, por favor",
    "Confirm account deletion": "Confirmar el borrado de la cuenta",
    "To delete your account, follow the link in the email sent to": "Para eliminar su cuenta, siga el enlace en el correo electrónico enviado a",
    "Image requirements": "Requisitos de imágenes",
    "Email": "Correo electrónico",
    "Сonfirmation": "Confirmación",
    "Change protection method": "Cambie el método de protección",
    "First, enter the code from the Google Authenticator app:": "Primero, introduzca el código de la aplicación Google Authenticator:",
    "First, enter the PIN-code we've just sent to your email:": "Primero, introduzca el código PIN que acabamos de enviar a su correo electrónico:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "¡Buen trabajo! Ha habilitado con éxito la verificación en 2 pasos mediante correo electrónico.",
    "Receiving codes via Email": "Recibiendo códigos por correo electrónico",
    "Receiving codes via Google Authenticator": "Recibiendo códigos a través de Google Authenticator",
    "Choose a method:": "Elija un método:",
    "To enter the platform": "Para entrar a la plataforma",
    "To withdraw funds": "Para retirar fondos",
    "day": "día",
    "days2": "días",
    "days5": "días",



text: 'Seleccionar moneda',
text1: 'Correo electrónico',
text2: 'Cancelar transacción',
text3: 'Código promocional gratuito',
text4: 'Estamos constantemente mejorando la plataforma para hacerla más conveniente.',
text5: '¿Te gusta esta plataforma? ¡Preséntala a otros!',
text6: 'Por favor, deja tus comentarios sobre QUOTEX, y recibirás una recompensa por cancelaciones de transacciones gratuitas.',
text7: 'Envía el enlace de tu comentario a nuestro equipo de soporte al cliente, y después de revisarlo, te enviaremos esta recompensa.',
text8: 'Cantidad mínima de depósito',
text9: 'Cantidad mínima de retiro',
text10: 'Retiro rápido',
text11: 'Gratis',
text12: 'No verificado',
text13: 'Reenviar',
text14: 'Confirmar tu participación',
text15: 'Cancelar transacción',
text16: 'Ingresa y activa rápidamente tu código promocional ahora. Puedes usar el código promocional en cualquier momento.',
text17: 'Código promocional:',
text18: 'Obtén un 30% de bonificación según el saldo de tu depósito',
text19: 'Moneda actual',
text20: 'Saldo disponible para retiro',
text21: 'En cuenta',
text22: 'Hoy',
text23: 'Ayer',
text24: 'Semana',
text25: 'Mes',
text26: 'Cantidad neta de transacciones',
text27: 'WELCOME es un bono del 30% para el primer depósito.',
text28: 'La cantidad del primer depósito es de $100. Para obtener un bono del 30%, elige un método de depósito.',
text29: 'Tarjeta bancaria',
text30: 'Banco',
text31: 'Método de pago seleccionado:',
text32: 'Cantidad mínima:',
text33: 'Cantidad máxima:',
text34: 'Seleccionar otro método',
text35: 'Información de pago',
text36: 'Cantidad',
text37: 'Usar bono',
text38: 'Términos y condiciones',
text39: 'Tengo un código promocional',
text40: 'Código promocional',
text41: 'Aplicar código promocional',
text42: 'El código promocional que ingresaste es correcto. Recibirás un 30% de bonificación.',
text43: 'Recibirás',
text44: '',
text45: 'Aprende todas las preguntas frecuentes',
text46: 'Para negociar activos digitales, necesitas abrir una cuenta personal. Para realizar operaciones reales, definitivamente necesitas depositar una cantidad de acuerdo al precio de la opción.',
text48: 'Tienes {0} códigos promocionales',
text49: 'Usa este código promocional para cancelar transacciones perdedoras',
text50: 'Solo puedes cancelar transacciones no cubiertas con un monto que no supere el valor máximo del código promocional.',
text51: 'Estado',
text52: 'Usar',
text53: 'No tienes historial de códigos promocionales. Puedes agregar un código promocional usando los botones a continuación.',
text54: 'Mostrar historial',
text55: 'Ingresar código promocional',
text56: 'Información de privacidad',
text57: 'Vacío',
text58: 'Nombre',
text59: 'Apellido',
text60: 'Fecha de nacimiento',
text61: 'País',
text62: 'Guardar',
text63: 'Dirección',
text64: 'Verificación de documentos',
text65: 'Es necesario proporcionar información personal antes de verificar la cuenta.',
text66: 'Seguridad',
text67: 'Verificación en dos pasos',
text68: 'Cambiar contraseña',
text69: 'Contraseña antigua',
text70: 'Contraseña nueva',
text71: 'Volver a ingresar la nueva contraseña',
text72: 'Cerrar sesión de la cuenta',
text73: 'Verificación de tarjeta de crédito o débito',
text74: 'Agregar nueva tarjeta bancaria',
text75: 'No tienes tarjetas de crédito o débito que necesiten verificación',
text76: 'No tienes historial de operaciones.',
text77: 'Inicio',
text78: 'Vencimiento',
text79: 'Aplicar filtro',
text80: 'Anterior',
text81: 'Siguiente',
text82: 'Beneficio',
text83: 'Cancelado',
text84: 'Cuenta',
text85: 'Tarifa',
text86: 'Puedes retirar fondos de tu cuenta en la plataforma a la tarjeta de crédito o billetera electrónica que usaste para depositar fondos. Puedes solicitar un retiro en cualquier momento. Tus solicitudes de retiro serán procesadas dentro de 3 días hábiles.',
text87: 'Depositar fondos',
text88: 'Algunas solicitudes anteriores',
text89: 'Historial de retiros',
text90: 'Mis solicitudes',
text91: 'Enviar solicitud',
text92: 'Preguntas frecuentes',
text93: 'No has enviado ninguna solicitud',
text94: 'Si tienes alguna pregunta, puedes encontrar respuestas en las preguntas frecuentes.',
text95: 'Copyright © 2024 Quotex. Todos los derechos reservados',
text96: 'Iniciar sesión',
text97: 'Contraseña',
text98: 'Recordarme',
text99: 'Volver a la información',
text100: 'Completado',


text104: 'An Innovative Platform for Smart Investments',
text105: 'Register and get $10,000 on a simulated trading account for learning.',
text106: 'Sign Up',
text107: 'Open a simulated account',
text108: 'Convenient Trading Interface',
text109: `We have created the simplest and most convenient interface so you won't deviate from what matters most: trading.`,
text110: 'Built-in Signals',
text111: 'Consider trading strategies, we provide the most accurate and innovative signals up to 87% to assist you in formulating your own effective strategy.',
text112: 'Trading Indicators',
text113: 'We have collected the most useful trading indicators. Use this indicator to increase your account balance.',
text114: 'Perfect Speed',
text115: 'Our platform adopts the most innovative technology and provides tremendous speed.',
text116: 'Try it out with a simulated account',
text117: 'To earn more money,',
text118: 'Make the right forecast',
text119: 'Will the price rise or fall?',
text120: 'Open a simulated account',
text121: 'Click',
text122: 'on one of the buttons, then see the results',
text123: 'Price will rise',
text124: 'Price will fall',
text125: 'Waiting for the result...',
text126: 'Your profit',
text127: 'Sorry, your prediction result is incorrect',
text128: 'Try again',
text129: 'Your profit',
text130: 'Congratulations! Your prediction result is correct',
text131: 'Start making money',
text132: 'Mobile app available anytime',
text133: 'Download our iPhone or Android user-friendly app and start trading!',
text134: 'Available hours',
text135: 'Start Trading',
text136: '3 steps',
text137: 'Open a real account for free in just a few minutes',
text138: 'One-click trading on simulated accounts',
text139: 'Practice',
text140: 'Improve your skills with simulated accounts and training materials',
text141: 'Start your learning with a simulated account',
text142: 'Deposit into your real account and start trading',
text143: 'Over 410 tools, with a minimum trade amount of $10',
text144: 'Go to deposit methods',
text145: 'FAQs',
text146: 'How to master?',
text147: 'Register for a simulated account and practice with it. Simulated accounts are the same as real accounts, but no deposit is required.',
text148: 'How long does it take to process withdrawals?',
text149: `On average, the withdrawal process takes 1 to 5 days from the date of receiving the corresponding user request, depending solely on the number of requests being processed simultaneously. The company always strives to pay directly from the date of receiving the user's request.`,
text150: `What is a trading platform? What is its function?`,
text151: `A trading platform is a software system where users trade (operate) financial instruments provided by this system, and also use information such as the company's quote value, real-time market situation, and company actions.`,
text152: 'Can I trade with my phone?',
text153: 'Our platform adopts the most innovative technology and can be accessed through any computer or smartphone browser.',
text154: 'What is the minimum deposit amount?',
text155: `The advantage of the company's trading platform is that you do not need to deposit large sums into your account. You can start trading with a small investment. The minimum deposit amount is $10.`,
text156: 'Are there any commissions for account deposits or withdrawals?',
text157: 'No. The company does not charge any commissions for account deposits or fund withdrawals.',
text158: 'However, it must be noted that payment systems may charge commissions and use different currency exchange rates.',
text159: 'Go to all questions',
text160: 'Quotex: Innovative Platform',
text161: 'Digital Asset Trading',
text162: 'Open a real account',
text163: 'Partners',
text164: 'Simulated account',
text165: 'Contact Information',
text166: 'Important Questions',
text167: 'Financial Issues',
text168: 'Verification',
text169: 'Regulations',
text170: 'Privacy Policy',
text171: 'Payment Terms',
text172: 'Service Agreement',
text173: 'Risk Warning',
text174: 'Trading Rules',
text175: 'Non-trading Rules',
text176: 'Risk Warning: Trading on a digital asset trading platform and using leverage tools for trading can involve significant risks and may result in the complete loss of your invested capital. Do not invest more than you can afford, and you should understand the risks involved. Using leverage tools for trading is not suitable for most investors. Not trading with leverage tools such as stocks also carries risks, as stock prices can both decrease and increase, which may mean that even if you win, the amount you can gain is much lower than what you invest. Past success does not guarantee future success. Before trading, please consider your trading experience and level, investment objectives, and if necessary, consult an independent financial advisor. Users should determine whether the use of services under the Quotex brand is allowed according to the laws and regulations of their country of residence.'
}