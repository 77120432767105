<template>
  <header class="header page__header">
    <div class="header__brand">
      <a class="header__logo" href="/trade" >
        <svg class="icon-logo-dark"><use xlink:href="../assets/svg/spritemap.svg#icon-logo-dark"></use></svg>
      </a>
      <div class="header__slogan">Web Trading Platform</div>
    </div>
    <div class="header__mobile-menu"></div>
    <div class="header__banner" v-if="$route.path == '/home'">
      <img class="header__banner-bg" src="../assets/image/32dcb5af043db29aeeaacbec3ed925f1.png" alt="Banner bg" />
      <a class="header__banner-container" href="https://qxbroker.com/zh/deposit?code=WELCOME30" >
        <img class="header__banner-icon" src="../assets/image/c76d4349ed58caafbc39b9861d7ad38c.png" alt="Banner icon" />
        <span>{{ $t('text18') }}</span>
        <div class="header__banner-percents">30%</div>
      </a>
      <div class="header__banner-close">
        <svg class="icon-close"><use xlink:href="../assets/svg/spritemap.svg#icon-close"></use></svg>
      </div>
    </div>
    <div class="trading-chart__mobile-select">
      <div class="flags trading-chart__mobile-select__flags">
        <svg class="flag-eur"><use xlink:href="../assets/svg/flags.svg#flag-eur"></use></svg><svg class="flag-chf"><use xlink:href="../assets/svg/flags.svg#flag-chf"></use></svg>
      </div>
      <div>EUR/CHF</div>
      <div class="trading-chart__mobile-select__percent">82%</div>
      <svg class="icon-caret trading-chart__mobile-select__caret"><use xlink:href="../assets/svg/spritemap.svg#icon-caret"></use></svg>
    </div>
    <div class="trading-chart__mobile__payout">
      {{ $t('Your payout:') }}
      <b class="trading-chart__mobile__payout-up">1.82 $</b>
    </div>
    <div class="header-notifications header-notifications-mobile">
      <div class="header-notifications__button">
        <svg class="icon-notification header-notifications__icon"><use xlink:href="../assets/svg/spritemap.svg#icon-notification"></use></svg>
      </div>
    </div>
    <div class="usermenu usermenu-mobile">
      <div class="usermenu-mobile__info">
        <div class="usermenu-mobile__info-levels">
          <svg class="icon-profile-level-standart"><use xlink:href="../assets/svg/spritemap.svg#icon-profile-level-standart"></use></svg>
        </div>
        <div class="usermenu-mobile__info-name">Live</div>
        <div class="usermenu-mobile__info-balance">$0.00</div>
        <div class="usermenu-mobile__info-caret">
          <svg class="icon-caret"><use xlink:href="../assets/svg/spritemap.svg#icon-caret"></use></svg>
        </div>
      </div>
    </div>
    <div class="header__container">
      <div class="header-notifications">
        <div class="header-notifications__button">
          <svg class="icon-notification header-notifications__icon"><use xlink:href="../assets/svg/spritemap.svg#icon-notification"></use></svg>
        </div>
      </div>
      <div class="usermenu" :class="{active:show}">
        <div class="usermenu__info" :class="{active:show}" @click="show = !show">
          <div class="usermenu__info-levels">
            <svg class="icon-profile-level-standart"><use xlink:href="../assets/svg/spritemap.svg#icon-profile-level-standart"></use></svg>
          </div>
          <div class="usermenu__info-wrapper"></div>
          <div class="usermenu__info-text">
            <div class="usermenu__info-name">{{ $t('Real account') }}</div>
            <div class="usermenu__info-balance">$0.00</div>
          </div>
          <div class="usermenu__info-caret">
            <svg class="icon-caret"><use xlink:href="../assets/svg/spritemap.svg#icon-caret"></use></svg>
          </div>
        </div>
        <div class="usermenu__dropdown" v-if="show">
          <ul class="usermenu__select">
            <li class="usermenu__select-item">
              <div class="usermenu__level-info">
                <div class="usermenu__level">
                  <div class="usermenu__level-icon">
                    <svg class="icon-profile-level-standart"><use xlink:href="../assets/svg/spritemap.svg#icon-profile-level-standart"></use></svg>
                  </div>
                  <div class="usermenu__level-block">
                    <div class="usermenu__level-name">{{ $t('standart') }}:</div>
                    <div class="usermenu__level-profit">+0% profit</div>
                  </div>
                </div>
                <div class="usermenu__eye">
                  <svg class="icon-eye"><use xlink:href="../assets/svg/spritemap.svg#icon-eye"></use></svg>
                </div>
              </div>
              <div class="usermenu__block">
                <div class="usermenu__block-info" dir="auto">
                  <div class="usermenu__email">891631422@qq.com</div>
                  <span class="usermenu__number">ID: 40590966</span>
                </div>
              </div>
              <div class="usermenu__currency" dir="auto">
                <div class="usermenu__currency-text">{{ $t('Currency') }}</div>
                <div class="usermenu__currency-code">USD</div>
                <div class="usermenu__currency-button">{{ $t('Change') }}</div>
              </div>
            </li>
            <li class="usermenu__select-item usermenu__select-item--radio active">
              <a aria-current="page" class="usermenu__select-name active" >{{ $t('Real account') }}</a>
              <b class="usermenu__select-balance">$0.00</b>
            </li>
            <li class="usermenu__select-item usermenu__select-item--radio">
              <a class="usermenu__select-name" >{{ $t('Demo account') }}</a>
              <div class="usermenu__select-balance__block" dir="ltr">
                <b class="usermenu__select-balance">$9,998.80</b>
                <div class="usermenu__select-refresh">
                  <svg class="icon-refresh"><use xlink:href="../assets/svg/spritemap.svg#icon-refresh"></use></svg>
                </div>
              </div>
            </li>
          </ul>
          <ul class="usermenu__menu" dir="auto">
            <li class="usermenu__menu-item"><a class="usermenu__menu-link" >{{ $t('Payment') }}</a></li>
            <li class="usermenu__menu-item"><a class="usermenu__menu-link" >{{ $t('Payouts') }}</a></li>
            <li class="usermenu__menu-item"><a class="usermenu__menu-link" >{{ $t('Transactions') }}</a></li>
            <li class="usermenu__menu-item"><a class="usermenu__menu-link" >{{ $t('Trade') }}</a></li>
            <li class="usermenu__menu-item"><a class="usermenu__menu-link" >{{ $t('Profile') }}</a></li>
            <li class="usermenu__menu-logout">
              <a class="usermenu__menu-button usermenu__menu-button--danger" >
                <svg class="icon-logout"><use xlink:href="../assets/svg/spritemap.svg#icon-logout"></use></svg>
                <span>{{ $t('Logout') }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="header__sidebar">
        <a class="button button--success button--small header__sidebar-button deposit"  >
          <div class="button__icon">
            <svg class="icon-plus"><use xlink:href="../assets/svg/spritemap.svg#icon-plus"></use></svg>
          </div>
          <span>{{ $t('Payment') }}</span>
        </a>
        <a class="button button--small header__sidebar-button hide-md-down"  >{{ $t('Payouts') }}</a>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data(){
    return{
      show:false
    }
  }
}

</script>