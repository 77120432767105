export default {
    "+{value}% profit": " +{value}% keuntungan",
    "Selfie verification required": "Pengesahan swafoto diperlukan",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "Untuk membuat langkah-langkah pengesahan akaun yang perlu, kami ingin meminta anda untuk memberi gambar diri sendiri (swafoto) dengan resolusi tinggi sambil memegang dokumen ID anda dengan tarikh semasa dan tandatangan anda pada sehelai kertas. Muka, badan dan kedua-dua tangan anda hendaklah kelihatan.<br><br> Butiran-butiran dokumen hendaklah jelas dan boleh dibaca.",
    "Your documents have been checked, now your identity is verified.": "Dokumen anda telah disemak, identiti anda kini sudah disahkan.",
    "You need fill identity information before verification your profile.": "Anda perlu mengisi maklumat identiti sebelum profil anda disahkan.",
    "Documents verification": "Pengesahan dokumen",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "Pengesahan profil bermaksud penyediaan dokumen rasmi yang mengiktiraf identiti Klien. Prosedur ini boleh dimulakan oleh jabatan keselamatan Syarikat pada bila-bila masa.",
    "Upload Photo": "Muat Naik Gambar",
    "Nickname": "Nama samaran",
    "First Name": "Nama Pertama",
    "Last Name": "Nama Keluarga",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Nama mesti dinyatakan dengan tepat seperti dalam dokumen. Disebabkan maklumat yang salah, akaun boleh dipadam tanpa amaran.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Nama keluarga mesti dinyatakan dengan tepat seperti dalam dokumen. Disebabkan maklumat yang salah, akaun boleh dipadam tanpa amaran.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "Tarikh lahir anda tidak dapat ditukar lagi. Jika tarikh lahir yang dinyatakan adalah salah, anda tidak akan dapat mengeluarkan dana daripada akaun anda.",
    "Empty": "Kosong",
    "Date of birth": "Tarikh lahir",
    "Unverified": "Belum Disahkan",
    "Verified": "Disahkan",
    "Resend": "Hantar semula",
    "Country": "Negara",
    "Address": "Alamat",
    "Change Identity Info": "Tukar Info Identiti",
    "Identity Info": "Info Identiti",
    "Two-factor authentication on login": "Penyahihan dua faktor semasa log masuk",
    "Two-factor authentication on withdrawal": "Penyahihan dua faktor semasa pengeluaran",
    "Active": "Aktif",
    "Inactive": "Tidak Aktif",
    "Setup": "Penyediaan",
    "Two-step verification": "Pengesahan dua langkah",
    "Security": "Keselamatan",
    "Google Authenticator": "Google Authenticator",
    "Scan the QR code": "Imbas kod QR",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Kami memandang serius tentang keselamatan anda, inilah sebab kami perlukan pengesahan 2 langkah. Ini cara untuk menetapkannya:",
    "Install ": "Pasang",
    "Can't scan?": "Tidak dapat mengimbas?",
    "Click here.": "Klik di sini.",
    "Enter this code into Google Authenticator.": "Masukkan kod ini ke Google Authenticator.",
    "Back to QR code.": "Kembali ke kod QR.",
    "Enter the 6-digit code from Authenticator below": "Masukkan kod 6 digit daripada Authenticator di bawah",
    "Enter 6-digit code...": "Masukkan kod 6 digit…",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "Bagus. Anda telah berjaya membenarkan pengesahan 2 langkah. Pada daftar masuk yang berikutnya, anda akan digesa untuk memberi kod.",
    "Code": "Kod",
    "contact support": "sokongan perhubungan",
    "If you ever lose access to your 2-step verification device, please ": "Jika anda kehilangan akses ke peranti 2 langkah pengesahan anda, sila",
    "Yes, activate": "Ya, aktifkan",
    "Cancel": "Batalkan",
    "Close": "Tutup",
    "Language": "Bahasa",
    "Timezone": "Zon masa",
    "Delete Account": "Padam Akaun",
    "Verification Credit / Debit Cards": "Pengesahan Kad Kredit / Debit",
    "Add New Card": "Tambah Kad Baharu",
    "Wait for loading...": "Tunggu untuk memuatkan…",
    "You don’t have any credit / debit cards for verification": "Anda tidak mempunyai sebarang kad kredit / debit untuk pengesahan",
    "Verificate": "Sahkan",
    "Verification Card": "Kad Pengesahan",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "Sila letakkan ID anda (menunjukkan gambar, nama dan tarikh lahir) berserta bahagian hadapan kad bank anda (menunjukkan nombor, tarikh tamat tempoh dan nama anda) pada helaian kosong A4.<br> Sila tulis \"Quotex.io\" dan juga tandatangan anda.<br> Ambil gambar dan muat naik.",
    "Card:": "Kad:",
    "Type:": "Jenis:",
    "Your ID and bank card on an A4 sheet": "ID dan kad bank anda pada helaian A4",
    "Yes, confirm": "Ya, sahkan",
    "Click or drag the image": "Klik atau lepaskan imej",
    "Card": "Kad",
    "Documents": "Dokumen",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "Pertama, isi bahagian \"Info Identiti\" dalam Profil anda, dan klik pada butang \"Tukar Info Identiti\" untuk menyimpan perubahan. Selepas itu, anda akan dapat menambah kad baharu.",
    "Specify the card details for further operations for depositing and debiting funds.": "Nyatakan butiran kad untuk operasi selanjutnya bagi membuat deposit dan mendebitkan dana.",
    "Card number:": "Nombor kad:",
    "Expiry month:": "Bulan tamat tempoh:",
    "Expiry year:": "Tahun tamat tempoh:",
    "Cardholder name:": "Nama pemegang kad:",
    "Next": "Seterusnya",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "Sila letakkan tandatangan anda dan tarikh semasa pada sehelai kertas, letakkan dokumen anda berserta kad bank, ambil gambar dan muat naik dalam borang di bawah.",
    "Do you have a two-sided document?": "Anda mempunyai dokumen dua bahagian?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "Muat naik bahagian belakang sebagai fail berasingan, juga pada sehelai kertas dengan tarikh dan tandatangan anda.",
    "Submit only valid documents": "Hanya hantar dokumen yang sah",
    "Do not edit or crop photo": "Jangan sunting atau potong gambar",
    "Do not upload other people's documents": "Jangan muat naik dokumen orang lain",
    "Read all": "Baca semua",
    "Uploading files": "Memuat naik fail",
    "For single-sided documents, upload only the front side.": "Untuk dokumen satu bahagian sahaja, muat naik bahagian hadapan sahaja.",
    "Back": "Kembali",
    "Add a card": "Tambah kad",
    "File Requirements": "Syarat-syarat Fail",
    "Scanned images are not accepted for card verification": "Imej imbasan tidak diterima untuk pengesahan kad",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "Kami hanya menerima fail dengan pasport sivil biasa anda atau ID Kebangsaan (kedua-dua bahagian hendaklah dimuat naik sebagai fail berasingan)",
    "Driving license CANNOT be accepted": "Lesen memandu TIDAK BOLEH diterima",
    "External (foreign/international/travel) passport will not be accepted as well": "Pasport luar (asing/antarabangsa/perjalanan) juga tidak akan diterima",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "Jika dokumen mempunyai 2 bahagian, anda perlu memuat naik bahagian belakang sebagai fail berasingan pada helaian kertas yang sama, dengan tarikh semasa dan tandatangan anda",
    "All information in the document and on the card should be clear and readable on the photo": "Semua maklumat dalam dokumen dan kad hendaklah jelas dan boleh dibaca dalam gambar",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "Kedua-dua dokumen dan kad anda mesti muat dalam gambar sepenuhnya, bahagian tepi tidak seharusnya terpotong",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "Gambar tersebut tidak seharusnya silau atau mempunyai kecacatan lain yang boleh menjadikan data pada dokumen dan kad sukar dibaca",
    "Expired documents and cards will be rejected automatically": "Dokumen-dokumen dan kad-kad yang sudah tamat tempoh akan ditolak secara automatik",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "Jika kad anda tidak mempunyai nama anda di atasnya, anda perlu memuat naik syot layar penyata bank yang menunjukkan nombor kad anda dan nama anda. Dalam sesetengah kes, menulis sendiri nama anda pada helaian kertas yang sama adalah dibenarkan",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "Jangan sembunyikan elemen-elemen dokumen yang mengandungi data yang diperlukan untuk pengesahan (nama, tarikh lahir, gambar)",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "Menyembunyikan 8 digit nombor kad adalah dibenarkan. 4 digit pertama dan 4 digit terakhir hendaklah jelas kelihatan",
    "CVV code should be hidden at all times, in every case": "Kod CVV hendaklah disembunyikan pada setiap masa dalam setiap keadaan",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "Apa-apa suntingan pada fail-fail anda adalah dilarang. Hanya gambar dan imej-imej imbasan yang tidak disunting boleh diterima. Menyunting maklumat peribadi dalam dokumen anda (nama, tarikh lahir, dll.) adalah tidak dibenarkan",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "Gambar mestilah sebahagian daripada dokumen (digam atau dicetak). Gambar yang dilampirkan berasingan tidak akan diterima",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "Anda hanya dibenarkan memuat naik dokumen-dokumen dan kad-kad dalam nama yang dinyatakan pada profil (nama akaun anda didaftarkan)",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "Kami mengingatkan anda bahawa sekiranya maklumat palsu dihantar, akaun boleh digantung/disekat, sehingga keadaan dijelaskan",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "Syarikat berhak untuk meminta anda menyediakan sebarang dokumen-dokumen tambahan untuk pengesahan akaun (termasuk swafoto anda bersama dokumen) pada bila-bila masa",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "Untuk semua soalan yang berkaitan dengan pengesahan dokumen, anda boleh mencipta tiket sokongan pada platform (bahagian \"Bantuan\" adalah butang pertama dengan ikon tanda soal berkelip-kelip, di bahagian panel paling kiri)",
    "Profile": "Profil",
    "Deposit": "Deposit",
    "Withdrawal": "Pengeluaran",
    "Transactions": "Transaksi",
    "Trades": "Perdagangan",
    "Market": "Pasaran",
    "Analytics": "Analisis",
    "My current currency": "Mata wang semasa saya",
    "Available for withdrawal": "Boleh dikeluarkan",
    "In the account": "Dalam akaun",
    "Percentage of turnover {percent}%": "Peratus perolehan {percent}%",
    "Cashback {percent}%": "Pulangan tunai {percent}%",
    "Currency:": "Mata wang:",
    "Change": "Tukar",
    "Live Account": "Akaun Langsung",
    "Daily budget limit": "Had peruntukan harian",
    "The value cannot exceed your total budget": "Nilai tidak boleh melebihi jumlah peruntukan anda",
    "Demo Account": "Akaun Demo",
    "Apply": "Gunakan",
    "Edit": "Sunting",
    "New value": "Nilai baharu",
    "Confirm": "Sahkan",
    "Logout": "Log keluar",
    "No, cancel": "Tidak, batalkan",
    "Exchange Form": "Borang Pertukaran",
    "My Currency:": "Mata Wang Saya:",
    "New Currency:": "Mata Wang Baharu:",
    "You are exchanging": "Anda sedang membuat penukaran",
    "You will receive": "Anda akan terima",
    "Exchange Fee": "Yuran Pertukaran",
    "Password changed": "Kata laluan diubah",
    "Old password": "Kata laluan lama",
    "New password": "Kata laluan baharu",
    "Confirm new password": "Sahkan kata laluan baharu",
    "Change Password": "Tukar Kata Laluan",
    "Confirm password must be equal new password value": "Kata laluan disahkan mesti sama dengan nilai kata laluan baharu",
    "About us": "Tentang kami",
    "Support": "Sokongan",
    "Help": "Bantuan",
    "2-step verification": "Pengesahan 2 langkah",
    "Please enter the PIN-code we've just sent to your email": "Sila masukkan kod PIN yang baru sahaja kami hantar ke e-mel anda",
    "Please enter the 6-digit code from your authentication app": "Sila masukkan kod 6 digit daripada aplikasi penyahihan anda",
    "The First Name field is required": "Ruangan Nama Pertama diperlukan",
    "The Last Name field is required": "Ruangan Nama Keluarga diperlukan",
    "Verification of documents": "Pengesahan dokumen-dokumen",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "Sila muat naik gambar berwarna atau imej imbasan pasport sivil biasa anda, lesen memandu atau kad Identiti Negara.",
    "Upload Documents": "Muat Naik Dokumen",
    "Why?": "Mengapa?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "Anda mahu mengesahkan pemadaman akaun anda tanpa undur semula? Tindakan ini tidak boleh diundur semula.",
    "Set up 2-step verification": "Tetapkan pengesahan 2 langkah",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "Malangnya, anda tidak boleh memuat naik fail ke bahagian ini. <br> Sila rujuk mesej daripada kumpulan Sokongan dan lampirkan fail yang diperlukan ke tiket.",
    "The uploaded document does not match the requirements": "Dokumen yang telah dimuat naik tidak sepadan dengan syarat-syarat",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "Dokumen-dokumen berikut layak untuk pengenalpastian: ID, pasport, pasport antarabangsa atau lesen memandu.",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "Imej hendaklah berkualiti tinggi, data boleh dibaca dengan senang dan bahagian tepi dokumen tidak terpotong.",
    "Balance from {amount}": "Baki daripada {amount}",
    "Not verified": "Tidak disahkan",
    "Clear": "Kosongkan",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "Bagi mengelakkan sekatan perkhidmatan, sila lengkapkan proses pengesahan: isi data peribadi anda dan muat naik dokumen yang diperlukan.",
    "Get verified": "Sahkan",
    "I got it!": "Saya faham!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "Pengesahan AADHAAR",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "Sila muat naik gambar berwarna Aadhaar fizikal anda (hanya bahagian hadapan berserta gambar dan nombor dokumen anda).",
    "Send AADHAAR": "Hantar AADHAAR",
    "AADHAAR number:": "Nombor AADHAAR:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "Sila muat naik gambar berwarna Aadhaar fizikal anda (hanya bahagian hadapan berserta gambar dan nombor dokumen anda).☝ Tiada versi elektronik yang akan diterima.",
    "You have already uploaded {number} files. Please wait until they are checked.": "Anda sudah muat naik {number} fail. Sila tunggu sehingga ia disemak.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "Sila muat naik gambar berwarna untuk dokumen anda: Untuk RG - kedua-dua bahagian perlu dimuat naik sebagai fail berasingan. Untuk CNH - hanya bahagian hadapan berserta gambar anda. Dokumen hendaklah sah (kurang 10 tahun dari tarikh dikeluarkan), dan mesti menunjukkan nombor CPF anda. *sekiranya tiada CPF dalam dokumen anda - sila muat naik gambar berasingan Comprovante de Inscrição no CPF, atau tangkapan layar penyata bank anda yang menunjukkan nama dan nombor CPF anda. ** Sila jangan muat naik sebarang versi elektronik. Ia tidak akan diterima.",
    "Submit for verification": "Hantar untuk pengesahan",
    "Settings": "Tetapan",
    "Please upload a color photograph of the front of your national identity card with your photo.": "Sila muat naik gambar berwarna bahagian hadapan kad pengenalan negara anda berserta gambar anda.",
    "Template": "Templat",
    "Light Mode": "Mod Cerah",
    "Twilight": "Samar-Samar",
    "Full Night": "Malam Gelap",
    "Grid's opacity": "Kelegapan grid",
    "Auto-scrolling": "Tatal automatik",
    "Automatic graphic scrolling": "Tatal grafik automatik",
    "1-click trade": "Perdagangan 1 klik",
    "Open trades without confirmation": "Buka perdagangan tanpa pengesahan",
    "Use timezone": "Gunakan zon masa",
    "Use your timezone data": "Gunakan data zon masa anda",
    "Graph colors": "Warna graf",
    "Up Trend": "Trend Naik",
    "Down Trend": "Trend Turun",
    "Platform": "Platform",
    "Short order label": "Label pesanan pendek",
    "Use short order element mode": "Gunakan mod elemen pesanan pendek",
    "Perfomance Mode": "Mod Prestasi",
    "Use optimized rendering for chart and candles": "Gunakan lukisan yang optimum untuk carta dan lilin",
    "Background": "Latar Belakang",
    "Choose file": "Pilih fail",
    "Max size — 2 Mb": "Saiz maks - 2 Mb",
    "Sign up": "Daftar",
    "Sign in": "Daftar masuk",
    "Budget and limits": "Peruntukan dan had",
    "Daily limit": "Had harian",
    "Not set": "Tidak ditetapkan",
    "Total funds": "Jumlah dana",
    "Top up": "Tambah nilai",
    "Set up": "Tetapkan",
    "Set a daily limit on trading to reduce financial risks.": "Tetapkan had harian untuk perdagangan bagi mengurangkan risiko kewangan.",
    "Limit amount": "Amaun had",
    "More": "Lagi",
    "Leader Board": "Papan Skor",
    "Signals": "Isyarat",
    "Notifications": "Pemberitahuan",
    "mark all as read": "tanda semua sudah dibaca",
    "No notifications": "Tiada pemberitahuan",
    "No more notifications": "Tiada lagi pemberitahuan",
    "Your position:": "Kedudukan anda:",
    "How does this rating work?": "Bagaimana cara rating ini berfungsi?",
    "of the Day": "Hari Ini",
    "Nothing has been found for current query, try another asset name.": "Tiada apa yang ditemui untuk pertanyaan ini, cuba nama aset yang lain.",
    "My rating in the Leader Board": "Rating saya di Papan Skor",
    "Participants can only be those who trade in live mode": "Peserta hanyalah mereka yang berdagang dalam mod langsung",
    "Your position depends on the amount of earned money": "Kedudukan anda bergantung pada amaun wang yang telah dijana",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "Dikira setiap hari, dari 00:00 UTC hingga 23:59 UTC",
    "All traders on our platform participate in this rating. Main features of the rating:": "Semua pedagang di platform kami menyertai rating ini. Ciri-ciri utama rating:",
    "How does it work?": "Bagaimana ia berfungsi?",
    "Trading signals": "Isyarat perdagangan",
    "Past signals": "Isyarat terdahulu",
    "What’s it?": "Apa itu?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "Isyarat perdagangan bukanlah arahan langsung untuk berdagang, tetapi adalah saranan penganalisis. Ia tidak seharusnya menggantikan analisis pasaran bebas.",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "Anda mempunyai purata masa kira-kira 10 minit untuk memanfaatkan isyarat perdagangan. Selepas masa ini, sebut harga boleh berubah dan memberi kesan yang tidak dijangka kepada hasil perdagangan.",
    "Continue": "Teruskan",
    "Duration": "Tempoh",
    "Return back to FAQ": "Kembali ke Soalan Lazim",
    "Return back to Help": "Kembali ke Bantuan",
    "How to withdraw money from the account?": "Bagaimana cara untuk mengeluarkan wang daripada akaun?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "Prosedur untuk mengeluarkan modal adalah sangat mudah dan dilakukan melalui akaun individu anda.",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "Kaedah yang anda pilih untuk membuat deposit ke akaun juga merupakan kaedah untuk mengeluarkan dana (lihat soalan \"Bagaimana cara membuat deposit?\").",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "Sebagai contoh, jika anda telah membuat deposit ke akaun menggunakan sistem pembayaran Visa, akan juga akan membuat pengeluaran melalui sistem pembayaran Visa.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "Apabila berkenaan pengeluaran amaun yang cukup besar, Syarikat mungkin meminta pengesahan (pengesahan diminta mengikut budi bicara mutlak Syarikat), itulah sebabnya penting untuk anda mendaftar akaun secara individu untuk diri sendiri bagi mengesahkan hak anda pada bila-bila masa.",
    "What is the minimum withdrawal amount?": "Berapakah amaun minimum pengeluaran?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "Amaun minimum pengeluaran bermula dari 10 USD untuk kebanyakan sistem pembayaran.<br>Untuk mata wang kripto, amaun ini bermula dari 50 USD (mungkin lebih tinggi untuk mata wang tertentu cth. Bitcoin).",
    "Do I need to provide any documents to make a withdrawal?": "Perlukah saya menyediakan apa-apa dokumen untuk membuat pengeluaran?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "Biasanya, dokumen-dokumen tambahan untuk mengeluarkan dana tidak diperlukan. Tetapi, mengikut budi bicara Syarikat, ia mungkin meminta anda untuk mengesahkan data peribadi dengan meminta dokumen-dokumen tertentu. Ini selalunya dilakukan untuk menghalang aktiviti-aktiviti yang berkaitan dengan perdagangan haram, penipuan kewangan serta penggunaan dana yang diperoleh secara haram.",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "Senarai dokumen tersebut adalah minimum, dan operasi untuk menyediakannya tidak akan mengambil terlalu banyak masa dan usaha anda.",
    "How long does it take to withdraw funds?": "Berapa masa yang diambil untuk mengeluarkan dana?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "Secara purata, prosedur pengeluaran mengambil masa dari sehari hingga lima hari dari tarikh permohonan Klien diterima dan hanya bergantung pada jumlah permohonan yang diproses secara serentak. Syarikat sentiasa cuba untuk membuat pembayaran terus pada hari permohonan Klien diterima.",
    "Is there any fee for depositing or withdrawing funds from the account?": "Adakah sebarang yuran akan dikenakan untuk membuat deposit atau mengeluarkan dana daripada akaun?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "Tidak. Syarikat tidak mengenakan sebarang yuran, sama ada untuk operasi deposit atau pengeluaran.",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "Akan tetapi, anda boleh mempertimbangkan bahawa sistem pembayaran boleh mengenakan yuran mereka dan menggunakan kadar penukaran mata wang dalaman.",
    "Can I close my account? How to do it?": "Bolehkah saya menutup akaun sendiri? Bagaimana caranya?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "Anda boleh membuang akaun dalam Akaun Individu anda dengan mengklik butang \"Padam Akaun\" yang terletak di bahagian bawah halaman profil.",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "Jika saya tersilap semasa mengisi data dalam akaun individu saya, bagaimana cara untuk memperbetulkannya?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "Anda perlu menghubungi khidmat sokongan teknikal di laman web Syarikat dan edit profil.",
    "What data is required to register on the Company website?": "Apakah data yang diperlukan untuk mendaftar di laman web Syarikat?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "Untuk menjana wang daripada opsyen digital, anda mestilah terlebih dahulu membuka akaun yang membolehkan anda membuat perdagangan. Untuk berbuat demikian, anda perlu mendaftar di laman web Syarikat.",
    "The registration process is simple and does not take much time.": "Proses pendaftaran adalah ringkas dan tidak mengambil masa yang lama.",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "Anda dikehendaki untuk mengisi soal jawab dalam borang yang dicadangkan. Anda akan diminta untuk memasukkan maklumat berikut:",
    "name (in English)": "nama (dalam bahasa Inggeris)",
    "email address (indicate the current, work, address)": "alamat e-mel (nyatakan e-mel semasa, kerja)",
    "telephone (with a code, for example, + 44123 ....)": "telefon (dengan kod, sebagai contoh, + 44123 ….)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "kata laluan yang anda akan gunakan pada masa akan datang untuk masuk ke dalam sistem (untuk mengurangkan risiko akses tidak dibenarkan ke akaun individu anda, kami mencadangkan anda supaya mencipta kata laluan yang rumit menggunakan huruf kecil, huruf besar dan nombor. Jangan dedahkan kata laluan kepada pihak ketiga)",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "Selepas mengisi soal jawab, anda akan ditawarkan dengan pelbagai cara untuk membuat deposit ke akaun untuk berdagang.",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "Apakah mata wang yang digunakan untuk membuka akaun Klien? Bolehkah saya menukar mata wang akaun Klien?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "Secara lalai, akaun perdagangan dibuka dalam dolar AS. Tetapi untuk keselesaan, anda boleh membuka beberapa akaun dalam mata wang berbeza.",
    "A list of available currencies can be found on your profile page in your Client's account.": "Senarai mata wang tersedia boleh dilihat pada halaman profil anda di akaun Klien anda.",
    "What are digital options?": "Apa itu opsyen digital?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "Opsyen - instrumen kewangan derivatif berdasarkan apa-apa aset pendasar (ia boleh menjadi saham, pasangan mata wang, minyak, dll.)",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "OPSYEN DIGITAL - opsyen bukan standard yang digunakan untuk membuat keuntungan pada pergerakan harga aset-aset tersebut bagi tempoh masa tertentu.",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "Opsyen digital, bergantung pada istilah yang telah dipersetujui oleh pihak-pihak yang terlibat dalam transaksi, mengikut masa yang telah ditetapkan oleh pihak-pihak tersebut, memberi pendapatan tetap (perbezaan antara pendapatan perdagangan dan harga aset) atau kerugian (mengikut amaun nilai aset).",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "Oleh sebab opsyen digital dibeli terlebih dahulu pada harga tetap, saiz keuntungan serta saiz bakal kerugian sudah diketahui sebelum perdagangan lagi.",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "Satu lagi ciri urus niaga ini ialah had masa. Mana-mana opsyen mempunyai tempoh tersendiri (masa tamat tempoh atau masa tamat).",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "Tanpa mengira tahap perubahan dalam harga aset pendasar (amaun ia menjadi lebih tinggi atau rendah), dalam kes opsyen yang menang, pembayaran tetap sentiasa dibuat. Oleh itu, risiko anda hanya dihadkan oleh amaun opsyen tersebut diperoleh.",
    "What is the expiration period of a trade?": "Apakah waktu tamat tempoh perdagangan?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "Tempoh masa tamat adalah masa di mana perdagangan dianggap selesai (ditutup) dan hasilnya akan dijumlahkan secara automatik.",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "Apabila menyimpulkan perdagangan dengan opsyen digital, anda menentukan masa pelaksanaan transaksi secara bebas (1 minit, 2 jam, bulan, dll.).",
    "What is the gist of digital options trading?": "Apakah inti pati perdagangan opsyen digital?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "Ia sudah diketahui bahawa opsyen digital adalah jenis instrumen kewangan derivatif yang paling ringkas. Untuk menjana wang dalam pasaran opsyen digital, anda tidak perlu meramal nilai harga pasaran aset yang boleh dicapainya.",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "Prinsip proses perdagangan dikurangkan kepada penyelesaian satu tugasan tunggal sahaja - harga aset akan meningkat atau turun pada masa kontrak dilaksanakan.",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "Aspek bagi opsyen tersebut adalah bahawa ia tidak penting untuk anda sekiranya harga aset pendasar naik seratus mata atau satu sahaja dari saat perdagangan disimpulkan kepada penutupannya. Apa yang penting untuk anda ialah menentukan arah pergerakan harga ini sahaja.",
    "If your prognosis is correct, in any case you get a fixed income.": "Jika prognosis anda tepat, dalam apa jua keadaan, anda akan menerima pendapatan tetap.",
    "What are the possible results of the placed trades?": "Apakah kemungkinan hasil bagi perdagangan yang dibuat?",
    "There are three possible outcomes in the digital options market:": "Terdapat tiga kemungkinan hasil dalam pasaran opsyen digital:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "sekiranya prognosis anda dalam menentukan arah pergerakan harga aset pendasar adalah tepat, anda akan menerima pendapatan.",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "jika pada masa opsyen disimpulkan, ramalan anda ternyata salah, anda mengalami kerugian yang dihadkan oleh saiz nilai aset (contohnya, anda hanya kerugian pelaburan anda).",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "jika hasil perdagangan itu sifar (harga aset pendasar tidak berubah, opsyen disimpulkan pada harga ia dibeli), pelaburan anda akan dipulangkan. Dengan itu, tahap risiko anda sentiasa dihadkan hanya oleh saiz nilai aset.",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "Adakah platform perdagangan anda mempunyai akaun demo untuk memahami proses perdagangan opsyen digital tanpa membelanjakan wang sendiri?",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "Ya. Untuk membangunkan kemahiran perdagangan dan menguji keupayaan platform perdagangan Syarikat, anda boleh menggunakan akaun demo (percuma). Ini merupakan simulator yang membenarkan anda untuk mencubanya terlebih dahulu dan kemudian beralih kepada perdagangan sebenar. Akaun demo tersebut juga sesuai untuk pedagang berpengalaman meningkatkan tahap profesional mereka.",
    "The balance of such an account is 10,000 units.": "Baki akaun tersebut ialah 10,000 unit.",
    "What determines profit size?": "Apakah yang menentukan saiz keuntungan?",
    "There are several factors that affect the size of your profit:": "Terdapat beberapa faktor yang mempengaruhi saiz keuntungan anda:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "kecairan aset yang anda pilih dalam pasaran (semakin tinggi permintaan aset tersebut dalam pasaran, semakin banyak keuntungan yang anda akan terima)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "masa perdagangan (kecairan aset pada waktu pagi dan kecairan aset pada waktu tengah hari boleh menjadi sangat berbeza)",
    "tariffs of a brokerage company": "tarif syarikat broker",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "perubahan dalam pasaran (peristiwa ekonomi, perubahan dalam sebahagian aset kewangan, dll.)",
    "What are the varieties of digital options?": "Apakah kepelbagaian opsyen digital?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "Dengan membuat perdagangan opsyen, anda mesti pilih aset pendasar yang akan mendasari opsyen. Ramalan anda akan dilakukan pada aset ini.",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "Mudah sahaja, dengan membeli kontrak digital, anda sebenarnya bertaruh pada pergerakan harga aset pendasar tersebut.",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "Aset pendasar adalah \"item\" yang harganya diambil kira apabila menamatkan perdagangan. Sebagai aset pendasar opsyen digital, produk yang menjadi rebutan di pasaran selalunya bertindak. Terdapat empat jenis daripadanya:",
    "securities (shares of world companies)": "sekuriti (saham syarikat-syarikat dunia)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "pasangan mata wang (EUR / USD, GBP / USD, dll)",
    "raw materials and precious metals (oil, gold, etc.)": "bahan mentah dan logam berharga (minyak, emas, dll.)",
    "indices (S&P 500, Dow, dollar index, etc.)": "indeks (S&P 500, Dow, indeks dolar, dll)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "Aset pendasar universal tidak wujud. Dengan memilihnya, anda hanya dibimbing oleh ilmu sendiri, gerak hati dan pelbagai jenis maklumat analisis, serta analisis pasaran bagi instrumen kewangan tertentu.",
    "What is a trading platform and why is it needed?": "Apa itu platform perdagangan dan mengapa ia diperlukan?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "Platform perdagangan - kompleks perisian yang membenarkan Klien menjalankan perdagangan (operasi) menggunakan instrumen kewangan yang berbeza. Ia juga mempunyai akses kepada pelbagai maklumat seperti nilai sebut harga, kedudukan pasaran masa nyata, tindakan Syarikat, dll.",
    "How to learn quickly how to make money in the digital options market?": "Bagaimana untuk belajar dengan cepat tentang cara menjana wang dalam pasaran opsyen digital?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "membangunkan strategi perdagangan sendiri, di mana bilangan ramalan yang dibuat dengan tepat adalah maksimum, dan ikut strategi tersebut",
    "diversify your risks": "pelbagaikan risiko anda",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "Dalam membangunkan strategi serta mencari opsyen kepelbagaian, pemantauan pasaran, mengkaji maklumat analisis dan statistik yang boleh diperoleh daripada pelbagai sumber (sumber Internet, pendapat pakar, penganalisis dalam bidang ini, dll.) akan membantu anda, salah satunya ialah laman web Syarikat.",
    "Is the download of the program to a computer or smartphone required?": "Adakah muat turun program ke komputer atau telefon pintar diperlukan?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "Tidak, ia tidak perlu. Anda hanya perlu mendaftar di laman web Syarikat dalam borang yang dipaparkan dan membuka akaun individu.",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "Sebanyak mana Syarikat membayar keuntungan kepada Klien sekiranya perdagangan itu berjaya?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "Syarikat menjana pendapatan bersama pelanggan. Oleh yang demikian, ia juga sangat berminat dalam perkongsian transaksi menguntungkan berbanding perkongsian transaksi yang tidak menguntungkan disebabkan fakta bahawa Syarikat memiliki peratusan pembayaran untuk strategi perdagangan berjaya yang dipilih oleh Klien.",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "Selain itu, perdagangan yang dijalankan oleh Klien akan membentuk volum perdagangan Syarikat yang dipindahkan kepada broker atau pertukaran, yang seterusnya dimasukkan dalam kelompok penyedia kecairan yang bersama-sama membawa kepada peningkatan dalam kecairan pasaran itu sendiri.",
    "What is account verification?": "Apa itu pengesahan akaun?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "Pengesahan dalam opsyen digital ialah pengesahan Klien mengenai data peribadi miliknya dengan memberi dokumen-dokumen tambahan kepada Syarikat. Syarat-syarat pengesahan untuk Klien adalah mudah, dan senarai dokumen juga minimum. Sebagai contoh, Syarikat mungkin meminta:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "berikan salinan imbasan berwarna untuk halaman pertama pasport Klien (halaman pasport dengan gambar)",
    "identify with the help of a \"selfie\" (photograph of himself)": "kenal pasti dengan bantuan \"swafoto\" (gambar diri sendiri)",
    "confirm the address of registration (residence) of the Client, etc": "sahkan alamat pendaftaran (tempat tinggal) Klien, dll",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "Syarikat boleh meminta apa-apa dokumen jika pengenalpastian lengkap Klien dan data yang dimasukkan olehnya tidak dapat dilakukan oleh Syarikat.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "Selepas salinan elektronik dokumen dihantar ke Syarikat, Klien perlu menunggu beberapa hari untuk Syarikat mengesahkan data yang telah diberikan.",
    "How to understand that I need to go through account verification?": "Bagaimana cara untuk memahami bahawa saya perlu melalui pengesahan akaun?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "Jika anda perlu lulus pengesahan, anda akan terima pemberitahuan melalui e-mel dan / atau pemberitahuan SMS.",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "Pada masa yang sama, Syarikat menggunakan butiran perhubungan yang anda nyatakan dalam soal selidik semasa pendaftaran (khususnya, alamat e-mel dan nombor telefon). Oleh itu, berhati-hati dan berikan maklumat yang relevan dan betul.",
    "How do I know that I successfully passed verification?": "Bagaimana saya boleh mengetahui sekiranya pengesahan saya diluluskan?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "Anda akan menerima pemberitahuan melalui e-mel dan / atau SMS tentang proses pengesahan akaun yang sudah selesai dan keupayaan untuk meneruskan operasi di platform perdagangan Syarikat.",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "Adakah mungkin untuk menyatakan data orang lain (palsu) apabila mendaftar di laman web?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "Tidak. Klien membuat pendaftaran kendiri di laman web Syarikat, memberi maklumat yang lengkap dan tepat tentang diri sendiri mengenai isu-isu yang ditanya dalam borang pendaftaran dan memastikan maklumat ini kekal terkini.",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "Sekiranya perlu untuk menjalankan pelbagai jenis pemeriksaan identiti Klien, Syarikat boleh meminta dokumen atau menjemput klien ke pejabatnya.",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "Jika data yang dimasukkan dalam ruang pendaftaran tidak sepadan dengan data dokumen yang dihantar, profil individu anda boleh disekat.",
    "How long does the verification process take?": "Berapa lama masa yang diambil untuk proses pengesahan?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "Tidak lebih daripada 5 (lima) hari bekerja dari tarikh Syarikat menerima dokumen-dokumen yang diminta.",
    "Is there a minimum amount that I can deposit to my account at registration?": "Adakah terdapat amaun minimum yang boleh saya depositkan ke akaun semasa pendaftaran?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "Kelebihan platform perdagangan Syarikat ini adalah anda tidak perlu membuat amaun deposit yang banyak ke akaun anda. Anda boleh mula berdagang dengan melaburkan amaun wang yang kecil. Deposit minimum ialah 10 AS dolar.",
    "How can I deposit?": "Bagaimana cara membuat deposit?",
    "It is very easy to do. The procedure will take a couple of minutes.": "Ia sangat mudah. Prosedur hanya akan mengambil masa beberapa minit.",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "Buka tetingkap pelaksanaan perdagangan dan klik butang hijau \"Deposit\" di sudut kanan atas tab tersebut.",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "Anda juga boleh membuat deposit ke akaun melalui Akaun Peribadi anda dengan mengklik butang \"Deposit\" di profil akaun.",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "Selepas itu, anda perlu memilih kaedah deposit ke akaun (Syarikat menawarkan banyak kaedah mudah yang disediakan untuk Klien dan dipaparkan dalam akaun peribadi masing-masing).",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "Seterusnya, nyatakan mata wang bagi amaun yang akan didepositkan dan mata wang akaun itu sendiri.",
    "Enter the amount of the deposit.": "Masukkan amaun deposit.",
    "Fill out the form by entering the requested payment details.": "Isi borang dengan memasukkan butiran pembayaran yang diminta.",
    "Make a payment.": "Buat pembayaran.",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "Perlukah saya membuat deposit ke akaun platform perdagangan dan sekerap mana saya perlu melakukannya?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "Untuk berdagang opsyen digital, anda perlu membuka akaun individu. Untuk memuktamadkan perdagangan sebenar, anda sememangnya perlu membuat deposit mengikut amaun anda membeli opsyen tersebut.",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "Anda boleh mula berdagang tanpa wang tunai, hanya menggunakan akaun latihan syarikat (akaun demo). Akaun tersebut adalah percuma dan dicipta untuk menunjukkan fungsi platform perdagangan. Dengan bantuan akaun tersebut, anda boleh berlatih mendapatkan opsyen digital, memahami prinsip asas perdagangan, menguji pelbagai kaedah dan strategi, atau menilai tahap gerak hati anda.",
    "What is the minimum deposit amount?": "Apakah amaun minimum deposit?",
    "{number} questions": "{number} soalan",
    "Verification": "Pengesahan",
    "Payment": "Pembayaran",
    "Payouts": "Bayaran",
    "Ask a specialist": "Tanya pakar",
    "FAQ": "Soalan Lazim",
    "Didn't find an answer to your question?": "Anda tidak temui jawapan kepada soalan anda?",
    "Open knowledge base": "Buka pusat ilmu",
    "Contact support": "Hubungi sokongan",
    "Open FAQ page": "Buka halaman Soalan Lazim",
    "New message": "Mesej baharu",
    "Tournaments": "Kejohanan",
    "To end": "Untuk menamatkan",
    "Until start": "Sehingga mula",
    "Front side": "Bahagian hadapan",
    "Back side (if any)": "Bahagian belakang (jika ada)",
    "Tourna-ments": "Pertandingan",
    "Deletion of Account and Personal Data": "Pemadaman Akaun dan Data Peribadi",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "Dengan memadam akaun anda dan data peribadi, anda akan kehilangan akses secara tetap ke akaun anda di platform Quotex. Ingat, data anda akan dipadam tanpa boleh diperoleh semula dan ia mustahil untuk memulihkan akaun anda kemudian!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "NOTA: Sila lengkapkan semua perdagangan terbuka dan pesanan belum selesai sebelum anda memadam akaun sendiri.",
    "Request Deletion": "Mohon Pemadaman",
    "To delete your account, please contact support service": "Untuk memadam akaun anda, sila hubungi khidmat sokongan",
    "Two-factor authorization via Email": "Kebenaran dua faktor melalui E-mel",
    "This feature is for experienced users who are familiar with Google Authenticator.": "Ciri ini adalah untuk pengguna berpengalaman yang sudah biasa dengan Google Authenticator.",
    "Please note!": "Ambil perhatian!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "Jika akses anda ke Google Authenticator hilang atas sebarang sebab selepas menyambungkannya, anda akan kehilangan akses ke akaun anda.",
    "Proceed to setup": "Teruskan ke penyediaan",
    "Account protection method": "Kaedah perlindungan akaun",
    "Here's how to set it up:": "Ini cara untuk menyediakannya:",
    "Please enter the PIN-code we've just sent to your email:": "Sila masukkan kod PIN yang baru sahaja kami hantar ke e-mel anda:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "Bagus. Anda telah berjaya membolehkan pengesahan 2 langkah melalui Google Authenticator. Pada waktu seterusnya anda daftar masuk, kod akan diminta daripada anda.",
    "If you ever lose access to your 2-step verification device, please": "Jika anda pernah kehilangan akses ke peranti pengesahan 2 langkah anda, sila",
    "Confirm account deletion": "Sahkan pemadaman akaun",
    "To delete your account, follow the link in the email sent to": "Untuk memadam akaun anda, ikut pautan dalam e-mel yang dihantar ke",
    "Image requirements": "Keperluan imej",
    "Email": "E-mel",
    "Сonfirmation": "Pengesahan",
    "Change protection method": "Tukar kaedah perlindungan",
    "First, enter the code from the Google Authenticator app:": "Pertama, masukkan kod daripada aplikasi Google Authenticator:",
    "First, enter the PIN-code we've just sent to your email:": "Pertama, masukkan kod PIN yang baru sahaja kami hantar ke e-mel anda:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "Bagus. Anda telah berjaya membolehkan pengesahan 2 langkah melalui E-mel.",
    "Receiving codes via Email": "Menerima kod melalui E-mel",
    "Receiving codes via Google Authenticator": "Menerima kod melalui Google Authenticator",
    "Choose a method:": "Pilih kaedah:",
    "To enter the platform": "Untuk masuk ke platform",
    "To withdraw funds": "Untuk mengeluarkan dana",
    "day": "hari",
    "days2": "hari",
    "days5": "hari",

text:'Pilihan Mata Wang',
text1:'E-mel',
text2:'Batal Transaksi untuk',
text3:'Kod Kupon Percuma',
text4:'Kami meningkatkan platform ini setiap hari untuk menjadikannya lebih mudah.',
text5:'Adakah anda suka platform ini? Sila kongsikan kepada orang lain!',
text6:'Sila tinggalkan ulasan anda tentang QUOTEX. Anda akan menerima ganjaran pembatalan transaksi percuma.',
text7:'Hantar pautan ulasan anda kepada pasukan khidmat pelanggan kami, selepas dipelajari, ganjaran ini akan dihantar kepada anda.',
text8:'Jumlah Deposit Minimum',
text9:'Jumlah Pengeluaran Minimum',
text10:'Pengeluaran Cepat',
text11:'Percuma',
text12:'Belum Disahkan',
text13:'Hantar Semula',
text14:'Sahkan Penyertaan Anda',
text15:'Batal Transaksi',
text16:'Sila masukkan dan aktifkan kod kupon anda segera. Anda boleh menggunakan kod kupon pada bila-bila masa.',
text17:'Kod Kupon:',
text18:'Ganjaran 30% berdasarkan baki deposit',
text19:'Mata Wang Semasa',
text20:'Baki Boleh Dikeluarkan',
text21:'Di Akaun',
text22:'Hari Ini',
text23:'Semalam',
text24:'Minggu',
text25:'Bulan',
text26:'Jumlah Bersih Dagangan',
text27:'WELCOME adalah ganjaran 30% bagi deposit pertama.',
text28:'Jumlah deposit pertama adalah $100. Untuk menerima ganjaran 30%, pilih cara deposit.',
text29:'Kad Bank',
text30:'Bank',
text31:'Cara Pembayaran yang Dipilih:',
text32:'Jumlah Minimum:',
text33:'Jumlah Maksimum:',
text34:'Pilih Cara Lain',
text35:'Maklumat Pembayaran',
text36:'Jumlah',
text37:'Gunakan Ganjaran',
text38:'Syarat dan Terma',
text39:'Saya Memiliki Kod Kupon',
text40:'Kod Kupon',
text41:'Gunakan Kod Kupon',
text42:'Kod kupon yang dimasukkan adalah betul. Anda akan menerima ganjaran sebanyak 30%.',
text43:'Anda akan menerima ganjaran sebanyak',
text44:'%',
text45:'Sila fahami semua soalan lazim',
text46:'Untuk berdagang aset digital, anda perlu membuka akaun peribadi. Untuk melakukan perdagangan sebenar, anda pasti perlu mendepositkan jumlah mengikut harga opsyen.',
text48:'Anda mempunyai {0} kod kupon',
text49:'Gunakan kod kupon ini untuk membatalkan transaksi kerugian',
text50:'Anda hanya boleh membatalkan transaksi yang tidak dilindungi, dengan jumlah tidak melebihi nilai maksimum kod kupon.',
text51:'Status',
text52:'Gunakan',
text53:'Anda belum mempunyai sejarah kod kupon. Anda boleh menambah kod kupon dengan menggunakan butang di bawah.',
text54:'Papar Sejarah',
text55:'Masukkan Kod Kupon',
text56:'Maklumat Peribadi',
text57:'Kosong',
text58:'Nama',
text59:'Nama Belakang',
text60:'Tarikh Lahir',
text61:'Negara',
text62:'Simpan',
text63:'Alamat',
text64:'Pengesahan Fail',
text65:'Sebelum pengesahan akaun, maklumat peribadi diperlukan.',
text66:'Keselamatan',
text67:'Pengesahan Dua Langkah',
text68:'Tukar Kata Laluan',
text69:'Kata Laluan Lama',
text70:'Kata Laluan Baru',
text71:'Masukkan Semula Kata Laluan Baru',
text72:'Log Keluar Akaun',
text73:'Pengesahan Kad Kredit atau Debit',
text74:'Tambah Kad Bank Baru',
text75:'Anda tidak mempunyai sebarang kad kredit atau debit yang perlu disahkan',
text76:'Anda belum mempunyai sejarah perdagangan.',
text77:'Permulaan',
text78:'Tamat Tempoh',
text79:'Terapkan Penapis',
text80:'Sebelumnya',
text81:'Seterusnya',
text82:'Keuntungan',
text83:'Dibatalkan',
text84:'Akaun',
text85:'Yuran',
text86:'Anda boleh mengeluarkan dana dari akaun platform ke kad bank atau dompet elektronik yang anda gunakan untuk mendepositkan. Anda boleh membuat permintaan pengeluaran pada bila-bila masa. Permintaan pengeluaran anda akan diproses dalam masa 3 hari bekerja.',
text87:'Deposit',
text88:'Beberapa permintaan sebelumnya',
text89:'Sejarah Pengeluaran',
text90:'Permintaan Saya',
text91:'Hantar Permintaan',
text92:'Soalan Lazim',
text93:'Anda tidak mempunyai sebarang permintaan yang diajukan',
text94:'Jika anda mempunyai sebarang pertanyaan, anda boleh mencari jawapan dalam soalan lazim.',
text95:'Hak Cipta © 2024 Quotex. Hak cipta terpelihara',
text96:'Log Masuk',
text97:'Kata Laluan',
text98:'Ingati Saya',
text99:'Maklum Balas',
text100:'Selesai',
text104:'Platform Inovasi untuk Pelaburan Pintar',
   text105: 'Daftar dan dapatkan $10,000 pada akaun demo tempat anda belajar berdagang',
   text106: 'Daftar',
   text107:'Buka akaun demo',
   text108:'Antara muka dagangan yang mudah',
   text109:'Kami telah mencipta antara muka yang paling ringkas dan paling mudah supaya anda tidak tersasar daripada perkara yang paling penting - perdagangan. ',
   text110:'Isyarat terbina dalam',
   text111:'Pertimbangkan strategi dagangan, kami menyediakan isyarat paling tepat dan inovatif sehingga 87% untuk membantu anda membangunkan strategi berkesan anda sendiri. ',
   text112:'Penunjuk dagangan',
   text113:'Kami telah mengumpul penunjuk dagangan yang paling berfaedah. Gunakan penunjuk ini untuk meningkatkan baki akaun anda. ',
   text114:'Kelajuan sempurna',
   text115: 'Platform kami menggunakan teknologi yang paling inovatif dan memberikan kelajuan yang luar biasa. ',
   text116:'Cuba dengan akaun demo',
   text117:'Untuk membuat lebih banyak wang,',
   text118:'Buat prognosis yang betul',
   text119:'Adakah harga menaik atau menurun? ',
   text120:'Buka akaun demo',
   text121: 'Klik',
   text122:'Pada salah satu butang dan lihat hasilnya',
   text123:'Harga akan naik',
   text124:'Harga akan turun',
   text125:'Menunggu keputusan...',
   text126:'Keuntungan anda',
   text127:'Maaf, ramalan anda tidak betul',
   text128:'Cuba lagi',
   text129:'Keuntungan anda',
   text130:'Tahniah! Ramalan awak betul',
   text131: 'Mula buat duit',
   text132:'Apl mudah alih sentiasa tersedia',
   text133: 'Muat turun aplikasi mesra pengguna kami untuk iPhone atau Android dan mula berdagang! ',
   text134:'Masa yang tersedia',
   text135:'Mulakan dagangan',
   text136:'3 langkah',
   text137:'Buka akaun sebenar secara percuma dalam beberapa minit sahaja',
   text138: 'Berdagang pada akaun demo dengan satu klik',
   teks139: 'Berlatih',
   text140:'Tingkatkan kemahiran anda dengan akaun demo dan bahan latihan',
   text141: 'Mulakan pengajaran anda dengan akaun demo',
   text142:'Deposit ke dalam akaun sebenar anda dan mula berdagang',
   text143:'Lebih 410 instrumen, jumlah transaksi minimum ialah $10',
   text144:'Kaedah deposit lompat',
   teks145:'Soalan Lazim',
   text146:'Bagaimana untuk menguasainya? ',
   text147:'Daftar akaun demo dan berlatih menggunakan akaun ini. Akaun demo adalah sama seperti akaun sebenar, tetapi tiada deposit diperlukan. ',
   text148:'Berapa lama masa yang diambil untuk memproses pengeluaran? ',
   text149: 'Secara purata, proses pengeluaran mengambil masa antara 1 dan 5 hari untuk diproses dari tarikh penerimaan permintaan pengguna yang sepadan, dan hanya bergantung pada bilangan permintaan yang diproses secara serentak. Syarikat sentiasa berusaha untuk membayar permintaan Pengguna secara terus pada tarikh ia menerimanya. ',
   text150:'Apakah itu platform dagangan? apa kesannya? ',
   text151: 'Platform dagangan ialah sistem perisian Pengguna menggunakan instrumen kewangan yang disediakan oleh sistem ini untuk menjalankan transaksi (operasi), dan juga menggunakan nilai sebut harga, situasi pasaran masa nyata, tindakan syarikat dan maklumat lain yang disediakan oleh syarikat. ',
   text152:'Bolehkah saya menggunakan telefon bimbit saya untuk menjalankan transaksi? ',
   text153:'Platform kami menggunakan teknologi paling inovatif dan boleh dibuka dalam penyemak imbas mana-mana komputer atau telefon pintar',
   text154:'Apakah jumlah deposit minimum? ',
   text155: 'Kelebihan platform dagangan syarikat ini ialah anda tidak perlu mendepositkan sejumlah besar wang ke dalam akaun anda. Anda boleh mula berdagang dengan melabur sejumlah kecil wang. Deposit minimum ialah $10. ',
   text156:'Adakah terdapat sebarang komisen semasa mendeposit atau mengeluarkan wang daripada akaun? ',
   teks157:'Tidak. Syarikat tidak mengenakan sebarang komisen untuk operasi seperti mendepositkan akaun atau mengeluarkan dana. ',
   text158: 'Walau bagaimanapun, perlu diingatkan bahawa sistem pembayaran mungkin mengenakan komisen dan menggunakan kadar pertukaran mata wang yang berbeza. ',
   text159: 'Pergi ke semua soalan',
   text160:'Quotex: Platform Inovasi',
   text161:'Perdagangan aset digital',
   text162:'Buka akaun sebenar',
   text163:'Rakan Kongsi',
   text164:'Akaun demo',
   text165:'Maklumat hubungan',
   text166:'Soalan penting',
   text167:'Isu kewangan',
   text168:'pengesahan',
   text169:'Peraturan',
   text170:'Dasar Privasi',
   text171:'Syarat Pembayaran',
   text172:'Perjanjian Perkhidmatan',
   text173:'Amaran Risiko',
   text174:'Peraturan operasi dagangan',
   text175:'Peraturan operasi bukan dagangan',
   text176:`Amaran Risiko: Berdagang pada platform dagangan aset digital dan menggunakan instrumen pembiayaan pinjaman boleh melibatkan risiko yang ketara dan boleh mengakibatkan kehilangan sepenuhnya modal pelaburan anda. Jangan melabur lebih daripada yang anda mampu, dan anda harus memahami risiko yang terlibat. Berdagang menggunakan instrumen pembiayaan yang dipinjam tidak sesuai untuk kebanyakan pelabur. Berdagang tanpa menggunakan dana yang dipinjam seperti saham juga berisiko kerana harga saham boleh menurun dan meningkat, yang boleh bermakna walaupun anda menang, anda akan mendapat pulangan yang lebih rendah daripada yang anda laburkan.
   Kejayaan masa lalu tidak menjamin kejayaan masa depan. Sebelum berdagang, sila beri perhatian kepada pengalaman dan tahap dagangan anda, matlamat pelaburan, dan hubungi penasihat kewangan bebas jika perlu. Pengguna harus menentukan sama ada penggunaan perkhidmatan berjenama Quotex dibenarkan berdasarkan undang-undang dan peraturan negara tempat tinggal mereka. `,
}