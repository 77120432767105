export default {
  "Price reached {price}": "Price reached {price}",
  "Apply": "应用",
  "1 day change": "1天内变化",
  "60 min change": "60分钟内内变化",
  "5 min change": "5分钟内内变化",
  "Buy": "买入",
  "Sell": "卖出",
  "Neutral": "中性",
  "Traders’ Sentiment": "交易者的情绪",
  "Pair Information": "货币对信息",
  "Fixed profitability": "固定收益",
  "From 9.00 to 18.00 (UTC+3)": "从9.00 到18.00（协调世界时）",
  "From": "从",
  "To": "到",
  "Trade Now": "开始交易",
  "Closes today at {closeTime}": "关闭在{closeTime}",
  "Open Now": "现在打开",
  "Minimum investment": "至少投资",
  "Profit - 5+ min": "利润 - 5分钟以上",
  "Profit - 1 min": "利润 - 1分钟",
  "Expiry time": "到期时间",
  "1 min - 4 hour": "1分钟至4个小时",
  "1 month change": "1个月内变化",
  "1 year change": "1年内变化",
  "YTD change": "本年迄今变化",
  "Trading Time": "交易期间",
  "Weekday": "星期几",
  "Date": "日期",
  "Trading Schedule": "交易期间",
  "Session Change": "会话更改",
  "Price Now": "价格",
  "Demo account": "模拟账户",
  "Market": "市场",
  "Sell the trade": "卖出交易",
  "Sell now": "立即卖出",
  "Get instantly answers": "快速获得答案",
  "Online chat": "在线聊天",
  "Max trade amount": "交易最高金额",
  "Smoothing period": "平滑周期",
  "How does this rating work?": "该排行榜如何运作？",
  "of the Day": "这天的",
  "Didn't find an answer to your question?": "没有找到您问题满意的答案？",
  "Search": "搜索",
  "Nothing has been found for current query, try another asset name.": "根据您的要求没有任何找到。",
  "My rating in the Leader Board": "我在排行榜上的位置",
  "Your position:": "您的位置",
  "Participants can only be those who trade in live mode": "以真实账户进行交易的交易者只可参与",
  "Your position depends on the amount of earned money": "您的位置取决于所获利润",
  "Calculated every day, from 00:00 UTC to 23:59 UTC": "每天从00:00 UTC到23:59 UTC计算",
  "All traders on our platform participate in this rating. Main features of the rating:": "我们平台上的所有交易者都参与排行榜。此排行榜的主要特点：",
  "How does it work?": "该如何运作？",
  "Backstep": "返回",
  "Depth": "深度",
  "24h changing": "24小时的变更",
  "Rate of return": "收益率",
  "Trade sold successfully": "交易已成功卖出",
  "You cannot sell a hedged trade": "您不可卖出对冲交易",
  "You cannot sell a trade": "您不可卖出交易",
  "Learn to trade without risk": "了解如何无风险交易",
  "Delete": "删除",
  "Ok": "ОК",
  "Delete all": "全部删除",
  "Return back to FAQ": "返回常见问题",
  "Return back to Help": "返回帮助",
  "How to withdraw money from the account?": "如何提取账户的资金？",
  "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "提取资金的过程非常简单，可通过您的用户信息进行。",
  "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "您选择的存款账户的方式也是提取资金的方式（请参阅问题“如何存款我的账户?\")",
  "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "例如，如果您已使用Visa支付系统存款了您的账户以进行交易，那么您将通过Visa支付系统提取资金。",
  "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "如果要提取大量金额，公司可能会要求验证（由公司自行决定进行验证），因此必须亲自注册账户为自己使用以随时证明您的账户使用权。",
  "What is the minimum withdrawal amount?": "最低取款金额是多少？",
  "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "The minimum withdrawal amount starts from 10 USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).",
  "Do I need to provide any documents to make a withdrawal?": "为进行取款要不要提供任何文件？",
  "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "提取资金通常无需提供任何其他文件。但是公司可能会自行要求以提供相关文件来证明您的个人身份。证明需要为防止无法贸易、金融诈骗以及通过非法手段获得的资金使用有关的活动。",
  "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "所需文件的清单不大，您提供这些文件并不会花费您的大量时间和精力。",
  "How long does it take to withdraw funds?": "处理取款需要多长时间？",
  "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "平均而言，取款过程从收到相应的用户请求之日起需要1到5天处理，并且仅取决于同时处理的请求的数量。公司始终努力在收到用户请求之日直接付款。",
  "Is there any fee for depositing or withdrawing funds from the account?": "账户存款或取款时有没有佣金？",
  "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "不是。公司不收取任何进行存款账户或提取资金等操作的佣金。",
  "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "但是，必须指的是，支付系统可能会收取佣金并使用有区别的货币汇率。",
  "Can I close my account? How to do it?": "我可不可注销账户？如何处理？",
  "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "可以在您的用户信息中注销账户，通过点击用户信息页面底部的\"注销账户\"按钮。",
  "If I made a mistake during entering data into my individual account, how can I fix this?": "输入了不正确的数据，如何修改？",
  "You have to contact the technical support service on the Company's website and edit the profile.": "您必须通过公司的网站联系客服团队，然后可修改您账户的数据。",
  "What data is required to register on the Company website?": "在公司网站上注册需要提供哪些信息？",
  "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "来通过投入二元期权获得利润，要开立账户为进行交易。起初需要注册在公司网站。",
  "The registration process is simple and does not take much time.": "注册过程很简单，不用花费很多时间。",
  "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "您必须填写问卷，将需要输入以下信息：",
  "name (in English)": "姓名（英文字母）",
  "email address (indicate the current, work, address)": "电子邮箱地址（输入有效的地址）",
  "telephone (with a code, for example, + 44123 ....)": "手机号码（国家代码，例如 +86...）",
  "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "密码用于进行登录 （以减少未授权登录您账户的风险，我们建议您的账户密码包含大写与小写字母、数字。请不传给第三方您的密码）",
  "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "输入个人信息之后，您将获得各种真实账户的存款方式。",
  "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "开立账户时需要选择哪一个货币类型？是否可以更改账户的货币？",
  "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "默认情况下，以美元开立交易账户。但是为了方便起见，您可以开立几个不同货币的账户。",
  "A list of available currencies can be found on your profile page in your Client's account.": "您可以在账户的用户信息页面上查看所可用的货币类型。",
  "What are digital options?": "什么是数字期权？",
  "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "期权是以股票、货币对、石油等任何一个基础资产为基础的金融衍生工具。",
  "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "数字期权 - 是一种不平常的期权。如果用户在期权到期时正确地预测基础资产的价格变动，则用户将获得盈利。",
  "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "数字期权根据双方同意的交易条款和时间将带来固定利润（交易利润与资产价格之间的差额）或损失（资产价值金额）。",
  "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "由于数字期权是以固定价格预先购买的，因此利润和损失的金额都在开始交易之前已知的。",
  "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "数字期权交易的另外一个特点是时间限制。任何期权都有期限 （到期时间或行使时间）。",
  "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "不管基础资产价格的变化程度（其涨跌幅度有多大），如果预测成功，始终获得固定利润。因此，您的风险仅限于购买期权的金额。",
  "What is the expiration period of a trade?": "交易的到期时间是什么？",
  "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "到期时间是指交易被视为已执行（完成）的时间并对其交易自动进行计算。",
  "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "交易数字期权时，您可以独立确定交易的执行时间（1分钟，2小时，1个月等）。",
  "What is the gist of digital options trading?": "数字交易的基本意义是什么？",
  "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "实际上，数字期权是金融衍生产品最简单的类型。为了在数字期权市场上赚钱，您无需预测资产市场价格可以达到的价值。",
  "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "交易过程的原理仅是解决一项任务，就合约到期时资产的价格将看涨或看跌。",
  "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "这种期权的特点，如果基础资产的价格超过一百点还是仅超过一个点，对于您绝不重要。重要的是，您只需确定此价格变动的方向。",
  "If your prognosis is correct, in any case you get a fixed income.": "如果您的预测是正确的，您将一定获得固定利润。",
  "What are the possible results of the placed trades?": "交易结果有哪些选择？",
  "There are three possible outcomes in the digital options market:": "数字期权市场上的交易结果有三种选择：",
  "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "如果您确定基础资产价格方向的预测正确，则您获得利润。",
  "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "如果您确定基础资产价格方向的预测不正确，则您遭受由资产价格限制的损失（就是说，您只能损失您的投资）；",
  "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "如果交易的结果没有上涨或下落的情况（基础资产的价格保持不变，则该期权以购买的价格行权），则您退还了投资。",
  "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "交易平台是否有模拟账户，以便了解数字期权的交易过程并无需使用您自己的资金来练习？",
  "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "当然有，以便掌握交易技能并尝试公司交易平台的功能，您可以使用模拟账户（完全免费）。这是一种模拟器，可让您先尝试模拟交易，然后再开始真实交易。该模拟账户也适合有经验的交易员为提高其交易水平。",
  "The balance of such an account is 10,000 units.": "该账户的余额为10,000。",
  "What determines profit size?": "利润额取决于什么？",
  "There are several factors that affect the size of your profit:": "有几个因素会影响您的利润额:",
  "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "在交易平台上选择的资产的流动性（资产需求越多，您获得的利润就越多）",
  "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "进行交易的时间 (上午的资产流动性和下午的资产流动性可能有很大的差异）",
  "tariffs of a brokerage company": "经纪公司费率",
  "changes in the market (economic events, changes in part of a financial asset, etc.)": "市场变化（经济事件、特定金融资产的变化等）",
  "What are the varieties of digital options?": "数字期权有哪些类型？",
  "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "进行期权交易时，您必须选择基础资产为期权。对此资产将进行预测。",
  "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "简而言之，当您购买数字合约时，您实际上押注基础资产的价格变动。",
  "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "基础资产是一种工具，在交易时以这种工具价格为交易价格。通常是以市场上需求最大的资产为数字期权的基础资产。具有四种类型：",
  "securities (shares of world companies)": "股票（世界上公司的资产）",
  "currency pairs (EUR \/ USD, GBP \/ USD, etc.)": "货币对（EUR\/USD、GBP\/USD等等）",
  "raw materials and precious metals (oil, gold, etc.)": "原料和金属 （石油、黄金等等）",
  "indices (S&P 500, Dow, dollar index, etc.)": "ETF（S&P 500, Dow, 美元指数等等）",
  "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "完美的基础资产不可有。您需要根据自己的知识和直觉以及各种分析数据来选择资产。",
  "What is a trading platform and why is it needed?": "交易平台是什么？有什么作用？",
  "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "交易平台是一个软件系统，用户使用此系统提供的金融工具进行交易（操作），还使用公司提供的牌价价值、实时市场形势、公司行动等信息。",
  "How to learn quickly how to make money in the digital options market?": "如何快速掌握在数字期权市场上赚钱的能力？",
  "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "制定并遵循自己的交易策略，为正确地预测更多交易结果",
  "diversify your risks": "分散自己的风险",
  "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "市场监测、分析和统计数据的研究等工具将有助于您制定策略并找到分散的方式。该数据来自各种信息来源（互联网资源、专家和分析师的意见等等），其中一个就是公司网站。",
  "Is the download of the program to a computer or smartphone required?": "我要不要安装该移动应用到电脑或智能手机上？",
  "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "不必要。您只需要以规定的形式在公司的网站上开立个人账户。",
  "At what expense does the Company pay profit to the Client in case of successful trade?": "如果交易成功，公司将使用什么资金为支付用户的利润？",
  "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "公司与用户一起赚钱。因此，用户交易的成功，对公司非常重要，需要成功交易的份额超过不成功交易的份额，因为公司通过用户的成功交易获得一定百分比的利润。",
  "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "此外，由用户进行的交易总额构成公司的交易量，该交易量传达给组成流动性提供者协会的经纪商或交易所，这共同导致市场本身的流动性增长。",
  "What is account verification?": "什么是账户验证？",
  "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "验证是用户以向公司提供其他文件为确认个人信息。用户身份的验证条件最简单，所需的文件很少，例如公司会要求:",
  "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "提供用户护照第一页的彩色扫描件（护照的照片页）",
  "identify with the help of a \"selfie\" (photograph of himself)": "以自拍照为验证（拍照自己）",
  "confirm the address of registration (residence) of the Client, etc": "确认用户的户籍所在地",
  "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "如果有必要，公司会要求所需的文件为验证用户和提供的数据。",
  "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "电子版扫描件发送至公司之后，必须等待一段时间来公司验证所提供的数据。",
  "How to understand that I need to go through account verification?": "如何了解我需要进行账户验证？",
  "If it becomes necessary to pass verification, you will receive a notification by e-mail and \/ or SMS notification.": "如果有必要进行验证，您就将收到电子邮件或短信通知。",
  "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "必须指的是，公司中使用您注册时提供的特定数据（就是电子邮箱地址和电话号码），因此提供有效的真实数据非常重要",
  "How do I know that I successfully passed verification?": "我怎么知道验证成功？",
  "You will receive a notification by e-mail and \/ or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "您将收到一封电子邮件和\/或短信，通知您的账户验证过程已完成，并且可以在公司平台上开始进行交易。",
  "Is it possible to indicate other people's (fake) data when registering on the website?": "在网站上注册时可不可输入别人（假）的数据？",
  "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "不可以。用户证明自己在公司网站上注册时提供完整和真实的信息，并证明保持所提供信息的准确性。",
  "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "如果有必要验证用户的身份，公司有权请求提供相关文件或者邀请用户来到公司的办公室。",
  "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "如果注册时用户输入的数据与提交的文件中指出的数据不一致，公司有权冻结该用户的交易账户。",
  "How long does the verification process take?": "验证过程需要多长时间？",
  "No more than 5 (five) business days from the date the Company receives the requested documents.": "自公司收到所需的文件之日起不超过5个工作日。",
  "Is there a minimum amount that I can deposit to my account at registration?": "账户存款的最低金额是多少？",
  "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "公司交易平台的优势就是不必存款大量金额来开始交易，最低存款额为10美元。",
  "How can I deposit?": "如何可能存入款项？",
  "It is very easy to do. The procedure will take a couple of minutes.": "这很容易落实。该过程不超于几分钟。",
  "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "前往交易页面，然后在右上角点击绿色按钮\"存款\"。",
  "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "您也可以通过点击用户信息的\"存款\"按钮来存款账户。",
  "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "之后，您需要选择存款账户的方式（公司提供许多方便的存款方式，这些方式可供用户使用并显示在用户信息中）。",
  "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "然后，选择用于存款账户的货币，也相应设置账户的货币。",
  "Enter the amount of the deposit.": "请输入存款金额。",
  "Fill out the form by entering the requested payment details.": "填写相应的申请表的支付信息。",
  "Make a payment.": "进行付款。",
  "Do I need to deposit the account of the trading platform and how often do I need to do this?": "是否需要存入款项于交易平台上的账户？存款要多久一次？",
  "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "来交易数字期权，您需要开立真实账户。来达成真实交易，您则肯定需要根据购买的期权数量存款。",
  "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "您可以使用该平台的模拟账户的模拟资金来开始交易。模拟账户是完全免费的，并且协助您了解清楚该平台的功能。使用该账户您可以练习购买数字期权，了解交易的基本原理，测试各种方法和策略，或评估您的直觉能力。",
  "What is the minimum deposit amount?": "最低存款额是多少？",
  "{number} questions": "{number} 个问题",
  "Verification": "验证",
  "Payment": "存款",
  "Payouts": "取款",
  "Ask a specialist": "向专家提出问题",
  "FAQ": "帮助",
  "Open knowledge base": "打开文件",
  "Trading Platform": "交易平台",
  "What account do you want to continue trading on?": "您要通过哪一账户进行交易？",
  "Your demo account has a {balance} balance": "您的模拟账户的余额 - {balance}",
  "Update your account without limit": "可以更新模拟账户",
  "Available not all assets": "一些资产不可使用",
  "Without risk": "无风险",
  "Trading on the demo account": "使用模拟账户进行交易",
  "Real account": "真实账户",
  "Top up your account with the minimum amount and start earning": "以最低金额存款您的账户并开始赚钱",
  "Participate in tournaments": "参与锦标赛",
  "More assets and features": "更多资产和功能",
  "Minimum deposit": "存款最低金额",
  "Have you Run out of funds?": "资金不够吗？",
  "Your balance is ${balance}. Top up and Continue trading with Quotex!": "您的余额为$ {balance}。存款您的账户，并开始在Quotex平台上进行交易。",
  "Your last deposit was at {lastDate}": "账户上次存款的时间 {lastDate}",
  "Your account balance is zero.": "Your account balance is zero.",
  "Top up with {amount}": "存款为 {amount} ",
  "Trade on a real account": "真实交易",
  "You are ready to trade Live!": "您已准备好进行真实交易",
  "Earn unlimited money": "无限收益",
  "Use strategies": "遵循策略",
  "All platform functionality is available": "平台所有的功能可用",
  "Risk Warning: Trading on financial markets carries risks and may not be suitable for all investors due to the high risk of losing the capital. You should not risk more than you are prepared to lose.": "风险提示: 该网站为用户提供的金融交易具有高风险性， 可能导致您投资资本的完全损失。不要投资超过您能承受的范围。",
  "Deposit funds": "存款",
  "Refill demo {amount}": "更新模拟余额 {amount} ",
  "Demo balance is less than {amount}": "模拟余额少为 {amount} ",
  "You are actively practising! Way to go! Try investing on a real account or refill your demo balance with {amount} for free.": "您正在积极的练习！必由之路！您使用真实账户开始投资或免费更新您的模拟账户的余额为{amount}。",
  "Now you have:": "您已经有：",
  "{percent}% bonus": "奖励 {percent}%",
  "Decline": "拒绝",
  "In order to collect your bonus you must ensure a certain turnover": "为获得奖励您必须得到相应的交易额",
  "Your turnover": "您的交易额 ",
  "To finish your bonus": "为获得奖励您还需要",
  "Do you confirm that you decline your current <b>{percent}% bonus<\/b>": "您是否要取消当前的<b>{percent}%奖励<\/b>？",
  "Yes, confirm": "确认",
  "No, cancel": "取消",
  "You have successfully declined {percent}% bonus. Now you trade without a bonus.": "您已成功取消您{percent}%的奖励。现在您可以不用奖励就进行交易。",
  "Error has occurred! Try to decline the bonus later, or write to our support.": "发生了错误。请稍后重试或联系客服团队。",
  "Ok, close window": "关闭窗口",
  "Deposit Bonus terms of use": "存款奖励使用条款",
  "A bonus is virtual money that is credited to your balance upon deposit. Depending on the promo code, the bonus can be from 10% to 100%.": "奖励是虚拟的资金，存款时加入到您的账户余额。奖励金额取决于优惠码，范围可以从10％到100％。",
  "Bonus wagering rules": "奖励收回规则",
  "You cannot withdraw bonus funds immediately. They are virtual. To transfer bonus funds into real funds, make a trading turnover of non-hedging transactions equal to the bonus amount multiplied by 100. After that, the bonus funds will become real.": "您不能立即提取虚拟的奖励。来提取该奖励，您需要得到未对冲的交易金额高于奖励一百倍以转换奖励为真实资金。之后，奖励将转换为真实资金。",
  "The progress of wagering the bonus can be seen 语言in the account selection panel.": "在账户选择面板中可以看到奖励收回的过程。",
  "Canceling a bonus or withdrawal funds with a valid bonus": "取消奖励或提取具有有效奖励的资金",
  "You can cancel the bonus in your personal account. In this case, your balance will be reduced by the amount of the bonus received. When withdrawing funds with a valid bonus, the bonus is automatically canceled, and the amount of the accrued bonus is deducted from the withdrawal amount.": "您可以在个人账户中取消奖励。在这种情况下，您的余额将减少为奖励的金额。当提取具有有效奖励的资金时，奖励也会自动取消。",
  "Chart {asset} isn’t available at the moment!": "{asset} 资产图标现在不可显示",
  "Deposit account": "存款",
  "Live Account": "真实账户",
  "Demo Account": "模拟账户",
  "Drawings": "绘图",
  "Trend indicators": "趋势指标",
  "Oscillators": "震荡指标",
  "Indicators": "指标",
  "Settings": "设置",
  "Language": "语言",
  "Time": "时间",
  "Up": "看涨",
  "Down": "看跌",
  "Your payout:": "您的支付：",
  "More": "更详细",
  "Leader Board": "排行榜",
  "Support": "客服",
  "Logout": "退出",
  "About us": "关于我们",
  "Deposit": "存款",
  "Withdrawal": "取款",
  "Transactions": "汇款",
  "Help": "帮助",
  "Trade": "交易",
  "Demo": "模拟",
  "Profile": "用户信息",
  "Analytics": "Analytics",
  "Top": "排行榜",
  "Indices": "指数",
  "Stocks": "Stocks",
  "Commodities": "原料",
  "Currencies": "货币",
  "Crypto": "加密货币",
  "Result (P\/L)": "交易成果",
  "End of trade": "交易\n结束",
  "Beginning of trade": "交易\n启动",
  "High:": "最大:",
  "Low:": "最小:",
  "Open:": "开启:",
  "Close:": "关闭:",
  "Area": "带",
  "Line": "折线图",
  "Candles": "蜡烛图",
  "Bars": "条形图",
  "Heiken Ashi": "Heiken Ashi 图标",
  "Period": "时期",
  "Overbought Level": "超买水平",
  "Oversold Level": "超卖水平",
  "Trade opened with price:": "交易开启价格",
  "ID": "ID",
  "Trade Pair": "Trade Pair",
  "Close Time": "关闭时间",
  "Open Price": "开启价格",
  "Close Price": "关闭价格",
  "Open time": "开启时间",
  "You don't have a trade history yet. You can open a trade using the form above.": "您还没有交易历史。您可以点击上面的按钮开立交易。",
  "ao": "Awesome Oscillator",
  "vo": "Volume Oscillator",
  "wwv": "Weis Waves Volume Oscillator",
  "macd": "MACD",
  "rsi": "RSI",
  "so": "Stochastic Oscillator",
  "adx": "ADX",
  "roc": "Rate Of Change",
  "aro": "Aroon",
  "cci": "CCI",
  "dem": "DeMarker",
  "ADX Smoothing": "ADX Smoothing",
  "DI Length": "DI Length",
  "will": "Williams %R",
  "bup": "Bulls power",
  "bep": "Bears power",
  "mom": "Momentum",
  "vor": "Vortex",
  "ma": "Moving Average",
  "bb": "Bollinger Bands",
  "all": "Alligator",
  "fra": "Fractal",
  "sar": "Parabolic SAR",
  "zz": "Zig Zag",
  "env": "Envelopes",
  "icc": "Ichimoku Cloud",
  "kch": "Keltner channel",
  "dch": "Donchian channel",
  "sup": "Supertrend",
  "atr": "ATR",
  "stc": "Schaff Trend Cycle",
  "Jaws Period": "鳄鱼下颚平均周期",
  "Jaws Shift": "蓝线相对偏移量",
  "Teeth Period": "鳄鱼牙齿平均周期",
  "Teeth Shift": "红线相对偏移量",
  "Lips Period": "鳄鱼嘴唇平均周期",
  "Lips Shift": "绿线相对偏移量",
  "Period Short": "短周期",
  "Period Long": "长周期",
  "Deviation": "离差",
  "Multiplier": "乘数",
  "Fast period": "快速周期",
  "Slow period": "慢速周期",
  "Signal period": "信号线周期",
  "Acceleration": "加速",
  "Max acceleration": "最大加速度",
  "Horizontal line": "水平线",
  "Vertical line": "垂直线",
  "Ray": "射线",
  "Fibonacci Retracement": "斐波那契回调线",
  "Fibonacci Fan": "菲波纳奇扇形线",
  "Trend Line": "趋势线",
  "Parallel Channel": "并行通道",
  "Rectangle": "长方形",
  "Cross Line": "正交线",
  "Trend Angle": "趋势角度",
  "Disjoint Channel": "不相交的通道",
  "Triangle": "三角形",
  "Extended Line": "延长线",
  "Date Range": "日期范围",
  "Price Range": "价格范围",
  "Max amount is": "最高金额为",
  "Min amount is": "最低金额为",
  "Min amount:": "最低金额：",
  "Max amount:": "最高金额：",
  "Unexpected error": "不可预知的错误",
  "Incorrect time": "不正确的时间",
  "Not enough funds": "您资金不够",
  "Contact support": "联系客服团队",
  "Open FAQ page": "前往常见问题",
  "Investment": "投资",
  "Trading signals": "交易信号",
  "Past signals": "过去的信号",
  "What’s it?": "这是什么？",
  "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "交易信号不是交易的直接指示，而是分析师的建议。信号不能代替独立的市场分析，也不能确保可预测的结果。",
  "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "每个信号都有自己的有效时间。在这段时间过期之后，报价可能会发生变化，并且对交易结果产生不可预报的影响。",
  "Continue": "继续吧",
  "Select trade pair": "请选择交易资产",
  "Get a {bonus}% bonus on your first deposit": "根据存款余额获得{bonus}%的奖励",
  "Signals": "信号",
  "Opacity": "透明度",
  "Do you confirm this trade in {label} on {amount} in {time}?": "您是否确认开立金额为{amount}和时间为{time}的交易{label}？",
  "currency": "货币",
  "Change": "兑换",
  "You are exchanging": "您兑换",
  "You will receive": "您获得",
  "Exchange Fee": "兑换手续费",
  "Night Mode": "夜晚",
  "Template": "平台主题",
  "Light Mode": "光亮",
  "Twilight": "黄昏",
  "Full Night": "夜晚",
  "Grid's opacity": "网格的透明度",
  "Auto-scrolling": "自动滚动",
  "Automatic graphic scrolling": "自动滚动图表",
  "1-click trade": "一键交易",
  "Open trades without confirmation": "未确认的进行交易",
  "Use timezone": "使用时区",
  "Use your timezone data": "使用您的时区数据",
  "Graph colors": "图表颜色",
  "Up Trend": "上升趋势",
  "Down Trend": "下降趋势",
  "Platform": "平台",
  "Currency:": "货币：",
  "Exchange Form": "兑换方式",
  "My Currency:": "我的货币：",
  "New Currency:": "新货币",
  "from 5 min": "5分钟以上",
  "Rev. from 1 min": "1分钟以上",
  "Market Closed": "市场关闭",
  "You have a new answer on your request. Go to the Support Page.": "客服团队发送给您一条新消息。",
  "Flat Top\/Bottom": "平顶\/底",
  "Arc": "弧",
  "Curve": "曲线",
  "Pitchfan": "Pitchfan",
  "Switch": "转换",
  "Switch time": "转换",
  "Investment:": "投资：",
  "Cyclic Lines": "循环线",
  "Gann Box": "Gann Box",
  "Perfomance Mode": "运作能力模式",
  "Use optimized rendering for chart and candles": "对图表和蜡烛使用优化的渲染",
  "New message": "新消息",
  "Trades": "交易",
  "Timezone": "时区",
  "Cashback Information": "现金返还信息",
  "Percentage of turnover": "营业额的百分比",
  "Percentage of turnover {percent}%": "Percentage of turnover {percent}%",
  "Cashback {percent}%": "Cashback {percent}%",
  "{percent}% of loss": "{percent}% of loss",
  "{percent}% of turnover": "{percent}% of turnover",
  "7% of turnover": "营业额的7%",
  "No losses": "No losses",
  "Activated:": "Activated:",
  "Remaining:": "Remaining:",
  "The current trading result during the validity period of the promo code — cashback is not accumulating.": "The current trading result during the validity period of the promo code — cashback is not accumulating.",
  "The current trading result during the validity period of the promo code — cashback is accumulating.": "The current trading result during the validity period of the promo code — cashback is accumulating.",
  "The current trading turnover during the validity period of the promo code.": "The current trading turnover during the validity period of the promo code.",
  "The trading result during the validity period of the promo code.": "The trading result during the validity period of the promo code.",
  "The trading turnover during the validity period of the promo code.": "The trading turnover during the validity period of the promo code.",
  "Active now": "正在激活",
  "From:": "从：",
  "Until:": "到：",
  "Cashback": "返现",
  "Your cashback will be credited to your account only at the end of the promo code validity period": "仅在优惠码有效期结束时，您的现金返还才会存入您的账户中",
  "Account levels": "账户类型",
  "Active": "激活",
  "Inactive": "不激活",
  "Standart": "标准",
  "Level for beginners": "新交易者的等级",
  "Basic percentage of profitability for all instruments": "所有工具获利的基本百分比",
  "Level for casual traders": "有经验交易者的等级",
  "Increased percentage of profitability for all instruments": "提高的所有工具的获利百分比",
  "Promo codes from the market in mailings and promotions": "市场中的优惠码在邮件和促销",
  "Balance from {amount}": "余额从{amount}起",
  "Level for professional traders": "高技能交易者的等级",
  "+{value}% profit": "+{value}% profit",
  "standart": "standard",
  "Short order label": "Short order label",
  "Use short order element mode": "Use short order element mode",
  "For technical reasons, we cannot show the chart of this pair, please choose another trading pair.": "For technical reasons, we cannot show the chart of this pair, please choose another trading pair.",
  "Payout": "取款",
  "Official": "Official",
  "Trade ID": "Trade ID",
  "Asset": "工具",
  "Type": "类型",
  "Duration": "时间",
  "Income": "Result",
  "Opening quote": "开启牌价",
  "Closing quote": "关闭牌价",
  "Chart": "Chart",
  "Difference": "Difference",
  "points": "points",
  "Not found": "没有找到",
  "Result": "Result",
  "Background": "Background",
  "Choose file": "Choose file",
  "Max size — 2 Mb": "Max size — 2 Mb",
  "New value": "New value",
  "Edit": "Edit",
  "Confirm": "确认",
  "rollover.deal_not_found": "Deal not found",
  "rollover.account_not_found": "Account not found",
  "rollover.profitable_deal": "Deal is profitable",
  "rollover.min_time": "Min time error",
  "rollover.min_amount": "Min amount error",
  "rollover.max_amount": "Max amount error",
  "rollover.not_enough_funds": "资金不够",
  "rollover.max_expiration_time": "Max expiration time",
  "Rollover": "Rollover",
  "Double up": "Double up",
  "double.access_denied": "Unavailable, as there is less than 1 minute left until the end of the trade",
  "Export to": "Export to",
  "pending trade": "pending trade",
  "Set manually": "Set manually",
  "Trade will close after": "Trade will close after",
  "timer": "timer",
  "time": "time",
  "Order list is empty.": "Order list is empty.",
  "Create a pending trade using the form above.": "Create a pending trade using the form above.",
  "Pending order": "Pending order",
  "Pending trade": "Pending trade",
  "quote": "quote",
  "Period:": "Period:",
  "Quote:": "Quote:",
  "Current quote:": "Current quote:",
  "Time:": "Time:",
  "Timer:": "Timer:",
  "Current time:": "Current time:",
  "Put": "Put",
  "Orders": "Orders",
  "Amount:": "Amount:",
  "Open time:": "Open time:",
  "Price:": "Price:",
  "Current price:": "Current price:",
  "Cancel": "取消",
  "Pending order successfully created": "Pending order successfully created",
  "Cannot open pending order": "Cannot open pending order",
  "Minimal opening time from 1 minute": "Minimal opening time from 1 minute",
  "Cannot cancel pending order": "Cannot cancel pending order",
  "Pending order successfully cancelled": "Pending order successfully cancelled",
  "Notifications": "Notifications",
  "mark all as read": "mark all as read",
  "No notifications": "No notifications",
  "No more notifications": "No more notifications",
  "There are several reasons why an account may become closed:": "There are several reasons why an account may become closed:",
  "1. No activity.": "1. No activity.",
  "The most common reason is that the account was closed for being inactive (no logins\/activity) for a long period of time – from 3 moths and more. Such accounts get deleted, if there are no funds on the balance, and they cannot be restored. You are free to register a new account. (provided there are no other active accounts registered by you on the Platform": "The most common reason is that the account was closed for being inactive (no logins\/activity) for a long period of time – from 3 moths and more. Such accounts get deleted, if there are no funds on the balance, and they cannot be restored. You are free to register a new account. (provided there are no other active accounts registered by you on the Platform",
  "The email cannot be reused. You would need to use a different email address.": "The email cannot be reused. You would need to use a different email address.",
  "2. Deleted by the owner.": "2. Deleted by the owner.",
  "If there are no funds on the balance, such accounts cannot be restored. As in previous case, you can simply make sure there are no other active accounts registered by you on the Platform, and create a new one.": "If there are no funds on the balance, such accounts cannot be restored. As in previous case, you can simply make sure there are no other active accounts registered by you on the Platform, and create a new one.",
  "If you have deleted your account yourself by mistake, and there are funds on its balance – please contact support for details (using the «Contacts» form on the main page of the website). The operators will check and see if the account can be restored.": "If you have deleted your account yourself by mistake, and there are funds on its balance – please contact support for details (using the «Contacts» form on the main page of the website). The operators will check and see if the account can be restored.",
  "If it’s possible to restore the account, you will be asked to provide": "If it’s possible to restore the account, you will be asked to provide",
  "A high-resolution photo of yourself (selfie) on which you are holding your document for identification (your passport or national ID will do) along with a sheet of paper with the name «QUOTEX» written on it by hand, current date and your signature. Your face, body and both arms must be visible. The details of the document should be clear and readable.": "A high-resolution photo of yourself (selfie) on which you are holding your document for identification (your passport or national ID will do) along with a sheet of paper with the name «QUOTEX» written on it by hand, current date and your signature. Your face, body and both arms must be visible. The details of the document should be clear and readable.",
  "Screenshots of the receipts for deposits in that account (a bank statement or detailed receipts from the payment system you used for depositing will do).": "Screenshots of the receipts for deposits in that account (a bank statement or detailed receipts from the payment system you used for depositing will do).",
  "3. Duplicated accounts.": "3. Duplicated accounts.",
  "It’s only allowed to have one active account on the Platform. If other accounts registered by the same person are detected they may be deleted with no warning (c 1.30 of the Service Agreement).": "It’s only allowed to have one active account on the Platform. If other accounts registered by the same person are detected they may be deleted with no warning (c 1.30 of the Service Agreement).",
  "4. Deleted for violation of the Service Agreement.": "4. Deleted for violation of the Service Agreement.",
  "The owner gets notified on the details of violation, and the possibility of refund, and if applicable, is asked to provide the required documents.": "The owner gets notified on the details of violation, and the possibility of refund, and if applicable, is asked to provide the required documents.",
  "In case of automatic detection of violations (e.g. using automated trading software) – the Company reserves the right not to notify the owner in advance. (You may contact support via the “Contacts” form at the bottom of the website’s front page for details and refund (if applicable). We remind you that all statutory documents (Service Agreement and its annexes) are publicly available and can be revisited at any time on the Company’s website.": "In case of automatic detection of violations (e.g. using automated trading software) – the Company reserves the right not to notify the owner in advance. (You may contact support via the “Contacts” form at the bottom of the website’s front page for details and refund (if applicable). We remind you that all statutory documents (Service Agreement and its annexes) are publicly available and can be revisited at any time on the Company’s website.",
  "What is a tournament?": "What is a tournament?",
  "A tournament is a competition between traders on the Platform. When entering a tournament all participants get equal amount of nominal units to their separate tournament accounts. Then you would place trades using this tournament account’s funds. Whoever managed to get the biggest balance at the end of a tournament – becomes a winner.": "A tournament is a competition between traders on the Platform. When entering a tournament all participants get equal amount of nominal units to their separate tournament accounts. Then you would place trades using this tournament account’s funds. Whoever managed to get the biggest balance at the end of a tournament – becomes a winner.",
  "What is the price of entering a tournament?": "What is the price of entering a tournament?",
  "Every tournament may have its own entry fee. The higher it is the bigger the final prize pool. Free tournaments are being held as well. You can get the detailed information in the description of the exact tournament.": "Every tournament may have its own entry fee. The higher it is the bigger the final prize pool.Free tournaments are being held as well. You can get the detailed information in the description of the exact tournament.",
  "How a winner is determined?": "How a winner is determined?",
  "By taking part in the tournament each trader gets equal amount of nominal units to a special tournament account. Then you would need to place trades using this account’s funds. The one whose account’s balance is the biggest of all at the end of the tournament – is considered a winner.": "By taking part in the tournament each trader gets equal amount of nominal units to a special tournament account. Then you would need to place trades using this account’s funds. The one whose account’s balance is the biggest of all at the end of the tournament – is considered a winner.",
  "What are the reasons for disqualification?": "What are the reasons for disqualification?",
  "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally. The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally. The list of such documents is minimum, and the operation to provide them will not take you much time and effort.",
  "Where to withdraw money? I made a deposit from a bank card, can I withdraw funds to my e-wallet?": "Where to withdraw money? I made a deposit from a bank card, can I withdraw funds to my e-wallet?",
  "In case you made no trades by the end of the tournament – you get disqualified automatically.": "In case you made no trades by the end of the tournament – you get disqualified automatically.",
  "Are there free tournaments?": "Are there free tournaments?",
  "Yes, please see the list of current tournaments.": "Yes, please see the list of current tournaments.",
  "How a prize pool is formed?": "How a prize pool is formed?",
  "A prize pool consists of 80% of the total amount received from participants as entry fees and rebuys.": "A prize pool consists of 80% of the total amount received from participants as entry fees and rebuys.",
  "What is a rebuy?": "What is a rebuy?",
  "A rebuy is an option to revert your balance to the initial amount, for a price set in accordance with the rules of a tournament. The balance will be updated to the initial amount, no matter what the current balance amount of the participant is.": "A rebuy is an option to revert your balance to the initial amount, for a price set in accordance with the rules of a tournament. The balance will be updated to the initial amount, no matter what the current balance amount of the participant is.",
  "If I win, when will I get the money?": "If I win, when will I get the money?",
  "Prize funds get transferred to the balance of your trading account within 24 hours after a tournament has ended.": "Prize funds get transferred to the balance of your trading account within 24 hours after a tournament has ended.",
  "Opening deals by time is currently available only for OTC trading.": "Opening deals by time is currently available only for OTC trading.",
  "Account closed - why and what to do?": "Account closed - why and what to do?",
  "Sign up": "注册",
  "Sign in": "登录",
  "Budget and limits": "Budget and limits",
  "Daily limit": "Daily limit",
  "Not set": "Not set",
  "Total funds": "Total funds",
  "Top up": "Top up",
  "Set up": "Set up",
  "Set a daily limit on trading to reduce financial risks.": "Set a daily limit on trading to reduce financial risks.",
  "Limit amount": "Limit amount",
  "Tournaments": "Tournaments",
  "Timer": "Timer",
  "Min trade amount": "Min trade amount",
  "Average profit": "Average profit",
  "Trades profit": "Trades profit",
  "Profitable trades": "Profitable trades",
  "Trades count": "Trades count",
  "To end": "To end",
  "Until start": "Until start",
  "Not enough funds to open a trade. Replenish the balance of your tournament account.": "Not enough funds to open a trade. Replenish the balance of your tournament account.",
  "Tourna-ments": "Tourna-ments",
  "Join us!": "Join us!",
  "Subscribe to our official Telegram channel.": "Subscribe to our official Telegram channel.",
  "Join now": "Join now",



text:'选择货币',
text1:'电子邮箱',
text2:'取消交易为',
text3:'免费优惠码',
text4:'我们每日改善该平台使其成为更便捷。',
text5:'您喜欢此平台吗？请介绍给别人！',
text6:'请留下有关QUOTEX的评论，您将获得免费交易取消为的奖励。',
text7:'对我们的客服团队发送您的评论链接，查看之后，向您将发送此奖励。',
text8:'最低存款金额',
text9:'最低取款金额',
text10:'快速取款',
text11:'免费',
text12:'未验证的',
text13:'重新发送',
text14:'Confirm your participation',
text15:'取消交易',
text16:'请立即输入并激活您的优惠码。您可以随时使用优惠码。',
text17:'优惠码：',
text18:'根据存款余额获得30%的奖励',
text19:'当前货币',
text20:'可取款的余额',
text21:'在账户里',
text22:'今天',
text23:'昨天',
text24:'星期',
text25:'月份',
text26:'净交易额',
text27:'WELCOME是首次存款的30%奖励。',
text28:'首次存款金额为$100。来获得30%的奖励，要选择存款方式。',
text29:'银行卡',
text30:'银行',
text31:'选择的支付方式：',
text32:'最低金额:',
text33:'最高金额:',
text34:'选择另外一个方式',
text35:'支付信息',
text36:'金额',
text37:'使用奖励',
text38:'条款和条件',
text39:'我有优惠码',
text40:'优惠码',
text41:'应用优惠码',
text42:'您输入的优惠码是正确的。您将获得奖励为30%',
text43:'您将获得百分之',
text44:'的奖励:',
text45:'请了解所有的常见问题',
text46:'来进行数字资产交易，则需要开立个人账户。来达成真实交易，则肯定需要根据期权的价格存款金额。',
text48:'有{0}个优惠码',
text49:'使用此优惠码来撤销亏损交易',
text50:'您只能取消未对冲的交易，其金额不超过优惠码的最大值。',
text51:'状态',
text52:'使用',
text53:'您还没有优惠码历史记录。您可以使用下面的按钮添加优惠码。',
text54:'显示历史',
text55:'输入优惠码',
text56:'隐私信息',
text57:'空着',
text58:'名字',
text59:'姓氏',
text60:'出生日期',
text61:'国家',
text62:'保存',
text63:'地址',
text64:'文件验证',
text65:'验证账户之前，需要输入个人信息。',
text66:'安全',
text67:'两步验证',
text68:'修改密码',
text69:'旧的密码',
text70:'新的密码',
text71:'重新输入新的密码',
text72:'注销账户',
text73:'进行信用卡或借记卡的验证',
text74:'添加新银行卡',
text75:'您没有任何需要验证的信用卡和借记卡',
text76:'您还没有交易历史。',
text77:'初期',
text78:'到期',
text79:'应用筛选',
text80:'上次',
text81:'下一个',
text82:'利润',
text83:'已取消',
text84:'账户',
text85:'费用',
text86:'您可以从平台账户中提取资金到您用于充值账户的银行卡或电子钱包。您可以随时请求取款。您的取款请求将在3个工作日内得到处理。',
text87:'存入款项',
text88:'过去的一些请求',
text89:'取款历史',
text90:'我的请求',
text91:'提出请求',
text92:'常见问题',
text93:'您没有任何提出的请求',
text94:'如有任何问题，您可以在常见问题中找到答案。',
text95:'Copyright © 2024 Quotex. 版权所有',
text96:'登录使用',
text97:'密码',
text98:'记住我',
text99:'返回信息',
text100:'已完成',
  text101:'存入款项',
  text102:'存入款项',
  text103:'存入款项',



  text104:'智能投资的创新平台',
  text105:'注册并在学习交易的模拟账户上获得10000美元',
  text106:'登记',
  text107:'开立模拟账户',
  text108:'便捷的交易界面',
  text109:'我们创建了最简单又方便的界面来您不会偏离最重要的就是进行交易。',
  text110:'内置信号',
  text111:'考虑一下交易策略，我们提供最准确又创新的信号高达为87%，来协助您制定自己的有效策略。',
  text112:'交易指标',
  text113:'我们收集了最有益的交易指标。使用此指标来增加您的账户金额。',
  text114:'完美速度',
  text115:'我们的平台采用最创新的技术并提供极大的速度。',
  text116:'通过模拟账户尝试一下',
  text117:'为了赚更多的钱，',
  text118:'做出正确的预后',
  text119:'价格看涨还是看跌？',
  text120:'开立模拟账户',
  text121:'点击',
  text122:'在其中一个按钮上，然后查看结果',
  text123:'价格会上涨',
  text124:'价格会下降',
  text125:'等待结果...',
  text126:'您的利润',
  text127:'很抱歉，您的预测结果是不正确的',
  text128:'再尝试一次',
  text129:'您的利润',
  text130:'恭喜您！您的预测结果是正确的',
  text131:'开始赚钱',
  text132:'移动应用程序随时可用',
  text133:'下载我们的iPhone或Android用户友好应用程序并开始交易！',
  text134:'可用时间',
  text135:'开始交易',
  text136:'3个步骤',
  text137:'只需几分钟即可免费开立真实账户',
  text138:'一键在模拟账户上进行交易',
  text139:'练习吧',
  text140:'通过模拟账户和培训教材提升您的技能',
  text141:'以模拟账户开始您的教学',
  text142:'存款您的真实账户并开始交易吧',
  text143:'超过410种工具，最低交易金额为$10',
  text144:'跳转存款方式',
  text145:'常见问题',
  text146:'如何掌握？',
  text147:'注册模拟账户并使用该账户练习。模拟账户与真实账户相同，但是不必存款。',
  text148:'处理取款需要多长时间？',
  text149:'平均而言，取款过程从收到相应的用户请求之日起需要1到5天处理，并且仅取决于同时处理的请求的数量。公司始终努力在收到用户请求之日直接付款。',
  text150:'交易平台是什么？有什么作用？',
  text151:'交易平台是一个软件系统，用户使用此系统提供的金融工具进行交易（操作），还使用公司提供的牌价价值、实时市场形势、公司行动等信息。',
  text152:'我可不可用手机进行交易？',
  text153:'我们的平台采用最创新技术，可在任何电脑或智能手机的浏览器中打开',
  text154:'最低存款额是多少？',
  text155:'该公司交易平台的优势在于，您无需将大笔资金存入您的账户。你可以通过投资少量资金开始交易。最低存款额为10美元。',
  text156:'账户存款或取款时有没有佣金？',
  text157:'不是。公司不收取任何进行存款账户或提取资金等操作的佣金。',
  text158:'但是，必须指的是，支付系统可能会收取佣金并使用有区别的货币汇率。',
  text159:'转到所有问题',
  text160:'Quotex：创新平台',
  text161:'数字资产交易',
  text162:'开立真实账户',
  text163:'合作伙伴',
  text164:'模拟账户',
  text165:'联系方式',
  text166:'重要问题',
  text167:'金融问题',
  text168:'验证',
  text169:'法规',
  text170:'隐私政策',
  text171:'支付条款',
  text172:'服务协议',
  text173:'风险提示',
  text174:'交易操作规则',
  text175:'非交易操作规则',
  text176:`            风险提示: 在数字资产交易平台进行交易并使用借贷资金工具进行交易可涉及重大的风险，可能导致您投资资本的完全损失。不要投资超过您能承受的范围，并所涉及的风险您应该理解。 使用借贷资金工具进行交易不适合大多数投资者。不使用股票等借贷资金工具进行交易也具有风险性，因为股票价格既可能降低，也可能增长，这可能意味着即使您获胜，但是可以获得的金额比投入的低得多。
  过去的成功并不确保未来的成功。进行交易之前，请注意您的交易经验和水平，投资目标，如果必要的话可以联系独立理财顾问。用户应该根据其居住国家法律和法规要求，确定是否允许使用Quotex品牌的服务。`,
};