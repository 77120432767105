import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import minixs from './minixs'
import i18n from './assets/lanage/index';
import Api from './utils/request';
import 'normalize.css';

import './assets/css/base.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'


import mxSelect from './components/mx-select.vue'
import mxSearchInput from './components/mx-searchInput.vue'
import mxInput from './components/mx-input.vue'
import mxDateinput from './components/mx-dateinput.vue'

Vue.component('MxSelect',mxSelect)
Vue.component('mxSearchInput',mxSearchInput)
Vue.component('mxInput',mxInput)
Vue.component('mxDateinput',mxDateinput)


// import Highcharts from 'highcharts';
// import VueHighcharts from 'highcharts-vue';

// Vue.use(VueHighcharts, { Highcharts });
Vue.use(ElementUI,{locale});

Vue.mixin(minixs)
Vue.config.productionTip = false;
Vue.prototype.$api = Api

Vue.directive('copy', {
  bind(el, { value }) {
    el.$value = value;
    el.handler = () => {
      if (!el.$value) {
        el.$value = el.getAttribute('value');
      }
      const input = document.createElement('input');
      input.value = el.$value;
      document.body.appendChild(input);
      input.select(); 
      document.execCommand('copy'); 
      document.body.removeChild(input); 
      ElementUI.Message.success('复制成功');
    };
    el.addEventListener('click', el.handler);
  },
  unbind(el) {
    el.removeEventListener('click', el.handler);
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
