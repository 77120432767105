<template>
   <div class="form__control">
      <label class="input-control-cabinet input-control-cabinet__tooltip-focus input-control-cabinet--text" :class="[ show ? 'active':'', Class]">
        <span class="input-control-cabinet__label">{{ label }}</span>
        <input @focus="focusHandle" @input="inputHandle" @blur="blurHandle" :value="value" class="input-control-cabinet__input" :placeholder="placeholder" />
        <div class="input-control-cabinet__badge active" v-if="isEmail">
          <div class="input-control-cabinet__status input-control-cabinet__status--danger">{{ $t('text12') }}</div>
          <button class="input-control-cabinet__action">{{$t('text13')}}</button>
        </div>
      </label>
    </div>

</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    Class: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array | String | Number,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    focusHandle() {
      this.show = true;
    },
    blurHandle() {
      this.show = false;
    },
    inputHandle(e) {
      this.$emit('update:value', e.target.value);
    },
  },
};
</script>
