export default {
    "+{value}% profit": "+{value}% लाभ",
    "Selfie verification required": "सेल्फी सत्यापन आवश्यक",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "आवश्यक खाता सत्यापन चरण को पूरा करने के लिए, हम आपसे एक कागज पर अपने ID डॉकयुमेंट को पकड़ कर वर्तमान दिनांक और अपने हस्ताक्षर के साथ,  एक उच्च रेसोल्यूशन वाली फोटो (सेल्फी), अपलोड करने का अनुरोध करते हैं। आपका चेहरा, शरीर और दोनों हाथ दिखाई देने चाहिए। <br><br> डॉकयुमेंट के विवरण स्पष्ट और पढ़ने योग्य होने चाहिए।",
    "Your documents have been checked, now your identity is verified.": "आप डॉक्युमेंट्स की जाँच हो चुकी है, आपकी पहचान अब सत्यापित है।",
    "You need fill identity information before verification your profile.": "अपने प्रोफाइल के सत्यापन से पहले आपको पहचान की जानकारी देनी होगी।",
    "Documents verification": "डॉक्युमेंट्स का सत्यापन",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "प्रोफ़ाइल के सत्यापन का अर्थ एक ऐसे डॉक्यूमेंट से है जो कि क्लाइंट के पहचान को सत्यापित करता हो। ये प्रक्रिया कम्पनी के सुरक्षा विभाग द्वारा कभी भी शुरू की जा सकती है।",
    "Upload Photo": "फोटो अपलोड करें",
    "Nickname": "उपनाम",
    "First Name": "प्रथम नाम",
    "Last Name": "अंतिम नाम",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "नाम ठीक वैसे ही निर्दिष्ट किया जाना चाहिए जैसा दस्तावेज़ में है। गलत जानकारी प्रदान करने के लिए, खाते को बिना किसी चेतावनी के हटाया जा सकता है।",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "अंतिम नाम ठीक वैसे ही निर्दिष्ट किया जाना चाहिए जैसा दस्तावेज़ में है। गलत जानकारी प्रदान करने के लिए, खाते को बिना किसी चेतावनी के हटाया जा सकता है।",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "आपकी जन्मतिथि में अब और बदलाव करना सम्भव नहीं है। यदि आपने इसे ग़लत दर्ज कर दिया था, तो आपने आपने खाते से पैसे नहीं निकाल पाएँगे।",
    "Empty": "खाली",
    "Date of birth": "जन्मतिथि",
    "Unverified": "नहीं जाँचा गया।",
    "Verified": "सत्यापित",
    "Resend": "दोबारा भेजा गया",
    "Country": "देश",
    "Address": "पता",
    "Change Identity Info": "पहचान की जानकारी बदली गयी है।",
    "Identity Info": "पहचान की जानकारी",
    "Two-factor authentication on login": "लॉगिन पर टू-फैक्टर ऑथेंटिकेशन",
    "Two-factor authentication on withdrawal": "धननिकासी पर टू-फैक्टर ऑथेंटिकेशन",
    "Active": "सक्रिय",
    "Inactive": "निष्क्रिय",
    "Setup": "सेटअप",
    "Two-step verification": "टू-स्टेप सत्यापन",
    "Security": "सुरक्षा",
    "Google Authenticator": "गूगल ऑथेंटिकेटर",
    "Scan the QR code": "QR कोड स्कैन करें",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "हम आपकी सुरक्षा को बहुत गंभीरता से लेते हैं, इसीलिए हमने 2-स्टेप सत्यापन अनिवार्य किया है। इसको सेट करने के चरण यहाँ देखें:",
    "Install ": "इनस्टॉल ",
    "Can't scan?": "स्कैन करने के समस्या आ रही है?",
    "Click here.": "यहाँ क्लिक करें",
    "Enter this code into Google Authenticator.": "गूगल ऑथेंटिकेटर में ये कोड भरें",
    "Back to QR code.": "QR कोड पर वापस जाएँ",
    "Enter the 6-digit code from Authenticator below": "ऑथेंटिकेटर से देखकर 6 अंकों का कोड यहाँ नीचे भरें",
    "Enter 6-digit code...": "6 अंकों का कोड भरें...",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "अति-उत्तम। आपने 2-स्टेप सत्यापन सफलतापूर्वक चालू कर लिया है। अगली बार साइन-इन के समय आपको कोड का प्रयोग करना होगा।",
    "Code": "कोड",
    "contact support": "सहायता से संपर्क करें",
    "If you ever lose access to your 2-step verification device, please ": "यदि आप कभी भी अपने 2-स्टेप वेरिफ़िकेशन डिवाइस तक पहुँच खो देते हैं, तो कृपया",
    "Yes, activate": "हाँ, चालू",
    "Cancel": "निरस्त",
    "Close": "बन्द",
    "Language": "भाषा",
    "Timezone": "टाइमजोन",
    "Delete Account": "खाता हटाएँ",
    "Verification Credit / Debit Cards": "सत्यापन क्रेडिट/ डेबिट कार्ड ",
    "Add New Card": "नया कार्ड जोड़े",
    "Wait for loading...": "लोड होने की प्रतीक्षा करें...",
    "You don’t have any credit / debit cards for verification": "आपके पास सत्यापन के लिए कोई भी क्रेडिट / डेबिट कार्ड नहीं है",
    "Verificate": "सत्यापन",
    "Verification Card": "सत्यापन कार्ड",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "कृपया अपनी ID  (फोटो, नाम और जन्मतिथि प्रदर्शित करते हुए) के साथ अपने बैंक कार्ड के फ्रंट साइड (नम्बर और एक्सपायरी दिनांक दिखाते हुए) को एक सादे A4 कागज पर रखें। <br> कृपया \"Quotex. io\" लिखे और इस पर अपना दस्तखत करें। <br> इसकी फोटों ले और अपलोड करें।",
    "Card:": "कार्ड:",
    "Type:": "प्रकार:।",
    "Your ID and bank card on an A4 sheet": "A4 शीट पर आपकी ID और बैंक कार्ड",
    "Yes, confirm": "हाँ, कन्फ़र्म",
    "Click or drag the image": "इमेज को क्लिक या ड्रैग करें",
    "Card": "कार्ड",
    "Documents": "दस्तावेज़",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "सबसे पहले, कृपया अपनी प्रोफ़ाइल में \"पहचान जानकारी\" अनुभाग भरें, और परिवर्तनों को सेव करने के लिए \"पहचान जानकारी बदलें\" बटन पर क्लिक करें। उसके बाद आप एक नया कार्ड जोड़ने में सक्षम हो जाएंगे।\n",
    "Specify the card details for further operations for depositing and debiting funds.": "धनराशि जमा करने और डेबिट करने के लिए आगे के संचालन के लिए कार्ड विवरण दर्ज करें।",
    "Card number:": "कार्ड नम्बर:",
    "Expiry month:": "अंतिम महीना:",
    "Expiry year:": "अंतिम वर्ष:",
    "Cardholder name:": "कार्डहोल्डर का नाम:",
    "Next": "अगला",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "कृपया शीट पर अपने हस्ताक्षर और वर्तमान तिथि रखें, अपने दस्तावेज़ को अपने बैंक कार्ड के साथ रखें, एक फोटो लें और इसे नीचे दिए गए फॉर्म में अपलोड करें।",
    "Do you have a two-sided document?": "क्या आपके पास दो तरफा दस्तावेज है?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "पिछले हिस्से को  तारीख और अपने हस्ताक्षर के साथ एक शीट पर अलग फाइल के रूप में अपलोड करें।\n",
    "Submit only valid documents": "केवल वैध दस्तावेज जमा करें",
    "Do not edit or crop photo": "फोटो को संपादित या क्रॉप न करें",
    "Do not upload other people's documents": "अन्य लोगों के दस्तावेज़ अपलोड न करें",
    "Read all": "सब पढ़ें",
    "Uploading files": "फाइलों को अपलोड किया जा रहा है",
    "For single-sided documents, upload only the front side.": "एक तरफा दस्तावेज़ों के लिए, केवल सामने की ओर अपलोड करें।",
    "Back": "वापस जाएँ",
    "Add a card": "एक कार्ड जोड़ें",
    "File Requirements": "फाइल आवश्यकताएं",
    "Scanned images are not accepted for card verification": "स्कैन की गई फोटो को कार्ड सत्यापन के लिए स्वीकार नहीं किया जाता है",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "हम केवल आपके नियमित नागरिक पासपोर्ट, या राष्ट्रीय आईडी के साथ फ़ाइलों को स्वीकार करते हैं (दोनों हिस्सों को अलग फ़ाइलों के रूप में अपलोड किया जाना चाहिए)",
    "Driving license CANNOT be accepted": "ड्राइविंग लाइसेंस स्वीकार नहीं किया जा सकता",
    "External (foreign/international/travel) passport will not be accepted as well": "बाहरी (विदेशी/अंतरराष्ट्रीय/यात्रा) पासपोर्ट भी स्वीकार नहीं किया जाएगा",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "यदि दस्तावेज़ में 2 साइड हैं, तो आपको वर्तमान तिथि और आपके हस्ताक्षर के साथ, कागज की एक ही शीट पर एक अलग फ़ाइल के रूप में रिवर्स अपलोड करने की आवश्यकता है",
    "All information in the document and on the card should be clear and readable on the photo": "दस्तावेज़ में और कार्ड पर सभी जानकारी स्पष्ट और फोटो पर पठनीय होना चाहिए",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "आपके दस्तावेज़ और आपके कार्ड दोनों को फोटो में पूरी तरह से फिट होना चाहिए किनारों को क्रॉप नहीं किया जाना चाहिए",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "कोई ग्लेर, या ऐसा अन्य फोटो दोष नहीं होना चाहिए, जिससे दस्तावेज़ और कार्ड के डेटा को पढ़ना मुश्किल हो सकता है",
    "Expired documents and cards will be rejected automatically": "समाप्त हो चुके दस्तावेज और कार्ड स्वतः अस्वीकार कर दिए जाएंगे",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "यदि आपके कार्ड पर आपका नाम नहीं है, तो आपको बैंक स्टेटमेंट का स्क्रीनशॉट अपलोड करना होगा, जिसमें आपका कार्ड नंबर और आपका नाम दिखाया गया है। कुछ मामलों में,  कागज की एक ही शीट पर हाथ से अपना नाम लिखने की अनुमति  है",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "सत्यापन के लिए आवश्यक डेटा युक्त दस्तावेजों के तत्वों (नाम, जन्म तिथि, फोटोग्राफ) को छिपाने की अनुमति नहीं है",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "कार्ड के नंबर में 8 अंक छिपाने की अनुमति है। पहले 4 और अंतिम 4 अंक स्पष्ट रूप से दिखाई देने चाहिए",
    "CVV code should be hidden at all times, in every case": "सीवीवी कोड हर समय, हर मामले में छिपा होना चाहिए",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "आपकी फ़ाइलों का कोई भी संपादन निषिद्ध है। केवल असंपादित फ़ोटो और स्कैन की गई छवियों को स्वीकार किया जा सकता है। अपने दस्तावेजों में व्यक्तिगत जानकारी के संपादन (नाम, जन्म तिथि, आदि) की अनुमति नहीं है",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "फोटो दस्तावेज़ (में चिपकी या प्रिंटेड) का एक हिस्सा होनी चाहिए । अलग से जुड़ी फोटो स्वीकार नहीं की जाएंगी",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "केवल उस नाम से दस्तावेज़ और कार्ड अपलोड करने की अनुमति है, जो प्रोफ़ाइल में निर्दिष्ट है (जिस पर आपका खाता पंजीकृत है)",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "हम आपको याद दिलाते हैं कि फर्जी/झूठी जानकारी जमा करने के लिए खाते को तब तक निलंबित/अवरुद्ध किया जा सकता है, जब तक कि परिस्थितियां स्पष्ट नहीं हो जातीं",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "कंपनी के पास किसी भी समय खाता सत्यापन (दस्तावेज़ के साथ आपकी सेल्फी सहित) के लिए कोई अतिरिक्त दस्तावेज प्रदान करने के लिए कहने का अधिकार सुरक्षित है",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "दस्तावेज सत्यापन से संबंधित सभी प्रश्नों के लिए, आप प्लेटफ़ॉर्म पर एक समर्थन टिकट बना सकते हैं (\"हेल्प\" सेक्शन बाएं पैनल पर ब्लिंक करते प्रश्न चिह्न आइकन के साथ पहला बटन है)",
    "Profile": "प्रोफ़ाइल",
    "Deposit": "जमा",
    "Withdrawal": "निकासी",
    "Transactions": "ट्रांजेक्शन",
    "Trades": "ट्रेड",
    "Market": "बाजार",
    "Analytics": "एनालिटिक्स",
    "My current currency": "मेरी वर्तमान मुद्रा",
    "Available for withdrawal": "निकासी के लिये उपलब्ध",
    "In the account": "खातें में",
    "Percentage of turnover {percent}%": "टर्नओवर का प्रतिशत {प्रतिशत}%",
    "Cashback {percent}%": "कैशबैक {प्रतिशत}%",
    "Currency:": "मुद्रा:",
    "Change": "बदलाव",
    "Live Account": "चालू खाता",
    "Daily budget limit": "दैनिक बजट सीमा",
    "The value cannot exceed your total budget": "यह मान आपके कुल बजट से अधिक नहीं हो सकता",
    "Demo Account": "डेमो खाता",
    "Apply": "निवेदन करें",
    "Edit": "एडिट करें",
    "New value": "नया मान",
    "Confirm": "निश्चित करें।",
    "Logout": "लॉगआउट",
    "No, cancel": "नहीं, कैंसिल",
    "Exchange Form": "बदलने का फॉर्म",
    "My Currency:": "मेरी मुद्रा:",
    "New Currency:": "नई मुद्रा:",
    "You are exchanging": "आप बदलाव कर रहे हैं।",
    "You will receive": "आप प्राप्त करेंगे।",
    "Exchange Fee": "बदलने का शुल्क",
    "Password changed": "पासवर्ड परिवर्तित हुआ",
    "Old password": "पुराना पासवर्ड",
    "New password": "नया पासवर्ड",
    "Confirm new password": "नया पासवर्ड कन्फ़र्म करें।",
    "Change Password": "पासवर्ड बदलें।",
    "Confirm password must be equal new password value": "पुष्टीकरण पासवर्ड का मान नए पासवर्ड के समान होना चाहिए",
    "About us": "हमारे बारे में",
    "Support": "सहयोग",
    "Help": "सहायता",
    "2-step verification": "2-स्टेप सत्यापन",
    "Please enter the PIN-code we've just sent to your email": "कृपया वह पिन-कोड भरें, जो हमने अभी ईमेल के द्वारा आपको भेजा है।",
    "Please enter the 6-digit code from your authentication app": "कृपया ऑथेंटिकेशन एप से छः अंकों का कोड भरें।",
    "The First Name field is required": "पहला नाम फील्ड आवश्यक है",
    "The Last Name field is required": "अंतिम नाम फील्ड आवश्यक है",
    "Verification of documents": "दस्तावेजों का सत्यापन",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "कृपया अपने नियमित सिविल पासपोर्ट, ड्राइविंग लाइसेंस, या राष्ट्रीय पहचान पत्र की रंगीन फोटो या स्कैन की गई छवि अपलोड करें।",
    "Upload Documents": "दस्तावेज अपलोड करें",
    "Why?": "क्यों?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "क्या आप बिना पीछे लौटे अपने खातें को मिटाने की पुष्टि करते हैं? ये क्रिया बाद में निष्क्रिय नहींकी जा सकती है।",
    "Set up 2-step verification": "2-स्टेप सत्यापन विधि सेट करें",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "दुर्भाग्य से, आप इस अनुभाग में फ़ाइलें अपलोड नहीं कर सकते। <br> कृपया सहायता टीम के संदेशों को देखें और आवश्यक फ़ाइलों को किसी टिकट के साथ अटैच करें।\n",
    "The uploaded document does not match the requirements": "अपलोड किया गया दस्तावेज़ आवश्यकताओं को पूरा नहीं करता",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "पहचान के लिए निम्न दस्तावेज़ पात्र हैं: ID, पासपोर्ट, अंतर्राष्ट्रीय पासपोर्ट या ड्राइविंग लाइसेन्स",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "इमेज उच्च गुणवत्ता की होनी चाहिए, लिखित डाटा सुtext्य होना चाहिए, और दस्तावेज़ के कोने क्रॉप नहीं किए होने चाहिए।",
    "Balance from {amount}": "{amount} से शेष",
    "Not verified": "सत्यापित नहीं है",
    "Clear": "साफ़",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "सेवा प्रतिबंधों से बचने के लिए, कृपया सत्यापन प्रक्रिया पूरी करें: अपना व्यक्तिगत डेटा भरें और आवश्यक दस्तावेज़ अपलोड करें।",
    "Get verified": "सत्यापित करा लें",
    "I got it!": "मैं समझ गया!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "आधार सत्यापन",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "कृपया अपने भौतिक आधार की एक रंगीन फोटो अपलोड करें (केवल सामने की तरफ आपकी फोटो और दस्तावेज संख्या के साथ)।",
    "Send AADHAAR": "आधार भेजें",
    "AADHAAR number:": "आधार संख्या:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "कृपया अपने भौतिक आधार की एक रंगीन फोटो अपलोड करें (केवल सामने की तरफ आपकी फोटो और दस्तावेज संख्या के साथ)। ☝ कोई इलेक्ट्रॉनिक संस्करण स्वीकार नहीं किया जाता है।",
    "You have already uploaded {number} files. Please wait until they are checked.": "आप {number} फ़ाइलें पहले ही अपलोड कर चुके हैं। कृपया उनकी जाँच होने तक प्रतीक्षा करें।",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "कृपया अपने दस्तावेज़ की रंगीन तस्वीर अपलोड करें: आरजी के लिए - दोनों पक्षों को अलग-अलग फाइलों के रूप में अपलोड करने की आवश्यकता है। CNH के लिए - आपकी तस्वीर के साथ केवल सामने की ओर। दस्तावेज़ मान्य होना चाहिए (जारी होने की तारीख से 10 साल से कम), और आपका सीपीएफ नंबर दिखाना चाहिए। * यदि आपके दस्तावेज़ में कोई CPF नहीं है - कृपया Comprovante de Inscrição no CPF की एक अलग तस्वीर अपलोड करें, या अपने बैंक स्टेटमेंट का एक स्क्रीनशॉट अपलोड करें जिसमें आपका नाम और CPF नंबर दिखाया गया हो। ** कृपया कोई इलेक्ट्रॉनिक संस्करण अपलोड न करें। उन्हें स्वीकार नहीं किया जाएगा।",
    "Submit for verification": "सत्यापन के लिए जमा करें",
    "Settings": "सेटिंग",
    "Please upload a color photograph of the front of your national identity card with your photo.": "कृपया अपने फोटो के साथ अपने राष्ट्रीय पहचान पत्र के सामने का रंगीन फोटोग्राफ अपलोड करें।",
    "Template": "टेम्पलेट",
    "Light Mode": "लाइट मोड",
    "Twilight": "ट्वाईलाईट",
    "Full Night": "फूल नाईट",
    "Grid's opacity": "ग्रिड की अपारदर्शिता",
    "Auto-scrolling": "स्वतः स्क्रॉलिंग",
    "Automatic graphic scrolling": "ग्राफ़िक की स्वतः स्क्रॉलिंग",
    "1-click trade": "1- क्लिक ट्रेड",
    "Open trades without confirmation": "बिना पुष्टि के ट्रेड खोलें।",
    "Use timezone": "टाइमजोन का प्रयोग करें",
    "Use your timezone data": "अपनी टाइमजोन के डाटा का प्रयोग करें",
    "Graph colors": "ग्राफ रंग",
    "Up Trend": "अप ट्रेड",
    "Down Trend": "डाउन ट्रेड",
    "Platform": "प्लेटफॉर्म",
    "Short order label": "शॉर्ट ऑर्डर लेबल",
    "Use short order element mode": "शॉर्ट ऑर्डर लेबल एलिमंट मोड का उपयोग करें",
    "Perfomance Mode": "पर्फोमन्स मोड",
    "Use optimized rendering for chart and candles": "चार्टों व कैंडलों के लिए ऑप्टिमाइज्ड रेंडरिंग का प्रयोग करें",
    "Background": "पृष्ठभूमि",
    "Choose file": "फ़ाइल चुनें",
    "Max size — 2 Mb": "अधिकतम साइज़ - 2 Mb",
    "Sign up": "साइन अप",
    "Sign in": "साइन इन",
    "Budget and limits": "बजट और सीमाएं",
    "Daily limit": "दैनिक सीमा",
    "Not set": "सेट नहीं",
    "Total funds": "कुल धन",
    "Top up": "ऊपर से",
    "Set up": "स्थापित करना",
    "Set a daily limit on trading to reduce financial risks.": "वित्तीय जोखिमों को कम करने के लिए व्यापार पर एक दैनिक सीमा निर्धारित करें।",
    "Limit amount": "सीमा राशि",
    "More": "अधिक",
    "Leader Board": "लीडर बोर्ड",
    "Signals": "सिग्नल",
    "Notifications": "नोटिफ़िकेशन",
    "mark all as read": "सभी को पढ़ा हुआ मार्क करें",
    "No notifications": "कोई नोटिफ़िकेशन नहीं",
    "No more notifications": "और अधिक नोटिफ़िकेशन नहीं",
    "Your position:": "आपकी स्थिति:",
    "How does this rating work?": "ये रेटिंग कैसे काम करता है?",
    "of the Day": "के दिन",
    "Nothing has been found for current query, try another asset name.": "वर्तमान सवाल के लिए कुछ भी नहीं पाया गया, दूसरा एसेट नाम डालें।",
    "My rating in the Leader Board": "लीडर बोर्ड में मेरी रेटिंग",
    "Participants can only be those who trade in live mode": "जो सजीव रूप से ट्रेडिंग करते हैं सिर्फ़ वो ही प्रतिभागी हो सकते हैं।",
    "Your position depends on the amount of earned money": "आपकी स्थिति कमाई गयी राशि पर निर्भर करती है।",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "रोजना जोड़ा जाता है, 00:00 से लेकर 23:59 तक",
    "All traders on our platform participate in this rating. Main features of the rating:": "हमारे प्लेटफॉर्म के सभी ट्रेडर्स इस रेटिंग में भाग लेते हैं: रेटिंग के मुख्य गुण हैं:",
    "How does it work?": "ये कैसे काम करता है?",
    "Trading signals": "ट्रेडिंग सिग्नल",
    "Past signals": "पिछला सिग्नल",
    "What’s it?": "ये क्या है?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "एक ट्रेडिंग सिग्नल सीधे तौर पर ट्रेड के लिए इंस्ट्रक्शन नहीं होता है, बल्कि ये एक सुझाया गया सलाह है। इसे स्वतंत्र बाजार के विश्लेषण को नही बदलना चाहिए।",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "ट्रेडिंग सिग्नल का लाभ उठाने के लिये आपके पास औसतन 10 मिनट का समय होता है। इस समय के बाद, कोट्स बदल जाता है और ये ट्रेड में अप्रत्याशित परिणाम दे सकता है। ",
    "Continue": "लगातार",
    "Duration": "समय अंतराल",
    "Return back to FAQ": "FAQ में वापस जाएं।",
    "Return back to Help": "मदद में वापस जाएं।",
    "How to withdraw money from the account?": "खातें से पैसे कैसे निकालें?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "राशि की निकासी करने का तरीका बहुत ही आसान है और ये सीधे ही आपके व्यक्तिगत खातें के द्वारा निकाला जा सकता है।",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "जिस विधि को आपने खातें में जमा करने के लिये चुना है वो ही विधि धन निकासी के लिये भी इस्तेमाल की जाएगी। (\"कैसे मैं जमा कर सकता हूँ?\" इस सवाल को देखें)",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "उदाहरण के लिए, यदि आपने अपने खातें में वीजा पेमेंट सिस्टम की मदद से धन जमा किया है, आप वीजा पेमेंट सिस्टम की मदद से भी पैसे की निकासी कर सकते हैं।",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "जब खातें से एक बड़ी राशि निकालने की बारी आती है तो फिर कम्पनी सत्यापन करने के लिए कह सकती है (सत्यापन का निर्णय सिर्फ कम्पनी के पास ही निहित है ), इसलिए एक व्यक्तिगत खातें का पंजीकरण कराना अति आवश्यक है ताकि आप अपने अधिकार का लाभ किसी भी समय उठा सकें।",
    "What is the minimum withdrawal amount?": "न्यूनतम निकासी राशि क्या है?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "अधिकांश भुगतान प्रणालियों के लिए न्यूनतम निकासी धनराशि 10 USD से शुरू होती है।<br> क्रिप्टोकरेंसियों के लिए यह धनराशि 50 USD से शुरू होती है (और कुछ करेंसियों जैसे Bitcoin के लिए अधिक भी हो सकती है)।",
    "Do I need to provide any documents to make a withdrawal?": "निकासी के लिये क्या मुझे कोई डॉक्युमेंट उपलब्ध करवाना होगा?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "ज्यादातर, पैसे निकालने के लिये अतिरिक्त डॉक्युमेंट्स की जरूरत नहीं पड़ती है। लेकिन कम्पनी सुरक्षा के लिए आपके पहचान के सत्यापन के लिए अतिरिक्त डॉक्यूमेंट जमा करने के लिए कह सकती है। ज्यादातर ये सब अवैध ट्रेडिंग और फ्रॉड को रोकने के लिये साथ ही ग़लत तरीक़े से पैसे कमाने से रोकने के लिए किया जाता है।",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "इस तरह के डॉक्युमेंट्स की लिस्ट बहुत छोटी है, और इस प्रक्रिया को करने के लिए आपको ज्यादा समय और प्रयास करने की जरूरत नहीं पड़ती है।",
    "How long does it take to withdraw funds?": "पैसे को निकालने में कितना वक़्त लगता है?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "औसतन, क्लाइंट द्वारा भुगतान किए जाने की रसीद प्राप्त होने के बाद 5 दिन में भुगतान की प्रक्रिया पूरी हो जाती है और ये भुगतान के लिये आये हुए आवेदनों की संख्या पर भी निर्भर करता है। जिस दिन क्लाइंट भुगतान करता है, कम्पनी उसी दिन उसके भुगतान को सफल बनाने का प्रयास करती है। ",
    "Is there any fee for depositing or withdrawing funds from the account?": "खातें में पैसा जमा करने के लिये या पैसा निकालने के लिए क्या कोई फीस भी देनी पड़ती है?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "नहीं। कम्पनी किसी भी जमा और निकासी के लिए कोई भी धनराशि चार्ज नहीं करती है।",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "हालाँकि, ये समझना फायदेमंद है कि पेमेंट सिस्टम पैसे ट्रांसफर करने के लिये पैसे चार्ज कर सकती है और आंतरिक करेंसी बदलाव का रेट ले सकती हैं।",
    "Can I close my account? How to do it?": "क्या मैं अपना खाता बन्द कर सकता हूँ? इसे कैसे करें?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "आप अपने व्यक्तिगत खाते को खाते की भीतर जाकर प्रोफ़ाइल पेज में नीचे दिए गए \"खाता मिताएँ\" के विकल्प पर क्लिक कर के खत्म कर सकते हैं।",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "यदि मैंने अपने व्यक्तिगत खाते में डेटा डालते समय कोई गलती कर दी है तो फिर मैं इसे कैसे ठीक कर सकता हूँ?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "आपको कम्पनी की वेबसाइट पर जाकर टेक्निकल सपोर्ट सर्विस से सम्पर्क करना होगा और अपनी प्रोफाइल को ठीक करना होगा।",
    "What data is required to register on the Company website?": "कम्पनी वेबसाइट पर पंजीकरण करने के लिए क्या डेटा आवश्यक होता है?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "डिजिटल विकल्प से पैसे कमाने के लिए, आपको सबसे पहले एक खाता खोलना होता है जो कि आपको ट्रेड समझने की सुविधा देता हो। ये करने के लिये आपको कम्पनी की वेबसाइट पर पंजीकरण करना होता है।",
    "The registration process is simple and does not take much time.": "पंजीकरण की प्रक्रिया बहुत आसान है और ये ज्यादा समय नहीं लेता है।",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "प्रस्तावित फॉर्म पर पूछे गए सभी सवालों का जवाब देना आवश्यक है। आपको ये सभी जानकारी वहाँ पर देनी होगी:",
    "name (in English)": "नाम (अँग्रेजी में)",
    "email address (indicate the current, work, address)": "ईमेल एड्रेस (वर्तमान में चलने वाला, काम, पता)",
    "telephone (with a code, for example, + 44123 ....)": "टेलीफोन (कोड के साथ, उदाहरण के लिये, +44123....)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "एक पासवर्ड जिसका इस्तेमाल आप भविष्य में सिस्टम में प्रवेश करने के लिए करेंगे (किसी अनाधिकृत एक्सेस से बचने के लिये हम आपको सलाह देते हैं कि आप बड़े अक्षर, छोटे अक्षर और संख्या का इस्तेमाल करते हुए कठिन पासवर्ड का चुनाव करें। किसी अन्य व्यक्ति को पासवर्ड कभी ना बताएं)",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "सभी सवालों का जवाब देने के बाद, आपको ट्रेडिंग के लिये खातें को खोलने के कई तरीके बताए जाते हैं।",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "क्लाइंट का खाता किस मुद्रा में खुलता है? क्या मैं क्लाइंट के खाते से मुद्रा के विकल्प को बदल सकता हूँ?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "पहले से ही, एक ट्रेडिंग खाता यूएस डॉलर में खोला जाता है। लेकिन आपकी सुविधा के लिये, आप विभिन्न खातें अलग-अलग मुद्राओं में खोल सकते हैं।",
    "A list of available currencies can be found on your profile page in your Client's account.": "क्लाइंट खाते के प्रोफ़ाइल पेज में मौजूदा सभी मुद्राओं का विकल्प लिस्ट आपको मिल जाएगा",
    "What are digital options?": "डिजिटल विकल्प क्या है?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "विकल्प - एक सीधे तौर पर फाइनेंशियल इंस्ट्रूमेंट जो कि किसी भी एसेट से जुड़ा हो सकता है (ये स्टॉक, मुद्रा जोड़े और तेल आदि हो सकता है)",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "डिजिटल विकल्प- किसी निश्चित समय मे  किसी असेट के मूल्य में होने वाले बदलाव से लाभ कमाने का एक नॉन-स्टैंडर्ड विकल्प है।",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "भुगतान के समय पार्टियों द्वारा तय किए गए शर्तो पर ही डिजिटल विकल्प निर्भर करता है, जो कि पार्टियों द्वारा निर्धारित किया जाता है और एक निश्चित कमाई (ट्रेड इनकम और सम्पति के दाम में अंतर) या नुकसान (एसेट के कीमत की राशि मे) को लाता है।",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "चूँकि डिजिटल विकल्प को एक निश्चित दाम में पहले से ही खरीदा जा चुका है, लाभ का आकार, साथ ही नुकसान का आकार भी ट्रेड के पहले ही पता चल जाता है।",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "इन डील्स का एक और गुण है समय सीमा। सभी विकल्प का अपना अलग शर्त है। (अनुभव का समय या सम्पूर्ण होने का समय)",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "किसी ली गयी सम्पति के मूल्य के बदलाव की डिग्री के संदर्भ में (ये कितना बढ़ या घट जाएगा), जीतने की स्थिति में एक निश्चित राशि का भुगतान किया जाएगा। हालाँकि, आपके रिस्क की राशि सिर्फ़ तय किये गए विकल्प के अनुसार ही सीमित है।",
    "What is the expiration period of a trade?": "ट्रेड के खत्म होने की अवधि क्या है?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "निष्क्रिय होने की तिथि का मतलब उस समय से है, जिसके बाद ट्रेड को पूरा होना (बन्द होना) समझा जाएगा, और इसका परिणाम स्वतः ही दिखाई देने लग जाता है।",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "ट्रांजेक्शन के निष्पादित होने के समय को सुनिश्चित कीजिये (1 मिनट, 2 घंटे, महीने इत्यादि)",
    "What is the gist of digital options trading?": "डिजिटल विकल्प ट्रेडिंग का सार क्या है?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "सच्चाई ये है कि डिजिटल विकल्प फाइनेंशियल इंस्ट्रूमेंट के वित्तीय समझौते का सबसे आसान तरीका है। डिजिटल विकल्प के माध्यम से पैसे कमाने के लिए आपको पहले से ही मार्किट के प्राइस का अनुमान लगाने की जरूरत नहीं है। ",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "ट्रेडिंग प्रक्रिया का सिद्धान्त सिर्फ़ एक अकेले टॉस्क के हल पर सीमित है- किसी भी एसेट का दाम कांट्रेक्ट टाइम के खत्म होने के समय घटेगा या बढ़ेगा",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "इस विकल्प का प्रभाव ये होता है कि इससे आपको कोई मतलब नहीं है, की जिस समय ट्रेड क्लोज हो रहा है उस वक्त आपके द्वारा अधिकृत किये गए एसेट का मूल्य 100 अंक तक बढ़ा हुआ है या फिर एक ही अंक पर रुका है। आपके लिए बस ये करना आवश्यक है कि आप मूल्य की दिशा का पहचान कर के रखें।",
    "If your prognosis is correct, in any case you get a fixed income.": "अगर किसी भी परिस्थिति में आपका अनुमान सही साबित होता है तो आपको इल्ड निश्चित राशि का लाभ दिया जाता है।",
    "What are the possible results of the placed trades?": "प्लेस किये गए ट्रेड का संभावित परिणाम क्या है?",
    "There are three possible outcomes in the digital options market:": "डिजिटल विकल्प के मार्किट में तीन तरह के सम्भावित परिणाम हासिल हो सकते हैं:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "किसी इवेंट में अगर आपके द्वारा लिए गए एसेट के मूल्य में होने वाले बदलाव का अनुमान आपने सही लगाया है तो फिर आपको लाभ हासिल होगा।",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "यदि किसी स्थिति में आपके द्वारा एसेट के मूल्य के उतार चढ़ाव के ऊपर लगाया गया अनुमान गलत साबित होता है तो फिर आपके एसेट की कीमत के अनुसार ही आपको नुकसान उठाना पड़ सकता है(वास्तव में, आप सिर्फ़ अपना निवेश ही खो सकते हैं)",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "अगर ट्रेड का रिजल्ट जीरो आता है (लिये गए एसेट का मूल्य नहीं बदलता है, ये अभी भी उसी मूल्य पर है जिस पर इसे खरीदा गया था), आपको निवेश किया गया मूल्य मिल जाता है। अतः हमेशा रिस्क आपके द्वारा निवेश किये गए एसेट की कीमत पर निर्भर करता है।",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "जानकारियां जो कि विभिन्न स्रोतों से मिलती हैं (इंटरनेट से, विशेषज्ञ की सलाह, क्षेत्र से संबंधित डेटा आदि) आपकी मदद करेंगे, जिसमें कम्पनी की वेबसाइट एक अहम भूमिका निभाती है। ",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "हाँ। अपनी ट्रेडिंग स्किल को और बेहतर बनाने के लिए आप कम्पनी के ट्रेडिंग प्लेटफॉर्म पर एक डेमो खाते का इस्तेमाल कर सकते हैं। (बिना किसी शुल्क के) ये एक तरह की ऐसी सुविधा है जो कि आपको पहले इस्तेमाल करने और फिर विश्वास होने के बाद वास्तविक ट्रेडिंग में उतरने की सहूलियत देता है। इस तरह के डेमो खाते अनुभवी ट्रेडर्स के लिए भी स्किल को बेहतर करने के लिए काफी मदद करते हैं।",
    "The balance of such an account is 10,000 units.": "इस तरह के खाते का बैलेंस 10,000 यूनिट होता है।",
    "What determines profit size?": "लाभ का आकार किस चीज से निर्धारित होता है?",
    "There are several factors that affect the size of your profit:": "बहुत सारे ऐसे घटक हैं जो कि आपके लाभ के आकार को प्रभावित करतें हैं:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "आपके द्वारा चुनी गए सम्पत्ति की मार्किट में कीमत (जितने ज्यादा सम्पत्ति की बाजार में माँग रहेगी उतना अधिक आपको लाभ हासिल होगा)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "ट्रेड का समय (सम्पति की कीमत सुबह के बाजार में और दोपहर के बाजार में व्यापक रूप से बदल सकती है)",
    "tariffs of a brokerage company": "दलाली वाली कम्पनी का टैरिफ",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "बाजार में बदलाव (आर्थिक इवेंट्स, आर्थिक सम्पतियों के भाग में बदलाव आदि)",
    "What are the varieties of digital options?": "डिजिटल विकल्प की विभिन्नताएं क्या है?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "एक विकल्प ट्रेड करने के लिये ये आवश्यक है कि आप एक वैकल्पिक एसेट का चुनाव करें जो कि विकल्प को प्रदर्शित करे। आपका अनुमान इसी एसेट पर लागू होगा।",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "आसान भाषा मे, डिजिटल कांट्रैक्ट को लेने का मतलब है कि आप किसी लिए गए एसेट के मूल्य में होने वाले बदलाव पर दांव खेल रहे हैं",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "एक लिया गया एसेट एक \"आइटम\" है जिसका दाम खाते में ट्रेड को समाहित करते समय लिया जा सकता है। डिजिटल विकल्प के रूप में अधिकृत किए गए एसेट में ज्यादातर सबसे ज्यादा माँग वाले प्रॉडक्ट ही काम करते हैं। इसके कुल 4 प्रकार है:",
    "securities (shares of world companies)": "सिक्योरिटी (दुनिया भर की कम्पनियों के शेयर)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "मुद्रा जोड़े (EUR / USD, GBP / USD आदि)",
    "raw materials and precious metals (oil, gold, etc.)": "कच्चा माल और महँगी धातुएँ (तेल और सोना इत्यादि)",
    "indices (S&P 500, Dow, dollar index, etc.)": "अन्य ( S&P 500, डॉ, डॉलर सूचकांक आदि)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "दुनिया भर में चलने वाला एसेट मौजूद नहीं है। इसे खुद की जानकारी समझ और विभिन्न तरह के आँकड़े ध्यान में रखते हुए चुनिए, और साथ ही इसे चुनने से पहले बाजार को भी अच्छे से समझने का प्रयास कीजिये।",
    "What is a trading platform and why is it needed?": "ट्रेडिंग प्लेटफॉर्म क्या है और इसकी क्या आवश्यकता है?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "ट्रेडिंग प्लेटफार्म- एक ऐसा सॉफ्टवेयर जो कि क्लाइंट को विभिन्न  फाइनेंशियल इंस्ट्रूमेंट का इस्तेमाल करते हुए ट्रेड करने की सहूलियत देता है। इसके साथ ही ये कई सारी जानकारियों को जैसे कि कोटेशन, वास्तविक समय मे बाजार की स्थिति, कम्पनी के एक्शन आदि के बारे में भी बताता है।",
    "How to learn quickly how to make money in the digital options market?": "कैसे जल्दी से सीखे, कैसे डिजिटल विकल्प के बाजार में पैसे कमाएं?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "खुद की ट्रेडिंग स्ट्रेटजी का विकास करने की, जिसमें अनुमान लगाए गए नम्बर सही साबित हो, ",
    "diversify your risks": "अपने रिस्क को कम करने का प्रयास करें।",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "डेवलपमेंट स्ट्रेटजी में, यहाँ तक कि बेहतर परिवेश को समझने के लिए मार्किट की समझ, डेटा को समझना और पढ़ना",
    "Is the download of the program to a computer or smartphone required?": "क्या इस प्रोग्राम को कम्प्यूटर या फिर स्मार्टफोन पर डाउनलोड करना आवश्यक है?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "नहीं, ये आवश्यक नहीं है। आपको बस कम्पनी के वेबसाइट पर खुद को पंजीकृत करते हुए एक व्यक्तिगत खाता खोलना है।",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "सफल ट्रेड होने की स्थिति में कम्पनी कितने ख़र्चे के बाद क्लाइंट को उसके लाभ का भुगतान करती है?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "कम्पनी अपने ग्राहकों से ही कमाई करती है। इसलिए, ये ग्राहकों द्वारा लाभ कमाए गए पैसे के ट्रांजेक्शन में अपना हिस्सा ज्यादा लेती है, नुकसान में हुए ट्रांजेक्शन की तुलना में, वास्तविक रूप से क्लाइंट द्वारा ट्रेडिंग की सफल स्ट्रेटजी का इस्तेमाल कर के कमाए गए लाभ में कम्पनी का भी प्रतिशत होता है।",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "इसके साथ ही, कम्पनी के ट्रेड वॉल्यूम में से क्लाइंट द्वारा लिए गए ट्रेड को, जो कि किसी दलाल को या बदलाव के लिये ट्रान्सफर किया जाता है, जो कि वास्तविक मुद्रा देने वाले के पास पहुँचता है, जो कि साथ मे मिलकर बाजार में वास्तविक मुद्रा को खुद ही बढ़ा देता है। ",
    "What is account verification?": "खाता जाँच प्रक्रिया क्या है?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "डिजिटल माध्यम में क्लाइंट की व्यक्तिगत जानकारी जरूरी डॉक्यूमेंट के साथ कम्पनी को उपलब्ध कराए जाने के लिए क्लाइंट द्वारा सत्यापित किया जाना होता है। क्लाइंट के लिए जांच की शर्तें जितना सम्भव हो उतना आसान रखा जाता है और डॉक्युमेंट्स की सूची भी न्यूनतम रखी जाती है। उदाहरण के लिए कंपनी आपसे माँग सकती है:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "क्लाइंट के पॉसपोर्ट के पहले पृष्ठ की एक रंगीन फोटो कॉपी उपलब्ध करवाइए (फोटो के साथ पॉसपोर्ट का पेज )",
    "identify with the help of a \"selfie\" (photograph of himself)": "सेल्फी (खुद के द्वारा खिंची गयी व्यक्तिगत फोटो) द्वारा सत्यापन",
    "confirm the address of registration (residence) of the Client, etc": "क्लाइंट के द्वारा पंजीकृत किये गए पते का सत्यापन",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "यदि क्लाइंट द्वारा प्रविष्ट किये गए डेटा से उसका सत्यापन नही हो पा रहा है तो कम्पनी किसी भी डॉक्युमेंट की माँग कर सकती है।",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "डॉक्यूमेंट्स की इलेक्ट्रॉनिक प्रति कम्पनी में जमा करने के बाद क्लाइंट को दिए गए डेटा के सत्यापन के लिए कुछ समय प्रतीक्षा करनी होगी।",
    "How to understand that I need to go through account verification?": "ये कैसे समझे कि मुझे खाते की जाँच प्रक्रिया से गुजरना है?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "अगर आपका जाँच प्रक्रिया को पास करना आवश्यक है तो फिर इसकी सूचना आपको ईमेल या/ अन्यथा एसएमएस द्वारा दी जाएगी।",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "इसी समय, कम्पनी उसी कॉन्टेक्ट विवरण का इस्तेमाल करती है जो कि आपके द्वारा पंजीकरण की प्रक्रिया के समय उपलब्ध करवाया गया था। (इसमें ईमेल एड्रेस और फोन नम्बर)। इसलिए सावधान रहें और जरूरी तथा सही जानकारी ही दें।",
    "How do I know that I successfully passed verification?": "मुझे ये कैसे पता चलेगा कि मैंने सफलतापूर्वक जाँच प्रक्रिया को पार कर लिया है?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "आपको ईमेल और/ या SMS द्वारा आपके खाते के सत्यापन प्रक्रिया के सफलतापूर्वक सम्पन्न होने की और कम्पनी के ट्रेडिंग प्लेटफ़ॉर्म के माध्यम से ट्रेडिंग प्रक्रिया को करने की सूचना दी जाएगी।",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "वेबसाइट पर पंजीकरण करते समय क्या किसी अन्य व्यक्ति (फर्जी) के डेटा को उपलब्ध कराना सम्भव है?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "नहीं। कम्पनी के वेबसाइट पर क्लाइंट को पंजीकरण फॉर्म में पूछी गयी जानकारी को स्वतः ही पूरी और सत्य रूप में उपलब्ध कराते हुए पंजीकरण प्रक्रिया पूरी करनी होती है, और साथ ही जानकरी को हमेशा अपडेट भी करना होता है।",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "यदि किसी क्लाइंट के विभिन्न पहचान पत्र की जाँच करना आवश्यक है तो फिर कंपनी उस व्यक्ति के डॉक्युमेंट्स को अपने ऑफिस में लाकर जाँच सकती है।",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "यदि पंजीकरण के लिये प्रविष्ट किया गया डेटा उपलब्ध कराए गए डॉक्यूमेंट में लिखे डेटा से मिलान नहीं करता है तो  आपका व्यक्तिगत खाता ब्लॉक किया जा सकता है।",
    "How long does the verification process take?": "जाँच प्रक्रिया में कितना समय लगता है?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "कंपनी द्वारा माँगे गए डॉक्यूमेंट उपलब्ध कराए जाने के बाद अधिकतम 5 (पाँच) कार्यकारी दिन",
    "Is there a minimum amount that I can deposit to my account at registration?": "पंजीकरण करते समय क्या कोई न्युनतम राशि है, जिसे मुझे अपने खाते में जमा करनी होगी?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "कम्पनी के ट्रेडिंग खाते के प्लेटफॉर्म का फायदा ये है कि इसमें आपको अधिक धनराशि अपने खाते में जमा करने की आवश्यकता नहीं है। आप छोटी धनराशि जमा कर के भी ट्रेडिंग शुरू कर सकते हैं। न्यूनतम जमा की जाने वाली राशि 10  यूएस डॉलर है।",
    "How can I deposit?": "मैं कैसे जमा कर सकता हूँ?",
    "It is very easy to do. The procedure will take a couple of minutes.": "ये करना बेहद आसान है। ये प्रक्रिया महज कुछ ही मिनट लेती है।",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "ट्रेड एक्सक्यूशन विंडो को खोलिए और ऊपर दायीं तऱफ के कॉर्नर पर दिए गए टैब हरे पर \"जमा\" पर क्लिक कीजिए।",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "एकांउट प्रोफ़ाइल में जाकर \"जमा\" के विकल्प पर क्लिक कर के आप अपने व्यक्तिगत खातें से भी राशि को जमा कर सकते हैं। ",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "इसके बाद खातें में जमा करने की विधि का चुनाव करना आवश्यक है। (कम्पनी बहुत से सुविधाजनक विधि का विकल्प देती है जो कि क्लाइंट के लिये उपलब्ध है और उसके व्यक्तिगत खातें में दिख रहा होता है)",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "अगला, उस करेंसी को दर्शाइये, जिसमें पैसे को जमा किया जाना है और उसी के अनुसार करेंसी के एकाउंट को भी सूचित कीजिये।",
    "Enter the amount of the deposit.": "जमा की जाने वाली राशि प्रविष्ट करें।",
    "Fill out the form by entering the requested payment details.": "अनुरोध किये जाने वाले भुगतान की विस्तृत जानकारी देते हुए फॉर्म भरें।",
    "Make a payment.": "भुगतान करें।",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "क्या ट्रेडिंग प्लेटफॉर्म पर मुझे खातें को जमा भी करना पड़ता है और ये मुझे कितनी बार करना होगा?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "डिजिटल विकल्प के साथ काम करने के लिए आपको एक व्यक्तिगत खाता खोलने की जरूरत है, सभी ट्रेड की एक साथ देखने के लिये, आपको निश्चित रूप से एक खरीदे जाने वाले विकल्प पर जाकर एक निश्चित राशि का भुगतान करना होगा।",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "आप बिना कैश के भी सिर्फ़ कम्पनी के ट्रेनिंग खाते (डेमो एकाउंट) की मदद से ट्रेडिंग शुरू कर सकते हैं। इस तरह के खातें का इस्तेमाल मुफ्त में ट्रेडिंग के तरीकों को सीखने के लिये किया जा सकता है। इस तरह के खातें का इस्तेमाल कर के आप डिजिटल विकल्प के इस्तेमाल का अभ्यास कर सकते हैं, ट्रेडिंग के मूल नियमों को समझकर, इसके विभिन्न विधियों और स्ट्रेटजी को समझिए और खुद के लेवल को भी आंकिएँ।",
    "What is the minimum deposit amount?": "न्यूनतम जमा राशि क्या है?",
    "{number} questions": "{number} सवाल",
    "Verification": "जाँच प्रक्रिया",
    "Payment": "भुगतान",
    "Payouts": "भुगतान किया जाने वाला",
    "Ask a specialist": "विशेषज्ञ से पूछें",
    "FAQ": "सामान्य प्रश्न",
    "Didn't find an answer to your question?": "आपके सवाल का कोई भी उत्तर नहीं मिला?",
    "Open knowledge base": "जानकारी के बेस को खोलें।",
    "Contact support": "कांटेक्ट सपोर्ट",
    "Open FAQ page": "FAQ पेज को खोलें।",
    "New message": "नया सन्देश",
    "Tournaments": "प्रतियोगिता",
    "To end": "छोर देना",
    "Until start": "शुरू होने तक",
    "Front side": "आगे की तरफ",
    "Back side (if any)": "पीछे की ओर (यदि कोई हो)",
    "Tourna-ments": "प्रतियोगिता",
    "Deletion of Account and Personal Data": "खाता और व्यक्तिगत डेटा का विलोपन",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "अपने खाते और व्यक्तिगत डेटा को हटाने से, आप Quotex प्लेटफॉर्म पर अपने खाते तक स्थायी रूप से पहुँच खो देंगे। याद रखें कि आपका डेटा अपरिवर्तनीय रूप से हटा दिया जाएगा और बाद में आपके खाते को पुनर्स्थापित करना असंभव है!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "ध्यान दें: कृपया अपना खाता हटाने से पहले सभी खुले ट्रेड और लंबित ऑर्डर पूरे करें।",
    "Request Deletion": "हटाने का अनुरोध करें",
    "To delete your account, please contact support service": "अपना खाता हटाने के लिए, कृपया सहायता सेवा से संपर्क करें",
    "Two-factor authorization via Email": "ईमेल के माध्यम से दो-कारक प्राधिकरण",
    "This feature is for experienced users who are familiar with Google Authenticator.": "यह सुविधा उन अनुभवी उपयोगकर्ताओं के लिए है जो Google प्रमाणक से परिचित हैं।",
    "Please note!": "कृपया ध्यान दें!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "यदि आप Google प्रमाणक को जोड़ने के बाद किसी भी कारण से उसकी पहुंच खो देते हैं, तो आप अपने खाते तक पहुंच खो देंगे।",
    "Proceed to setup": "सेटअप करने के लिए आगे बढ़ें",
    "Account protection method": "खाता सुरक्षा विधि",
    "Here's how to set it up:": "यहां बताया गया है कि इसे कैसे सेट अप करें:",
    "Please enter the PIN-code we've just sent to your email:": "कृपया वह पिन-कोड दर्ज करें जिसे हमने अभी-अभी आपके ईमेल पर भेजा है:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "अच्छा काम। आपने Google प्रमाणक के माध्यम से द्वि-चरणीय सत्यापन सफलतापूर्वक सक्षम कर लिया है। अगली बार जब आप साइन इन करेंगे, तो आपको एक कोड के लिए संकेत दिया जाएगा।",
    "If you ever lose access to your 2-step verification device, please": "यदि आप कभी भी अपने द्वि-चरणीय सत्यापन डिवाइस तक पहुंच खो देते हैं, तो कृपया",
    "Confirm account deletion": "खाता हटाने की पुष्टि करें",
    "To delete your account, follow the link in the email sent to": "अपना खाता हटाने के लिए, भेजे गए ईमेल में दिए गए लिंक का अनुसरण करें",
    "Image requirements": "छवि आवश्यकताएँ",
    "Email": "ईमेल",
    "Сonfirmation": "पुष्टि",
    "Change protection method": "सुरक्षा पद्धति बदलें",
    "First, enter the code from the Google Authenticator app:": "सबसे पहले, Google प्रमाणक ऐप से कोड दर्ज करें:",
    "First, enter the PIN-code we've just sent to your email:": "सबसे पहले, वह पिन-कोड दर्ज करें जिसे हमने अभी-अभी आपके ईमेल पर भेजा है:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "अच्छा काम। आपने ईमेल द्वारा द्वि-चरणीय सत्यापन सफलतापूर्वक सक्षम कर लिया है।",
    "Receiving codes via Email": "ईमेल द्वारा कोड प्राप्त करना",
    "Receiving codes via Google Authenticator": "Google प्रमाणक के माध्यम से कोड प्राप्त करना",
    "Choose a method:": "कोई तरीका चुनें:",
    "To enter the platform": "मंच में प्रवेश करने के लिए",
    "To withdraw funds": "धन निकालने के लिए",
    "day": "दिन",
    "days2": "दिन",
    "days5": "दिन",


text: 'मुद्रा चुनें',
text1: 'ईमेल',
text2: 'रद्दीकरण लेन-देन के लिए',
text3: 'मुफ्त छूट कोड',
text4: 'हम इस प्लेटफ़ॉर्म को रोजाना सुधारते हैं ताकि यह अधिक सुविधाजनक बन सके।',
text5: 'क्या आपको यह प्लेटफ़ॉर्म पसंद है? कृपया इसे दूसरों को सुझाएं!',
text6: 'कृपया QUOTEX पर अपनी टिप्पणियाँ छोड़ें, आपको मुफ्त लेन-देन रद्द करने का इनाम मिलेगा।',
text7: 'हमारी सेवा टीम को अपनी टिप्पणियाँ भेजें, सत्यापन के बाद, हम आपको इस इनाम को भेजेंगे।',
text8: 'न्यूनतम जमा राशि',
text9: 'न्यूनतम निकासी राशि',
text10: 'त्वरित निकासी',
text11: 'मुफ्त',
text12: 'सत्यापित नहीं',
text13: 'फिर से भेजें',
text14: 'अपनी भागीदारी की पुष्टि करें',
text15: 'लेन-देन रद्द करें',
text16: 'कृपया अपना कूपन दर्ज करें और सक्रिय करें। आप कभी भी कूपन का उपयोग कर सकते हैं।',
text17: 'कूपन:',
text18: 'जमा राशि के आधार पर 30% इनाम प्राप्त करें',
text19: 'वर्तमान मुद्रा',
text20: 'निकासी के लिए शेष राशि',
text21: 'खाते में',
text22: 'आज',
text23: 'कल',
text24: 'सप्ताह',
text25: 'महीना',
text26: 'नेट लेन-देन राशि',
text27: 'WELCOME पहली जमा का 30% इनाम है।',
text28: 'पहली जमा की राशि 100 डॉलर है। 30% इनाम प्राप्त करने के लिए, जमा करने का तरीका चुनें।',
text29: 'बैंक कार्ड',
text30: 'बैंक',
text31: 'चयनित भुगतान तरीका:',
text32: 'न्यूनतम राशि:',
text33: 'अधिकतम राशि:',
text34: 'एक और तरीका चुनें',
text35: 'भुगतान जानकारी',
text36: 'राशि',
text37: 'इनाम का उपयोग',
text38: 'शर्तें और शर्तें',
text39: 'मेरे पास एक कूपन है',
text40: 'कूपन',
text41: 'कूपन लागू करें',
text42: 'आपके द्वारा दर्ज किया गया कूपन सही है। आपको 30% इनाम मिलेगा।',
text43: 'आपको मिलेगा',
text44: 'का इनाम:',
text45: 'सभी सामान्य प्रश्नों को समझें',
text46: 'डिजिटल एसेट ट्रेडिंग करने के लिए, आपको व्यक्तिगत खाता खोलना होगा। वास्तविक ट्रेडिंग करने के लिए, आपको अवधि की कीमत के आधार पर जमा राशि की आवश्यकता है।',
text48: 'यहाँ {0} कूपन हैं',
text49: 'इस कूपन का उपयोग करें और नुकसान करने वाले लेन-देन को रद्द करें',
text50: 'आप केवल उन लेन-देन को रद्द कर सकते हैं जो लेन-देन को कवर नहीं किया गया है और जिनकी राशि कूपन की अधिकतम मान से अधिक नहीं है।',
text51: 'स्थिति',
text52: 'का उपयोग करें',
text53: 'आपके पास अभी तक कोई कूपन इतिहास नहीं है। आप नीचे दिए गए बटन का उपयोग करके कूपन जोड़ सकते हैं।',
text54: 'इतिहास दिखाएं',
text55: 'कूपन दर्ज करें',
text56: 'गोपनीयता सूचना',
text57: 'खाली',
text58: 'नाम',
text59: 'उपनाम',
text60: 'जन्मतिथि',
text61: 'देश',
text62: 'सहेजें',
text63: 'पता',
text64: 'दस्तावेज़ सत्यापन',
text65: 'खाता सत्यापित करने से पहले, आपको व्यक्तिगत जानकारी दर्ज करनी होगी।',
text66: 'सुरक्षा',
text67: 'दो-चरणीय सत्यापन',
text68: 'पासवर्ड बदलें',
text69: 'पुराना पासवर्ड',
text70: 'नया पासवर्ड',
text71: 'नए पासवर्ड को फिर से दर्ज करें',
text72: 'खाता लॉग आउट',
text73: 'क्रेडिट कार्ड या डेबिट कार्ड सत्यापित करें',
text74: 'नया बैंक कार्ड जोड़ें',
text75: 'आपके पास सत्यापित करने के लिए कोई क्रेडिट कार्ड या डेबिट कार्ड नहीं है',
text76: 'आपके पास अभी तक कोई लेन-देन का इतिहास नहीं है।',
text77: 'आरंभ',
text78: 'समाप्त',
text79: 'फ़िल्टर लागू करें',
text80: 'पिछला',
text81: 'अगला',
text82: 'लाभ',
text83: 'रद्द कर दिया गया है',
text84: 'खाता',
text85: 'शुल्क',
text86: 'आप प्लेटफ़ॉर्म पर अपने खाते से धन निकाल सकते हैं, जो आपने जमा करने के लिए उपयोग किया है, अपने बैंक कार्ड या इलेक्ट्रॉनिक वॉलेट में। आप कभी भी निकासी का अनुरोध कर सकते हैं। आपका निकासी का अनुरोध 3 कार्य दिवसों में प्रसंस्कृत होगा।',
text87: 'जमा करें',
text88: 'कुछ पिछले अनुरोध',
text89: 'निकासी इतिहास',
text90: 'मेरे अनुरोध',
text91: 'एक अनुरोध प्रस्तुत करें',
text92: 'सामान्य प्रश्न',
text93: 'आपने अभी तक कोई अनुरोध नहीं किया है',
text94: 'यदि आपके पास कोई सवाल है, तो आप सामान्य प्रश्नों में जवाब पा सकते हैं।',
text95: 'कॉपीराइट © 2024 कोटेक्स। सभी अधिकार सुरक्षित हैं',
text96: 'का उपयोग करके लॉग इन करें',
text97: 'पासवर्ड',
text98: 'मुझे याद रखें',
text99: 'जानकारी पर वापस जाएं',
text100: 'पूरा हो गया है',



    text104:'बुद्धिमान निवेश के लिए नवाचार मंच',
   text105: 'साइन अप करें और डेमो खाते पर $10,000 प्राप्त करें जहां आप व्यापार करना सीखते हैं',
   text106:'रजिस्टर',
   text107:'एक डेमो खाता खोलें',
   text108:'सुविधाजनक ट्रेडिंग इंटरफ़ेस',
   text109:'हमने सबसे सरल और सबसे सुविधाजनक इंटरफ़ेस बनाया है ताकि आप सबसे महत्वपूर्ण चीज़ - ट्रेडिंग - से न भटकें। ',
   text110:'अंतर्निहित सिग्नल',
   text111:'एक ट्रेडिंग रणनीति पर विचार करें, हम आपको अपनी प्रभावी रणनीति विकसित करने में मदद करने के लिए 87% तक सबसे सटीक और नवीन संकेत प्रदान करते हैं। ',
   text112:'ट्रेडिंग संकेतक',
   text113:'हमने सबसे लाभप्रद व्यापारिक संकेतक एकत्र किए हैं। अपने खाते का शेष बढ़ाने के लिए इस संकेतक का उपयोग करें। ',
   text114:'उत्तम गति',
   text115: 'हमारा प्लेटफ़ॉर्म सबसे नवीन तकनीक का उपयोग करता है और जबरदस्त गति प्रदान करता है। ',
   text116:'इसे डेमो अकाउंट से आज़माएं',
   text117:'अधिक पैसा कमाने के लिए',
   text118:'सही पूर्वानुमान लगाएं',
   text119:'क्या कीमत तेजी या मंदी है? ',
   text120:'एक डेमो खाता खोलें',
   text121:'क्लिक करें',
   text122:'किसी एक बटन पर क्लिक करें और परिणाम देखें',
   text123:'कीमत बढ़ेगी',
   text124:'कीमत गिर जाएगी',
   text125:'परिणामों की प्रतीक्षा है...',
   text126:'आपका लाभ',
   text127:'क्षमा करें, आपकी भविष्यवाणी ग़लत है',
   text128:'पुनः प्रयास करें',
   text129:'आपका लाभ',
   text130:'बधाई हो! आपकी भविष्यवाणी सही है',
   text131:'पैसा कमाना शुरू करें',
   text132:'मोबाइल ऐप हमेशा उपलब्ध है',
   text133: 'iPhone या Android के लिए हमारा उपयोगकर्ता-अनुकूल ऐप डाउनलोड करें और ट्रेडिंग शुरू करें! ',
   text134:'उपलब्ध समय',
   text135:'ट्रेडिंग शुरू करें',
   text136:'3 चरण',
   text137:'कुछ ही मिनटों में मुफ़्त में एक वास्तविक खाता खोलें',
   text138:'एक क्लिक से डेमो खाते पर व्यापार करें',
   text139:'अभ्यास',
   text140:'डेमो अकाउंट और प्रशिक्षण सामग्री के साथ अपने कौशल में सुधार करें',
   text141:'अपना शिक्षण एक डेमो अकाउंट से शुरू करें',
   text142:'अपने वास्तविक खाते में जमा करें और व्यापार शुरू करें',
   text143:'410 से अधिक उपकरण, न्यूनतम लेनदेन राशि $10 है',
   text144:'जंप डिपॉजिट विधि',
   text145:'अक्सर पूछे जाने वाले प्रश्न',
   text146:'इसमें महारत कैसे हासिल करें? ',
   text147:'एक डेमो खाता पंजीकृत करें और इस खाते का उपयोग करने का अभ्यास करें। एक डेमो खाता वास्तविक खाते के समान ही होता है, लेकिन किसी जमा राशि की आवश्यकता नहीं होती है। ',
   text148:'निकासी की प्रक्रिया में कितना समय लगता है? ',
   text149: 'औसतन, निकासी प्रक्रिया को संबंधित उपयोगकर्ता अनुरोध की प्राप्ति की तारीख से संसाधित होने में 1 से 5 दिन लगते हैं, और यह केवल एक साथ संसाधित अनुरोधों की संख्या पर निर्भर करता है। कंपनी हमेशा उपयोगकर्ता का अनुरोध प्राप्त होने की तारीख पर सीधे भुगतान करने का प्रयास करती है। ',
   text150:'ट्रेडिंग प्लेटफॉर्म क्या है? क्या असर है? ',
   text151: 'ट्रेडिंग प्लेटफॉर्म एक सॉफ्टवेयर प्रणाली है। उपयोगकर्ता लेनदेन (संचालन) करने के लिए इस प्रणाली द्वारा प्रदान किए गए वित्तीय उपकरणों का उपयोग करते हैं, और उद्धरण मूल्य, वास्तविक समय बाजार की स्थिति, कंपनी की गतिविधियों और कंपनी द्वारा प्रदान की गई अन्य जानकारी का भी उपयोग करते हैं। ',
   text152:'क्या मैं लेन-देन करने के लिए अपने मोबाइल फ़ोन का उपयोग कर सकता हूँ? ',
   text153:'हमारा प्लेटफ़ॉर्म सबसे नवीन तकनीक का उपयोग करता है और इसे किसी भी कंप्यूटर या स्मार्टफोन के ब्राउज़र में खोला जा सकता है',
   text154:'न्यूनतम जमा राशि क्या है? ',
   text155: 'इस कंपनी के ट्रेडिंग प्लेटफॉर्म का लाभ यह है कि आपको अपने खाते में बड़ी मात्रा में पैसा जमा करने की आवश्यकता नहीं है। आप छोटी रकम निवेश करके ट्रेडिंग शुरू कर सकते हैं। न्यूनतम जमा राशि $10 है. ',
   text156:'क्या खाते से पैसे जमा करने या निकालने पर कोई कमीशन है? ',
   text157:'नहीं. कंपनी खातों में जमा करने या धनराशि निकालने जैसे कार्यों के लिए कोई कमीशन नहीं लेती है। ',
   text158: 'हालांकि, यह ध्यान दिया जाना चाहिए कि भुगतान प्रणालियाँ कमीशन ले सकती हैं और भिन्न मुद्रा विनिमय दरों का उपयोग कर सकती हैं। ',
   text159:'सभी प्रश्नों पर जाएँ',
   text160:'कोटेक्स: इनोवेशन प्लेटफार्म',
   text161:'डिजिटल एसेट ट्रेडिंग',
   text162:'एक वास्तविक खाता खोलें',
   text163:'साझेदार',
   text164:'डेमो अकाउंट',
   text165:'संपर्क जानकारी',
   text166:'महत्वपूर्ण प्रश्न',
   text167:'वित्तीय मुद्दे',
   text168:'सत्यापन',
   text169:'विनियम',
   text170:'गोपनीयता नीति',
   text171:'भुगतान शर्तें',
   text172:'सेवा अनुबंध',
   text173:'जोखिम चेतावनी',
   text174:'ट्रेडिंग संचालन नियम',
   text175:'गैर-व्यापारिक परिचालन नियम',
   text176:`जोखिम चेतावनी: डिजिटल एसेट ट्रेडिंग प्लेटफॉर्म पर ट्रेडिंग और उधार फंडिंग उपकरणों का उपयोग करने में महत्वपूर्ण जोखिम शामिल हो सकते हैं और इसके परिणामस्वरूप आपकी निवेश पूंजी का पूरा नुकसान हो सकता है। अपनी क्षमता से अधिक निवेश न करें और आपको इसमें शामिल जोखिमों को समझना चाहिए। उधार के फंडिंग उपकरणों का उपयोग करके व्यापार करना अधिकांश निवेशकों के लिए उपयुक्त नहीं है। स्टॉक जैसे उधार ली गई धनराशि का उपयोग किए बिना व्यापार करना भी जोखिम भरा है क्योंकि स्टॉक की कीमतें घट और बढ़ दोनों सकती हैं, जिसका मतलब यह हो सकता है कि अगर आप जीतते हैं, तो भी आपको निवेश की तुलना में बहुत कम वापस मिलेगा।
   अतीत की सफलता भविष्य की सफलता की गारंटी नहीं देती। ट्रेडिंग से पहले, कृपया अपने ट्रेडिंग अनुभव और स्तर, निवेश लक्ष्यों पर ध्यान दें और यदि आवश्यक हो तो एक स्वतंत्र वित्तीय सलाहकार से संपर्क करें। उपयोगकर्ताओं को यह निर्धारित करना चाहिए कि उनके निवास के देश के कानूनों और विनियमों के आधार पर कोटेक्स-ब्रांडेड सेवाओं के उपयोग की अनुमति है या नहीं। `,
}