import Vue from 'vue'; // 引入Vue
import VueI18n from 'vue-i18n'; // 引入国际化的包
import ZH from './zh'
import sa from './sa'
import bn from './bn'
import gb from './gb'
import es from './es'
import ir from './ir'
import ph from './ph'
import fr from './fr'
import _in from './in'
import id from './id'
import jp from './jp'
import my from './my'
import br from './br'
import ru from './ru'
import th from './th'
import tr from './tr'
import ua from './ua'
import vn from './vn'


import ElementLocale from 'element-ui/lib/locale';
Vue.use(VueI18n); // 全局注册国际化包

// 准备翻译的语言环境信息
const i18n = new VueI18n({
  locale: localStorage.activeLange || 'zh', // 从cookie中获取语言类型 获取不到就是中文
  messages: {
    ph,
    fr,
    id,
    jp,
    my,
    br,
    ru,
    th,
    tr,
    ua,
    vn,
    zh:ZH, // 中文语言包
    sa:sa,
    bn,
    gb,
    es,
    ir,
    in:_in,
  },
  silentTranslationWarn: true
});

ElementLocale.i18n((key, value) => i18n.t(key, value));
export default i18n;
