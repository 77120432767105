export default {
    "+{value}% profit": "+กำไร {value}%",
    "Selfie verification required": "จำเป็นต้องยืนยันตนผ่านเซลฟี",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "ในการดำเนินตามขั้นตอนการยืนยันบัญชีที่จำเป็น เราขอให้คุณมอบภาพถ่ายตัวของคุณ (เซลฟี) ที่ถือเอกสารประจำตัวของคุณพร้อมวันที่ปัจจุบันและลายเซ็นของคุณบนกระดาษความคมชัดสูง ภาพถ่ายควรจะเห็นใบหน้า ลำตัวและมือทั้งสองข้างของคุณ <br><br>รายละเอียดบนเอกสารควรชัดเจนและสามารถอ่านออก",
    "Your documents have been checked, now your identity is verified.": "เอกสารของคุณได้รับการตรวจสอบแล้ว ยืนยันตัวตนของคุณเรียบร้อยแล้ว",
    "You need fill identity information before verification your profile.": "คุณจะต้องกรอกข้อมูลยืนยันตัวตนก่อนทำการยืนยันโปรไฟล์",
    "Documents verification": "การยืนยันเอกสาร",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "การยืนยันโปรไฟล์หมายถึงการมอบเอกสารราชการที่ยืนยันตัวตนของลูกค้า ฝ่ายความปลอดภัยของบริษัทสามารถเริ่มขั้นตอนดังกล่าวได้ทุกเวลา",
    "Upload Photo": "อัปโหลดภาพ",
    "Nickname": "ชื่อเล่น",
    "First Name": "ชื่อ",
    "Last Name": "นามสกุล",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "ต้องระบุชื่อตามที่สะกดในเอกสาร หากมอบข้อมูลที่ไม่ถูกต้อง บัญชีอาจถูกลบโดยไม่ต้องแจ้งเตือน",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "ต้องระบุนามสกุลตามที่สะกดในเอกสาร หากมอบข้อมูลที่ไม่ถูกต้อง บัญชีอาจถูกลบโดยไม่ต้องแจ้งเตือน",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "ไม่สามารถเปลี่ยนวันเดือนปีเกิดของคุณได้อีก หากคุณระบุไม่ถูกต้อง คุณจะไม่สามารถถอนเงินจากบัญชี",
    "Empty": "ว่าง",
    "Date of birth": "วันเดือนปีเกิด",
    "Unverified": "ยังไม่ยืนยัน",
    "Verified": "ยืนยันแล้ว",
    "Resend": "ส่งอีกครั้ง",
    "Country": "ประเทศ",
    "Address": "ที่อยู่",
    "Change Identity Info": "เปลี่ยนข้อมูลตัวตน",
    "Identity Info": "ข้อมูลตัวตน",
    "Two-factor authentication on login": "การยืนยันสองขั้นตอนสำหรับเข้าสู่ระบบ",
    "Two-factor authentication on withdrawal": "การยืนยันสองขั้นตอนสำหรับการถอนเงิน",
    "Active": "เปิดใช้งาน",
    "Inactive": "ปิดใช้งาน",
    "Setup": "ตั้งค่า",
    "Two-step verification": "การยืนยันสองขั้นตอน",
    "Security": "ความปลอดภัย",
    "Google Authenticator": "Google Authenticator",
    "Scan the QR code": "สแกนรหัส QR",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "เราให้ความสำคัญกับความปลอดภัยของคุณอย่างจริงจัง ซึ่งเป็นเหตุผลว่าทำไมเราจึงต้องให้ใช้การยืนยัน 2 ขั้นตอน ขั้นตอนการตั้งค่าเป็นดังต่อไปนี้",
    "Install ": "ติดตั้ง",
    "Can't scan?": "สแกนไม่ได้?",
    "Click here.": "คลิกที่นี่",
    "Enter this code into Google Authenticator.": "กรอกรหัสนี้บน Google Authenticator",
    "Back to QR code.": "กลับยังรหัส QR",
    "Enter the 6-digit code from Authenticator below": "กรอกรหัส 6 หลักจาก Authenticator ด้านล่าง",
    "Enter 6-digit code...": "กรอกรหัส 6 หลัก...",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "เยี่ยมมาก คุณทำการยืนยัน 2 ขั้นตอนเรียบร้อยแล้ว ครั้งต่อไปที่เข้าสู่ระบบ คุณจะถูกขอให้กรอกรหัส",
    "Code": "รหัส",
    "contact support": "ติดต่อฝ่ายสนับสนุน",
    "If you ever lose access to your 2-step verification device, please ": "หากคุณสูญเสียการเข้าถึงอุปกรณ์ที่เปิดการยืนยัน 2 ขั้นตอน กรุณา",
    "Yes, activate": "ใช่ เปิดใช้งาน",
    "Cancel": "ยกเลิก",
    "Close": "ปิด",
    "Language": "ภาษา",
    "Timezone": "เขตเวลา",
    "Delete Account": "ลบบัญชี",
    "Verification Credit / Debit Cards": "การยืนยันบัตรเครดิต/บัตรเดบิต",
    "Add New Card": "เพิ่มบัตรใหม่",
    "Wait for loading...": "กำลังโหลด...",
    "You don’t have any credit / debit cards for verification": "คุณไม่มีบัตรเครดิต/บัตรเดบิตสำหรับการยืนยัน",
    "Verificate": "ยืนยัน",
    "Verification Card": "การยืนยันบัตร",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "กรุณาวางบัตรประชาชน (แสดงภาพ ชื่อและวันเดือนปีเกิด) พร้อมกับด้านหน้าของบัตรธนาคารของคุณ (แสดงหมายเลข วันหมดอายุและชื่อของคุณ) บนกระดาษเปล่าขนาด A4<br> กรุณาเขียน \"Quotex.io\" และลงลายมือชื่อลงบนกระดาษ<br>ถ่ายภาพและอัปโหลดภาพดังกล่าว",
    "Card:": "บัตร:",
    "Type:": "ประเภท:",
    "Your ID and bank card on an A4 sheet": "บัตรประชาชนและบัตรธนาคารบนกระดาษ A4",
    "Yes, confirm": "ใช่ ฉันยืนยัน",
    "Click or drag the image": "คลิกหรือลากภาพ",
    "Card": "บัตร",
    "Documents": "เอกสาร",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "ขั้นตอนแรก กรุณากรอกส่วน \"ข้อมูลยืนยันตัวตน\" ในโปรไฟล์ของคุณ และคลิกที่ปุ่ม \"เปลี่ยนข้อมูลยืนยันตัวตน\" เพื่อบันทึกการเปลี่ยนแปลง คุณจะสามารถเพิ่มบัตรใบใหม่หลังจากนั้น",
    "Specify the card details for further operations for depositing and debiting funds.": "กรอกข้อมูลบัตรสำหรับการดำเนินการเพิ่มเติมสำหรับการฝากและถอนเงิน",
    "Card number:": "หมายเลขบัตร:",
    "Expiry month:": "เดือนหมดอายุ:",
    "Expiry year:": "ปีหมดอายุ:",
    "Cardholder name:": "ชื่อผู้ถือบัตร:",
    "Next": "ต่อไป",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "กรุณาลงลายมือชื่อและวันที่ปัจจุบันลงบนกระดาน วางเอกสารพร้อมกับบัตรธนาคารของคุณ ถ่ายภาพและอัปโหลดภาพดังกล่าวในรูปแบบต่อไปนี้",
    "Do you have a two-sided document?": "คุณมีเอกสารสองด้านใช่หรือไม่",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "อัปโหลดด้านหลังเอกสารเป็นไฟล์แยก และวางบนกระดาษที่มีวันที่และลายมือชื่อของคุณ",
    "Submit only valid documents": "ส่งเฉพาะเอกสารที่ยังไม่หมดอายุเท่านั้น",
    "Do not edit or crop photo": "อย่าแก้ไขหรือตัดภาพ",
    "Do not upload other people's documents": "ไม่อัปโหลดเอกสารของบุคคลอื่น",
    "Read all": "อ่านทั้งหมด",
    "Uploading files": "อัปโหลดไฟล์",
    "For single-sided documents, upload only the front side.": "สำหรับเอกสารที่มีหน้าเดียว อัปโหลดเฉพาะด้านหน้า",
    "Back": "กลับ",
    "Add a card": "เพิ่มบัตร",
    "File Requirements": "ข้อกำหนดไฟล์",
    "Scanned images are not accepted for card verification": "ภาพสแกนไม่สามารถใช้ในการยืนยันบัตร",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "เรายอมรับไฟล์หนังสือเดินทางภายในประเทศหรือบัตรประชาชนของคุณเท่านั้น (ด้านหน้าและด้านหลังของเอกสารควรอัปโหลดแยก)",
    "Driving license CANNOT be accepted": "ไม่ยอมรับใบอนุญาตขับขี่",
    "External (foreign/international/travel) passport will not be accepted as well": "ไม่ยอมรับหนังสือหนังสือเดินทางภายนอกประเทศ (ต่างประเทศ/สากล/ท่องเที่ยว) เช่นกัน",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "หากเอกสารมี 2 ด้าน คุณจะต้องอัปโหลดหน้าหลังเป็นไฟล์แยกบนกระดาษแผ่นเดียวกันที่มีวันที่ปัจจุบันและลายมือชื่อของคุณ",
    "All information in the document and on the card should be clear and readable on the photo": "ข้อมูลทั้งหมดในเอกสารและบนบัตรที่ปรากฎอยู่ในภาพจะต้องชัดเจนและอ่านออก",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "ทั้งเอกสารและบัตรของคุณจะต้องอยู่ในภาพทั้งหมด ขอบเอกสารและบัตรต้องไม่ถูกตัด",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "ไม่ควรมีแสงจ้าหรือข้อบกพร่องอื่น ๆ ที่ทำให้การอ่านข้อมูลบนเอกสารและบัตรเป็นเรื่องยาก",
    "Expired documents and cards will be rejected automatically": "เอกสารและบัตรที่หมดอายุจะถูกปฏิเสธโดยอัตโนมัติ",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "หากบัตรของคุณไม่มีชื่อของคุณ คุณจะต้องอัปโหลดภาพถ่ายหน้าจอรายการเดินบัญชีธนาคารที่แสดงหมายเลขบัตรและชื่อของคุณ ในบางกรณี คุณสามารถเขียนชื่อด้วยมือลงบนกระดาษแผ่นเดียวกัน",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "ไม่อนุญาตให้ซ่อนส่วนใดส่วนหนึ่งของบัตรที่ประกอบด้วยข้อมูลที่จำเป็นต่อการยืนยันตน (ชื่อ วันเดือนปีเกิด ภาพถ่าย)",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "คุณสามารถซ่อนหมายเลขบัตร 8 หลัก 4 หลักแรกและ 4 หลักสุดท้ายควรสามารถมองเห็นอย่างชัดเจน",
    "CVV code should be hidden at all times, in every case": "คุณควรซ่อนรหัส CVV ทุกครั้งและทุกกรณี",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "ห้ามการแก้ไขไฟล์ทุกรูปแบบ ยอมรับแต่เฉพาะภาพถ่ายและภาพสแกนที่ยังไม่ผ่านแก้ไขเท่านั้น ห้ามการแก้ไขข้อมูลส่วนบุคคลในเอกสารของคุณ (ชื่อ วันเดือนปีเกิด เป็นต้น)",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "ภาพถ่ายจะต้องเป็นส่วนหนึ่งของเอกสาร (แปะกาวหรือพิมพ์) ไม่ยอมรับภาพถ่ายแยกต่างหาก",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "คุณสามารถอัปโหลดเอกสารและบัตรในชื่อที่ระบุในโปรไฟล์เท่านั้น (ชื่อที่ใช้การลงทะเบียนบัญชีของคุณ)",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "เราขอเตือนคุณว่าการส่งข้อมูลเท็จ/ปลอมอาจทำให้บัญชีถูกปิด/บล็อกจนกว่าจะได้รับ",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "บริษัทสงวนสิทธิ์ในการขอให้คุณมอบเอกสารเพิ่มเติมใด ๆ เพื่อการยืนยันบัญชี (รวมถึงภาพเซลฟีกับเอกสารของคุณ) ได้ทุกเวลา",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "หากคุณมีคำถามเกี่ยวกับการยืนยันเอกสาร คุณสามารถสร้างคำขอสนับสนุนบนแพลตฟอร์ม (ส่วน \"ช่วยเหลือ\" คือปุ่มแรกที่มีไอคอนเครื่องหมายคำถามตรงพาเนลด้านซ้ายสุด)",
    "Profile": "โปรไฟล์",
    "Deposit": "ฝากเงิน",
    "Withdrawal": "ถอนเงิน",
    "Transactions": "ธุรกรรม",
    "Trades": "เทรด",
    "Market": "ตลาด",
    "Analytics": "การวิเคราะห์",
    "My current currency": "สกุลเงินปัจจุบันของฉัน",
    "Available for withdrawal": "พร้อมถอน",
    "In the account": "ในบัญชี",
    "Percentage of turnover {percent}%": "เปอร์เซ็นต์มูลค่าซื้อขาย {percent}%",
    "Cashback {percent}%": "เงินคืน {percent}%",
    "Currency:": "สกุลเงิน:",
    "Change": "เปลี่ยนแปลง",
    "Live Account": "บัญชีสด",
    "Daily budget limit": "วงเงินงบประมาณรายวัน",
    "The value cannot exceed your total budget": "มูลค่าต้องไม่เกินยอดงบประมาณทั้งหมดของคุณ",
    "Demo Account": "บัญชีทดลอง",
    "Apply": "ใช้",
    "Edit": "แก้ไข",
    "New value": "มูลค่าใหม่",
    "Confirm": "ยืนยัน",
    "Logout": "ออกจากระบบ",
    "No, cancel": "ไม่ ฉันยกเลิก",
    "Exchange Form": "แบบการทำธุรกรรมเงินตราต่างประเทศ",
    "My Currency:": "สกุลเงินของฉัน:",
    "New Currency:": "สกุลเงินใหม่:",
    "You are exchanging": "คุณกำลังแลกเปลี่ยน",
    "You will receive": "คุณจะได้รับ",
    "Exchange Fee": "ค่าธรรมเนียมแลกเปลี่ยน",
    "Password changed": "เปลี่ยนรหัสผ่านแล้ว",
    "Old password": "รหัสผ่านเดิม",
    "New password": "รหัสผ่านใหม่",
    "Confirm new password": "ยืนยันรหัสผ่านใหม่",
    "Change Password": "เปลี่ยนรหัสผ่าน",
    "Confirm password must be equal new password value": "รหัสผ่านยืนยันจะต้องมีค่าเท่ากับรหัสผ่านใหม่",
    "About us": "เกี่ยวกับเรา",
    "Support": "บริการลูกค้า",
    "Help": "ช่วยเหลือ",
    "2-step verification": "การยืนยัน 2 ขั้นตอน",
    "Please enter the PIN-code we've just sent to your email": "กรุณากรอกรหัสลับบุคคลที่เราเพิ่งส่งให้กับคุณ",
    "Please enter the 6-digit code from your authentication app": "กรุณากรอกรหัส 6 หลักจากแอปยืนยันตนของคุณ",
    "The First Name field is required": "ต้องกรอกช่องชื่อ",
    "The Last Name field is required": "ต้องกรอกช่องนามสกุล",
    "Verification of documents": "การยืนยันเอกสาร",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "กรุณาอัปโหลดภาพถ่ายหรือภาพสแกนสีหนังสือเดินทางบุคคลทั่วไป ใบอนุญาตขับขี่หรือบัตรประจำตัวประชาชนของคุณ",
    "Upload Documents": "อัปโหลดเอกสาร",
    "Why?": "ทำไม",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "คุณยืนยันจะลบบัญชีอย่างถาวรหรือไม่ การกระทำนี้ไม่สามารถย้อนกลับ",
    "Set up 2-step verification": "ตั้งค่าการยืนยัน 2 ขั้นตอน",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "ขออภัย คุณไม่สามารถอัปโหลดไฟล์ในส่วนนี้ <br>กรุณาอ้างอิงข้อความจากทีมสนับสนุนและแนบไฟล์ที่กำหนดในคำร้องขอ",
    "The uploaded document does not match the requirements": "เอกสารที่อัปโหลดไม่ตรงกับข้อกำหนด",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "เอกสารที่สามารถใช้ในการยืนยันตนคือ บัตรประชาชน หนังสือเดินทาง หนังสือเดินทางระหว่างประเทศหรือใบอนุญาตขับขี่",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "ภาพควรมีคุณภาพสูง ข้อมูลควรอ่านง่ายและไม่ตัดขอบเอกสาร",
    "Balance from {amount}": "ยอดเงินตั้งแต่ {amount}",
    "Not verified": "ไม่ได้ยืนยัน",
    "Clear": "ล้าง",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "ในการหลีกเลี่ยงข้อจำกัดการบริการ กรุณาดำเนินการยืนยันให้เรียบร้อย: กรอกข้อมูลส่วนบุคคลของคุณและอัปโหลดเอกสารที่กำหนด",
    "Get verified": "รับการยืนยัน",
    "I got it!": "ฉันเข้าใจแล้ว!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "การยืนยัน AADHAAR",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "กรุณาอัปโหลดภาพสีบัตรแข็ง Aadhaar (เฉพาะด้านหน้าที่มีหมายเลขเอกสารและภาพตัวคุณ)",
    "Send AADHAAR": "ส่ง AADHAAR",
    "AADHAAR number:": "หมายเลข AADHAAR:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "กรุณาอัปโหลดภาพสีบัตรแข็ง Aadhaar (เฉพาะด้านหน้าที่มีหมายเลขเอกสารและภาพตัวคุณ) ☝ ไม่รับเอกสารอิเล็กทรอนิกส์",
    "You have already uploaded {number} files. Please wait until they are checked.": "คุณอัปโหลดแล้ว {number} ไฟล์ กรุณารอจนกว่าเอกสารจะถูกตรวจสอบ",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "กรุณาอัปโหลดภาพถ่ายสีเอกสารของคุณ: สำหรับ RG - อัปโหลดด้านหน้าและด้านหลังแยกกัน สำหรับ CNH - เฉพาะด้านหน้าที่มีภาพตัวคุณ เอกสารต้องไม่หมดอายุ (น้อยกว่า 10 ปีนับจากวันที่ออก) และต้องแสดงหมายเลข CPF ของคุณ *ในกรณีที่เอกสารของคุณไม่มีหมายเลข CPF - กรุณาอัปโหลดภาพ Comprovante de Inscrição no CPF หรือภาพจับหน้าจอรายการเดินบัญชีธนาคารของคุณที่แสดงชื่อและหมายเลข CPF ของคุณต่างหาก **กรุณาอย่าอัปโหลดเอกสารอิเล็กทรอนิกส์ เราไม่ยอมรับเอกสารอิเล็กทรอนิกส์",
    "Submit for verification": "ส่งการยืนยัน",
    "Settings": "ตั้งค่า",
    "Please upload a color photograph of the front of your national identity card with your photo.": "กรุณาอัปโหลดภาพถ่ายสีด้านหน้าบัตรประชาชนที่มีภาพของคุณ",
    "Template": "แผ่นแบบ",
    "Light Mode": "โหมดกลางวัน",
    "Twilight": "สนธยา",
    "Full Night": "ดึกดื่น",
    "Grid's opacity": "ความทึบจางของตาราง",
    "Auto-scrolling": "เลื่อนอัตโนมัติ",
    "Automatic graphic scrolling": "เลื่อนกลับอัตโนมัติ",
    "1-click trade": "เทรดในคลิกเดียว",
    "Open trades without confirmation": "เปิดการเทรดโดยไม่ต้องยืนยัน",
    "Use timezone": "ใช้เขตเวลา",
    "Use your timezone data": "ใช้ข้อมูลเขตเวลาของคุณ",
    "Graph colors": "สีกราฟ",
    "Up Trend": "แนวโน้มขาขึ้น",
    "Down Trend": "แนวโน้มขาลง",
    "Platform": "แพลตฟอร์ม",
    "Short order label": "ป้ายคำสั่งแบบสั้น",
    "Use short order element mode": "ใช้โหมดป้ายคำสั่งแบบสั้น",
    "Perfomance Mode": "โหมดสมรรถภาพ",
    "Use optimized rendering for chart and candles": "ใช้การเรนเดอร์ที่เหมาะที่สุดสำหรับกราฟและแท่งเทียน",
    "Background": "เบื้องหลัง",
    "Choose file": "เลือกไฟล์",
    "Max size — 2 Mb": "ขนาดไม่เกิน 2 Mb",
    "Sign up": "ลงทะเบียน",
    "Sign in": "ลงชื่อเข้าใช้",
    "Budget and limits": "งบประมาณและวงเงินสูงสุด",
    "Daily limit": "วงเงินสูงสุดต่อวัน",
    "Not set": "ไม่ได้กำหนด",
    "Total funds": "ยอดเงินทุน",
    "Top up": "ฝากเงิน",
    "Set up": "ตั้งค่า",
    "Set a daily limit on trading to reduce financial risks.": "ตั้งวงเงินสูงสุดรายวันสำหรับการเทรดเพื่อลดความเสี่ยงทางการเงิน",
    "Limit amount": "จำนวนสูงสุด",
    "More": "เพิ่มเติม",
    "Leader Board": "กระดานผู้นำ",
    "Signals": "สัญญาณ",
    "Notifications": "การแจ้งเตือน",
    "mark all as read": "ทำเครื่องหมายว่าอ่านแล้ว",
    "No notifications": "ไม่มีการแจ้งเตือน",
    "No more notifications": "ไม่มีการแจ้งเตือนเพิ่มเติม",
    "Your position:": "ตำแหน่งของคุณ:",
    "How does this rating work?": "การจัดอันดับทำงานอย่างไร",
    "of the Day": "ของวัน",
    "Nothing has been found for current query, try another asset name.": "ไม่พบข้อมูลจากคำค้นหาดังกล่าว ทดลองชื่อสินทรัพย์อื่น",
    "My rating in the Leader Board": "อันดับของฉันบนกระดานผู้นำ",
    "Participants can only be those who trade in live mode": "ผู้เข้าร่วมจะต้องเป็นผู้ที่ทำการเทรดสดเท่านั้น",
    "Your position depends on the amount of earned money": "อันดับของคุณขึ้นอยู่กับจำนวนรายได้",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "คำนวณทุกวัน ตั้งแต่เวลา 00:00 UTC จนถึง 23:59 UTC",
    "All traders on our platform participate in this rating. Main features of the rating:": "เทรดเดอร์ทุกคนบนแพลตฟอร์มของเราเข้าร่วมการจัดอันดับนี้ ฟีเจอร์หลักของการจัดอันดับ:",
    "How does it work?": "วิธีการทำงาน",
    "Trading signals": "สัญญาณเทรด",
    "Past signals": "สัญญาณในอดีต",
    "What’s it?": "นั่นคืออะไร",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "สัญญาณเทรดไม่ใช่คำแนะนำการเทรดโดยตรง แต่เป็นคำแนะนำจากนักวิเคราะห์ คุณไม่ควรใช้สัญญาณเทรดทดแทนการค้นคว้าตลาดด้วยตนเอง",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "คุณมีเวลาประมาณ 10 นาทีในการใช้ประโยชน์จากสัญญาณเทรด หลังผ่านช่วงเวลาดังกล่าว ราคาอาจมีการเปลี่ยนแปลงและส่งผลกระทบที่ไม่คาดคิดต่อผลลัพธ์การเทรด",
    "Continue": "ดำเนินการต่อ",
    "Duration": "ระยะเวลา",
    "Return back to FAQ": "กลับไปยังคำถามที่พบบ่อย",
    "Return back to Help": "กลับไปยังความช่วยเหลือ",
    "How to withdraw money from the account?": "วิธีถอนเงินจากบัญชี",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "กระบวนการถอนเงินเรียบง่ายและดำเนินการบนบัญชีส่วนบุคคลของคุณ",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "ช่องทางที่คุณใช้ฝากเงินเข้าบัญชีจะยังเป็นช่องทางการถอนเงินเช่นกัน (ดูคำถาม \"ฉันจะฝากเงินอย่างไร\")",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "ตัวอย่างเช่น หากคุณฝากเงินเข้าบัญชีผ่านระบบชำระเงินของ Visa คุณจะต้องถอนเงินออกผ่านระบบชำระเงินของ Visa เช่นกัน",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "เมื่อเกี่ยวข้องกับการถอนเงินจำนวนมาก บริษัทอาจร้องขอการยืนยัน (บริษัทร้องขอการยืนยันตามดุลยพินิจของบริษัท) ซึ่งเป็นเหตุผลสำคัญว่าทำไมต้องลงทะเบียนด้วยตนเองเพื่อยืนยันสิทธิ์ของคุณทุกครั้ง",
    "What is the minimum withdrawal amount?": "จำนวนถอนเงินขั้นต่ำคือเท่าไหร่",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "จำนวนเงินถอนขั้นต่ำเริ่มตั้งแต่ 10 USD สำหรับระบบชำระเงินส่วนใหญ่<br>จำนวนเงินถอนขั้นต่ำของสกุลเงินดิจิทัลเริ่มตั้งแต่ 50 USD (หรืออาจสูงกว่าในบางสกุลเงินดิจิทัล เช่น Bitcoin)",
    "Do I need to provide any documents to make a withdrawal?": "ฉันจำเป็นต้องมอบเอกสารใดบ้างในการถอนเงิน",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "โดยปรกติแล้ว คุณไม่จำเป็นต้องใช้เอกสารเพิ่มเติมในการถอนเงิน แต่บริษัทอาจขอให้คุณยืนยันข้อมูลส่วนบุคคลโดยการร้องขอเอกสารเพิ่มเติมตามดุลยพินิจของเรา เพื่อป้องกันกิจกรรมที่เกี่ยวข้องกับการค้าที่ผิดกฎหมาย การทุจริตทางการบัญชี เช่นเดียวกับการใช้เงินทุนที่ได้มาอย่างผิดกฎหมาย",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "รายการเอกสารที่ต้องมอบนั้นก็เท่าที่จำเป็น และการมอบเอกสารดังกล่าวจะไม่เสียเวลานานนัก",
    "How long does it take to withdraw funds?": "ถอนเงินใช้เวลานานแค่ไหน",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "โดยเฉลี่ยแล้ว กระบวนการถอนเงินจะใช้เวลาตั้งแต่ 1 จนถึง 5 วัน นับตั้งแต่วันที่ได้รับคำขอของลูกค้าและขึ้นอยู่กับจำนวนคำขอที่ดำเนินการในเวลาเดียวกันเท่านั้น บริษัทพยายามจ่ายเงินภายในวันที่ได้รับคำขอจากลูกค้า",
    "Is there any fee for depositing or withdrawing funds from the account?": "บริษัทเรียกเก็บค่าธรรมเนียมฝากเงินหรือถอนเงินจากบัญชีหรือไม่",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "ไม่ บริษัทไม่เรียกเก็บค่าธรรมเนียมฝากเงินหรือถอนเงินแต่อย่างใด",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "อย่างไรก็ตาม คุณควรทราบว่า ระบบชำระเงินอาจเรียกเก็บค่าธรรมเนียมและใช้อัตราแลกเปลี่ยนของระบบชำระเงินเอง",
    "Can I close my account? How to do it?": "ฉันสามารถปิดบัญชีได้ไหม วิธีปิดบัญชี",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "คุณสามารถลบบัญชีในบัญชีส่วนบุคคลโดยการคลิกที่ปุ่ม \"ลบบัญชี\" ที่อยู่ด้านล่างของหน้าโปรไฟล์",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "หากฉันกรอกข้อมูลโปรไฟล์ของฉันผิดพลาด ฉันจะแก้ไขอย่างไร",
    "You have to contact the technical support service on the Company's website and edit the profile.": "คุณจะต้องติดต่อฝ่ายบริการสนับสนุนทางเทคนิคบนเว็บไซต์ของบริษัทและแก้ไขโปรไฟล์",
    "What data is required to register on the Company website?": "ข้อมูลที่จำเป็นในการลงทะเบียนบนเว็บไซต์ของบริษัทมีอะไรบ้าง",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "ในการทำเงินผ่านดิจิตอลออปชัน คุณต้องเปิดบัญชีที่ทำให้คุณสามารถทำการเทรดเสียก่อน ซึ่งคุณจำเป็นต้องลงทะเบียนบนเว็บไซต์ของบริษัท",
    "The registration process is simple and does not take much time.": "ขั้นตอนการลงทะเบียนเรียบง่ายและใช้เวลาไม่นาน",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "คุณจะต้องกรอกข้อมูลตามรายการคำถามบนแบบคำขอ ซึ่งคุณจะต้องกรอกข้อมูลต่อไปนี้",
    "name (in English)": "ชื่อ (ภาษาอังกฤษ)",
    "email address (indicate the current, work, address)": "ที่อยู่อีเมล (กรอกที่อยู่อีเมลที่ใช้งานปัจจุบัน)",
    "telephone (with a code, for example, + 44123 ....)": "หมายเลขโทรศัพท์ (พร้อมรหัส ตัวอย่างเช่น +44123 ....)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "รหัสผ่านที่คุณจะใช้เพื่อเข้าสู่ระบบในอนาคต (ในการลดความเสี่ยงของการเข้าถึงบัญชีส่วนบุคคลโดยไม่ได้รับอนุญาต เราแนะนำให้คุณสร้างรหัสผ่านที่ซับซ้อน โดยใช้ตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่และหมายเลข อย่าเปิดเผยรหัสผ่านให้กับบุคคลที่สาม)",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "หลังจากกรอกแบบฟอร์มเรียบร้อยแล้ว คุณจะเห็นหลากช่องทางฝากเงินเข้าบัญชีเพื่อทำการเทรด",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "ลูกค้าสามารถเปิดบัญชีสกุลเงินใดได้บ้าง ฉันสามารถเปลี่ยนสกุลเงินของบัญชีลูกค้าได้หรือไม่",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "โดยค่าเริ่มต้น บัญชีเทรดจะเปิดในสกุลเงินดอลลาร์สหรัฐ แต่คุณสามารถเปิดบัญชีในสกุลเงินต่าง ๆ ตามความเหมาะสม",
    "A list of available currencies can be found on your profile page in your Client's account.": "คุณสามารถดูรายชื่อสกุลเงินบนหน้าโปรไฟล์ในบัญชีลูกค้าของคุณ",
    "What are digital options?": "ดิจิตอลออปชันคืออะไร",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "ออปชัน - ตราสารอนุพันธ์ที่อ้างอิงสินทรัพย์อ้างอิง (ซึ่งอาจเป็นหุ้น คู่เงิน น้ำมัน เป็นต้น)",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "ดิจิตอลออปชันคือ ออปชันแบบพิเศษที่ทำกำไรจากการเคลื่อนไหวของสินทรัพย์ภายในช่วงเวลาที่กำหนด",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "ขึ้นอยู่กับเงื่อนไขที่ตกลงกันของคู่สัญญาตอนดำเนินธุรกรรม ดิจิตอลออปชันมอบรายได้แบบตายตัว (ส่วนต่างระหว่างรายได้จากการเทรดกับราคาสินทรัพย์) หรือขาดทุน (ตามจำนวนมูลค่าของสินทรัพย์) ตามเวลาที่กำหนดโดยคู่สัญญา",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "เพราะดิจิตอลออปชันซื้อขายล่วงหน้าในราคาตายตัว ทำให้คุณทราบกำไรและขาดทุนที่คาดว่าจะเกิดขึ้นก่อนดำเนินการเทรด",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "ลักษณะเฉพาะอีกข้อของดิจิตอลออปชันคือมีเวลาจำกัด ออปชันมีกำหนดเวลา (วันหมดอายุ)",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "ไม่ว่าราคาของสินทรัพย์อ้างอิงจะเปลี่ยนแปลงมากน้อยแค่ไหน (วิ่งขึ้นหรือดิ่งลงมากน้อยแค่ไหน) ในกรณีที่ออปชันเป็นไปตามที่คาดการณ์ คุณจะได้รับรายได้แบบตายตัว ความเสี่ยงของคุณจำกัดอยู่เพียงจำนวนเงินที่ซื้อออปชัน",
    "What is the expiration period of a trade?": "เวลาหมดอายุของสัญญาคืออะไร",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "เวลาหมดอายุคือเวลาที่การเทรดจะถือว่าสิ้นสุดลง (ถูกปิด) และผลลัพธ์จะถูกสรุปโดยอัตโนมัติ",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "เมื่อเปิดการเทรดดิจิตอลออปชัน คุณสามารถกำหนดเวลาของธุรกรรม (1 นาที 2 ชั่วโมง หนึ่งเดือน เป็นต้น)",
    "What is the gist of digital options trading?": "แก่นของการเทรดดิจิตอลออปชันคืออะไร",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "ดิจิตอลออปชันคือตราสารอนุพันธ์ที่เรียบง่ายที่สุด ในการทำเงินบนตลาดดิจิตอลออปชัน คุณไม่จำเป็นต้องพยากรณ์ว่าราคาตลาดของสินทรัพย์จะแตะที่ระดับเท่าไหร่",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "ทำให้หลักการเทรดเหลือเพียงแค่การตอบคำถามว่า ราคาสินทรัพย์จะวิ่งขึ้นหรืดดิ่งลง ณ เวลาปิดสัญญา",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "คุณไม่ต้องสนใจว่าราคาของสินทรัพย์อ้างอิง (นับตั้งแต่เปิดการเทรดจนสิ้นสุดการเทรด) ว่าจะวิ่ง 100 จุดหรือจุดเดียว คุณเพียงแค่ให้ความสนใจกับทิศทางการเคลื่อนไหวของราคาสินทรัพย์อ้างอิงก็พอ",
    "If your prognosis is correct, in any case you get a fixed income.": "หากการคาดการณ์ของคุณถูกต้อง คุณได้รับรายได้แบบตายตัว",
    "What are the possible results of the placed trades?": "ผลลัพธ์ที่เป็นไปได้ของการเทรดมีอะไรบ้าง",
    "There are three possible outcomes in the digital options market:": "3 ผลลัพธ์ที่เป็นไปได้ของตลาดดิจิตอลออปชันมีดังต่อไปนี้",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "ในกรณีที่การคาดการณ์ทิศทางการเคลื่อนไหวของราคาสินทรัพย์อ้างอิงถูกต้อง คุณจะได้รับรายได้",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "แต่หากการคาดการณ์ไม่เป็นไปตามที่คิด คุณจะขาดทุนเท่ากับขนาดมูลค่าสินทรัพย์ (พูดง่าย ๆ คือ คุณเสียแต่เงินลงทุน)",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "หากผลลัพธ์การเทรดออกมาเป็นศูนย์ (ราคาสินทรัพย์อ้างอิงไม่มีการเปลี่ยนแปลง สัญญาปิดในราคาเข้าซื้อ) คุณได้รับเงินลงทุนกลับคืน ระดับความเสี่ยงของคุณจะจำกัดอยู่ที่ขนาดมูลค่าสินทรัพย์เท่านั้น",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "แพลตฟอร์มเทรดของคุณมีบัญชีทดลองเพื่อทำความเข้าใจการทำงานกับดิจิตอลออปชันโดยไม่ต้องเสียเงินหรือไม่",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "ใช่ ในการพัฒนาทักษะการเทรดและทดสอบศักยภาพแพลตฟอร์มเทรดของบริษัท คุณสามารถใช้บัญชีทดลอง (ฟรี) ซึ่งเป็นเครื่องจำลองที่ทำให้คุณสามารถลองทดสอบก่อนจะเข้าสู่สนามจริง บัญชีทดลองดังกล่าวยังเหมาะสำหรับเทรดเดอร์ที่มีประสบการณ์เพื่อพัฒนาระดับทักษะของตน",
    "The balance of such an account is 10,000 units.": "ยอดเงินของบัญชีดังกล่าวอยู่ที่ $10,000",
    "What determines profit size?": "ขนาดกำไรขึ้นอยู่ปัจจัยอะไรบ้าง",
    "There are several factors that affect the size of your profit:": "ปัจจัยที่ส่งผลกระทบต่อกำไรของคุณ:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "สภาพคล่องของสินทรัพย์ที่เลือกบนตลาด (ยิ่งสินทรัพย์ดังกล่าวเป็นที่ต้องการของตลาดมากเท่าไหร่ คุณจะได้รับกำไรมากเท่านั้น)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "ช่วงเวลาเทรด (สภาพคล่องของสินทรัพย์ในตอนเช้าและสภาพคล่องของสินทรัพย์ในตอนบ่ายอาจแตกต่างกันอย่างมีนัยสำคัญ)",
    "tariffs of a brokerage company": "ค่าธรรมเนียมของโบรกเกอร์",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "การเปลี่ยนแปลงที่เกิดขึ้นในตลาด (สถานการณ์ทางเศรษฐกิจ การเปลี่ยนแปลงของสินทรัพย์การเงิน เป็นต้น)",
    "What are the varieties of digital options?": "ดิจิตอลออปชันมีกี่ประเภท",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "ในการเทรดออปชัน คุณจะต้องเลือกสินทรัพย์ที่ออปชันจะใช้อ้างอิง คุณคาดการณ์ทิศทางของสินทรัพย์นี้",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "การซื้อสัญญาดิจิตอลคือการพนันการเคลื่อนไหวของราคาสินทรัพย์อ้างอิง",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "สินทรัพย์อ้างอิงคือ \"สิ่ง\" ที่ใช้ในการอ้างอิงราคาเมื่อดำเนินการเทรด สินทรัพย์อ้างอิงของดิจิตอลออปชันที่ได้รับความนิยมมากที่สุดบนตลาดประกอบด้วยสินทรัพย์ 4 ประเภทต่อไปนี้",
    "securities (shares of world companies)": "หลักทรัพย์ (หุ้นบริษัทต่าง ๆ จากทั่วโลก)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "คู่เงิน (EUR / USD, GBP / USD เป็นต้น)",
    "raw materials and precious metals (oil, gold, etc.)": "วัตถุดิบและโลหะมีค่า (ทองคำ น้ำมัน เป็นต้น)",
    "indices (S&P 500, Dow, dollar index, etc.)": "ดัชนี (S&P 500, Dow, dollar index เป็นต้น)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "ไม่มีสินทรัพย์อ้างอิงสากล เลือกสินทรัพย์อ้างอิงที่คุณมีความรู้ สัญชาตญาณและข้อมูลการวิเคราะห์ต่าง ๆ เช่นเดียวกับการวิเคราะห์ตลาดของตราสารทางการเงินที่ต้องการลงทุน",
    "What is a trading platform and why is it needed?": "แพลตฟอร์มเทรดคืออะไร และมีความจำเป็นอย่างไร",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "แพลตฟอร์มเทรด - ซอฟต์แวร์อันซับซ้อนที่ทำให้ลูกค้าสามารถเทรดตราสารทางการเงินต่าง ๆ และยังสามารถเข้าถึงข้อมูลที่หลากหลาย เช่น มูลค่าราคา ตำแหน่งของตลาดแบบเรียลไทม์ การกระทำของบริษัท",
    "How to learn quickly how to make money in the digital options market?": "วิธีเรียนรู้การทำเงินบนตลาดดิจิตอลออปชันอย่างรวดเร็ว",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "พัฒนากลยุทธ์การเทรดของคุณ ซึ่งก็คือจำนวนสูงสุดของการคาดการณ์ถูกต้องอยู่ที่เท่าใด และทำตามนั้น",
    "diversify your risks": "กระจายความเสี่ยงของคุณ",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "ในการพัฒนากลยุทธ์ เช่นเดียวกับการค้นหาช่องทางการกระจายการลงทุน จับตาดูตลาด ศึกษาข้อมูลการวิเคราะห์และทางสถิติที่ได้มาจากแหล่งข้อมูลต่าง ๆ (อินเทอร์เน็ต ความเห็นผู้เชี่ยวชาญ นักวิเคราะห์ในอุตสาหกรรม เป็นต้น) จะช่วยคุณได้ ซึ่งเว็บไซต์ของบริษัทเป็นหนึ่งในช่องทางดังกล่าว",
    "Is the download of the program to a computer or smartphone required?": "คุณจำเป็นต้องดาวน์โหลดโปรแกรมลงคอมพิวเตอร์หรือสมาร์ทโฟนหรือไม่",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "ไม่ คุณเพียงแค่ลงทะเบียนบนเว็บไซต์ของบริษัทในแบบคำขอและเปิดบัญชีส่วนบุคคล",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "ในกรณีที่การเทรดทำกำไร บริษัทเสียอะไรบ้างในการจ่ายกำไรให้กับลูกค้า",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "บริษัททำเงินร่วมกับลูกค้า ดังนั้น บริษัทต้องการส่วนแบ่งจากธุรกรรมที่ทำกำไรมากกว่าส่วนแบ่งของธุรกรรมที่ไม่ทำกำไร เนื่องจากบริษัทได้รับเปอร์เซ็นต์ส่วนแบ่งจากกลยุทธ์การเทรดที่ประสบความสำเร็จของลูกค้า",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "นอกจากนั้นแล้ว การเทรดที่ดำเนินการโดยลูกค้าถือว่าเป็นปริมาณการเทรดของบริษัท ซึ่งจะโอนไปยังโบรกเกอร์หรือตลาดหลักทรัพย์ ซึ่งจะรวมอยู่ในกลุ่มผู้ให้บริการสภาพคล่อง ทำให้สภาพคล่องของตลาดเพิ่มขึ้น",
    "What is account verification?": "การยืนยันบัญชีคืออะไร",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "การยืนยันในดิจิตอลออปชันคือ ลูกค้ายืนยันข้อมูลส่วนบุคคลของตนโดยการมอบเอกสารเพิ่มเติมให้กับทางบริษัท เงื่อนไขการยืนยันของลูกค้านั้นไม่ซับซ้อน และรายการเอกสารที่ต้องมอบนั้นก็เท่าที่จำเป็น ตัวอย่างเช่น บริษัทอาจร้องขอ",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "สำเนาสแกนสีหน้าแรกของหนังสือเดินทางของลูกค้า (หนังสือเดินทางหน้าที่มีรูปภาพ)",
    "identify with the help of a \"selfie\" (photograph of himself)": "ยืนยันจากการถ่าย \"เซลฟี\" (ถ่ายภาพตนเอง)",
    "confirm the address of registration (residence) of the Client, etc": "ยืนยันที่อยู่ลงทะเบียน (ที่พำนักอาศัย) ของลูกค้า เป็นต้น",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "บริษัทอาจร้องขอเอกสารอื่น ๆ เพิ่มเติม หากไม่สามารถยืนยันตัวตนลูกค้าและข้อมูลที่ลูกค้ากรอกอย่างสมบูรณ์",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "หลังจากที่มอบสำเนาเอกสารอิเล็กทรอนิกส์ให้กับบริษัทแล้ว บริษัทจะใช้เวลาสักพักในการยืนยันข้อมูลที่ได้รับ",
    "How to understand that I need to go through account verification?": "ฉันจะทราบได้อย่างไรว่าฉันจะต้องทำการยืนยันบัญชี",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "หากจำเป็นต้องทำการยืนยัน คุณจะได้รับการแจ้งเตือนทางอีเมลและ/หรือ SMS",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "ในขณะเดียวกัน บริษัทใช้ข้อมูลติดต่อที่คุณระบุในแบบคำขอตอนลงทะเบียน (โดยเฉพาะที่อยู่อีเมลและหมายเลขโทรศัพท์) ดังนั้น กรุณากรอกอย่างระมัดระวังและมอบข้อมูลที่ถูกต้องและตรงกับคุณ",
    "How do I know that I successfully passed verification?": "ฉันจะทราบได้อย่างไรว่าฉันผ่านการยืนยันแล้ว",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "คุณจะได้รับการแจ้งเตือนทางอีเมลและ/หรือ SMS เกี่ยวกับการเสร็จสิ้นขั้นตอนการยืนยันบัญชีของคุณและความสามารถในการดำเนินการบนแพลตฟอร์มเทรดของบริษัท",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "คุณสามารถกรอกข้อมูลของคนอื่น (ปลอม) ตอนลงทะเบียนบนเว็บไซต์ได้ไหม",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "ไม่ ลูกค้าดำเนินการลงทะเบียนด้วยตนเองบนเว็บไซต์ของบริษัท ซึ่งมอบข้อมูลที่ถูกต้องและครบถ้วนเกี่ยวกับลูกค้าตามที่กำหนดในแบบคำขอลงทะเบียน และอัปเดตข้อมูลดังกล่าวให้เป็นปัจจุบัน",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "หากบริษัทจำเป็นต้องดำเนินการตรวจสอบตัวตนของลูกค้า บริษัทสามารถร้องขอเอกสารหรือเชิญลูกค้ามายังสำนักงานของบริษัท",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "หากข้อมูลที่กรอกตอนลงทะเบียนไม่ตรงกับข้อมูลบนเอกสารที่ส่งให้กับบริษัท โปรไฟล์ส่วนบุคคลของคุณอาจถูกบล็อก",
    "How long does the verification process take?": "ขั้นตอนการยืนยันใช้เวลานานแค่ไหน",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "ภายใน 5 (ห้า) วันทำการนับจากวันที่บริษัทได้รับเอกสารคำขอ",
    "Is there a minimum amount that I can deposit to my account at registration?": "จำนวนขั้นต่ำที่ฉันต้องฝากเข้าบัญชีตอนลงทะเบียนอยู่ที่เท่าไหร่",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "ข้อดีของแพลตฟอร์มเทรดของบริษัทคือ คุณไม่จำเป็นต้องฝากเงินจำนวนมากเข้าบัญชี คุณสามารถเริ่มต้นเทรดด้วยเงินจำนวนเล็กน้อย เงินฝากขั้นต่ำอยู่ที่ 10 ดอลลาร์สหรัฐ",
    "How can I deposit?": "ฉันจะฝากเงินอย่างไร",
    "It is very easy to do. The procedure will take a couple of minutes.": "ขั้นตอนง่ายดายมากและใช้เวลาเพียงไม่กี่นาที",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "เปิดหน้าต่างดำเนินการเทรดและคลิกที่ปุ่ม \"ฝากเงิน\" สีเขียวที่อยู่แถบมุมขวาบน",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "คุณยังสามารถฝากเงินเข้าบัญชีผ่านบัญชีส่วนบุคคลโดยการคลิกปุ่ม \"ฝากเงิน\" ในโปรไฟล์บัญชี",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "หลังจากเลือกช่องทางฝากเงินเข้าบัญชีแล้ว (บริษัทเสนอหลากหลายช่องทางที่สะดวกสำหรับลูกค้า ซึ่งอยู่ในบัญชีส่วนบุคคลของลูกค้า)",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "ถัดไป ระบุสกุลเงินที่จะฝากเงินเข้าบัญชีและตรงกับสกุลเงินของบัญชี",
    "Enter the amount of the deposit.": "กรอกจำนวนเงินฝาก",
    "Fill out the form by entering the requested payment details.": "กรอกแบบคำขอโดยกรอกข้อมูลการชำระเงินที่กำหนด",
    "Make a payment.": "ชำระเงิน",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "ฉันจำเป็นต้องฝากเงินเข้าบัญชีของแพลตฟอร์มเทรดหรือไม่และฉันต้องฝากเงินบ่อยแค่ไหน",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "ในการใช้งานดิจิตอลออปชัน คุณจะต้องเปิดบัญชีส่วนบุคคล ในการเปิดการเทรดจริง คุณจะต้องฝากเงินเข้าบัญชีตามจำนวนที่ต้องการซื้อออปชัน",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "คุณสามารถเริ่มต้นเทรดโดยไม่ต้องใช้เงินสด คุณเพียงแค่ใช้บัญชีฝึกฝน (บัญชีทดลอง) ของบริษัท บัญชีดังกล่าวไม่มีค่าธรรมเนียมและสร้างขึ้นเพี่อสาธิตการทำงานของแพลตฟอร์ม คุณสามารถฝึกฝนการเทรดดิจิตอลออปชัน ทำความเข้าใจพื้นฐานการเทรด ทดสอบวิธีการและกลยุทธ์ต่าง ๆ หรือประเมินระดับความหยั่งรู้ของคุณบนบัญชีดังกล่าว",
    "What is the minimum deposit amount?": "เงินฝากขั้นต่ำอยู่ที่เท่าไหร่",
    "{number} questions": "{number} คำถาม",
    "Verification": "การยืนยัน",
    "Payment": "การชำระเงิน",
    "Payouts": "รายได้",
    "Ask a specialist": "ถามผู้เชี่ยวชาญ",
    "FAQ": "คำถามที่พบบ่อย",
    "Didn't find an answer to your question?": "ไม่พบคำตอบที่ต้องการใช่ไหม",
    "Open knowledge base": "เปิดคลังความรู้",
    "Contact support": "ติดต่อบริการลูกค้า",
    "Open FAQ page": "เปิดหน้าคำตอบที่พบบ่อย",
    "New message": "ข้อความใหม่",
    "Tournaments": "การแข่งขัน",
    "To end": "เพื่อสิ้นสุด",
    "Until start": "จนกว่าจะเริ่มต้น",
    "Front side": "ด้านหน้า",
    "Back side (if any)": "ด้านหลัง (ถ้ามี)",
    "Tourna-ments": "ทัวร์นา-เมนต์",
    "Deletion of Account and Personal Data": "การลบบัญชีและข้อมูลส่วนบุคคล",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "โดยการลบบัญชีและข้อมูลส่วนบุคคลของคุณ คุณจะสูญเสียการเข้าถึงบัญชีของคุณบนแพลตฟอร์ม Quotex อย่างถาวร โปรดจำว่าข้อมูลของคุณจะถูกลบโดยไม่สามารถกู้คืนได้และไม่สามารถกู้คืนบัญชีของคุณในภายหลัง!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "หมายเหตุ: กรุณาปิดการเทรดที่เปิดอยู่และคำสั่งรอเปิดทั้งหมดก่อนที่จะคุณลบบัญชี",
    "Request Deletion": "ร้องขอการลบ",
    "To delete your account, please contact support service": "หากต้องการลบบัญชีของคุณ กรุณาติดต่อฝ่ายบริการลูกค้า",
    "Two-factor authorization via Email": "การยืนยันแบบสองขั้นตอนผ่านอีเมล",
    "This feature is for experienced users who are familiar with Google Authenticator.": "คุณสมบัตินี้สำหรับผู้ใช้ที่มีประสบการณ์อันคุ้นเคยกับ Google Authenticator",
    "Please note!": "กรุณาทราบ!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "หากคุณสูญเสียการเข้าถึง Google Authenticator ด้วยเหตุผลใดก็ตามภายหลังการเชื่อมต่อ คุณจะสูญเสียการเข้าถึงบัญชี",
    "Proceed to setup": "ดำเนินการตั้งค่า",
    "Account protection method": "วิธีการปกป้องบัญชี",
    "Here's how to set it up:": "วิธีตั้งค่า",
    "Please enter the PIN-code we've just sent to your email:": "กรุณากรอกรหัสลับบุคคลที่เราเพิ่งส่งให้ทางอีเมล",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "เยี่ยมมาก คุณเปิดใช้การยืนยันแบบ 2 ขั้นตอนผ่าน Google Authenticator เรียบร้อยแล้ว ครั้งต่อไปที่คุณลงชื่อเข้าใช้ คุณจะต้องกรอกรหัส",
    "If you ever lose access to your 2-step verification device, please": "หากคุณสูญเสียการเข้าถึงอุปกรณ์ที่ใช้การยืนยันแบบ 2 ขั้นตอน กรุณา",
    "Confirm account deletion": "ยืนยันการลบบัญชี",
    "To delete your account, follow the link in the email sent to": "หากต้องการลบบัญชีของคุณ คลิกที่ลิงก์ในอีเมลที่ส่งไป",
    "Image requirements": "ข้อกำหนดรูปภาพ",
    "Email": "อีเมล",
    "Сonfirmation": "การยืนยัน",
    "Change protection method": "เปลี่ยนวิธีปกป้อง",
    "First, enter the code from the Google Authenticator app:": "ขั้นแรก กรอกรหัสจากแอป Google Authenticator:",
    "First, enter the PIN-code we've just sent to your email:": "ขั้นแรก กรอกรหัสลับบุคคลที่เราเพิ่งส่งเข้าอีเมลของคุณ:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "เยี่ยมมาก คุณเปิดใช้การยืนยันแบบสองขั้นตอนผ่านอีเมลเรียบร้อยแล้ว",
    "Receiving codes via Email": "รับรหัสทางอีเมล",
    "Receiving codes via Google Authenticator": "รับรหัสทาง Google Authenticator",
    "Choose a method:": "เลือกช่องทาง:",
    "To enter the platform": "เพื่อเข้าสู่แพลตฟอร์ม",
    "To withdraw funds": "เพื่อถอนเงิน",
    "day": "วัน",
    "days2": "วันที่2",
    "days5": "วันที่5",


text:'การเลือกสกุลเงิน',
text1:'อีเมล',
text2:'ยกเลิกธุรกรรมสำหรับ',
text3:'โค้ดคูปองฟรี',
text4:'เราปรับปรุงแพลตฟอร์มนี้ให้ดียิ่งขึ้นทุกวันเพื่อความสะดวกขึ้น',
text5:'คุณชอบแพลตฟอร์มนี้หรือไม่? กรุณาแชร์ให้คนอื่นรู้!',
text6:'กรุณาแสดงความคิดเห็นของคุณเกี่ยวกับ QUOTEX คุณจะได้รับการยกเลิกธุรกรรมฟรี',
text7:'ส่งลิงก์ความคิดเห็นของคุณให้ทีมบริการลูกค้าของเรา หลังจากทีมตรวจสอบเสร็จ คุณจะได้รับการตอบแทนนี้',
text8:'ยอดฝากขั้นต่ำ',
text9:'ยอดถอนขั้นต่ำ',
text10:'ถอนเร็ว',
text11:'ฟรี',
text12:'ยังไม่ได้ตรวจสอบ',
text13:'ส่งซ้ำ',
text14:'ยืนยันการเข้าร่วมของคุณ',
text15:'ยกเลิกธุรกรรม',
text16:'กรุณาป้อนและเปิดใช้โค้ดคูปองของคุณทันที คุณสามารถใช้โค้ดคูปองได้ตลอดเวลา',
text17:'โค้ดคูปอง:',
text18:'รับโบนัส 30% ของยอดฝาก',
text19:'สกุลเงินปัจจุบัน',
text20:'ยอดเงินที่สามารถถอนได้',
text21:'ในบัญชี',
text22:'วันนี้',
text23:'เมื่อวาน',
text24:'สัปดาห์',
text25:'เดือน',
text26:'ปริมาณการซื้อขายสุทธิ',
text27:'WELCOME เป็นโบนัส 30% สำหรับยอดฝากครั้งแรก',
text28:'ยอดฝากครั้งแรกคือ $100 ในการรับโบนัส 30% โปรดเลือกวิธีการฝากเงิน',
text29:'บัตรเครดิต',
text30:'ธนาคาร',
text31:'วิธีการชำระเงินที่เลือก:',
text32:'ยอดขั้นต่ำ:',
text33:'ยอดสูงสุด:',
text34:'เลือกวิธีการอื่น',
text35:'ข้อมูลการชำระเงิน',
text36:'จำนวน',
text37:'ใช้โบนัส',
text38:'ข้อกำหนดและเงื่อนไข',
text39:'ฉันมีโค้ดคูปอง',
text40:'โค้ดคูปอง',
text41:'ใช้โค้ดคูปอง',
text42:'โค้ดคูปองที่ป้อนถูกต้อง คุณจะได้รับโบนัส 30%',
text43:'คุณจะได้รับโบนัส',
text44:'%',
text45:'กรุณาอ่านคำถามที่พบบ่อยทั้งหมด',
text46:'ในการซื้อขายสินทรัพย์ดิจิทัล คุณต้องเปิดบัญชีส่วนบุคคล ในการทำธุรกรรมจริง คุณจะต้องฝากจำนวนตามราคาตัวเลือก',
text48:'คุณมี {0} โค้ดคูปอง',
text49:'ใช้โค้ดคูปองนี้เพื่อยกเลิกธุรกรรมที่เสีย',
text50:'คุณสามารถยกเลิกธุรกรรมที่ไม่ได้รับการป้องกันเท่านั้น มูลค่าไม่เกินค่าสูงสุดของโค้ดคูปอง',
text51:'สถานะ',
text52:'ใช้',
text53:'คุณยังไม่มีประวัติของโค้ดคูปอง คุณสามารถเพิ่มโค้ดคูปองได้โดยใช้ปุ่มด้านล่าง',
text54:'แสดงประวัติ',
text55:'ป้อนโค้ดคูปอง',
text56:'ข้อมูลส่วนตัว',
text57:'ว่างเปล่า',
text58:'ชื่อ',
text59:'นามสกุล',
text60:'วันเกิด',
text61:'ประเทศ',
text62:'บันทึก',
text63:'ที่อยู่',
text64:'การตรวจสอบเอกสาร',
text65:'ก่อนที่จะตรวจสอบบัญชี จำเป็นต้องใส่ข้อมูลส่วนตัว',
text66:'ความปลอดภัย',
text67:'การตรวจสอบสองขั้นตอน',
text68:'เปลี่ยนรหัสผ่าน',
text69:'รหัสผ่านเก่า',
text70:'รหัสผ่านใหม่',
text71:'ใส่รหัสผ่านใหม่อีกครั้ง',
text72:'ปิดบัญชี',
text73:'การตรวจสอบบัตรเครดิตหรือบัตรเดบิต',
text74:'เพิ่มบัตรเครดิตใหม่',
text75:'คุณไม่มีบัตรเครดิตหรือบัตรเดบิตที่ต้องการตรวจสอบ',
text76:'คุณยังไม่มีประวัติการทำธุรกรรม',
text77:'เริ่ม',
text78:'สิ้นสุด',
text79:'ใช้ตัวกรอง',
text80:'ก่อนหน้า',
text81:'ถัดไป',
text82:'กำไร',
text83:'ถูกยกเลิก',
text84:'บัญชี',
text85:'ค่าธรรมเนียม',
text86:'คุณสามารถถอนเงินจากบัญชีของพลัตฟอร์มไปยังบัตรเครดิตหรือกระเป๋าเงินอิเล็กทรอนิกส์ที่คุณใช้ในการฝากเงิน คุณสามารถขอถอนเงินได้ทุกเมื่อ คำขอถอนเงินของคุณจะถูกประมวลผลภายใน 3 วันทำการ',
text87:'ฝากเงิน',
text88:'คำขอก่อนหน้าบางคำ',
text89:'ประวัติการถอนเงิน',
text90:'คำขอของฉัน',
text91:'ส่งคำขอ',
text92:'คำถามที่พบบ่อย',
text93:'คุณไม่มีคำขอที่ส่ง',
text94:'หากคุณมีคำถาม คุณสามารถหาคำตอบได้ในคำถามที่พบบ่อย',
text95:'ลิขสิทธิ์ © 2024 Quotex สงวนสิทธิ์',
text96:'เข้าสู่ระบบ',
text97:'รหัสผ่าน',
text98:'จดจำฉัน',
text99:'กลับไปยังข้อมูล',
text100:'เสร็จสิ้น',

text104:'แพลตฟอร์มนวัตกรรมเพื่อการลงทุนอัจฉริยะ',
   text105: 'ลงทะเบียนและรับ $10,000 ในบัญชีทดลองที่คุณเรียนรู้การซื้อขาย',
   text106:'ลงทะเบียน',
   text107:'เปิดบัญชีทดลอง',
   text108:'อินเทอร์เฟซการซื้อขายที่สะดวกสบาย',
   text109:'เราได้สร้างอินเทอร์เฟซที่เรียบง่ายและสะดวกที่สุด ดังนั้นคุณจึงไม่หลงทางจากสิ่งที่สำคัญที่สุด นั่นก็คือการซื้อขาย' ,
   text110:'สัญญาณในตัว',
   text111:'พิจารณากลยุทธ์การซื้อขาย เราให้สัญญาณที่แม่นยำและสร้างสรรค์ที่สุดสูงถึง 87% เพื่อช่วยให้คุณพัฒนากลยุทธ์ที่มีประสิทธิภาพของคุณเอง ',
   text112:'ตัวบ่งชี้การซื้อขาย',
   text113:'เราได้รวบรวมตัวชี้วัดการซื้อขายที่เป็นประโยชน์มากที่สุด ใช้ตัวบ่งชี้นี้เพื่อเพิ่มยอดเงินในบัญชีของคุณ',
   text114:'ความเร็วที่สมบูรณ์แบบ',
   text115: 'แพลตฟอร์มของเราใช้เทคโนโลยีที่ทันสมัยที่สุดและมอบความเร็วอันมหาศาล',
   text116:'ลองใช้บัญชีทดลอง',
   text117:'เพื่อที่จะทำเงินได้มากขึ้น',
   text118:'พยากรณ์โรคให้ถูกต้อง',
   text119:'ราคาเป็นขาขึ้นหรือขาลง?',
   text120:'เปิดบัญชีทดลอง',
   text121:'คลิก',
   text122:'บนปุ่มใดปุ่มหนึ่งและดูผลลัพธ์',
   text123:'ราคาจะสูงขึ้น',
   text124:'ราคาจะลดลง',
   text125:'กำลังรอผล...',
   text126:'กำไรของคุณ',
   text127:'ขออภัย การคาดคะเนของคุณไม่ถูกต้อง',
   text128:'ลองอีกครั้ง',
   text129:'กำไรของคุณ',
   text130:'ขอแสดงความยินดี! คำทำนายของคุณถูกต้อง',
   text131:'เริ่มสร้างรายได้',
   text132:'แอปมือถือพร้อมใช้งานเสมอ',
   text133: 'ดาวน์โหลดแอปที่ใช้งานง่ายของเราสำหรับ iPhone หรือ Android และเริ่มซื้อขาย!',
   text134:'เวลาที่ว่าง',
   text135:'เริ่มการซื้อขาย',
   text136:'3 ขั้นตอน',
   text137:'เปิดบัญชีจริงฟรีในเวลาเพียงไม่กี่นาที',
   text138:'ซื้อขายในบัญชีทดลองได้ด้วยคลิกเดียว',
   text139:'ฝึกซ้อม',
   text140:'พัฒนาทักษะของคุณด้วยบัญชีทดลองและเอกสารการฝึกอบรม',
   text141:'เริ่มการสอนของคุณด้วยบัญชีทดลอง',
   text142:'ฝากเข้าบัญชีจริงของคุณและเริ่มซื้อขาย',
   text143:'ตราสารมากกว่า 410 รายการ จำนวนธุรกรรมขั้นต่ำคือ $10',
   text144:'วิธีฝากเงินแบบกระโดด',
   text145:'คำถามที่พบบ่อย',
   text146:'จะเชี่ยวชาญได้อย่างไร?',
   text147:'ลงทะเบียนบัญชีทดลองและฝึกฝนโดยใช้บัญชีนี้ บัญชีทดลองเหมือนกับบัญชีจริง แต่ไม่จำเป็นต้องฝากเงิน',
   text148:'การถอนเงินใช้เวลานานแค่ไหน?',
   text149: 'โดยเฉลี่ยแล้ว กระบวนการถอนเงินจะใช้เวลาระหว่าง 1 ถึง 5 วันในการดำเนินการนับจากวันที่ได้รับคำขอของผู้ใช้ที่เกี่ยวข้อง และขึ้นอยู่กับจำนวนคำขอที่ประมวลผลพร้อมกันเท่านั้น บริษัทมุ่งมั่นที่จะชำระเงินตามคำขอของผู้ใช้โดยตรงในวันที่ได้รับคำขอ',
   text150:'แพลตฟอร์มการซื้อขายคืออะไร? ผลกระทบคืออะไร?',
   text151: 'แพลตฟอร์มการซื้อขายคือระบบซอฟต์แวร์ ผู้ใช้ใช้เครื่องมือทางการเงินที่จัดทำโดยระบบนี้เพื่อทำธุรกรรม (การดำเนินการ) และยังใช้มูลค่าราคาเสนอ สถานการณ์ตลาดแบบเรียลไทม์ การดำเนินการของบริษัท และข้อมูลอื่น ๆ ที่บริษัทให้ไว้',
   text152:'ฉันสามารถใช้โทรศัพท์มือถือทำธุรกรรมได้หรือไม่?',
   text153:'แพลตฟอร์มของเราใช้เทคโนโลยีที่ทันสมัยที่สุดและสามารถเปิดได้ในเบราว์เซอร์ของคอมพิวเตอร์หรือสมาร์ทโฟนเครื่องใดก็ได้',
   text154:'จำนวนเงินฝากขั้นต่ำคือเท่าไร?',
   text155: 'ข้อดีของแพลตฟอร์มการซื้อขายของบริษัทนี้คือคุณไม่จำเป็นต้องฝากเงินจำนวนมากเข้าบัญชีของคุณ คุณสามารถเริ่มต้นการซื้อขายด้วยการลงทุนจำนวนเล็กน้อย เงินฝากขั้นต่ำคือ $10',
   text156:'มีค่าธรรมเนียมในการฝากหรือถอนเงินจากบัญชีหรือไม่?',
   text157:'ไม่. บริษัทไม่คิดค่าคอมมิชชั่นใดๆ สำหรับการดำเนินงาน เช่น การฝากบัญชีหรือการถอนเงิน',
   text158: 'อย่างไรก็ตาม ต้องสังเกตว่าระบบการชำระเงินอาจเรียกเก็บค่าคอมมิชชันและใช้อัตราแลกเปลี่ยนเงินตราส่วนต่าง',
   text159:'ไปที่คำถามทั้งหมด',
   text160:'Quotex: แพลตฟอร์มนวัตกรรม',
   text161:'การซื้อขายสินทรัพย์ดิจิทัล',
   text162:'เปิดบัญชีจริง',
   text163:'พันธมิตร',
   text164:'บัญชีทดลอง',
   text165:'ข้อมูลการติดต่อ',
   text166:'คำถามสำคัญ',
   text167:'ปัญหาทางการเงิน',
   text168:'การยืนยัน',
   text169:'ข้อบังคับ',
   text170:'นโยบายความเป็นส่วนตัว',
   text171:'เงื่อนไขการชำระเงิน',
   text172:'ข้อตกลงการบริการ',
   text173:'คำเตือนความเสี่ยง',
   text174:'กฎการดำเนินการซื้อขาย',
   text175:'กฎการดำเนินการที่ไม่ใช่การซื้อขาย',
   text176:`คำเตือนความเสี่ยง: การซื้อขายบนแพลตฟอร์มการซื้อขายสินทรัพย์ดิจิทัลและการใช้เครื่องมือให้กู้ยืมเงินอาจเกี่ยวข้องกับความเสี่ยงที่สำคัญและอาจส่งผลให้สูญเสียเงินลงทุนทั้งหมด อย่าลงทุนเกินกว่าที่คุณสามารถจ่ายได้ และคุณควรเข้าใจถึงความเสี่ยงที่เกี่ยวข้อง การซื้อขายโดยใช้เครื่องมือทางการเงินที่ยืมมาไม่เหมาะสำหรับนักลงทุนส่วนใหญ่ การซื้อขายโดยไม่ใช้เงินทุนที่ยืมมา เช่น หุ้นก็มีความเสี่ยงเช่นกัน เนื่องจากราคาหุ้นสามารถลดลงหรือเพิ่มขึ้นได้ ซึ่งอาจหมายความว่าแม้ว่าคุณจะชนะ คุณก็จะได้รับคืนน้อยกว่าที่คุณลงทุนมาก
   ความสำเร็จในอดีตไม่ได้รับประกันความสำเร็จในอนาคต ก่อนทำการซื้อขาย โปรดใส่ใจกับประสบการณ์และระดับการซื้อขายของคุณ เป้าหมายการลงทุน และติดต่อที่ปรึกษาทางการเงินอิสระหากจำเป็น ผู้ใช้ควรพิจารณาว่าการใช้บริการที่มีตราสินค้า Quotex นั้นได้รับอนุญาตตามกฎหมายและข้อบังคับของประเทศที่พำนักหรือไม่ `
}