<template>
  <div id="root">
    <router-view  />
  </div>
</template>
<script>
export default {
  mounted(){

  },
  methods:{
    setId(innerWidth){
   
    }
  },
}

</script>


<style>
#root{
  width: 100%;
  background: linear-gradient(0deg, #1c1f2d, #1c1f2d), #f2f4f9;
}
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}



body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #353A4D;
  font: 400 14px/18px Roboto, Arial, Helvetica, sans-serif
}
</style>