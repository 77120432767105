export default {
    "+{value}% profit": "+{value}% lợi nhuận",
    "Selfie verification required": "Yêu cầu xác minh ảnh tự chụp",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "Để thực hiện các bước xác minh tài khoản cần thiết, bạn vui lòng cung cấp ảnh bản thân có độ phân giải cao (ảnh tự chụp) trong đó cầm giấy tờ tùy thân của bạn với một tờ giấy ghi ngày tháng hiện tại và chữ ký của bạn. Ảnh phải nhìn rõ khuôn mặt, phần thân và cả hai tay của bạn.<Br><br> Các chi tiết của tài liệu phải rõ ràng và dễ đọc.",
    "Your documents have been checked, now your identity is verified.": "Chúng tôi đã kiểm tra tài liệu của bạn, danh tính của bạn đã được xác nhận.",
    "You need fill identity information before verification your profile.": "Bạn cần điền thông tin nhận dạng trước khi xác minh hồ sơ của mình.",
    "Documents verification": "Xác minh tài liệu ",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "Xác minh hồ sơ có nghĩa là cung cấp tài liệu chính thức xác nhận danh tính của Khách hàng. Bộ phận an ninh của Công ty có thể bắt đầu quy trình này bất cứ lúc nào.",
    "Upload Photo": "Tải ảnh lên",
    "Nickname": "Nickname",
    "First Name": "Tên",
    "Last Name": "Họ",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Tên phải được chỉ định chính xác như trong tài liệu. Khi cung cấp thông tin không chính xác, tài khoản có thể bị xóa mà không cần cảnh báo.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Họ phải được chỉ định chính xác như trong tài liệu. Khi cung cấp thông tin không chính xác, tài khoản có thể bị xóa mà không cần cảnh báo.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "Không thể thay đổi ngày sinh của bạn. Nếu bạn chỉ định sai, bạn sẽ không thể rút tiền từ tài khoản của mình.",
    "Empty": "Trống",
    "Date of birth": "Ngày sinh",
    "Unverified": "Chưa được xác minh",
    "Verified": "Đã xác minh",
    "Resend": "Gửi lại",
    "Country": "Quốc gia",
    "Address": "Địa chỉ",
    "Change Identity Info": "Thay đổi Thông tin Xác minh",
    "Identity Info": "Thông tin nhận dạng",
    "Two-factor authentication on login": "Xác thực hai yếu tố khi đăng nhập",
    "Two-factor authentication on withdrawal": "Xác thực hai yếu tố khi rút tiền",
    "Active": "Hoạt động",
    "Inactive": "Không hoạt động",
    "Setup": "Thiết lập",
    "Two-step verification": "Xác thực hai bước",
    "Security": "Bảo mật",
    "Google Authenticator": "Google Authenticator",
    "Scan the QR code": "Quét mã QR",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Chúng tôi rất coi trọng vấn đề bảo mật của bạn, đó là lý do tại sao chúng tôi yêu cầu xác minh 2 bước. Đây là cách thiết lập nó:\n",
    "Install ": "Cài đặt ",
    "Can't scan?": "Không thể quét?",
    "Click here.": "Bấm vào đây.",
    "Enter this code into Google Authenticator.": "Nhập mã này vào Google Authenticator.",
    "Back to QR code.": "Quay lại mã QR.",
    "Enter the 6-digit code from Authenticator below": "Nhập mã gồm 6 chữ số từ Authenticator bên dưới",
    "Enter 6-digit code...": "Nhập mã gồm 6 chữ số...",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "Tốt. Bạn đã thành công bật xác minh 2 bước. Lần đăng nhập tiếp theo, bạn sẽ được nhắc nhập mã.",
    "Code": "Mã",
    "contact support": "liên hệ dịch vụ hỗ trợ",
    "If you ever lose access to your 2-step verification device, please ": "Nếu bạn mất kết nối với thiết bị dùng để xác minh 2 bước, vui lòng",
    "Yes, activate": "Có, kích hoạt",
    "Cancel": "Hủy bỏ",
    "Close": "Đóng",
    "Language": "Ngôn ngữ",
    "Timezone": "Múi giờ",
    "Delete Account": "Xóa tài khoản",
    "Verification Credit / Debit Cards": "Xác minh Thẻ tín dụng / Thẻ ghi nợ",
    "Add New Card": "Thêm thẻ mới",
    "Wait for loading...": "Đang chờ tải….",
    "You don’t have any credit / debit cards for verification": "Bạn không có bất kỳ thẻ tín dụng / thẻ ghi nợ nào để xác minh",
    "Verificate": "Xác minh",
    "Verification Card": "Thẻ xác minh",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "Vui lòng đặt chứng minh thư của bạn (có ảnh, tên và ngày sinh) cùng với mặt trước của thẻ ngân hàng (hiển thị số, ngày hết hạn và tên của bạn) trên một tờ A4 trắng. <br> Vui lòng viết “Quotex.io” và ký tên của bạn vào đó. <br>Chụp ảnh và tải ảnh lên.",
    "Card:": "Thẻ:",
    "Type:": "Loại:",
    "Your ID and bank card on an A4 sheet": "Chứng minh thư và thẻ ngân hàng của bạn trên một tờ A4",
    "Yes, confirm": "Có, xác nhận",
    "Click or drag the image": "Nhấp hoặc thả hình ảnh",
    "Card": "Thẻ",
    "Documents": "Tài liệu",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "Trước tiên, vui lòng điền vào phần “Thông tin nhận dạng” trong Hồ sơ của bạn và nhấp vào nút \"Thay đổi thông tin nhận dạng\" để lưu các thay đổi. Sau đó, bạn sẽ có thể thêm một thẻ mới.",
    "Specify the card details for further operations for depositing and debiting funds.": "Chỉ định chi tiết thẻ cho các hoạt động tiếp theo để gửi tiền và ghi nợ.",
    "Card number:": "Số thẻ :",
    "Expiry month:": "Tháng hết hạn:",
    "Expiry year:": "Năm hết hạn:",
    "Cardholder name:": "Tên chủ thẻ:",
    "Next": "Tiếp theo",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "Vui lòng điền chữ ký của bạn và ngày hiện tại lên tờ giấy, đặt tài liệu cùng với thẻ ngân hàng của bạn, chụp ảnh và tải nó lên theo biểu mẫu bên dưới.",
    "Do you have a two-sided document?": "Bạn có tài liệu hai mặt không?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "Tải lên mặt sau dưới dạng tệp riêng biệt, cũng trên trang tính có ngày tháng và chữ ký của bạn.",
    "Submit only valid documents": "Chỉ gửi các tài liệu hợp lệ",
    "Do not edit or crop photo": "Không gửi ảnh đã chỉnh sửa hoặc cắt ảnh",
    "Do not upload other people's documents": "Không tải lên tài liệu của người khác",
    "Read all": "Đọc toàn bộ",
    "Uploading files": "Tải lên tệp",
    "For single-sided documents, upload only the front side.": "Đối với tài liệu một mặt, chỉ tải lên mặt trước.",
    "Back": "Mặt sau",
    "Add a card": "Thêm một thẻ",
    "File Requirements": "Yêu cầu về tệp",
    "Scanned images are not accepted for card verification": "Hình ảnh được quét không được chấp nhận để xác minh thẻ",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "Chúng tôi chỉ chấp nhận các tệp là hộ chiếu dân sự thông thường hoặc ID quốc gia của bạn (cả hai mặt phải được tải lên dưới dạng tệp riêng biệt)",
    "Driving license CANNOT be accepted": "KHÔNG THỂ chấp nhận giấy phép lái xe",
    "External (foreign/international/travel) passport will not be accepted as well": "Hộ chiếu nước ngoài (nước ngoài/quốc tế/du lịch) cũng sẽ không được chấp nhận",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "Nếu tài liệu có 2 mặt, bạn cần gộp thành một tệp riêng biệt, trên cùng một tờ giấy, có ngày tháng hiện tại và chữ ký của bạn",
    "All information in the document and on the card should be clear and readable on the photo": "Tất cả thông tin trong tài liệu và trên thẻ phải rõ ràng và dễ đọc trên ảnh",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "Cả tài liệu và thẻ của bạn phải vừa khít với ảnh, các cạnh không được cắt xén",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "Không để bị chói hoặc có lỗi gây khó khăn cho việc đọc dữ liệu của tài liệu và thẻ",
    "Expired documents and cards will be rejected automatically": "Các tài liệu và thẻ hết hạn sẽ tự động bị từ chối",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "Nếu thẻ không có tên của bạn trên thẻ, bạn cần tải lên ảnh chụp màn hình bảng sao kê ngân hàng, hiển thị số thẻ và tên của bạn. Trong một số trường hợp, được phép viết tay tên của bạn trên cùng một tờ giấy",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "Không được phép ẩn các thành phần của tài liệu, hoặc chứa dữ liệu cần thiết để xác minh (tên, ngày sinh, ảnh)",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "Được phép ẩn 8 chữ số trong số của thẻ. Trong đó 4 chữ số đầu tiên và 4 chữ số cuối cùng phải hiển thị rõ ràng",
    "CVV code should be hidden at all times, in every case": "Mã CVV nên được ẩn mọi lúc, trong mọi trường hợp",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "Mọi chỉnh sửa tệp của bạn đều bị cấm. Chỉ những ảnh chưa chỉnh sửa và ảnh đã quét mới có thể được chấp nhận. Không được phép chỉnh sửa thông tin cá nhân trong tài liệu của bạn (tên, ngày sinh, v.v.)",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "Ảnh phải là một phần của tài liệu (dán hoặc in). Ảnh đính kèm riêng sẽ không được chấp nhận",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "Chỉ được phép tải lên các tài liệu và thẻ với tên, được chỉ định trong hồ sơ (mà tài khoản của bạn đã được đăng ký)",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "Xin bạn lưu ý nếu bạn gửi thông tin giả mạo/sai sự thật, tài khoản có thể bị tạm ngưng/bị khóa cho đến khi các trường hợp được làm rõ",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "Công ty có quyền yêu cầu bạn cung cấp bất kỳ tài liệu bổ sung nào để xác minh tài khoản (bao gồm cả ảnh tự chụp của bạn kèm theo tài liệu) bất kỳ lúc nào",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "Đối với tất cả các câu hỏi liên quan đến xác minh tài liệu, bạn có thể tạo ticket hỗ trợ trên nền tảng (phần “Trợ giúp” là nút đầu tiên có biểu tượng dấu chấm hỏi nhấp nháy, trên bảng điều khiển ngoài cùng bên trái)",
    "Profile": "Hồ sơ",
    "Deposit": "Nạp tiền",
    "Withdrawal": "Rút tiền",
    "Transactions": "Giao dịch",
    "Trades": "Lệnh giao dịch",
    "Market": "Thị trường",
    "Analytics": "Phân tích",
    "My current currency": "Đơn vị tiền tệ hiện tại của tôi",
    "Available for withdrawal": "Khả dụng để rút",
    "In the account": "Trong tài khoản",
    "Percentage of turnover {percent}%": "Phần trăm doanh thu {percent}%",
    "Cashback {percent}%": "Hoàn tiền {percent}%",
    "Currency:": "Tiền tệ:",
    "Change": "Thay đổi",
    "Live Account": "Tài khoản Thực",
    "Daily budget limit": "Giới hạn ngân sách hàng ngày",
    "The value cannot exceed your total budget": "Giá trị không thể vượt quá tổng ngân sách của bạn",
    "Demo Account": "Tài khoản Demo",
    "Apply": "Gửi",
    "Edit": "Tùy chỉnh",
    "New value": "Giá trị mới",
    "Confirm": "Xác nhận ",
    "Logout": "Đăng xuất",
    "No, cancel": "Không, hủy",
    "Exchange Form": "Biểu mẫu Tỷ giá",
    "My Currency:": "Đơn vị tiền tệ của Tôi:",
    "New Currency:": "Đơn vị tiền tệ Mới:",
    "You are exchanging": "Bạn đang trao đổi",
    "You will receive": "Bạn sẽ nhận",
    "Exchange Fee": "Phí trao đổi",
    "Password changed": "Mật khẩu đã được thay đổi",
    "Old password": "Mật khẩu cũ",
    "New password": "Mật khẩu mới ",
    "Confirm new password": "Xác nhận mật khẩu mới",
    "Change Password": "Thay đổi Mật khẩu",
    "Confirm password must be equal new password value": "Mật khẩu xác nhận phải khớp với giá trị của mật khẩu mới",
    "About us": "Giới thiệu",
    "Support": "Trợ giúp ",
    "Help": "Trợ giúp",
    "2-step verification": "Xác minh 2 bước",
    "Please enter the PIN-code we've just sent to your email": "Vui lòng nhập mã PIN mà chúng tôi vừa gửi đến email của bạn",
    "Please enter the 6-digit code from your authentication app": "Vui lòng nhập mã gồm 6 chữ số từ ứng dụng xác thực của bạn",
    "The First Name field is required": "Tên là trường bắt buộc cần nhập",
    "The Last Name field is required": "Họ là trường bắt buộc cần nhập",
    "Verification of documents": "Xác minh tài liệu",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "Vui lòng tải lên ảnh màu hoặc ảnh scan của hộ chiếu dân sự thông thường, giấy phép lái xe hoặc Chứng minh nhân dân của bạn.",
    "Upload Documents": "Tải lên Tài liệu",
    "Why?": "Tại sao?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "Bạn có muốn xác nhận việc xóa tài khoản của mình mà không hoàn tác không? Không thể hoàn tác hành động này.",
    "Set up 2-step verification": "Thiết lập xác thực 2 bước",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "Rất tiếc, bạn không thể tải tập tin lên khu vực này. <br> Vui lòng tham khảo thông báo từ đội ngũ Hỗ trợ và đính kèm các tập tin theo yêu cầu vào phiếu.",
    "The uploaded document does not match the requirements": "Tài liệu đã tải lên không phù hợp với yêu cầu",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "Các tài liệu sau đây đủ điều kiện để nhận dạng: Giấy tờ tùy thân, hộ chiếu, hộ chiếu quốc tế hoặc bằng lái xe.",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "Hình ảnh phải có chất lượng cao, dữ liệu phải dễ đọc và các cạnh của tài liệu không được cắt xén.",
    "Balance from {amount}": "Số dư từ {amount}",
    "Not verified": "Chưa xác minh",
    "Clear": "Xóa",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "Để tránh các hạn chế về dịch vụ, vui lòng hoàn tất quy trình xác minh: điền dữ liệu cá nhân của bạn và tải lên các tài liệu cần thiết.",
    "Get verified": "Nhận xác minh",
    "I got it!": "Tôi đã hiểu rồi!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "Xác minh AADHAAR",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "Vui lòng tải lên ảnh màu Aadhaar thực tế của bạn (chỉ mặt trước bao gồm ảnh và số tài liệu của bạn).",
    "Send AADHAAR": "Gửi AADHAAR",
    "AADHAAR number:": "Số AADHAAR:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "Vui lòng tải lên ảnh màu Aadhaar thực tế của bạn (chỉ mặt trước bao gồm ảnh và số tài liệu của bạn). ☝ Bất cứ phiên bản điện tử nào cũng không được chấp nhận.",
    "You have already uploaded {number} files. Please wait until they are checked.": "Bạn đã tải lên {number} tệp. Vui lòng đợi cho đến khi chúng được kiểm tra.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "Vui lòng tải lên một bức ảnh màu tài liệu của bạn: Đối với RG - cả hai mặt cần được tải lên dưới dạng tệp riêng biệt. Đối với CNH - chỉ mặt trước bao gồm ảnh của bạn. Tài liệu phải hợp lệ (chưa tới 10 năm kể từ ngày cấp) và phải hiển thị số CPF của bạn. * trong trường hợp không có CPF trong tài liệu của bạn - vui lòng tải lên một bức ảnh riêng của Comprovante de Inscrição no CPF hoặc ảnh chụp màn hình bảng sao kê ngân hàng của bạn hiển thị tên và số CPF của bạn. ** Vui lòng không tải lên bất kỳ phiên bản điện tử nào. Chúng sẽ không được chấp nhận.",
    "Submit for verification": "Gửi để xác minh",
    "Settings": "Cài đặt",
    "Please upload a color photograph of the front of your national identity card with your photo.": "Xin vui lòng tải lên một bức ảnh màu của mặt trước thẻ định danh quốc gia với hình ảnh của bạn.",
    "Template": "Bản mẫu",
    "Light Mode": "Chế độ Sáng",
    "Twilight": "Hoàng hôn",
    "Full Night": "Đêm huyền thoại",
    "Grid's opacity": "Độ mờ của lưới",
    "Auto-scrolling": "Tự động cuộn",
    "Automatic graphic scrolling": "Tự động cuộn đồ họa",
    "1-click trade": "Giao dịch bằng 1 cú nhấp chuột",
    "Open trades without confirmation": "Mở giao dịch mà không cần xác nhận",
    "Use timezone": "Sử dụng múi giờ",
    "Use your timezone data": "Sử dụng dữ liệu múi giờ của bạn",
    "Graph colors": "Màu đồ thị",
    "Up Trend": "Xu hướng Tăng",
    "Down Trend": "Lệnh <br>giao dịch</br>",
    "Platform": "Nền tảng",
    "Short order label": "Nhãn lệnh bán",
    "Use short order element mode": "Dùng chế độ yếu tố lệnh bán",
    "Perfomance Mode": "Chế độ Hiệu suất",
    "Use optimized rendering for chart and candles": "Sử dụng kết xuất được tối ưu hóa cho biểu đồ và nến",
    "Background": "Nền",
    "Choose file": "Chọn tập tin",
    "Max size — 2 Mb": "Kích thước tối đa — 2 Mb",
    "Sign up": "Đăng ký",
    "Sign in": "Đăng nhập",
    "Budget and limits": "Ngân sách và giới hạn",
    "Daily limit": "Giới hạn hàng ngày",
    "Not set": "Chưa đặt",
    "Total funds": "Tổng số tiền",
    "Top up": "Nạp tiền",
    "Set up": "Thiết đặt",
    "Set a daily limit on trading to reduce financial risks.": "Đặt giới hạn giao dịch hàng ngày để giảm rủi ro tài chính.",
    "Limit amount": "Số tiền giới hạn",
    "More": "Thêm",
    "Leader Board": "Bảng xếp hạng",
    "Signals": "Tín hiệu",
    "Notifications": "Thông báo",
    "mark all as read": "đánh dấu tất cả đã đọc",
    "No notifications": "Không có thông báo",
    "No more notifications": "Không có thêm thông báo",
    "Your position:": "Vị trí của bạn:",
    "How does this rating work?": "Xếp hạng này hoạt động như thế nào?",
    "of the Day": "trong Ngày",
    "Nothing has been found for current query, try another asset name.": "Không tìm thấy gì cho truy vấn hiện tại, hãy thử một tên tài sản khác.",
    "My rating in the Leader Board": "Xếp hạng của tôi trong Bảng xếp hạng",
    "Participants can only be those who trade in live mode": "Người tham gia chỉ có thể là những người giao dịch ở chế độ thực",
    "Your position depends on the amount of earned money": "Vị trí của bạn phụ thuộc vào số tiền mà bạn kiếm được",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "Được tính hàng ngày, từ 00:00 UTC đến 23:59 UTC",
    "All traders on our platform participate in this rating. Main features of the rating:": "Tất cả các nhà giao dịch trên nền tảng của chúng tôi đều tham gia vào xếp hạng này. Các tính năng chính của xếp hạng:",
    "How does it work?": "Cách thức hoạt động?",
    "Trading signals": "Tín hiệu giao dịch",
    "Past signals": "Tín hiệu trong quá khứ",
    "What’s it?": "Đó là gì?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "Một tín hiệu giao dịch không phải là một chỉ dẫn trực tiếp để giao dịch, mà là một khuyến nghị của nhà phân tích. Không nên sử dụng nó để thay thế cho phân tích thị trường độc lập.",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "Bạn có trung bình 10 phút để tận dụng tín hiệu giao dịch. Sau thời gian này, báo giá có thể thay đổi và có ảnh hưởng không mong muốn đến kết quả giao dịch.",
    "Continue": "Tiếp tục",
    "Duration": "Thời lượng",
    "Return back to FAQ": "Quay lại Câu hỏi thường gặp",
    "Return back to Help": "Quay lại Trợ giúp",
    "How to withdraw money from the account?": "Làm thế nào để rút tiền từ tài khoản?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "Thủ tục rút vốn vô cùng đơn giản và được thực hiện thông qua tài khoản cá nhân của bạn.",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "Phương thức bạn đã chọn để nạp tiền vào tài khoản cũng là một phương thức để rút tiền (xem câu hỏi \"Làm thế nào để nạp tiền?\").",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "Ví dụ: nếu bạn nạp tiền vào tài khoản của mình qua hệ thống thanh toán Visa, bạn cũng sẽ rút tiền qua hệ thống thanh toán Visa.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "Khi bạn cần rút một số tiền có giá trị đủ lớn, Công ty có thể yêu cầu xác minh (xác minh được yêu cầu theo quyết định riêng của Công ty). Đăng ký một tài khoản riêng cho chính mình là rất quan trọng, giúp bạn xác nhận quyền của bạn đối với tài khoản bất cứ lúc nào.",
    "What is the minimum withdrawal amount?": "Số tiền tối thiểu có thể rút là bao nhiêu?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "Số tiền rút tối thiểu bắt đầu từ 10 USD đối với hầu hết các hệ thống thanh toán.<br> Đối với tiền điện tử, số tiền này bắt đầu từ 50 USD (và có thể cao hơn đối với một số loại tiền tệ nhất định, chẳng hạn như Bitcoin).",
    "Do I need to provide any documents to make a withdrawal?": "Tôi có cần cung cấp bất kỳ tài liệu nào để thực hiện rút tiền không?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "Thông thường, không cần cung cấp thêm tài liệu để rút tiền. Nhưng tùy theo quyết định của Công ty, Công ty có thể yêu cầu bạn xác nhận dữ liệu cá nhân của mình bằng cách yêu cầu cung cấp thêm một số tài liệu nhất định. Thông thường, yêu cầu này được đưa ra nhằm ngăn chặn các hoạt động liên quan đến giao dịch bất hợp pháp, gian lận tài chính, cũng như ngăn chặn việc sử dụng số tiền thu được một cách bất hợp pháp.",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "Danh sách những tài liệu như vậy luôn được hạn chế tối đa, việc cung cấp chúng sẽ không làm bạn mất nhiều thời gian và công sức.",
    "How long does it take to withdraw funds?": "Mất bao lâu để rút tiền?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "Trung bình, quy trình rút tiền mất từ ​​một đến năm ngày kể từ ngày nhận được yêu cầu của Khách hàng và quy trình này chỉ phụ thuộc vào khối lượng yêu cầu cần xử lý đồng thời. Công ty luôn cố gắng thực hiện thanh toán trực tiếp vào ngày nhận được yêu cầu từ Khách hàng.",
    "Is there any fee for depositing or withdrawing funds from the account?": "Tôi có phải trả bất kỳ khoản phí nào khi nạp hoặc rút tiền từ tài khoản không?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "Không. Công ty không tính bất kỳ khoản phí nào đối với việc nạp hoặc rút tiền.",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "Tuy nhiên, các hệ thống thanh toán có thể tính phí và sử dụng tỷ giá quy đổi nội tệ.",
    "Can I close my account? How to do it?": "Tôi có thể đóng tài khoản của mình không? Làm thế nào để đóng tài khoản?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "Bạn có thể xóa một tài khoản trong phần Tài khoản Cá nhân của mình bằng cách nhấp vào nút “Xóa Tài khoản” ở cuối trang hồ sơ.",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "Nếu tôi mắc lỗi khi nhập dữ liệu vào tài khoản cá nhân của mình, tôi có thể sửa lỗi này bằng cách nào?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "Bạn phải liên hệ với dịch vụ hỗ trợ kỹ thuật trên trang web của Công ty và chỉnh sửa hồ sơ.",
    "What data is required to register on the Company website?": "Cần cung cấp những dữ liệu nào khi đăng ký trên trang web của Công ty?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "Để có thể kiếm lời từ các quyền chọn kỹ thuật số, trước tiên bạn phải mở một tài khoản cho phép bạn thực hiện giao dịch. Để làm điều này, bạn cần đăng ký trên trang web của Công ty.",
    "The registration process is simple and does not take much time.": "Quy trình đăng ký đơn giản và không tốn nhiều thời gian.",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "Bạn cần phải hoàn tất một bảng câu hỏi theo mẫu. Bạn sẽ được yêu cầu nhập các thông tin sau:",
    "name (in English)": "tên (bằng Tiếng Anh)",
    "email address (indicate the current, work, address)": "địa chỉ email (cho biết cơ quan, địa chỉ hiện tại)",
    "telephone (with a code, for example, + 44123 ....)": "điện thoại (kèm mã, ví dụ: + 44123....)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "mật khẩu mà bạn sẽ sử dụng sau này để truy cập vào hệ thống (để giảm thiểu rủi ro tài khoản cá nhân của bạn bị truy cập trái phép, chúng tôi khuyên bạn nên tạo một mật khẩu phức tạp bằng cách sử dụng chữ thường, chữ hoa và số. Không tiết lộ mật khẩu cho bên thứ ba)",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "Sau khi điền vào bảng câu hỏi, bạn sẽ được cung cấp nhiều cách khác nhau để nạp tiền vào tài khoản để giao dịch.",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "Tài khoản của Khách hàng được mở bằng loại tiền nào? Tôi có thể thay đổi đơn vị tiền tệ trong tài khoản của Khách hàng không?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "Một tài khoản giao dịch được mặc định mở bằng đồng đô la Mỹ.\n Nhưng để thuận tiện cho bạn, bạn có thể mở nhiều tài khoản bằng các loại tiền tệ khác nhau.",
    "A list of available currencies can be found on your profile page in your Client's account.": "Bạn có thể tìm thấy danh sách các loại tiền hiện có trên trang hồ sơ trong tài khoản Khách hàng của mình.",
    "What are digital options?": "Quyền chọn kỹ thuật số là gì?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "Quyền chọn - một công cụ tài chính phái sinh dựa trên bất kỳ tài sản cơ sở nào (nó có thể là cổ phiếu, các cặp tiền tệ, dầu, v.v.).",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "QUYỀN CHỌN KỸ THUẬT SỐ - một loại quyền chọn phi tiêu chuẩn được sử dụng để kiếm lời từ biến động giá của các tài sản trong một khoảng thời gian nhất định.",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "Quyền chọn kỹ thuật số, tùy thuộc vào các điều khoản mà các bên thỏa thuận trong giao dịch, tại thời điểm do các bên xác định, mang lại thu nhập cố định (chênh lệch giữa thu nhập từ giao dịch và giá của tài sản) hoặc lỗ (bằng giá trị của tài sản).",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "Do quyền chọn kỹ thuật số được mua trước với giá cố định, quy mô lợi nhuận, cũng như quy mô tổn thất tiềm năng, đều được biết trước khi giao dịch.",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "Một đặc tính khác của các giao dịch này là giới hạn thời gian. Bất kỳ quyền chọn nào cũng có thời hạn riêng (thời gian đáo hạn hoặc thời gian kết thúc).",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "Bất kể mức độ thay đổi về giá của tài sản (cao hơn hay thấp hơn bao nhiêu), trong trường hợp thắng một quyền chọn, Khách hàng luôn nhận được một khoản lợi nhuận cố định. Do đó, rủi ro chỉ giới hạn ở số tiền mà bạn bỏ ra để mua quyền chọn.",
    "What is the expiration period of a trade?": "Thời gian đáo hạn của một giao dịch là gì?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "Thời gian đáo hạn là khoảng thời gian mà sau đó giao dịch sẽ được coi là đã hoàn thành (đã đóng) và kết quả được tự động tổng hợp.",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "Khi kết thúc một giao dịch quyền chọn kỹ thuật số, bạn tự xác định thời gian thực hiện giao dịch (1 phút, 2 giờ, tháng, v.v.).",
    "What is the gist of digital options trading?": "Điểm cốt lõi của giao dịch quyền chọn là gì?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "Thực tế là quyền chọn kỹ thuật số là loại công cụ tài chính phái sinh đơn giản nhất. Để kiếm lời trên thị trường quyền chọn kỹ thuật số, bạn không cần phải dự đoán mức giá thị trường mà tài sản có thể đạt được.",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "Quá trình giao dịch chỉ gói gọn ở việc giải quyết một nhiệm vụ duy nhất - đó là giá của tài sản sẽ tăng hay giảm vào thời điểm hợp đồng được thực hiện.",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "Điểm mấu chốt của quyền chọn là việc giá của tài sản cơ sở biến động một trăm điểm hay chỉ một điểm, kể từ thời điểm giao dịch được thực hiện cho đến khi kết thúc, không quan trọng. Điều quan trọng là bạn chỉ xác định được hướng di chuyển của giá.",
    "If your prognosis is correct, in any case you get a fixed income.": "Nếu tiên lượng của bạn là chính xác, bạn sẽ thu về một khoản lợi nhuận cố định trong mọi trường hợp.",
    "What are the possible results of the placed trades?": "Các giao dịch đã được đặt có thể sẽ có những kết quả gì?",
    "There are three possible outcomes in the digital options market:": "Có ba kết quả có thể xảy ra trong thị trường quyền chọn kỹ thuật số:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "trong trường hợp tiên lượng của bạn về hướng chuyển động của giá tài sản cơ sở là chính xác, bạn sẽ có được thu nhập.",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "nếu vào thời điểm đáo hạn quyền chọn, dự báo của bạn là không chính xác, bạn phải chịu mất một khoản tối đa bằng giá trị tài sản (tức là trên thực tế, bạn chỉ có thể mất khoản đầu tư của mình).",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "nếu kết quả của giao dịch bằng 0 (giá của tài sản cơ sở không đổi, quyền chọn đáo hạn ở mức giá mà nó đã được mua), bạn sẽ hoàn trả khoản đầu tư của mình. Do đó, mức độ rủi ro của bạn luôn được giới hạn ở quy mô của giá trị tài sản.",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "Nền tảng giao dịch của bạn có tài khoản demo cho phép tìm hiểu về quy trình giao dịch quyền chọn kỹ thuật số mà không cần dùng đến tiền thật không?",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "Có. để phát triển kỹ năng giao dịch của mình và kiểm định nền tảng giao dịch của Công ty, bạn có thể sử dụng tài khoản demo (miễn phí). Đây là một loại tài khoản giả lập giúp bạn trải nghiệm nền tảng trước khi tiến hành giao dịch thực. Tài khoản demo cũng phù hợp cho các nhà giao dịch có kinh nghiệm muốn nâng cao trình độ chuyên môn của mình.",
    "The balance of such an account is 10,000 units.": "Số dư của một tài khoản như vậy là 10.000 đơn vị.",
    "What determines profit size?": "Điều gì quyết định quy mô lợi nhuận?",
    "There are several factors that affect the size of your profit:": "Có một số yếu tố ảnh hưởng đến quy mô lợi nhuận của bạn:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "tính thanh khoản của tài sản bạn đã chọn trên thị trường (tài sản có nhu cầu càng lớn trên thị trường thì lợi nhuận thu về càng cao)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "thời gian giao dịch (tính thanh khoản của tài sản vào buổi sáng và vào buổi chiều có thể khác nhau đáng kể)",
    "tariffs of a brokerage company": "thuế quan của một công ty môi giới",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "những thay đổi trên thị trường (các sự kiện kinh tế, thay đổi một phần tài sản tài chính, v.v.)",
    "What are the varieties of digital options?": "Có các loại quyền chọn kỹ thuật số nào?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "Khi thực hiện giao dịch quyền chọn, bạn phải chọn loại tài sản cơ sở làm nền tảng cho quyền chọn. Bạn sẽ dự báo biến động giá của loại tài sản này.",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "Đơn giản là khi mua một hợp đồng kỹ thuật số trên một loại tài sản nào đó, bạn thực sự đang đặt cược vào biến động giá của loại tài sản đó.",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "Tài sản cơ sở là một \"mặt hàng\", giá của nó được tính đến khi chốt giao dịch. Những sản phẩm được tìm kiếm nhiều nhất trên thị trường thường là tài sản cơ sở của quyền chọn kỹ thuật số. Có bốn loại tài sản cơ sở sau: ",
    "securities (shares of world companies)": "chứng khoán (cổ phiếu của các công ty trên thế giới)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "cặp tiền tệ (EUR / USD, GBP / USD, v.v...)",
    "raw materials and precious metals (oil, gold, etc.)": "nguyên liệu thô và kim loại quý (dầu, vàng,v.v...)",
    "indices (S&P 500, Dow, dollar index, etc.)": "chỉ số (S&P 500, chỉ số DOW, chỉ số đô la, v.v...) ",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "Không tồn tại một tài sản cơ sở phổ quát. Khi lựa chọn loại tài sản cơ sở, bạn phải dựa vào kiến ​​thức, trực giác của bản thân, các loại thông tin phân tích, cũng như phân tích thị trường cho một công cụ tài chính cụ thể.",
    "What is a trading platform and why is it needed?": "Nền tảng giao dịch là gì và tại sao nó lại cần thiết?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "Nền tảng giao dịch - một tổ hợp phần mềm cho phép Khách hàng thực hiện các giao dịch (hoạt động) bằng cách sử dụng các công cụ tài chính khác nhau. Nó cũng cho phép Khách hàng truy cập các thông tin khác nhau như giá trị của các báo giá, vị thế thị trường theo thời gian thực, các hành động của Công ty, v.v.",
    "How to learn quickly how to make money in the digital options market?": "Làm thế nào nhanh chóng học được cách kiếm lời trong thị trường quyền chọn kỹ thuật số?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "phát triển các chiến lược giao dịch của riêng bạn sao cho số lượng giao dịch được dự đoán chính xác sẽ là tối đa và hãy nương theo chúng",
    "diversify your risks": "đa dạng hóa rủi ro của bạn",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "Trong quá trình xây dựng chiến lược, cũng như tìm cách đa dạng hóa quyền chọn, giám sát thị trường, nghiên cứu thông tin phân tích và thống kê từ nhiều nguồn khác nhau (nguồn Internet, ý kiến ​​chuyên gia, nhà phân tích trong lĩnh vực này, v.v.) sẽ giúp ích cho bạn, một trong số đó là trang web của Công ty.",
    "Is the download of the program to a computer or smartphone required?": "Có cần tải chương trình xuống máy tính hoặc điện thoại thông minh không?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "Không, không có quy định bắt buộc. Bạn chỉ cần đăng ký trên trang web của Công ty theo mẫu có sẵn và mở một tài khoản cá nhân.",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "Công ty phải chịu phí tổn gì khi thanh toán lợi nhuận cho Khách hàng trong trường hợp giao dịch thành công?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "Công ty cũng kiếm lời khi khách hàng có giao dịch thành công. Do đó, công ty muốn thấy tỷ lệ các giao dịch sinh lời cao hơn tỷ lệ các giao dịch không sinh lời, do Công ty được hưởng phần trăm từ khoản thanh toán cho chiến lược giao dịch thành công mà Khách hàng lựa chọn.",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "Ngoài ra, các giao dịch được Khách hàng chốt hợp lại thành khối lượng giao dịch của Công ty, khối lượng giao dịch này được chuyển cho một nhà môi giới hoặc sàn giao dịch - người được đưa vào nhóm các nhà cung cấp thanh khoản, dẫn đến gia tăng tính thanh khoản của chính thị trường.",
    "What is account verification?": "Xác minh tài khoản là gì?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "Xác minh trong quyền chọn kỹ thuật số là việc Khách hàng xác nhận dữ liệu cá nhân của mình bằng cách cung cấp cho Công ty các tài liệu bổ sung. Các điều kiện xác minh đối với khách hàng đơn giản nhất có thể và những tài liệu cũng ở mức tối thiểu. Ví dụ, Công ty có thể yêu cầu:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "cung cấp bản quét màu của trang đầu tiên trong hộ chiếu của Khách hàng (trang hộ chiếu có ảnh)",
    "identify with the help of a \"selfie\" (photograph of himself)": "xác nhận bằng cách cung cấp một bức \"ảnh tự sướng\" (ảnh do chính người đăng ký chụp)\n",
    "confirm the address of registration (residence) of the Client, etc": "xác nhận địa chỉ đăng ký (cư trú) của Khách hàng, v.v.",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "Công ty có thể yêu cầu bất kỳ tài liệu nào nếu không thể hoàn toàn nhận dạng Khách hàng và dữ liệu do Khách hàng cung cấp.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "Sau khi gửi các bản sao điện tử của tài liệu cho Công ty, Khách hàng sẽ phải đợi một thời gian để Công ty xác minh dữ liệu được cung cấp.",
    "How to understand that I need to go through account verification?": "Làm thế nào để biết rằng tôi cần phải thực hiện xác minh tài khoản?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "Nếu cần phải thực hiện xác minh, bạn sẽ nhận được thông báo qua e-mail và/hoặc SMS.",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "Đồng thời, Công ty sử dụng các chi tiết liên hệ đã được bạn cung cấp trong bảng câu hỏi khi đăng ký (cụ thể là địa chỉ email và số điện thoại). Do đó, hãy thận trọng và cung cấp thông tin phù hợp và chính xác.",
    "How do I know that I successfully passed verification?": "Làm cách nào để biết rằng tôi đã thành công vượt qua bước xác minh?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "Bạn sẽ nhận được thông báo qua e-mail và/hoặc thông báo SMS về việc hoàn tất quá trình xác minh tài khoản của mình và về việc bạn đủ điều kiện tiến hành các hoạt động trên nền tảng giao dịch của Công ty.",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "Có thể biết được dữ liệu (giả mạo) của người khác khi người đó đăng ký trên trang web không?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "Không. Khách hàng tự đăng ký trên trang web của Công ty, cung cấp đầy đủ và chính xác thông tin cá nhân về các vấn đề được yêu cầu trong mẫu đăng ký và đảm bảo cung cấp những thông tin được cập nhật.",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "Nếu cần tiến hành nhiều hình thức kiểm tra danh tính Khách hàng, Công ty có thể yêu cầu tài liệu hoặc mời Khách hàng đến văn phòng của mình.",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "Nếu dữ liệu được nhập vào các trường đăng ký không khớp với dữ liệu trên các tài liệu đã gửi, hồ sơ cá nhân của bạn có thể sẽ bị chặn.",
    "How long does the verification process take?": "Quá trình xác minh mất bao lâu?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "Không quá 5 (năm) ngày làm việc kể từ ngày Công ty nhận được tài liệu đã yêu cầu.",
    "Is there a minimum amount that I can deposit to my account at registration?": "Có quy định về số tiền tối thiểu mà tôi có thể nạp vào tài khoản của mình khi đăng ký không?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "Lợi thế của nền tảng giao dịch của Công ty là bạn không phải nạp một khoản tiền lớn vào tài khoản của mình. Bạn có thể bắt đầu giao dịch bằng cách đầu tư một số tiền nhỏ. Giá trị tiền nạp tối thiểu là 10 đô la Mỹ.",
    "How can I deposit?": "Tôi có thể nạp tiền bằng cách nào?",
    "It is very easy to do. The procedure will take a couple of minutes.": "Rất dễ mở tài khoản này, chỉ mất một vài phút.",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "Mở cửa sổ thực hiện giao dịch và nhấp vào nút \"Nạp tiền\" màu xanh lá cây ở góc trên bên phải của thẻ.",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "Bạn cũng có thể nạp tiền vào tài khoản thông qua Tài khoản Cá nhân của mình bằng cách nhấp vào nút \"Nạp tiền\" trong hồ sơ tài khoản.",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "Sau đó, bạn cần phải chọn phương thức nạp tiền vào tài khoản (Công ty cung cấp rất nhiều phương thức thuận tiện cho Khách hàng, các phương thức này được hiển thị trong tài khoản cá nhân của Khách hàng).",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "Tiếp theo, cho biết đơn vị tiền tệ sẽ được sử dụng để nạp tài khoản và đơn vị tiền tệ của chính tài khoản.",
    "Enter the amount of the deposit.": "Nhập số tiền nạp vào.",
    "Fill out the form by entering the requested payment details.": "Điền vào biểu mẫu bằng cách nhập các chi tiết thanh toán được yêu cầu.",
    "Make a payment.": "Thực hiện thanh toán.",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "Tôi có cần phải nạp tiền vào tài khoản của nền tảng giao dịch không và cần làm việc này bao lâu một lần?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "Để giao dịch các quyền chọn kỹ thuật số, bạn cần mở một tài khoản cá nhân. Để chốt các giao dịch thực, bạn cần phải nạp một khoản tương đương với giá trị quyền chọn mà bạn đã mua.",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "Bạn có thể bắt đầu giao dịch mà không cần tiền mặt, chỉ sử dụng tài khoản đào tạo của công ty (tài khoản demo). Tài khoản này được cung cấp miễn phí nhằm minh họa cách thức nền tảng giao dịch hoạt động. Với sự trợ giúp của một tài khoản như vậy, bạn có thể thực hành mua các quyền chọn kỹ thuật số, nắm bắt các nguyên tắc giao dịch cơ bản, thử nghiệm các phương pháp và chiến lược khác nhau hoặc đánh giá mức độ chính xác của trực giác của bạn.",
    "What is the minimum deposit amount?": "Giá trị tiền nạp tối thiểu là bao nhiêu?",
    "{number} questions": "{number} câu hỏi",
    "Verification": "Xác minh",
    "Payment": "Thanh toán",
    "Payouts": "Chi trả",
    "Ask a specialist": "Hỏi một chuyên gia",
    "FAQ": "Câu hỏi thường gặp",
    "Didn't find an answer to your question?": "Không tìm thấy câu trả lời cho câu hỏi của bạn?",
    "Open knowledge base": "Mở cơ sở kiến thức",
    "Contact support": "Liên hệ hỗ trợ",
    "Open FAQ page": "Mở trang Câu hỏi thường gặp",
    "New message": "Tin nhắn mới",
    "Tournaments": "giải đấu",
    "To end": "Để kết thúc",
    "Until start": "Cho đến khi bắt đầu",
    "Front side": "Mặt trước",
    "Back side (if any)": "Mặt sau (nếu có)",
    "Tourna-ments": "Các giải đấu",
    "Deletion of Account and Personal Data": "Xóa tài khoản và dữ liệu cá nhân",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "Bằng cách xóa tài khoản và dữ liệu cá nhân, bạn sẽ vĩnh viễn mất quyền truy cập vào tài khoản của mình trên nền tảng Quotex. Hãy nhớ rằng dữ liệu của bạn sẽ bị xóa vĩnh viễn và không thể khôi phục tài khoản của bạn sau này!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "LƯU Ý: Vui lòng hoàn thành tất cả các giao dịch đang mở và các lệnh đang chờ xử lý trước khi bạn xóa tài khoản của mình.",
    "Request Deletion": "Yêu cầu xóa",
    "To delete your account, please contact support service": "Để xóa tài khoản của bạn, vui lòng liên hệ với đội ngũ dịch vụ hỗ trợ",
    "Two-factor authorization via Email": "Xác thực hai yếu tố qua Email",
    "This feature is for experienced users who are familiar with Google Authenticator.": "Tính năng này dành cho người dùng có kinh nghiệm đã quen thuộc với Google Authenticator.",
    "Please note!": "Xin lưu ý!",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "Nếu bạn mất quyền truy cập vào Google Authenticator vì bất kỳ lý do gì sau khi liên kết, bạn sẽ mất quyền truy cập vào tài khoản của mình.",
    "Proceed to setup": "Tiến hành thiết lập",
    "Account protection method": "Phương pháp bảo vệ tài khoản",
    "Here's how to set it up:": "Cách thiết lập:",
    "Please enter the PIN-code we've just sent to your email:": "Vui lòng nhập mã PIN chúng tôi vừa gửi đến email của bạn:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "Làm tốt lắm. Bạn đã kích hoạt thành công xác minh 2 bước qua Google Authenticator. Trong lần đăng nhập tiếp theo, bạn sẽ nhận và đăng nhập qua mã xác thực.",
    "If you ever lose access to your 2-step verification device, please": "Nếu bạn mất quyền truy cập vào thiết bị xác minh 2 bước của mình, hãy",
    "Confirm account deletion": "Xác nhận xóa tài khoản",
    "To delete your account, follow the link in the email sent to": "Để xóa tài khoản của bạn, hãy nhấp vào liên kết trong email được gửi tới",
    "Image requirements": "Yêu cầu về hình ảnh",
    "Email": "Email",
    "Сonfirmation": "Xác nhận",
    "Change protection method": "Thay đổi phương pháp bảo vệ",
    "First, enter the code from the Google Authenticator app:": "Đầu tiên, nhập mã từ ứng dụng Google Authenticator:",
    "First, enter the PIN-code we've just sent to your email:": "Trước tiên, hãy nhập mã PIN mà chúng tôi vừa gửi đến email của bạn:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "Làm tốt lắm. Bạn đã kích hoạt thành công xác minh 2 bước qua Email.",
    "Receiving codes via Email": "Nhận mã qua Email",
    "Receiving codes via Google Authenticator": "Nhận mã qua Google Authenticator",
    "Choose a method:": "Chọn một phương pháp:",
    "To enter the platform": "Để vào nền tảng",
    "To withdraw funds": "Để rút tiền",
    "day": "ngày",
    "days2": "ngày",
    "days5": "ngày",

text:'Lựa chọn loại tiền tệ',
text1:'Email',
text2:'Hủy giao dịch thành',
text3:'Mã giảm giá miễn phí',
text4:'Chúng tôi hàng ngày cải thiện nền tảng này để làm cho nó thuận tiện hơn.',
text5:'Bạn có thích nền tảng này không? Xin vui lòng giới thiệu cho người khác!',
text6:'Để lại đánh giá của bạn về QUOTEX, và bạn sẽ nhận được phần thưởng hủy giao dịch miễn phí.',
text7:'Gửi liên kết đánh giá của bạn cho đội ngũ hỗ trợ của chúng tôi, sau khi xem xét, phần thưởng sẽ được gửi cho bạn.',
text8:'Số tiền gửi tối thiểu',
text9:'Số tiền rút tối thiểu',
text10:'Rút tiền nhanh',
text11:'Miễn phí',
text12:'Chưa xác minh',
text13:'Gửi lại',
text14:'Xác nhận sự tham gia của bạn',
text15:'Hủy giao dịch',
text16:'Vui lòng nhập và kích hoạt mã giảm giá của bạn ngay bây giờ. Bạn có thể sử dụng mã giảm giá bất kỳ lúc nào.',
text17:'Mã giảm giá:',
text18:'Nhận thưởng 30% dựa trên số dư gửi tiền',
text19:'Tiền tệ hiện tại',
text20:'Số dư có thể rút được',
text21:'Trong tài khoản',
text22:'Hôm nay',
text23:'Hôm qua',
text24:'Tuần',
text25:'Tháng',
text26:'Khối lượng giao dịch ròng',
text27:'WELCOME là phần thưởng 30% cho lần gửi tiền đầu tiên.',
text28:'Số tiền gửi ban đầu là 100 $. Để nhận 30% thưởng, bạn cần chọn phương thức gửi tiền.',
text29:'Thẻ ngân hàng',
text30:'Ngân hàng',
text31:'Phương thức thanh toán đã chọn:',
text32:'Số tiền tối thiểu:',
text33:'Số tiền tối đa:',
text34:'Chọn phương thức khác',
text35:'Thông tin thanh toán',
text36:'Số tiền',
text37:'Sử dụng thưởng',
text38:'Điều khoản và điều kiện',
text39:'Tôi có mã giảm giá',
text40:'Mã giảm giá',
text41:'Áp dụng mã giảm giá',
text42:'Mã giảm giá bạn nhập là chính xác. Bạn sẽ nhận được thưởng 30%.',
text43:'Bạn sẽ nhận được thưởng',
text44:'%',
text45:'Vui lòng đọc tất cả các câu hỏi thường gặp',
text46:'Để giao dịch tài sản số, bạn cần mở tài khoản cá nhân. Để thực hiện giao dịch thực sự, bạn nhất định cần gửi số tiền tương ứng với giá của tùy chọn.',
text48:'Bạn có {0} mã giảm giá',
text49:'Sử dụng mã giảm giá này để hủy giao dịch lỗ',
text50:'Bạn chỉ có thể hủy giao dịch chưa được bảo vệ, số tiền không vượt quá giá trị tối đa của mã giảm giá.',
text51:'Trạng thái',
text52:'Sử dụng',
text53:'Bạn chưa có lịch sử mã giảm giá. Bạn có thể thêm mã giảm giá bằng cách sử dụng nút dưới đây.',
text54:'Hiển thị lịch sử',
text55:'Nhập mã giảm giá',
text56:'Thông tin cá nhân',
text57:'Trống',
text58:'Tên',
text59:'Họ',
text60:'Ngày sinh',
text61:'Quốc gia',
text62:'Lưu',
text63:'Địa chỉ',
text64:'Xác minh tài liệu',
text65:'Trước khi xác minh tài khoản, bạn cần nhập thông tin cá nhân.',
text66:'Bảo mật',
text67:'Xác minh hai bước',
text68:'Thay đổi mật khẩu',
text69:'Mật khẩu cũ',
text70:'Mật khẩu mới',
text71:'Nhập lại mật khẩu mới',
text72:'Đóng tài khoản',
text73:'Xác minh thẻ tín dụng hoặc thẻ ghi nợ',
text74:'Thêm thẻ tín dụng mới',
text75:'Bạn không có bất kỳ thẻ tín dụng hoặc thẻ ghi nợ nào cần xác minh',
text76:'Bạn chưa có lịch sử giao dịch.',
text77:'Bắt đầu',
text78:'Kết thúc',
text79:'Áp dụng bộ lọc',
text80:'Trước',
text81:'Tiếp theo',
text82:'Lợi nhuận',
text83:'Đã hủy',
text84:'Tài khoản',
text85:'Phí',
text86:'Bạn có thể rút tiền từ tài khoản của nền tảng đến thẻ tín dụng hoặc ví điện tử bạn đã sử dụng để nạp tiền. Bạn có thể yêu cầu rút tiền bất kỳ lúc nào. Yêu cầu rút tiền của bạn sẽ được xử lý trong vòng3 ngày làm việc.',
text87:'Nạp tiền',
text88:'Một số yêu cầu trước đó',
text89:'Lịch sử rút tiền',
text90:'Yêu cầu của tôi',
text91:'Gửi yêu cầu',
text92:'Câu hỏi thường gặp',
text93:'Bạn chưa có bất kỳ yêu cầu nào được gửi',
text94:'Nếu có bất kỳ câu hỏi nào, bạn có thể tìm thấy câu trả lời trong phần Câu hỏi thường gặp.',
text95:'Bản quyền © 2024 Quotex. Bảo lưu mọi quyền.',
text96:'Đăng nhập',
text97:'Mật khẩu',
text98:'Ghi nhớ tôi',
text99:'Quay lại thông tin',
text100:'Hoàn tất',
text104:'Nền tảng đổi mới cho đầu tư thông minh',
   text105: 'Đăng ký và nhận $10.000 trên tài khoản demo nơi bạn học cách giao dịch',
   text106:'Đăng ký',
   text107:'Mở tài khoản demo',
   text108:'Giao diện giao dịch thuận tiện',
   text109:'Chúng tôi đã tạo giao diện đơn giản và thuận tiện nhất để bạn không lạc mất điều quan trọng nhất - giao dịch. ',
   text110:'Tín hiệu tích hợp',
   text111:'Hãy xem xét một chiến lược giao dịch, chúng tôi cung cấp các tín hiệu chính xác và sáng tạo nhất lên tới 87% để giúp bạn phát triển chiến lược hiệu quả của riêng mình. ',
   text112:'Chỉ báo giao dịch',
   text113:'Chúng tôi đã thu thập các chỉ số giao dịch có lợi nhất. Sử dụng chỉ báo này để tăng số dư tài khoản của bạn. ',
   text114:'Tốc độ hoàn hảo',
   text115: 'Nền tảng của chúng tôi sử dụng công nghệ tiên tiến nhất và mang lại tốc độ vượt trội. ',
   text116:'Hãy thử với tài khoản demo',
   text117:'Để kiếm được nhiều tiền hơn',
   text118:'Đưa ra tiên lượng chính xác',
   text119:'Giá tăng hay giảm? ',
   text120:'Mở tài khoản demo',
   text121:'Nhấp chuột',
   text122:'Trên một trong các nút và xem kết quả',
   text123:'Giá sẽ tăng',
   text124:'Giá sẽ giảm',
   text125:'Đang chờ kết quả...',
   text126:'Lợi nhuận của bạn',
   text127:'Xin lỗi, dự đoán của bạn không chính xác',
   text128:'Thử lại',
   text129:'Lợi nhuận của bạn',
   text130:'Xin chúc mừng! Dự đoán của bạn là chính xác',
   text131:'Bắt đầu kiếm tiền',
   text132:'Ứng dụng di động luôn có sẵn',
   text133: 'Tải xuống ứng dụng thân thiện với người dùng của chúng tôi dành cho iPhone hoặc Android và bắt đầu giao dịch! ',
   text134:'Thời gian có sẵn',
   text135:'Bắt đầu giao dịch',
   text136:'3 bước',
   text137:'Mở tài khoản thực miễn phí chỉ trong vài phút',
   text138:'Giao dịch trên tài khoản demo chỉ bằng một cú nhấp chuột',
   text139:'Thực hành',
   text140:'Cải thiện kỹ năng của bạn với tài khoản demo và tài liệu đào tạo',
   text141:'Bắt đầu việc giảng dạy của bạn bằng tài khoản demo',
   text142:'Gửi tiền vào tài khoản thực của bạn và bắt đầu giao dịch',
   text143:'Hơn 410 công cụ, số tiền giao dịch tối thiểu là $10',
   text144:'Phương thức gửi tiền nhảy',
   text145:'Câu hỏi thường gặp',
   text146:'Làm sao để làm chủ được nó? ',
   text147:'Đăng ký tài khoản demo và thực hành sử dụng tài khoản này. Tài khoản demo giống như tài khoản thật nhưng không cần đặt cọc. ',
   text148:'Mất bao lâu để xử lý việc rút tiền? ',
   text149: 'Trung bình quá trình rút tiền mất từ ​​1 đến 5 ngày để xử lý kể từ ngày nhận được yêu cầu tương ứng của người dùng và chỉ phụ thuộc vào số lượng yêu cầu được xử lý đồng thời. Công ty luôn cố gắng thanh toán trực tiếp yêu cầu của Người dùng vào ngày nhận được. ',
   text150:'Nền tảng giao dịch là gì? tác dụng là gì? ',
   text151: 'Nền tảng giao dịch là một hệ thống phần mềm. Người dùng sử dụng các công cụ tài chính do hệ thống này cung cấp để thực hiện các giao dịch (hoạt động), đồng thời sử dụng giá trị báo giá, tình hình thị trường theo thời gian thực, hành động của công ty và các thông tin khác do công ty cung cấp. ',
   text152:'Tôi có thể sử dụng điện thoại di động để thực hiện giao dịch không? ',
   text153:'Nền tảng của chúng tôi sử dụng công nghệ tiên tiến nhất và có thể mở trong trình duyệt của bất kỳ máy tính hoặc điện thoại thông minh nào',
   text154:'Số tiền gửi tối thiểu là bao nhiêu? ',
   text155: 'Ưu điểm của nền tảng giao dịch của công ty này là bạn không cần phải gửi số tiền lớn vào tài khoản của mình. Bạn có thể bắt đầu giao dịch bằng cách đầu tư một số tiền nhỏ. Khoản tiền gửi tối thiểu là $10. ',
   text156:'Có hoa hồng khi gửi hoặc rút tiền từ tài khoản không? ',
   text157:'Không. Công ty không tính bất kỳ khoản hoa hồng nào cho các hoạt động như gửi tiền vào tài khoản hoặc rút tiền. ',
   text158: 'Tuy nhiên, cần lưu ý rằng hệ thống thanh toán có thể tính phí hoa hồng và sử dụng tỷ giá hối đoái chênh lệch. ',
   text159:'Đi tới tất cả các câu hỏi',
   text160:'Quotex: Nền tảng đổi mới',
   text161:'Giao dịch tài sản kỹ thuật số',
   text162:'Mở tài khoản thật',
   text163:'Đối tác',
   text164:'Tài khoản demo',
   text165:'Thông tin liên hệ',
   text166:'Câu hỏi quan trọng',
   text167:'Vấn đề tài chính',
   text168:'xác minh',
   text169:'Quy định',
   text170:'Chính sách quyền riêng tư',
   text171:'Điều khoản thanh toán',
   text172:'Thỏa thuận dịch vụ',
   text173:'Cảnh báo rủi ro',
   text174:'Quy tắc hoạt động giao dịch',
   text175:'Quy tắc hoạt động phi giao dịch',
   text176:`Cảnh báo rủi ro: Giao dịch trên nền tảng giao dịch tài sản kỹ thuật số và sử dụng các công cụ cấp vốn cho vay có thể gây ra rủi ro đáng kể và có thể dẫn đến mất toàn bộ vốn đầu tư của bạn. Đừng đầu tư nhiều hơn mức bạn có thể chi trả và bạn nên hiểu những rủi ro liên quan. Giao dịch sử dụng các công cụ tài trợ vay mượn không phù hợp với hầu hết các nhà đầu tư. Giao dịch mà không sử dụng vốn vay như cổ phiếu cũng có rủi ro vì giá cổ phiếu có thể giảm và tăng, điều này có thể có nghĩa là ngay cả khi bạn thắng, bạn sẽ nhận lại ít hơn nhiều so với số tiền bạn đã đầu tư.
   Thành công trong quá khứ không đảm bảo cho thành công trong tương lai. Trước khi giao dịch, vui lòng chú ý đến kinh nghiệm và trình độ giao dịch của bạn, mục tiêu đầu tư và liên hệ với cố vấn tài chính độc lập nếu cần thiết. Người dùng nên xác định xem việc sử dụng các dịch vụ mang nhãn hiệu Quotex có được phép hay không dựa trên luật pháp và quy định của quốc gia cư trú của họ. `,
}