export default {
  '+{value}% profit': '+{value}% profit',
  'Selfie verification required': 'Vérification de selfie requise',
  'In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.':
    "Afin d'effectuer les étapes de vérification de compte nécessaires, nous vous demandons de bien vouloir fournir une photo haute résolution de vous-même (selfie) tenant votre pièce d'identité avec une date actuelle et votre signature sur une feuille de papier. Votre visage, votre corps et vos deux mains doivent être visibles.<br><br>Les détails du document doivent être clairs et lisibles.",
  'Your documents have been checked, now your identity is verified.': 'Vos documents ont été vérifiés,  votre identité est confirmé.',
  'You need fill identity information before verification your profile.': "Vous devez remplir les informations d'identité avant de vérifier votre profil.",
  'Documents verification': 'Vérification des Documents',
  "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "La vérification de profil signifie juste  fournir un document officiel certifiant l'identité du Client. Cette procédure peut être initiée par le service de sécurité de l'entreprise à tout moment.",
  'Upload Photo': 'Télécharger Une Photo',
  Nickname: 'Nickname',
  'First Name': 'Prénom',
  'Last Name': 'Nom De Famille',
  'The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.': 'Le nom doit être spécifié exactement comme dans le document. Pour avoir fourni des informations incorrectes, le compte peut être supprimé sans avertissement.',
  'The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.': 'Le nom de famille doit être spécifié exactement comme dans le document. Pour avoir fourni des informations incorrectes, le compte peut être supprimé sans avertissement.',
  "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "Il n'est pas possible de modifier davantage votre date de naissance. Si vous l'avez mal indiqué, vous ne pourrez pas retirer de fonds de votre compte.",
  Empty: 'Vide',
  'Date of birth': 'Date de naissance',
  Unverified: 'Non vérifié',
  Verified: 'Verifié ',
  Resend: 'Renvoyer',
  Country: 'Pays',
  Address: 'Adresse',
  'Change Identity Info': "Modifier Les Informations D'Identité",
  'Identity Info': "Informations d'Identité",
  'Two-factor authentication on login': 'Authentification à deux facteurs lors de la connexion',
  'Two-factor authentication on withdrawal': 'Authentification à deux facteurs lors du retrait',
  Active: 'Actif',
  Inactive: 'Inactif',
  Setup: 'Configuration',
  'Two-step verification': 'Vérification en deux étapes',
  Security: 'Sécurité',
  'Google Authenticator': 'Google Authenticator',
  'Scan the QR code': 'Scannez le QR code',
  "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Nous prenons votre sécurité très au sérieux, c'est pourquoi nous exigeons une vérification en deux étapes. Voici comment le configurer:",
  'Install ': 'Installez',
  "Can't scan?": 'Vous ne pouvez pas scanner?',
  'Click here.': 'Cliquez ici.',
  'Enter this code into Google Authenticator.': 'Saisissez ce code dans Google Authenticator.',
  'Back to QR code.': 'Retour au code QR.',
  'Enter the 6-digit code from Authenticator below': "Entrez le code à 6 chiffres de l'authentificateur ci-dessous",
  'Enter 6-digit code...': 'Entrez le code à 6 chiffres …',
  "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": 'Bon travail. Vous avez activé la validation en deux étapes avec succès. La prochaine fois que vous vous connecterez, vous serez invité à entrer un code.',
  Code: 'Code',
  'contact support': 'contacter le service client.',
  'If you ever lose access to your 2-step verification device, please ': "Si jamais vous perdez l'accès à votre appareil de vérification en 2 étapes, veuillez",
  'Yes, activate': 'Oui, activer',
  Cancel: 'Annuler',
  Close: 'Fermez',
  Language: 'Langue',
  Timezone: 'Fuseau horaire',
  'Delete Account': 'Supprimez votre Compte',
  'Verification Credit / Debit Cards': 'Vérification de la carte de crédit',
  'Add New Card': 'Ajouter une nouvelle carte',
  'Wait for loading...': 'Attendez le chargement…',
  'You don’t have any credit / debit cards for verification': "Vous n'avez aucune carte pour effectuer une vérification",
  Verificate: 'vérification',
  'Verification Card': 'Vérificationde la carte',
  'Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.':
    "Veuillez placer votre pièce d'identité (avec photo, nom et date de naissance) ainsi que le recto de votre carte bancaire (indiquant le numéro, la date d'expiration et votre nom) sur une feuille A4 vierge. <br> Veuillez écrire «Quotex.io »Et ajoutez-y votre signature. <br> Prenez une photo et téléchargez-la.",
  'Card:': 'Carte',
  'Type:': 'Type:',
  'Your ID and bank card on an A4 sheet': "Votre pièce d'identité et carte bancaire sur une format A4",
  'Yes, confirm': 'Oui, confirmer',
  'Click or drag the image': "Cliquez ou faites glisser l'image",
  Card: 'Cartes ',
  Documents: 'Documents',
  'First, please fill out the “Identity Info” section in your Profile, and click on "Change Identity Info" button to save changes. After that you will be able to add a new card.': "Tout d'abord, veuillez remplir la section “Informations d'identité” de votre profil et cliquer sur le bouton \"Modifier les informations d'identité\" pour enregistrer les modifications. Après cela, vous pourrez ajouter une nouvelle carte.",
  'Specify the card details for further operations for depositing and debiting funds.': "Spécifiez les détails de la carte pour d'autres opérations de dépôt et de débit de fonds.",
  'Card number:': 'Numéro de carte:',
  'Expiry month:': "Mois d'expiration:",
  'Expiry year:': "Année d'expiration:",
  'Cardholder name:': 'Nom du titulaire de la carte:',
  Next: 'Suivant',
  'Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.': 'Veuillez apposer votre signature et la date du jour sur la feuille, déposer votre document avec votre carte bancaire, prendre une photo et la télécharger dans le formulaire ci-dessous.',
  'Do you have a two-sided document?': 'Avez-vous un document recto verso?',
  'Upload the back side as a separate file, also on a sheet with the date and your signature.': 'Téléchargez le verso en tant que fichier séparé, également sur une feuille avec la date et votre signature.',
  'Submit only valid documents': 'Soumettre uniquement des documents valides',
  'Do not edit or crop photo': 'Ne pas modifier ou recadrer la photo',
  "Do not upload other people's documents": "Ne pas télécharger les documents d'autres personnes",
  'Read all': 'Tout lire',
  'Uploading files': 'Téléchargement de fichiers',
  'For single-sided documents, upload only the front side.': 'Pour les documents recto, téléchargez uniquement le recto.',
  Back: 'verso',
  'Add a card': 'Ajouter une carte',
  'File Requirements': 'Exigences de fichier',
  'Scanned images are not accepted for card verification': 'Les images numérisées ne sont pas acceptées pour la vérification de la carte',
  'We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)': "Nous acceptons uniquement les fichiers avec votre passeport civil ordinaire ou votre carte d'identité nationale (les deux côtés doivent être téléchargés en tant que fichiers séparés)",
  'Driving license CANNOT be accepted': 'Le permis de conduire NE PEUT PAS être accepté',
  'External (foreign/international/travel) passport will not be accepted as well': 'Le passeport externe (étranger/international/de voyage) ne sera pas non plus accepté',
  'If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature': 'Si le document a 2 faces, vous devez télécharger le verso dans un fichier séparé, sur la même feuille de papier, avec la date du jour et votre signature',
  'All information in the document and on the card should be clear and readable on the photo': 'Toutes les informations contenues dans le document et sur la carte doivent être claires et lisibles sur la photo',
  'Both your document and your card must fit into the photo completely the edges should not be cropped': "Votre document et votre carte doivent s'insérer complètement dans la photo, les bords ne doivent pas être rognés",
  'There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data': "Il ne doit pas y avoir de reflets ou d'autres défauts de la photo qui pourraient rendre difficile la lecture des données du document et de la carte",
  'Expired documents and cards will be rejected automatically': 'Les documents et cartes expirés seront automatiquement rejetés',
  'If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper': "Si votre carte ne porte pas votre nom, vous devez télécharger une capture d'écran du relevé bancaire, indiquant le numéro de votre carte et votre nom. Dans certains cas, il est permis d'écrire votre nom à la main sur la même feuille de papier",
  'It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)': "Il n'est pas permis de cacher des éléments des documents, contenant les données, nécessaires à la vérification (nom, date de naissance, photographie)",
  'It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible': 'Il est permis de masquer les 8 chiffres du numéro de la carte. Les 4 premiers et les 4 derniers chiffres doivent être clairement visibles',
  'CVV code should be hidden at all times, in every case': 'Le code CVV doit être caché à tout moment, dans tous les cas',
  'Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed': "Toute modification de vos fichiers est interdite. Seules les photos non éditées et les images numérisées peuvent être acceptées. La modification des informations personnelles dans vos documents (nom, date de naissance, etc.) n'est pas autorisée",
  'The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted': 'La photographie doit faire partie du document (collé ou imprimé). Les photos jointes séparément ne seront pas acceptées',
  'It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)': 'Il est uniquement autorisé de télécharger des documents et des cartes au nom, spécifié dans le profil (sur lequel votre compte est enregistré)',
  'We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified': "Nous vous rappelons qu'en cas de soumission d'informations fausses, le compte peut être suspendu/bloqué, jusqu'à ce que les circonstances soient clarifiées",
  'The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time': 'La Société se réserve le droit de vous demander de fournir des documents supplémentaires pour la vérification du compte (y compris votre selfie avec document) à tout moment',
  'For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)': "Pour toutes les questions liées à la vérification des documents, vous pouvez créer un ticket d'assistance sur la plateforme (la section « Aide » est le premier bouton avec une icône de point d'interrogation clignotante, dans votre compte)",
  Profile: 'Compte',
  Deposit: 'Dépôt',
  Withdrawal: 'Retrait',
  Transactions: 'Opérations',
  Trades: 'Transactions',
  Market: 'Marché',
  Analytics: 'analytique',
  'My current currency': 'Ma devise actuelle',
  'Available for withdrawal': 'Disponible pour le retrait',
  'In the account': 'Dans le compte',
  'Percentage of turnover {percent}%': "Pourcentage du chiffre d'affaires  {percent}%",
  'Cashback {percent}%': 'Cashback {percent}%',
  'Currency:': 'Devise:',
  Change: 'Changer',
  'Live Account': 'Compte Live',
  'Daily budget limit': 'Limite de budget journalier',
  'The value cannot exceed your total budget': 'La valeur ne peut pas excéder votre budget total ',
  'Demo Account': 'Compte Démo',
  Apply: 'Appliquer',
  Edit: 'Éditer',
  'New value': 'Nouvelle valeur',
  Confirm: 'Confirmez',
  Logout: 'Déconnexion',
  'No, cancel': 'Non, annuler',
  'Exchange Form': 'Échanger de',
  'My Currency:': 'Ma devise:',
  'New Currency:': 'Nouvelle devise:',
  'You are exchanging': 'Vous échangez',
  'You will receive': 'Vous allez recevoir',
  'Exchange Fee': "Frais d'échange",
  'Password changed': 'Mot de passe changé',
  'Old password': 'Ancien mot de passe',
  'New password': 'Nouveau mot de passe',
  'Confirm new password': 'Confirmez le nouveau mot de passe',
  'Change Password': 'Modifier votre mot de passe',
  'Confirm password must be equal new password value': '"Confirmer le mot de passe" doit être égal au nouveau mot de passe',
  'About us': 'À propos de nous',
  Support: 'Soutien',
  Help: 'Aide',
  '2-step verification': 'Vérification en 2 étapes',
  "Please enter the PIN-code we've just sent to your email": "Veuillez saisir le code PIN que nous venons d'envoyer à votre adresse e-mail",
  'Please enter the 6-digit code from your authentication app': "Veuillez saisir le code à 6 chiffres de votre application d'authentification",
  'The First Name field is required': 'Le champ Prénom est obligatoire',
  'The Last Name field is required': 'Le champ Nom de famille est obligatoire',
  'Verification of documents': 'Vérification des documents',
  'Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.': "Veuillez télécharger une photo couleur ou une image numérisée de votre passeport civil ordinaire, de votre permis de conduire ou de votre carte d'identité nationale.",
  'Upload Documents': 'Télécharger des documents',
  'Why?': 'Pourquoi?',
  'Do you want to confirm deleting your account without undo? This action cannot be undone.': 'Voulez-vous confirmer la suppression de votre compte? Cette action ne peut pas être annulée.',
  'Set up 2-step verification': 'Configurer la vérification en 2 étapes',
  'Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.': 'Malheureusement, vous ne pouvez pas télécharger de fichiers dans cette section. <br> Veuillez vous référer aux messages du service client et joindre les fichiers requis à un ticket.',
  'The uploaded document does not match the requirements': 'Le document téléchargé ne correspond pas aux exigences',
  "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "Les documents suivants sont éligibles pour l'identification : pièce d'identité, passeport, passeport international ou permis de conduire.",
  'Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.': 'Les images doivent être de haute qualité, les données doivent être faciles à lire et les bords du document ne doivent pas être rognés.',
  'Balance from {amount}': 'Solde a partir de {amount}',
  'Not verified': 'Non vérifié',
  Clear: 'Clair',
  'In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.': "Afin d'éviter les restrictions de service, veuillez compléter le processus de vérification: remplissez vos données personnelles et téléchargez les documents requis.",
  'Get verified': 'Soyez vérifié',
  'I got it!': "J'ai compris!",
  'Passport.IN': 'Aadhaar',
  'Passport.BR': 'CPF',
  'AADHAAR Verification': 'Vérification AADHAAR',
  'Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).': 'Veuillez télécharger une photo couleur de votre Aadhaar physique (uniquement la face avant avec votre photo et votre numéro de document).',
  'Send AADHAAR': 'Envoyer AADHAAR',
  'AADHAAR number:': 'Numéro AADHAAR:',
  'Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.': "Veuillez télécharger une photo couleur de votre Aadhaar physique (uniquement la face avant avec votre photo et votre numéro de document).  Aucune version électronique n'est acceptée.",
  'You have already uploaded {number} files. Please wait until they are checked.': "Vous avez déjà téléchargé des fichiers {number}. Veuillez patienter jusqu'à ce qu'ils soient vérifiés.",
  'Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.':
    "Veuillez télécharger une photo couleur de votre document: Pour RG-les deux côtés doivent être téléchargés en tant que fichiers séparés. Pour CNH-seulement la face avant avec votre photo. Le document doit être valide (moins de 10 ans à compter de la date d'émission) et doit indiquer votre numéro CPF. * dans le cas où il n'y a pas de CPF dans votre document - veuillez télécharger une photo séparée de Comprovante de Inscrição no CPF, ou une capture d'écran de votre relevé bancaire indiquant votre nom et votre numéro CPF. ** Veuillez ne télécharger aucune version électronique. Ils ne seront pas acceptés.",
  'Submit for verification': 'Soumettre pour vérification',
  Settings: 'Paramètre',
  'Please upload a color photograph of the front of your national identity card with your photo.': "Veuillez télécharger une photographie en couleur du recto de votre carte d'identité nationale avec votre photo.",
  Template: 'Modèle',
  'Light Mode': 'Mode jour',
  Twilight: 'crépuscule',
  'Full Night': ' Mode Nuit ',
  "Grid's opacity": 'Opacité de la grille',
  'Auto-scrolling': 'Défilement automatique',
  'Automatic graphic scrolling': 'Défilement automatique du graphique ',
  '1-click trade': 'Tradez  en 1 clic',
  'Open trades without confirmation': 'Transactions ouvertes sans confirmation',
  'Use timezone': 'Utilisez le fuseau horaire',
  'Use your timezone data': 'Utilisez vos données de fuseau horaire',
  'Graph colors': 'Couleurs du graphique ',
  'Up Trend': 'Tendance haussière ',
  'Down Trend': 'Tendance a La Baisse',
  Platform: 'Plateforme ',
  'Short order label': 'Étiquette de commande courte',
  'Use short order element mode': "Utiliser le mode d'élément de commande courte",
  'Perfomance Mode': 'Mode Performance',
  'Use optimized rendering for chart and candles': 'Utilisez un rendu optimisé pour les graphiques et les bougies',
  Background: 'Arrière-plan',
  'Choose file': 'Choisir le fichier',
  'Max size — 2 Mb': 'Taille maximale — 2 Mo',
  'Sign up': 'Créer un compte',
  'Sign in': 'Se connecter',
  'Budget and limits': 'Budget et limites',
  'Daily limit': 'Limite journalière',
  'Not set': 'Non défini',
  'Total funds': 'Total des fonds',
  'Top up': 'Recharger',
  'Set up': 'Configurer',
  'Set a daily limit on trading to reduce financial risks.': 'Fixez une limite quotidienne de trading pour réduire les risques financiers.',
  'Limit amount': 'Montant limite',
  More: 'Plus',
  'Leader Board': 'Classement de meilleurs ',
  Signals: 'Signaux',
  Notifications: 'Notification',
  'mark all as read': 'tout marquer comme lu',
  'No notifications': 'Aucune notification',
  'No more notifications': 'Plus de notifications',
  'Your position:': 'Votre position:',
  'How does this rating work?': 'Comment fonctionne ce classement?',
  'of the Day': 'De la Journée',
  'Nothing has been found for current query, try another asset name.': "Rien n'a été trouvé pour la requête actuelle, essayez un autre nom d'actif.",
  'My rating in the Leader Board': 'Mon classement dans le tableau des leaders',
  'Participants can only be those who trade in live mode': 'Les Participants ne peuvent être que ceux qui tradent avec leur compte réel ',
  'Your position depends on the amount of earned money': "Votre position dépend du montant d'argent gagné",
  'Calculated every day, from 00:00 UTC to 23:59 UTC': 'Calculé tous les jours, de 00: 00 UTC à 23: 59 UTC',
  'All traders on our platform participate in this rating. Main features of the rating:': 'Tous les traders de notre plateforme participent à ce classement . Principales caractéristiques du classement :',
  'How does it work?': 'Comment ça marche?',
  'Trading signals': 'Signaux de Trading',
  'Past signals': 'Signaux antérieurs',
  'What’s it?': "Qu'es ce que c'est?",
  'A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.': "Un signal de trading n'est pas une instruction directe au trading, mais une recommandation d'analyste. Il ne devrait pas remplacer l'analyse de marché indépendante.",
  'You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.': 'Vous avez en moyenne 10 minutes pour profiter du signal de trading. Passé ce délai, les cotations peuvent changer et avoir un effet inattendu sur le résultat de la transaction.',
  Continue: 'Continuez',
  Duration: 'Durée',
  'Return back to FAQ': 'Retournez à la FAQ',
  'Return back to Help': "Retournez à la page d'aide",
  'How to withdraw money from the account?': 'Comment effectuer un retrait du compte?',
  'The procedure for withdrawing capital is extremely simple and is carried out through your individual account.': 'La procédure de retrait de fonds est simple et est effectuée via votre compte personel.',
  'The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question "How can I deposit?").': 'La méthode par laquelle vous avez crédité votre compte est également celle que vous utiliserez lors du retrait de fonds (voir la question sur  " Comment puis-je  faire un dépôt ?").',
  'For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.': 'Par exemple, si vous avez effectué un dépôt sur votre compte via le système de paiement Visa, vos retraits seront effectués via le même système.',
  "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.":
    "En ce qui concerne le retrait d'un montant suffisamment important, Nous pouvons demander une vérification (la vérification est demandée à notre seule discrétion), c'est pourquoi il est très important de s'enregistrer avec vos données personnelles afin de confirmer vos droits à tout moment.",
  'What is the minimum withdrawal amount?': 'Quel est le montant minimum de retrait?',
  'The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).': 'Le montant minimum de retrait commence à partir de 10 USD pour la plupart des systèmes de paiement.<br>Pour les crypto-monnaies, ce montant commence à partir de 50 USD (et peut être plus élevé pour certaines devises, par exemple Bitcoin).',
  'Do I need to provide any documents to make a withdrawal?': 'Dois-je fournir des documents pour effectuer un retrait?',
  'Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.':
    "Généralement des documents supplémentaires pour retirer des fonds ne sont pas nécessaires . Mais nous pouvons, à notre discrétion, vous demander de confirmer vos données personnelles en demandant certains documents. Habituellement, cela est fait afin de prévenir les activités liées au commerce illégal, la fraude financière, ainsi que l'utilisation de fonds obtenus illégalement.",
  'The list of such documents is minimum, and the operation to provide them will not take you much time and effort.': "La liste de ces documents est minimale, et l'opération ne vous prendra pas beaucoup de temps et d'efforts.",
  'How long does it take to withdraw funds?': 'Combien de temps faut-il pour faire un retrait de fonds?',
  'On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.':
    "En moyenne, la procédure de retrait prend entre un à cinq jours à compter de la date de réception de la demande correspondante du Client et ne dépend que du volume de demandes traitées simultanément.Nous essayons toujours d'effectuer les paiements directement le jour où la demande est reçue du Client.",
  'Is there any fee for depositing or withdrawing funds from the account?': "Y a t'il des frais lors des retraits ou des dépôts au  compte?",
  'No. The company does not charge any fee for either the deposit or for the withdrawal operations.': 'Non. Nous ne prenons aucun frais pour le dépôt ou pour les opérations de retrait.',
  'However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.': 'Cependant, il est important de noter que les systèmes de paiement peuvent facturer leurs frais et utiliser le taux de conversion des devises internes.',
  'Can I close my account? How to do it?': 'Puis-je supprimer mon compte? Comment faire?',
  'You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.': 'Vous pouvez supprimer votre compte en cliquant sur le bouton "Supprimer le compte" situé au bas de la page de profil.',
  'If I made a mistake during entering data into my individual account, how can I fix this?': "Si j'ai fait une erreur lors de la saisie de données dans mon compte, comment puis-je résoudre ce problème?",
  "You have to contact the technical support service on the Company's website and edit the profile.": 'Vous devez contacter le service de support technique sur notre site Web et modifier les données .',
  'What data is required to register on the Company website?': "Quelles sont les données requises pour s'inscrire sur votre site Web?",
  'To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.': "Pour faire de l'argent sur les options numériques, vous devez d'abord ouvrir un compte qui vous permettra d'effectuer des transactions. Pour ce faire, vous devez vous inscrire sur le site Web de l'entreprise.",
  'The registration process is simple and does not take much time.': "Le processus d'inscription est simple et ne prend pas beaucoup de temps.",
  'It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:': 'Il est nécessaire de remplir le formulaire proposé. Vous devrez entrer les informations suivantes:',
  'name (in English)': 'Nom (En Anglais)',
  'email address (indicate the current, work, address)': "Adresse e-mail (indiquer l'adresse courante)",
  'telephone (with a code, for example, + 44123 ....)': "Téléphone (avec le code d'indication par exemple, + 44123 ....)",
  'a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)':
    "Ceci est le mot de  passe que vous utiliserez à l'avenir pour vous connecter a votre compte  (afin de minimiser le risque d'accès non autorisé à votre compte individuel, nous vous recommandons de créer un mot de passe complexe en utilisant des lettres minuscules, majuscules et des chiffres. Ne divulguez pas le mot de passe à des tiers)",
  'After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.': "Après avoir rempli le questionnaire, il vous sera proposé différentes façons d'effectuer un dépôt dans votre compte de trading.",
  "In what currency is the Client's account opened? Can I change the currency of the Client's account?": 'Dans quelle devise le compte du Client est-il ouvert? Puis-je changer la devise du compte du Client?',
  'By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.': 'Par défaut, un compte de trading est ouvert en dollars américains.\n\nMais pour votre commodité, la devise peut être changée à tout moment dans votre profil.\n\nUne liste des devises disponibles se trouve sur votre page de profil dans le compte de votre client.',
  "A list of available currencies can be found on your profile page in your Client's account.": 'Une liste des devises disponibles se trouve sur page de votre  compte.',
  'What are digital options?': "C'est quoi les options numériques?",
  'Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).': "Option-un instrument financier dérivé basé sur n'importe quel actif sous-jacent (il peut s'agir d'une action, de paires de devises, de pétrole, etc.).",
  'DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.': "Option Numérique - une option non standard qui est utilisée pour faire un profit sur les mouvements de prix d'actifs pendant une certaine période de temps.",
  'A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).':
    "Une option numérique, selon les modalités convenues par les parties à la transaction, à un moment déterminé par les parties, apporte un revenu fixe (la différence entre les revenus et le prix de l'actif) ou la perte (dans le montant de la valeur de l'actif).",
  'Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.': "Étant donné que l'option numérique est achetée à l'avance à un prix fixe, le montant du bénéfice, ainsi que le montant de la perte potentielle, sont connus avant même la transaction.",
  'Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).': "Une autre caractéristique de ces offres est la limite de temps. Toute option a son propre terme (temps d'expiration ou temps de conclusion).",
  'Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.':
    "Quel que soit le degré de variation du prix de l'actif sous-jacent (s'il a augmenté ou baissé, en cas de GAIN d'une option, Un paiement fixe est toujours effectué. Par conséquent, vos risques ne sont limités que par le montant pour lequel l'option est acquise.",
  'What is the expiration period of a trade?': "C'est quoi la période d'expiration d'une transaction?",
  'The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.': "La période d'expiration est le temps après lequel la transaction sera considérée comme terminé (fermé) et le résultat est automatiquement résumé.",
  'When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).': "Lors de la conclusion d'une transaction avec des options numériques, vous déterminez indépendamment le temps d'exécution de la transaction (1 minute, 2 heures, mois, etc.).",
  'What is the gist of digital options trading?': "Quelle est l'essence de Digital Trading?",
  'The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.': "Le fait est que l'option numérique est le type d'instrument financier dérivé le plus simple. Afin de gagner de l'argent sur le marché des options numériques, vous n'avez pas besoin de prédire la valeur du prix du marché d'un actif qu'il peut atteindre.",
  'The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.': "Le principe se résume à la résolution d'une seule tâche - la variation du prix de l'actif, va il augmenter ou diminuer au moment de l'exécution du contrat.",
  'The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.':
    "L'aspect de ces options est que cela n'a pas d'importance pour vous, que le prix de l'actif sous-jacent grimpera de cent points ou d'un seul, à partir du moment où la transaction  est conclu à sa clôture. Il est important pour vous de déterminer uniquement la direction du mouvement de ce prix.",
  'If your prognosis is correct, in any case you get a fixed income.': 'Si votre pronostic est correct, dans tous les cas, vous obtenez un revenu fixe.',
  'What are the possible results of the placed trades?': "Quels sont les résultats possibles d'une transaction?",
  'There are three possible outcomes in the digital options market:': 'Il y a trois résultats possibles sur le marché des options numériques:',
  'in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.': "Dans le cas où votre pronostic pour déterminer la direction du mouvement des prix de l'actif sous-jacent est correct, vous recevez un profit.",
  'if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).': "Si, au moment où l'option a été conclue, vos prévisions se sont avérées erronées, vous subissez une perte limitée par la taille de la valeur de l'actif (c-à-d, en fait, vous ne  perdez que votre investissement).",
  'if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.':
    "Si le résultat de la transaction est nul (le prix de l'actif sous-jacent n'a pas changé, l'option est conclue au prix auquel elle a été achetée), vous retournez votre investissement.Ainsi, le niveau de risque est toujours limité que par la taille de la valeur de l'actif.",
  'Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?': "Votre plateforme de trading dispose telle d'un compte démo afin de comprendre le processus de travail avec les options numériques sans dépenser son propre argent?",
  "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.":
    "Oui. Afin de développer des compétences et de tester les capacités de la plateforme, vous pouvez utiliser un compte démo (gratuit). C'est une sorte de simulateur qui vous permet d'essayer tout d'abord, et ensuite passer aux transactions réelles. Un tel compte de démonstration est également adapté pour les tradeurs expérimentés pour améliorer leur niveau professionnel.",
  'The balance of such an account is 10,000 units.': "Le solde d'un tel compte est de 10 000 unités.",
  'What determines profit size?': "Qu'est-ce qui détermine la taille du bénéfice?",
  'There are several factors that affect the size of your profit:': 'Votre bénéfice depend de plusieurs facteurs:',
  'the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)': "La liquidité de l'actif que Vous choisissez sur le marché (plus l'actif est demandé sur le marché, plus vous obtiendrez de profits)",
  'the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)': "l'heure de la transaction  (la liquidité d'un actif le matin et la liquidité d'un actif dans l'après-midi peuvent varier de manière significative)",
  'tariffs of a brokerage company': 'Tarifs du courtier',
  'changes in the market (economic events, changes in part of a financial asset, etc.)': "Changements sur le marché (événements économiques, changements d'une partie d'un actif financier, etc.)",
  'What are the varieties of digital options?': "Quelles sont les variétés d'options numériques?",
  'Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.': "Pour effectuer une transaction avec les options numérique,  vous devez choisir l'actif qui sous-tend l'option. Vos prévisions seront effectuées sur cet actif.",
  'Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.': "Simplement, en achetant un contrat numérique, vous pariez réellement sur le mouvement des prix d'un tel actif sous-jacent.",
  'An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:': "Un actif sous-jacent est un \"élément\" dont le prix est pris en compte lors de la conclusion d'une transaction. l'actif sous-jacent des options numériques, les produits les plus recherchés sur les marchés agissent généralement. Il existe quatre types d'actifs:",
  'securities (shares of world companies)': 'Titres (actions de sociétés mondiales)',
  'currency pairs (EUR / USD, GBP / USD, etc.)': 'Paires de devises (EUR/USD, GBP/USD, etc.)',
  'raw materials and precious metals (oil, gold, etc.)': 'Matières premières et métaux précieux (huile, Or, etc.)',
  'indices (S&P 500, Dow, dollar index, etc.)': 'les indices (S&P 500, Dow jones, indice du dollar, etc.)',
  'A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.': "Un actif sous-jacent universel n'existe pas. En le choisissant, vous n'êtes guidé que par vos propres connaissances, votre intuition et divers types d'informations analytiques, ainsi que par l'analyse de marché d'un instrument financier particulier.",
  'What is a trading platform and why is it needed?': "Qu'est ce qu'une plateforme de trading et pourquoi est-elle nécessaire?",
  'Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.':
    "Une plateforme de trading est un logiciel complexe qui permet au Client d'effectuer des transactions (opérations) en utilisant différents instruments financiers. Il a également accès à diverses informations telles que la valeur des cotations, les positions du marché en temps réel, les actions de l'entreprise, etc.",
  'How to learn quickly how to make money in the digital options market?': "Comment apprendre rapidement comment gagner de l'argent sur le marché des options numériques?",
  'develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them': 'Développez vos propres stratégies de trading, dans lesquelles le nombre de transactions correctement prédits sera maximal, et appliquez les',
  'diversify your risks': 'Diversifiez vos risques',
  'In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.':
    "Dans l'élaboration de stratégies, ainsi que dans la recherche d'options de diversification, la surveillance du marché, l'étude des informations analytiques et statistiques qui peuvent être obtenues à partir de diverses sources (ressources Internet, Avis d'experts, analystes dans ce domaine, etc.) vous aidera, dont l'un est notre site Web.",
  'Is the download of the program to a computer or smartphone required?': 'Le téléchargement du programme sur un ordinateur ou un smartphone est-il nécessaire?',
  "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": 'pas besoin de faire un dépôt lors de la création de votre compte. ',
  'At what expense does the Company pay profit to the Client in case of successful trade?': "À quels frais l'entreprise paie-t-elle des bénéfices au Client en cas de transaction réussite?",
  'Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.':
    'Entreprise gagne avec les clients. Par conséquent, Elle est intéressé par la part des transactions rentables qui prévaut de manière significative sur les transactions non rentables, en raison du fait que la société a un pourcentage de paiements pour une stratégie réussite choisie par le Client.',
  'In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.':
    'En outre, les transactions effectuées par le Client constituent ensemble le volume de négociation de la société, qui est transféré à un courtier ou à une bourse, qui sont à leur tour inclus dans le pool de fournisseurs de liquidité, ce qui conduit ensemble à une augmentation de la liquidité du marché lui-même.',
  'What is account verification?': "Qu'est-ce que la vérification de Compte?",
  'Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:':
    "La vérification est la confirmation par le Client de ses données personnelles en fournissant des documents supplémentaires. Les conditions de vérification pour le Client sont aussi simples que possible, et la liste des documents est minimale. Par exemple l'entreprise,  peut demander:",
  "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": 'Fournir un scan en couleur de la première page du passeport (page avec photo)',
  'identify with the help of a "selfie" (photograph of himself)': "De s'Identifier à l'aide d'une photo du client. ",
  'confirm the address of registration (residence) of the Client, etc': 'De confirmer votre adresse de résidence etc',
  'The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.': "Nous pouvons vous demander des documents supplémentaires s'il  n'est pas possible de vous identifier pleinement  par les données saisies par vous. ",
  'After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.': 'Une fois que les copies électroniques des documents ont été soumises, le Client devra attendre un certain temps pour vérifier les données fournies.',
  'How to understand that I need to go through account verification?': 'Comment comprendre que je dois passer par la vérification de Compte?',
  'If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.': "S'il devient nécessaire de passer la vérification, vous recevrez une notification par e-mail ou par SMS.",
  'At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.': "Nous utilisons les coordonnées que vous avez spécifiées dans le questionnaire lors de l'inscription (en particulier, l'adresse e-mail et le numéro de téléphone ). Par conséquent, soyez prudent et fournissez des informations pertinentes et correctes.",
  'How do I know that I successfully passed verification?': 'Comment savoir que la vérification est terminée?',
  "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "Vous recevrez une notification par e-mail ou par SMS dès  l'achèvement du processus de vérification de votre compte et la possibilité de procéder à des opérations de trading.",
  "Is it possible to indicate other people's (fake) data when registering on the website?": "Est-il possible d'indiquer les données (fausses), d'autres personnes lors de l'inscription sur le site?",
  "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "Non. Le client effectue l'inscription sur le site Web en fournissant des informations complètes et précises sur lui-même, en repondants aux questions posées sur le formulaire d'inscription, et maintient ces informations à jour.",
  'If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.': "S'il est nécessaire d'effectuer divers types de contrôles de l'identité du Client, Nous pouvons  demander des documents ou inviter  le client dans nos bureau.",
  'If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.': "Si les données saisies dans les champs d'inscription ne correspondent pas aux données des documents soumis, votre profil peut être bloqué.",
  'How long does the verification process take?': 'Combien de temps dure le processus de vérification?',
  'No more than 5 (five) business days from the date the Company receives the requested documents.': 'Pas plus de 5 (cinq) jours ouvrables à compter de la date à laquelle nous recevrons  les documents demandés.',
  'Is there a minimum amount that I can deposit to my account at registration?': "Quel est le montant minimum de dépôt lors de l'inscription?",
  'The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.': "L'avantage de notre plateforme est que vous n'avez pas à déposer de grosses sommes sur votre compte. Vous pouvez commencer à trader en investissant une petite somme d'argent. Le dépôt minimum est 10 dollars américains.",
  'How can I deposit?': 'Comment effectuer un dépôt?',
  'It is very easy to do. The procedure will take a couple of minutes.': "C'est simple, la procédure prendra quelques minutes.",
  'Open the trade execution window and click on the green "Deposit" button in the upper right corner of the tab.': 'Ouvrez la fenêtre d\'exécution des transactions et cliquez sur le bouton vert "dépôt" dans le coin supérieur droit de l\'onglet.',
  'You can also deposit the account through your Personal Account by clicking the "Deposit" button in the account profile.': 'Vous pouvez également faire un dépôt via votre Compte en cliquant sur le bouton "Dépôt" dans votre profil.',
  'After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).': 'Il est nécessaire de choisir un moyen de dépôt du compte (Nous offrons plusieurs  méthodes pratiques qui sont disponibles pour le Client et sont affichés dans son compte individuel).',
  'Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.': 'Ensuite, indiquez la devise dans laquelle vous aimeriez faire votre dépôt, par défaut la devise du compte lui-même.',
  'Enter the amount of the deposit.': 'Entrez le montant du dépôt. ',
  'Fill out the form by entering the requested payment details.': 'Remplissez le formulaire en entrant les détails de paiement demandés.',
  'Make a payment.': 'Effectuez le paiement.',
  'Do I need to deposit the account of the trading platform and how often do I need to do this?': 'Dois-je faire des dépôts au compte avant de trader et à quelle fréquence dois-je le faire?',
  'To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.': 'Pour utiliser les options numériques, vous devez ouvrir un compte personel. Pour effectuer des transactions réelles, vous devrez certainement faire un dépôt dans votre compte.',
  "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.":
    "Vous pouvez commencer à trader sans argent, en utilisant uniquement le compte de formation (compte Démo). Un tel compte est gratuit et créé pour démontrer le fonctionnement de la plateforme. Avec l'aide d'un tel compte, vous pouvez pratiquer l'acquisition d'options numériques, comprendre les principes de base du trading, tester diverses méthodes et stratégies, ou évaluer le niveau de votre intuition.",
  'What is the minimum deposit amount?': 'Quel est le montant minimum du dépôt?',
  '{number} questions': '{number} questions',
  Verification: 'Vérification',
  Payment: 'Recharge',
  Payouts: 'Paiement',
  'Ask a specialist': 'Demandez à un spécialiste',
  FAQ: 'FAQ',
  "Didn't find an answer to your question?": "Vous n'avez pas trouvé de réponse à votre question?",
  'Open knowledge base': 'Documentation ouverte ',
  'Contact support': 'Contacter le support',
  'Open FAQ page': 'Ouvrir la page FAQ',
  'New message': 'Nouveau message',
  Tournaments: 'Tournois',
  'To end': 'Pour terminer',
  'Until start': "Jusqu'au début",
  'Front side': 'Front side',
  'Back side (if any)': 'Verso (cas échéant)',
  'Tourna-ments': 'Tournois',
  'Deletion of Account and Personal Data': 'Suppression du compte et des données personnelles',
  'By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!': "En supprimant votre compte et vos données personnelles, vous perdrez définitivement l'accès à votre compte sur la plateforme Quotex. N'oubliez pas que vos données seront supprimées irrémédiablement et qu'il est impossible de restaurer votre compte ultérieurement !",
  'NOTE: Please complete all open trades and pending orders before you delete your account.': 'REMARQUE : Veuillez terminer tous les trades ouverts et les transactions en attente avant de supprimer votre compte.',
  'Request Deletion': 'Demande de Suppression',
  'To delete your account, please contact support service': 'Pour supprimer votre compte, veuillez contacter le service client.',
  'Two-factor authorization via Email': 'Autorisation à deux facteurs par e-mail',
  'This feature is for experienced users who are familiar with Google Authenticator.': 'Cette fonctionnalité est destinée aux utilisateurs expérimentés qui connaissent Google Authenticator.',
  'Please note!': 'Veuillez noter!',
  'If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.': "Si vous perdez l'accès à Google Authenticator pour une raison quelconque après l'avoir connecté, vous perdrez l'accès à votre compte.",
  'Proceed to setup': 'Procéder à la configuration',
  'Account protection method': 'Méthode de protection du compte',
  "Here's how to set it up:": 'Voici comment le configurer:',
  "Please enter the PIN-code we've just sent to your email:": "Veuillez saisir le code PIN que nous venons d'envoyer à votre adresse e-mail:",
  "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": 'Bon travail. Vous avez activé avec succès la vérification en 2 étapes via Google Authenticator. La prochaine fois que vous vous connecterez, vous serez invité à entrer un code.',
  'If you ever lose access to your 2-step verification device, please': "Si jamais vous perdez l'accès à votre dispositif de vérification en 2 étapes, veuillez",
  'Confirm account deletion': 'Confirmer la suppression du compte',
  'To delete your account, follow the link in the email sent to': "Pour supprimer votre compte, suivez le lien dans l'e-mail envoyé à",
  'Image requirements': 'Exigences relatives aux images',
  Email: 'E-mail',
  Сonfirmation: 'Confirmation',
  'Change protection method': 'Changer la méthode de protection',
  'First, enter the code from the Google Authenticator app:': "Saisissez d'abord le code de l'application Google Authenticator:",
  "First, enter the PIN-code we've just sent to your email:": "Entrez d'abord le code PIN que nous venons de vous envoyer par e-mail:",
  "Nice work. You've successfully enabled 2-step verification via Email.": 'Bon travail. Vous avez activé avec succès la vérification en 2 étapes par e-mail.',
  'Receiving codes via Email': 'Recevoir des codes par e-mail',
  'Receiving codes via Google Authenticator': 'Recevoir des codes via Google Authenticator',
  'Choose a method:': 'Choisissez une méthode:',
  'To enter the platform': 'Pour accéder à la plateforme',
  'To withdraw funds': 'Pour effectuer un retrait de fonds',
  day: 'jour ',
  days2: 'Jours',
  days5: 'Jours',

  text: 'Choisir la devise',
  text1: 'E-mail',
  text2: 'Annuler la transaction pour',
  text3: 'Code de réduction gratuit',
  text4: 'Nous améliorons quotidiennement cette plateforme pour la rendre plus pratique.',
  text5: `Aimez-vous cette plateforme ? Veuillez la recommander à d'autres !`,
  text6: 'Veuillez laisser des commentaires sur QUOTEX, vous recevrez une récompense de transaction annulée gratuite.',
  text7: 'Envoyez vos commentaires à notre équipe de service client, après vérification, nous vous enverrons cette récompense.',
  text8: 'Montant minimum de dépôt',
  text9: 'Montant minimum de retrait',
  text10: 'Retrait rapide',
  text11: 'Gratuit',
  text12: 'Non vérifié',
  text13: 'Renvoyer',
  text14: 'Confirmer votre participation',
  text15: 'Annuler la transaction',
  text16: 'Veuillez entrer et activer votre code de réduction immédiatement. Vous pouvez utiliser le code de réduction à tout moment.',
  text17: 'Code de réduction :',
  text18: 'Obtenez une récompense de 30 % en fonction du solde du dépôt',
  text19: 'Devise actuelle',
  text20: 'Solde retirable',
  text21: 'Dans le compte',
  text22: 'Aujourd’hui',
  text23: 'Hier',
  text24: 'Semaine',
  text25: 'Mois',
  text26: 'Volume de transactions nettes',
  text27: 'BIENVENUE est une récompense de 30 % sur le premier dépôt.',
  text28: 'Le montant du premier dépôt est de 100 $. Allez obtenir une récompense de 30 %, choisissez le mode de dépôt.',
  text29: 'Carte bancaire',
  text30: 'Banque',
  text31: 'Mode de paiement sélectionné :',
  text32: 'Montant minimum :',
  text33: 'Montant maximum :',
  text34: 'Sélectionner un autre mode',
  text35: 'Informations de paiement',
  text36: 'Montant',
  text37: 'Utiliser la récompense',
  text38: 'Termes et conditions',
  text39: `J'ai un code de réduction`,
  text40: 'Code de réduction',
  text41: 'Appliquer le code de réduction',
  text42: 'Le code de réduction que vous avez saisi est correct. Vous recevrez une récompense de 30 %.',
  text43: 'Vous recevrez',
  text44: 'de récompense :',
  text45: 'Veuillez consulter toutes les questions fréquemment posées',
  text46: `Pour effectuer des transactions d'actifs numériques, vous devez ouvrir un compte personnel. Pour effectuer de véritables transactions, vous devez certainement déposer un montant en fonction du prix de l'option.`,
  text48: 'Il y a {0} codes de réduction',
  text49: 'Utilisez ce code de réduction pour annuler les transactions en perte',
  text50: 'Vous ne pouvez annuler que les transactions non couvertes dont le montant ne dépasse pas la valeur maximale du code de réduction.',
  text51: 'Statut',
  text52: 'Utiliser',
  text53: `Vous n'avez pas encore d'historique de code de réduction. Vous pouvez utiliser les boutons ci-dessous pour ajouter un code de réduction.`,
  text54: `Afficher l'historique`,
  text55: 'Entrer le code de réduction',
  text56: 'Informations de confidentialité',
  text57: 'Vide',
  text58: 'Prénom',
  text59: 'Nom',
  text60: 'Date de naissance',
  text61: 'Pays',
  text62: 'Enregistrer',
  text63: 'Adresse',
  text64: 'Vérification des documents',
  text65: 'Avant de vérifier le compte, vous devez saisir des informations personnelles.',
  text66: 'Sécurité',
  text67: 'Vérification en deux étapes',
  text68: 'Modifier le mot de passe',
  text69: 'Ancien mot de passe',
  text70: 'Nouveau mot de passe',
  text71: 'Confirmer le nouveau mot de passe',
  text72: 'Déconnexion du compte',
  text73: 'Vérification de la carte de crédit ou de débit',
  text74: 'Ajouter une nouvelle carte bancaire',
  text75: `Vous n'avez pas de carte de crédit ou de débit à vérifier`,
  text76: `Vous n'avez pas encore d'historique de transactions.`,
  text77: 'Début',
  text78: 'Fin',
  text79: 'Appliquer le filtre',
  text80: 'Précédent',
  text81: 'Suivant',
  text82: 'Profit',
  text83: 'Annulé',
  text84: 'Compte',
  text85: 'Frais',
  text86: 'Vous pouvez retirer des fonds de votre compte sur la plateforme vers votre carte bancaire ou votre portefeuille électronique utilisé pour le dépôt. Vous pouvez demander un retrait à tout moment. Votre demande de retrait sera traitée dans les 3 jours ouvrables.',
  text87: 'Déposer',
  text88: 'Certaines demandes précédentes',
  text89: 'Historique des retraits',
  text90: 'Mes demandes',
  text91: 'Faire une demande',
  text92: 'Questions fréquemment posées',
  text93: `Vous n'avez pas encore fait de demande`,
  text94: 'Si vous avez des questions, vous pouvez trouver des réponses dans les questions fréquemment posées.',
  text95: 'Copyright © 2024 Quotex. Tous droits réservés',
  text96: 'Connectez-vous à',
  text97: 'Mot de passe',
  text98: 'Se souvenir de moi',
  text99: `Retour à l'information`,
  text100: 'Terminé',

  text104: `« Plateforme d'innovation pour l'investissement intelligent »`,
  text105: 'Inscrivez-vous et obtenez 10 000 $ sur un compte démo où vous apprendrez à trader',
  text106: `S'inscrire`,
  text107: 'Ouvrez un compte démo',
  text108: 'Interface de trading pratique',
  text109: `Nous avons créé l'interface la plus simple et la plus pratique pour que vous ne vous éloigniez pas de ce qui est le plus important : le trading. `,
  text110: 'Signal intégré',
  text111: `« Envisagez une stratégie de trading, nous fournissons les signaux les plus précis et innovants jusqu'à 87 % pour vous aider à développer votre propre stratégie efficace. `,
  text112: 'Indicateur de trading',
  text113: `« Nous avons collecté les indicateurs de trading les plus avantageux. Utilisez cet indicateur pour augmenter le solde de votre compte. `,
  text114: 'Vitesse parfaite',
  text115: `« Notre plate-forme utilise la technologie la plus innovante et offre une vitesse incroyable. `,
  text116: 'Essayez-le avec un compte démo',
  text117: `Afin de gagner plus d'argent`,
  text118: 'Faites un pronostic correct',
  text119: `« Le prix est-il haussier ou baissier ? `,
  text120: 'Ouvrez un compte démo',
  text121: 'Cliquez',
  text122: 'Sur un des boutons et voir les résultats',
  text123: 'Le prix va augmenter',
  text124: 'Le prix va baisser',
  text125: 'En attente des résultats...',
  text126: `« Votre bénéfice »`,
  text127: 'Désolé, votre prédiction est incorrecte',
  text128: `« Réessayez »`,
  text129: `« Votre bénéfice »`,
  text130: 'Félicitations ! Votre prédiction est correcte',
  text131: `« Commencez à gagner de l’argent »`,
  text132: `L'application mobile est toujours disponible`,
  text133: `« Téléchargez notre application conviviale pour iPhone ou Android et commencez à trader ! `,
  text134: `« Heure disponible »`,
  text135: `« Commencer à trader »`,
  text136: `« 3 étapes »`,
  text137: 'Ouvrez un vrai compte gratuitement en quelques minutes',
  text138: 'Tradez sur le compte démo en un seul clic',
  texte139: 'Pratique',
  text140: 'Améliorez vos compétences avec des comptes de démonstration et du matériel de formation',
  text141: 'Commencez votre enseignement avec un compte démo',
  text142: 'Dépôt sur votre compte réel et commencez à trader',
  text143: `« Plus de 410 instruments, le montant minimum de la transaction est de 10 $ »`,
  text144: 'Méthode de dépôt sauté',
  texte145: 'FAQ',
  text146: `« Comment le maîtriser ? `,
  text147: 'Enregistrez un compte démo et entraînez-vous à utiliser ce compte. Un compte démo est identique à un compte réel, mais aucun dépôt n’est requis. ',
  text148: `« Combien de temps faut-il pour traiter un retrait ? `,
  text149: `« En moyenne, le processus de retrait prend entre 1 et 5 jours à compter de la date de réception de la demande de l'utilisateur correspondante, et dépend uniquement du nombre de demandes traitées simultanément. La Société s'efforce toujours de régler la demande de l'Utilisateur directement à la date de sa réception. `,
  text150: `Qu'est-ce que la plateforme de trading ? quel est l'effet ? `,
  text151: `« La plateforme de trading est un système logiciel. Les utilisateurs utilisent les instruments financiers fournis par ce système pour effectuer des transactions (opérations), et utilisent également la valeur de cotation, la situation du marché en temps réel, les actions de l'entreprise et d'autres informations fournies par l'entreprise. `,
  text152: `« Puis-je utiliser mon téléphone portable pour effectuer des transactions ? » `,
  text153: `Notre plateforme utilise la technologie la plus innovante et peut être ouverte dans le navigateur de n'importe quel ordinateur ou smartphone`,
  text154: 'Quel est le montant minimum du dépôt ? ',
  text155: `« L'avantage de la plateforme de trading de cette société est que vous n'avez pas besoin de déposer de grosses sommes d'argent sur votre compte. Vous pouvez commencer à trader en investissant une petite somme d’argent. Le dépôt minimum est de 10 $. `,
  text156: `« Y a-t-il une commission lors du dépôt ou du retrait d'argent du compte ? `,
  texte157: 'Non. La société ne facture aucune commission pour les opérations telles que le dépôt de comptes ou le retrait de fonds. ',
  text158: `« Il convient toutefois de noter que les systèmes de paiement peuvent facturer des commissions et utiliser des taux de change différentiels. `,
  text159: `« Aller à toutes les questions »`,
  text160: `Quotex : Plateforme d'innovation`,
  text161: `« Négoce d'actifs numériques »`,
  text162: 'Ouvrir un compte réel',
  text163: 'Partenaire',
  text164: 'Compte démo',
  text165: 'Coordonnées',
  text166: 'Question importante',
  texte167: `« Questions financières »`,
  text168: 'vérification',
  texte169: `« Règlements »`,
  text170: 'Politique de confidentialité',
  text171: `« Conditions de paiement »`,
  text172: 'Contrat de service',
  text173: `« Avertissement de risque »`,
  text174: 'Règles des opérations commerciales',
  text175: 'Règles de fonctionnement non commerciales',
  text176: `Avertissement sur les risques : le trading sur des plateformes de négociation d'actifs numériques et l'utilisation d'instruments de financement de prêt peuvent comporter des risques importants et entraîner une perte totale de votre capital d'investissement. N’investissez pas plus que ce que vous pouvez vous permettre et vous devez comprendre les risques encourus. Le trading utilisant des instruments de financement empruntés ne convient pas à la plupart des investisseurs. Trader sans utiliser de fonds empruntés tels que des actions est également risqué, car les cours des actions peuvent à la fois diminuer et augmenter, ce qui pourrait signifier que même si vous gagnez, vous récupérerez beaucoup moins que ce que vous avez investi.
   Le succès passé ne garantit pas le succès futur. Avant de trader, veuillez prêter attention à votre expérience et niveau de trading, à vos objectifs d'investissement et contacter un conseiller financier indépendant si nécessaire. Les utilisateurs doivent déterminer si l'utilisation des services de marque Quotex est autorisée en fonction des lois et réglementations de leur pays de résidence. `,
};
