<template>
  <div class="input-control-wrapper section-deal--black" style="margin-bottom: 20px;">
    <div class="input-control input-control--number input-control--dark input-control--text-left">
      <span class="input-control__label">{{ mainlabel }}</span>
      <div class="input-control__input">
        <button id="dropdownButton" class="dropdown-button" @click="toggleDropdown">{{ showValue }}</button>
        <ul class="dropdown-list" :class="{ 'open': isOpen }">
          <li class="dropdown-item" :class="{ 'active': item === showValue }" :value="item" v-for="item in option"
            :key="item" @click="activeItem(item)">{{ item }}</li>
        </ul>
      </div>

      <span v-if="sublabel != ''" class="input-control__label__switch">{{ sublabel }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'indicator-value-select',
  data () {
    return {
      value: null,
      isOpen: false,
    }
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    mainlabel: {
      type: String,
      default: ''
    },
    sublabel: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 1
    },
    default: {
      type: String,
      default: ""
    },
    interval: {
      type: Number,
      default: 1
    },
    suffix: {
      type: String,
      default: ''
    },
    option: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    showValue () {
      if (this.value == null) {
        this.value = this.default

      }
      return this.value
    }
  },
  methods: {
    activeItem (value) {
      this.value = value
      this.$emit('inputValueChange', { index: this.index, value: this.value })
      this.isOpen = false // 选择后关闭下拉框
    },
    toggleDropdown () {
      this.isOpen = !this.isOpen // 切换下拉框状态
    }
  }
}
</script>

<style>
.input-control__input {
  position: relative;
}

/* Dropdown button */
.dropdown-button {
  width: 100%;
  padding: 3px;
  background-color: #1c1f2d;
  color: #fff;
  border: 1px solid #1c1f2d;
  cursor: pointer;
  text-align: left;
  position: relative;
  font-size: 14px;
}

.dropdown-button::after {
  content: '\25BC';
  /* Down arrow symbol */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

/* .dropdown-list {
  position: absolute;
  z-index: 888;
  background-color: #494f65;
  width: 100%;
  list-style: none;
  padding-left: 0;
  padding: 11px;
  box-sizing: content-box;
  left: -11px;
  border-radius: 4px;
} */
.dropdown-list {
  position: absolute;
  z-index: 888;
  background-color: #494f65;
  width: 100%;
  list-style: none;
  padding-left: 0;
  padding: 0 11px;
  box-sizing: content-box;
  left: -11px;
  border-radius: 4px;

  /* Animation properties */
  max-height: 0;
  /* Initial height: hidden */
  opacity: 0;
  /* Fully transparent */
  overflow: hidden;
  /* Hide overflowing content */
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

/* Dropdown list - active state */
.dropdown-list.open {
  max-height: 300px;
  /* Adjust based on content height */
  opacity: 1;
  /* Fully visible */
  padding: 11px;
  /* Add padding back */
}


.dropdown-list .dropdown-item {
  padding: 10px;
  color: #ccc;
  cursor: pointer;
  font-size: 14px;
  /* border-bottom: 1px solid #aaa; */
}

.dropdown-list .dropdown-item:hover {
  /* background-color: #3a3a4f; */
  color: #187cdb;
}

.dropdown-list .dropdown-item.active {
  color: #4c9aff;
  /* Blue active color */
}

.dropdown-list .dropdown-item.disabled {
  color: #666;
  cursor: not-allowed;
}

.dropdown-list .dropdown-item.disabled:hover {
  background-color: transparent;
}
</style>