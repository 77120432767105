<template>
  <div class="form__control">
    <label class="input-control-cabinet input-control-cabinet__tooltip-focus input-control-cabinet--text" :class="[show ? 'active' : '', Class]">
      <span class="input-control-cabinet__label">{{ label }}</span>
      <input @focus="focusHandle" @input="inputHandle" @blur="blurHandle" :value="value" type="date" class="input-control-cabinet__input" :placeholder="placeholder" />
      <div class="input-control-cabinet__tooltip"><div class="input-control-cabinet__tooltip-text">It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.</div></div>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    Class: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array | String | Number,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    focusHandle() {
      this.show = true;
    },
    blurHandle() {
      this.show = false;
    },
    inputHandle(e) {
      this.$emit('update:value', e.target.value);
    },
  },
};
</script>
