<template>
  <div class="modal active center">
    <div class="modal__container modal-rating">
      <div class="modal__dialog">
        <button class="modal__close" @click="$emit('close')">
          <svg class="icon-close"><use xlink:href="../assets/svg/spritemap.svg#icon-close"></use></svg>
        </button>
        <div class="modal__body">
          <div class="modal-rating__header" dir="auto">
            <svg class="icon-modal-rating"><use xlink:href="../assets/svg/spritemap.svg#icon-modal-rating"></use></svg>
            <div>
              <div class="modal-rating__header-subtitle">{{ $t('Leader Board') }}</div>
              <div class="modal-rating__header-title">{{ $t('How does it work?') }}</div>
            </div>
          </div>
          <div class="modal-rating__body" dir="auto">
            <span class="modal-rating__body-title">{{ $t('All traders on our platform participate in this rating. Main features of the rating:') }}</span>
            <div class="modal-rating__body-list">{{ $t('Calculated every day, from 00:00 UTC to 23:59 UTC') }}</div>
            <div class="modal-rating__body-list">{{$t('Participants can only be those who trade in live mode')}}</div>
            <div class="modal-rating__body-list">{{ $t('Your position depends on the amount of earned money') }}</div>
          </div>
          <div>
            <div class="modal-rating__footer-title" dir="auto">{{$t('My rating in the Leader Board')}}</div>
            <div class="modal-rating__footer-loading"><img class="modal-rating__footer-expand" src="/trade-room/react/images/da6df85959d15de5ee46035b5d0b918a.png" alt="Modal image" style="width: 0%" /></div>
            <div class="modal-rating__footer-block">
              <div class="modal-rating__footer-user">
                <svg class="modal-rating__footer-flag flag-jp"><use xlink:href="../assets/svg/flags.svg#flag-jp"></use></svg>
                <div>40590966</div>
              </div>
              <div class="modal-rating__footer-money">
                <div class="modal-rating__footer-money__name">{{ $t('Your position:') }}</div>
                <div class="modal-rating__footer-cash">&nbsp;45369</div>
                <div class="modal-rating__footer-cash">$0.00</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}

</script>
<style>
.modal-rating__body-list{
    display: flex;
    align-items: center;
}

</style>