export default {
    "+{value}% profit": "+{value}% profit",
    "Selfie verification required": "Kailangan ang beripikayon ng selfie",
    "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "Upang maisagawa ang kinakailangang hakbang sa beripikasyon ng account, hinihiling namin na magbigay ng isang  mataas na resolution na  litrato ng iyong sarili at iyong pirma sa isang piraso ng papel. Ang iyong mukha, katawan, at parehong kamay ay dapat na nakikita. <br><br> Ang mga detalye ng dokumento ay dapat malinaw at nababasa.",
    "Your documents have been checked, now your identity is verified.": "Ang iyong mga dokumento ay sinuri, ngayon ang iyong identidad ay beripikado",
    "You need fill identity information before verification your profile.": "Kailangan mo na sagutan ang impormasyong sa pagkakakilanlan bago beripikahin ang iyong profile",
    "Documents verification": "Beripikasyon ng mga dokumento",
    "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "Ang beripikasyon ng profile ay ang pagbibigay ng opisyal na dokumento na nagpapatunay sa pagkakakilanlan ng Kliyente. Ang pamamaraan na ito ay maaaring gawin ng departamento ng seguridad ng Kompanya anumang oras. ",
    "Upload Photo": "I-upload ang larawan",
    "Nickname": "Palayaw",
    "First Name": "Pangalan",
    "Last Name": "Apelyido",
    "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Ang pangalan ay dapat na tinukoy nang eksakto tulad ng sa dokumento. Para sa pagbibigay ng maling impormasyon, maaaring mabura ang account nang walang babala.",
    "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "Ang apelyido ay dapat na tinukoy nang eksakto tulad ng sa dokumento. Para sa pagbibigay ng maling impormasyon, maaaring mabura ang account nang walang babala.",
    "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "Hindi posible na palitan pa ang iyong petsa ng kapanganak. Kung nailagay mo ito nang mali, hindi ka maaaring makapag-withdraw ng pera mula sa iyong account.",
    "Empty": "Walang Laman",
    "Date of birth": "Petsa ng Kapanganakan",
    "Unverified": "Hindi beripikado",
    "Verified": "Beripikado",
    "Resend": "Ipadala Muli",
    "Country": "Bansa",
    "Address": "Adres",
    "Change Identity Info": "Palitan ang Impormasyon ng Pagkakakilanlan",
    "Identity Info": "Impormasyon ng Pagkakakilanlan",
    "Two-factor authentication on login": "Dalawang hakbang na beripikasyon sa pag-login",
    "Two-factor authentication on withdrawal": "Dalawang hakbang na beripikasyon sa pag-withdraw",
    "Active": "Aktibo",
    "Inactive": "Hindi aktibo",
    "Setup": "Setup",
    "Two-step verification": "Dalawang hakbang na beripikasyon",
    "Security": "Seguridad",
    "Google Authenticator": "Google Authenticator",
    "Scan the QR code": "I-scan ang code ng QR",
    "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "Sineseryoso namin ang iyong serguridad, kung kaya kinakailangan namin ang 2 hakbang na beripikasyon. Heto ang paraan para maayos ito:",
    "Install ": "I-install",
    "Can't scan?": "Hindi mai-scan?",
    "Click here.": "Pindutin rito",
    "Enter this code into Google Authenticator.": "Ilagay ang code sa Google Authenticator",
    "Back to QR code.": "Bumalik sa code ng QR",
    "Enter the 6-digit code from Authenticator below": "Ilagay sa ibaba ang 6 na numerong code mula sa Authenticator",
    "Enter 6-digit code...": "Ilagay ang 6 na numerong code",
    "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "Magaling. Matagumpay mong maayong ang 2 hakbang na beripikasyon. Sa susunod na ikaw ay mag-sign in, ikaw ay tatanungin para sa isang code.",
    "Code": "Code",
    "contact support": "makipag-ugnayan sa suporta",
    "If you ever lose access to your 2-step verification device, please ": "Kung ikaw ay mawalan ng akses sa iyong device para sa 2 hakbang na beripikasyon, mangyaring",
    "Yes, activate": "Oo, paganahin",
    "Cancel": "Ikansela",
    "Close": "Isara",
    "Language": "Wika",
    "Timezone": "Timezone",
    "Delete Account": "Burahin ang Account",
    "Verification Credit / Debit Cards": "Beripikasyon ng Credit/Debit Card",
    "Add New Card": "Magdagadag ng Bagong Card",
    "Wait for loading...": "Maghintay sa pag-load",
    "You don’t have any credit / debit cards for verification": "Ikaw ay walang credit/debit card para sa beripikasyon. ",
    "Verificate": "Beripikasyon",
    "Verification Card": "Card sa Beripikasyon",
    "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "Mangyaring ilagay ang iyong ID (nagpapakita ng litrato, pangalan, at lugar ng kapanganakan) kasama ng harap na bahagi ng iyong card sa bangko (nagpapakita ng numer, petsa ng pagtatapos, at iyong pangalan) sa isang blangkong A4 na papel <br>. Mangyaring isulat ang  “Quotex.io” at idagdag ang iyong pirma irot. <br> Kumuha ng litrato at i-upload ito. Take a photo and upload it.",
    "Card:": "Card: ",
    "Type:": "Tipo:",
    "Your ID and bank card on an A4 sheet": "Ang itong ID at card sa bangko sa isang A4 na papel ",
    "Yes, confirm": "Oo, kinukumpirma ko",
    "Click or drag the image": "Pindutin o i-drag ang larawan",
    "Card": "Card",
    "Documents": "Mga Dokumento",
    "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "Una,  sagutan ang bahaging \"Impormasyon sa Pagkakakilanlan\" sa iyong Profile, at pindutin ang buton na \"Baguhin ang Impormasyon sa Pagkakakilanlan\" upang i-save ang mga pagbabago. Pagkatapos nito ikaw ay maaari nang magdagdag ng bagong card.",
    "Specify the card details for further operations for depositing and debiting funds.": "Ilagay ang detalye ng card para sa patuloy na operasyon ng pagdeposito at pag-debit ng pondo. ",
    "Card number:": "Numero ng card:",
    "Expiry month:": "Buwan ng pagtatapos:",
    "Expiry year:": "Taon ng pagtatapos:",
    "Cardholder name:": "Pangalan ng may-ari ng card:",
    "Next": "Kasunod ",
    "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "Mangyaring ilagay ang iyong pirma at ang kasalukuyang petsa sa isang papel, ilagay ang dokumento kasama ang iyong card sa bangko, kumuha ng litrato at i-upload ito sa form sa ibaba.",
    "Do you have a two-sided document?": "Tinatanggap ba ang dokumento na magkabilaan ang panig?",
    "Upload the back side as a separate file, also on a sheet with the date and your signature.": "I-upload ang likurang bahagi bilang hiwalay na file, nasa isang papel rin na may petsa at iyong pirma.",
    "Submit only valid documents": "Magpasa lamang ng mga balidong dokumento",
    "Do not edit or crop photo": "Huwag i-edit o putulin ang litrato. ",
    "Do not upload other people's documents": "Huwag mag-upload ng dokumento ng ibang tao",
    "Read all": "Basahin lahat",
    "Uploading files": "Ina-upload ang mga file",
    "For single-sided documents, upload only the front side.": "Para sa mga dokument na may impormasyon lang sa isang panig, i-upload lamang ang harapan.",
    "Back": "Likod",
    "Add a card": "Magdagdag ng isang card",
    "File Requirements": "Mga Kinakailangang File",
    "Scanned images are not accepted for card verification": "Ang mga naka-iscan na litrato ay hindi tinatanggap para sa beripikasyon ng card",
    "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "Tinatanggap lang namin ang mga file ng iyong regular na passport, o Pambansang ID (ang bawat bahagi ay dapat na i-upload bilang hiwalay na mga file)",
    "Driving license CANNOT be accepted": "HINDI TINATANGGAP ang lisensiya sa pagmamaneho",
    "External (foreign/international/travel) passport will not be accepted as well": "Ang panlabas (dayuhan/internasyonal/paglalakbay) na passport ay hindi rin tinatanggap",
    "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "Kung ang dokumento ay may dalawang panig, kailangan mong i-upload ang kabila nito bilang hiwalay na file, sa parehong piraso ng papel, na may kasalukuyang petsa at iyong pirma. ",
    "All information in the document and on the card should be clear and readable on the photo": "Lahat ng impormasyon sa dokumento at sa card at dapat na malinaw at nababasa sa litrato.",
    "Both your document and your card must fit into the photo completely the edges should not be cropped": "Kapwa iyong dokumento at card ay dapat na lubos na magkasya sa litrato at ang mga kanto ay hindi putol",
    "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "Dapat ay walang mga liwanag o iba pag mga depekto sa litrato na magdudulot ng kahirapan na mabasa ang mga datos sa dokumento at card",
    "Expired documents and cards will be rejected automatically": "Ang mga tapos nang mga dokumento at card ay awtomatikong tatanggihan",
    "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "Kung walang pangalan sa iyong card, kailangan mong mag-upload ng screenshot ng statement ng bangko, ipinapakita ang numero ng iyong card at iyong pangala. Sa ilang mga kaso, pinahihintulutan na isulat ang iyong pangalan sa parehong piraso ng papel. ",
    "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "Hind pinahihtulutan na itago ang mga bahagi ng mga dokumento na nagtataglay ng datos na kailangan paa sa beripikasyon (pangalan, petsa ng kapanganakan, litrato)",
    "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "Pinahihintulutan na itago ang 8 digit sa numero ng card. Ang unang apat at huling apat na digit ay dapat malinaw na nakikita.",
    "CVV code should be hidden at all times, in every case": "Ang CVV code ay dapat na nakatago sa lahat ng pagkakataon, sa bawat kaso",
    "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "Ang anumang pag-edit ng iyong mga file ay ipinagbabawal. Tanging mga hindi in-edit na litrato at ini-scan na mga litrato ang maaaring tanggapin. Ang pag-edit ng iyong mga personal na impormasyon sa iyong mga dokumento (pangalan, petsa ng kapanganakan, etc) ay ipinagbabawal",
    "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "Ang litrato ay dapat na bahagi ng dokumento (nakadikit o naka-print). Ang mga litrao na hiwalay na nakakabit ay hindi tatanggapin",
    "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "Pinahihintulutan na i-upload ang mga dokumento at card sa pangalan na nakatukoy sa profile (kung saan ang iyong account ay nakarehistro)",
    "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "Ipinapaalala namin sa iyo na ang pagpasa ng peke/maling impormasyon ang account ay maaaring suspendihin/i-block, hanggang ang sitwasyon ay maging malinaw",
    "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "Ang Kompanya ay may karapatan na humiling sa iyo na magbigay ng anumang karagdagang dokumento para sa beripikasyon ng account (kasama ang iyong selfie na may dokumento) anumang oras",
    "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "Para sa mga tanong na may kaugnayan sa beripikasyon ng mga dokumento, maaari kang gumawa ng support ticket sa platform (ang bahaging \"Tulong\" ay ang unang buton na may kumikislap na tandang pananong, sa pinakakaliwang bahagi)",
    "Profile": "Profile",
    "Deposit": "Deposito",
    "Withdrawal": "Withdrawal",
    "Transactions": "Mga Transaksyon",
    "Trades": "Mga Palitan",
    "Market": "Merkado",
    "Analytics": "Analytics",
    "My current currency": "Aking kasalukuyang salapi",
    "Available for withdrawal": "Maaaring I-withdraw",
    "In the account": "Nasa Account",
    "Percentage of turnover {percent}%": "Porsyento ng turnover {percent}%",
    "Cashback {percent}%": "Cashback {percent}%",
    "Currency:": "Salapi:",
    "Change": "Pagbabago",
    "Live Account": "Live na Account",
    "Daily budget limit": "Arawang limit ng badyet",
    "The value cannot exceed your total budget": "Ang halaga ay hindi maaaring humigit sa iyong kabuuang badyet",
    "Demo Account": "Demo Account",
    "Apply": "Mag-aplay",
    "Edit": "I-edit",
    "New value": "Bagong halaga",
    "Confirm": "Kumpirmahin",
    "Logout": "Mag-Logout",
    "No, cancel": "Hindi, ikansela",
    "Exchange Form": "Form ng Palitan",
    "My Currency:": "Aking salapi:",
    "New Currency:": "Bagong Salapi",
    "You are exchanging": "Ikaw ay nakikipagpalit",
    "You will receive": "Ikaw ay makakatanggap",
    "Exchange Fee": "Singil sa palitan",
    "Password changed": "Napalitan ang password",
    "Old password": "Lumang Password",
    "New password": "Bagong Password",
    "Confirm new password": "Kumpirmahin ang bagong password",
    "Change Password": "Palitan ang password",
    "Confirm password must be equal new password value": "Kumpirmahin na ang password ay dapat na katumbas sa bagong password",
    "About us": "Tungkol sa amin",
    "Support": "Suporta ",
    "Help": "Tulong",
    "2-step verification": "2 hakbang na beripikasyon",
    "Please enter the PIN-code we've just sent to your email": "Mangyaring ilagay and CODE ng PIN na aming ipinadala sa iyong email",
    "Please enter the 6-digit code from your authentication app": "Mangyaring ilagay ang 6 na numerong code mula sa iyong authenticator na app",
    "The First Name field is required": "Kailangan ang Pangalan",
    "The Last Name field is required": "Kailangan ang Apelyido",
    "Verification of documents": "Beripikasyon ng mga dokumento",
    "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "Mangyaring mag-upload ng may kulay na larawan o naka-scan na imahen ng iyong pasaporte, lisensiya sa pagmamaneho, o National ID.",
    "Upload Documents": "I-upload ang mga dokumento",
    "Why?": "Bakit?",
    "Do you want to confirm deleting your account without undo? This action cannot be undone.": "Gusto mo bang kumpirmahin ang pagbura ng iyong account nang walang pagbawi? Ang aksyon na ito ay hindi maaaring bawin. ",
    "Set up 2-step verification": "Ayusin ang dalawang hakbang na beripikasyon",
    "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "Sa kasamaang palad, hindi ka maaaring mag-upload ng mga ile sa bahaging ito. <br> Mangyaring sumangguni sa mga mensahe mula sa Suporta at ikabit ang mga hiniling na file sa isang ticket.",
    "The uploaded document does not match the requirements": "Ang na-upload na dokumento ay hindi tugma sa mga pangangailangan",
    "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "Ang mga sumusunod na dokumento ay tinatanggap para sa pagkakakilanlan: ID, Passport, Internasyonal na Passport o lisensiya sa pagmamaneho",
    "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "Ang mga litrato ay dapat mataas ang kalidad, ang datos ay madaling basahin, at ang mga kanto ng dokumento ay hindi putol.",
    "Balance from {amount}": "Balanse mula {amount}",
    "Not verified": "Hindi beripikado",
    "Clear": "Burahin",
    "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "Upang maiwasan ang paghihigpit sa serbisyo, mangyaring kompletuhin ang proseso ng beripikasyon: sagutan ang iyong personal na datos at i-upload ang mga kinakailangang dokumento.",
    "Get verified": "Maging beripikado",
    "I got it!": "Nakuha ko na!",
    "Passport.IN": "Aadhaar",
    "Passport.BR": "CPF",
    "AADHAAR Verification": "Beripikasyon sa AADHAAR",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "Mangyaring magupload ng isang may-kulay na larawan ng iyong pisikal na Aadhaar (lamang ang harap na bahagi na may iyong larawan at numero ng dokumento).",
    "Send AADHAAR": "Ipadala ang AADHAAR",
    "AADHAAR number:": "Numero sa AADHAAR:",
    "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "Mangyaring mag upload ng isang kulay na larawan ng iyong pisikal na Aadhaar (lamang ang harap na bahagi na may iyong larawan at numero ng dokumento). ☝ Walang electronic na bersyon ang tinatanggap.",
    "You have already uploaded {number} files. Please wait until they are checked.": "Ikaw ay nakapag-upload na ng {number} file. Mangyaring maghintay hanggang lahat ng ito ay masuri.",
    "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "Mag-upload lamang ng kulay na larawan ng iyong dokumento: Para sa RG - kailangang i-upload ang magkabilang panig bilang hiwalay na file. Para sa CNH - lamang ang harap na bahagi sa iyong larawan. Ang dokumento ay dapat na may bisa (mas mababa sa 10 taon mula sa petsa ng isyu), at dapat na ipinapakita ang iyong numero ng CPF. * sakaling walang CPF sa iyong dokumento - mangyaring mag-upload ng hiwalay na larawan ng Comprovante de Inscrição no CPF, o ng screenshot ng iyong bank statement na nagpapakita ng iyong pangalan at numero ng CPF. ** Huwag mag-upload ng anumang elektronikong bersyon. Hindi tatanggapin ang mga ito.",
    "Submit for verification": "Ipasa para sa beripikasyon",
    "Settings": "Mga Setting",
    "Please upload a color photograph of the front of your national identity card with your photo.": "Mangyaring mag-upload ng may-kulay na litrato sa harap ng iyong pambansang identity card kasama ang iyong litrato.",
    "Template": "Padron",
    "Light Mode": "Light Mode",
    "Twilight": "Dapithapon ",
    "Full Night": "Buong Gabi",
    "Grid's opacity": "Kalabuan ng grid",
    "Auto-scrolling": "Awtomatikong pag-scroll",
    "Automatic graphic scrolling": "Awtomatikong pag-scroll ng graphic",
    "1-click trade": "1 pindot na palitan",
    "Open trades without confirmation": "Bukas na Palitan na walang kumpirmasyon",
    "Use timezone": "Gamitin ang Timezon",
    "Use your timezone data": "Gamitin ang iyong datos sa timezone",
    "Graph colors": "Kulay ng Graph",
    "Up Trend": "Pataas na Trend",
    "Down Trend": "Pababang Trend",
    "Platform": "Platform",
    "Short order label": "Label na short order",
    "Use short order element mode": "Gamitin ang mode na short order element",
    "Perfomance Mode": "Perfomance Mode",
    "Use optimized rendering for chart and candles": "Gumamit ng pinakamabuting pagpapakita ng tsart at kandila",
    "Background": "Background",
    "Choose file": "Piliin ang file",
    "Max size — 2 Mb": "Pinakamalaking file - 2MB",
    "Sign up": "Mag-sign up",
    "Sign in": "Mag-sign in",
    "Budget and limits": "Budget at mga limitasyon",
    "Daily limit": "Pang-araw-araw na limitasyon",
    "Not set": "Hindi nakatakda",
    "Total funds": "Kabuuang pondo",
    "Top up": "Mag-top up",
    "Set up": "Set Up",
    "Set a daily limit on trading to reduce financial risks.": "Magtakda ng isang araw araw na limitasyon sa pakikipagpalitan upang mabawasan ang mga panganib sa pananalapi.",
    "Limit amount": "Limitahan ang halaga",
    "More": "Higit pa ",
    "Leader Board": "Listahan ng mga Nangunguna",
    "Signals": "Mga Senyales",
    "Notifications": "Mga notipikasyon",
    "mark all as read": "markahan ang lahat na nabasa",
    "No notifications": "Walang mga notipikasyon",
    "No more notifications": "Wala nang mga notipikasyon",
    "Your position:": "Iyong posisyon:",
    "How does this rating work?": "Paano gumagana ang pagmamarka na ito?",
    "of the Day": "ng araw na ito",
    "Nothing has been found for current query, try another asset name.": "Walang nahanap sa iyong kasalukuyang tanong, sumubok ng ibang pangalan ng pag-aari",
    "My rating in the Leader Board": "Ang aking marka sa listahan ng nangunguna",
    "Participants can only be those who trade in live mode": "Ang mga kalahok ay iyong maaari lang makipagpalitan sa live mode",
    "Your position depends on the amount of earned money": "Ang iyong posisyon ay depende sa halaga ng naipong pera",
    "Calculated every day, from 00:00 UTC to 23:59 UTC": "Ito ay kinakalkula bawat araw, mula 00:00 UTC hanggang 23:59 UTC",
    "All traders on our platform participate in this rating. Main features of the rating:": "Ang lahat ng mga nakikipagpalita sa aming platform ay makikilahok sa pagmamarka na ito. Ang pangunahing tampok ng mga marka: ",
    "How does it work?": "Paano ito gumagana?",
    "Trading signals": "Senyales sa pakikipagpalitan",
    "Past signals": "Mga nakaraang senyales",
    "What’s it?": "Ano ito?",
    "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "Ang senyales sa pakikipagpalitan ay isang hindi direkta na utos sa pakikipagpalitan, kundi isang mungkahi ng isang nagsusuri. Hindi dapat nito palita ang isang malayang analisis ng merkado. ",
    "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "Ikaw ay may hindi bababa sa 10 minuto para mapakinabangan ang senyales sa pakikipagpalitan. Pagkatapos ng oras na ito, ang mga quote ay maaaring magbago at magkaroon ng hindi inaasahang epekto sa resulta ng palitan.",
    "Continue": "Magpatuloy",
    "Duration": "Tagal",
    "Return back to FAQ": "Bumalik sa Mga Madalas na Katanungan",
    "Return back to Help": "Bumalik sa Tulong",
    "How to withdraw money from the account?": "Paano mag-withdraw ng pera mula sa account?",
    "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "Ang paraan sa pag-withdraw ng kapital ay napakasimple at gagawin sa pamamagitan ng iyong indibidwal na account. ",
    "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "Ang paraan na iyong pinili na magdeposito ng account ay ang paraan din sa pag-withdraw ng pera (tingnan ang tanong na \"Paano ako Magdedeposito?).",
    "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "Halimbawa, kung ikaw ay gumawa ng deposito sa iyong account sa pagbabayad sa Visa, ikaw rin ay makakatanggap ng pera sa pamamagitan ng Visa.",
    "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "Kapag magwi-withdraw ng napakalaking halaga, ang Kompanya ay maaaring humiling ng beripikasyon (ang beripikasyon ay hinihiling ayon sa desisyon ng Kompanya), kaya mahalaga na magrehistro ng isang indibidwal na account para sa iyong sarili para makompirma ang iyong karapatan anumang oras. ",
    "What is the minimum withdrawal amount?": "Ano ang pinakamababang halaga sa pag-withdraw?",
    "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "Ang pinakamababang halaga ng withdrawal ay nagsisimula sa 10 USD para sa karamihan ng mga sistema ng pagbayad.<br>Para sa mga cryptocurrency ang halagang ito ay nagsisimula sa 50 USD (at maaaring mas mataas para sa ilang mga salapii hal. Bitcoin).",
    "Do I need to provide any documents to make a withdrawal?": "Kailangan ko ba na magbigay ng dokumento para maka-withdraw?",
    "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "Kadalasan ay hindi kailangan ng karagdagang pondo para makapag-withdraw ng pera. Pero ang Kompanya ay maaaring humiling na kumpirmahin ang iyong personal na datos sa paghiling ng ilang mga dokument. Kadalasan ito ay ginagawa para mapigilan ang mga aktibidad na may kaugnayan sa ilegal na pakikipagpalitan, pamemeke, at paggamig ng pera na ilegal na nakuha. ",
    "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "Kaunti lamang ang listahan ng mga dokumento, at ang oras para maibigay ito ay hindi kakailanganin ng maraming oras at pagod. ",
    "How long does it take to withdraw funds?": "Gaano katagal para makapag-withdraw ng pera?",
    "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "Sa karaniwan, ang paraan ng pag-withdraw ay nagtatagal ng isa hanggang limang araw mula sa petsa na matanggap ang hiling ng Kliyente at depende sa dami ng mga pinoprosesong mga hiling. Ang kompanya ay palaging sinusubukan na gawin ang pagbabayad sa araw mismo na matanggap ang huling mula sa Kliyente. ",
    "Is there any fee for depositing or withdrawing funds from the account?": "Mayroon bang singil sa pagdeposito o pagwithdraw ng pondo mula sa account?",
    "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "Hindi. Ang kompanya ay hindi naniningil para sa pagsasagawa ng deposito o withdrawal. ",
    "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "Gayumpaman, kailangan ikonsidera na ang mga sistema ng pagbayad ay maaaring maningil at gumamit ng sariling antas ng palitan. ",
    "Can I close my account? How to do it?": "Maaari ko bang isara ang aking account? Paano ko gagawin ito?",
    "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "Maaari mong burahin ang isang account sa iyong indibidwal na account sa pagpindot sa \"Burahin ang Account\" na buton na matatagpuan sa ibaba ng pahina ng profile. ",
    "If I made a mistake during entering data into my individual account, how can I fix this?": "Kung ako ay nakagawa ng pagkakamali sa paglalagay ng datos sa aking indibidwal na account, paano ko ito maaayos?",
    "You have to contact the technical support service on the Company's website and edit the profile.": "Kailangan mo na makipag-ugnayan sa serbisyo sa teknikal na suporta sa website ng Kompanya at i-edit ang iyong profile. ",
    "What data is required to register on the Company website?": "Ano ang datos na kailangan para makapagrehisto sa website ng Kompanya?",
    "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "Para makaipon ng pera sa digital na opsyon, kailangan mo magbukas ng account na nagpapahintulot sa iyo na magsagawa ng palitan. Para magawa ito, kailangan mo na magrehistro sa website ng Kompanya. ",
    "The registration process is simple and does not take much time.": "Ang proseso ng rehistrasyon ay simple at hindi kailangan ng maraming oras. ",
    "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "Kailangan na sagutan ang isang kuwentyonaryo sa iminumungkahing pormularyo. Ikaw ay kailangan na ilagay ang mga sumusunod na impormasyon:",
    "name (in English)": "pangalan (sa Ingles)",
    "email address (indicate the current, work, address)": "email address (ilagay ang kasalukuyang email address sa trabaho)",
    "telephone (with a code, for example, + 44123 ....)": "numero ng telepono (na may code, halimbawa, +44123...)",
    "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "para makapasok sa sistema (para mabawasan ang panganib ng hindi otorisadong akses sa iyong indibidwal na account, inirerekomenda namin na gumawa ka ng isang komplikadong password gamit ang malaki at maliit na letra at numero. Huwag ipakita ang iyong password sa mga third party)",
    "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "Pagkatapos na sagutan ang kuwestyonaryo, ikaw ay aalukin ng iba't ibang paraan para makapagdeposito ng account para makipagpalitan. ",
    "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "Sa aling salapi mabubuksan ang account ng Kliyente? Maaari bang palitan ko ang salapi sa account ng Kliyente?",
    "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "Sa karaniwan, ang isang account sa pakikipagpalitan ay binubuksan sa US dollar. Ngunit para sa iyong kaginhawaan, maaari kang magbukas ilang account sa iba't ibang salapi. ",
    "A list of available currencies can be found on your profile page in your Client's account.": "Ang listahan ng mga salapi ay mahahanap sa pahina ng profile sa account ng Kliyente. ",
    "What are digital options?": "Ano ang mga Digital na Opsyon?",
    "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "Opsyon -  isang deribatibong pinansiyal na pag-aari ayon sa isang nakapailalim na asset (maaaring ito ay stock, pares ng salapi, petrolyo, atbp.).",
    "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "DIGITAL NA OPSYON - isang hindi pamatayang opsyon na ginagamit para kumita sa paggalaw ng presyon tulad ng mga pag-aari sa isang panahon. ",
    "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "Ang isang digital na opsyon, depende sa tuntunin na pinagkasunduan ng kapwa partido sa transaksyon, sa panahon na tinukoy ng kapwa partido, ay mayroong iisang kita (ang diperensiya sa pagitan ng kita sa pakikipagpalitan at ang presyo ng pag-aari) o pagkawala (depende sa halaga ng pag-aari).",
    "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "Dahil ang digital na opsyon ay paunang binili sa iisang presyo, sa laki ng kita, at maging sa pinakamalaking pagkawala, ay kilala kahit bago pa ang pakikipagpalitan. ",
    "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "Ano pang tampok ng mga palitang ito ay ang limitasyon sa oras. Ang anumang opsyon ay may sariling panahon (panahon ng pagtatapos).",
    "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "Gaano man ang antas ng pagbabago sa presyo ng nakapailalim na pag-aari (gaano ito naging mataas o mababa), sa kaso ng pagkapanalo ng opsyon, magkakaroon ng iisang kita. Kung gayon, ang iyong panganib ay limitado lang sa halaga kung saan ang opsyon ay nakuha. ",
    "What is the expiration period of a trade?": "Kailan ang panahon ng pagtatapos ng isang palitan?",
    "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "Ang panahon ng pagtatapos ay ang panahon kung saan ang palitan ay itinuturing na kompleto na (sarado) at ang resulta ay nakabuod na. ",
    "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "Kapag nagtapos ka ng palita ng digital na opsyon, malaya mong matutukoy ang oras ng pagsasagawa ng transaksyon (1 minuto, 2 oras, buwan, atbp.).",
    "What is the gist of digital options trading?": "Ano ang pinakaideya ng pakikipagpalitan ng mga digital na opsyon?",
    "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "Sa katunayan ang digital na opsyon ay ang pinakasimpleng deribatibong pinansiyal na pag-aari. Para makagawa ng pera sa merkado ng digital na opsyon, hindi mo kailangan na hulaan ang halaga ng presyo sa merkado ng pag-aari, na kaya nitong maabot. ",
    "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "Ang prinsipyo ng proseso sa pagkikipagpalit ay pinasimple lamang sa solusyon ng iisang trabaho lamang - ang presyo ng pag-aaro na tataas o bababa sa panahon na ang kontrata ay isasagawa. ",
    "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "Ang aspeto ng opsyon na ito ay hindi nakasalalay sa iyo, kundi sa halaga ng nakailalim na pag-aari na tumaas ng isa o isang daan puntos, mula sa panahon na ginawa ang palitan ay isinara. Mahalaga para sa iyo na tukuyin lang ang direksyon ng paggalaw ng presyo nito.  ",
    "If your prognosis is correct, in any case you get a fixed income.": "Kung ang iyong hula ay tama, ikaw ay makakatanggap ng iisang kita. ",
    "What are the possible results of the placed trades?": "Ano ang posibleng resulta ng mga inilagay na palitan?",
    "There are three possible outcomes in the digital options market:": "May tatlong posibleng resulta sa merkado ng digital na opsyon:",
    "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "sa pagkakataon na ang iyong hulo sa pagtukoy ng direksyon ng paggalaw ng presyo ay tama, ikaw ay kikita. ",
    "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "sa panahon kung saan natapos ang opsyon at ang iyong hulo ay mali, maaari kang magkaroon ng pagkawala ng pera ayon sa laki ng halaga ng pag-aari (hal., sa totoo lang, maaaring mawala lang ang iyong pinuhunan).",
    "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "kung ang resulta ng palitan ay zero (ang halaga ng nakailalom na pag-aari ay hindi nagbago, ang opsyon ay magtatapos sa presyo nang ito ay binili), babalik ang iyong pinuhunan. Kaya ang lebel ng iyong panganib ay palaging limitado sa laki ng halaga ng pag-aari. ",
    "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "Ang iyong trading platform ba ay may demo account para maunawaan ang proseso ng pakikipagpalitan ng mga digital na opsyon nang hindi ginagastos ang iyong sariling pera?",
    "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "Oo. Para mabuo ang iyong kaalaman sa pakikipagpalitan at subukan ang kakayahan ng platform sa pakikipagpalitan ng Kompanya, maaari mong gamitin ang demo account (nang libre). Ito ay isang uri ng simulator ay hinahayaan ka na subukan muna ito, at pagkatapos ay lumipat sa tunay na pakikipagpalitan. Ang demo account na ito ay maaari rin sa mga may karanasan na sa pakikipagpalitan para mapahusay ang kanilang propesyonal na lebel.",
    "The balance of such an account is 10,000 units.": "Ang balanse ng account na ito ay 10,000 na yunit. ",
    "What determines profit size?": "Ano ang tumutukoy sa laki ng kita?",
    "There are several factors that affect the size of your profit:": "May ilang mga sanhi na maaaring makaapekto sa laki ng iyong tubo:",
    "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "ang liquidity ng pag-aari na iyong napili sa merkado (kung mas marami ang kailangan sa merkado, mas malaking tubo ang iyong matatanggap)",
    "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "ang oras ng pakikipagpalitan (ang liquidity ng isang pag-aari sa umaga at ang liquidity ng isang asset sa hapon ay maaaring maging malaki ang pagkakaiba)",
    "tariffs of a brokerage company": "taripa ng kompanya ng brokerage",
    "changes in the market (economic events, changes in part of a financial asset, etc.)": "pagbabago sa merkado (pangyayaring ekonomiko, pagbabago sa mga parte ng isang pinansiyal na pag-aari, atbp.)",
    "What are the varieties of digital options?": "Ano ang mga uri ng mga digital na opsyon?",
    "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "Kapag gumagawa ng palitan ng opsyon, kailangan mo na pumili ng pag-aari kung saan papailalim ang opsyon. Ang iyong pagtataya ay gagawin sa pag-aari na ito. ",
    "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "Sa madaling sabi, sa pagbili ng digital na kontrata, ikaw ay pumupusta sa paggalaw ng presyo ng isang nakapailalim na pag-aari. ",
    "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "Ang isang nakapailalim na \"bagay\" na ang presyo ay tatandaan kapag nagtatapos ng palitan. Bilang nakapailalim na pag-aari ng mga digital na opsyon, ang pinakahinahanap na mga produkto ang madalas na kumikilos. May apat na uri nito: ",
    "securities (shares of world companies)": "mga security (share ng mga kompanya sa mundo)",
    "currency pairs (EUR / USD, GBP / USD, etc.)": "pares ng salita (EUR / USD, GBP / USD, atbp.)",
    "raw materials and precious metals (oil, gold, etc.)": "hilaw na materyales at mga mamahaling bakal (petrolyo, ginto, atbp.)",
    "indices (S&P 500, Dow, dollar index, etc.)": "mga index (S&P 500, Dow, dollar index, atbp.)",
    "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "Ang isang pangkalahatan na nakailalim na pag-aari ay hindi mahahanap. Sa pagpili nito ikaw ay ginagabayan ng iyong kaalaman, kutob at iba pang impormasyon na analitikal, gayundin ang analisis ng merkado para sa isang partikular na pag-aari. ",
    "What is a trading platform and why is it needed?": "Ano ang isang platform sa pakikipagpalitan at bakit ito kailangan?",
    "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "Platform sa pakikipagpalitan - isang complex ng software na nagbibigay daan sa Kliyente na magsagawa ng mga palitan (operasyon) gamit ang iba't ibang mga pinansiyal na pag-aari. Nagbibigay daan din to na makapag-akses ng iba't ibang impormasyon tulad ng halaga ng mga quotation, napapanahong posisyon sa merkado, aksyon ng Kompanya, atbp. ",
    "How to learn quickly how to make money in the digital options market?": "Paano matutunan nang mabilis ang pagkita ng pera sa merkado ng digital na opsyon?",
    "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "bumuo ng iyong sariling istratehiya sa pakikipagpalitan, kung saan ang bilang ng tamang hula sa pakikipagpalitan ay ang pinakamataas, ay sundin ito",
    "diversify your risks": "pag-iba-ibahin ang iyong mga panganib",
    "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "Sa pagbubuo ng istratehiya, at sa paghahanap ng pag-iiba-iba ng mga opsyon, pagbabantay sa merkado, pag-aaral ng analitikal at estadistikal na impormasyon na maaaring makuha mula sa iba't ibang mga batis (sanggunian sa Internet, ekspertong opinyon, mga taga-analisa larangan, etc.) ay makakatulong sa iyo, kung saan isa rito ay ang website ng Kompanya.",
    "Is the download of the program to a computer or smartphone required?": "Kailangan ba ang pag-download ng programa sa isang kompyuter o smartphone?",
    "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "Hindi mo ito kailangan. Dapat lang ay makapagrehsitro ka sa website ng Kompanya ayon sa ipinakitang form at magbukas ng indibidwal na account. ",
    "At what expense does the Company pay profit to the Client in case of successful trade?": "Gaano nagbabayad ang Kompanya ng kita sa Kliyente kung mayroong matagumpay na palitan?",
    "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "Ang Kompanya ay kumikita sa mga kustomer. Kung gayon, ito ay interesado sa bahagi nito sa mga mapagkakakitaang transaksyon kaysa sa mga bahagi sa mga hindi mapagkakakitaan, dahil ang Kompanya ay may porsyento ng mga bayad para sa matagumpay na istratehiya sa pakikipagpalitan na pinili ng Kliyente. ",
    "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "Dagdag pa rito, ang mga palitan na isinagawa ng Kliyente ay bumubuo sa kabuuang dami ng palitan ng Kompanya, na inililipat sa mga broker o exchange, na mapupunta sa kaban ng mga tagapagbigay ng liquidity, na kapag nagsama-sama ay magdudulot ng pagtaas sa liquidity ng merkado. ",
    "What is account verification?": "Ano ang beripikasyon ng account?",
    "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "Ang beripikasyon sa digital na paraan ay isang kompirmasyon ng Kliyente ng kanyang personal na datos sa pagbibigay sa Kompanya ng mga karagdagang dokumento. Ang kondisyon sa beripikasyon para sa Kliyente ay simple lamang hanggat maaari at kaunti lamang ang listahan ng dokument. Halimbawa, ang Kompanya ay maaaring humingi ng:",
    "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "magbigay ng may kulay na kopya ng unang pahina ng pasaporte ng Kliyente (pahina ng pasaporte na may litrato)",
    "identify with the help of a \"selfie\" (photograph of himself)": "makilala gamit ang isang selfie (litrato ng sarili)",
    "confirm the address of registration (residence) of the Client, etc": "kumpirmahan ang adres ng rehistrasyon (tirahan) ng Kliyente, atbp. ",
    "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "Ang Kompanya ay maaaring humiling na anumang dokumento kung hindi posible na makilala ang Kliyente at ang datos ay iniligay ng Kliyente.",
    "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "Pagkatapos na ang dokumento ay ipinasa sa Kompanya, ang Kliyente ay kailangang maghintay ng ilang panahon para maberipika ang datos na ibinigay.",
    "How to understand that I need to go through account verification?": "Paaano ko mauunawaan na kailangan ko na magdaan sa beripikasyon ng account?",
    "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "Kung kinakailangan na makapasa sa beripikasyon, makakatanggap ka ng notipikasyon sa pamamagitan ng e-mail at / o SMS",
    "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "Sa parehong panahon, ang kompanya ay ginagamit ang detalye sa pakikipag-ugnayan na iyong inilagay sa mga kuwestyonaryo noong rehistrasyon (sa partikular, ang email address at numero ng telepono). Kung gayon, maging maingat at ibigay ang tamang impormasyon. ",
    "How do I know that I successfully passed verification?": "Paano ko malalaman na ako ay nakapasa sa beripikasyon?",
    "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "Ikaw ay makakatangap ng notipikasyon sa pamamagitan ng email at / o SMS tungkol sa pagkompleto ng proseso ng beripikasyon sa iyong account at ang kakayahan na magpatuloy sa operasyon sa platform ng palitan ng Kompanya. ",
    "Is it possible to indicate other people's (fake) data when registering on the website?": "Posible ba na maglagay ng datos ng ibang tao (peke) kapag nagpaparehistro sa website?",
    "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "Hindi. Ang kliyente ay magsasagawa ng rehistrasyon sa website ng Kompanya, magbibigay ng kompleto ay eksaktong impormasyon tungkol sa kanyang sarili sa mga tanong sa form ng rehistrasyon, at panatilihin na ang impormasyon na ito ay napapanahon. ",
    "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "uri ng mga tseke ng pagkakakilanlan ng kliyente, ang Kompanya ay maaaring humiling ng mga dokumento o imbitahin ang kliyente sa opisina nito. ",
    "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "Kung ang datos na inilagay sa rehistrasyon ay hindi tugma sa datos ng ipinasang dokumento, ang iyong indibidwal na profile ay maaaring i-block",
    "How long does the verification process take?": "Gaano katagal ang proseso ng beripikasyon?",
    "No more than 5 (five) business days from the date the Company receives the requested documents.": "Hindi hihigit sa 5 (limang) araw ng negosyo mula sa petsa na matanggap ng Kompanya ang mga hiniling na dokumento. ",
    "Is there a minimum amount that I can deposit to my account at registration?": "Mayroon bang pinakamababang halaga na maaari kong ideposito sa aking account sa rehistrasyon?",
    "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "Ang adbentahe ng platform sa pakikipagpalitan ng Kompanya ay hindi mo kailangan na magdeposito ng malaking halaga sa iyong account. Maaari kang magsimulang makipagpalitan sa maliit na halaga ng pera. Ang pinakamababang deposito ay 10 US dollars.",
    "How can I deposit?": "Paano ako makakapagdeposito?",
    "It is very easy to do. The procedure will take a couple of minutes.": "Madali lamg itong gawin. Ang paraan ay aabutin ng ibang minuto lamang. ",
    "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "Buksan ang window sa pagsasagawa ng palitan at pindutin ang berdeng \"Deposito\" na buton sa itaas na kanan na kanto ng tab. ",
    "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "Maaari ka rin magdeposito sa account sa pamamagitan ng iyong Personal na Account sa pamamagitan ng pagpindot ng \"Deposito\" na buton sa profile ng account. ",
    "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "Pagkatapos ay kailangan na pumili ng paraan ng pagdeposito sa account (ang Kompanya ay nag-aalok ng maraming mga kumbiniyenteng mga paraan na magagamit ng Kliyente at makikita sa kanyang indibidwal na account).",
    "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "Pagkatapos ay tukuyin ang salapi kung saan ang account ay idedeposito, at ayon sa salapi ng account mismo. ",
    "Enter the amount of the deposit.": "Ilagay ang halaga ng deposito. ",
    "Fill out the form by entering the requested payment details.": "Sagutan ang form sa pamamagitan ng paglalagay ng hiniling na detalye sa pagbayad.",
    "Make a payment.": "Gawin ang bayad. ",
    "Do I need to deposit the account of the trading platform and how often do I need to do this?": "Kailangan ko ba na magdeposito ng account sa platform sa pakikipagpalitan at gaano kadalas ko dapat gawin ito?",
    "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "Para makagamit ng mga digital na opsyon kailangan mong magbukas ng isang indibidwal na account. Para makagawa ng totoong palitan, kailangan mo na gumawa ng deposito sa halaga ng biniling opsyon. ",
    "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "Maaari kang magsimula na makipagpalitan nang walang pera, sa paggamit ng account sa pagsasanay ng kompanya (demo account). Ang account na ito ay walang singil at ginawa para ipakita kung paano gumalaw ang platform sa pakikipagpalitan. Sa tulong ng account na ito, maaari kang magsanay sa pagkuha ng mga digital na opsyon, unawain ang mga batayang konsepto sa pakikipagpalitan, subukan ang iba't ibang mga paraan at istratehiya, o itaya ang lebel ng iyong kakayahan. ",
    "What is the minimum deposit amount?": "Ano ang pinakamababang deposito?",
    "{number} questions": "{number} mga tanong",
    "Verification": "Beripikasyon",
    "Payment": "Pagbayad",
    "Payouts": "Pagbayad",
    "Ask a specialist": "Magtanong sa isang Ispesyalista",
    "FAQ": "Mga Madalas na Katanungan",
    "Didn't find an answer to your question?": "Hindi mahanap ang sagot sa iyong tanong?",
    "Open knowledge base": "Bukas na Kaalaman",
    "Contact support": "Makipag-ugnayan sa suporta ",
    "Open FAQ page": "Buksan ang pahina ng Mga Madalas na Katanungan",
    "New message": "Bagong Mensahe",
    "Tournaments": "Mga paligsahan",
    "To end": "Upang tapusin",
    "Until start": "Hanggang magsimula",
    "Front side": "Harapan",
    "Back side (if any)": "Likod na panig (kung mayroon)",
    "Tourna-ments": "Mga Tournament",
    "Deletion of Account and Personal Data": "Pagbubura ng Account at Personal na Datos",
    "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "Sa pagbubura ng iyong account at personal na datos, permenente kang mawawalan ng akses sa iyong account sa Quotex platform. Tandaan na ang iyong datos ay buburahin at hindi na mababawi at imposible na maibalik ulit ang iyong account!",
    "NOTE: Please complete all open trades and pending orders before you delete your account.": "TANDAAN: Mangyaring kumpletuhin ang lahat ng mga bukas ng pakikipagpalitan at mga nakabinbing order bago burahin ang iyong account.",
    "Request Deletion": "Hilingin ang Pagbura",
    "To delete your account, please contact support service": "Upang burahin ang iyong account, mangyaring makipag-ugnayan sa serbisyo ng suporta",
    "Two-factor authorization via Email": "Two-factor authorization sa pamamagitan ng Email",
    "This feature is for experienced users who are familiar with Google Authenticator.": "Ang tampok na ito ay para sa mga bihasang user na pamilyar sa Google Authenticator.",
    "Please note!": "Pakitandaan !",
    "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "Kung nawalan ka ng access sa Google Authenticator sa anumang kadahilanan pagkatapos ikonekta ito, mawawala ka ng access sa iyong account.",
    "Proceed to setup": "Magpatuloy sa pag-setup",
    "Account protection method": "Paraan ng proteksyon ng account",
    "Here's how to set it up:": "Narito kung paano i-set up ito:",
    "Please enter the PIN-code we've just sent to your email:": "Ilagay lamang ang PIN-code na ipinadala lang namin sa iyong email:",
    "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "Magaling. Matagumpay mong pinagana ang 2-step verification sa pamamagitan ng Google Authenticator. Sa susunod na ikaw ay mag-sign in, hihilingin ka para sa isang code.",
    "If you ever lose access to your 2-step verification device, please": "Kung sakaling mawalan ka ng access sa iyong device para sa 2-step verification, mangyaring",
    "Confirm account deletion": "Kumpirmahin ang pagbura ng account",
    "To delete your account, follow the link in the email sent to": "Upang burahin ang iyong account, sundin ang link sa email na ipinadala sa",
    "Image requirements": "Mga kinakailangan sa imahe",
    "Email": "Email",
    "Сonfirmation": "Kumpirmasyon",
    "Change protection method": "Baguhin ang paraan ng proteksyon",
    "First, enter the code from the Google Authenticator app:": "Una, ilagay ang code mula sa Google Authenticator app:",
    "First, enter the PIN-code we've just sent to your email:": "Ilagay lamang ang PIN-code na ipinadala lang namin sa iyong email:",
    "Nice work. You've successfully enabled 2-step verification via Email.": "Magaling. Matagumpay mong pinahintulutan ang 2-step verification sa pamamagitan ng Email.",
    "Receiving codes via Email": "Pagtanggap ng mga code sa pamamagitan ng Email",
    "Receiving codes via Google Authenticator": "Pagtanggap ng mga code sa pamamagitan ng Google Authenticator",
    "Choose a method:": "Pumili ng paraan:",
    "To enter the platform": "Upang makapasok sa platform",
    "To withdraw funds": "Upang mag-withdraw ng pondo",
    "day": "araw",
    "days2": "days2",
    "days5": "days5",



text: 'Pumili ng Pera',
text1: 'Email',
text2: 'I-cancel ang Transaksiyon Para sa',
text3: 'Libreng Kodigo ng Diskwento',
text4: 'Araw-araw naming pinapabuti ang plataporma upang gawing mas madali.',
text5: 'Gusto mo ba ang platapormang ito? Mangyaring ipaalam ito sa iba!',
text6: 'Mangyaring mag-iwan ng mga puna tungkol sa QUOTEX, makakakuha ka ng premyong Libreng Pagkansela ng Transaksiyon.',
text7: 'Ipadala ang iyong mga puna sa aming koponan ng serbisyo sa customer, pagkatapos suriin, ipapadala namin ang premyo sa iyo.',
text8: 'Minimum na Halaga ng Deposit',
text9: 'Minimum na Halaga ng Pag-kuha',
text10: 'Mabilis na Pag-kuha',
text11: 'Libre',
text12: 'Hindi Napatunayan',
text13: 'Ipadala Muli',
text14: 'Kumpirmahin ang Iyong Partisipasyon',
text15: 'I-cancel ang Transaksiyon',
text16: 'Mangyaring mag-input at i-activate ang iyong Libreng Kodigo ng Diskwento. Maaari mong gamitin ang Kodigo ng Diskwento sa anumang oras.',
text17: 'Kodigo ng Diskwento:',
text18: 'Makakuha ng 30% na Premyo batay sa Balanseng Deposit',
text19: 'Kasalukuyang Pera',
text20: 'Natitirang Balanse na Puwedeng Kuhanin',
text21: 'Sa loob ng Account',
text22: 'Ngayon',
text23: 'Kahapon',
text24: 'Linggo',
text25: 'Buwan',
text26: 'Net na Halaga ng Transaksiyon',
text27: 'Ang WELCOME ay isang 30% na premyo sa unang deposito.',
text28: 'Ang unang halaga ng deposito ay $100. Pumunta upang makakuha ng 30% na premyo, piliin ang paraang pang-deposito.',
text29: 'Card ng Bangko',
text30: 'Bangko',
text31: 'Piniling Paraan ng Pagbabayad:',
text32: 'Minimum na Halaga:',
text33: 'Maximum na Halaga:',
text34: 'Pumili ng Ibang Paraan',
text35: 'Impormasyon sa Pagbabayad',
text36: 'Halaga',
text37: 'Gamitin ang Premyo',
text38: 'Mga Tuntunin at Kundisyon',
text39: 'Mayroon akong Kodigo ng Diskwento',
text40: 'Kodigo ng Diskwento',
text41: 'I-apply ang Kodigo ng Diskwento',
text42: 'Ang Kodigo ng Diskwento na iyong isinumite ay wasto. Makakakuha ka ng 30% na premyo.',
text43: 'Makakakuha ka ng',
text44: 'na premyo:',
text45: 'Mangyaring basahin ang lahat ng karaniwang mga tanong',
text46: 'Upang mag-trade ng digital asset, kinakailangan mong magbukas ng personal na account. Upang magawa ang totoong pag-trade, tiyak na kailangan mo ang halagang deposito batay sa presyo ng opsyon.',
text48: 'Mayroong {0} na Kodigo ng Diskwento',
text49: 'Gumamit ng Kodigo ng Diskwento na ito upang kanselahin ang mga transaksiyon na nalulugi',
text50: 'Maaari kang magkansela lamang ng mga hindi hedge na transaksiyon, na ang halaga ay hindi hihigit sa maximum na halaga ng Kodigo ng Diskwento.',
text51: 'Katayuan',
text52: 'Gamitin',
text53: 'Wala ka pang kasaysayan ng Kodigo ng Diskwento. Maaari mong gamitin ang mga button sa ibaba upang magdagdag ng Kodigo ng Diskwento.',
text54: 'Ipakita ang Kasaysayan',
text55: 'Mag-input ng Kodigo ng Diskwento',
text56: 'Impormasyon sa Privacy',
text57: 'Blangko',
text58: 'Pangalan',
text59: 'Apelyido',
text60: 'Petsa ng Kapanganakan',
text61: 'Bansa',
text62: 'I-save',
text63: 'Direksyon',
text64: 'Pag-verify ng Dokumento',
text65: 'Bago ma-verify ang account, kinakailangan mong mag-input ng personal na impormasyon.',
text66: 'Seguridad',
text67: 'Dalawang Hakbang na Pag-verify',
text68: 'Baguhin ang Password',
text69: 'Lumang Password',
text70: 'Bagong Password',
text71: 'Ulitin ang Bagong Password',
text72: 'I-logout ang Account',
text73: 'Mag-verify ng Credit Card o Debit Card',
text74: 'Magdagdag ng Bagong Card ng Bangko',
text75: 'Wala kang anumang kinakailangang i-verify na credit card at debit card',
text76: 'Wala ka pang kasaysayan ng transaksiyon.',
text77: 'Unang Etapa',
text78: 'Huling Etapa',
text79: 'Mag-apply ng Filter',
text80: 'Huli',
text81: 'Susunod',
text82: 'Kita',
text83: 'Nakansela na',
text84: 'Account',
text85: 'Bayad',
text86: 'Maaari kang mag-withdraw ng pera mula sa iyong account sa plataporma patungo sa iyong bank account o e-wallet na ginamit mo para sa pag-deposito. Maaari kang mag-request ng pag-kuha sa anumang oras. Ang iyong kahilingan sa pag-withdraw ay aaprubahan sa loob ng 3 na araw na pang-negosyo.',
text87: 'Mag-deposito',
text88: 'Ilang Nakaraang Mga Hiling',
text89: 'Kasaysayan ng Pag-kuha',
text90: 'Ang Aking Mga Hiling',
text91: 'Maglagay ng Hiling',
text92: 'Karaniwang Mga Tanong',
text93: 'Wala kang anumang hiling na isinumite',
text94: 'Kung mayroon kang anumang mga tanong, maaari kang makakita ng mga sagot sa mga karaniwang tanong.',
text95: 'Copyright © 2024 Quotex. Lahat ng Karapatan ay Nakareserba',
text96: 'Mag-login gamit',
text97: 'Password',
text98: 'Tandaan ako',
text99: 'Bumalik sa Impormasyon',
text100: 'Tapos na',


text104: 'Innovation Platform para sa Intelligent Investment',
   text105: 'Mag-sign up at makakuha ng $10,000 sa isang demo account kung saan ka natutong mag-trade',
   text106: 'Magparehistro',
   text107: 'Magbukas ng demo account',
   text108:'Maginhawang interface ng kalakalan',
   text109:'Ginawa namin ang pinakasimple at pinaka-maginhawang interface upang hindi ka malihis sa kung ano ang pinakamahalaga - ang pangangalakal. ',
   text110: 'Built-in na signal',
   text111:'Isaalang-alang ang isang diskarte sa pangangalakal, nagbibigay kami ng pinakatumpak at makabagong mga signal hanggang sa 87% upang matulungan kang bumuo ng iyong sariling epektibong diskarte. ',
   text112:'Tagapagpahiwatig ng kalakalan',
   text113:'Nakolekta namin ang pinakakapaki-pakinabang na mga tagapagpahiwatig ng kalakalan. Gamitin ang indicator na ito upang madagdagan ang balanse ng iyong account. ',
   text114: 'Perpektong bilis',
   text115: 'Gumagamit ang aming platform ng pinaka-makabagong teknolohiya at naghahatid ng napakabilis na bilis. ',
   text116: 'Subukan ito gamit ang isang demo account',
   text117: 'Upang kumita ng mas maraming pera,',
   text118: 'Gumawa ng tamang pagbabala',
   text119: 'Ang presyo ba ay bullish o bearish? ',
   text120: 'Magbukas ng demo account',
   text121: 'I-click',
   text122:'Sa isa sa mga button at tingnan ang mga resulta',
   text123: 'Tataas ang presyo',
   text124: 'Bababa ang presyo',
   text125:'Naghihintay ng mga resulta...',
   text126: 'Ang iyong kita',
   text127:'Paumanhin, mali ang hula mo',
   text128: 'Subukan muli',
   text129: 'Ang iyong kita',
   text130:'Congratulations! Tama ang hula mo',
   text131: 'Magsimulang kumita',
   text132:'Palaging available ang mobile app',
   text133: 'I-download ang aming user-friendly na app para sa iPhone o Android at simulan ang pangangalakal! ',
   text134:'Available time',
   text135: 'Simulan ang pangangalakal',
   text136:'3 hakbang',
   text137:'Magbukas ng totoong account nang libre sa loob lamang ng ilang minuto',
   text138: 'Makipagkalakalan sa demo account sa isang pag-click',
   text139: 'Pagsasanay',
   text140: 'Pagbutihin ang iyong mga kasanayan sa mga demo account at mga materyales sa pagsasanay',
   text141: 'Simulan ang iyong pagtuturo gamit ang isang demo account',
   text142:'Magdeposito sa iyong totoong account at simulan ang pangangalakal',
   text143:'Higit sa 410 instrumento, ang minimum na halaga ng transaksyon ay $10',
   text144:'Jump deposit method',
   text145:'FAQ',
   text146: 'Paano ito master? ',
   text147:'Magrehistro ng demo account at magsanay gamit ang account na ito. Ang isang demo account ay kapareho ng isang tunay na account, ngunit walang kinakailangang deposito. ',
   text148:'Gaano katagal bago magproseso ng withdrawal? ',
   text149: 'Sa karaniwan, ang proseso ng pag-withdraw ay tumatagal sa pagitan ng 1 at 5 araw upang maproseso mula sa petsa ng pagtanggap ng kaukulang kahilingan ng user, at depende lamang sa bilang ng mga kahilingang naproseso nang sabay-sabay. Palaging nagsusumikap ang Kumpanya na direktang bayaran ang kahilingan ng Gumagamit sa petsa ng pagtanggap nito. ',
   text150:'Ano ang trading platform? ano ang epekto? ',
   text151: 'Ang trading platform ay isang software system. Ginagamit ng mga user ang mga instrumento sa pananalapi na ibinigay ng system na ito upang magsagawa ng mga transaksyon (mga operasyon), at ginagamit din ang halaga ng panipi, real-time na sitwasyon sa merkado, mga aksyon ng kumpanya at iba pang impormasyon na ibinigay ng kumpanya. ',
   text152:'Maaari ko bang gamitin ang aking mobile phone upang magsagawa ng mga transaksyon? ',
   text153: 'Gumagamit ang aming platform ng pinaka-makabagong teknolohiya at maaaring mabuksan sa browser ng anumang computer o smartphone',
   text154:'Ano ang pinakamababang halaga ng deposito? ',
   text155: 'Ang bentahe ng platform ng kalakalan ng kumpanyang ito ay hindi mo kailangang magdeposito ng malaking halaga ng pera sa iyong account. Maaari mong simulan ang pangangalakal sa pamamagitan ng pamumuhunan ng maliit na halaga ng pera. Ang pinakamababang deposito ay $10. ',
   text156:'Mayroon bang anumang komisyon kapag nagdedeposito o nag-withdraw ng pera mula sa account? ',
   text157: 'Hindi. Ang kumpanya ay hindi naniningil ng anumang komisyon para sa mga operasyon tulad ng pagdedeposito ng mga account o pag-withdraw ng mga pondo. ',
   text158: 'Gayunpaman, dapat tandaan na ang mga sistema ng pagbabayad ay maaaring maningil ng mga komisyon at gumamit ng differential currency exchange rates. ',
   text159: 'Pumunta sa lahat ng tanong',
   text160:'Quotex: Innovation Platform',
   text161:'Digital asset trading',
   text162: 'Magbukas ng totoong account',
   text163: 'Partner',
   text164:'Demo account',
   text165: 'Impormasyon sa pakikipag-ugnayan',
   text166:'Mahalagang tanong',
   text167:'Mga isyu sa pananalapi',
   text168:'verification',
   text169: 'Mga Regulasyon',
   text170:'Patakaran sa Privacy',
   text171:'Mga Tuntunin sa Pagbabayad',
   text172: 'Kasunduan sa Serbisyo',
   text173: 'Babala sa Panganib',
   text174:'Mga panuntunan sa pagpapatakbo ng kalakalan',
   text175:'Non-trading operating rules',
   text176:`Babala sa Panganib: Ang pangangalakal sa mga digital asset trading platform at paggamit ng mga instrumento sa pagpopondo sa pagpapautang ay maaaring magsama ng malalaking panganib at maaaring magresulta sa kumpletong pagkawala ng iyong kapital sa pamumuhunan. Huwag mamuhunan ng higit sa iyong makakaya, at dapat mong maunawaan ang mga panganib na kasangkot. Ang pangangalakal gamit ang hiniram na mga instrumento sa pagpopondo ay hindi angkop para sa karamihan ng mga mamumuhunan. Ang pangangalakal nang hindi gumagamit ng mga hiniram na pondo tulad ng mga stock ay mapanganib din dahil ang mga presyo ng stock ay maaaring parehong bumaba at tumaas, na maaaring mangahulugan na kahit na manalo ka, mas mababa ang iyong babalik kaysa sa iyong namuhunan.
   Ang nakaraang tagumpay ay hindi ginagarantiyahan ang tagumpay sa hinaharap. Bago mag-trade, mangyaring bigyang-pansin ang iyong karanasan sa pangangalakal at antas, mga layunin sa pamumuhunan, at makipag-ugnayan sa isang independiyenteng tagapayo sa pananalapi kung kinakailangan. Dapat matukoy ng mga user kung pinapayagan ang paggamit ng mga serbisyong may tatak na Quotex batay sa mga batas at regulasyon ng kanilang bansang tinitirhan. `,

}