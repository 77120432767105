export default {
        "+{value}% profit": "+{value}% লাভ",
        "Selfie verification required": "সেলফি যাচাইকরণ প্রয়োজন",
        "In order to perform the necessary account verification steps, we kindly ask you to provide a high-resolution photo of yourself (selfie) holding your ID document with a current date and your signature on a sheet of paper. Your face, body and both hands should be visible.<br><br> The details of the document should be clear and readable.": "প্রয়োজনীয় অ্যাকাউন্ট যাচাইকরণের পদক্ষেপগুলি সম্পাদন করার জন্য, আমরা অনুগ্রহ করে আপনাকে আপনার একটি উচ্চ-রেজোলিউশনের ছবি (সেলফি) প্রদান করতে বলছি যাতে আপনার আইডি ডকুমেন্টের বর্তমান তারিখ এবং কাগজের শীটে আপনার স্বাক্ষর রয়েছে। আপনার মুখ, শরীর এবং উভয় হাত দৃশ্যমান হওয়া উচিত।<br><br> নথির বিবরণ পরিষ্কার এবং পাঠযোগ্য হওয়া উচিত।",
        "Your documents have been checked, now your identity is verified.": "আপনার নথিগুলি পরীক্ষা করা হয়েছে, এখন আপনার পরিচয় যাচাই করা হয়েছে।",
        "You need fill identity information before verification your profile.": "আপনার প্রোফাইল যাচাই করার আগে আপনাকে পরিচয় তথ্য পূরণ করতে হবে।",
        "Documents verification": "নথি যাচাইকরণ",
        "Profile verification means the provision of an official document certifying the Client's identity. This procedure can be initiated by the Company's security department at any time.": "প্রোফাইল যাচাইকরণ মানে ক্লায়েন্টের পরিচয় প্রত্যয়িত একটি অফিসিয়াল নথির বিধান। কোম্পানির নিরাপত্তা বিভাগ যে কোনো সময় এই পদ্ধতিটি শুরু করতে পারে৷",
        "Upload Photo": "ছবি আপলোড করুন",
        "Nickname": "ডাকনাম",
        "First Name": "নামের প্রথম অংশ",
        "Last Name": "নামের শেষাংশ",
        "The name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "ডকুমেন্টে যেভাবে আছে নামটি অবশ্যই সেভাবে উল্লেখ করতে হবে। ভুল তথ্য প্রদানের করলে, কোন সতর্কতা ছাড়াই অ্যাকাউন্টটি মুছে ফেলা হতে পারে।",
        "The last name must be specified exactly as in the document. For providing incorrect information, the account may be deleted without warning.": "নামের শেষাংশ ডকুমেন্টে যেভাবে আছে সেভাবেই উল্লেখ করতে হবে। ভুল তথ্য প্রদান করলে, কোন সতর্কতা ছাড়াই অ্যাকাউন্টটি মুছে ফেলা হতে পারে।",
        "It's not possible to change your birth date further. If you indicated it incorrectly, you will not be able to withdraw funds from your account.": "আপনার জন্ম তারিখ আর পরিবর্তন করা সম্ভব নয়। আপনি যদি এটি ভুলভাবে নির্দেশ করেন তবে আপনি আপনার অ্যাকাউন্ট থেকে তহবিল উত্তোলন করতে পারবেন না।",
        "Empty": "খালি",
        "Date of birth": "জন্ম তারিখ",
        "Unverified": "অযাচাইকৃত",
        "Verified": "যাচাইকৃত",
        "Resend": "আবার পাঠান",
        "Country": "দেশ",
        "Address": "ঠিকানা",
        "Change Identity Info": "পরিচয় তথ্য পরিবর্তন করুন",
        "Identity Info": "পরিচয় তথ্য",
        "Two-factor authentication on login": "লগইনে দ্বি-ফ্যাক্টর প্রমাণীকরণ",
        "Two-factor authentication on withdrawal": "উত্তোলনের উপর দ্বি-ফ্যাক্টর প্রমাণীকরণ",
        "Active": "সক্রিয়",
        "Inactive": "নিষ্ক্রিয়",
        "Setup": "সেটআপ",
        "Two-step verification": "দুই স্তরবিশিষ্ট যাচাইকরণ",
        "Security": "নিরাপত্তা",
        "Google Authenticator": "গুগল প্রমাণীকরণকারী",
        "Scan the QR code": "QR কোড স্ক্যান করুন",
        "We take your security very seriously, which is why we require 2-step verification. Here's how to set it up:": "আমরা আপনার নিরাপত্তাকে অত্যন্ত গুরুত্ব সহকারে নিই, তাই আমাদের 2-স্তরবিশিষ্ট যাচাইকরণ প্রয়োজন৷ এটি কীভাবে সেট আপ করবেন তা এখানে:",
        "Install ": " ইনস্টল করুন",
        "Can't scan?": "স্ক্যান করতে পারবেন না?",
        "Click here.": "এখানে ক্লিক করুন।",
        "Enter this code into Google Authenticator.": "এই কোডটি Google প্রমাণীকরণকারীতে প্রবেশ করান।",
        "Back to QR code.": "QR কোডে ফিরে যান।",
        "Enter the 6-digit code from Authenticator below": "নীচের প্রমাণীকরণকারী থেকে 6-সংখ্যার কোডটি লিখুন৷",
        "Enter 6-digit code...": "6-সংখ্যার কোড লিখুন...",
        "Nice work. You've successfully enabled 2-step verification. The next time you sign in, you'll be prompted for a code.": "চমৎকার কাজ। আপনি সফলভাবে 2-স্তরবিশিষ্ট যাচাইকরণ সক্ষম করেছেন৷ পরের বার আপনি সাইন ইন করার সময়, আপনাকে একটি কোডের জন্য অনুরোধ করা হবে।",
        "Code": "কোড",
        "contact support": "সাপোর্টে যোগাযোগ করুন",
        "If you ever lose access to your 2-step verification device, please ": "আপনি যদি কখনও আপনার ২-পদক্ষেপে যাচাইকরণ ডিভাইসে অ্যাক্সেস হারান, অনুগ্রহ করে",
        "Yes, activate": "হ্যাঁ, সক্রিয় করুন",
        "Cancel": "বাতিল করুন",
        "Close": "বন্ধ করুন",
        "Language": "ভাষা",
        "Timezone": "টাইমজোন",
        "Delete Account": "একাউন্ট মুছে ফেলুন",
        "Verification Credit / Debit Cards": "ক্রেডিট/ডেবিট কার্ড যাচাইকরণ",
        "Add New Card": "নতুন কার্ড যোগ করুন",
        "Wait for loading...": "লোড হওয়ার জন্য অপেক্ষা করুন...",
        "You don’t have any credit / debit cards for verification": "যাচাইকরণের জন্য আপনার কাছে কোনো ক্রেডিট/ডেবিট কার্ড নেই",
        "Verificate": "যাচাই করুন",
        "Verification Card": "যাচাইকরণ কার্ড",
        "Please place your ID (showing photo, name and date of birth) along with the front side of your bank card (showing the number, expiration date, and your name) on a blank A4 sheet.<br> Please write “Quotex.io” and add your signature to it.<br> Take a photo and upload it.": "অনুগ্রহ করে আপনার আইডি (ছবি, নাম এবং জন্ম তারিখ দেখানো) আপনার ব্যাংক কার্ডের সামনের পাশে (নম্বর, মেয়াদ শেষ হওয়ার তারিখ এবং আপনার নাম দেখানো) একটি ফাঁকা A4 শীটে রাখুন।<br> অনুগ্রহ করে লিখুন “Quotex.io ” এবং এতে আপনার স্বাক্ষর যোগ করুন।<br> একটি ছবি তুলুন এবং আপলোড করুন।",
        "Card:": "কার্ড:",
        "Type:": "ধরণ:",
        "Your ID and bank card on an A4 sheet": "একটি A4 শীটে আপনার আইডি এবং ব্যাংক কার্ড",
        "Yes, confirm": "হ্যাঁ, নিশ্চিত করুন",
        "Click or drag the image": "ছবিটিতে ক্লিক করুন বা টেনে আনুন",
        "Card": "কার্ড",
        "Documents": "নথিপত্র",
        "First, please fill out the “Identity Info” section in your Profile, and click on \"Change Identity Info\" button to save changes. After that you will be able to add a new card.": "প্রথমে, অনুগ্রহ করে আপনার প্রোফাইলের \"পরিচয় তথ্য\" বিভাগটি পূরণ করুন এবং পরিবর্তনগুলি সংরক্ষণ করতে \"পরিচয় তথ্য পরিবর্তন করুন\" বোতামে ক্লিক করুন৷ এরপরে আপনি একটি নতুন কার্ড যুক্ত করতে পারবেন।",
        "Specify the card details for further operations for depositing and debiting funds.": "তহবিল ডিপোজিট এবং ডেবিট করার জন্য আরও ক্রিয়াকলাপের জন্য কার্ডের বিবরণ নির্দিষ্ট করুন৷",
        "Card number:": "কার্ড নম্বর:",
        "Expiry month:": "মেয়াদ শেষ হওয়ার মাস:",
        "Expiry year:": "মেয়াদ শেষ হওয়ার বছর:",
        "Cardholder name:": "কার্ডধারীর নাম:",
        "Next": "পরবর্তী",
        "Please put your signature and the current date on the sheet, place your document along with your bank card, take a photo and upload it in the form below.": "অনুগ্রহ করে শীটে আপনার স্বাক্ষর এবং বর্তমান তারিখ রাখুন, আপনার ব্যাংক কার্ডের সাথে আপনার নথি রাখুন, একটি ছবি তুলুন এবং নীচের ফর্মে আপলোড করুন।",
        "Do you have a two-sided document?": "আপনি একটি দ্বি-তরফা নথি আছে?",
        "Upload the back side as a separate file, also on a sheet with the date and your signature.": "একটি পৃথক ফাইল হিসাবে পিছনের দিকটি আপলোড করুন, তারিখ এবং আপনার স্বাক্ষর সহ একটি শীটেও।",
        "Submit only valid documents": "শুধুমাত্র বৈধ নথি জমা দিন",
        "Do not edit or crop photo": "ফটো এডিট বা ক্রপ করবেন না",
        "Do not upload other people's documents": "অন্য ব্যক্তির নথি আপলোড করবেন না",
        "Read all": "সব পড়ুন",
        "Uploading files": "ফাইল আপলোড করা হচ্ছে",
        "For single-sided documents, upload only the front side.": "এক পাশের ডকুমেন্টের জন্য শুধু সামনের অংশ আপলোড করুন।",
        "Back": "পেছনে",
        "Add a card": "একটি কার্ড যোগ করুন",
        "File Requirements": "ফাইলের আবশ্যকতা",
        "Scanned images are not accepted for card verification": "কার্ড যাচাইকরণের জন্য স্ক্যান করা ছবি গ্রহণ করা হয় না",
        "We only accept files with your regular civil passport, or National ID (both sides should be uploaded as separate files)": "আমরা শুধুমাত্র আপনার নিয়মিত সিভিল পাসপোর্ট, বা জাতীয় পরিচয়পত্র সহ ফাইলগুলি গ্রহণ করি (উভয় পাশেরই পৃথক ফাইল হিসাবে আপলোড করা উচিত)",
        "Driving license CANNOT be accepted": "ড্রাইভিং লাইসেন্স গ্রহণ করা যাবে না",
        "External (foreign/international/travel) passport will not be accepted as well": "বহিরাগত (বিদেশী/আন্তর্জাতিক/ভ্রমণ) পাসপোর্টও গ্রহণ করা হবে না",
        "If the document has 2 sides, you need to upload the reverse as a separate file, on the same sheet of paper, with current date and your signature": "যদি নথিটির 2টি দিক থাকে, তাহলে আপনাকে বর্তমান তারিখ এবং আপনার স্বাক্ষর সহ কাগজের একই শীটে একটি পৃথক ফাইল হিসাবে বিপরীতে আপলোড করতে হবে",
        "All information in the document and on the card should be clear and readable on the photo": "নথিতে এবং কার্ডের সমস্ত তথ্য ফটোতে পরিষ্কার এবং পাঠযোগ্য হওয়া উচিত",
        "Both your document and your card must fit into the photo completely the edges should not be cropped": "আপনার নথি এবং আপনার কার্ড উভয়ই ফটোতে পুরোপুরি ফিট হওয়া উচিত প্রান্তগুলি কাটা উচিত নয়৷",
        "There should be no glares, or other photo defects, that could make it difficult to read the document’s and card’s data": "কোনও গ্লেয়ার বা অন্য ফটো ত্রুটি থাকা উচিত নয়, যা নথি এবং কার্ডের তথ্য পড়া কঠিন করে তুলতে পারে",
        "Expired documents and cards will be rejected automatically": "মেয়াদোত্তীর্ণ নথি এবং কার্ড স্বয়ংক্রিয়ভাবে প্রত্যাখ্যান করা হবে",
        "If you card doesn’t have your name on it, you need to upload a screenshot of bank statement, showing your cards number and your name. In some cases, it’s allowed to write your name by hand on the same sheet of paper": "আপনার কার্ডে আপনার নাম না থাকলে, আপনাকে ব্যাংক স্টেটমেন্টের একটি স্ক্রিনশট আপলোড করতে হবে, আপনার কার্ড নম্বর এবং আপনার নাম দেখাচ্ছে৷ কিছু ক্ষেত্রে, কাগজের একই শীটে হাত দিয়ে আপনার নাম লেখার অনুমতি দেওয়া হয়",
        "It’s not allowed to hide elements of the documents, containing the data, required for verification (name, date of birth, photograph)": "যাচাইকরণের জন্য প্রয়োজনীয় (নাম, জন্ম তারিখ, ছবি) তথ্য সহ নথির উপাদানগুলি লুকানোর অনুমতি নেই",
        "It is allowed to hide the 8 digits in the card’s number. The first 4 and the last 4 digits should be clearly visible": "কার্ডের নম্বরে 8টি সংখ্যা লুকানোর অনুমতি রয়েছে। প্রথম 4 এবং শেষ 4 সংখ্যা স্পষ্টভাবে দৃশ্যমান হওয়া উচিত",
        "CVV code should be hidden at all times, in every case": "CVV কোড সব সময়ে লুকানো উচিত, প্রতিটি ক্ষেত্রে",
        "Any editing of your files is prohibited. Only unedited photos and scanned images can be accepted. Editing of personal information in your documents (name, date of birth, etc.) is not allowed": "আপনার ফাইলের কোনো সম্পাদনা নিষিদ্ধ। শুধুমাত্র অসম্পাদিত ছবি এবং স্ক্যান করা ছবি গ্রহণ করা যেতে পারে। আপনার নথিতে ব্যক্তিগত তথ্য (নাম, জন্ম তারিখ, ইত্যাদি) সম্পাদনা করার অনুমতি নেই",
        "The photograph must be a part of the document (glued in or printed). Photos attached separately will not be accepted": "ছবি অবশ্যই নথির একটি অংশ হতে হবে (লাগানো বা মুদ্রিত)। আলাদাভাবে সংযুক্ত ছবি গ্রহণ করা হবে না",
        "It’s only allowed to upload documents and cards in the name, specified in the profile (to which your account is registered)": "এটি শুধুমাত্র প্রোফাইলে উল্লেখিত নামে নথি এবং কার্ড আপলোড করার অনুমতি দেয় (যেটিতে আপনার অ্যাকাউন্ট নিবন্ধিত আছে)",
        "We remind you that for submitting fake/false information the account may be suspended/blocked, until the circumstances are clarified": "আমরা আপনাকে মনে করিয়ে দিচ্ছি যে জাল/মিথ্যা তথ্য জমা দেওয়ার জন্য অ্যাকাউন্টটি সাসপেন্ড/ব্লক করা হতে পারে, যতক্ষণ না পরিস্থিতি স্পষ্ট হয়",
        "The Company reserves the right to ask you to provide any additional documents for account verification (including your selfie with document) at any time": "কোম্পানি যেকোনো সময় অ্যাকাউন্ট যাচাইকরণের জন্য (নথির সাথে আপনার সেলফি সহ) কোনো অতিরিক্ত নথি প্রদান করতে বলার অধিকার সংরক্ষণ করে",
        "For all questions related to documents verification, you can create a support ticket on the platform (“Help” section is the first button with blinking question mark icon, on the leftmost panel)": "নথি যাচাই সংক্রান্ত সমস্ত প্রশ্নের জন্য, আপনি প্ল্যাটফর্মে একটি সহায়তা টিকিট তৈরি করতে পারেন (\"সহায়তা\" বিভাগটি বাঁদিকের প্যানেলে ব্লিঙ্কিং প্রশ্ন চিহ্ন আইকন সহ প্রথম বোতাম)",
        "Profile": "প্রোফাইল",
        "Deposit": "ডিপোজিট",
        "Withdrawal": "উত্তোলন",
        "Transactions": "লেনদেন",
        "Trades": "ট্রেডসমূহ",
        "Market": "মার্কেট",
        "Analytics": "বিশ্লেষণ",
        "My current currency": "আমার বর্তমান মুদ্রা",
        "Available for withdrawal": "উত্তোলনের জন্য উপলব্ধ",
        "In the account": "অ্যাকাউন্টে",
        "Percentage of turnover {percent}%": "টার্নওভারের শতাংশ {percent}%",
        "Cashback {percent}%": "ক্যাশব্যাক {percent}%",
        "Currency:": "মুদ্রা:",
        "Change": "পরিবর্তন করুন",
        "Live Account": "লাইভ অ্যাকাউন্ট",
        "Daily budget limit": "দৈনিক বাজেটের সীমা",
        "The value cannot exceed your total budget": "এই মান আপনার মোট বাজেট অতিক্রম করতে পারবে না।",
        "Demo Account": "ডেমো অ্যাকাউন্ট",
        "Apply": "আবেদন করুন",
        "Edit": "সম্পাদনা করুন",
        "New value": "নতুন মূল্য",
        "Confirm": "নিশ্চিত করুন",
        "Logout": "লগআউট করুন",
        "No, cancel": "না, বাতিল করুন",
        "Exchange Form": "এক্সচেঞ্জ ফর্ম",
        "My Currency:": "আমার মুদ্রা:",
        "New Currency:": "নতুন মুদ্রা:",
        "You are exchanging": "আপনি এক্সচেঞ্জ করছেন",
        "You will receive": "আপনি পাবেন",
        "Exchange Fee": "এক্সচেঞ্জ ফি",
        "Password changed": "পাসওয়ার্ড পরিবর্তন করা হয়েছে",
        "Old password": "পুরানো পাসওয়ার্ড",
        "New password": "নতুন পাসওয়ার্ড",
        "Confirm new password": "নতুন পাসওয়ার্ড নিশ্চিত করুন",
        "Change Password": "পাসওয়ার্ড পরিবর্তন করুন",
        "Confirm password must be equal new password value": "কনফার্ম পাসওয়ার্ডের মান অবশ্যই নতুন পাসওয়ার্ডের মানের সমান হতে হবে",
        "About us": "আমাদের সম্পর্কে",
        "Support": "সহায়তা",
        "Help": "সহায়তা",
        "2-step verification": "2-স্তরবিশিষ্ট যাচাইকরণ",
        "Please enter the PIN-code we've just sent to your email": "আমরা এইমাত্র আপনার ইমেইলে যে PIN-কোডটি পাঠিয়েছি দয়া করে সেটি লিখুন",
        "Please enter the 6-digit code from your authentication app": "অনুগ্রহ করে আপনার প্রমাণীকরণ অ্যাপ থেকে 6-সংখ্যার কোড লিখুন",
        "The First Name field is required": "নামের প্রথম অংশ ক্ষেত্রটি প্রয়োজনীয়",
        "The Last Name field is required": "নামের শেষাংশ ক্ষেত্রটি প্রয়োজনীয়",
        "Verification of documents": "নথি যাচাইকরণ",
        "Please upload a color photo or scanned image of your regular civil passport, driving license, or National Identity card.": "আপনার রেগুলার সিভিল পাসপোর্ট, ড্রাইভিং লাইসেন্স, বা জাতীয় আইডি কার্ডের রঙিন বা স্ক্যানকৃত ছবি আপলোড করুন।",
        "Upload Documents": "নথি আপলোড করুন",
        "Why?": "কেন?",
        "Do you want to confirm deleting your account without undo? This action cannot be undone.": "আপনি কি পূর্বাবস্থায় ফেরানো ছাড়া আপনার অ্যাকাউন্ট মুছে ফেলা নিশ্চিত করতে চান? এই ক্রিয়াটি পূর্বাবস্থায় ফেরানো যাবে না।",
        "Set up 2-step verification": "2-স্তরবিশিষ্ট যাচাইকরণ সেট আপ করুন৷",
        "Unfortunately, you cannot upload files to this section. <br> Please refer to the messages from the Support team and attach the required files to a ticket.": "দুর্ভাগ্যবশত, এই সেকশনে আপনি ফাইল আপলোড করতে পারবেন না।<br> অনুগ্রহপূর্বক সাপোর্ট টীমের বার্তাগুলো পড়ুন এবং প্রয়োজনীয় ফাইলগুলো একটি টিকিটের সাথে যুক্ত করুন।",
        "The uploaded document does not match the requirements": "আপলোড করা নথি আবশ্যকতার সাথে মেলে না",
        "The following documents are eligible for identification: ID, passport, international passport or driver's license.": "নিম্নলিখিত নথিগুলি সনাক্তকরণের জন্য যোগ্য: আইডি, পাসপোর্ট, আন্তর্জাতিক পাসপোর্ট বা ড্রাইভার লাইসেন্স।",
        "Images should be of high quality, the data should be easy to read, and the edges of the document should not be cropped.": "ছবিগুলি উচ্চ মানের হওয়া উচিত, ডেটা পড়তে সহজ হওয়া উচিত এবং নথির প্রান্তগুলি কাটা উচিত নয়৷",
        "Balance from {amount}": "{amount} থেকে ব্যালেন্স",
        "Not verified": "ভেরিফাইড নয়",
        "Clear": "মুছুন",
        "In order to avoid service restrictions, please complete the verification process: fill out your personal data and upload the required documents.": "সেবা নেয়া থেকে নিষিদ্ধ হওয়া ঠেকাতে, অনুগ্রহপূর্বক যাচাই-প্রক্রিয়াটি সম্পূর্ণ করুন: আপনার ব্যক্তিগত তথ্য পূরণ করুন এবং প্রয়োজনীয় কাগজপত্র আপলোড করুন।",
        "Get verified": "ভেরিফাইড হোন",
        "I got it!": "আমি বুঝেছি!",
        "Passport.IN": "Aadhaar",
        "Passport.BR": "CPF",
        "AADHAAR Verification": "আধার ভেরিফিকেশন",
        "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number).": "অনুগ্রহপূর্বক আপনার আসল আধার এর একটি রঙিন ছবি আপলোড করুন (আপনার ছবি এবং নথির নাম্বারসহ শুধু সামনের পার্শ্বের ছবি)",
        "Send AADHAAR": "আধার পাঠান",
        "AADHAAR number:": "আধার নাম্বার:",
        "Please upload a color photo of your physical Aadhaar (only the front side with your photo and document number). ☝ No electronic versions are accepted.": "অনুগ্রহপূর্বক আপনার আসল আধার এর একটি রঙিন ছবি আপলোড করুন (আপনার ছবি এবং নথির নাম্বারসহ শুধু সামনের পার্শ্বের ছবি) ☝ কোনো ইলেকট্রনিক ভার্সন গ্রহণ করা হয় না।",
        "You have already uploaded {number} files. Please wait until they are checked.": "আপনি ইতোমধ্যে {number} ফাইল আপলোড করেছেন। অনুগ্রহপূর্বক সেগুলো চেক করা পর্যন্ত অপেক্ষা করুন।",
        "Please upload a color photograph of your document: For RG - both sides need to be uploaded as separate files. For CNH - only the front side with your photo. Document should be valid (less than 10 years from the date of issue), and must be showing your CPF number. * in case there is no CPF in your document - please upload a separate photo of Comprovante de Inscrição no CPF, or a screenshot of your bank statement showing your name and CPF number. ** Please do not upload any electronic versions. They will not be accepted.": "অনুগ্রহপূর্বক আপনার নথির জন্য একি রঙিন ছবি আপলোড করুন: RG এর জন্য - উভয় পার্শ্বের আলাদাভাবে আপলোড করুন। CNH এর জন্য - আপনার ছবিসহ শুধুমাত্র সামনের দিক। নথি অবশ্যই বৈধ হতে হবে (ইস্যুকৃত তারিখ হতে দশ বছরের কম), এবং অবশ্যই আপনার CPF নাম্বার থাকতে হবে। *যদি আপনার কাগজে কোনো CPF না থাকে - অনুগ্রহপূর্বক Comprovante de Inscrição no CPF এর একটি আলাদা ছবি আপলোড করুন, অথবা আপনার নাম এবং CPF নাম্বার প্রদর্শন করে এমন একটি স্ক্রিনশট। ** অনুগ্রহপূর্বক কোনো ইলেকট্রনিক ভার্সন আপলোড করবেন না। সেগুলো গ্রহণ করা হয় না।",
        "Submit for verification": "যাচাইয়ের জন্য জমা দিন",
        "Settings": "সেটিংস",
        "Please upload a color photograph of the front of your national identity card with your photo.": "অনুগ্রহ করে আপনার ছবির সাথে আপনার জাতীয় পরিচয়পত্রের সামনের একটি রঙিন ছবি আপলোড করুন।",
        "Template": "টেমপ্লেট",
        "Light Mode": "লাইট মোড",
        "Twilight": "গোধূলি",
        "Full Night": "পুরো রাত",
        "Grid's opacity": "গ্রিডের অস্বচ্ছতা",
        "Auto-scrolling": "অটো-স্ক্রলিং",
        "Automatic graphic scrolling": "স্বয়ংক্রিয় গ্রাফিক স্ক্রোলিং",
        "1-click trade": "1-ক্লিক ট্রেড",
        "Open trades without confirmation": "নিশ্চিতকরণ ছাড়াই ট্রেড খুলুন",
        "Use timezone": "টাইমজোন ব্যবহার করুন",
        "Use your timezone data": "আপনার টাইমজোন ডেটা ব্যবহার করুন",
        "Graph colors": "গ্রাফ রং",
        "Up Trend": "আপ ট্রেন্ড",
        "Down Trend": "ডাউন ট্রেন্ড",
        "Platform": "প্ল্যাটফর্ম",
        "Short order label": "শর্ট অর্ডার লেবেল",
        "Use short order element mode": "শর্ট অর্ডার এলিমেন্ট মোড ব্যবহার করুন",
        "Perfomance Mode": "পারফরম্যান্স মোড",
        "Use optimized rendering for chart and candles": "চার্ট এবং মোমবাতি জন্য অপ্টিমাইজ রেন্ডারিং ব্যবহার করুন",
        "Background": "পটভূমি",
        "Choose file": "ফাইল পছন্দ করুন",
        "Max size — 2 Mb": "সর্বোচ্চ আকার - 2 Mb",
        "Sign up": "নিবন্ধন করুন",
        "Sign in": "সাইন ইন করুন",
        "Budget and limits": "বাজেট এবং লিমিট",
        "Daily limit": "দৈনিক সীমা",
        "Not set": "নির্ধারিত নেই",
        "Total funds": "মোট তহবিল",
        "Top up": "টপ আপ করুন",
        "Set up": "সংস্থাপন",
        "Set a daily limit on trading to reduce financial risks.": "আর্থিক ঝুঁকি এড়াতে প্রাত্যহিক একটি সীমা নির্ধারণ করুন।",
        "Limit amount": "সীমার পরিমাণ",
        "More": "আরও",
        "Leader Board": "লিডার বোর্ড",
        "Signals": "সংকেত",
        "Notifications": "নোটিফিকেশনসমূহ",
        "mark all as read": "সবগুলো পঠিত বলে চিহ্নিত করুন",
        "No notifications": "নোটিফিকেশন নেই",
        "No more notifications": "আর নোটিফিকেশন নেই",
        "Your position:": "আপনার অবস্থান:",
        "How does this rating work?": "এই রেটিং কিভাবে কাজ করে?",
        "of the Day": "দিনের",
        "Nothing has been found for current query, try another asset name.": "বর্তমান প্রশ্নের জন্য কিছুই পাওয়া যায়নি, অন্য অ্যাসেট নাম চেষ্টা করুন।",
        "My rating in the Leader Board": "লিডার বোর্ডে আমার রেটিং",
        "Participants can only be those who trade in live mode": "অংশগ্রহণকারীরা শুধুমাত্র তারাই হতে পারে যারা লাইভ মোডে ট্রেড করে",
        "Your position depends on the amount of earned money": "আপনার অবস্থান উপার্জিত অর্থের পরিমাণের উপর নির্ভর করে",
        "Calculated every day, from 00:00 UTC to 23:59 UTC": "প্রতিদিন গণনা করা হয়, 00:00 UTC থেকে 23:59 UTC পর্যন্ত",
        "All traders on our platform participate in this rating. Main features of the rating:": "আমাদের প্ল্যাটফর্মের সমস্ত ব্যবসায়ীরা এই রেটিংয়ে অংশগ্রহণ করে। রেটিং এর প্রধান ফিচারসমূহ:",
        "How does it work?": "এটা কিভাবে কাজ করে?",
        "Trading signals": "ট্রেডিং সংকেত",
        "Past signals": "অতীত সংকেত",
        "What’s it?": "এটা কি?",
        "A trading signal is not a direct instruction to trade, but an analyst recommendation. It should not replace independent market analysis.": "একটি ট্রেডিং সংকেত ট্রেড করার জন্য সরাসরি নির্দেশ নয়, কিন্তু একটি বিশ্লেষক সুপারিশ। এটা স্বাধীন মার্কেট বিশ্লেষণ প্রতিস্থাপন করা উচিত নয়।",
        "You have an average of 10 minutes to take advantage of the trading signal. After this time, the quotes may change and have an unexpected effect on the trade result.": "ট্রেডিং সিগন্যালের সুবিধা নিতে আপনার কাছে গড়ে 10 মিনিট সময় আছে। এই সময়ের পরে, উদ্ধৃতিগুলি পরিবর্তিত হতে পারে এবং ট্রেডের ফলাফলের উপর অপ্রত্যাশিত প্রভাব ফেলতে পারে।",
        "Continue": "চালিয়ে যান",
        "Duration": "সময়কাল",
        "Return back to FAQ": "FAQ-এ ফিরে যান",
        "Return back to Help": "সাহায্যে ফিরে যান",
        "How to withdraw money from the account?": "কিভাবে একাউন্ট থেকে টাকা উত্তোলন করতে হয়?",
        "The procedure for withdrawing capital is extremely simple and is carried out through your individual account.": "মূলধন উত্তোলনের পদ্ধতিটি অত্যন্ত সহজ এবং আপনার ব্যক্তিগত অ্যাকাউন্টের মাধ্যমে করা হয়।",
        "The method that you have chosen to deposit the account is also a method of withdrawing funds (see the question \"How can I deposit?\").": "আপনি অ্যাকাউন্টে ডিপোজিট করার জন্য যে পদ্ধতিটি বেছে নিয়েছেন তাও তহবিল উত্তোলনের একটি পদ্ধতি (\"কিভাবে আমি ডিপোজিট করতে পারি?\" প্রশ্নটি দেখুন)।",
        "For example, if you made a deposit to your account via the Visa payment system, you will also withdraw money via the Visa payment system.": "উদাহরণস্বরূপ, আপনি যদি ভিসা পেমেন্ট সিস্টেমের মাধ্যমে আপনার অ্যাকাউন্টে একটি ডিপোজিট করেন, তাহলে আপনি ভিসা পেমেন্ট সিস্টেমের মাধ্যমেই অর্থ উত্তোলন করবেন।",
        "When it comes to the withdrawal of a sufficiently large amount, the Company may request verification (verification is requested at the Company's sole discretion), which is why it is very important to register an account individually for yourself in order to confirm your rights to it at any time.": "যখন বড় পরিমাণে অর্থ উত্তোলনের কথা আসে, তখন কোম্পানি যাচাইয়ের জন্য অনুরোধ করতে পারে (যাচাই কোম্পানির নিজস্ব বিবেচনার ভিত্তিতে অনুরোধ করা হয়), যার কারণে যেকোন সময় এটিতে আপনার অধিকার নিশ্চিত করার জন্য নিজের জন্য পৃথকভাবে একটি অ্যাকাউন্ট নিবন্ধন করা খুবই গুরুত্বপূর্ণ।",
        "What is the minimum withdrawal amount?": "সর্বনিম্ন উত্তোলনের পরিমাণ কত?",
        "The minimum withdrawal amount starts from %s USD for most payment systems.<br>For cryptocurrencies this amount starts from 50 USD (and may be higher for certain currencies e.g. Bitcoin).": "বেশিরভাগ পেমেন্ট সিস্টেমের জন্য ন্যূনতম উত্তোলনের পরিমাণ 10 USD থেকে শুরু হয়।<br>ক্রিপ্টোকারেন্সির জন্য এই পরিমাণ 50 USD থেকে শুরু হয় (এবং নির্দিষ্ট কিছু মুদ্রার যেমন বিটকয়েনের ক্ষেত্রে বেশি হতে পারে)।",
        "Do I need to provide any documents to make a withdrawal?": "টাকা উত্তোলনের জন্য আমাকে কি কোনো নথি প্রদান করতে হবে?",
        "Usually, additional documents to withdraw funds are not needed . But the Company at its discretion may ask you to confirm your personal data by requesting certain documents. Usually this is done in order to prevent activities related to illegal trade, financial fraud, as well as the use of funds obtained illegally.": "সাধারণত, তহবিল উত্তোলনের জন্য অতিরিক্ত কোন নথির প্রয়োজন হয় না। কিন্তু কোম্পানি তার বিবেচনার ভিত্তিতে আপনাকে কিছু নথির অনুরোধ করে আপনার ব্যক্তিগত ডেটা নিশ্চিত করতে বলতে পারে। সাধারণত অবৈধ ট্রেড, আর্থিক জালিয়াতি, সেইসাথে অবৈধভাবে প্রাপ্ত তহবিলের ব্যবহার রোধ করার জন্য এটি করা হয়।",
        "The list of such documents is minimum, and the operation to provide them will not take you much time and effort.": "এই জাতীয় নথিগুলির তালিকা খুবই কম, এবং সেগুলি সরবরাহ করার প্রক্রিয়া আপনাকে\n অনেক সময় এবং প্রচেষ্টা নেবে না।",
        "How long does it take to withdraw funds?": "তহবিল উত্তোলন করতে কতক্ষণ লাগে?",
        "On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.": "গড়ে, উত্তোলনের পদ্ধতিটি ক্লায়েন্টের সংশ্লিষ্ট অনুরোধ প্রাপ্তির তারিখ থেকে এক থেকে পাঁচ দিন পর্যন্ত সময় নেয় এবং শুধুমাত্র একই সাথে প্রক্রিয়াকৃত অনুরোধের পরিমাণের উপর নির্ভর করে। ক্লায়েন্টের কাছ থেকে অনুরোধ পাওয়ার দি্নেই কোম্পানি সর্বদা সরাসরি পেমেন্ট করার চেষ্টা করে।",
        "Is there any fee for depositing or withdrawing funds from the account?": "অ্যাকাউন্ট থেকে তহবিল ডিপোজিট বা উত্তোলনের জন্য কোন ফি আছে কি?",
        "No. The company does not charge any fee for either the deposit or for the withdrawal operations.": "না। কোম্পানী ডিপোজিট বা উত্তোলন ক্রিয়াকলাপের জন্য কোনো ফি চার্জ করে না।",
        "However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.": "যাইহোক, এটি বিবেচনা করা উচিত যে পেমেন্ট সিস্টেমগুলি তাদের ফি চার্জ করতে পারে এবং অভ্যন্তরীণ মুদ্রা রূপান্তর হার ব্যবহার করতে পারে।",
        "Can I close my account? How to do it?": "আমি কি আমার অ্যাকাউন্ট বন্ধ করতে পারি? এটা কিভাবে করতে হবে?",
        "You can delete an account in your Individual Account by clicking on the “Delete Account” button located at the bottom of the profile page.": "প্রোফাইল পৃষ্ঠার নীচে অবস্থিত \"অ্যাকাউন্ট মুছুন\" বোতামে ক্লিক করে আপনি আপনার ব্যক্তিগত অ্যাকাউন্টে একটি অ্যাকাউন্ট মুছে ফেলতে পারেন।",
        "If I made a mistake during entering data into my individual account, how can I fix this?": "আমার ব্যক্তিগত অ্যাকাউন্টে ডেটা প্রবেশ করার সময় আমি যদি কোন ভুল করে থাকি, তাহলে আমি কীভাবে এটি ঠিক করতে পারি?",
        "You have to contact the technical support service on the Company's website and edit the profile.": "আপনাকে কোম্পানির ওয়েবসাইটে প্রযুক্তিগত সহায়তা পরিষেবার সাথে যোগাযোগ করতে হবে এবং প্রোফাইল এডিট করতে হবে।",
        "What data is required to register on the Company website?": "কোম্পানির ওয়েবসাইটে নিবন্ধন করার জন্য কোন তথ্য প্রয়োজন?",
        "To earn money on digital options, you must first open an account that allows you to conduct trades. To do this, you need to register on the Company website.": "ডিজিটাল অপশনগুলিতে অর্থ উপার্জন করতে, আপনাকে প্রথমে একটি অ্যাকাউন্ট খুলতে হবে যা আপনাকে ট্রেড পরিচালনা করতে দেয়। এটি করার জন্য, আপনাকে কোম্পানির ওয়েবসাইটে নিবন্ধন করতে হবে।",
        "The registration process is simple and does not take much time.": "নিবন্ধন প্রক্রিয়া সহজ এবং খুব বেশি সময় নেয় না।",
        "It is necessary to fill out a questionnaire on the proposed form. You will be required to enter the following information:": "প্রস্তাবিত ফর্মে একটি প্রশ্নাবলী পূরণ করা প্রয়োজন। আপনাকে নিম্নলিখিত তথ্য প্রবেশ করতে হবে:",
        "name (in English)": "নাম (ইংরেজিতে)",
        "email address (indicate the current, work, address)": "ইমেইল ঠিকানা (বর্তমান, কাজ, ঠিকানা নির্দেশ করে)",
        "telephone (with a code, for example, + 44123 ....)": "টেলিফোন (কোড সহ, উদাহরণস্বরূপ, + 44123 ....)",
        "a password that you will use in future to enter the system (in order to minimize the risk of unauthorized access to your individual account, we recommend that you create a complex password using lowercase, uppercase letters and numbers. Do not disclose the password to third parties)": "একটি পাসওয়ার্ড যা আপনি ভবিষ্যতে সিস্টেমে প্রবেশ করতে ব্যবহার করবেন (আপনার ব্যক্তিগত অ্যাকাউন্টে অননুমোদিত অ্যাক্সেসের ঝুঁকি কমানোর জন্য, আমরা সুপারিশ করি যে আপনি ছোট হাতের, বড় হাতের অক্ষর এবং সংখ্যা ব্যবহার করে একটি জটিল পাসওয়ার্ড তৈরি করুন। তৃতীয় কোন পক্ষের কাছে পাসওয়ার্ড প্রকাশ করবেন না)",
        "After filling out the questionnaire, you will be offered various ways to  deposit an account for trading.": "প্রশ্নাবলী ফর্ম পূরণ করার পরে, আপনাকে ট্রেডিংয়ের জন্য একটি অ্যাকাউন্ট ডিপোজিট করার বিভিন্ন উপায় অফার করা হবে।",
        "In what currency is the Client's account opened? Can I change the currency of the Client's account?": "গ্রাহকের অ্যাকাউন্ট কোন মুদ্রায় খোলা হয়? আমি কি গ্রাহকের অ্যাকাউন্টের মুদ্রা পরিবর্তন করতে পারি?",
        "By default, a trading account is opened in US dollars. But for your convenience, you can open several accounts in different currencies.": "ডিফল্টরূপে, একটি ট্রেডিং অ্যাকাউন্ট US ডলারে খোলা হয়। কিন্তু আপনার সুবিধার জন্য, আপনি বিভিন্ন মুদ্রায় বেশ কয়েকটি অ্যাকাউন্ট খুলতে পারেন।",
        "A list of available currencies can be found on your profile page in your Client's account.": "উপলব্ধ মুদ্রার একটি তালিকা আপনার ক্লায়েন্টের অ্যাকাউন্টে আপনার প্রোফাইল পেজে পাওয়া যাবে।",
        "What are digital options?": "ডিজিটাল অপশনগুলো কি?",
        "Option -  a derivative financial instrument based on any underlying asset (it can be a stock, currency pairs, oil, etc.).": "অপশন - যেকোন অন্তর্নিহিত অ্যাসেটের উপর ভিত্তি করে একটি ডেরিভেটিভ আর্থিক উপকরণ (এটি একটি স্টক, মুদ্রা জোড়া, তেল ইত্যাদি হতে পারে)।",
        "DIGITAL OPTION - a non-standard option that is used to make a profit on price movements of such assets for a certain period of time.": "ডিজিটাল অপশন - একটি নন-স্ট্যান্ডার্ড বিকল্প যা একটি নির্দিষ্ট সময়ের জন্য এই জাতীয় অ্যাসেটের দামের গতিবিধিতে লাভ করতে ব্যবহৃত হয়।",
        "A digital option, depending on the terms agreed upon by the parties to the transaction, at a time determined by the parties, brings a fixed income (the difference between the trade income and the price of the asset) or loss (in the amount of the value of the asset).": "একটি ডিজিটাল অপশন, পক্ষগুলির দ্বারা নির্ধারিত সময়ে, লেনদেনের জন্য পক্ষগুলির দ্বারা সম্মত শর্তগুলির উপর নির্ভর করে, একটি নির্দিষ্ট আয় (ট্রেডের আয় এবং অ্যাসেটের মূল্যের মধ্যে পার্থক্য) বা ক্ষতি (অ্যাসেটের মূল্যের পরিমাণে) নিয়ে আসে।",
        "Since the digital option is purchased in advance at a fixed price, the size of the profit, as well as the size of the potential loss, are known even before the trade.": "যেহেতু ডিজিটাল অপশন একটি নির্দিষ্ট মূল্যে অগ্রিম ক্রয় করা হয়, তাই লাভের আকার, সেইসাথে সম্ভাব্য ক্ষতির আকার, ট্রেডের আগেও জানা যায়।",
        "Another feature of these deals is the time limit. Any option has its own term (expiration time or conclusion time).": "এই ডিলের আরেকটি ফিচার হল সময়সীমা। যে কোনো অপশনের একটি নিজস্ব মেয়াদ আছে (মেয়াদ শেষ হওয়ার সময় বা সমাপ্তির সময়)।",
        "Regardless of the degree of change in the price of the underlying asset (how much it has become higher or lower), in case of winning an option, a fixed payment is always made. Therefore, your risks are limited only by the amount for which the option is acquired.": "অন্তর্নিহিত অ্যাসেটের মূল্যের পরিবর্তনের মাত্রা নির্বিশেষে (এটি কত বেশি বা কম হয়েছে), একটি অপশন জেতার ক্ষেত্রে, একটি নির্দিষ্ট অর্থ প্রদান করা হয়। অতএব, আপনার ঝুঁকি শুধুমাত্র সেই পরিমাণ দ্বারা সীমাবদ্ধ যে অপশনটি অর্জিত হয়েছে।",
        "What is the expiration period of a trade?": "একটি ট্রেডের মেয়াদ শেষ হওয়ার সময়কাল কত?",
        "The expiration period is the time after which the trade will be considered completed (closed) and the result is automatically summed up.": "মেয়াদ শেষ হওয়ার সময়কাল হল সেই সময় যার পরে ট্রেডটি সম্পন্ন (বন্ধ) বলে বিবেচিত হবে এবং ফলাফল স্বয়ংক্রিয়ভাবে সংক্ষিপ্ত হবে।",
        "When concluding a trade with digital options, you independently determine the time of execution of the transaction (1 minute, 2 hours, month, etc.).": "ডিজিটাল অপশনগুলির সাথে একটি ট্রেড শেষ করার সময়, আপনি স্বাধীনভাবে লেনদেন সম্পাদনের সময় (1 মিনিট, 2 ঘন্টা, মাস, ইত্যাদি) নির্ধারণ করেন।",
        "What is the gist of digital options trading?": "ডিজিটাল অপশন ট্রেডিং এর সারমর্ম কি?",
        "The fact is that a digital option is the simplest type of derivative financial instrument. In order to make money in the digital options market, you do not need to predict the value of the market price of an asset that it can reach.": "আসল বিষয়টি হল একটি ডিজিটাল অ্যাসেট হল সবচেয়ে সহজ ধরনের ডেরিভেটিভ আর্থিক উপকরণ। ডিজিটাল অপশন মার্কেটে অর্থ উপার্জন করার জন্য, আপনাকে একটি অ্যাসেটের বাজার মূল্যের মূল্য ভবিষ্যদ্বাণী করতে হবে না যেখানে এটি পৌঁছাতে পারে।",
        "The principle of the trading process is reduced only to the solution of one single task - the price of an asset will increase or decrease by the time the contract is executed.": "ট্রেডিং প্রক্রিয়ার নীতিটি শুধুমাত্র একটি একক কাজের সমাধানের জন্য হ্রাস করা হয় - চুক্তিটি কার্যকর হওয়ার সময় একটি অ্যাসেটের দাম বাড়বে বা কমবে।",
        "The aspect of such options is that it does not matter to you at all, that the price of the underlying asset will go one hundred points or only one, from the moment the trade is concluded to its close. It is important for you to determine only the direction of movement of this price.": "এই ধরনের অপশনগুলির বিশেষ দিকটি হল যে এটি আপনার কাছে মোটেই বিবেচ্য নয় যে অন্তর্নিহিত অ্যাসেটের মূল্য একশ পয়েন্ট নাকি শুধুমাত্র একটি হবে, যে মুহূর্ত থেকে ট্রেড শেষ হওয়ার মুহুর্ত থেকে। শুধুমাত্র এই মূল্যের গতিবিধি নির্ধারণ করা আপনার জন্য গুরুত্বপূর্ণ।",
        "If your prognosis is correct, in any case you get a fixed income.": "আপনার পূর্বাভাস সঠিক হলে, যে কোনও ক্ষেত্রে আপনি একটি নির্দিষ্ট আয় পাবেন।",
        "What are the possible results of the placed trades?": "স্থাপন করা ট্রেডের সম্ভাব্য ফলাফল কি?",
        "There are three possible outcomes in the digital options market:": "ডিজিটাল অপশন মার্কেটে তিনটি সম্ভাব্য ফলাফল আছে:",
        "in the event that your prognosis of determining the direction of the price movement of the underlying asset is correct, you receive income.": "অন্তর্নিহিত অ্যাসেটের দাম ওঠানামার দিক নির্ণয় করার আপনার পূর্বাভাস সঠিক হলে, আপনি আয় পাবেন।",
        "if by the time the option was concluded your forecast turned out to be erroneous, you incur a loss limited by the size of the asset value (i.e., in fact, you can only lose your investment).": "যদি অপশনটি শেষ হওয়ার সময় আপনার পূর্বাভাস ভুল বলে প্রমাণিত হয়, তাহলে আপনি অ্যাসেট মূল্যের আকার দ্বারা সীমিত ক্ষতির সম্মুখীন হবেন যেমন, এমনকি, আপনি শুধুমাত্র আপনার বিনিয়োগ হারাতে পারেন)।",
        "if the outcome of the trade is zero (the price of the underlying asset has not changed, the option is concluded at the price at which it was purchased), you return your investment.Thus, the level of your risk is always limited only by the size of the asset value.": "যদি ট্রেডের ফলাফল শূন্য হয় (অন্তর্নিহিত অ্যাসেটের মূল্য পরিবর্তিত হয় নি, অপশনটি যে মূল্যে এটি কেনা হয়েছিল সেই মূল্যে সমাপ্ত হয়), আপনি আপনার বিনিয়োগ ফেরত পাবেন। সুতরাং, আপনার ঝুঁকির মাত্রা সর্বদা শুধুমাত্র অ্যাসেটের মূল্যের আকার দ্বারা সীমাবদ্ধ থাকে।",
        "Does your trading platform have a demo account in order to understand the process of working with digital options without spending your own money?": "আপনার নিজস্ব অর্থ ব্যয় না করে ডিজিটাল অপশনগুলির সাথে কাজ করার প্রক্রিয়াটি বোঝার জন্য আপনার ট্রেডিং প্ল্যাটফর্মের একটি ডেমো অ্যাকাউন্ট আছে?",
        "Yes. In order to develop trading skills and test the capabilities of the Company's trading platform, you can use a demo account (free of charge). This is a kind of simulator that allows you to try first, and only then move on to real trading. Such a demo account is also suitable for experienced traders to improve their professional level.": "হ্যাঁ। ট্রেডিং দক্ষতা বিকাশ করতে এবং কোম্পানির ট্রেডিং প্ল্যাটফর্মের ক্ষমতা পরীক্ষা করার জন্য, আপনি একটি ডেমো অ্যাকাউন্ট ব্যবহার করতে পারেন (বিনামূল্যে)। এটি এমন এক ধরণের সিমুলেটর যা আপনাকে প্রথমে চেষ্টা করতে দেয় এবং শুধুমাত্র তারপরই আসল ট্রেডিংয়ে যেতে দেয়। এই ধরনের একটি ডেমো অ্যাকাউন্ট অভিজ্ঞ ট্রেডারদের জন্য তাদের পেশাদার স্তর উন্নত করার জন্য উপযুক্ত।",
        "The balance of such an account is 10,000 units.": "এই ধরনের অ্যাকাউন্টের ব্যালেন্স 10,000 ইউনিট।",
        "What determines profit size?": "কি লাভের আকার নির্ধারণ করে?",
        "There are several factors that affect the size of your profit:": "আপনার লাভের আকারকে প্রভাবিত করে এমন কয়েকটি কারণ রয়েছে:",
        "the liquidity of the asset you have chosen in the market (the more the asset is in demand in the market, the more profit you will receive)": "আপনি মার্কেটে যে অ্যাসেটটি বেছে নিয়েছেন তার তারল্য (মার্কেটে অ্যাসেটের চাহিদা যত বেশি, আপনি তত বেশি লাভ পাবেন)",
        "the time of the trade (the liquidity of an asset in the morning and the liquidity of an asset in the afternoon can vary significantly)": "ট্রেডের সময় (সকালে একটি অ্যাসেটের তারল্য এবং বিকেলে একটি অ্যাসেটের তারল্য উল্লেখযোগ্যভাবে পরিবর্তিত হতে পারে)",
        "tariffs of a brokerage company": "একটি ব্রোকারেজ কোম্পানির শুল্ক",
        "changes in the market (economic events, changes in part of a financial asset, etc.)": "মার্কেটে পরিবর্তন (অর্থনৈতিক ইভেন্ট, একটি আর্থিক অ্যাসেটের অংশে পরিবর্তন, ইত্যাদি)",
        "What are the varieties of digital options?": "ডিজিটাল অপশনের বৈচিত্র্য কি?",
        "Making an option trade, you must choose the underlying asset that will underlie the option. Your forecast will be carried out on this asset.": "একটি অপশন ট্রেড করার জন্য, আপনাকে অবশ্যই অন্তর্নিহিত অ্যাসেটটি বেছে নিতে হবে যা অপশনটিকে অন্তর্নিহিত করবে। আপনার পূর্বাভাস এই অ্যাসেটের উপর বাহিত হবে।",
        "Simply, buying a digital contract, you are actually betting on the price movement of such an underlying asset.": "সহজভাবে, একটি ডিজিটাল চুক্তি কেনা, আপনি আসলে এই ধরনের একটি অন্তর্নিহিত অ্যাসেটের মূল্য আন্দোলনের উপর বাজি ধরছেন।",
        "An underlying asset is an “item” whose price is taken into account when concluding a trade.As the underlying asset of digital options, the most sought-after products on the markets usually act. There are four types of them:": "একটি অন্তর্নিহিত অ্যাসেট হল একটি \"আইটেম\" যার মূল্য একটি ট্রেড শেষ করার সময় বিবেচনায় নেওয়া হয়৷ ডিজিটাল অপশনগুলির অন্তর্নিহিত অ্যাসেট হিসাবে, বাজারে সর্বাধিক চাওয়া-পাওয়া পণ্যগুলি সাধারণত কাজ করে৷ তাদের চার ধরনের আছে:",
        "securities (shares of world companies)": "নিরাপত্তা (আন্তর্জাতিক কোম্পানির শেয়ার)",
        "currency pairs (EUR / USD, GBP / USD, etc.)": "মুদ্রার সংগ্রহ (EUR/USD, GBP/USD, ইত্যাদি)",
        "raw materials and precious metals (oil, gold, etc.)": "কাঁচামাল এবং মূল্যবান ধাতু (তেল, সোনা, ইত্যাদি)",
        "indices (S&P 500, Dow, dollar index, etc.)": "সূচক (S&P 500, ডো, ডলার সূচক, ইত্যাদি)",
        "A universal underlying asset does not exist. Сhoosing  it you are guided only by your own knowledge, intuition and various kinds of analytical information, as well as market analysis for a particular financial instrument.": "একটি সর্বজনীন অন্তর্নিহিত অ্যাসে্টের অস্তিত্ব নেই। এটি বেছে নেওয়ার জন্য আপনি শুধুমাত্র আপনার নিজস্ব জ্ঞান, অন্তর্দৃষ্টি এবং বিভিন্ন ধরণের বিশ্লেষণাত্মক তথ্য, সেইসাথে একটি নির্দিষ্ট আর্থিক উপকরণের বাজার বিশ্লেষণ দ্বারা পরিচালিত হন।",
        "What is a trading platform and why is it needed?": "একটি ট্রেডিং প্ল্যাটফর্ম কি এবং কেন এটি প্রয়োজন?",
        "Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.": "ট্রেডিং প্ল্যাটফর্ম - একটি সফটওয়্যার কমপ্লেক্স যা ক্লায়েন্টকে বিভিন্ন আর্থিক উপকরণ ব্যবহার করে ট্রেড (অপারেশন) পরিচালনা করতে দেয়। এটি বিভিন্ন তথ্য যেমন কোটেশনের মান, রিয়েল-টাইম মার্কেট পজিশন, কোম্পানির ক্রিয়াকলাপ ইত্যাদিতেও অ্যাক্সেস করে।",
        "How to learn quickly how to make money in the digital options market?": "কিভাবে দ্রুত শিখবেন কিভাবে ডিজিটাল অপশন মার্কেটে অর্থ উপার্জন করতে হয়?",
        "develop your own trading strategies, in which the number of correctly predicted trades will be maximum, and follow them": "আপনার নিজস্ব ট্রেডিং কৌশল বিকাশ করুন, যাতে সঠিকভাবে ভবিষ্যদ্বাণী করা ট্রেডের সংখ্যা সর্বাধিক হবে এবং সেগুলি অনুসরণ করুন",
        "diversify your risks": "আপনার ঝুঁকি বিচিত্র করুন",
        "In developing strategies, as well as in searching for diversification options, market monitoring, studying analytical and statistical information that can be obtained from various sources (Internet resources, expert opinions, analysts in this field, etc.) will help you, one of which is the Company website.": "কৌশলগুলি বিকাশের পাশাপাশি বৈচিত্র্যের অপশনগুলি অনুসন্ধানে, বাজার পর্যবেক্ষণে, বিশ্লেষণাত্মক এবং পরিসংখ্যানগত তথ্য অধ্যয়ন করা যা বিভিন্ন উৎস থেকে পাওয়া যেতে পারে (ইন্টারনেট রিসোর্স, বিশেষজ্ঞের মতামত, এই ক্ষেত্রের বিশ্লেষক ইত্যাদি) যা আপনাকে সাহায্য করবে, যার মধ্যে একটি কোম্পানির ওয়েবসাইট।",
        "Is the download of the program to a computer or smartphone required?": "একটি কম্পিউটার বা স্মার্টফোনে প্রোগ্রামটি কি ডাউনলোড করা প্রয়োজন?",
        "No, it's not required. You just need to register on the Company's website in the presented form and open a individual account.": "না, এটার প্রয়োজন নেই। আপনাকে শুধুমাত্র উপস্থাপিত ফর্মে কোম্পানির ওয়েবসাইটে নিবন্ধন করতে হবে এবং একটি আলাদা খুলতে হবে।",
        "At what expense does the Company pay profit to the Client in case of successful trade?": "সফল ট্রেডের ক্ষেত্রে কোম্পানি কোন খরচে ক্লায়েন্টকে মুনাফা দেয়?",
        "Company earns with customers. Therefore, it is interested in the share of profitable transactions significantly prevailing over the share of unprofitable ones, due to the fact that the Company has a percentage of payments for a successful trading strategy chosen by the Client.": "কোম্পানি গ্রাহকদের মাধ্যমে উপার্জন করে। অতএব, এটি লাভজনক লেনদেনের শেয়ারে আগ্রহী যেটি অলাভজনক শেয়ারের উপর উল্লেখযোগ্যভাবে বিরাজ করছে, কারণ ক্লায়েন্ট দ্বারা নির্বাচিত একটি সফল ট্রেডিং কৌশলের জন্য কোম্পানির পেমেন্টের শতাংশ রয়েছে।",
        "In addition, trades conducted by the Client together constitute the trading volume of the Company, which is transferred to a broker or exchange, which in turn are included in the pool of liquidity providers, which together leads to an increase in the liquidity of the market itself.": "উপরন্তু, ক্লায়েন্ট দ্বারা পরিচালিত ট্রেডগুলি একসাথে কোম্পানির ট্রেডিং ভলিউম গঠন করে, যা একটি ব্রোকার বা এক্সচেঞ্জে স্থানান্তরিত হয়, যা তারল্য সরবরাহকারীদের পুলে অন্তর্ভুক্ত করা হয়, যা একসাথে নিজেই বাজারের তারল্য বৃদ্ধির দিকে পরিচালিত করে।",
        "What is account verification?": "অ্যাকাউন্ট যাচাইকরণ কি?",
        "Verification in digital options is a confirmation by the Client of his personal data by providing the Company with additional documents. Verification conditions for the Client are as simple as possible, and the list of documents is minimum. For example, a Company may ask:": "ডিজিটাল বিকল্পগুলিতে যাচাইকরণ হল কোম্পানিকে অতিরিক্ত নথি প্রদান করে তার ব্যক্তিগত ডেটার গ্রাহকের দ্বারা একটি নিশ্চিতকরণ। ক্লায়েন্টের জন্য যাচাইকরণের শর্তগুলি যতটা সম্ভব সহজ, এবং নথিগুলির তালিকা সর্বনিম্ন। উদাহরণস্বরূপ, একটি কোম্পানি জিজ্ঞাসা করতে পারে:",
        "provide a color scan copy of the first spread of the Client's passport (passport page with photo)": "ক্লায়েন্টের পাসপোর্টের প্রথম স্প্রেডের একটি রঙিন স্ক্যান কপি প্রদান করুন (ছবি সহ পাসপোর্ট পৃষ্ঠা)",
        "identify with the help of a \"selfie\" (photograph of himself)": "একটি \"সেলফি\" এর সাহায্যে সনাক্ত করুন (নিজের ছবি)",
        "confirm the address of registration (residence) of the Client, etc": "ক্লায়েন্টের নিবন্ধন (বাসস্থান) ঠিকানা নিশ্চিত করুন, ইত্যাদি",
        "The Company may request any documents if it is not possible to fully identify the Client and the data entered by him.": "ক্লায়েন্ট এবং তার দ্বারা প্রবেশ করা ডেটা সম্পূর্ণরূপে সনাক্ত করা সম্ভব না হলে কোম্পানি যেকোনো নথির অনুরোধ করতে পারে।",
        "After the electronic copies of documents have been submitted to the Company, the Client will have to wait some time to verify the data provided.": "নথিগুলির ইলেকট্রনিক কপিগুলি কোম্পানিতে জমা দেওয়ার পরে, প্রদত্ত ডেটা যাচাই করার জন্য ক্লায়েন্টকে কিছু সময় অপেক্ষা করতে হবে।",
        "How to understand that I need to go through account verification?": "কিভাবে বুঝবেন যে আমার অ্যাকাউন্ট ভেরিফিকেশনের মাধ্যমে যেতে হবে?",
        "If it becomes necessary to pass verification, you will receive a notification by e-mail and / or SMS notification.": "যাচাইকরণ পাস করার প্রয়োজন হলে, আপনি ই-মেইল এবং/অথবা এসএমএস বিজ্ঞপ্তির মাধ্যমে একটি বিজ্ঞপ্তি পাবেন।",
        "At the same time, the Company uses the contact details that you specified in the questionnaire during registration (in particular, the email address  and the phone number ). Therefore, be careful and provide relevant and correct information.": "একই সময়ে, কোম্পানি নিবন্ধনের সময় আপনার প্রশ্নাবলীতে উল্লেখ করা যোগাযোগের বিবরণ ব্যবহার করে (বিশেষত, ইমেইল ঠিকানা এবং ফোন নম্বর)। অতএব, সতর্ক থাকুন এবং প্রাসঙ্গিক এবং সঠিক তথ্য প্রদান করুন।",
        "How do I know that I successfully passed verification?": "আমি কিভাবে জানব যে আমি সফলভাবে যাচাইকরণ পাস করেছি?",
        "You will receive a notification by e-mail and / or SMS notification about the completion of the verification process of your account and the ability to proceed with operations on the Company's trading platform.": "আপনি আপনার অ্যাকাউন্টের যাচাইকরণ প্রক্রিয়া এবং কোম্পানির ট্রেডিং প্ল্যাটফর্মে ক্রিয়াকলাপগুলি চালিয়ে যাওয়ার ক্ষমতা সম্পর্কে ই-মেইল এবং/ অথবা এসএমএস নোটিফিকেশনের মাধ্যমে একটি বিজ্ঞপ্তি পাবেন।",
        "Is it possible to indicate other people's (fake) data when registering on the website?": "ওয়েবসাইটে নিবন্ধন করার সময় কি অন্য লোকের (জাল) তথ্য নির্দেশ করা সম্ভব?",
        "No. The client performs self-registration on the Company's website, providing complete and accurate information about himself on issues asked in the registration form, and maintains this information up to date.": "না। ক্লায়েন্ট কোম্পানির ওয়েবসাইটে স্ব-নিবন্ধন করে, নিবন্ধন ফর্মে জিজ্ঞাসা করা সমস্যাগুলির বিষয়ে নিজের সম্পর্কে সম্পূর্ণ এবং সঠিক তথ্য প্রদান করে এবং এই তথ্য আপ টু ডেট রাখে।",
        "If it is necessary to conduct various kinds of checks of the Client’s identity, the Company can request documents or invite the Client to its office.": "ক্লায়েন্টের পরিচয়ের বিভিন্ন ধরণের চেক পরিচালনা করার প্রয়োজন হলে, কোম্পানি নথির জন্য অনুরোধ করতে পারে বা গ্রাহককে তার অফিসে আমন্ত্রণ জানাতে পারে।",
        "If the data entered in the registration fields does not match the data of the submitted documents, your individual profile may be blocked.": "নিবন্ধনের ঘরগুলিতে প্রবেশ করা ডেটা যদি জমা দেওয়া নথিগুলির ডেটার সাথে মেলে না, তবে আপনার ব্যক্তিগত প্রোফাইল ব্লক করা হতে পারে।",
        "How long does the verification process take?": "যাচাইকরণ প্রক্রিয়ায় কতো সময় নেয়?",
        "No more than 5 (five) business days from the date the Company receives the requested documents.": "কোম্পানির অনুরোধকৃত নথি প্রাপ্ত হওয়ার তারিখ থেকে 5 (পাঁচ) ব্যবসায়িক দিনের বেশি নয়।",
        "Is there a minimum amount that I can deposit to my account at registration?": "নিবন্ধনের সময় আমি কি আমার অ্যাকাউন্টে ন্যূনতম পরিমাণ ডিপোজি্টের কোন লিমিট আছে?",
        "The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is %s US dollars.": "কোম্পানির ট্রেডিং প্ল্যাটফর্মের সুবিধা হল যে আপনাকে আপনার অ্যাকাউন্টে বেশি পরিমাণে ডিপোজিট করতে হবে না। আপনি অল্প পরিমাণ অর্থ বিনিয়োগ করে ট্রেড শুরু করতে পারেন। ন্যূনতম ডিপোজিট হল 10 মার্কিন ডলার।",
        "How can I deposit?": "আমি কিভাবে ডিপোজিট করতে পারি?",
        "It is very easy to do. The procedure will take a couple of minutes.": "এটা করা খুবই সহজ। পদ্ধতিটি কয়েক মিনিট সময় নেবে।",
        "Open the trade execution window and click on the green \"Deposit\" button in the upper right corner of the tab.": "ট্রেড এক্সিকিউশন উইন্ডো খুলুন এবং ট্যাবের উপরের ডানদিকে সবুজ \"ডিপোজিট\" বোতামে ক্লিক করুন।",
        "You can also deposit the account through your Personal Account by clicking the \"Deposit\" button in the account profile.": "এছাড়াও আপনি অ্যাকাউন্ট প্রোফাইলে \"ডিপোজিট\" বোতামে ক্লিক করে আপনার ব্যক্তিগত অ্যাকাউন্টের মাধ্যমে অ্যাকাউন্টটি ডিপোজিট করতে পারেন।",
        "After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).": "পরে অ্যাকাউন্টটি ডিপোজিট করার একটি পদ্ধতি বেছে নেওয়ার প্রয়োজন হয় (কোম্পানি অনেক সুবিধাজনক পদ্ধতি অফার করে যা ক্লায়েন্টের জন্য উপলব্ধ আছে এবং তার ব্যক্তিগত অ্যাকাউন্টে প্রদর্শিত হয়)।",
        "Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.": "এর পরে, অ্যাকাউন্টটি যে মুদ্রায় ডিপোজিট করা হবে তা নির্দেশ করুন এবং সেই অনুযায়ী অ্যাকাউন্টের মুদ্রা।",
        "Enter the amount of the deposit.": "ডিপোজিটের পরিমাণ লিখুন।",
        "Fill out the form by entering the requested payment details.": "অনুরোধকৃত পেমেন্টের বিশদ প্রবেশ করে ফর্মটি পূরণ করুন।",
        "Make a payment.": "একটি পেমেন্ট করুন।",
        "Do I need to deposit the account of the trading platform and how often do I need to do this?": "আমাকে কি ট্রেডিং প্ল্যাটফর্মের অ্যাকাউন্টে ডিপোজিট করতে হবে এবং কত ঘন ঘন এটি করতে হবে?",
        "To work with digital options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.": "ডিজিটাল অপশনগুলির সাথে কাজ করার জন্য আপনাকে একটি নিজস্ব অ্যাকাউন্ট খুলতে হবে। সত্যিকারের লেনদেন শেষ করার জন্য, আপনাকে অবশ্যই ক্রয়কৃত বিকল্পের পরিমাণে একটি ডিপোজিট করতে হবে।",
        "You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring digital options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.": "শুধুমাত্র কোম্পানির প্রশিক্ষণ অ্যাকাউন্ট (ডেমো অ্যাকাউন্ট) ব্যবহার করে আপনি নগদ অর্থ ছাড়াই ট্রেড শুরু করতে পারেন। এই ধরনের একটি অ্যাকাউন্ট বিনামূল্যে এবং ট্রেডিং প্ল্যাটফর্মের কার্যকারিতা প্রদর্শনের জন্য তৈরি করা হয়। এই ধরনের একটি অ্যাকাউন্টের সাহায্যে, আপনি ডিজিটাল বিকল্পগুলি অর্জনের অনুশীলন করতে পারেন, ট্রেডিংয়ের মূল নীতিগুলি বুঝতে পারেন, বিভিন্ন পদ্ধতি এবং কৌশল পরীক্ষা করতে পারেন বা আপনার অন্তর্দৃষ্টির স্তর মূল্যায়ন করতে পারেন।",
        "What is the minimum deposit amount?": "ন্যূনতম ডিপোজিটের পরিমাণ কত?",
        "{number} questions": "{number}টি প্রশ্ন",
        "Verification": "যাচাইকরণ",
        "Payment": "পেমেন্ট",
        "Payouts": "পেআউট",
        "Ask a specialist": "একজন বিশেষজ্ঞকে জিজ্ঞাসা করুন",
        "FAQ": "সচরাচর জিজ্ঞাস্য",
        "Didn't find an answer to your question?": "আপনার প্রশ্নের উত্তর খুঁজে পাননি?",
        "Open knowledge base": "উন্মুক্ত জ্ঞানের ভিত্তি",
        "Contact support": "যোগাযোগ সহায়তা",
        "Open FAQ page": "FAQ পেজ খুলুন",
        "New message": "নতুন বার্তা",
        "Tournaments": "টুর্নামেন্ট",
        "To end": "শেষ করতে",
        "Until start": "শুরুর আগ পর্যন্ত",
        "Front side": "সামনের দিকে",
        "Back side (if any)": "পিছনের অংশ (যদি থাকে)",
        "Tourna-ments": "টুর্নামেন্টস",
        "Deletion of Account and Personal Data": "অ্যাকাউন্ট বা ব্যক্তিগত ডেটা ডিলেট করা",
        "By deleting your account and personal data, you will lose access to your account on the Quotex platform permanently. Remember that your data will be deleted irretrievably and it is impossible to restore your account later!": "আপনার অ্যাকাউন্ট বা ব্যক্তিগত ডেটা ডিলেট করলে, আপনি Quotex এ আপনার অ্যাকাউন্টের অ্যাক্সেস হারাবেন। মনে রাখবেন যে, আপনার তথ্য ডিলেট হলেও অপরিবর্তনীয় থাকবে এবং আপনার অ্যাকাউন্ট পরবর্তীতে ফিরিয়ে আনা সম্ভব!",
        "NOTE: Please complete all open trades and pending orders before you delete your account.": "বিঃদ্রঃ অ্যাকাউন্ট ডিলেট করার আগে অনুগ্রহ করে সকল চালু ট্রেড এবং পেন্ডিং অর্ডার সম্পন্ন করুন।",
        "Request Deletion": "ডিলেটের জন্য অনুরোধ করুন",
        "To delete your account, please contact support service": "আপনার অ্যাকাউন্ট ডিলেট করতে অনুগ্রহ করে সহায়তা পরিষেবায় যোগাযোগ করুন",
        "Two-factor authorization via Email": "ইমেইলের মাধ্যমে দ্বি-স্তরবিশিষ্ট যাচাইকরণ",
        "This feature is for experienced users who are familiar with Google Authenticator.": "এই ফিচারটি অভিজ্ঞ ব্যবহারকারীদের জন্য যারা গুগল অথেন্টিকেটরের সাথে পরিচিত।",
        "Please note!": "অনুগ্রহ করে নোট করুন!",
        "If you lose access to Google Authenticator for any reason after connecting it, you will lose access to your account.": "আপনি যদি এটি সংযোগ করার পরে কোনো কারণে গুগল অথেন্টিকেটরে অ্যাক্সেস হারান তবে আপনি আপনার অ্যাকাউন্টে অ্যাক্সেস হারাবেন।",
        "Proceed to setup": "সেটআপ করতে এগিয়ে যান",
        "Account protection method": "অ্যাকাউন্ট সুরক্ষা পদ্ধতি",
        "Here's how to set it up:": "এটি কীভাবে সেট আপ করবেন তা এখানে পাবেন:",
        "Please enter the PIN-code we've just sent to your email:": "আমরা এইমাত্র আপনার ইমেইলে যে পিন-কোডটি পাঠিয়েছি দয়া করে সেটি লিখুন:",
        "Nice work. You've successfully enabled 2-step verification via Google Authenticator. The next time you sign in, you'll be prompted for a code.": "চমৎকার করেছেন। আপনি গুগল অথেন্টিকেটরের মাধ্যমে সফলভাবে ২-স্তরবিশিষ্ট যাচাইকরণ সক্ষম করেছে। পরের বার আপনি সাইন ইন করার সময়, আপনাকে একটি কোডের জন্য অনুরোধ করা হবে।",
        "If you ever lose access to your 2-step verification device, please": "আপনি যদি কখনও আপনার ২-স্তরবিশিষ্ট যাচাইকরণ ডিভাইসে অ্যাক্সেস হারান, অনুগ্রহ করে",
        "Confirm account deletion": "অ্যাকাউন্ট মুছে ফেলা নিশ্চিত করুন",
        "To delete your account, follow the link in the email sent to": "আপনার অ্যাকাউন্ট মুছে ফেলার জন্য, আপনাকে পাঠানো ইমেইল লিঙ্ক অনুসরণ করুন",
        "Image requirements": "ছবির আবশ্যকতা",
        "Email": "ইমেইল",
        "Сonfirmation": "নিশ্চিতকরণ",
        "Change protection method": "সুরক্ষা পদ্ধতি পরিবর্তন করুন",
        "First, enter the code from the Google Authenticator app:": "প্রথমে, গুগল অথেন্টিকেটর অ্যাপ থেকে কোডটি লিখুন:",
        "First, enter the PIN-code we've just sent to your email:": "প্রথমে, আমরা এইমাত্র আপনার ইমেইলে যে পিন-কোডটি পাঠিয়েছি তা লিখুন:",
        "Nice work. You've successfully enabled 2-step verification via Email.": "চমৎকার করেছেন। আপনি সফলভাবে ইমেইলের মাধ্যমে ২-স্তরবিশিষ্ট যাচাইকরণ সক্ষম করেছেন।",
        "Receiving codes via Email": "ইমেইলের মাধ্যমে কোড গ্রহণ করা হচ্ছে",
        "Receiving codes via Google Authenticator": "গুগল অথেন্টিকেটরের মাধ্যমে কোড গ্রহণ করা হচ্ছে",
        "Choose a method:": "একটি পদ্ধতি চয়ন করুন:",
        "To enter the platform": "প্লাটফর্মে প্রবেশ করতে",
        "To withdraw funds": "তহবিল উত্তোলন করতে",
        "day": "দিন",
        "days2": "দিন2",
        "days5": "দিন5",


text: 'মুদ্রা নির্বাচন',
text1: 'ইমেল',
text2: 'লেনদেন বাতিল করুন',
text3: 'বিনামূল্যে প্রোমো কোড',
text4: 'আমরা প্ল্যাটফর্মটি প্রতিদিন উন্নত করছি যাতে এটি আরও সুবিধাজনক হয়।',
text5: 'আপনি কি এই প্ল্যাটফর্মটি পছন্দ করেন? অন্যকে পরিচিতি করুন!',
text6: 'অনুগ্রহ করে আপনার QUOTEX সম্পর্কে মন্তব্য দিন, আপনি বিনামূল্যে লেনদেন বাতিলের জন্য পুরস্কার পাবেন।',
text7: 'আমাদের কাস্টমার সাপোর্ট টিমের জন্য আপনার মন্তব্যের লিঙ্ক প্রেরণ করুন, তার পরে আমরা আপনাকে এই পুরস্কার প্রেরণ করব।',
text8: 'ন্যূনতম আমান',
text9: 'ন্যূনতম উত্তোলন পরিমাণ',
text10: 'দ্রুত উত্তোলন',
text11: 'বিনামূল্যে',
text12: 'যাচাই করা না',
text13: 'পুনরায় পাঠান',
text14: 'আপনার অংশগ্রহণ নিশ্চিত করুন',
text15: 'লেনদেন বাতিল করুন',
text16: 'আপনার প্রচারণা কোড দ্রুততমভাবে লিখুন এবং সক্রিয় করুন। আপনি যে কোন সময় প্রচারণা কোড ব্যবহার করতে পারেন।',
text17: 'প্রচারণা কোড:',
text18: 'আপনার আমানসূচী অনুযায়ী 30% বোনাস পান',
text19: 'বর্তমান মুদ্রা',
text20: 'উত্তোলন করার জন্য উপলব্ধ পরিমাণ',
text21: 'অ্যাকাউন্টে',
text22: 'আজ',
text23: 'গতকাল',
text24: 'সপ্তাহ',
text25: 'মাস',
text26: 'নীট লেনদেন পরিমাণ',
text27: `WELCOME হ'ו'ল প্রথম আমানের 30% বোনাস।`,
text28: 'প্রথম আমান $100। 30% বোনাস পেতে আমান পছন্দ করুন।',
text29: 'ব্যাংক কার্ড',
text30: 'ব্যাংক',
text31: 'নির্বাচিত পরিশোধের পদ্ধতি:',
text32: 'ন্যূনতম পরিমাণ:',
text33: 'সর্বোচ্চ পরিমাণ:',
text34: 'অন্য একটি পদ্ধতি নির্বাচন করুন',
text35: 'পরিশোধের তথ্য',
text36: 'পরিমাণ',
text37: 'বোনাস ব্যবহার করুন',
text38: 'শর্তাবলী',
text39: 'আমার প্রচারণা কোড আছে',
text40: 'প্রচারণা কোড',
text41: 'প্রচারণা কোড প্রয়োগ করুন',
text42: 'আপনি যেরচারণা কোডটি ইনপুট করেছেন সেটি সঠিক। আপনি 30% বোনাস পাবেন।',
text43: 'আপনি পাবেন',
text44: '',
text45: 'সমস্ত সাধারণ প্রশ্ন সম্পর্কে জানুন',
text46: 'ক্রিপ্টো এসেট লেনদেন করতে, একটি ব্যক্তিগত অ্যাকাউন্ট খোলার প্রয়োজন হবে। প্রস্তুত লেনদেন করার জন্য আপনার নির্বাচিত বিকল্পের মূল্য অনুযায়ী আমান দিতে হবে।',
text48: 'আপনার পাঁচ টি প্রচারণা কোড আছে',
text49: 'এই প্রচারণা কোডটি ক্ষতিগ্রস্ত লেনদেন বাতিল করতে ব্যবহৃত হবে',
text50: 'আপনি শুধুমাত্র বাতিল হওয়া লেনদেনগুলি বাতিল করতে পারবেন যার পরিমাণ প্রচারণা কোডের সর্বোচ্চ মৌল্যের চেয়ে বেশি না।',
text51: 'অবস্থা',
text52: 'ব্যবহার করুন',
text53: 'আপনার প্রচারণা কোডের ইতিহাস নেই। আপনি নীচের বাটনগুলি ব্যবহার করে প্রচারণা কোড যোগ করতে পারেন।',
text54: 'ইতিহাস দেখান',
text55: 'প্রচারণা কোড ইনপুট করুন',
text56: 'গোপনীয়তা তথ্য',
text57: 'ফাঁকা',
text58: 'নাম',
text59: 'পদবি',
text60: 'জন্ম তারিখ',
text61: 'দেশ',
text62: 'সংরক্ষণ করুন',
text63: 'ঠিকানা',
text64: 'নথি যাচাই',
text65: 'অ্যাকাউন্ট যাচাই করার আগে ব্যক্তিগত তথ্য প্রদান করতে হবে।',
text66: 'নিরাপত্তা',
text67: 'দুটি পদক্ষেপ যাচাই',
text68: 'পাসওয়ার্ড পরিবর্তন করুন',
text69: 'পুরানো পাসওয়ার্ড',
text70: 'নতুন পাসওয়ার্ড',
text71: 'নতুন পাসওয়ার্ড আবার প্রদর্শন করুন',
text72: 'অ্যাকাউন্ট লগ আউট করুন',
text73: 'ক্রেডিট কার্ড বা ডেবিট কার্ড যাচাই করুন',
text74: 'নতুন ব্যাংক কার্ড যোগ করুন',
text75: 'আপনার যেকোনো যাচাইকরণ প্রয়োজন কার্ড বা ডেবিট কার্ড নেই',
text76: 'আপনার কোনও লেনদেনের ইতিহাস নেই।',
text77: 'শুরু',
text78: 'মেয়াদোত্তীর্ণ',
text79: 'প্রয়োজনীয় ফিল্টার প্রয়োগ করুন',
text80: 'পূর্ববর্তী',
text81: 'পরবর্তী',
text82: 'লাভ',
text83: 'বাতিল',
text84: 'অ্যাকাউন্ট',
text85: 'ফি',
text86: 'আপনি প্ল্যাটফর্ম অ্যাকাউন্ট থেকে অ্যাকাউন্টে টাকা উত্তোলন করতে পারেন যা আপনি টাকা জমা দিতে ব্যবহার করেছিলেন এবং ইলেক্ট্রনিক মানিব্যাগে। আপনি যে সময় ইচ্ছা করে উত্তোলনের অনুরোধ করতে পারেন। আপনার উত্তোলনের অনুরোধগুলি আমরা 3 আবর্জনা দিনের মধ্যে প্রস্তুত করব।',
text87: 'টাকা জমা করুন',
text88: 'আগের কিছু অনুরোধ',
text89: 'উত্তোলনের ইতিহাস',
text90: 'আমার অনুরোধগুলি',
text91: 'অনুরোধ দিন',
text92: 'সাধারণ প্রশ্ন',
text93: 'আপনি কোনও অনুরোধ দেননি',
text94: 'যদি কোনও প্রশ্ন থাকে তবে আপনি সাধারণ প্রশ্ন অনুভাগে উত্তর পাবেন।',
text95: 'কপিরাইট © 2024 Quotex। সর্বস্বত্ব সংরক্ষিত',
text96: 'লগইন করুন',
text97: 'পাসওয়ার্ড',
text98: 'আমাকে মনে রাখুন',
text99: 'তথ্য ফিরে যাও',
text100: 'সম্পন্ন হয়েছে',


text104: 'বুদ্ধিমান বিনিয়োগের জন্য উদ্ভাবন প্ল্যাটফর্ম',
   text105: 'সাইন আপ করুন এবং একটি ডেমো অ্যাকাউন্টে $10,000 পান যেখানে আপনি ট্রেড করতে শিখবেন',
   text106: 'রেজিস্টার',
   text107: 'একটি ডেমো অ্যাকাউন্ট খুলুন',
   text108: 'সুবিধাজনক ট্রেডিং ইন্টারফেস',
   text109:'আমরা সবচেয়ে সহজ এবং সবচেয়ে সুবিধাজনক ইন্টারফেস তৈরি করেছি যাতে আপনি সবচেয়ে গুরুত্বপূর্ণ - ট্রেডিং থেকে বিচ্যুত না হন। ',
   text110: 'বিল্ট-ইন সিগন্যাল',
   text111:'একটি ট্রেডিং কৌশল বিবেচনা করুন, আমরা আপনাকে আপনার নিজস্ব কার্যকরী কৌশল বিকাশে সহায়তা করার জন্য 87% পর্যন্ত সবচেয়ে সঠিক এবং উদ্ভাবনী সংকেত প্রদান করি। ',
   text112: 'ট্রেডিং ইন্ডিকেটর',
   text113:'আমরা সবচেয়ে উপকারী ট্রেডিং সূচক সংগ্রহ করেছি। আপনার অ্যাকাউন্ট ব্যালেন্স বাড়াতে এই সূচক ব্যবহার করুন. ',
   text114: 'পারফেক্ট স্পিড',
   text115: 'আমাদের প্ল্যাটফর্ম সবচেয়ে উদ্ভাবনী প্রযুক্তি ব্যবহার করে এবং অসাধারণ গতি প্রদান করে। ',
   text116: 'একটি ডেমো অ্যাকাউন্ট দিয়ে চেষ্টা করে দেখুন',
   text117:'আরো টাকা উপার্জন করার জন্য,',
   text118: 'একটি সঠিক পূর্বাভাস তৈরি করুন',
   text119:'দাম কি তেজি নাকি বিয়ারিশ? ',
   text120: 'একটি ডেমো অ্যাকাউন্ট খুলুন',
   text121: 'ক্লিক করুন',
   text122: 'একটি বোতামে এবং ফলাফল দেখুন',
   text123: 'দাম বাড়বে',
   text124: 'দাম কমবে',
   text125: 'ফলাফলের জন্য অপেক্ষা করছি...',
   text126:'আপনার লাভ',
   text127: 'দুঃখিত, আপনার ভবিষ্যদ্বাণী ভুল',
   text128: 'আবার চেষ্টা করুন',
   text129:'আপনার লাভ',
   text130:'অভিনন্দন! আপনার ভবিষ্যদ্বাণী সঠিক',
   text131: 'অর্থ উপার্জন শুরু করুন',
   text132:'মোবাইল অ্যাপ সবসময় পাওয়া যায়',
   text133: 'আইফোন বা অ্যান্ড্রয়েডের জন্য আমাদের ব্যবহারকারী-বান্ধব অ্যাপ ডাউনলোড করুন এবং ট্রেডিং শুরু করুন! ',
   text134: 'উপলভ্য সময়',
   text135: 'বাণিজ্য শুরু করুন',
   text136:'3টি ধাপ',
   text137: 'মাত্র কয়েক মিনিটের মধ্যে বিনামূল্যে একটি আসল অ্যাকাউন্ট খুলুন',
   text138: 'এক ক্লিকে ডেমো অ্যাকাউন্টে ট্রেড করুন',
   text139: 'অভ্যাস',
   text140:'ডেমো অ্যাকাউন্ট এবং প্রশিক্ষণ উপকরণ দিয়ে আপনার দক্ষতা উন্নত করুন',
   text141: 'একটি ডেমো অ্যাকাউন্ট দিয়ে আপনার শিক্ষা শুরু করুন',
   text142: 'আপনার আসল অ্যাকাউন্টে জমা করুন এবং ট্রেডিং শুরু করুন',
   text143:'410 টিরও বেশি উপকরণ, ন্যূনতম লেনদেনের পরিমাণ হল $10',
   text144: 'জাম্প ডিপোজিট পদ্ধতি',
   text145:'FAQ',
   text146:'কিভাবে এটা আয়ত্ত করা যায়? ',
   text147:'একটি ডেমো অ্যাকাউন্ট নিবন্ধন করুন এবং এই অ্যাকাউন্টটি ব্যবহার করে অনুশীলন করুন। একটি ডেমো অ্যাকাউন্ট একটি আসল অ্যাকাউন্টের মতোই, তবে কোনও জমার প্রয়োজন নেই৷ ',
   text148:'একটি প্রত্যাহার প্রক্রিয়া করতে কতক্ষণ সময় লাগে? ',
   text149: 'গড়ে, প্রত্যাহারের প্রক্রিয়াটি সংশ্লিষ্ট ব্যবহারকারীর অনুরোধ প্রাপ্তির তারিখ থেকে প্রক্রিয়া করতে 1 থেকে 5 দিনের মধ্যে সময় নেয় এবং শুধুমাত্র একই সাথে প্রক্রিয়াকৃত অনুরোধের সংখ্যার উপর নির্ভর করে। কোম্পানি সর্বদা ব্যবহারকারীর অনুরোধটি পাওয়ার তারিখে সরাসরি অর্থ প্রদান করার চেষ্টা করে। ',
   text150:'ট্রেডিং প্ল্যাটফর্ম কি? প্রভাব কি? ',
   text151: 'ট্রেডিং প্ল্যাটফর্ম হল একটি সফ্টওয়্যার সিস্টেম ব্যবহারকারীরা লেনদেন (অপারেশন) পরিচালনা করতে এবং কোম্পানির দ্বারা প্রদত্ত কোটেশন মূল্য, রিয়েল-টাইম মার্কেট পরিস্থিতি, কোম্পানির ক্রিয়াকলাপ এবং অন্যান্য তথ্য ব্যবহার করে। ',
   text152:'আমি কি আমার মোবাইল ফোন ব্যবহার করে লেনদেন করতে পারি? ',
   text153:'আমাদের প্ল্যাটফর্ম সবচেয়ে উদ্ভাবনী প্রযুক্তি ব্যবহার করে এবং যেকোনো কম্পিউটার বা স্মার্টফোনের ব্রাউজারে খোলা যায়',
   text154:'সর্বনিম্ন জমার পরিমাণ কত? ',
   text155: 'এই কোম্পানির ট্রেডিং প্ল্যাটফর্মের সুবিধা হল যে আপনাকে আপনার অ্যাকাউন্টে বেশি পরিমাণ অর্থ জমা করার দরকার নেই। আপনি অল্প পরিমাণ অর্থ বিনিয়োগ করে ব্যবসা শুরু করতে পারেন। সর্বনিম্ন আমানত হল $10। ',
   text156:'অ্যাকাউন্ট থেকে টাকা জমা বা তোলার সময় কি কোন কমিশন আছে? ',
   text157: 'না। অ্যাকাউন্ট জমা করা বা তহবিল তোলার মতো ক্রিয়াকলাপগুলির জন্য কোম্পানি কোনো কমিশন চার্জ করে না। ',
   text158: 'তবে, এটা অবশ্যই উল্লেখ্য যে পেমেন্ট সিস্টেম কমিশন চার্জ করতে পারে এবং ডিফারেনশিয়াল কারেন্সি এক্সচেঞ্জ রেট ব্যবহার করতে পারে। ',
   text159: 'সব প্রশ্নে যান',
   text160:'কোটেক্স: ইনোভেশন প্ল্যাটফর্ম',
   text161:'ডিজিটাল অ্যাসেট ট্রেডিং',
   text162: 'একটি আসল অ্যাকাউন্ট খুলুন',
   text163: 'পার্টনার',
   text164: 'ডেমো অ্যাকাউন্ট',
   text165: 'যোগাযোগ তথ্য',
   text166: 'গুরুত্বপূর্ণ প্রশ্ন',
   text167: 'আর্থিক সমস্যা',
   text168: 'যাচাই',
   text169: 'প্রবিধান',
   text170: 'গোপনীয়তা নীতি',
   text171: 'পেমেন্ট শর্তাবলী',
   text172: 'পরিষেবা চুক্তি',
   text173: 'ঝুঁকি সতর্কীকরণ',
   text174: 'ট্রেডিং অপারেশন নিয়ম',
   text175: 'নন-ট্রেডিং অপারেটিং নিয়ম',
   text176:`ঝুঁকি সতর্কীকরণ: ডিজিটাল অ্যাসেট ট্রেডিং প্ল্যাটফর্মে ট্রেড করা এবং ধার দেওয়া তহবিল উপকরণ ব্যবহার করা উল্লেখযোগ্য ঝুঁকির সাথে জড়িত হতে পারে এবং এর ফলে আপনার বিনিয়োগ মূলধন সম্পূর্ণ ক্ষতি হতে পারে। আপনার সামর্থ্যের চেয়ে বেশি বিনিয়োগ করবেন না এবং আপনার জড়িত ঝুঁকিগুলি বোঝা উচিত। ধার করা তহবিল উপকরণ ব্যবহার করে ট্রেড করা বেশিরভাগ বিনিয়োগকারীদের জন্য উপযুক্ত নয়। ধার করা তহবিল যেমন স্টক ব্যবহার না করে ট্রেড করাও ঝুঁকিপূর্ণ কারণ স্টকের মূল্য হ্রাস এবং বৃদ্ধি উভয়ই হতে পারে, যার অর্থ হতে পারে আপনি জিতলেও, আপনি বিনিয়োগের চেয়ে অনেক কম ফেরত পাবেন।
   অতীত সাফল্য ভবিষ্যতের সাফল্যের নিশ্চয়তা দেয় না। ট্রেড করার আগে, অনুগ্রহ করে আপনার ট্রেডিং অভিজ্ঞতা এবং স্তর, বিনিয়োগ লক্ষ্যের দিকে মনোযোগ দিন এবং প্রয়োজনে একজন স্বাধীন আর্থিক উপদেষ্টার সাথে যোগাযোগ করুন। ব্যবহারকারীদের তাদের বসবাসের দেশের আইন ও প্রবিধানের উপর ভিত্তি করে কোটেক্স-ব্র্যান্ডেড পরিষেবার ব্যবহার অনুমোদিত কিনা তা নির্ধারণ করা উচিত। `,

}