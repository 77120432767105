<template>
  <div id="select-country-name" class="select-input-container">
    <input id="input-country-name" :value="value" @input="inputHandle" @focus="show = true" type="text" placeholder="Search..." class="select-input" />
    <div class="modal-sign__input select select-icon" >
      <div class="icon-input">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
          <g opacity="0.3" clip-path="url(#clip0_7761_804)">
            <path
              d="M8.08594 0.34375C3.86406 0.34375 0.429688 3.77812 0.429688 8C0.429688 12.2219 3.86406 15.6562 8.08594 15.6562C12.3078 15.6562 15.7422 12.2219 15.7422 8C15.7422 3.77812 12.3078 0.34375 8.08594 0.34375ZM14.4328 7.3625H11.3422C11.2766 5.3125 10.8828 3.30937 10.1797 1.97187C12.4797 2.775 14.1859 4.85625 14.4328 7.3625ZM8.08594 14.3813C7.31719 14.3813 6.22344 12.1656 6.10156 8.6375H10.0672C9.94844 12.1625 8.85469 14.3813 8.08594 14.3813ZM6.10156 7.3625C6.22031 3.8375 7.31719 1.61875 8.08594 1.61875C8.85469 1.61875 9.94844 3.83438 10.0703 7.3625H6.10156ZM5.99219 1.97187C5.28906 3.30937 4.89531 5.30937 4.82656 7.35938H1.73906C1.98594 4.85625 3.69219 2.775 5.99219 1.97187ZM1.73906 8.6375H4.82969C4.89844 10.6875 5.29219 12.6906 5.99531 14.025C3.69219 13.225 1.98594 11.1437 1.73906 8.6375ZM10.1797 14.0281C10.8828 12.6906 11.2766 10.6906 11.3453 8.64062H14.4359C14.1859 11.1438 12.4797 13.225 10.1797 14.0281Z"
              fill="white"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_7761_804">
              <rect width="16" height="16" fill="white" transform="translate(0.0859375)"></rect>
            </clipPath>
          </defs>
        </svg>
      </div>

      <label class="modal-sign__input-label">Country / Region of residence</label>

      <div class="modal-sign__input-select form__select select__wrapper">
        <div class="select__result" @click="show = !show">
          <div><span class="select__text">{{ value }}</span></div>
          <i class="select__icon"></i>
        </div>
        <div class="select__options" :class="[  show ? 'select__active':'' ]">
          <div v-for="item in options.filter((v)=>{
            if(this.isSR) return true
            return v.label.indexOf(this.value) > -1
          })" :key="item.value">
            <span @click="selectHandle(item)" class="select__text">{{ item.label }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'placeholder',
    },
    lable: {
      type: String,
      default: 'placeholder',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array | String | Number,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed:{
    isSR(){
        return this.options.findIndex(v=>v.value == this.value) > -1
    }
  },
  methods: {
    inputHandle(e){
      this.$emit('update:value', e.target.value);
    console.log(this.value,this.isSR)
    },
    selectHandle(item) {
      this.$emit('update:value', item.value);
      this.show = false;
      console.log(this.show,this.value )
    },
  },
};
</script>
